export function InfoPendingIllustration() {
  return (
    <svg width="400px" viewBox="0 0 403 478" fill="none">
      <path
        d="M296.035 21.493c-94.228-43.86-163.328-16.29-231.171 43.861C-54.491 171.873 7.07 401.201 118.887 442.556c111.817 41.354 212.326-55.139 262.58-124.063 50.255-68.924 8.795-253.139-85.432-297z"
        fill="#F3FBF6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98.828 348.922h79.964l10.478 128.29H70.237l28.591-128.29z"
        fill="#D6C6C5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98.828 348.922h24.594l14.719 128.29H70.237l28.591-128.29z"
        fill="#000"
        fillOpacity={0.1}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M203.833 432.791c-1.285-1.617-3.651-2.025-5.196-.654-1.487 1.319-3.118 2.912-3.803 4.098-1.4 2.423-3.277 7.539-3.277 7.539l50.558 29.177s5.732-5.375 1.786-8.345a347.016 347.016 0 01-6.513-5.008l-17.01-23.426a1.765 1.765 0 00-2.504-.361l-3.031 2.333s-4.99-.435-7.448-1.853c-1.136-.656-2.463-2.118-3.562-3.5z"
        fill="#5E5343"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M169.885 174.179c5.49 5.2 11.301 8.405 14.384 8.293 7.73-.282 14.272-26.9 10.925-38.694-3.348-11.793-31.442-22.774-37.494-2.189-2.1 7.145-.473 14.289 2.87 20.56l-16.321 35.852 23.446 5.141 2.19-28.963z"
        fill="#B28B67"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M149.004 132.393c-.593-.916-.771-2.078-.905-3.173-.14-1.138-.266-2.285-.271-3.439-.011-2.305.539-4.833 2.48-6.196 1.558-1.092 3.579-1.292 5.349-1.044a9.181 9.181 0 013.366 1.198c1.024.605 1.817 1.536 2.788 2.219.56-2.359 1.509-4.748 3.153-6.506 1.583-1.692 3.665-2.38 5.828-2.208 2.219.176 4.236 1.147 5.936 2.612.426.368.844.746 1.234 1.156.326.344.656.743 1.083.947.475.228.788-.007 1.194-.301a8.37 8.37 0 011.518-.864c2.339-1.039 5.115-1.068 7.254.331.985.645 1.879 1.568 2.398 2.685.464.996.631 2.45 1.398 3.241.325.336.667.061 1.068-.11.603-.257 1.189-.558 1.795-.803.852-.345 1.737-.549 2.639-.559 1.32-.015 3.042.434 3.04 2.159 0 .647-.249 1.286-.429 1.899-.247.843-.497 1.684-.759 2.522a18.828 18.828 0 01-.669 1.857c-.14.325-.483.913-.412 1.285.089.465.982.407 1.337.501.702.184 1.386.464 1.975.911.456.346.914.808 1.001 1.437.053.386-.062.746-.205 1.1-.171.425-.137.678-.179 1.135 1.054-.26 4.58-.578 4.621 1.218.014.599-.442 1.171-.787 1.597-.605.748-1.325 1.395-2.05 2.003-1.511 1.269-3.205 2.279-4.964 3.074 1.599 1.175 1.552 3.599-.285 4.646-.811.463-1.751.399-2.596.173-.31-.083-.734-.302-.992-.301-.139 0-.305.081-.491.053-1.224-.181-2.52-.77-3.64-1.296-1.995-.937-3.764-2.367-5.055-4.238-.331-.48-.626-.779-1.187-.896-.493-.103-1.004-.074-1.497-.169-1.306-.251-2.337-1.142-3.45-1.829-.43 1.612-1.287 3.304-2.275 4.623-.805 1.075-1.977 1-3.115.861-3.638-.447-7.133-1.605-10.703-2.383 3.284 1.539 6.783 2.643 10.006 4.313 1.456.755.046 1.539-.775 2.117-1.35.952-2.484 2.249-3.387 3.681-1.207-2.574-3.876-4.681-6.671-4.584-3.088.106-6.014 3.609-4.768 6.862.724 1.889 2.545 2.956 4.02 4.022-1.562 1.044-2.299 2.931-3.425 4.409-.556.729-1.288 1.53-2.194 1.628-.336.036-.704-.035-1.007.165-.375.249-.525.586-.974.771-1.794.741-4.207.125-5.277-1.59-.968-1.551-.2-3.501 1.126-4.567-1.507-1.055-4.334-2.607-3.108-5.047-3.542-1.026-10.99-8.769-5.232-11.97-2.319-2.077-3.95-6.267-1.542-9.117 1.853-2.195 5.142-2.637 7.669-2.221z"
        fill="#191847"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M224.256 371.917c-8.986 19.049-23.027 65.289-23.027 65.289l11.92 5.444s30.248-48.61 48.732-81.837a1099.156 1099.156 0 00-2.552 24.757c-1.926 20.972-.001 66.281 1.036 79.369.651 8.226 11.833 6.503 12.876-.021.177-1.105.898-4.984 1.973-10.772 5.272-28.382 19.069-102.661 19.093-120.848.01-7.319-14.295-13.894-22.821-8.776-5.856-6.246-16.94-9.846-23.307.471-4.048 6.559-13.666 25.18-23.923 46.924zM202.605 266.849l26.244 31.805c8.144 4.568 14.705 9.349 19.682 14.341 1.066 1.612 2.0944.59-3.54 2.696-5.633-1.895-11.643-3.634-12.932-1.876-1.288 1.758 1.597 4.98-.674 7.217-1.514 1.492-5.444-4.202-11.789-17.083l-28.567-21.299 11.576-15.801z"
        fill="#B28B67"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M168.66 190.485c9.277 37.148 28.313 68.64 58.511 104.011l-17.901 12.177c-37.219-25.648-56.571-40.338-59.401-73.935-.71-8.432 3.675-33.629 5.364-47.044l13.427 4.791z"
        fill="#96A0AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M202.081 420.092l40.913-96.878c9.802-15.76 37.282 3.207 33.94 12.347-7.589 20.752-46.182 87.934-48.443 94.116l-26.41-9.585z"
        fill="#191847"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M297.172 340.743c0 27.486-13.059 91.601-13.067 97.653l-28.098.022s6.169-85.814 4.375-86.738c-1.794-.924-73.542 7.16-95.061 7.16-31.03 0-42.466-26.616-43.508-62.848l60.069 7.057c12.516 1.339 77.799 14.501 102.043 19.025 10.38 1.937 13.247 11.097 13.247 18.669z"
        fill="#2F3676"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M227.889 172.83l11.323.127c1.158.013 2.006 1.084 1.894 2.392a2.762 2.762 0 01-.143.67l-13.546 38.931c-.436 1.251-1.663 1.954-2.741 1.57a1.837 1.837 0 01-.661-.411l-9.966-9.62c-.665-.642-.846-1.733-.447-2.696l12.189-29.438c.383-.927 1.221-1.535 2.098-1.525z"
        fill="#242932"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M228.572 175.895l5.963.37c1.165-.062 2.075.948 2.032 2.254-.009.274-.06.547-.151.809l-10.524 30.233c-.435 1.251-1.665 1.953-2.747 1.568a1.86 1.86 0 01-.769-.523l-5.405-6.088c-.577-.65-.717-1.661-.355-2.56l9.579-23.809c.359-.893 1.53-2.209 2.377-2.254z"
        fill="#F8F9FD"
      />
      <path
        d="M208.886 214.181l-7.735 9.546 1.282 13.98c7.71-2.998 8.89-7.253 13.256-12.711 11.082-4.096 13.051-5.449 18.009-12.965 2.653-4.022 4.279-15.904 1.25-18.144-.747-.553-1.044 8.936-11.069 11.242-10.024 2.305-6.757-7.756-7.052-9.706-3.111.31-6.407 10.053-7.941 18.758z"
        fill="#B28B67"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M123.578 294.229l69.691 10.586s-18.09-85.373-18.681-116.348c-.02-1.053-1.965-2.908-2.825-2.665-9.44 2.665-20.204-1.993-20.204-1.993-19.023 30.582-24.224 59.904-27.981 110.42z"
        fill="#DDE3E9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M156.218 329.516c-37.688 21.613-43.03-1.324-43.03-1.324-6.795-45.649 11.065-108.942 27.621-140.866.633-1.219 1.979-3.517 5.149-3.517h12.433c1.605 4.83 3.178 9.512 4.705 14.053 5.827 17.336 10.968 32.629 14.539 46.356.576-.548 1.166-1.121 1.77-1.707 1.393-1.353 2.862-2.779 4.41-4.152 7.805-8.071 9.539-9.865 19.064-20.612l14.246 7.701c-2.454 11.511-9.275 25.743-19.68 36.504-4.504 4.658-9.445 8.829-14.72 12.025 1.036 21.643-5.868 39.112-26.507 55.539z"
        fill="#C3C8D2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M262.079 459.149c-1.921-.758-4.174.071-4.827 2.031-.628 1.885-1.243 4.08-1.243 5.449 0 2.799.933 8.168.933 8.168h58.38s2.275-7.52-2.628-8.119c-4.902-.6-8.145-1.083-8.145-1.083l-26.45-11.786a1.763 1.763 0 00-2.349.939l-1.458 3.535s-4.539 2.117-7.377 2.117c-1.312 0-3.193-.603-4.836-1.251z"
        fill="#5D5343"
      />
      <path
        d="M300.502 71.566a2.313 2.313 0 00-2.331-2.477l-66.778.71a2.312 2.312 0 00-2.287 2.261l-1.404 63.496a2.311 2.311 0 002.311 2.363h13.309c.452 0 .783.426.672.865l-4.265 16.801c-.206.812.688 1.458 1.394 1.007l29.647-18.944a.697.697 0 01.374-.109h22.49c1.213 0 2.22-.937 2.306-2.147l4.562-63.826z"
        fill="#14AA4B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M244.121 118.52c0-6.185 5.014-11.2 11.2-11.2h15.999c6.186 0 11.2 5.015 11.2 11.2v3.2a1.6 1.6 0 11-3.2 0v-3.2a8 8 0 00-8-8h-15.999a8 8 0 00-8 8v3.2a1.6 1.6 0 01-3.2 0v-3.2zM263.321 99.32a6.4 6.4 0 100-12.8 6.4 6.4 0 000 12.8zm0 3.2c5.301 0 9.599-4.298 9.599-9.6s-4.298-9.6-9.599-9.6a9.6 9.6 0 100 19.2z"
        fill="#fff"
      />
      <path
        d="M211.165 78.102l-9.347-4.333m25.192-21.72l-3.603-5.844m34.544-4.802l.203-5.708m34.902 15.201l3.289-6.325m22.303 25.458l6.847-4.215"
        stroke="#14AA4B"
        strokeWidth={4.624}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
