export function FollowUpBannerLoanCancelledIllustration() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="140" height="130" fill="none" viewBox="0 0 140 130">
      <path fill="#F7F7FA" d="M0 0h75c35.899 0 65 29.102 65 65 0 35.899-29.101 65-65 65H0V0z" />
      <g clipPath="url(#clip0_9586_49592)">
        <g clipPath="url(#clip1_9586_49592)">
          <path
            fill="#1D232A"
            d="M71.256 42.375c0 1.121-.103 2.22-.296 3.28a17.59 17.59 0 01-.715 2.74c-.126.374-.269.743-.428 1.11a17.041 17.041 0 01-.821 1.709c-.115.209-.227.414-.354.615-3.188 5.342-8.996 8.916-15.624 8.914l-6.604-.001-.066-20.993-.05-13.694 1.65-.001 12.486-.487c.265.12.525.244.786.376.449.226.888.47 1.309.734-4.227.205-8.453.413-12.681.62-.523.026-.514.846.012.823 4.599-.229 9.206-.454 13.808-.682.045.035.094.07.139.104a18.34 18.34 0 017.449 14.833z"
          />
          <path
            fill="#E8E9EA"
            d="M63.488 48.826c-3.559 9.492-14.075 14.296-23.49 10.732a18.08 18.08 0 01-6.365-4.09 18.346 18.346 0 01-3.758-5.404l4.346-3.315a13.145 13.145 0 01-.527-1.977l-4.535 3.46a17.964 17.964 0 01-.346-1.162c.02-.01.04-.022.06-.036l4.536-3.462.061-1.025-.53.4-4.325 3.298a18.448 18.448 0 01.745-10.243c.012-.03.02-.058.032-.086a18.299 18.299 0 0115.624-11.85 17.27 17.27 0 011.28-.058L52.98 24h.088a18.142 18.142 0 016.37 1.156c.334.128.667.266.996.411l-6.1.238c.725.348 1.416.74 2.073 1.17l6.121-.298c.006 0 .01.005.014.007.388.238.764.488 1.125.754-2.059.103-4.119.203-6.179.304a18.161 18.161 0 014.934 5.774 18.425 18.425 0 011.065 15.31z"
          />
          <path
            fill="#FCFCFC"
            stroke="#FCFCFC"
            d="M37.812 51.2v-.001a12.344 12.344 0 01-2.858-4.426c-.147-.392-.276-.79-.382-1.193l18.162-13.85c.358.212.702.443 1.032.689a12.296 12.296 0 013.618 4.312l-19.31 14.721a10.71 10.71 0 01-.262-.252zM30.08 49.279a16.83 16.83 0 01-.332-.867l3.623-2.764c.077.31.164.617.262.92L30.08 49.28z"
          />
          <mask id="path-6-inside-1_9586_49592" fill="#fff">
            <path d="M29.16 48.234l.009.031a.246.246 0 01-.011-.031h.001z" />
          </mask>
          <path fill="#FCFCFC" d="M29.16 48.234l.009.031a.246.246 0 01-.011-.031h.001z" />
          <path
            fill="#FCFCFC"
            d="M29.16 48.234l.961-.273-.206-.727h-.756v1zm.009.031l-.92.392 1.882-.665-.962.273zm-.011-.031v-1h-1.324l.362 1.273.962-.273zm-.96.273l.01.035-.001-.004 1.924-.546c-.005-.016-.01-.03-.01-.035-.003-.006-.001-.002 0 .004l-1.924.546zm1.89-.633a.8.8 0 01.032.087l-1.924.546c.011.04.027.09.053.15l1.84-.783zm-.93 1.36h.001v-2h-.001v2z"
            mask="url(#path-6-inside-1_9586_49592)"
          />
          <path
            fill="#1D232A"
            d="M45.654 50.949a6.152 6.152 0 01-2.082-.375c-2.584-.934-3.945-3.677-3.768-5.788a.236.236 0 01.253-.216c.13.011.225.126.215.255-.163 1.934 1.087 4.447 3.458 5.305 1.708.617 3.473.401 4.72-.575.941-.739 1.418-1.816 1.309-2.958-.208-2.153-1.525-3.098-3.194-4.291l-.154-.11c-1.705-1.223-3.122-2.388-3.186-4.282a3.678 3.678 0 011.531-3.127c1.203-.884 2.874-1.067 4.468-.49 1.899.687 2.933 2.384 3.266 3.745a.237.237 0 01-.172.287.234.234 0 01-.285-.173c-.305-1.242-1.245-2.79-2.97-3.413-1.47-.531-2.94-.375-4.033.426a3.217 3.217 0 00-1.339 2.73c.058 1.68 1.331 2.724 2.99 3.913l.153.11c1.697 1.214 3.16 2.262 3.39 4.631.126 1.306-.417 2.537-1.488 3.377-.86.67-1.938 1.019-3.082 1.019z"
          />
          <path
            fill="#1D232A"
            d="M42.893 52.396a.237.237 0 01-.222-.317l6.922-19.486a.235.235 0 11.442.159L43.114 52.24a.234.234 0 01-.22.156z"
          />
          <mask id="path-10-inside-2_9586_49592" fill="#fff">
            <path d="M33.47 42.546l-.06 1.026-4.537 3.461a.286.286 0 01-.06.037 14.77 14.77 0 01-.198-.826l4.325-3.298.53-.4z" />
          </mask>
          <path
            fill="#FCFCFC"
            d="M33.47 42.546l-.06 1.026-4.537 3.461a.286.286 0 01-.06.037 14.77 14.77 0 01-.198-.826l4.325-3.298.53-.4z"
          />
          <path
            fill="#FCFCFC"
            d="M33.47 42.546l.998.06.129-2.158-1.728 1.3.601.798zm-.06 1.026l.606.795.364-.278.027-.458-.998-.06zm-4.537 3.461l.602.798.005-.003-.607-.795zm-.06.037l-.965.26.3 1.116 1.062-.458-.397-.918zm-.198-.826l-.607-.795-.501.383.129.617.979-.205zm4.325-3.298l-.605-.796-.002.001.607.795zm-.468-.459l-.061 1.025 1.996.12.061-1.026-1.996-.119zm.33.29l-4.536 3.462 1.214 1.59 4.536-3.462-1.213-1.59zm-4.532 3.458c.008-.005.063-.047.147-.084l.793 1.837c.128-.056.22-.123.265-.157l-1.205-1.596zm1.509.574c-.07-.257-.132-.514-.185-.769l-1.958.409c.062.299.134.593.212.881l1.93-.52zm-.558.23l4.325-3.297-1.212-1.59-4.326 3.297 1.213 1.59zm4.324-3.296l.326-.247.142-.106.043-.033.011-.008.003-.003h.001l-.6-.8-.602-.799-.004.003-.012.009-.043.032-.142.108-.333.251 1.21 1.593z"
            mask="url(#path-10-inside-2_9586_49592)"
          />
          <path
            fill="#1D232A"
            d="M37.489 52.061a.236.236 0 01-.162-.065A13.316 13.316 0 0134 46.828a13.292 13.292 0 01-.344-7.748c.114-.45.261-.915.435-1.385a13.254 13.254 0 012.295-3.921.232.232 0 01.33-.027.236.236 0 01.026.332 12.822 12.822 0 00-2.63 5.117c-.636 2.478-.52 5.06.331 7.472a12.849 12.849 0 003.208 4.985c.094.09.097.239.008.334a.231.231 0 01-.17.075z"
          />
          <path fill="#E8E9EA" d="M83.467 49.346V94.27H31.542V82.854H28V71.916h3.546V60.744H64.93V49.346h18.537z" />
          <path
            fill="#FCFCFC"
            stroke="#FCFCFC"
            d="M87.572 93.771V49.846h4.08V93.77h-4.08zM83.967 93.771V49.846h.866V93.77h-.866z"
          />
          <path
            fill="#E8E9EA"
            d="M95.984 60.284V71.92h3.55v10.94h-3.55V94.27h-3.83V49.346h7.38v10.938h-3.55zM87.072 49.346h-1.737V94.27h1.737V49.346z"
          />
          <path fill="#1D232A" d="M64.93 71.455h-1.774V83.16h1.774V71.455z" />
          <path
            fill="#1D232A"
            d="M56.897 94.271h6.261V60.744h-1.681L60.06 71.107l-.167 1.222-1.416 10.36-.167 1.222-1.414 10.36z"
          />
          <path
            fill="#1D232A"
            d="M95.984 71.445v.473H31.546c-.005 0-.011 0-.016-.002h.016v-.47h64.438zM95.984 60.271H64.93v.472h31.054v-.472zM95.984 82.857H31.542v.473h64.442v-.473z"
          />
        </g>
        <g filter="url(#filter0_d_9586_49592)">
          <path
            fill="#4A4F55"
            d="M94.48 107c9.676 0 17.52-7.897 17.52-17.64 0-9.741-7.844-17.639-17.52-17.639-9.675 0-17.52 7.898-17.52 17.64S84.806 107 94.48 107z"
          />
        </g>
        <path
          fill="#FCFCFC"
          d="M102.887 95.205a1.863 1.863 0 010 2.62 1.828 1.828 0 01-2.601 0l-5.805-5.846-5.807 5.845a1.827 1.827 0 01-2.6 0 1.861 1.861 0 010-2.619l5.806-5.844-5.807-5.847a1.861 1.861 0 010-2.618 1.83 1.83 0 012.601 0l5.806 5.846 5.806-5.846a1.831 1.831 0 012.601 0 1.863 1.863 0 010 2.618l-5.805 5.847 5.805 5.844z"
        />
        <g filter="url(#filter1_d_9586_49592)">
          <path
            fill="#4A4F55"
            d="M94.48 107c9.676 0 17.52-7.897 17.52-17.64 0-9.741-7.844-17.639-17.52-17.639-9.675 0-17.52 7.898-17.52 17.64S84.806 107 94.48 107z"
          />
        </g>
        <path
          fill="#FCFCFC"
          d="M102.887 95.205a1.863 1.863 0 010 2.62 1.828 1.828 0 01-2.601 0l-5.805-5.846-5.807 5.845a1.827 1.827 0 01-2.6 0 1.861 1.861 0 010-2.619l5.806-5.844-5.807-5.847a1.861 1.861 0 010-2.618 1.83 1.83 0 012.601 0l5.806 5.846 5.806-5.846a1.831 1.831 0 012.601 0 1.863 1.863 0 010 2.618l-5.805 5.847 5.805 5.844z"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_9586_49592"
          width="41.039"
          height="41.279"
          x="73.961"
          y="70.721"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix values="0 0 0 0 0.290196 0 0 0 0 0.309804 0 0 0 0 0.333333 0 0 0 0.32 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_9586_49592" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_9586_49592" result="shape" />
        </filter>
        <filter
          id="filter1_d_9586_49592"
          width="41.039"
          height="41.279"
          x="73.961"
          y="70.721"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix values="0 0 0 0 0.290196 0 0 0 0 0.309804 0 0 0 0 0.333333 0 0 0 0.32 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_9586_49592" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_9586_49592" result="shape" />
        </filter>
        <clipPath id="clip0_9586_49592">
          <path fill="#fff" d="M0 0H84V83H0z" transform="translate(28 24)" />
        </clipPath>
        <clipPath id="clip1_9586_49592">
          <path fill="#fff" d="M0 0H71.533V70.271H0z" transform="translate(28 24)" />
        </clipPath>
      </defs>
    </svg>
  )
}
