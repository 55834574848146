export function InsuranceStatusLoadingIllustration({
  width = 120,
  height = 120,
  ...rest
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M101.898 34.6301L94.5207 96.6986L54.1272 91.8971L48.0507 91.1756L46.6797 91.0121L46.5852 91.0016L46.8132 89.0861L47.0112 87.4271L50.6592 56.7191L53.7717 30.5291L53.9607 28.9346L66.3747 30.4091L101.898 34.6301Z"
        fill="#00A868"
      />
      <path
        d="M81.5789 80.5358C81.4814 80.5358 81.3914 80.4713 81.3629 80.3723L67.3259 30.8078C67.2914 30.6878 67.3619 30.5633 67.4804 30.5303C67.6004 30.4958 67.7249 30.5663 67.7579 30.6848L81.7964 80.2493C81.8309 80.3693 81.7604 80.4938 81.6419 80.5268C81.6194 80.5328 81.5999 80.5358 81.5789 80.5358Z"
        fill="#1D232A"
      />
      <path
        d="M81.9103 85.7199L51.4873 91.3584L48.0508 90.9459L48.1978 89.2149L50.6593 56.3904L52.6468 29.8959L53.8618 29.3379L67.5433 30.7464L81.9103 85.7199Z"
        fill="#1D232A"
        stroke="#1D232A"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M65.1193 23.4L18 33.8994L31.5948 94.9105L78.7141 84.4111L65.1193 23.4Z" fill="#E8E9EA" />
      <path
        d="M27.5052 43.8652C27.4017 43.8652 27.3087 43.7932 27.2862 43.6897C27.2592 43.5682 27.3357 43.4482 27.4572 43.4212L60.5966 36.0352C60.7181 36.0067 60.8381 36.0847 60.8651 36.2062C60.8921 36.3277 60.8156 36.4477 60.6941 36.4747L27.5547 43.8607C27.5382 43.8637 27.5217 43.8652 27.5052 43.8652Z"
        fill="#1D232A"
      />
      <path
        d="M29.6595 53.5293C29.556 53.5293 29.463 53.4573 29.4405 53.3538C29.4135 53.2323 29.49 53.1123 29.6115 53.0853L62.7509 45.6992C62.871 45.6707 62.9925 45.7487 63.0195 45.8702C63.0465 45.9917 62.97 46.1117 62.8484 46.1387L29.709 53.5233C29.6925 53.5263 29.676 53.5293 29.6595 53.5293Z"
        fill="#1D232A"
      />
      <path
        d="M31.8133 63.1929C31.7098 63.1929 31.6168 63.1209 31.5943 63.0174C31.5673 62.8959 31.6438 62.7759 31.7653 62.7489L64.9048 55.3628C65.0263 55.3358 65.1463 55.4123 65.1733 55.5338C65.2003 55.6553 65.1238 55.7753 65.0023 55.8023L31.8628 63.1869C31.8463 63.1899 31.8298 63.1929 31.8133 63.1929Z"
        fill="#1D232A"
      />
      <path
        d="M33.9673 72.856C33.8638 72.856 33.7708 72.7855 33.7483 72.6805C33.7213 72.559 33.7978 72.439 33.9193 72.412L67.0589 65.0259C67.1804 64.9989 67.3004 65.0754 67.3274 65.1969C67.3544 65.3184 67.2779 65.4384 67.1564 65.4654L34.0168 72.8515C33.9988 72.8545 33.9823 72.856 33.9673 72.856Z"
        fill="#1D232A"
      />
      <path
        d="M36.1197 82.5184C36.0162 82.5184 35.9232 82.4479 35.9007 82.3429C35.8737 82.2214 35.9502 82.1014 36.0717 82.0744L59.4567 76.8619C59.5767 76.8349 59.6982 76.9114 59.7252 77.0329C59.7522 77.1544 59.6757 77.2744 59.5542 77.3014L36.1692 82.5139C36.1527 82.5169 36.1362 82.5184 36.1197 82.5184Z"
        fill="#1D232A"
      />
      <g filter="url(#filter0_d_1848_26118)">
        <path
          d="M89.4001 111.6C98.6785 111.6 106.2 104.078 106.2 94.8C106.2 85.5216 98.6785 78 89.4001 78C80.1217 78 72.6001 85.5216 72.6001 94.8C72.6001 104.078 80.1217 111.6 89.4001 111.6Z"
          fill="#E8E9EA"
        />
      </g>
      <path
        d="M100.164 87.8442L99.1411 92.594C98.9986 93.2557 98.4132 93.7085 97.7629 93.7085C97.6655 93.7085 97.5682 93.6986 97.4694 93.6775L92.7154 92.6688C91.9536 92.5066 91.4669 91.7589 91.6277 90.9957C91.79 90.2339 92.539 89.7473 93.3008 89.9095L95.1037 90.2918C93.298 88.0135 90.2128 86.9484 87.2701 87.8513C85.4856 88.3986 83.9959 89.5949 83.0775 91.2172C82.6924 91.8957 81.8318 92.1342 81.1547 91.7504C80.4762 91.3653 80.2378 90.5048 80.6215 89.8277C81.8981 87.5734 83.9648 85.9144 86.442 85.154C90.447 83.9267 94.6424 85.3233 97.1675 88.3591L97.406 87.2503C97.5696 86.4885 98.3201 86.0033 99.0819 86.1683C99.8436 86.3319 100.329 87.0824 100.164 87.8442Z"
        fill="#00A868"
      />
      <path
        d="M98.1803 99.7746C96.9051 102.029 94.837 103.688 92.3612 104.448C91.3779 104.75 90.3848 104.893 89.4072 104.893C86.3982 104.893 83.5415 103.536 81.6357 101.245L81.3973 102.352C81.2548 103.014 80.6693 103.466 80.019 103.466C79.9203 103.466 79.8201 103.457 79.7199 103.434C78.9596 103.27 78.4743 102.52 78.6379 101.758L79.6621 97.0082C79.8257 96.2479 80.5734 95.7626 81.3338 95.9248L86.0878 96.9335C86.8496 97.0957 87.3363 97.8434 87.1741 98.6066C87.0132 99.3683 86.2642 99.855 85.5024 99.6928L83.6981 99.3105C85.5052 101.589 88.5904 102.654 91.5331 101.751C93.3176 101.204 94.8059 100.007 95.7257 98.3851C96.1094 97.7065 96.9699 97.4681 97.6485 97.8532C98.327 98.237 98.5654 99.0975 98.1803 99.7746Z"
        fill="#00A868"
      />
      <defs>
        <filter
          id="filter0_d_1848_26118"
          x="69.6001"
          y="77"
          width="39.6001"
          height="39.5996"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.290196 0 0 0 0 0.309804 0 0 0 0 0.333333 0 0 0 0.32 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1848_26118" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1848_26118" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
