export function OiLandlineIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="32" height="32" fill="white" />
      <g clipPath="url(#clip0_2841_10515)">
        <path
          d="M9.20849 7.97168C0.718687 13.1771 6.35509 24 14.7811 24C23.0201 24 26.9999 18.4974 26.9999 14.73C26.9999 12.6565 25.5325 10.2859 20.7519 9.90202C17.5949 9.65042 15.3157 7 12.3325 7C11.3799 7 10.3569 7.27112 9.20849 7.97168Z"
          fill="url(#paint0_linear_2841_10515)"
        />
        <path
          d="M14.1575 16.5997C13.6207 16.5997 13.3215 16.0553 13.3215 15.5022C13.3215 14.9491 13.6185 14.4199 14.1575 14.4199C14.6943 14.4199 14.9913 14.9491 14.9913 15.5022C14.9913 16.0553 14.6943 16.5997 14.1575 16.5997ZM14.1575 13.4461C12.9849 13.4461 12.2039 14.3614 12.2039 15.5087C12.2039 16.6582 12.9827 17.5714 14.1575 17.5714C15.3279 17.5714 16.1067 16.6582 16.1067 15.5087C16.1067 14.3614 15.3279 13.4461 14.1575 13.4461ZM17.3409 13.4482C16.9471 13.4482 16.6259 14.3722 16.6259 15.5109C16.6259 16.6496 16.9471 17.5735 17.3409 17.5735C17.7347 17.5735 18.0537 16.6496 18.0537 15.5109C18.0537 14.3722 17.7347 13.4482 17.3409 13.4482ZM17.3299 12.9364C17.7171 12.9364 18.0295 12.6262 18.0295 12.2445C18.0295 11.8628 17.7171 11.5548 17.3299 11.5548C16.9427 11.5548 16.6303 11.8649 16.6303 12.2445C16.6303 12.6262 16.9449 12.9364 17.3299 12.9364Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2841_10515"
          x1="10.9581"
          y1="23.2943"
          x2="19.6172"
          y2="8.08132"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E9278B" />
          <stop offset="0.5" stopColor="#FF6C00" />
          <stop offset="0.689" stopColor="#FF6C00" />
          <stop offset="1" stopColor="#FFFF00" />
        </linearGradient>
        <clipPath id="clip0_2841_10515">
          <rect width="22" height="17" fill="white" transform="translate(5 7)" />
        </clipPath>
      </defs>
    </svg>
  )
}
