export const ExclamationIllustration = ({ width = 145, height = 144, ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg width={width} height={height} viewBox="0 0 184 184" fill="none" {...props}>
    <path
      d="M175.26 135.162C193.095 92.1403 181.885 60.591 157.426 29.6153C114.113 -24.8791 20.8637 3.22852 4.04818 54.2811C-12.7673 105.334 26.4688 151.224 54.4946 174.169C82.5204 197.114 157.426 178.184 175.26 135.162Z"
      fill="#F7F8F9"
    />
    <path
      d="M132.227 56.6023C132.351 54.8738 130.972 53.4084 129.239 53.4268L59.9975 54.1634C58.3982 54.1804 57.1007 55.463 57.0654 57.062L55.61 122.88C55.5732 124.543 56.9108 125.91 58.5736 125.91H71.844C72.4239 125.91 72.8487 126.456 72.706 127.018L68.3963 143.996C68.1319 145.038 69.2785 145.866 70.184 145.287L100.917 125.649C101.06 125.558 101.226 125.51 101.396 125.51H124.542C126.098 125.51 127.388 124.308 127.499 122.757L132.227 56.6023Z"
      fill="#677481"
    />
    <path
      d="M90.1818 74.9793L78.8184 98.1451C78.5854 98.6398 78.4834 99.1887 78.5226 99.7371C78.5617 100.286 78.7407 100.814 79.0416 101.271C79.3426 101.728 79.755 102.096 80.2379 102.34C80.7209 102.584 81.2574 102.694 81.7942 102.661L107.813 100.731C108.349 100.685 108.864 100.497 109.305 100.185C109.747 99.8723 110.101 99.4469 110.331 98.9509C110.561 98.4548 110.66 97.9054 110.618 97.3572C110.576 96.809 110.394 96.2812 110.091 95.8262L95.4348 74.5898C95.1267 74.1486 94.7129 73.7957 94.2332 73.565C93.7535 73.3344 93.2242 73.2338 92.6962 73.2729C92.1682 73.3121 91.6595 73.4897 91.2191 73.7885C90.7786 74.0874 90.4214 74.4975 90.1818 74.9793V74.9793Z"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M93.4062 82.8535L93.872 89.1339"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M94.3359 95.4141L94.3506 95.413"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
