import {
  type LoanBalance,
  type LoanDailyStatement,
  type LoanDailyStatementMapped,
  type LoanRepaymentMethod
} from '~/lib/types'

export function getTotalPaidFrom(balance: LoanBalance) {
  const { repayments } = balance
  const totalPaid = repayments < 0 ? repayments * -1 : 0

  return totalPaid
}

export function getTotalToPayFrom(balance: LoanBalance) {
  const { principal, fee, additionalTax, repayments, dailyTax, interest } = balance
  return principal + fee + additionalTax + repayments + dailyTax + interest
}

export const transcribeRepaymentMethod = new Map<LoanRepaymentMethod, string>([
  ['TransactionSplit', 'Percentual de retenção'],
  ['BalanceRetention', 'Percentual de retenção'],
  ['Boleto', 'Boleto'],
  ['Manual', 'Ajustes financeiros operacionais'],
  ['StoneBankingRetention', 'Percentual de retenção']
])

export function mapDailyStatements(dailyStatements: LoanDailyStatement[]) {
  const daily: LoanDailyStatementMapped[] = []

  dailyStatements.forEach(statement => {
    const { entries, ...item } = statement

    entries?.forEach(entry =>
      daily.push({
        ...item,
        ...entry
      })
    )
  })

  return daily
}
