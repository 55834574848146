export function PapersDocumentIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={64} height={56} fill="none" {...props}>
      <path
        d="M63.423 8.972l-5.532 46.551-30.295-3.6-4.558-.542-1.028-.123-.071-.008.171-1.436.149-1.244 2.735-23.031L27.33 5.896 27.47 4.7l9.31 1.106 26.642 3.166z"
        fill="#BBBDBF"
      />
      <path
        d="M48.184 43.401a.17.17 0 01-.162-.122L37.494 6.105a.168.168 0 11.324-.092l10.53 37.174a.168.168 0 01-.164.214z"
        fill="#1D232A"
      />
      <path
        d="M48.433 47.29l-22.817 4.228-2.578-.31.11-1.297 1.846-24.619 1.491-19.87.911-.42L37.657 6.06l10.776 41.23z"
        fill="#1D232A"
        stroke="#1D232A"
        strokeWidth={3}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M35.84.55L.5 8.424l10.196 45.759 35.34-7.875L35.839.55z" fill="#E8E9EA" />
      <path
        d="M7.629 15.898a.168.168 0 01-.036-.333l24.855-5.54a.169.169 0 11.073.33l-24.855 5.54a.203.203 0 01-.037.003zM9.244 23.147a.168.168 0 01-.036-.333l24.855-5.54a.169.169 0 11.073.33L9.282 23.142a.205.205 0 01-.038.005zM10.86 30.394a.168.168 0 01-.036-.333l24.855-5.54a.169.169 0 11.073.33L10.897 30.39c-.012.002-.025.004-.037.004zM12.475 37.641a.169.169 0 01-.036-.333l24.855-5.54a.169.169 0 11.073.33l-24.854 5.54a.233.233 0 01-.038.003zM14.09 44.889a.169.169 0 01-.036-.333l17.539-3.91a.17.17 0 01.073.33l-17.54 3.91a.21.21 0 01-.036.003z"
        fill="#1D232A"
      />
    </svg>
  )
}
