import { createContext, useContext, useState } from 'react'
import { type AlertProps } from '@stone-payments/jade'

export interface FeedbackProps {
  type: AlertProps['variant']
  message: string
  action?: {
    title: string
    handle: () => void
  }
}

type FeedbackNotificationContextType = {
  currentFeedback: FeedbackProps | undefined
  addFeedback: (props: FeedbackProps) => void
  removeFeedback: () => void
}

export const Context = createContext<FeedbackNotificationContextType>({
  currentFeedback: undefined,
  addFeedback: () => null,
  removeFeedback: () => null
})

export function Provider({ children }: { children: React.ReactNode }) {
  const [feedback, setFeedback] = useState<FeedbackProps>()

  const addFeedback = (props: FeedbackProps) => setFeedback(props)
  const removeFeedback = () => setFeedback(undefined)

  return (
    <Context.Provider
      value={{
        currentFeedback: feedback,
        addFeedback,
        removeFeedback
      }}
    >
      {children}
    </Context.Provider>
  )
}

export function useFeedback() {
  return useContext(Context)
}
