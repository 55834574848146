import { createContext } from 'react'

type FullScreens = 'transfer' | 'payment' | 'deposit'

type State = Record<FullScreens, boolean>

export const initialState: State = {
  transfer: false,
  payment: false,
  deposit: false
}

function toggleFullScreen(): void {
  return undefined
}

export const FullScreensStateContext = createContext(initialState)
export const FullScreensUpdateContext = createContext<(key: keyof State) => void>(toggleFullScreen)
