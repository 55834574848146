export function TransactionalLimitsSunIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1.5em" height="1.5em" viewBox="0 0 24 25" fill="none" {...props}>
      <mask
        id="prefix__a"
        style={{
          maskType: 'alpha'
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={24}
        height={25}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 9a3.5 3.5 0 100 7 3.5 3.5 0 000-7zm-5.5 3.5a5.5 5.5 0 1111 0 5.5 5.5 0 01-11 0zM12 1.5a1 1 0 011 1v2a1 1 0 11-2 0v-2a1 1 0 011-1zM12 19.5a1 1 0 011 1v2a1 1 0 11-2 0v-2a1 1 0 011-1zM3.793 4.293a1 1 0 011.414 0l1.5 1.5a1 1 0 01-1.414 1.414l-1.5-1.5a1 1 0 010-1.414zM17.293 17.793a1 1 0 011.414 0l1.5 1.5a1 1 0 01-1.414 1.414l-1.5-1.5a1 1 0 010-1.414zM1 12.5a1 1 0 011-1h2a1 1 0 110 2H2a1 1 0 01-1-1zM19 12.5a1 1 0 011-1h2a1 1 0 110 2h-2a1 1 0 01-1-1zM6.707 17.793a1 1 0 010 1.414l-1.5 1.5a1 1 0 11-1.414-1.414l1.5-1.5a1 1 0 011.414 0zM20.207 4.293a1 1 0 010 1.414l-1.5 1.5a1 1 0 11-1.414-1.414l1.5-1.5a1 1 0 011.414 0z"
          fill="#000"
        />
      </mask>
      <g mask="url(#prefix__a)">
        <path fill="#767F8D" d="M0 .5h24v24H0z" />
      </g>
    </svg>
  )
}
