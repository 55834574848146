export function QrCodeSmartphoneIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={43} height={55} fill="none" {...props}>
      <path
        d="M35.299 13.63l-.778 6.239-.16 1.274-.085.679-2.995 23.986a4.154 4.154 0 01-4.64 3.603L9.929 47.326a4.148 4.148 0 01-3.607-4.63l4.017-32.174a4.152 4.152 0 014.637-3.603L23.72 8.01l7.97.992a4.149 4.149 0 013.61 4.627z"
        fill="#1D232A"
      />
      <path
        d="M35.3 13.631l-.78 6.239-8.903-6.355-3.879-2.773c.389-.337 1.124-1.398 1.982-2.735L31.69 9a4.156 4.156 0 013.61 4.631zM34.363 21.142l-.085.679-7.694-5.433c-.135-.095-.118-.322-.018-.43.124-.129.296-.113.43-.018.85.605 1.708 1.208 2.558 1.81 1.602 1.129 3.205 2.258 4.809 3.392z"
        fill="#42EC9A"
      />
      <path
        d="M21.425 48.757L4.51 46.647A4.153 4.153 0 01.9 42.015L4.915 9.84a4.153 4.153 0 014.637-3.603l16.916 2.11a4.153 4.153 0 013.61 4.632l-4.015 32.176a4.15 4.15 0 01-4.638 3.603z"
        fill="#00A868"
      />
      <path d="M8.013 12.495l18.427 2.298-3.453 27.675L4.56 40.17l3.453-27.675z" fill="#FCFCFC" stroke="#FCFCFC" />
      <path d="M10.129 12.255l-2.55-.318-3.577 28.668 2.55.318 3.577-28.668z" fill="#42EC9A" />
      <path d="M17.563 11.026a.868.868 0 10.18-1.727.868.868 0 00-.18 1.727z" fill="#1D232A" />
      <path
        d="M21.045 33.955h0l-9.933-1.205s0 0 0 0a.935.935 0 01-.817-1.041h0l1.204-9.918s0 0 0 0a.936.936 0 011.043-.815h0l9.934 1.203s0 0 0 0 0 0 0 0c.514.062.879.528.817 1.041h0l-1.201 9.917s0 0 0 0a.94.94 0 01-1.047.818z"
        fill="#FCFCFC"
        stroke="#FCFCFC"
      />
      <path
        d="M20.633 33.366l-.019-.002-9.081-1.134a.837.837 0 01-.727-.936l1.133-9.07a.84.84 0 01.934-.725l9.082 1.134a.833.833 0 01.73.933l-1.134 9.07a.838.838 0 01-.918.73zM12.785 22.21a.121.121 0 00-.13.104l-1.134 9.07a.118.118 0 00.104.133l9.082 1.134a.123.123 0 00.133-.104l1.133-9.07a.121.121 0 00-.024-.089.11.11 0 00-.08-.044l-9.081-1.134h-.003z"
        fill="#1D232A"
      />
      <path
        d="M20.302 26.46l-1.749-.218a.24.24 0 01-.208-.267l.218-1.746a.24.24 0 01.267-.208l1.75.219a.24.24 0 01.208.266l-.219 1.747a.24.24 0 01-.267.208zM18.659 29.84l-.583-.073a.082.082 0 01-.07-.089l.072-.581a.082.082 0 01.09-.07l.582.072a.082.082 0 01.07.089l-.072.581a.082.082 0 01-.09.07zM18.473 31.318l-.583-.072a.082.082 0 01-.07-.089l.072-.581a.082.082 0 01.09-.07l.582.072a.082.082 0 01.07.089l-.072.581a.077.077 0 01-.09.07zM20.142 30.023l-.583-.072a.082.082 0 01-.07-.089l.072-.581a.082.082 0 01.089-.07l.582.072a.082.082 0 01.07.089l-.071.581a.082.082 0 01-.09.07zM19.308 30.67l-.583-.071a.082.082 0 01-.07-.09l.072-.58a.082.082 0 01.089-.07l.582.071a.082.082 0 01.07.09l-.071.58a.079.079 0 01-.09.07zM14.57 30.83l-1.75-.218a.24.24 0 01-.208-.267l.219-1.746a.24.24 0 01.267-.208l1.75.219a.24.24 0 01.208.266l-.219 1.747a.239.239 0 01-.267.208zM15.197 25.82l-1.749-.218a.24.24 0 01-.208-.267l.218-1.746a.24.24 0 01.267-.208l1.75.218a.24.24 0 01.208.267l-.219 1.747a.238.238 0 01-.267.208zM21.726 28.418h-.008l-10.032-1.254a.359.359 0 01-.312-.4.355.355 0 01.4-.312l10.032 1.253a.358.358 0 01-.08.713z"
        fill="#1D232A"
      />
      <path
        d="M16.083 32.795h-.007a.359.359 0 01-.313-.4l1.253-10.017a.354.354 0 01.4-.312.359.359 0 01.313.4l-1.252 10.016a.36.36 0 01-.394.313z"
        fill="#1D232A"
      />
      <path
        d="M34.23 17.2l-2.308-4.543-4.99-1.14 4.57-2.287 1.142-4.955 2.308 4.542 4.99 1.141-4.57 2.287-1.142 4.954zM24.868 9.947l-.661-2.268-2.179-.945 2.283-.654.949-2.163.661 2.268 2.18.945-2.284.654-.949 2.163zM27.115 53.527l-.797-3.173-2.966-1.42 3.194-.787 1.425-2.944.797 3.173 2.966 1.42-3.193.787-1.426 2.944z"
        fill="#F1BD38"
      />
    </svg>
  )
}
