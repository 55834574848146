import {
  type MutationKey,
  type QueryFunction,
  type QueryKey,
  type UseInfiniteQueryOptions,
  type UseMutationOptions,
  type UseQueryOptions,
  QueryClient,
  useInfiniteQuery,
  useMutation,
  useQuery
} from '@tanstack/react-query'
import { type AxiosError, type AxiosResponse } from 'axios'

import { type PaginatedResponse } from '~/domains/platform/infra/http/paginated-client'

type DataResponse<T, Suppress> = Suppress extends true ? T : AxiosResponse<T>
type AnyObject = Record<string | number, any>

export type UseInfiniteQueryRequestOptions<TData, SupressAxiosResponse = false> = Omit<
  UseInfiniteQueryOptions<DataResponse<PaginatedResponse<TData>, SupressAxiosResponse>>,
  'queryKey' | 'queryFn'
>

export type UseMutationRequestOptions<
  TData = unknown,
  TError = unknown,
  SupressAxiosResponse = false,
  TVariables extends AnyObject = AnyObject
> = Omit<UseMutationOptions<DataResponse<TData, SupressAxiosResponse>, TError, TVariables>, 'mutationFn'>

export const useQueryRequest = <TData = unknown, TError = unknown, SuppressAxiosResponse = false>(
  ...args: Parameters<
    typeof useQuery<unknown, AxiosError<TError>, DataResponse<TData, SuppressAxiosResponse>, readonly unknown[]>
  >
) => {
  const { fetchStatus, isInitialLoading, isLoading, ...rest } = useQuery<
    unknown,
    AxiosError<TError>,
    DataResponse<TData, SuppressAxiosResponse>,
    readonly unknown[]
  >(...args)

  return {
    /**
     * @deprecated use `fetchStatus` property
     * @deprecatedReason This property is being deprecated in favor of updating the react query version to v4, in v5 this property will be removed.
     */
    isIdle: fetchStatus === 'idle',
    isLoading: isInitialLoading,
    isPending: isLoading,
    fetchStatus,
    isInitialLoading,
    ...rest
  }
}

export type UseQueryRequestOptions<TData = unknown, TError = unknown, SuppressAxiosResponse = false> = Omit<
  UseQueryOptions<unknown, AxiosError<TError>, DataResponse<TData, SuppressAxiosResponse>, readonly unknown[]>,
  'queryKey' | 'queryFn'
>

export const useInfiniteQueryRequest = <TData = unknown, TError = unknown, SuppressAxiosResponse = false>(
  queryKey: QueryKey,
  queryFn: QueryFunction<DataResponse<TData, SuppressAxiosResponse>>,
  options: UseInfiniteQueryOptions<DataResponse<TData, SuppressAxiosResponse>, TError>
) => {
  return useInfiniteQuery<DataResponse<TData, SuppressAxiosResponse>, TError>(queryKey, queryFn, options)
}

export const useMutationRequest = <
  TData = unknown,
  TError = unknown,
  SuppressAxiosResponse = false,
  TVariables extends AnyObject = AnyObject
>(
  mutationKey: MutationKey,
  mutationFn: (opt: TVariables) => Promise<DataResponse<TData, SuppressAxiosResponse>>,
  options?: UseMutationRequestOptions<TData, TError, SuppressAxiosResponse, TVariables>
) => {
  return useMutation<DataResponse<TData, SuppressAxiosResponse>, TError, TVariables>(mutationKey, mutationFn, options)
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})
