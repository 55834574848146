export function UberIllustration() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.18868 16.8906C5.18868 18.1698 6.02642 18.9962 7.13585 18.9962C8.23396 18.9962 9.08302 18.1472 9.08302 16.8906V12H10.2717V19.9245H9.09434V19.1887C8.56226 19.7434 7.82642 20.0604 7 20.0604C5.30189 20.0604 4 18.8264 4 16.9585V12H5.18868V16.8906ZM11.4377 19.9245V12H12.5811V14.8868C12.8478 14.6137 13.1665 14.3968 13.5185 14.249C13.8705 14.1012 14.2485 14.0255 14.6302 14.0264C16.3283 14.0264 17.6642 15.3736 17.6642 17.0491C17.6642 18.7132 16.3283 20.0604 14.6302 20.0604C13.8264 20.0604 13.0906 19.7321 12.5698 19.2V19.9245H11.4377ZM12.5585 17.0491C12.5585 18.1585 13.4415 19.0528 14.5509 19.0528C15.6377 19.0528 16.5321 18.1585 16.5321 17.0491C16.5321 15.9283 15.6377 15.0453 14.5509 15.0453C13.4528 15.0453 12.5585 15.9283 12.5585 17.0491V17.0491ZM18.3434 17.0377C18.3434 15.4075 19.6226 14.0377 21.3094 14.0377C22.9736 14.0377 24.1962 15.317 24.1962 17.0377V17.4113H19.4981C19.6566 18.3509 20.4377 19.0528 21.4113 19.0528C22.0792 19.0528 22.6453 18.7811 23.0755 18.2038L23.9019 18.8151C23.3245 19.5849 22.4642 20.0491 21.4113 20.0491C19.6792 20.0491 18.3434 18.7585 18.3434 17.0377V17.0377ZM21.2868 15.034C20.4377 15.034 19.7245 15.6226 19.5208 16.5057H23.0415C22.8491 15.6226 22.1358 15.034 21.2868 15.034ZM28 15.1698H27.5245C26.7774 15.1698 26.234 15.7472 26.234 16.6415V19.9245H25.0906V14.1509H26.2226V14.8642C26.5057 14.4 26.9698 14.1057 27.6038 14.1057H28V15.1698Z"
        fill="black"
      />
    </svg>
  )
}
