export function HandWithCellphoneIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="219" height="176" viewBox="0 0 219 176" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_3278_14100)">
        <path
          d="M185.957 166.477C204.69 153.053 223.817 96.9685 217.201 72.9034C215.503 66.7513 205.561 64.1404 197.87 75.8393C193.994 81.7181 190.761 87.1128 188.063 91.7848C179.262 52.8911 172.968 46.2446 165.9 45.5461C161.2 45.0619 148.018 48.4578 154.699 71.0221C154.855 71.5339 155.024 72.0596 155.19 72.599C155.19 72.6302 155.221 72.6786 155.232 72.7062C155.242 72.7339 155.19 72.6855 155.176 72.682C153.975 72.1514 152.748 71.6817 151.5 71.2746C134.21 65.4649 128.915 77.0877 132.204 85.7711C133.428 88.9941 136.098 92.2101 138.861 94.8902C141.427 97.3601 144.176 99.6327 147.084 101.689C146.666 101.748 146.247 101.803 145.836 101.893C130.043 104.87 118.371 117.109 117.075 132.463C115.346 152.766 126.899 168.825 143.17 173.715C159.115 178.529 173.293 175.562 185.957 166.477Z"
          fill="#E09661"
        />
        <path
          d="M161.632 78.7982L162.047 77.7919L161.449 77.4219C161.508 77.8645 161.574 78.3244 161.632 78.7982Z"
          fill="#1D232A"
        />
        <path
          d="M150.767 101.759L151.383 103.792L161.609 78.8761C160.634 78.2295 159.683 77.6658 158.766 77.1816L144.934 94.953C146.704 97.3649 148.655 99.6399 150.767 101.759Z"
          fill="#1D232A"
        />
        <path
          d="M145.866 123.814L169.761 134.997C171.573 135.845 173.731 135.064 174.579 133.251L218.346 39.7328C219.195 37.92 218.413 35.7626 216.6 34.9142L192.705 23.7313C190.893 22.8828 188.735 23.6646 187.887 25.4775L144.12 118.995C143.271 120.808 144.053 122.966 145.866 123.814Z"
          fill="#42EC9A"
        />
        <path
          d="M145.877 123.8L169.769 134.984C170.201 135.186 170.668 135.3 171.144 135.321C171.62 135.342 172.096 135.269 172.543 135.106C172.991 134.942 173.402 134.692 173.754 134.37C174.105 134.048 174.389 133.66 174.589 133.227L204.153 70.0645L164.595 75.2517L144.13 118.983C143.723 119.854 143.679 120.85 144.006 121.753C144.334 122.657 145.006 123.393 145.877 123.8Z"
          fill="#1D232A"
        />
        <path
          d="M98.5243 101.654L157.041 129.04C159.16 130.032 161.682 129.118 162.674 126.999L205.922 34.5899C206.914 32.4709 206 29.9492 203.881 28.9574L145.364 1.57105C143.245 0.579335 140.723 1.49318 139.731 3.6122L96.4831 96.0214C95.4914 98.1404 96.4053 100.662 98.5243 101.654Z"
          fill="#00A868"
        />
        <path d="M108.013 92.4235L159.282 116.418L195.628 38.7578L144.359 14.7634L108.013 92.4235Z" fill="#FCFCFC" />
        <path d="M112.771 94.6507L159.286 116.42L195.632 38.7597L149.117 16.9905L112.771 94.6507Z" fill="#42EC9A" />
        <path
          d="M172.431 23.8707C173.698 23.8707 174.724 22.8442 174.724 21.5779C174.724 20.3117 173.698 19.2852 172.431 19.2852C171.165 19.2852 170.139 20.3117 170.139 21.5779C170.139 22.8442 171.165 23.8707 172.431 23.8707Z"
          fill="#1D232A"
        />
        <path
          d="M178.194 158.1C182.25 150.769 199.544 129.477 187.852 112.546C181.956 104.011 174.711 97.7142 169.268 94.626C166.27 93.1667 160.405 93.5886 157.331 98.4473C154.301 103.545 154.834 115.997 161.747 124.072C150.266 121.451 137.734 128.851 133.56 137.092"
          fill="#E09661"
        />
        <path
          d="M178.193 158.105C178.064 158.103 177.94 158.053 177.847 157.963C177.747 157.869 177.689 157.738 177.685 157.601C177.681 157.463 177.732 157.33 177.826 157.23C180.441 154.484 188.367 143.052 190.811 130.547C191.473 127.142 191.429 123.638 190.682 120.251C189.935 116.864 188.501 113.667 186.468 110.856C185.309 109.212 184.055 107.636 182.713 106.136C182.619 106.035 182.568 105.901 182.573 105.763C182.578 105.626 182.638 105.495 182.738 105.401C182.839 105.307 182.973 105.257 183.111 105.262C183.249 105.267 183.379 105.326 183.473 105.427C184.842 106.96 186.122 108.57 187.308 110.248C189.425 113.173 190.918 116.501 191.697 120.027C192.476 123.552 192.523 127.2 191.835 130.744C189.345 143.491 181.25 155.141 178.584 157.946C178.533 157.998 178.472 158.04 178.405 158.067C178.338 158.094 178.265 158.107 178.193 158.105Z"
          fill="#1D232A"
        />
        <path
          d="M65.8662 77.1319C97.1347 65.4918 128.223 64.7275 141.752 69.4064C146.51 71.0525 151.704 75.4132 149.975 80.8182C148.097 86.6971 142.841 88.8895 138.646 90.214C128.016 93.5614 111.884 94.8375 96.6955 100.474"
          fill="#E09661"
        />
        <path
          d="M85.1212 133.572C75.0926 140.309 52.5421 152.008 25.7692 138.175C14.5165 132.362 -9.46568 111.627 5.02044 78.263C10.5535 65.4955 25.5929 51.1201 44.6507 47.7657C53.9185 46.13 63.3557 47.2746 69.2484 55.8716C76.2546 54.6543 91.5153 58.7245 95.876 69.3583C97.159 73.0654 98.3693 77.4572 99.7733 81.1159C101.848 86.5314 103.498 92.2269 103.37 98.0297C103.246 102.4 102.5 106.731 101.153 110.89C98.2033 120.141 92.4905 128.617 85.1212 133.572Z"
          fill="#E09661"
        />
        <path
          d="M53.511 59.6315C53.3898 59.6291 53.2733 59.5847 53.1813 59.5059C53.0894 59.427 53.0277 59.3186 53.0068 59.1993C52.986 59.0799 53.0073 58.957 53.0671 58.8517C53.1269 58.7463 53.2214 58.665 53.3346 58.6217C59.9627 56.249 66.9689 55.1086 74.0073 55.257C74.1449 55.257 74.2768 55.3116 74.3741 55.4089C74.4714 55.5062 74.526 55.6381 74.526 55.7757C74.526 55.9133 74.4714 56.0452 74.3741 56.1425C74.2768 56.2398 74.1449 56.2944 74.0073 56.2944C67.0949 56.151 60.2144 57.2714 53.7046 59.6004C53.6428 59.6236 53.5769 59.6342 53.511 59.6315Z"
          fill="#1D232A"
        />
        <path
          d="M69.9537 75.9427C69.8317 75.9426 69.7137 75.8995 69.6204 75.821C69.5271 75.7425 69.4644 75.6337 69.4433 75.5136C69.4223 75.3934 69.4442 75.2698 69.5053 75.1642C69.5664 75.0587 69.6627 74.978 69.7773 74.9364C76.3478 72.5849 88.797 69.9152 95.6026 68.532C95.7375 68.5045 95.8777 68.5316 95.9925 68.6075C96.1073 68.6834 96.1872 68.8017 96.2147 68.9366C96.2422 69.0714 96.2151 69.2116 96.1392 69.3264C96.0633 69.4412 95.945 69.5211 95.8101 69.5486C89.0149 70.9319 76.6451 73.5843 70.1266 75.9116C70.0712 75.9322 70.0127 75.9428 69.9537 75.9427Z"
          fill="#1D232A"
        />
        <path
          d="M101.157 110.891C98.5253 106.645 94.5865 104.387 89.7451 103.536C94.1706 101.484 98.7254 99.7234 103.381 98.2656C103.255 102.56 102.506 106.813 101.157 110.891Z"
          fill="#1D232A"
        />
        <path
          d="M100.334 112.478C100.242 112.477 100.152 112.453 100.073 112.407C99.9933 112.361 99.9272 112.295 99.881 112.215C96.454 106.136 89.759 104.376 89.6933 104.358C89.6227 104.346 89.5553 104.32 89.4954 104.281C89.4355 104.242 89.3844 104.19 89.3452 104.131C89.306 104.071 89.2797 104.003 89.2679 103.933C89.256 103.862 89.259 103.79 89.2764 103.72C89.2939 103.651 89.3256 103.586 89.3694 103.529C89.4133 103.473 89.4684 103.426 89.5313 103.391C89.5941 103.357 89.6634 103.336 89.7348 103.33C89.8061 103.324 89.8779 103.333 89.9458 103.355C90.2362 103.428 97.1318 105.226 100.787 111.703C100.853 111.824 100.87 111.966 100.833 112.098C100.796 112.231 100.709 112.344 100.59 112.412C100.511 112.455 100.423 112.478 100.334 112.478Z"
          fill="#1D232A"
        />
        <path
          d="M133.56 137.614C133.478 137.613 133.398 137.593 133.325 137.555C133.204 137.493 133.112 137.386 133.069 137.256C133.026 137.127 133.036 136.986 133.097 136.864C135.67 131.787 141.241 127.15 147.638 124.76C147.763 124.731 147.894 124.748 148.006 124.809C148.119 124.87 148.204 124.97 148.247 125.091C148.29 125.211 148.287 125.344 148.238 125.462C148.19 125.58 148.099 125.676 147.984 125.732C141.818 128.035 136.465 132.482 134.006 137.334C133.964 137.416 133.9 137.486 133.821 137.535C133.743 137.584 133.653 137.612 133.56 137.614Z"
          fill="#1D232A"
        />
      </g>
      <defs>
        <clipPath id="clip0_3278_14100">
          <rect width="218" height="176" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
