export function IdentityIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={40} height={51} fill="none" {...props}>
      <g clipPath="url(#prefix__clip0_14391_92170)">
        <path
          d="M38.83 45.517L9.861 50.96a1.422 1.422 0 01-1.664-1.126L.036 7.133a1.411 1.411 0 011.135-1.65L30.14.04a1.422 1.422 0 011.664 1.125l8.161 42.702a1.416 1.416 0 01-1.135 1.65z"
          fill="#E8E9EA"
        />
        <path
          d="M38.106 44.515l-27.94 5.249a.755.755 0 01-.883-.598L1.293 7.361a.75.75 0 01.602-.876l27.94-5.249c.41-.076.805.19.883.597l7.99 41.806a.75.75 0 01-.602.876z"
          fill="#008E5A"
        />
        <path
          d="M36.076 42.833l-24.642 4.629a.674.674 0 01-.79-.535l-7.26-37.98a.669.669 0 01.54-.783l24.642-4.629c.367-.07.722.17.79.535l7.26 37.98a.672.672 0 01-.54.783z"
          fill="#00A868"
        />
        <path
          d="M23.84 8.137l2.664 13.938a1.367 1.367 0 01-1.098 1.597L14.294 25.76a1.377 1.377 0 01-1.61-1.088L10.02 10.733a1.367 1.367 0 011.097-1.597L22.23 7.048c.748-.14 1.47.348 1.61 1.089zM28.833 41.607L17.72 43.696a1.377 1.377 0 01-1.61-1.089l-2.665-13.94a1.366 1.366 0 011.098-1.597l11.112-2.089a1.377 1.377 0 011.61 1.089l2.665 13.94a1.366 1.366 0 01-1.098 1.597z"
          fill="#E8E9EA"
        />
        <path
          d="M26.396 21.518l.107.559a1.367 1.367 0 01-1.098 1.597l-11.111 2.088a1.377 1.377 0 01-1.61-1.088l-.076-.398c.405-.974 1.24-1.89 2.896-2.2l7.538-1.416c1.478-.279 2.573.18 3.354.858z"
          fill="#FE6A34"
        />
        <path
          d="M16.217 17.606a1.597 1.597 0 01-1.093 1.763c-.003 0-.003 0-.005.002-.044.014-.09.026-.134.037-.008.002-.014.002-.021.005-.03.007-.06.011-.09.016-.022.002-.043.007-.064.007a1.472 1.472 0 01-.348.003l-.023-.002a1.604 1.604 0 01-1.404-1.342 1.599 1.599 0 011.346-1.823 1.623 1.623 0 011.217.304c.276.207.488.503.584.853.014.058.025.117.035.177zM23.384 15.052a1.597 1.597 0 01-1.093 1.762c-.003 0-.003 0-.005.002-.044.014-.09.026-.134.038l-.021.004c-.03.007-.06.012-.09.016-.022.003-.043.007-.064.007a1.472 1.472 0 01-.348.003l-.023-.002a1.604 1.604 0 01-1.404-1.342 1.599 1.599 0 011.346-1.823 1.617 1.617 0 011.8 1.157c.015.058.026.115.036.178z"
          fill="#8F5049"
        />
        <path
          d="M19.668 20.174a4.072 4.072 0 01-4.473-1.134l-.002-.003a3.888 3.888 0 01-.212-.264l-.056-.078c-.02-.032-.044-.064-.065-.097l-.042-.064c-.02-.035-.042-.07-.063-.104a3.971 3.971 0 01-.533-2.356c0-.005 0-.01.003-.014a4.016 4.016 0 012.622-3.41 4.078 4.078 0 014.49 1.153c.3.35.546.756.716 1.212.78 2.077-.288 4.387-2.385 5.16z"
          fill="#8F5049"
        />
        <path
          d="M21.337 13.803c-.448.122-1.152-.088-1.31-.751-.237.52-.76.998-1.257 1.086-.16.028-.962-.083-.714-.867-.17.544-1.75 2.038-2.74 1.593-.18.891-.77 1.507-1.105 1.452.002-.08.007-.161.014-.242 0-.005 0-.01.002-.014a4.016 4.016 0 012.623-3.41 4.078 4.078 0 013.908.602c.207.162.402.346.579.55z"
          fill="#1D232A"
        />
        <path
          d="M15.182 9.482c.849.738 1.642 2.981.63 4.127-1.013 1.145-3.45.758-4.298.02-.848-.737-.714-2.264.299-3.41 1.015-1.143 2.523-1.472 3.37-.737z"
          fill="#1D232A"
        />
        <path
          d="M21.418 20.963c.208 1.094-.591 2.163-1.784 2.388-1.193.225-2.33-.482-2.538-1.575l4.322-.813z"
          fill="#8F5049"
        />
        <path
          d="M14.973 20.646c.296-.055.464-.477.375-.942-.089-.464-.4-.796-.697-.74-.296.055-.464.477-.375.942.089.464.4.796.697.74zM23.563 17.257c.24-.18.203-.632-.085-1.01-.287-.377-.715-.537-.955-.357-.24.18-.203.632.084 1.01.288.377.715.537.956.357z"
          fill="#F1BD38"
        />
        <path
          d="M25.662 38.971c-.017 0-.033 0-.05-.002a.346.346 0 01-.297-.388c.071-.516.114-1 .14-1.623a.348.348 0 01.693.028c-.027.645-.07 1.152-.146 1.688a.344.344 0 01-.34.297zM17.514 38.296a.344.344 0 01-.34-.423 9.54 9.54 0 01.07-.27c.185-.702.53-2.008.505-4.986-.011-1.218.357-2.187 1.087-2.881.758-.72 1.897-1.092 3.13-1.03 1.507.079 3.43 1.022 4.04 5.063.073.476.14 1.443.166 1.977a.346.346 0 01-.332.362.341.341 0 01-.365-.329c-.024-.504-.09-1.46-.158-1.906-.434-2.874-1.574-4.38-3.387-4.476-1.04-.056-1.992.252-2.61.837-.587.558-.88 1.357-.872 2.377.026 3.073-.348 4.49-.528 5.169a6.351 6.351 0 00-.063.248.358.358 0 01-.343.268z"
          fill="#1D232A"
        />
        <path
          d="M19.211 39.617a.333.333 0 01-.123-.023.344.344 0 01-.205-.444 6.84 6.84 0 00.36-1.465.35.35 0 01.394-.292c.19.027.323.202.295.39a7.544 7.544 0 01-.396 1.608.348.348 0 01-.325.226zM23.562 39.7a.345.345 0 01-.342-.423c.339-1.44.468-3.183.348-4.66-.08-.976-.37-2.235-.768-2.754-.25-.327-.656-.52-1.118-.535-.439-.018-.84.144-1.076.416-.383.443-.373.898-.363 1.527v.069c.024 1.312.039 2.104-.155 3.068a.35.35 0 01-.41.27.345.345 0 01-.273-.407c.18-.888.168-1.611.142-2.919V33.3c-.012-.677-.025-1.36.529-2.004.37-.43.978-.676 1.626-.659.673.02 1.275.314 1.652.808.574.752.84 2.312.905 3.116a16.524 16.524 0 01-.363 4.873.34.34 0 01-.334.265z"
          fill="#1D232A"
        />
        <path
          d="M21.083 40.34a.345.345 0 01-.328-.462c.728-2.033.982-4.517.698-6.814a.347.347 0 01.303-.385.35.35 0 01.388.301c.299 2.398.031 4.998-.731 7.13a.353.353 0 01-.33.23zM33.439 37.735a.345.345 0 01-.342-.28l-5.065-26.503a.348.348 0 01.685-.128l5.065 26.503a.345.345 0 01-.278.403c-.021.002-.042.005-.065.005z"
          fill="#1D232A"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_14391_92170">
          <path fill="#fff" d="M0 0h40v51H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
