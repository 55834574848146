export function OiIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="32" height="32" fill="white" />
      <path
        d="M5 15.6357C5 22.1045 10.4855 26 17.1845 26C23.3078 26 27.999 21.5713 27.999 17.9915C27.999 14.6314 25.8813 13.0121 23.135 11.1926C20.6669 9.56152 18.782 6 13.7039 6C7.99966 6 5 11.2057 5 15.6357Z"
        fill="url(#paint0_linear_2841_10510)"
      />
      <path
        d="M14.3735 18.804C13.6639 18.804 13.2709 18.0756 13.2709 17.3378C13.2709 16.6 13.6639 15.8928 14.3735 15.8928C15.0831 15.8928 15.4733 16.6002 15.4733 17.3378C15.4733 18.0753 15.0812 18.804 14.3735 18.804ZM14.3735 14.5887C12.8252 14.5887 11.7951 15.8114 11.7951 17.3452C11.7951 18.8789 12.8252 20.1026 14.3735 20.1026C15.9177 20.1026 16.9458 18.8809 16.9458 17.3452C16.9458 15.8094 15.9177 14.5895 14.3735 14.5895M18.5729 14.5924C18.0527 14.5924 17.6302 15.8262 17.6302 17.3489C17.6302 18.8715 18.0527 20.1053 18.5729 20.1053C19.0931 20.1053 19.5144 18.8715 19.5144 17.3489C19.5144 15.8262 19.0929 14.5924 18.5729 14.5924ZM18.5608 13.9081C18.7438 13.9084 18.9228 13.8545 19.075 13.7533C19.2273 13.652 19.3461 13.5079 19.4163 13.3392C19.4866 13.1705 19.5051 12.9848 19.4696 12.8056C19.4341 12.6264 19.3462 12.4617 19.2169 12.3324C19.0876 12.2031 18.9229 12.115 18.7434 12.0792C18.564 12.0434 18.378 12.0616 18.2089 12.1314C18.0398 12.2012 17.8952 12.3195 17.7935 12.4714C17.6918 12.6232 17.6374 12.8018 17.6374 12.9845C17.6372 13.1057 17.661 13.2257 17.7073 13.3377C17.7536 13.4498 17.8216 13.5516 17.9074 13.6374C17.9931 13.7231 18.095 13.7912 18.2071 13.8377C18.3192 13.8841 18.4394 13.9081 18.5608 13.9081Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2841_10510"
          x1="8.76689"
          y1="23.4959"
          x2="22.4432"
          y2="9.79732"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFFF00" />
          <stop offset="0.31" stopColor="#00D217" />
          <stop offset="0.5" stopColor="#00D217" />
          <stop offset="0.69" stopColor="#00D217" />
          <stop offset="1" stopColor="#00CDFF" />
        </linearGradient>
      </defs>
    </svg>
  )
}
