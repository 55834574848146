export function CoinsStackSuccessIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={90} height={88} viewBox="0 0 109 109" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_8598_18286)">
        <path
          d="M54.5803 23.6029C54.5803 25.0041 54.4504 26.3779 54.2083 27.7024C54.1198 28.2141 54.0056 28.7179 53.8816 29.2197C53.7222 29.8633 53.5274 30.499 53.3089 31.1248C53.1495 31.5932 52.9704 32.0538 52.7697 32.5123C52.6614 32.7918 52.5335 33.0713 52.4016 33.3488C52.193 33.7857 51.9785 34.2186 51.7364 34.6477C51.5928 34.9094 51.4511 35.1653 51.2916 35.4172C47.2807 42.0929 39.9733 46.5585 31.6345 46.5565L23.3253 46.5545L23.2427 20.3221L23.1797 3.20971L25.256 3.20774L40.9652 2.59961C41.2978 2.74918 41.6245 2.90466 41.9531 3.06998C42.518 3.35141 43.071 3.65646 43.6004 3.9871C38.2827 4.24295 32.9649 4.50273 27.6452 4.76252C26.9879 4.79401 26.9997 5.81937 27.661 5.78985C33.4471 5.50448 39.2431 5.22305 45.0332 4.93768C45.0902 4.98098 45.1513 5.02427 45.2083 5.06757C49.731 8.37787 52.9803 13.3118 54.1316 19.0133C54.4248 20.4914 54.5823 22.0284 54.5803 23.6029Z"
          fill="#1D232A"
        />
        <path
          d="M44.8068 31.6643C40.3295 43.5259 27.0981 49.5285 15.2523 45.0748C12.1526 43.9116 9.45829 42.1463 7.24421 39.9637C5.25842 38.0035 3.66429 35.7107 2.5169 33.2112L7.9842 29.0684C7.69883 28.2655 7.47841 27.4408 7.32096 26.5985L1.61552 30.9224C1.45414 30.4422 1.3085 29.958 1.18058 29.4699C1.20616 29.4581 1.22978 29.4424 1.25536 29.4247L6.96278 25.0988L7.03953 23.8176C7.03953 23.8176 6.58884 24.1542 6.37235 24.3175L0.930633 28.4387C0.682656 27.2893 0.52521 26.1183 0.458295 24.9355C0.283137 21.8653 0.727921 18.7065 1.86744 15.6383C1.88318 15.6029 1.89302 15.5675 1.90876 15.532C5.15215 6.9414 12.989 1.42293 21.5659 0.724261C22.0992 0.680963 22.6365 0.655378 23.1758 0.651442L31.5873 0.641602H31.6975C33.6361 0.64357 35.5215 0.883674 37.3164 1.3383C38.1331 1.54691 38.9361 1.79686 39.7115 2.08617C40.1327 2.24558 40.5519 2.41877 40.9652 2.59983L33.2916 2.89701C34.2029 3.33196 35.0728 3.82201 35.8993 4.35929C38.4657 4.2353 41.034 4.11131 43.6004 3.98732C43.6083 3.98732 43.6122 3.99323 43.6181 3.9952C44.1062 4.29238 44.5785 4.6053 45.0332 4.9379C42.4432 5.06583 39.8512 5.19178 37.2593 5.31774C39.8473 7.27991 41.9472 9.75378 43.4666 12.5327C46.5702 18.1968 47.2709 25.1382 44.8068 31.6643Z"
          fill="#42EC9A"
        />
        <path
          d="M12.3351 34.7933L12.3342 34.7924C10.7162 33.1975 9.47795 31.2779 8.68495 29.1794C8.48005 28.6352 8.30209 28.0808 8.15833 27.5174L31.2589 10.0223C31.7662 10.3134 32.2521 10.6333 32.7166 10.977C34.5553 12.3397 36.0432 14.0712 37.1147 16.026C37.2212 16.2209 37.3242 16.4185 37.4232 16.6174L12.8172 35.2488C12.6531 35.1017 12.4922 34.9491 12.3351 34.7933Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
          strokeWidth="0.787229"
        />
        <path
          d="M2.2772 31.6162C2.20902 31.4326 2.1427 31.2493 2.07823 31.065L7.06128 27.29C7.18606 27.8433 7.33827 28.3899 7.51856 28.9264L2.67446 32.5969C2.53405 32.2726 2.40104 31.9455 2.2772 31.6162Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
          strokeWidth="0.787229"
        />
        <mask id="path-5-inside-1_8598_18286" fill="white">
          <path d="M1.61525 30.9238C1.61919 30.9376 1.62312 30.9494 1.62706 30.9632C1.62115 30.9494 1.61722 30.9376 1.61328 30.9238H1.61525Z" />
        </mask>
        <path
          d="M1.61525 30.9238C1.61919 30.9376 1.62312 30.9494 1.62706 30.9632C1.62115 30.9494 1.61722 30.9376 1.61328 30.9238H1.61525Z"
          fill="#FCFCFC"
        />
        <path
          d="M1.61525 30.9238L2.37218 30.7075L2.20903 30.1366H1.61525V30.9238ZM1.62706 30.9632L0.903502 31.2733L2.38399 30.7469L1.62706 30.9632ZM1.61328 30.9238V30.1366H0.569572L0.856353 31.1401L1.61328 30.9238ZM0.858321 31.1401C0.862796 31.1558 0.867044 31.1695 0.868743 31.175C0.870981 31.1823 0.870669 31.1814 0.87013 31.1795L2.38399 30.7469C2.37951 30.7312 2.37526 30.7175 2.37356 30.712C2.37133 30.7047 2.37164 30.7056 2.37218 30.7075L0.858321 31.1401ZM2.35061 30.653C2.35508 30.6634 2.35941 30.6746 2.36334 30.686C2.36711 30.6969 2.36945 30.7048 2.37021 30.7075L0.856353 31.1401C0.866838 31.1768 0.88126 31.2215 0.903502 31.2733L2.35061 30.653ZM1.61328 31.7111H1.61525V30.1366H1.61328V31.7111Z"
          fill="#FCFCFC"
          mask="url(#path-5-inside-1_8598_18286)"
        />
        <path
          d="M22.3691 34.3184C21.511 34.3184 20.6234 34.1629 19.7496 33.85C16.4983 32.6829 14.7861 29.2546 15.0085 26.6173C15.0223 26.454 15.164 26.3379 15.3273 26.3477C15.4907 26.3615 15.6107 26.5052 15.597 26.6666C15.3923 29.0833 16.9648 32.2224 19.9484 33.295C22.0975 34.0665 24.3175 33.7969 25.886 32.5767C27.0708 31.6536 27.6711 30.3075 27.5333 28.8806C27.2716 26.1903 25.6144 25.0094 23.5145 23.5176L23.3216 23.3818C21.1764 21.8527 19.3934 20.3963 19.3127 18.0307C19.2595 16.4778 19.9621 15.053 21.2394 14.1221C22.7529 13.018 24.8548 12.7897 26.8602 13.51C29.2495 14.3681 30.5504 16.4897 30.9696 18.1901C31.0089 18.3475 30.9125 18.5089 30.7531 18.5483C30.5937 18.5876 30.4342 18.4912 30.3949 18.3318C30.0111 16.7809 28.8283 14.8463 26.6575 14.067C24.8095 13.4037 22.9595 13.5986 21.5838 14.6003C20.4679 15.4151 19.8539 16.6589 19.8992 18.011C19.972 20.1109 21.574 21.4157 23.6621 22.9016L23.853 23.0374C25.9884 24.5548 27.8305 25.8655 28.1178 28.8255C28.2772 30.4571 27.5943 31.9961 26.2462 33.0451C25.1657 33.8835 23.8078 34.3184 22.3691 34.3184Z"
          fill="#1D232A"
        />
        <path
          d="M18.8954 36.1266C18.862 36.1266 18.8285 36.1207 18.7951 36.1089C18.6416 36.0538 18.5609 35.8845 18.616 35.731L27.3247 11.38C27.3798 11.2265 27.5491 11.1458 27.7026 11.2009C27.8561 11.256 27.9368 11.4253 27.8817 11.5788L19.1729 35.9317C19.1296 36.0518 19.0175 36.1266 18.8954 36.1266Z"
          fill="#1D232A"
        />
        <path
          d="M1.36892 28.6003L6.59586 24.6418L6.58073 24.8943L1.41733 28.8078C1.40076 28.7387 1.38461 28.6695 1.36892 28.6003Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
          strokeWidth="0.787229"
        />
        <path
          d="M12.0953 35.7064C12.0225 35.7064 11.9497 35.6788 11.8926 35.6257C10.023 33.8485 8.57643 31.6147 7.70654 29.1665C6.59655 26.0412 6.44501 22.6935 7.27357 19.4835C7.41723 18.9226 7.60223 18.3401 7.82069 17.7536C8.50361 15.9469 9.47584 14.2977 10.7079 12.8531C10.8141 12.7291 10.9991 12.7134 11.1231 12.8196C11.2471 12.9259 11.2629 13.1109 11.1566 13.2349C9.96983 14.6283 9.03302 16.2185 8.37372 17.9602C8.16117 18.527 7.98404 19.0879 7.84628 19.6292C7.04724 22.7249 7.19287 25.9526 8.26351 28.9657C9.10191 31.3254 10.4973 33.4805 12.3 35.1947C12.4181 35.3069 12.422 35.4938 12.3099 35.6119C12.2508 35.6749 12.1741 35.7064 12.0953 35.7064Z"
          fill="#1D232A"
        />
        <path
          d="M69.9431 32.3135V88.4527H4.61294V74.1862H0.157227V60.5179H4.61885V46.5564H46.6215V32.3135H69.9431Z"
          fill="#42EC9A"
        />
        <path
          d="M74.8731 88.0601V32.7081H80.4782V88.0601H74.8731Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
          strokeWidth="0.787229"
        />
        <path
          d="M70.337 88.0601V32.7081H71.8977V88.0601H70.337Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
          strokeWidth="0.787229"
        />
        <path
          d="M85.6919 45.9827V60.5228H90.1574V74.1931H85.6919V88.4537H80.8721V32.3145H90.1574V45.9827H85.6919Z"
          fill="#42EC9A"
        />
        <path d="M74.4795 32.3145H72.293V88.4537H74.4795V32.3145Z" fill="#42EC9A" />
        <path d="M46.6214 59.9414H44.3896V74.5681H46.6214V59.9414Z" fill="#1D232A" />
        <path
          d="M36.5132 88.453H44.3914V46.5566H42.2757L40.4946 59.5066L40.284 61.0338L38.5029 73.9798L38.2923 75.507L36.5132 88.453Z"
          fill="#1D232A"
        />
        <path
          d="M85.6916 59.9297V60.5201H4.6188C4.6129 60.5201 4.60503 60.5201 4.59912 60.5182H4.6188V59.9297H85.6916Z"
          fill="#1D232A"
        />
        <path d="M85.6918 45.9658H46.6216V46.5563H85.6918V45.9658Z" fill="#1D232A" />
        <path d="M85.6915 74.1904H4.61279V74.7809H85.6915V74.1904Z" fill="#1D232A" />
      </g>
      <g filter="url(#filter0_d_8598_18286)">
        <path
          d="M83.8002 104.359C95.9739 104.359 105.843 94.4905 105.843 82.3168C105.843 70.1431 95.9739 60.2744 83.8002 60.2744C71.6265 60.2744 61.7578 70.1431 61.7578 82.3168C61.7578 94.4905 71.6265 104.359 83.8002 104.359Z"
          fill="#E8E9EA"
        />
      </g>
      <path
        d="M80.1412 93.4378C79.5803 93.4378 79.0158 93.2342 78.5716 92.8233L71.2272 86.036C70.2888 85.1679 70.2314 83.7057 71.0977 82.7673C71.9657 81.8289 73.4279 81.7715 74.3664 82.6378L80.0116 87.8555L93.1031 73.6886C93.9711 72.7502 95.4333 72.6929 96.3717 73.5591C97.3102 74.4272 97.3675 75.8894 96.5013 76.8278L81.8403 92.6937C81.385 93.1879 80.7649 93.4378 80.1412 93.4378Z"
        fill="#00A868"
      />
      <defs>
        <filter
          id="filter0_d_8598_18286"
          x="59.3961"
          y="59.4872"
          width="48.8083"
          height="48.8083"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.57446" />
          <feGaussianBlur stdDeviation="1.18084" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.290196 0 0 0 0 0.309804 0 0 0 0 0.333333 0 0 0 0.32 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8598_18286" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8598_18286" result="shape" />
        </filter>
        <clipPath id="clip0_8598_18286">
          <rect width="90" height="87.8114" fill="white" transform="translate(0.157227 0.641602)" />
        </clipPath>
      </defs>
    </svg>
  )
}
