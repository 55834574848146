export const OpenFinanceIllustration = () => (
  <svg width="140" height="23" viewBox="0 0 140 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M131.347 12.3912C131.347 13.9836 131.691 15.2029 132.381 16.0512C133.07 16.8996 134.076 17.3238 135.402 17.3238C136.142 17.3238 136.766 17.2066 137.273 16.9743C137.781 16.7419 138.303 16.3582 138.836 15.8253L139.817 16.5991C139.507 16.9082 139.192 17.1832 138.874 17.4198C138.555 17.6585 138.22 17.8568 137.868 18.0166C137.514 18.1765 137.133 18.2937 136.719 18.3705C136.306 18.4451 135.841 18.4835 135.325 18.4835C133.603 18.4835 132.292 17.942 131.388 16.857C130.484 15.772 130.032 14.1882 130.032 12.1056C130.032 10.0911 130.471 8.52439 131.35 7.40527C132.228 6.28615 133.449 5.72766 135.016 5.72766C136.583 5.72766 137.804 6.26058 138.682 7.32853C139.561 8.39649 140 9.88438 140 11.7965V12.3912H131.347ZM138.629 10.5708C138.612 10.3555 138.587 10.1658 138.552 10.0016C138.518 9.83748 138.476 9.67548 138.422 9.51134C138.371 9.34721 138.309 9.17241 138.241 8.98269C137.966 8.32827 137.54 7.81668 136.962 7.44577C136.385 7.07486 135.737 6.89154 135.012 6.89154C134.289 6.89154 133.639 7.077 133.061 7.44577C132.484 7.81668 132.057 8.32827 131.782 8.98269C131.695 9.17241 131.631 9.34934 131.588 9.51134C131.546 9.67548 131.507 9.83962 131.471 10.0016C131.437 10.1658 131.411 10.3555 131.394 10.5708C131.377 10.7861 131.36 11.0397 131.343 11.3318H138.678C138.663 11.0397 138.646 10.7861 138.629 10.5708Z"
      fill="#3F3F3F"
    />
    <path
      d="M74.0083 7.04712V18.4856H72.6952V7.04712H70.9941V6.0687H72.6952V3.38921C72.6952 2.44489 72.9489 1.66683 73.4562 1.05718C73.9635 0.447528 74.7053 0.1427 75.6838 0.1427H78.8152V1.30232H75.9161C75.2638 1.30232 74.7821 1.48351 74.473 1.84376C74.1639 2.20401 74.0083 2.71134 74.0083 3.36363V6.0687H75.7776V7.04712H74.0083Z"
      fill="#3F3F3F"
    />
    <path d="M80.1647 6.0686H78.8516V18.4855H80.1647V6.0686Z" fill="#3F3F3F" />
    <path
      d="M103.645 17.2407C103.012 17.8183 102.404 18.0208 101.763 18.234C101.087 18.4258 100.371 18.4621 99.4947 18.4621C98.5674 18.4621 97.8234 18.3768 97.2671 18.2042C96.7086 18.0336 96.2332 17.7395 95.8368 17.328C95.5447 17.036 95.3209 16.6758 95.1674 16.2452C95.0118 15.8167 94.9351 15.3691 94.9351 14.9044C94.9351 13.7703 95.3166 12.8921 96.0819 12.2632C96.845 11.6365 97.9066 11.3232 99.2644 11.3232H103.283V9.93122C103.283 8.93574 103.016 8.179 102.483 7.66314C101.95 7.14728 101.049 6.88935 99.7782 6.88935C98.9021 6.88935 98.2114 7.00872 97.7041 7.2496C97.1967 7.49048 96.747 7.89336 96.3526 8.46038L95.3998 7.63543C95.9327 6.89788 96.5423 6.39481 97.2287 6.12835C97.9151 5.8619 98.7656 5.72974 99.7782 5.72974C101.426 5.72974 102.641 6.08146 103.423 6.78703C104.203 7.49048 104.596 8.49662 104.596 9.80119V14.8894C104.542 15.6909 104.429 16.3987 103.645 17.2407ZM103.28 12.3826H99.4158C97.3033 12.3826 96.246 13.2161 96.246 14.8809C96.246 15.7059 96.504 16.3155 97.0198 16.7099C97.5357 17.1042 98.3585 17.3025 99.4925 17.3025C100.06 17.3025 100.603 17.2513 101.128 17.149C101.652 17.0467 102.136 16.7802 102.583 16.3496C103.048 15.9212 103.278 15.1303 103.278 13.9792V12.3826H103.28Z"
      fill="#3F3F3F"
    />
    <path
      d="M127.607 17.4688C127.298 17.7182 126.984 17.9164 126.667 18.0613C126.349 18.2084 126.014 18.3108 125.663 18.3704C125.311 18.4301 124.919 18.46 124.49 18.46C123.648 18.46 122.889 18.3129 122.209 18.023C121.532 17.7309 120.956 17.311 120.483 16.761C120.01 16.2111 119.649 15.5417 119.4 14.7509C119.15 13.9601 119.027 13.0754 119.027 12.097C119.027 11.1186 119.15 10.2339 119.4 9.44308C119.649 8.65437 120.01 7.9829 120.483 7.43294C120.956 6.88297 121.529 6.46303 122.209 6.17099C122.887 5.87896 123.648 5.73401 124.49 5.73401C124.919 5.73401 125.311 5.76385 125.663 5.82353C126.014 5.88322 126.349 5.98767 126.667 6.13263C126.984 6.27758 127.298 6.47582 127.607 6.72523C127.916 6.97463 128.242 7.27946 128.585 7.63971L127.683 8.46465C127.151 7.88058 126.65 7.47344 126.176 7.24109C125.703 7.00874 125.143 6.89363 124.488 6.89363C123.783 6.89363 123.152 7.03218 122.595 7.30504C122.037 7.58002 121.57 7.99142 121.19 8.54139C120.881 8.98904 120.662 9.48145 120.534 10.0229C120.406 10.5643 120.34 11.255 120.34 12.097C120.34 12.939 120.404 13.6296 120.534 14.1711C120.664 14.7125 120.881 15.2049 121.19 15.6526C121.568 16.2026 122.037 16.614 122.595 16.8889C123.154 17.1639 123.785 17.3004 124.488 17.3004C124.814 17.3004 125.115 17.2748 125.39 17.2236C125.665 17.1725 125.927 17.0872 126.176 16.9657C126.426 16.8463 126.675 16.6822 126.922 16.4754C127.172 16.2686 127.426 16.0214 127.681 15.7272L128.583 16.5521C128.242 16.9167 127.916 17.2215 127.607 17.4688Z"
      fill="#3F3F3F"
    />
    <path
      d="M115.275 7.09853C114.45 6.30769 113.352 5.91333 111.977 5.91333C111.913 5.91333 111.852 5.91973 111.788 5.92186C111.724 5.91973 111.662 5.91333 111.598 5.91333C110.225 5.91333 109.125 6.30769 108.3 7.09853C107.441 7.92348 107.013 9.07457 107.013 10.5497V18.4837H108.326V10.782C108.326 9.57977 108.618 8.66103 109.202 8.0258C109.786 7.39057 110.636 7.07295 111.751 7.07295C111.764 7.07295 111.775 7.07508 111.785 7.07508C111.798 7.07508 111.809 7.07295 111.82 7.07295C112.937 7.07295 113.785 7.39057 114.369 8.0258C114.953 8.66103 115.245 9.57977 115.245 10.782V18.4837H116.558V10.5497C116.562 9.07457 116.132 7.92348 115.275 7.09853Z"
      fill="#3F3F3F"
    />
    <path
      d="M91.147 7.09853C90.3221 6.30769 89.2243 5.91333 87.8494 5.91333C87.7854 5.91333 87.7236 5.91973 87.6597 5.92186C87.5957 5.91973 87.5339 5.91333 87.4699 5.91333C86.0972 5.91333 84.9972 6.30769 84.1723 7.09853C83.3132 7.92348 82.8848 9.07457 82.8848 10.5497V18.4837H84.1979V10.782C84.1979 9.57977 84.4899 8.66103 85.074 8.0258C85.658 7.39057 86.5086 7.07295 87.6234 7.07295C87.6362 7.07295 87.6469 7.07508 87.6575 7.07508C87.6703 7.07508 87.681 7.07295 87.6916 7.07295C88.8086 7.07295 89.657 7.39057 90.2411 8.0258C90.8252 8.66103 91.1172 9.57977 91.1172 10.782V18.4837H92.4303V10.5497C92.4346 9.07457 92.0061 7.92348 91.147 7.09853Z"
      fill="#3F3F3F"
    />
    <path
      d="M52.3936 11.8672H39.9235C40.1025 12.9671 40.5843 13.8411 41.3666 14.4913C42.1489 15.1414 43.1465 15.4654 44.3594 15.4654C45.8089 15.4654 47.056 14.9581 48.0983 13.9434L51.3683 15.4782C50.5519 16.6357 49.5756 17.4905 48.4394 18.0447C47.3011 18.599 45.9518 18.8761 44.3871 18.8761C41.9613 18.8761 39.9831 18.1108 38.4569 16.5803C36.9306 15.0498 36.1675 13.1313 36.1675 10.8291C36.1675 8.46935 36.9285 6.50823 38.4505 4.94999C39.9725 3.39176 41.8803 2.61157 44.174 2.61157C46.6104 2.61157 48.5908 3.39176 50.117 4.94999C51.6433 6.51036 52.4064 8.56954 52.4064 11.1275L52.3936 11.8672ZM48.5119 8.81041C48.2561 7.94709 47.7487 7.24578 46.9941 6.70647C46.2395 6.16717 45.3634 5.89645 44.3658 5.89645C43.2829 5.89645 42.3344 6.19914 41.5179 6.80666C41.0042 7.1861 40.531 7.8533 40.094 8.81255H48.5119V8.81041Z"
      fill="#3F3F3F"
    />
    <path
      d="M54.9365 4.58302H58.8716V4.57875C59.7647 3.82628 60.5726 3.30402 61.2974 3.00986C62.0221 2.71569 62.7639 2.5686 63.5228 2.5686C65.0768 2.5686 66.3963 3.11218 67.4813 4.19719C68.3936 5.12019 68.8477 6.48445 68.8477 8.29209V18.6583H64.9553V11.7901C64.9553 9.91854 64.8722 8.67579 64.7038 8.05974C64.5354 7.44583 64.2433 6.97686 63.8255 6.65498C63.4077 6.3331 62.894 6.17323 62.2801 6.17323C61.485 6.17323 60.8007 6.43968 60.2315 6.9726C59.6603 7.50551 59.2659 8.24306 59.0442 9.18312C58.9291 9.6734 58.8716 10.7328 58.8716 12.3657V18.6583H54.9365V4.58302Z"
      fill="#3F3F3F"
    />
    <path
      d="M22.8126 4.74721V4.70031C23.5246 3.98834 24.292 3.45543 25.119 3.09944C25.9461 2.74346 26.8286 2.56653 27.7708 2.56653C29.8172 2.56653 31.5566 3.34032 32.9891 4.8879C34.4216 6.43548 35.1378 8.42005 35.1378 10.8416C35.1378 13.1779 34.3981 15.1305 32.9188 16.7015C31.4394 18.2725 29.685 19.0591 27.6578 19.0591C26.754 19.0591 25.9141 18.8992 25.134 18.5752C24.3559 18.2534 23.5821 17.7268 22.8126 16.9957V22.4826H18.9053V4.74508H22.8126V4.74721ZM26.9501 6.201C25.7095 6.201 24.6821 6.62306 23.8656 7.46933C23.0492 8.31559 22.6399 9.41979 22.6399 10.7862C22.6399 12.1803 23.0492 13.3058 23.8656 14.167C24.6586 15.0026 25.6498 15.431 26.8414 15.4566C27.9222 15.4801 28.9901 15.0943 29.7767 14.3546C30.7381 13.4507 31.2177 12.2655 31.2177 10.8011C31.2177 9.45603 30.8148 8.35397 30.0069 7.49278C29.199 6.63159 28.1801 6.201 26.9501 6.201Z"
      fill="#3F3F3F"
    />
    <path
      d="M16.3022 6.60178C15.571 5.33345 14.5564 4.32944 13.2539 3.58975C12.1071 2.93747 10.8921 2.57296 9.6088 2.49622V6.20529C10.6533 6.29908 11.5252 6.71262 12.2286 7.44591C13.0322 8.28791 13.4351 9.37932 13.4351 10.7265C13.4351 12.0716 13.0258 13.1715 12.2073 14.0285C11.3887 14.8832 10.3634 15.3117 9.13131 15.3117C8.00366 15.3117 7.05721 14.9557 6.28769 14.2501L3.65723 16.8806C5.18349 18.2747 6.99539 18.9739 9.09507 18.9739C10.6235 18.9739 12.0218 18.6115 13.2902 17.8846C14.5713 17.1513 15.5796 16.1324 16.3171 14.8257C17.0142 13.5915 17.3872 12.191 17.3979 10.7734C17.4064 9.27913 17.0419 7.88717 16.3022 6.60178Z"
      fill="#3F3F3F"
    />
    <path
      d="M6.22407 7.32435C6.90407 6.67207 7.71623 6.29903 8.66694 6.20737V0C6.24539 0.176927 3.94747 1.34081 2.33594 3.43622L6.22407 7.32435Z"
      fill="#4A7E94"
    />
    <path
      d="M4.88097 10.3473C4.93426 9.46905 5.18153 8.70378 5.61852 8.04937L1.66644 4.09729C0.29792 6.00725 -0.194492 8.44372 0.0677017 10.3473H4.88097Z"
      fill="#4A7E94"
    />
    <path
      d="M4.89393 11.2871H0.0678711C0.436647 13.4848 1.66874 15.122 2.99463 16.2091L5.66559 13.5381C5.22647 12.905 4.97067 12.1547 4.89393 11.2871Z"
      fill="#4A7E94"
    />
  </svg>
)
