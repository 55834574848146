interface Props {
  fill?: string | any
  size?: number
}

export const AlertPhoneIllustration = ({ fill = 'white', size = 93 }: Props) => (
  <svg width={size} height={size} viewBox="0 0 93 79" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#prefix__alert_phone)">
      <path
        d="M33.73 77.862l-6.52 1.105a2.369 2.369 0 01-2.725-1.954L20.01 50.24l11.183-1.895 4.474 26.774a2.378 2.378 0 01-1.939 2.744z"
        fill="#00A868"
      />
      <path d="M57.999 41.476l8.343-11.279-3.152-8.085-13.735-2.553L58 41.476z" fill="#008E5A" />
      <path
        d="M56.884 43.09l9.458-12.894-1.262-3.238-10.326 10.67 2.13 5.463zM54.754 37.329c-.05 0-.1-.019-.138-.055a.21.21 0 01-.015-.295l7.183-8.027a.206.206 0 01.294-.015c.086.076.093.209.015.295L54.91 37.26a.211.211 0 01-.155.07z"
        fill="#1D232A"
      />
      <path
        d="M48.03 3.704l19.635 50.367c.719 1.845-.979 3.713-2.865 3.156L38.098 49.34 8.544 61.02 0 39.102l29.554-11.679 14.244-24.07c1.006-1.7 3.513-1.494 4.232.35zM61.65 27.357c.906 2.324 2.976 3.516 4.69 2.839l-3.17-8.133c-1.714.678-2.425 2.97-1.52 5.294z"
        fill="#00A868"
      />
      <path
        d="M10.58 47.569c1.97 5.054.797 10.477-2.036 13.451L0 39.103c4.086.243 8.61 3.415 10.58 8.466z"
        fill="#42EC9A"
      />
      <path
        d="M37.926 45.913a.208.208 0 01-.208-.205.21.21 0 01.204-.214l23.904-.407a.21.21 0 01.008.418l-23.904.408h-.004zM92.793 36.76a.236.236 0 01-.053-.007l-15.906-4.166a.21.21 0 01.105-.405l15.906 4.166a.21.21 0 01-.052.412zM75.492 21.624a.21.21 0 01-.076-.403l15.302-6.035a.207.207 0 01.269.118.21.21 0 01-.118.27L75.568 21.61a.209.209 0 01-.076.014zM64.8 57.228L38.098 49.34 31.8 51.828l.227 1.509-.138.051a.206.206 0 01-.11.263l-7.802 3.531 7.517-1.826a.207.207 0 01.245.138.207.207 0 01-.117.258l-7.411 3.102 6.268-1.348a.208.208 0 01.247.161.209.209 0 01-.16.248l-8.475 1.823a.205.205 0 01-.241-.142.208.208 0 01.117-.256l7.06-2.954-7.123 1.73a.209.209 0 01-.245-.135.21.21 0 01.112-.26l7.094-3.209L21.2 57.36l-.216-1.256-9.585 3.788c.795-.981 1.356-2.132 1.547-3.55l24.158-9.547 28.066.881 2.494 6.397c.72 1.844-.979 3.712-2.865 3.156zM69.513 14.143a.21.21 0 01-.173-.326L78.52.09a.207.207 0 01.288-.057.21.21 0 01.057.29l-9.18 13.726a.204.204 0 01-.172.093z"
        fill="#1D232A"
      />
    </g>
    <defs>
      <clipPath id="prefix__alert_phone">
        <path fill={fill} d="M0 0h93v79H0z" />
      </clipPath>
    </defs>
  </svg>
)
