import { memo } from 'react'

export const VerifyEmailClockIllustration = memo(function () {
  return (
    <svg width="133" height="152" viewBox="0 0 133 152" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_12673_25520)">
        <path
          d="M115.428 44.585L60.8075 1.5725C58.9925 0.1425 56.435 0.1425 54.62 1.5725L0 44.585V85.9001H115.428V44.585Z"
          fill="#008E5A"
        />
        <path d="M115.428 61.1199L0 61.9224L16.5225 44.585H102.77L115.428 61.1199Z" fill="#1D232A" />
        <path
          d="M109.895 50.61C109.812 50.61 109.73 50.5825 109.662 50.53L102.64 44.965H16.6398L9.64225 49.745C9.47225 49.8625 9.23726 49.8175 9.12226 49.6475C9.00476 49.4775 9.04975 49.2425 9.21975 49.1275L16.3123 44.2825C16.3748 44.24 16.4473 44.2175 16.5248 44.2175H102.772C102.857 44.2175 102.94 44.245 103.005 44.2975L110.13 49.945C110.292 50.0725 110.32 50.31 110.19 50.4725C110.115 50.5625 110.005 50.61 109.895 50.61Z"
          fill="#1D232A"
        />
        <path d="M102.77 5.90991H16.5225V117.83H102.77V5.90991Z" fill="#E8E9EA" />
        <path
          d="M102.77 111.335L64.3197 77.495C60.5422 74.17 54.8822 74.17 51.1072 77.495L12.6572 111.335"
          fill="#00A868"
        />
        <path d="M115.428 122.475H0V44.585L56.8475 85.9L115.428 44.585V122.475Z" fill="#00A868" />
        <path
          d="M12.6575 111.71C12.555 111.71 12.4525 111.667 12.3775 111.585C12.24 111.43 12.2525 111.192 12.4075 111.055L49.3075 78.0325C49.4625 77.895 49.7 77.9075 49.8375 78.0625C49.975 78.2175 49.9625 78.455 49.8075 78.5925L12.9075 111.615C12.835 111.677 12.7475 111.71 12.6575 111.71Z"
          fill="#1D232A"
        />
        <path
          d="M102.77 111.71C102.68 111.71 102.593 111.677 102.52 111.615L66.1426 79.0998C65.9876 78.9623 65.9751 78.7248 66.1126 78.5698C66.2501 78.4148 66.4876 78.4023 66.6426 78.5398L103.02 111.055C103.175 111.192 103.188 111.43 103.05 111.585C102.975 111.667 102.873 111.71 102.77 111.71Z"
          fill="#1D232A"
        />
        <path
          d="M29.9202 27.6301C29.7127 27.6301 29.5452 27.4626 29.5452 27.2551C29.5452 27.0476 29.7127 26.8801 29.9202 26.8801L89.3702 26.8701C89.5777 26.8701 89.7452 27.0376 89.7452 27.2451C89.7452 27.4526 89.5777 27.6201 89.3702 27.6201L29.9202 27.6301Z"
          fill="#1D232A"
        />
        <path
          d="M29.9226 44.9627C29.7151 44.9627 29.5476 44.7952 29.5476 44.5876C29.5476 44.3801 29.7151 44.2126 29.9226 44.2126L89.3726 44.2026C89.5801 44.2026 89.7476 44.3701 89.7476 44.5776C89.7476 44.7851 89.5801 44.9527 89.3726 44.9527L29.9226 44.9627Z"
          fill="#1D232A"
        />
        <path
          d="M29.9253 62.2975C29.7178 62.2975 29.5503 62.13 29.5503 61.9226C29.5503 61.7151 29.7178 61.5476 29.9253 61.5476L89.3753 61.5376C89.5828 61.5376 89.7503 61.7051 89.7503 61.9126C89.7503 62.12 89.5828 62.2875 89.3753 62.2875L29.9253 62.2975Z"
          fill="#1D232A"
        />
        <g filter="url(#filter0_d_12673_25520)">
          <path
            d="M102.75 147.5C118.214 147.5 130.75 134.964 130.75 119.5C130.75 104.036 118.214 91.5 102.75 91.5C87.286 91.5 74.75 104.036 74.75 119.5C74.75 134.964 87.286 147.5 102.75 147.5Z"
            fill="#E8E9EA"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M100.509 103.672C92.0317 105.323 86.4899 113.545 88.1306 122.037C89.7714 130.529 97.9734 136.074 106.45 134.423C114.927 132.772 120.469 124.549 118.828 116.058C117.188 107.566 108.986 102.021 100.509 103.672ZM84.2934 122.784C82.2424 112.17 89.1698 101.892 99.766 99.8282C110.362 97.7643 120.615 104.696 122.666 115.31C124.717 125.925 117.789 136.203 107.193 138.267C96.5968 140.33 86.3443 133.399 84.2934 122.784Z"
          fill="#00A868"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M101.623 109.438C102.682 109.231 103.708 109.925 103.913 110.986L105.23 117.806L110.691 119.947C111.696 120.341 112.191 121.476 111.797 122.483C111.403 123.49 110.269 123.987 109.265 123.594L102.803 121.06C102.18 120.816 101.725 120.269 101.598 119.611L100.075 111.733C99.8704 110.672 100.563 109.644 101.623 109.438Z"
          fill="#00A868"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_12673_25520"
          x="71.75"
          y="90.5"
          width="62"
          height="62"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.290196 0 0 0 0 0.309804 0 0 0 0 0.333333 0 0 0 0.32 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_12673_25520" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_12673_25520" result="shape" />
        </filter>
        <clipPath id="clip0_12673_25520">
          <rect width="133" height="151" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
})
