import { memo } from 'react'

function CoinsStackSvg(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="67" height="56" viewBox="0 0 100 90" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M53.06 22.661c0 1.383-.126 2.739-.362 4.046a21.916 21.916 0 01-.877 3.378c-.155.462-.33.916-.526 1.37a21.101 21.101 0 01-1.007 2.107c-.14.258-.278.51-.434.759-3.91 6.588-11.035 10.995-19.165 10.994l-8.1-.002-.081-25.89-.062-16.888 2.025-.002 15.315-.6c.325.147.643.3.964.464.55.278 1.09.579 1.606.905-5.185.252-10.37.509-15.556.765-.64.031-.63 1.043.015 1.014 5.642-.282 11.292-.56 16.937-.84l.171.127a22.633 22.633 0 018.7 13.764c.286 1.458.44 2.975.438 4.53z"
        fill="#1D232A"
      />
      <path
        d="M43.531 30.617c-4.365 11.706-17.265 17.63-28.814 13.235a22.166 22.166 0 01-7.808-5.044A22.626 22.626 0 012.3 32.144l5.33-4.089a16.269 16.269 0 01-.646-2.438l-5.562 4.268a22.27 22.27 0 01-.424-1.434.493.493 0 00.072-.044l5.565-4.27.075-1.264s-.44.332-.65.493L.753 27.433A22.863 22.863 0 011.667 14.8c.016-.034.025-.07.04-.104C4.87 6.217 12.51.77 20.873.082c.52-.043 1.044-.068 1.57-.072l8.2-.01h.108a22.138 22.138 0 017.813 1.426c.411.157.82.328 1.223.507l-7.482.293c.889.43 1.737.913 2.543 1.443l7.508-.367c.008 0 .012.006.017.008.476.293.937.602 1.38.93-2.525.126-5.052.25-7.58.375a22.368 22.368 0 016.053 7.12 22.833 22.833 0 011.306 18.882z"
        fill="#42EC9A"
      />
      <path
        d="M11.957 33.628l-.001-.001a15.351 15.351 0 01-3.532-5.5 15.703 15.703 0 01-.493-1.56l22.4-17.173c.466.274.913.573 1.342.894a15.26 15.26 0 014.515 5.447L12.35 34.007a13.09 13.09 0 01-.393-.38zM2.503 31.358a21.125 21.125 0 01-.494-1.293L6.658 26.5c.108.464.237.924.384 1.376l-4.539 3.482z"
        fill="#FCFCFC"
        stroke="#FCFCFC"
      />
      <mask id="prefix__available_credit" fill="#fff">
        <path d="M1.422 29.887l.01.037a.299.299 0 01-.012-.037h.002z" />
      </mask>
      <path d="M1.422 29.887l.01.037a.299.299 0 01-.012-.037h.002z" fill="#FCFCFC" />
      <path
        d="M1.422 29.887l.962-.272-.206-.728h-.756v1zm.01.037l-.92.39 1.883-.662-.962.272zm-.012-.037v-1H.098l.36 1.272.962-.272zm-.96.272l.01.037c.003.008.002.004 0 0l1.925-.544-.011-.038c-.002-.007-.001-.004 0 0l-1.925.545zm1.893-.625a.708.708 0 01.03.08l-1.926.545c.012.042.029.094.055.155l1.841-.78zm-.933 1.353h.002v-2H1.42v2z"
        fill="#FCFCFC"
        mask="url(#prefix__available_credit)"
      />
      <path
        d="M21.655 33.236a7.507 7.507 0 01-2.553-.463c-3.17-1.152-4.84-4.535-4.623-7.138a.29.29 0 01.311-.266.29.29 0 01.263.315c-.2 2.385 1.333 5.483 4.242 6.542 2.096.761 4.26.495 5.79-.71 1.154-.91 1.74-2.239 1.605-3.647-.255-2.655-1.87-3.82-3.918-5.293l-.188-.134c-2.091-1.51-3.83-2.946-3.909-5.281-.051-1.533.634-2.939 1.879-3.858 1.476-1.09 3.525-1.315 5.48-.604 2.33.847 3.598 2.941 4.007 4.62a.292.292 0 01-.212.353.287.287 0 01-.349-.214c-.374-1.53-1.527-3.44-3.644-4.209-1.801-.655-3.605-.462-4.946.526-1.088.805-1.687 2.032-1.643 3.367.071 2.072 1.633 3.36 3.669 4.826l.186.134c2.082 1.498 3.878 2.791 4.158 5.713.156 1.61-.51 3.129-1.825 4.164-1.053.827-2.377 1.257-3.78 1.257z"
        fill="#1D232A"
      />
      <path
        d="M18.268 35.02a.293.293 0 01-.273-.39l8.492-24.032a.288.288 0 01.368-.177c.15.054.228.221.175.373l-8.491 24.034a.287.287 0 01-.27.192z"
        fill="#1D232A"
      />
      <mask id="prefix__available_credit1" fill="#fff">
        <path d="M6.71 22.873l-.075 1.264-5.564 4.27a.352.352 0 01-.073.044c-.09-.336-.173-.676-.244-1.018l5.306-4.067c.21-.161.65-.493.65-.493z" />
      </mask>
      <path
        d="M6.71 22.873l-.075 1.264-5.564 4.27a.352.352 0 01-.073.044c-.09-.336-.173-.676-.244-1.018l5.306-4.067c.21-.161.65-.493.65-.493z"
        fill="#FCFCFC"
      />
      <path
        d="M6.71 22.873l.998.059.128-2.164-1.729 1.307.603.798zm-.075 1.264l.609.793.362-.278.027-.456-.998-.059zm-5.564 4.27l.604.796.004-.003-.608-.794zm-.073.044l-.966.26.3 1.119 1.064-.462-.398-.917zm-.244-1.018l-.608-.793-.499.382.128.615.98-.204zm5.306-4.067l-.607-.795-.002.001.609.794zm-.348-.552l-.075 1.264 1.996.118.075-1.264-1.996-.118zm.315.53L.462 27.613 1.679 29.2l5.565-4.27-1.217-1.586zM.467 27.61c.002-.003.053-.042.133-.076l.796 1.834c.134-.058.23-.128.28-.165L.465 27.61zm1.497.582a17.24 17.24 0 01-.23-.962l-1.959.407c.076.363.163.722.257 1.073l1.932-.518zm-.601.035l5.305-4.067-1.217-1.588L.146 26.64l1.217 1.587zm5.304-4.066c.103-.08.264-.202.4-.305l.174-.132c.023-.016.04-.03.053-.04l.014-.01.004-.003h.001l-.603-.798-.603-.798h-.001l-.004.004-.014.01c-.013.01-.03.024-.053.04l-.175.133c-.136.103-.3.227-.407.31l1.214 1.589z"
        fill="#FCFCFC"
        mask="url(#prefix__available_credit1)"
      />
      <path
        d="M11.64 34.606a.288.288 0 01-.198-.08 16.432 16.432 0 01-4.082-6.374 16.477 16.477 0 01-.422-9.556c.14-.554.32-1.129.534-1.708a16.368 16.368 0 012.815-4.836.284.284 0 01.404-.033c.121.105.137.287.033.41a15.832 15.832 0 00-2.713 4.663 15.99 15.99 0 00-.514 1.647 15.883 15.883 0 00.406 9.215A15.856 15.856 0 0011.84 34.1a.294.294 0 01-.2.505z"
        fill="#1D232A"
      />
      <path d="M68.038 31.258v55.405H4.344v-14.08H0v-13.49h4.35V45.314H45.3V31.258h22.738z" fill="#42EC9A" />
      <path
        d="M72.96 31.758h5.233v54.405H72.96V31.758zM68.539 31.758h1.289v54.405h-1.29V31.758z"
        fill="#FCFCFC"
        stroke="#FCFCFC"
      />
      <path
        d="M83.392 44.747v14.35h4.354V72.59h-4.354v14.074h-4.699V31.258h9.053v13.49h-4.354zM72.46 31.258H70.33v55.405h2.132V31.258z"
        fill="#42EC9A"
      />
      <path d="M45.301 58.524h-2.176V72.96h2.176V58.524z" fill="#1D232A" />
      <path
        d="M35.446 86.663h7.68V45.315h-2.062l-1.736 12.78-.206 1.508-1.736 12.776-.206 1.508-1.734 12.776z"
        fill="#1D232A"
      />
      <path
        d="M83.393 58.512v.583H4.35c-.006 0-.013 0-.019-.002h.02v-.58h79.042zM83.393 44.731H45.301v.583h38.092v-.583zM83.392 72.586H4.344v.583h79.048v-.583z"
        fill="#1D232A"
      />
    </svg>
  )
}

export const CoinsStackIllustration = memo(CoinsStackSvg)
