import { type Roles } from '~/lib/types'

import { type EventName, type RawViews, type Views } from './interface'

const ViewAbbrMap: Record<keyof Roles, RawViews> = {
  acquirer: 'A',
  banking: 'B',
  credit: 'C'
}

const generateEventName = (roles: Roles): EventName => {
  const rolesKeys = Object.keys(roles) as (keyof Roles)[]

  const views = rolesKeys.reduce((acc, curr) => {
    if (roles[curr]) {
      return [...acc, ViewAbbrMap[curr]]
    }

    return acc
  }, [] as RawViews[])

  return `document_change_view_${views.join('') as Views}`
}

export const registerDocumentChangeEvent = (roles: Roles) => {
  if (window?.hj !== undefined) {
    const eventName = generateEventName(roles)

    window.hj('event', eventName)
  }
}
