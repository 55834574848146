import util from 'util'

import { type AxiosError } from 'axios'
import pino from 'pino'

import { authenticator } from '~/domains/platform/core/authenticator/authenticator'
import { omit } from '~/domains/platform/lib/object-helpers/omit'
import { appEnv } from '~/lib/helpers/utils'
import { isomorphicCookies } from '~/lib/helpers/utils/cookies'

import { type LogErrorInputs, type LogUser, log } from './log'

type LoggerError = Error | AxiosError | any

export const pinoLogger = pino({
  prettyPrint: {
    colorize: appEnv.isLocal(),
    levelFirst: true,
    translateTime: 'SYS:standard',
    crlf: true,
    ignore: 'pid'
  }
})

function getFileFromError(error: LoggerError): string {
  const errorStack = error?.stack || ''
  const minifiedStack = errorStack.replace(/\n/gm, '').replace(/\t/gm, '').replace(/\r/gm, '').replace(/\s/gm, '')
  const filenameRegex = new RegExp(/((src)\/(.*?)\.(t|j)s(x?))+(:\d?\d:\d?\d)/)
  const [file] = minifiedStack?.match(filenameRegex) || []

  return file
}

const isApiError = (error: LoggerError) => error?.isAxiosError

function baseErrorFactory(
  error: LoggerError,
  location = 'server_side',
  description: Record<string, any>
): LogErrorInputs {
  return {
    location,
    type: isApiError(error) ? 'api_error' : 'internal_error',
    createdAt: new Date().toISOString(),
    errorDescription: description
  }
}

function internalErrorFactory(error: Error): Record<string, any> {
  return {
    name: error.name,
    message: error.message
  }
}

function apiErrorFactory(error: LoggerError): Record<string, any> {
  return {
    trace: {
      config: {
        baseURL: error?.config?.baseURL,
        url: error?.config?.url,
        method: error?.config?.method,
        headers: omit(error?.config?.headers, 'Authorization'),
        params: error?.config?.params,
        data: error?.config?.data
      },
      response: {
        status: error?.response?.status,
        statusText: error?.response?.statusText,
        data: { ...error?.response?.data }
      }
    }
  }
}

function errorMultiplexer(error: LoggerError, location?: string) {
  if (appEnv.isLocal()) return error

  if (isApiError(error)) {
    const apiErrorDescription = apiErrorFactory(error)
    return baseErrorFactory(error, location, apiErrorDescription)
  }

  if (error && error?.message && error?.stack) {
    const internalErrorDescription = internalErrorFactory(error)
    return { ...baseErrorFactory(error, location, internalErrorDescription), file: getFileFromError(error) }
  }

  return baseErrorFactory(error, location, { ...internalErrorFactory(error) })
}

export function printJsonError(error: Record<string, any>) {
  if (appEnv.isLocal()) {
    pinoLogger.error(error, error?.errorDescription?.message ?? error?.message)
  } else {
    const inspectOptions = {
      showHidden: false,
      colors: false,
      depth: 5
    }
    const stringifiedError = JSON.stringify(util.inspect(error, inspectOptions))

    const minifyString = stringifiedError
      .replace(/\\n/gm, '')
      .replace(/\\t/gm, '')
      .replace(/\\r/gm, '')
      .replace(/\s*:\s*/gm, ':')
      .replace(/\s*,\s*/gm, ',')
      .replace(/\s*{\s*/gm, '{')
      .replace(/\s*}\s*/gm, '}')

    pinoLogger.error(minifyString)
  }
}

function userFactory(): LogUser {
  const { token } = isomorphicCookies.getAll()
  const subjectId = token ? authenticator.parseToken({ token })['stone_subject_id'] : undefined

  const { accountId, identityId, loginEmail: email } = isomorphicCookies.getAll()
  const organizationId = identityId?.split(':')[0] === 'organization' ? identityId : undefined
  const userStringId = subjectId?.split(':')[1]

  return { userId: userStringId, accountId, organizationId, email }
}

/**
 * @deprecated Use Analitica instead, see Analitica Docs or call #web-platform Slack Channel
 * @see [Analitica Docs](https://github.com/stone-payments/analitica/blob/main/packages/analitica/README.md#configurando-os-eventos)
 */
export function logger(error: Error | AxiosError | any) {
  const user = userFactory()

  if (typeof window === 'undefined') {
    printJsonError(errorMultiplexer(error))
    return
  }

  const errorStructure = {
    error: errorMultiplexer(error, window?.location?.pathname),
    user,
    response: error?.response
  }

  if (!appEnv.isLocal()) log(errorStructure)
}
