export function HomePixFailedQRCodeIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="128" height="128" viewBox="0 0 128 128" fill="none" {...props}>
      <rect width="128" height="128" fill="white" />
      <path
        d="M59.1275 59.1163C46.7928 59.1163 34.7199 59.1163 22.3803 59.1163C22.3803 56.3846 22.3803 53.7764 22.3803 51.1681C22.3803 44.9143 22.3506 38.6605 22.3902 32.4067C22.4247 26.232 26.2383 22.3839 32.3785 22.3542C40.6872 22.3147 49.0009 22.3444 57.3097 22.3493C57.8679 22.3493 58.4261 22.4036 59.1226 22.4382C59.1275 34.7087 59.1275 46.8359 59.1275 59.1163ZM29.1676 52.3043C37.096 52.3043 44.6934 52.3043 52.3353 52.3043C52.3353 44.534 52.3353 36.9366 52.3353 29.1366C45.3504 29.1366 38.5384 29.107 31.7264 29.1564C30.0617 29.1662 29.1775 30.2135 29.1725 31.8683C29.1577 38.6062 29.1676 45.3392 29.1676 52.3043Z"
        fill="#F4F4F9"
      />
      <path
        d="M68.8674 59.0967C68.8674 46.7521 68.8674 34.6842 68.8674 22.3445C70.0381 22.3445 71.0854 22.3445 72.1326 22.3445C79.8683 22.3445 87.6041 22.3297 95.3349 22.3494C101.816 22.3692 105.61 26.1482 105.629 32.5996C105.654 40.6613 105.634 48.728 105.634 56.7898C105.634 57.511 105.634 58.2322 105.634 59.0918C93.2651 59.0967 81.1379 59.0967 68.8674 59.0967ZM75.6102 52.2798C83.4991 52.2798 91.0965 52.2798 98.8422 52.2798C98.8422 45.3393 98.8619 38.6014 98.8273 31.8635C98.8175 29.9222 97.6665 29.1219 95.7844 29.1318C91.2596 29.1565 86.7297 29.1368 82.2049 29.1368C80.0165 29.1368 77.8282 29.1368 75.6102 29.1368C75.6102 37.0503 75.6102 44.5984 75.6102 52.2798Z"
        fill="#F4F4F9"
      />
      <path
        d="M59.0669 105.61C53.7862 105.61 48.6192 105.61 43.4521 105.61C39.5842 105.61 35.7164 105.679 31.8485 105.59C26.5382 105.467 22.5715 101.969 22.448 96.7578C22.2307 87.555 22.3888 78.3422 22.3888 68.922C34.6197 68.922 46.742 68.922 59.0669 68.922C59.0669 80.9653 59.0669 93.0974 59.0669 105.61ZM29.1612 75.5809C29.1612 82.6053 29.1464 89.3432 29.1711 96.086C29.1761 98.057 30.3468 98.8326 32.2042 98.8276C36.566 98.8177 40.9279 98.8276 45.2897 98.8276C47.646 98.8276 49.9974 98.8276 52.3092 98.8276C52.3092 90.8548 52.3092 83.2475 52.3092 75.5809C44.5685 75.5809 37.0205 75.5809 29.1612 75.5809Z"
        fill="#F4F4F9"
      />
      <path
        d="M91.5263 68.9171C91.5263 70.9819 91.5263 73.0715 91.5263 75.4475C86.216 75.4475 81.0144 75.4475 75.5855 75.4475C75.5855 77.8236 75.5855 79.923 75.5855 82.2249C73.2786 82.2249 71.199 82.2249 68.9217 82.2249C68.9217 77.8977 68.9217 73.5111 68.9217 68.9171C76.3462 68.9171 83.8449 68.9171 91.5263 68.9171Z"
        fill="#F4F4F9"
      />
      <path
        d="M82.7114 105.61C82.7114 103.283 82.7114 101.253 82.7114 98.8226C86.0112 98.8226 89.3357 98.8226 92.6602 98.8226C93.8112 98.8226 94.9572 98.8325 96.1082 98.8078C97.8964 98.7683 98.8202 97.8297 98.8449 96.0464C98.8646 94.7522 98.8498 93.453 98.8498 92.0304C101.152 92.0304 103.241 92.0304 105.326 92.0304C107.065 100.917 102.056 106.055 94.5571 105.645C90.7139 105.437 86.851 105.61 82.7114 105.61Z"
        fill="#F4F4F9"
      />
      <path
        d="M99.0053 68.8035C101.223 68.8035 103.258 68.8035 105.462 68.8035C105.462 73.2789 105.462 77.6754 105.462 82.2496C103.347 82.2496 101.258 82.2496 99.0053 82.2496C99.0053 77.8433 99.0053 73.4469 99.0053 68.8035Z"
        fill="#F4F4F9"
      />
      <path
        d="M68.8812 92.0008C71.1239 92.0008 73.1591 92.0008 75.3425 92.0008C75.3425 96.4911 75.3425 100.883 75.3425 105.447C73.1986 105.447 71.114 105.447 68.8812 105.447C68.8812 100.996 68.8812 96.5997 68.8812 92.0008Z"
        fill="#F4F4F9"
      />
      <path
        d="M82.6791 82.6942C85.7072 82.6942 88.5377 82.6942 91.6893 82.6942C91.6893 85.2975 91.7437 87.8218 91.6399 90.3411C91.6202 90.7906 90.9533 91.5711 90.5581 91.5908C87.9598 91.7044 85.3565 91.6501 82.6791 91.6501C82.6791 88.543 82.6791 85.7668 82.6791 82.6942Z"
        fill="#F4F4F9"
      />
      <path
        d="M45.2897 36.2946C45.2897 39.4264 45.2897 42.2569 45.2897 45.2406C42.3505 45.2406 39.515 45.2406 36.3239 45.2406C36.3239 42.6373 36.2696 40.0291 36.3783 37.4307C36.3931 37.0355 37.1538 36.3637 37.5935 36.344C40.1128 36.2402 42.6419 36.2946 45.2897 36.2946Z"
        fill="#F4F4F9"
      />
      <path
        d="M91.695 45.3098C88.5632 45.3098 85.787 45.3098 82.8034 45.3098C82.8034 42.3904 82.8034 39.5006 82.8034 36.3095C85.3424 36.3095 87.8667 36.2403 90.381 36.3737C90.8404 36.3984 91.6111 37.2085 91.6357 37.6877C91.7642 40.123 91.695 42.5781 91.695 45.3098Z"
        fill="#F4F4F9"
      />
      <path
        d="M36.324 82.6497C39.4954 82.6497 42.2666 82.6497 45.2255 82.6497C45.2255 85.5494 45.2255 88.375 45.2255 91.6303C42.642 91.6303 39.9795 91.6847 37.3268 91.5661C36.9761 91.5513 36.3882 90.6967 36.3734 90.2175C36.2697 87.7773 36.324 85.3271 36.324 82.6497Z"
        fill="#F4F4F9"
      />
      <g clipPath="url(#FailedQRCodeIllustration)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M45.5417 50.4997C46.3931 50.4997 47.0833 51.1713 47.0833 51.9997V59.4997H54.7917C55.6431 59.4997 56.3333 60.1713 56.3333 60.9997C56.3333 61.8281 55.6431 62.4997 54.7917 62.4997H45.5417C44.6902 62.4997 44 61.8281 44 60.9997V51.9997C44 51.1713 44.6902 50.4997 45.5417 50.4997Z"
          fill="#00A868"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M60.3499 49.16C63.6598 48.7069 67.0313 49.3127 69.9565 50.8861C72.8817 52.4594 75.2021 54.9152 76.5682 57.8833C77.9342 60.8514 78.2718 64.171 77.5302 67.3421C76.7885 70.5131 75.0078 73.3637 72.4562 75.4644C69.9047 77.5651 66.7206 78.802 63.3836 78.9889C60.0467 79.1758 56.7378 78.3024 53.9554 76.5004C51.173 74.6984 49.0679 72.0654 47.9572 68.9981C47.6743 68.2168 48.0959 67.3602 48.899 67.0849C49.702 66.8096 50.5824 67.2199 50.8653 68.0012C51.7539 70.4551 53.438 72.5615 55.6639 74.0031C57.8898 75.4447 60.537 76.1434 63.2065 75.9939C65.876 75.8444 68.4233 74.8548 70.4645 73.1742C72.5058 71.4937 73.9304 69.2132 74.5237 66.6764C75.117 64.1396 74.8469 61.4838 73.7541 59.1094C72.6613 56.7349 70.8049 54.7703 68.4648 53.5116C66.1246 52.2529 63.4274 51.7683 60.7795 52.1307C58.1316 52.4932 55.6766 53.6831 53.7842 55.5212C53.7732 55.5319 53.762 55.5425 53.7507 55.5528L46.5974 62.0928C45.9769 62.6601 45.0012 62.6306 44.4182 62.0268C43.8351 61.4231 43.8655 60.4738 44.486 59.9066L51.6228 53.3817C53.9859 51.0934 57.048 49.612 60.3499 49.16Z"
          fill="#00A868"
        />
      </g>
      <defs>
        <clipPath id="FailedQRCodeIllustration">
          <rect width="37" height="36" fill="white" transform="translate(44 46)" />
        </clipPath>
      </defs>
    </svg>
  )
}
