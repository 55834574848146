import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser'

export const dynamicTemplate = (templateString: string, templateVariables: Record<string, any>) => {
  return templateString.replace(/\${(.*?)}/g, (_, g) => templateVariables[g])
}

export const dynamicRenderTemplate = (templateString: string, templateVariables: Record<string, any>) => {
  const entries = Object.entries(templateVariables).map(([key, value]) => [key, renderToString(value)])

  return parse(dynamicTemplate(templateString, Object.fromEntries(entries)))
}
