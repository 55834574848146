import axios, { type AxiosPromise, type AxiosTransformer } from 'axios'

import { type CustomAxiosRequestConfig } from '~/domains/platform/infra/http/http-client-factory'
import { BANKING_API_ENDPOINT } from '~/lib/constants'

import { keysToSnakecase, responseSuccessInterceptor, transformResponse } from './http-helpers'

const simpleInstance = axios.create({
  baseURL: BANKING_API_ENDPOINT(),
  transformResponse: [...(axios.defaults.transformResponse as AxiosTransformer[]), transformResponse],
  transformRequest: [keysToSnakecase, ...(axios.defaults.transformRequest as AxiosTransformer[])]
})

simpleInstance.interceptors.response.use(responseSuccessInterceptor)

export const simpleHttpClient = async <T>(config: CustomAxiosRequestConfig = {}) => {
  return simpleInstance({ ...config }) as AxiosPromise<T>
}
