export function GreyBlockedLockerIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="132" height="132" viewBox="0 0 132 132" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M125.73 96.9642C138.525 66.1007 130.482 43.4674 112.936 21.2457C81.8638 -17.848 14.9674 2.31611 2.90411 38.9408C-9.15917 75.5655 18.9885 108.487 39.094 124.947C59.1994 141.408 112.936 127.828 125.73 96.9642Z"
        fill="#DCE0E9"
      />
      <g opacity="0.9">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M55.3559 48.6049C55.4467 46.2475 55.9444 43.5061 57.882 41.0484C59.7842 38.6357 62.1162 37.0753 67.2054 37.2713C72.2975 37.4675 74.7378 39.197 76.5571 41.7678C78.4053 44.3795 78.8665 47.1748 78.7767 49.5072L78.5113 56.3976C78.4036 56.391 78.2946 56.3844 78.1844 56.3777C71.7643 55.9879 61.1544 55.3437 55.098 55.3006L55.3559 48.6049ZM51.7438 55.377L52.0096 48.476C52.1102 45.8641 52.7882 41.8438 55.0283 39.0024C57.3039 36.1159 61.7018 33.6571 67.3362 33.8741C72.9677 34.0911 76.9927 36.8891 79.1456 39.9314C81.2695 42.9328 82.1425 46.9959 82.0409 49.6329L81.7728 56.592C82.3899 56.627 82.8678 56.6519 83.1767 56.6638C86.5885 56.7952 88.7759 58.1678 88.7147 59.7545C88.7147 59.7545 88.4459 80.1709 87.7561 84.6402C87.0663 89.1095 85.5947 91.9302 82.8943 92.006C80.1938 92.0818 48.5991 90.6229 46.2693 90.4153C43.9394 90.2078 42.7425 88.599 42.8508 85.7878C42.8752 85.1546 42.9071 83.6173 42.9492 81.5817C43.0943 74.5802 43.3614 61.6827 43.8677 59.3913C44.5211 56.4336 46.6938 56.052 51.2136 55.4325C51.368 55.4113 51.5453 55.3929 51.7438 55.377Z"
          fill="#677481"
        />
        <path
          d="M65.4831 72.8686C64.711 72.7933 63.1881 72.097 63.2721 69.9142C63.3405 68.1406 64.8793 67.2435 66.7961 67.1807C68.713 67.1179 69.7423 68.7971 69.6898 70.1614C69.6477 71.2528 68.6884 72.4911 68.214 72.9738C68.7408 74.6792 69.7074 78.2233 69.3587 78.7564C68.9228 79.4228 64.1647 78.693 63.7866 77.8586C63.4841 77.1911 64.7915 74.2538 65.4831 72.8686Z"
          fill="white"
        />
      </g>
    </svg>
  )
}
