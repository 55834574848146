import ReactGA from 'react-ga'

import { type AnalyticsProps } from './interface'

/**
 * @deprecated you should use the new amplitude provider.
 * @see [amplitude](https://github.com/dlpco/dashboard/wiki/SDK-Web-Analytics)
 */
export const UNSAFE_trackHotjarEvent = (eventName: string) => {
  if (window?.hj !== undefined) {
    window.hj('event', eventName)
  }
}

/**
 * @deprecated you should use the new amplitude provider.
 * @see [amplitude](https://github.com/dlpco/dashboard/wiki/SDK-Web-Analytics)
 */
export const UNSAFE_trackHotjarAndAnalyticsEvents = ({ action, category, label, value }: AnalyticsProps) => {
  ReactGA.event({ action, category, label, value })
  if (window?.hj !== undefined) {
    window.hj('event', action)
  }
}
