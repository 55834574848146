import { theme as gingaTheme } from '@dlpco/ginga-stone'
import { parseToRgb, rgbToColorString, transparentize } from 'polished'
import { type RgbaColor } from 'polished/lib/types/color'

import { Bucket } from '~/domains/platform/lib/bucket'
import { clientSideOnly } from '~/domains/platform/lib/client-side-only'
import { appEnv } from '~/lib/helpers'

const forcedDarkByDevtools =
  appEnv.isLocal() && clientSideOnly(() => Bucket.local.get('force::dark::mode') === true, false)
const colorModePreference = forcedDarkByDevtools ? 'dark' : 'light'

/**
 * Blends a translucent color with solid white like Photoshop's Flatten command.
 * See https://www.rweber.net/projects/flattening-color-layers-javascript/
 * @param color original translucent color;
 * @returns flattened color
 */
export const flattenColor = (color: string): string => {
  const { red: colorRed, green: colorGreen, blue: colorBlue, alpha: colorAlpha = 1 } = parseToRgb(color) as RgbaColor
  const { red: whiteRed, green: whiteGreen, blue: whiteBlue } = parseToRgb('white')

  const red = Math.round(colorAlpha * colorRed + (1 - colorAlpha) * whiteRed)
  const green = Math.round(colorAlpha * colorGreen + (1 - colorAlpha) * whiteGreen)
  const blue = Math.round(colorAlpha * colorBlue + (1 - colorAlpha) * whiteBlue)

  return rgbToColorString({ red, green, blue })
}

const flattenColorFunction = (receivedColor: any): any => {
  return Object.fromEntries(Object.entries(receivedColor).map(([key, value]) => [key, flattenColor(value as string)]))
}

const gingaColor = Object.fromEntries(
  Object.entries(gingaTheme[colorModePreference].color).map(([key, value]) => [key, value.replace(/\s/g, '')])
)

// Brand Colors
let brandColors: any = {
  primary: gingaColor.primaryHover,
  highPrimary: gingaColor.primaryHigh,
  stoneGreen: gingaColor.primary,
  lighterGreen: gingaColor.primaryOverlay,
  lighterGreenHover: gingaColor.primaryOverlayHover,
  purple: gingaColor.help,
  lightPurple: gingaColor.helpOverlayHover
}

brandColors = flattenColorFunction(brandColors)

// Black and Greys
let blackGrey = {
  black: gingaColor.neutralHigh,
  darkGray: gingaColor.neutralHigh,
  darkGray1: gingaColor.neutralHigh,
  darkGray2: gingaColor.neutral,
  mediumGray: gingaColor.neutral,
  mediumGray2: gingaColor.neutralLow,
  mediumGray3: gingaColor.neutralLow,
  mediumGray4: gingaColor.back,
  lightGray: gingaColor.back,
  lightGray5: gingaColor.surfaceHigh,
  lightGray6: gingaColor.surfaceHighHover,
  neutralOverlay: gingaColor.neutralOverlay,
  neutralOverlayHover: gingaColor.neutralOverlayHover,
  white: '#FFFFFF'
}

blackGrey = flattenColorFunction(blackGrey)

// UI Colors
let ui = {
  danger: gingaColor.negative,
  highDanger: gingaColor.negativeHigh,
  lightRed: gingaColor.warningOverlayHover,
  orange: gingaColor.warningHover,
  lightOrange: gingaColor.warningOverlayHover,
  yellow: '#F2B417',
  lightYellow: '#FFCE5B',
  backgroundYellow: '#FFF7E0',
  blue: gingaColor.info,
  blue3: gingaColor.info,
  green: gingaColor.primary,
  alertOrange: gingaColor.warningOverlayHover,
  alertLightOrange: gingaColor.warningOverlay,
  alertDarkOrange: gingaColor.warning
}

ui = flattenColorFunction(ui)

const backgrounds = {
  blue: transparentize(0.75, ui.blue),
  green: transparentize(0.75, ui.green),
  orange: transparentize(0.75, ui.orange),
  darkGray: transparentize(0.75, blackGrey.darkGray)
}

const title = {
  primary: blackGrey.darkGray,
  secondary: blackGrey.darkGray2
}

const text = {
  paragraph: blackGrey.mediumGray,
  span: blackGrey.mediumGray2,
  error: ui.danger,
  menu: blackGrey.darkGray,
  muted: blackGrey.mediumGray2
}

const label = {
  inactive: blackGrey.darkGray,
  focus: brandColors.primary,
  disabled: gingaColor.borderLow
}

const placeholder = {
  grey: gingaColor.borderLow,
  focus: blackGrey.darkGray,
  error: ui.danger
}

const border = {
  normal: gingaColor.borderMedium,
  focus: brandColors.primary,
  disabled: gingaColor.borderLow,
  error: ui.danger
}

const input = {
  disabled: gingaColor.borderLow,
  active: brandColors.primary,
  border: gingaColor.borderMedium
}

export default {
  ...brandColors,
  ...blackGrey,
  ...ui,
  title,
  text,
  label,
  placeholder,
  border,
  input,
  menuDivider: gingaColor.borderMedium,
  muted: blackGrey.mediumGray4,
  openedMenu: blackGrey.black,
  background: blackGrey.white,
  backgrounds
}
