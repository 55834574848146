export const NotFoundIllustration = () => (
  <svg width="443" height="411" viewBox="0 0 443 411" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.25"
      d="M421.12 301.905C461.476 205.809 436.109 135.339 380.763 66.1501C282.754 -55.5712 71.7473 7.21138 33.6969 121.245C-4.35359 235.279 84.4308 337.781 147.848 389.032C211.266 440.283 380.763 398.001 421.12 301.905Z"
      fill="#FFF5D5"
    />
    <path
      d="M188.243 115.399C110.339 115.706 51.2459 185.304 31.4375 220.064H173.291C264.154 223.004 437.905 218.684 406.007 177.892C366.135 126.901 330.863 144.92 315.911 159.489C300.959 174.058 266.071 181.342 239.617 150.671C218.454 126.134 198.211 115.36 188.243 115.399Z"
      fill="#FFF5D5"
    />
    <path
      d="M6.62323 223.958C43.3149 195.832 93.3309 189.806 106.387 189.806H125.135C179.369 201.524 251.681 193.489 289.846 185.453C320.377 179.024 359.256 176.747 374.879 176.413C399.876 175.855 431.587 178.183 439.89 196.666C450.268 219.769 418.065 247.395 410.7 251.748C404.808 255.23 400.211 258.556 398.648 259.784C384.588 273.512 394.966 285.9 405.679 296.614C414.249 305.186 412.597 312.24 410.7 314.695C400.322 339.807 328.345 342.485 305.245 343.155C282.146 343.825 276.789 349.517 265.407 363.914C254.024 378.312 233.938 380.32 214.52 380.32C195.103 380.32 151.917 369.941 149.908 340.476C148.301 316.905 122.233 311.682 109.4 312.016C97.3482 312.016 68.8921 311.481 51.4836 309.338C29.723 306.659 24.7014 298.623 34.4099 287.239C42.1768 278.132 29.8345 269.605 22.6926 266.48C-6.76789 252.284 -1.20985 232.393 6.62323 223.958Z"
      fill="#FFF9E8"
    />
    <path
      d="M441.495 200.68C450.325 233.591 417.988 245.84 404.57 249.034L415.304 216.83L441.495 200.68Z"
      fill="#FFF9E8"
    />
    <circle cx="282.172" cy="131.886" r="17.6358" fill="#FFD962" />
    <path
      d="M279.065 236.686C256.238 214.9 191.004 184.634 112.679 237.85L279.065 236.686Z"
      fill="url(#paint0_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.207652 237.533C-1.05931 248.539 3.1815 262.432 20.987 267.483C28.6485 268.318 33.0058 270.598 35.0443 273.585C36.5518 266.267 26.9625 259.907 20.9977 257.304C8.54248 251.316 2.50496 244.282 0.207652 237.533ZM29.8997 292.874C25.4338 302.249 30.9663 307.959 49.8003 310.272C67.2222 312.411 95.7004 312.946 107.762 312.946C120.605 312.612 146.693 317.827 148.301 341.361C150.311 370.778 193.531 381.141 212.963 381.141C232.395 381.141 252.498 379.135 263.889 364.761C275.28 350.386 280.641 344.703 303.758 344.035C304.301 344.019 304.871 344.003 305.467 343.986C330.225 343.285 399.153 341.335 409.295 316.852C410.944 314.723 415.316 308.121 410.816 300.815C410.716 302.909 410.028 304.508 409.306 305.441C398.919 330.513 326.887 333.187 303.769 333.855C280.651 334.524 275.291 340.207 263.9 354.581C252.508 368.956 232.406 370.961 212.974 370.961C193.542 370.961 150.322 360.598 148.312 331.181C146.704 307.647 120.615 302.432 107.772 302.767C95.7111 302.767 67.2329 302.232 49.811 300.092C39.4645 298.822 32.8992 296.344 29.8997 292.874ZM391.559 270.391C392.119 267.277 393.865 264.078 397.234 260.797C398.797 259.572 403.398 256.251 409.295 252.774C414.294 249.827 432.1 237.969 439.802 223.447C429.484 231.39 417.955 237.779 412.18 240.979C410.928 241.673 409.946 242.217 409.306 242.595C403.409 246.072 398.808 249.392 397.244 250.618C390.11 257.567 389.262 264.173 391.559 270.391Z"
      fill="#FFF5D5"
    />
    <path
      d="M405.434 231.849C393.126 216.535 358.09 195.076 316.42 231.757L405.434 231.849Z"
      fill="url(#paint1_linear)"
    />
    <path
      d="M171.047 167.648C170.47 167.648 170.003 168.115 170.003 168.692C170.003 169.269 170.47 169.737 171.047 169.737L171.047 167.648ZM173.574 169.737C174.151 169.737 174.619 169.269 174.619 168.692C174.619 168.115 174.151 167.648 173.574 167.648L173.574 169.737ZM171.047 169.737L173.574 169.737L173.574 167.648L171.047 167.648L171.047 169.737Z"
      fill="#088037"
    />
    <path
      d="M176.628 108.81C177.015 109.238 177.015 109.932 176.628 110.361C176.241 110.789 175.614 110.789 175.228 110.361L176.628 108.81ZM171.823 106.591C171.437 106.163 171.437 105.468 171.823 105.04C172.21 104.612 172.837 104.612 173.224 105.04L171.823 106.591ZM175.228 110.361L171.823 106.591L173.224 105.04L176.628 108.81L175.228 110.361Z"
      fill="#088037"
    />
    <path
      d="M231.509 165.189C230.935 165.247 230.47 165.763 230.47 166.34C230.47 166.917 230.935 167.337 231.509 167.278L231.509 165.189ZM236.562 166.762C237.136 166.703 237.601 166.188 237.601 165.611C237.601 165.034 237.136 164.613 236.562 164.672L236.562 166.762ZM231.509 167.278L236.562 166.762L236.562 164.672L231.509 165.189L231.509 167.278Z"
      fill="#088037"
    />
    <path
      d="M207.03 236.406C206.456 236.465 205.991 236.98 205.991 237.557C205.991 238.134 206.456 238.555 207.03 238.496L207.03 236.406ZM212.083 237.979C212.657 237.921 213.122 237.405 213.122 236.828C213.122 236.251 212.657 235.831 212.083 235.89L212.083 237.979ZM207.03 238.496L212.083 237.979L212.083 235.89L207.03 236.406L207.03 238.496Z"
      fill="#088037"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M148.312 129.039C148.312 124.156 152.27 120.197 157.154 120.197C162.037 120.197 165.996 124.156 165.996 129.039V156.632C166.026 162.928 170.499 165.581 173.996 164.961C181.153 171.955 193.927 186.192 176.522 188.276C160.943 190.141 148.312 177.551 148.312 163.096C148.312 163.096 148.312 163.096 148.312 163.096V129.039Z"
      fill="#088037"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M234.102 155.152C234.102 150.268 230.143 146.31 225.26 146.31C220.377 146.31 216.418 150.268 216.418 155.152V182.818C216.353 189.061 211.902 191.691 208.419 191.073C201.261 198.068 188.488 212.304 205.892 214.388C221.471 216.253 234.103 203.663 234.103 189.208C234.103 189.208 234.102 189.208 234.102 189.208V155.152Z"
      fill="#14AA4B"
    />
    <path
      d="M170.994 261.708C170.42 261.767 169.954 262.282 169.954 262.859C169.954 263.436 170.42 263.856 170.994 263.798L170.994 261.708ZM176.047 263.281C176.621 263.222 177.086 262.707 177.086 262.13C177.086 261.553 176.621 261.133 176.047 261.192L176.047 263.281ZM170.994 263.798L176.047 263.281L176.047 261.192L170.994 261.708L170.994 263.798Z"
      fill="#088037"
    />
    <path
      d="M173.574 117.365C173.574 107.598 181.491 99.6807 191.258 99.6807C201.025 99.6807 208.942 107.598 208.942 117.365V273.176C208.942 282.942 201.025 290.86 191.258 290.86C181.491 290.86 173.574 282.942 173.574 273.176V117.365Z"
      fill="#14AA4B"
    />
    <path
      d="M188.262 101.08C186.297 102.79 182.199 107.701 181.525 117.4C180.852 127.099 181.245 233.662 181.525 286.198"
      stroke="#088037"
      strokeWidth="2.08955"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M194.156 101.08C196.121 102.79 200.219 107.701 200.893 117.4C201.567 127.099 201.173 233.662 200.893 286.198"
      stroke="#088037"
      strokeWidth="2.08955"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M144.944 135.94C144.367 135.94 143.899 136.407 143.899 136.984C143.899 137.561 144.367 138.029 144.944 138.029L144.944 135.94ZM149.997 138.029C150.574 138.029 151.041 137.561 151.041 136.984C151.041 136.407 150.574 135.94 149.997 135.94L149.997 138.029ZM144.944 138.029L149.997 138.029L149.997 135.94L144.944 135.94L144.944 138.029Z"
      fill="#088037"
    />
    <path
      d="M204.875 113.866C204.488 114.295 204.488 114.989 204.875 115.417C205.261 115.846 205.888 115.846 206.275 115.417L204.875 113.866ZM209.679 111.647C210.066 111.219 210.066 110.525 209.679 110.097C209.292 109.668 208.666 109.668 208.279 110.097L209.679 111.647ZM206.275 115.417L209.679 111.647L208.279 110.097L204.875 113.866L206.275 115.417Z"
      fill="#088037"
    />
    <path
      d="M170.992 206.501C170.418 206.56 169.953 207.075 169.953 207.652C169.953 208.229 170.418 208.649 170.992 208.591L170.992 206.501ZM176.045 208.074C176.619 208.015 177.085 207.5 177.085 206.923C177.085 206.346 176.619 205.926 176.045 205.985L176.045 208.074ZM170.992 208.591L176.045 208.074L176.045 205.985L170.992 206.501L170.992 208.591Z"
      fill="#088037"
    />
    <path
      d="M208.522 204.13C213.154 203.974 224.943 199.467 225.364 187.343V147.242"
      stroke="#088037"
      strokeWidth="2.08955"
      strokeLinecap="round"
    />
    <path
      d="M248.435 294.002C233.465 275.376 190.854 249.277 140.173 293.89L248.435 294.002Z"
      fill="url(#paint2_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="224.281"
        y1="215.848"
        x2="223.685"
        y2="227.929"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF5D5" />
        <stop offset="0.927083" stopColor="#FFF9E8" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="374.186"
        y1="215.847"
        x2="375.187"
        y2="228.865"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF6D8" />
        <stop offset="0.927083" stopColor="#FFF9E8" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="212.761"
        y1="275.473"
        x2="212.381"
        y2="286.204"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF6D7" />
        <stop offset="0.927083" stopColor="#FFF9E8" />
      </linearGradient>
    </defs>
  </svg>
)
