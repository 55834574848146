export function PiggyBankNeutralErrorIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="110" height="105" viewBox="0 0 110 105" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M21.2117 15.0264L33.1811 14.5871L33.5847 14.5721C33.5715 14.5214 33.5584 14.4707 33.5471 14.4219C33.0403 12.4058 32.1767 10.3032 31.1198 8.36965C30.9077 7.97731 30.6862 7.59435 30.4572 7.2189C28.6569 4.2641 26.4473 1.8706 24.4781 1.06713C23.4587 0.654133 22.1334 0.988285 22.0302 2.28171L21.313 13.4664L21.283 13.9526L21.2117 15.0264Z"
        fill="#BBBDBF"
      />
      <path
        d="M21.2117 15.0264L33.1811 14.5871L33.5847 14.5721C33.5715 14.5214 33.5584 14.4707 33.5471 14.4219C33.0403 12.4057 32.1767 10.3032 31.1198 8.36963L21.283 13.9526L21.2117 15.0264Z"
        fill="#1D232A"
      />
      <path
        d="M21.5552 14.3488C21.4632 14.3488 21.3731 14.3037 21.3187 14.2211C21.2342 14.0897 21.2718 13.917 21.4013 13.8307L28.2533 9.3797L21.9401 11.7619C21.8049 11.8126 21.6528 11.7525 21.589 11.6249C21.5252 11.4954 21.5684 11.3377 21.6904 11.2607C21.7317 11.2344 25.9123 8.59877 29.1149 7.49682C29.2614 7.44802 29.4228 7.52498 29.4735 7.67141C29.5242 7.81783 29.4453 7.97928 29.2989 8.02996C27.8947 8.51241 26.2934 9.30086 24.9474 10.0255L30.4966 7.93047C30.6299 7.87978 30.7819 7.93798 30.8476 8.06751C30.9133 8.19704 30.8702 8.35285 30.75 8.43169L21.711 14.3037C21.6604 14.3338 21.6078 14.3488 21.5552 14.3488Z"
        fill="#1D232A"
      />
      <path
        d="M100.55 40.2679C100.233 40.2679 99.9213 40.1759 99.6622 40.0014C97.9652 38.8487 97.9933 36.4083 98.9939 34.6474C98.2374 34.3752 97.4039 34.3208 96.6567 34.531C95.3126 34.9121 94.3965 36.2299 94.6988 37.3544C94.7382 37.5046 94.65 37.6585 94.4998 37.6998C94.3496 37.7393 94.1957 37.651 94.1544 37.5009C93.7695 36.0666 94.8452 34.4578 96.5028 33.9904C97.4001 33.7369 98.4063 33.8158 99.3037 34.1668C99.7486 33.5605 100.278 33.1531 100.882 32.956C101.526 32.7458 102.315 32.772 103.099 33.033C103.862 33.2845 104.789 33.9791 105.086 34.8164C105.138 34.9628 105.061 35.1242 104.915 35.1768C104.768 35.2294 104.607 35.1524 104.554 35.006C104.329 34.3696 103.546 33.7745 102.921 33.568C102.249 33.3465 101.586 33.3183 101.055 33.4929C100.595 33.6431 100.182 33.951 99.8237 34.4109C100.227 34.6305 100.597 34.9084 100.909 35.2388C102.082 36.4871 102.495 38.111 101.958 39.3725C101.787 39.7723 101.44 40.0764 101.004 40.206C100.858 40.2454 100.702 40.2679 100.55 40.2679ZM99.512 34.8746C98.611 36.4139 98.5378 38.554 99.9776 39.5339C100.224 39.701 100.548 39.7479 100.849 39.6616C101.126 39.579 101.339 39.3969 101.444 39.1491C101.988 37.865 101.277 36.4458 100.501 35.6198C100.22 35.3195 99.8819 35.0698 99.512 34.8746Z"
        fill="#1D232A"
      />
      <path
        d="M62.5563 84.2183L69.3389 85.5193C70.2174 85.6882 71.0941 85.2133 71.4301 84.3835L79.7933 63.8557L63.5588 60.7432L61.0433 82.1571C60.9306 83.1333 61.5896 84.0343 62.5563 84.2183Z"
        fill="#4A4F55"
      />
      <path
        d="M69.8008 81.4681C69.7726 81.4681 69.7445 81.4643 69.7163 81.4549L61.8318 78.9976C61.6892 78.9525 61.6066 78.8042 61.6422 78.6597C61.6798 78.5151 61.8243 78.4232 61.9689 78.4532L65.2728 79.1009L61.7661 78.1284C61.6216 78.089 61.5333 77.9426 61.5671 77.7962C61.5991 77.6497 61.7436 77.5559 61.89 77.5803L72.9114 79.4707C73.0653 79.497 73.1667 79.6434 73.1404 79.7954C73.1141 79.9475 72.9696 80.0526 72.8156 80.0245L67.3021 79.0783L72.4627 80.5107C72.6091 80.5501 72.6955 80.7003 72.6617 80.8467C72.6279 80.9931 72.4834 81.0889 72.3332 81.0588L66.938 80.0019L69.8815 80.9199C70.0298 80.9669 70.1124 81.1245 70.0673 81.2728C70.0317 81.3892 69.9209 81.4681 69.8008 81.4681Z"
        fill="#1D232A"
      />
      <path d="M73.7392 78.7219L76.525 71.8812L62.3706 71.1885L61.5483 77.8358L73.7392 78.7219Z" fill="#1D232A" />
      <path
        d="M7.20176 71.3853L11.3993 76.6491C11.9568 77.3493 12.9311 77.5577 13.7271 77.1466L33.4308 66.9906L23.1228 54.0676L7.38385 68.8454C6.67049 69.5155 6.59165 70.6194 7.20176 71.3853Z"
        fill="#4A4F55"
      />
      <path
        d="M17.9921 74.4416C17.9133 74.4416 17.8363 74.4097 17.78 74.3458C17.6749 74.2257 17.5716 74.1037 17.4665 73.9779C16.6424 73.0186 15.6437 71.9129 14.6731 70.841C13.573 69.6245 12.4373 68.3686 11.555 67.3211C11.4555 67.2028 11.4705 67.0245 11.5888 66.925C11.707 66.8236 11.8854 66.8405 11.9849 66.9588C12.7095 67.8186 13.6106 68.8248 14.5211 69.8329C14.4328 69.6996 14.3446 69.5663 14.2582 69.4311C13.3553 68.0551 12.5011 66.756 11.6714 65.6935C11.5794 65.5752 11.5963 65.4063 11.7089 65.3068C11.8216 65.2092 11.9924 65.2167 12.0956 65.3237C12.8071 66.0614 13.7157 67.0864 14.6468 68.1715C13.7138 66.8668 12.8109 65.6203 12.1576 64.7492C12.0637 64.6253 12.09 64.4489 12.2139 64.355C12.3378 64.2611 12.5143 64.2874 12.6081 64.4113C14.6956 67.1972 19.3325 73.8146 19.3775 73.8803C19.4639 74.0042 19.4376 74.1769 19.3156 74.267C19.1936 74.359 19.0227 74.3365 18.927 74.2182C18.8951 74.1788 16.8526 71.6351 14.7632 69.174C15.7938 70.7434 16.8564 72.3559 17.8964 73.6118C18.0034 73.7357 18.1066 73.8577 18.2061 73.976C18.3056 74.0943 18.2925 74.2708 18.1742 74.3702C18.1235 74.4191 18.0578 74.4416 17.9921 74.4416Z"
        fill="#1D232A"
      />
      <path d="M12.5481 63.9963L19.2987 74.2743L25.5162 71.0698L15.3527 61.3625L12.5481 63.9963Z" fill="#1D232A" />
      <path
        d="M45.5427 77.0771H64.1051C82.0817 77.0771 96.6529 62.504 96.6529 44.5293C96.6529 26.5527 82.0798 11.9814 64.1051 11.9814H45.5427C27.5661 11.9814 12.9949 26.5546 12.9949 44.5293C12.9949 62.504 27.5661 77.0771 45.5427 77.0771Z"
        fill="#BBBDBF"
      />
      <path
        d="M41.4317 76.8201L60.9383 11.9796C53.7277 11.9796 47.9645 11.0692 42.5074 10.2901C24.6903 7.90409 8.31127 20.4123 5.92528 38.2293C3.53928 56.0464 15.3472 74.7326 41.4317 76.8201Z"
        fill="#BBBDBF"
      />
      <path
        d="M31.2194 51.4076C31.1537 51.4076 31.088 51.3851 31.0354 51.3381C28.5443 49.1793 28.9385 46.1062 28.9554 45.9748C28.9761 45.8208 29.1187 45.7138 29.2727 45.7345C29.4266 45.7551 29.5336 45.8978 29.513 46.0517C29.5073 46.0874 29.1525 48.9596 31.4033 50.912C31.5216 51.0134 31.5329 51.1917 31.4315 51.31C31.3771 51.3738 31.2982 51.4076 31.2194 51.4076Z"
        fill="#1D232A"
      />
      <path
        d="M24.0633 51.584C22.7079 51.584 21.5065 51.1954 20.7837 50.6585C20.658 50.5665 20.6335 50.39 20.7255 50.2643C20.8175 50.1385 20.994 50.1141 21.1198 50.2061C22.6891 51.37 26.759 51.7417 29.2821 48.3382C29.3759 48.2143 29.5524 48.1881 29.6763 48.28C29.8021 48.372 29.8265 48.5485 29.7345 48.6743C28.1351 50.8293 25.9518 51.584 24.0633 51.584Z"
        fill="#1D232A"
      />
      <path
        d="M17.5492 28.8129C18.7539 28.8129 19.7305 27.8363 19.7305 26.6316C19.7305 25.4268 18.7539 24.4502 17.5492 24.4502C16.3444 24.4502 15.3678 25.4268 15.3678 26.6316C15.3678 27.8363 16.3444 28.8129 17.5492 28.8129Z"
        fill="#1D232A"
      />
      <path
        d="M30.5923 34.5837C31.797 34.5837 32.7736 33.607 32.7736 32.4023C32.7736 31.1976 31.797 30.2209 30.5923 30.2209C29.3875 30.2209 28.4109 31.1976 28.4109 32.4023C28.4109 33.607 29.3875 34.5837 30.5923 34.5837Z"
        fill="#1D232A"
      />
      <path
        d="M5.87072 44.1857L12.7659 46.8795C16.9071 48.4977 21.574 46.4515 23.1922 42.3103C24.8104 38.1691 22.7642 33.5022 18.6229 31.884L11.7278 29.1901C7.58654 27.572 2.91967 29.6182 1.30148 33.7594C-0.316718 37.8987 1.72949 42.5675 5.87072 44.1857Z"
        fill="#BBBDBF"
      />
      <path
        d="M15.6849 47.7113C14.6787 47.7113 13.6556 47.5274 12.6644 47.1406L5.76922 44.4468C5.62468 44.3905 5.55334 44.2271 5.60966 44.0826C5.66598 43.9381 5.8293 43.8667 5.97385 43.923L12.869 46.6169C16.8582 48.175 21.373 46.1964 22.9311 42.2072C23.804 39.9752 23.605 37.5066 22.3886 35.4379C22.3097 35.3046 22.3548 35.1319 22.4881 35.053C22.6213 34.9742 22.7941 35.0192 22.8729 35.1525C24.1776 37.3714 24.3897 40.0184 23.4548 42.4118C22.1708 45.6989 19.017 47.7113 15.6849 47.7113Z"
        fill="#1D232A"
      />
      <path
        d="M14.7595 42.3648C15.4878 42.6501 16.3101 42.2897 16.5954 41.5594L17.8344 38.3888C18.1198 37.6604 17.7593 36.8382 17.0291 36.5528C16.3007 36.2675 15.4785 36.6279 15.1931 37.3582L13.9541 40.5288C13.6707 41.2572 14.0311 42.0794 14.7595 42.3648Z"
        fill="#4A4F55"
      />
      <path
        d="M7.4626 39.5152C8.19097 39.8006 9.01321 39.4401 9.29855 38.7099L10.5375 35.5392C10.8229 34.8108 10.4625 33.9886 9.7322 33.7032C9.00382 33.4179 8.18158 33.7783 7.89624 34.5086L6.65725 37.6793C6.37379 38.4076 6.73422 39.2299 7.4626 39.5152Z"
        fill="#4A4F55"
      />
      <path
        d="M44.7056 20.4198C46.0028 14.7242 45.066 7.09501 42.5787 4.27161C41.8522 3.44562 40.5155 3.15464 39.8472 4.26598L33.4308 15.3099"
        fill="#BBBDBF"
      />
      <path
        d="M34.4576 13.8213C34.4088 13.8213 34.36 13.8082 34.3168 13.7838C34.1816 13.705 34.1366 13.5341 34.2154 13.399L36.6334 9.23709C36.7122 9.10193 36.8831 9.05688 37.0182 9.13572C37.1534 9.21456 37.1984 9.38539 37.1196 9.52056L34.7016 13.6824C34.6491 13.7707 34.5552 13.8213 34.4576 13.8213Z"
        fill="#1D232A"
      />
      <path
        d="M44.7056 20.7015C44.6849 20.7015 44.6643 20.6996 44.6436 20.694C44.4916 20.6602 44.3977 20.5081 44.4315 20.3561C44.9628 18.0189 45.4077 15.2668 44.5103 9.64257C44.4859 9.48863 44.5911 9.34409 44.745 9.31968C44.8989 9.29528 45.0435 9.4004 45.0679 9.55434C45.9821 15.2781 45.5259 18.0902 44.9815 20.4818C44.9515 20.6132 44.8351 20.7015 44.7056 20.7015Z"
        fill="#1D232A"
      />
      <path
        d="M82.5622 85.8478L88.6014 85.0631C89.4049 84.958 90.0225 84.3029 90.0769 83.4956L89.0294 64.4377H74.077L80.6812 84.5169C80.8671 85.3823 81.6837 85.9605 82.5622 85.8478Z"
        fill="#BBBDBF"
      />
      <path
        d="M44.4558 85.4592H37.5494C36.6539 85.4592 35.8843 84.8285 35.7078 83.9499L29.4828 62.2131H46.012L46.3255 83.7171C46.2561 84.7008 45.4395 85.4592 44.4558 85.4592Z"
        fill="#BBBDBF"
      />
      <path
        d="M49.1978 17.1496C53.3784 18.0619 59.0102 18.2647 62.6277 17.831C62.7929 17.8122 62.9168 17.6208 62.913 17.3992L62.8755 14.8481C62.8717 14.6115 62.7234 14.4238 62.5489 14.4294C58.8544 14.5552 53.2733 14.3675 49.2823 13.5565C49.0439 13.5077 48.828 13.5527 48.8336 13.8456L48.8749 16.6915C48.8787 16.9168 49.0138 17.1101 49.1978 17.1496Z"
        fill="#4A4F55"
      />
      <path
        d="M49.1978 17.1496C53.3785 18.0619 59.0102 18.2646 62.6277 17.831C62.7929 17.8122 62.9168 17.6207 62.913 17.3992L62.8924 15.9913C62.8886 15.7548 62.7403 15.5689 62.5658 15.5764C58.8732 15.7379 53.2902 15.6065 49.3011 14.8368C49.0627 14.7899 48.8468 14.8368 48.8524 15.1296L48.8749 16.6915C48.8787 16.9168 49.0138 17.1101 49.1978 17.1496Z"
        fill="#1D232A"
      />
      <path
        d="M33.2992 75.8233C33.1772 75.8233 33.0646 75.7426 33.0289 75.6187L30.9114 68.226C30.8682 68.0758 30.9546 67.92 31.1047 67.8768C31.253 67.8337 31.4107 67.92 31.4539 68.0702L33.5714 75.4628C33.6146 75.613 33.5283 75.7688 33.3781 75.812C33.3518 75.8195 33.3255 75.8233 33.2992 75.8233Z"
        fill="#1D232A"
      />
      <path
        d="M77.6212 75.5378C77.501 75.5378 77.3903 75.4609 77.3528 75.3407L75.2934 68.806C75.2465 68.6577 75.3291 68.5 75.4774 68.4531C75.6257 68.4062 75.7834 68.4888 75.8303 68.6371L77.8896 75.1718C77.9366 75.3201 77.854 75.4778 77.7057 75.5247C77.6775 75.5322 77.6494 75.5378 77.6212 75.5378Z"
        fill="#1D232A"
      />
      <g filter="url(#filter0_d_4567_55)">
        <path
          d="M88.2275 104.9C99.8394 104.9 109.253 95.4868 109.253 83.8749C109.253 72.2629 99.8394 62.8496 88.2275 62.8496C76.6155 62.8496 67.2022 72.2629 67.2022 83.8749C67.2022 95.4868 76.6155 104.9 88.2275 104.9Z"
          fill="#4A4F55"
        />
      </g>
      <path
        d="M98.3155 90.8415C99.177 91.7048 99.177 93.1013 98.3155 93.9629C97.8847 94.3937 97.3197 94.6091 96.7548 94.6091C96.1898 94.6091 95.6249 94.3937 95.1941 93.9629L88.2274 86.9963L81.259 93.9629C80.8282 94.3937 80.2633 94.6091 79.6983 94.6091C79.1334 94.6091 78.5684 94.3937 78.1376 93.9629C77.2761 93.1013 77.2761 91.7048 78.1376 90.8415L85.106 83.8749L78.1376 76.9065C77.2761 76.0449 77.2761 74.6466 78.1376 73.7851C78.9992 72.9235 80.3957 72.9235 81.259 73.7851L88.2274 80.7535L95.1941 73.7851C96.0556 72.9235 97.4539 72.9235 98.3155 73.7851C99.177 74.6466 99.177 76.0449 98.3155 76.9065L91.3488 83.8749L98.3155 90.8415Z"
        fill="#FCFCFC"
      />
      <defs>
        <filter
          id="filter0_d_4567_55"
          x="64.9495"
          y="62.0987"
          width="46.556"
          height="46.556"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.5018" />
          <feGaussianBlur stdDeviation="1.12635" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.290196 0 0 0 0 0.309804 0 0 0 0 0.333333 0 0 0 0.32 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4567_55" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4567_55" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
