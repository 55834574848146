export function KeyLoadingIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="10em" height="10em" viewBox="0 0 108 132" fill="none" {...props}>
      <path
        d="M40.217 6.198L25.092 28.886c-3.763 5.641-2.24 13.268 3.392 17.03l34.77 23.252c5.633 3.77 13.247 2.244 17.002-3.398l15.125-22.687c3.497-5.247 2.457-12.3-2.41-16.304L76.743 13.421a12.369 12.369 0 00-2.03-1.355L56.17 2.178c-5.56-2.97-12.457-1.226-15.954 4.02zm18.13 8.402a4.23 4.23 0 015.858-1.17l15.31 10.234c1.934 1.29 2.457 3.93 1.168 5.867l-3.038 4.552a4.23 4.23 0 01-5.858 1.17L56.477 25.02c-1.933-1.292-2.457-3.931-1.168-5.868l3.038-4.552z"
        fill="#1D232A"
      />
      <path
        d="M57.243 59.313L40.434 48.072 0 108.74l2.385 7.183 11.58-1.832-.299-2.696 2.434-3.955-2.41-1.614 10.596-4.681-2.393-3.293.806-4.35 5.632-1.72 5.012-4.229-.781-2.421 6.253-9.379 3.698 2.075 4.368-2.97 10.362-15.545z"
        fill="#00A868"
      />
      <path opacity={0.5} d="M46.445 47.24L1.813 114.204l-.943-2.833 43.617-65.44 1.958 1.307z" fill="#1D232A" />
      <path
        d="M41.506 5.463L26.381 28.151c-3.762 5.642-2.24 13.269 3.393 17.03l34.77 23.253c5.632 3.769 13.247 2.243 17.002-3.398L96.67 42.348c3.497-5.246 2.458-12.3-2.409-16.303L78.032 12.679a12.358 12.358 0 00-2.03-1.356L57.46 1.444C51.9-1.526 45.003.217 41.506 5.464zm18.13 8.402a4.23 4.23 0 015.858-1.17l15.31 10.234c1.934 1.292 2.458 3.93 1.169 5.868l-3.038 4.552a4.23 4.23 0 01-5.858 1.17l-15.31-10.234c-1.934-1.291-2.458-3.93-1.169-5.867l3.038-4.553z"
        fill="#00A868"
      />
      <path
        d="M55.285 62.243l-11.208-6.037-40.66 60.565 2.336 7.24 11.652-1.945-.274-2.72 2.482-4.02-2.41-1.614 10.685-4.826-2.377-3.309.846-4.407 5.673-1.792 5.068-4.318-.765-2.437 6.358-9.54 3.706 2.066 4.408-3.035 4.48-19.87z"
        fill="#008E5A"
      />
      <g filter="url(#prefix__filter0_d_10925_62601)">
        <path
          d="M77.264 127C92.582 127 105 114.562 105 99.219c0-15.343-12.418-27.781-27.736-27.781-15.318 0-27.736 12.438-27.736 27.78 0 15.344 12.418 27.782 27.736 27.782z"
          fill="#E8E9EA"
        />
      </g>
      <path
        d="M95.034 87.716l-1.688 7.854a2.332 2.332 0 01-2.76 1.792l-7.849-1.668a2.335 2.335 0 01-1.795-2.767 2.33 2.33 0 012.762-1.796l2.976.632c-2.981-3.767-8.075-5.528-12.933-4.035a11.955 11.955 0 00-6.921 5.566 2.328 2.328 0 01-3.175.881 2.336 2.336 0 01-.88-3.18 16.598 16.598 0 019.61-7.728c6.611-2.03 13.537.28 17.706 5.3l.394-1.833a2.327 2.327 0 012.767-1.79 2.33 2.33 0 011.786 2.772zM91.76 107.445a16.595 16.595 0 01-9.607 7.728 16.568 16.568 0 01-4.877.735 16.727 16.727 0 01-12.83-6.033l-.394 1.832a2.332 2.332 0 01-2.77 1.789 2.335 2.335 0 01-1.786-2.772l1.691-7.854a2.328 2.328 0 012.76-1.792l7.849 1.668a2.332 2.332 0 011.793 2.767 2.325 2.325 0 01-2.76 1.796l-2.979-.632c2.984 3.768 8.077 5.529 12.936 4.036a11.964 11.964 0 006.921-5.566 2.324 2.324 0 013.175-.88 2.33 2.33 0 01.878 3.178z"
        fill="#00A868"
      />
      <defs>
        <filter
          id="prefix__filter0_d_10925_62601"
          x={46.528}
          y={70.438}
          width={61.472}
          height={61.563}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={1.5} />
          <feColorMatrix values="0 0 0 0 0.290196 0 0 0 0 0.309804 0 0 0 0 0.333333 0 0 0 0.32 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_10925_62601" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_10925_62601" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
