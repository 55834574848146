import { type ComponentProps, useEffect } from 'react'
import { Icon, IconButton } from '@dlpco/ginga-stone'
import styled from 'styled-components'
import { theme } from 'styled-tools'

export const ToastContainer = styled.div`
  align-items: center;
  background-color: ${theme('colors.black')};
  border-radius: 8px;
  bottom: 5rem;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.12), 0 6px 12px -2px rgba(0, 0, 0, 0.04);
  color: ${theme('colors.white')};
  display: grid;
  grid-template-columns: 20px auto 36px;
  grid-gap: 12px;
  min-height: 54px;
  padding: 0.5rem;
  padding-left: 1rem;
  position: fixed;
  right: 1.5rem;
  width: 328px;
  z-index: ${theme('zIndices.toast')};
`

const icon: Record<ToastProps['type'], ComponentProps<typeof Icon>['use']> = {
  error: 'round-error-outline',
  info: 'round-info-outline',
  success: 'round-check-outline'
}

const label: Record<ToastProps['type'], string> = {
  error: 'Erro',
  success: 'Sucesso',
  info: 'Informativo'
}

export const Toast = ({ message, type, remove }: ToastProps) => {
  useEffect(() => {
    const id = setTimeout(remove, 5000)
    return () => clearTimeout(id)
  }, [remove])
  return (
    <ToastContainer>
      <Icon use={icon[type]} role="img" aria-label={label[type]} size="large" inheritColor />
      <p>{message}</p>
      <IconButton
        color="onColor"
        data-testid="toast-close"
        icon="close-outline"
        onClick={() => remove()}
        size="small"
        variant="contentOnly"
      />
    </ToastContainer>
  )
}

interface ToastProps {
  message: string
  type: 'success' | 'info' | 'error'
  remove: (id?: string) => void
}
