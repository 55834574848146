import dayjs from 'dayjs'
import Linq from 'linq'
import curry from 'lodash.curry'
import groupBy from 'lodash.groupby'

import {
  type AcquirerReceivables,
  type MerchantsContentProps,
  type PaymentAttempt,
  type PaymentInformation,
  type Receivable,
  type ReceivableAccount,
  type SettlementStatusId,
  type WeeklyReceivable
} from '~/lib/types'

const LINK_ABC = 'Link ABC'

const allMerchantContent = curry(function allMerchantContent(
  merchants: MerchantsContentProps[],
  filterFn: (merchant: MerchantsContentProps) => boolean
) {
  const filteredMerchants = merchants.filter(filterFn)
  const affiliationCodeMerchants = filteredMerchants.map(({ affiliationCode }) => affiliationCode).join(',')

  return affiliationCodeMerchants
})

const onlyPaymentLinks = curry(function onlyPaymentLinks(
  stoneCodes: string,
  { salesChannelName, affiliationCode }: MerchantsContentProps
) {
  return salesChannelName === LINK_ABC && stoneCodes.includes(affiliationCode)
})

const otherPaymentTypes = curry(function otherPaymentTypes(
  stoneCodes: string,
  { salesChannelName, affiliationCode }: MerchantsContentProps
) {
  return salesChannelName !== LINK_ABC && stoneCodes.includes(affiliationCode)
})

function distinguishMerchantsByStoneCodes(stoneCodes: string, merchantsContent: MerchantsContentProps[]) {
  const filterMerchantContent = allMerchantContent(merchantsContent)
  return [filterMerchantContent(onlyPaymentLinks(stoneCodes)), filterMerchantContent(otherPaymentTypes(stoneCodes))]
}

const normalizeData = (paymentType: string, data: any) =>
  ({
    ...data,
    paymentType,
    type: 'acquirer',
    referenceDate: dayjs(data.referenceDate).format('YYYY-MM-DD'),
    scheduledTo: dayjs(data.referenceDate).format('YYYY-MM-DD'),
    scheduledToEffective: dayjs(data.referenceDate).format('YYYY-MM-DD'),
    operationId: String(data.walletTypeId),
    amount: data.amount * 100
  } as AcquirerReceivables)

const groupOnDate = (arr: Omit<WeeklyReceivable, 'quantity'>[]): WeeklyReceivable[] =>
  Linq.from(arr)
    .groupBy(f => f.referenceDate)
    .select(g => ({
      referenceDate: g.key(),
      amount: g.sum(r => r.amount),
      quantity: g.count()
    }))
    .toArray()

const groupOnArrangement = (receivables: Receivable[]): Omit<WeeklyReceivable, 'quantity'>[] =>
  Linq.from(receivables)
    .groupBy(f =>
      JSON.stringify({
        referenceDate: f.referenceDate,
        settlementStatusId: f.settlementStatusId,
        walletTypeId: f.walletTypeId
      })
    )
    .select(g => {
      const keys = JSON.parse(g.key())

      return {
        referenceDate: keys.referenceDate,
        amount: g.sum(r => r.amount)
      }
    })
    .toArray()

const weeklyReceivablesFactory = (obj: Receivable[]): WeeklyReceivable[] => groupOnDate(groupOnArrangement(obj))

const sumTotalReceivablesAmount = (receivables: Receivable[]): number => {
  let sumTotal = 0
  receivables.map(x => (sumTotal += x.amount))
  return sumTotal
}

const groupByReceivableAccount = (param: ReceivableAccount[]): ReceivableAccount[][] => {
  const grouppedBy = groupBy(param, pa => `${pa.bankCode}__${pa.branch}__${pa.accountNumber}`)
  return Object.values(grouppedBy)
}

const byPaymentDate = (param: PaymentAttempt): number => new Date(param.paymentDate).getTime()

const getLastPaymentAttempt = (param: PaymentAttempt[]): PaymentAttempt => {
  const sortedData = param.sort(byPaymentDate)

  return sortedData[sortedData.length - 1]
}

const reducePaymentInformations = (param: PaymentInformation[]) =>
  param.reduce((acc, elem) => {
    const lastPayment = getLastPaymentAttempt(elem.paymentAttempts)

    if (!lastPayment) return acc

    const paidStatus: SettlementStatusId = 30

    return [
      ...acc,
      {
        bankCode: lastPayment.bankCode,
        accountNumber: lastPayment.accountNumber,
        accountCheckDigit: lastPayment.accountCheckDigit,
        accountNumberWithCheckDigit: `${lastPayment.accountNumber}${
          lastPayment.accountCheckDigit != null && lastPayment.accountCheckDigit !== 'N/I'
            ? `-${lastPayment.accountCheckDigit}`
            : ''
        }`,
        branch: lastPayment.branch,
        bankName: lastPayment.bankName,
        amount: elem.settlementStatusId === paidStatus ? elem.amount : 0
      }
    ]
  }, [] as ReceivableAccount[])

const reduceCommonAccounts = (param: ReceivableAccount[]) =>
  param.reduce(
    (acc, elem) => {
      return {
        ...acc,
        ...elem,
        amount: acc.amount + elem.amount
      }
    },
    { amount: 0 } as ReceivableAccount
  )

const sortAccounts = (receivableAccounts: ReceivableAccount[]) => {
  const stoneAccount = receivableAccounts.find(acc => acc.bankCode === '197')
  const filteredAccounts = receivableAccounts.filter(acc => acc.bankCode !== '197')

  const descAccounts = (a: ReceivableAccount, b: ReceivableAccount): number => {
    if (a.amount > b.amount) return -1
    if (a.amount < b.amount) return 1

    return 0
  }

  const ascAccounts = (a: ReceivableAccount, b: ReceivableAccount): number => {
    if (a.bankCode < b.bankCode) return -1
    if (a.bankCode > b.bankCode) return 1

    return 0
  }

  const others = filteredAccounts.sort(ascAccounts).sort(descAccounts)

  return stoneAccount ? [stoneAccount, ...others] : others
}

const receivableAccounts = (list: PaymentInformation[]) => {
  const reducedPaymentInformations = reducePaymentInformations(list)
  const grouppedByReceivableAccount = groupByReceivableAccount(reducedPaymentInformations)
  const mappedReducedCommonAccounts = grouppedByReceivableAccount.map(reduceCommonAccounts)
  const sorttedAccounts = sortAccounts(mappedReducedCommonAccounts)

  return sorttedAccounts
}

const multiplyAmountByOneHundred = (array: Record<string, any>[]): Record<string, any>[] =>
  array.map(obj => {
    return { ...obj, amount: obj.amount * 100 }
  })

export const acquirerSDK = {
  normalizeData,
  distinguishMerchantsByStoneCodes,
  weeklyReceivablesFactory,
  sumTotalReceivablesAmount,
  receivableAccounts,
  multiplyAmountByOneHundred
}
