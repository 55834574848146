export function LoginDeviceAuthorizationIllustration(props: React.SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg width="394" height="291" viewBox="0 0 394 291" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="6.17139" y="30.8568" width="133.713" height="226.283" fill="#D0D5DD" />
      <rect x="7.4812" y="30.0171" width="130.078" height="50.0301" fill="#008E5A" />
      <rect x="14.3998" y="84.3418" width="117.256" height="34.971" rx="4.11424" fill="#303742" />
      <rect x="15.9738" y="125.484" width="113.142" height="43.1995" fill="white" />
      <rect x="14.3998" y="176.912" width="115.199" height="55.5422" fill="white" />
      <rect x="14.9865" y="239.204" width="52.5316" height="17.5105" fill="white" />
      <rect x="77.5197" y="239.204" width="52.5316" height="17.5105" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 269.816C0.00504044 276.126 3.40336 287.168 17.4427 288.037C27.3729 288.569 50.5587 289.643 69.046 289.983C69.8383 290.038 70.692 290.06 71.6106 290.049C71.8931 290.046 72.1771 290.042 72.4627 290.038C72.7926 290.043 73.1206 290.047 73.4464 290.051C74.3918 290.062 75.2687 290.038 76.0805 289.98C94.5564 289.638 117.696 288.567 127.614 288.036C141.652 287.166 145.051 276.126 145.057 269.816H145.087V21.2843H145.025C145.41 15.242 142.467 2.93654 127.614 2.01629C117.69 1.48455 94.5277 0.4121 76.0461 0.0710966C75.2439 0.0150968 74.3785 -0.00852379 73.4464 0.00273719C73.1422 0.0063993 72.8362 0.0103361 72.5285 0.014517C72.2207 0.0103361 71.9147 0.0063993 71.6106 0.00273719C70.6785 -0.00852379 69.8131 0.0150968 69.0109 0.0710966C50.5293 0.4121 27.3666 1.48455 17.4427 2.01629C2.58958 2.93654 -0.353165 15.242 0.0319206 21.2843H0V269.802V269.816ZM9.14244 31.8072C8.92655 31.8072 8.75154 31.9823 8.75154 32.1981V254.744C8.75154 254.96 8.92656 255.135 9.14244 255.135H136.679C136.895 255.135 137.07 254.96 137.07 254.744V32.1981C137.07 31.9822 136.895 31.8072 136.679 31.8072H9.14244Z"
        fill="#767F8D"
      />
      <rect x="7.4812" y="30.0171" width="130.078" height="50.0301" fill="#008E5A" />
      <rect x="14.3998" y="84.3418" width="117.256" height="34.971" rx="4.11424" fill="#303742" />
      <rect x="14.3998" y="176.912" width="115.199" height="55.5422" fill="white" />
      <rect x="14.9865" y="239.204" width="52.5316" height="17.5105" fill="white" />
      <rect x="77.5197" y="239.204" width="52.5316" height="17.5105" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 269.816C0.00504044 276.126 3.40336 287.168 17.4427 288.037C27.3729 288.569 50.5587 289.643 69.046 289.983C69.8383 290.038 70.692 290.06 71.6106 290.049C71.8931 290.046 72.1771 290.042 72.4627 290.038C72.7926 290.043 73.1206 290.047 73.4464 290.051C74.3918 290.062 75.2687 290.038 76.0805 289.98C94.5564 289.638 117.696 288.567 127.614 288.036C141.652 287.166 145.051 276.126 145.057 269.816H145.087V21.2843H145.025C145.41 15.242 142.467 2.93654 127.614 2.01629C117.69 1.48455 94.5277 0.4121 76.0461 0.0710966C75.2439 0.0150968 74.3785 -0.00852379 73.4464 0.00273719C73.1422 0.0063993 72.8362 0.0103361 72.5285 0.014517C72.2207 0.0103361 71.9147 0.0063993 71.6106 0.00273719C70.6785 -0.00852379 69.8131 0.0150968 69.0109 0.0710966C50.5293 0.4121 27.3666 1.48455 17.4427 2.01629C2.58958 2.93654 -0.353165 15.242 0.0319206 21.2843H0V269.802V269.816ZM9.14244 31.8072C8.92655 31.8072 8.75154 31.9823 8.75154 32.1981V254.744C8.75154 254.96 8.92656 255.135 9.14244 255.135H136.679C136.895 255.135 137.07 254.96 137.07 254.744V32.1981C137.07 31.9822 136.895 31.8072 136.679 31.8072H9.14244Z"
        fill="#A6AEBA"
      />
      <rect x="54.6384" y="260.191" width="37.1832" height="22.4051" rx="3.90896" fill="white" />
      <rect x="54.6384" y="260.191" width="37.1832" height="22.4051" rx="3.90896" fill="white" />
      <circle cx="30.8568" cy="156.341" r="8.22847" fill="#D0D5DD" />
      <circle cx="57.5993" cy="156.341" r="8.22847" fill="#D0D5DD" />
      <circle cx="84.3419" cy="156.341" r="8.22847" fill="#D0D5DD" />
      <circle cx="111.084" cy="156.341" r="8.22847" fill="#D0D5DD" />
      <rect x="255.083" y="30.8568" width="133.713" height="226.283" fill="#D0D5DD" />
      <rect x="256.657" y="59.6565" width="129.598" height="197.483" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M376.519 288.037C390.561 287.167 393.958 276.122 393.962 269.813H394V21.2788H393.93C394.313 15.2343 391.368 2.93631 376.519 2.01632C366.597 1.48465 343.441 0.412502 324.961 0.0712853C324.158 0.0151635 323.292 -0.00854871 322.358 0.0027428C322.053 0.00643543 321.746 0.0103722 321.437 0.0145531C321.231 0.011776 321.026 0.00909045 320.822 0.00652698L320.515 0.0027428C319.582 -0.00854871 318.715 0.0151635 317.912 0.0712853C299.432 0.412502 276.276 1.48465 266.354 2.01632C251.506 2.93631 248.56 15.2343 248.943 21.2788H248.911V269.813H248.911C248.916 276.123 252.314 287.165 266.354 288.035C276.27 288.567 299.401 289.638 317.874 289.98C318.688 290.038 319.567 290.062 320.515 290.051C320.844 290.047 321.176 290.043 321.51 290.038C321.794 290.042 322.077 290.046 322.358 290.049C323.277 290.06 324.13 290.037 324.922 289.983C343.408 289.643 366.59 288.569 376.519 288.037ZM258.077 31.8055C257.861 31.8055 257.686 31.9805 257.686 32.1964V254.745C257.686 254.961 257.861 255.136 258.077 255.136H385.615C385.831 255.136 386.006 254.961 386.006 254.745V32.1964C386.006 31.9805 385.831 31.8055 385.615 31.8055H258.077Z"
        fill="#A6AEBA"
      />
      <rect x="303.603" y="260.186" width="37.1835" height="22.4053" rx="3.90896" fill="white" />
      <rect x="263.94" y="230.136" width="52.5321" height="17.5107" fill="#A6AEBA" />
      <rect x="326.477" y="230.136" width="52.5321" height="17.5107" fill="#008E5A" />
      <rect x="277.711" y="154.284" width="59.6564" height="4.11424" fill="#008E5A" />
      <rect
        x="267.425"
        y="143.998"
        width="111.084"
        height="49.3708"
        rx="4.11424"
        stroke="#A6AEBA"
        strokeWidth="2.05712"
      />
      <rect
        x="352.796"
        y="161.484"
        width="14.3998"
        height="14.3998"
        rx="3.08568"
        stroke="#A6AEBA"
        strokeWidth="2.05712"
      />
      <path
        d="M229.067 101.526C229.469 101.124 229.469 100.473 229.067 100.072L222.522 93.5258C222.12 93.1241 221.469 93.1241 221.067 93.5258C220.665 93.9275 220.665 94.5787 221.067 94.9804L226.886 100.799L221.067 106.617C220.665 107.019 220.665 107.67 221.067 108.072C221.469 108.474 222.12 108.474 222.522 108.072L229.067 101.526ZM164.569 101.827H228.34V99.7703H164.569V101.827Z"
        fill="black"
      />
    </svg>
  )
}

export function LoginDeviceSmallMobileAuthorizationIllustration(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg width="80" height="80" viewBox="0 0 40 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="1.70135" y="8.50702" width="36.8641" height="62.3853" fill="#D0D5DD" />
      <rect x="2.06244" y="8.27551" width="35.8621" height="13.7931" fill="#008E5A" />
      <rect x="3.97009" y="24.3869" width="31.7598" height="11.9099" fill="white" />
      <rect x="3.97009" y="38.5654" width="31.7598" height="15.3128" fill="white" />
      <rect x="4.13184" y="63.679" width="14.4828" height="4.82759" fill="white" />
      <rect x="21.3719" y="63.679" width="14.4828" height="4.82759" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 74.3872C0.00137228 76.1268 0.938258 79.171 4.80888 79.4108C7.54656 79.5575 13.9386 79.8534 19.0354 79.9472C19.2539 79.9622 19.4894 79.9685 19.7428 79.9654L19.9783 79.9624L20.2489 79.9659C20.5094 79.969 20.7511 79.9623 20.9748 79.9464C26.0686 79.8522 32.4484 79.5568 35.1828 79.4103C39.0531 79.1705 39.9901 76.1268 39.9917 74.3872H40V5.86795H39.9829C40.089 4.20207 39.2777 0.809593 35.1828 0.555885C32.4468 0.409278 26.0609 0.113616 20.9656 0.0195992C20.7445 0.00416498 20.5059 -0.00235052 20.2489 0.000754643L19.9958 0.00399714L19.7428 0.000754643C19.4858 -0.00235052 19.2472 0.00416498 19.026 0.0195992C13.9308 0.113616 7.54488 0.409278 4.80888 0.555885C0.713989 0.809593 -0.0973457 4.20207 0.00879637 5.86795H0V74.3834V74.3872ZM2.52035 8.76929C2.46084 8.76929 2.41259 8.81754 2.41259 8.87706V70.2322C2.41259 70.2917 2.46084 70.34 2.52036 70.34H37.6817C37.7412 70.34 37.7895 70.2917 37.7895 70.2322V8.87706C37.7895 8.81754 37.7412 8.76929 37.6817 8.76929H2.52035Z"
        fill="#767F8D"
      />
      <rect x="2.06244" y="8.27551" width="35.8621" height="13.7931" fill="#008E5A" />
      <rect x="3.97009" y="56.1469" width="14.4828" height="4.82759" fill="white" />
      <rect x="21.2102" y="56.1469" width="14.4828" height="4.82759" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 74.3872C0.00137228 76.1268 0.938258 79.171 4.80888 79.4108C7.54656 79.5575 13.9386 79.8534 19.0354 79.9472C19.2539 79.9622 19.4894 79.9685 19.7428 79.9654L19.9783 79.9624L20.2489 79.9659C20.5094 79.969 20.7511 79.9623 20.9748 79.9464C26.0686 79.8522 32.4484 79.5568 35.1828 79.4103C39.0531 79.1705 39.9901 76.1268 39.9917 74.3872H40V5.86795H39.9829C40.089 4.20207 39.2777 0.809593 35.1828 0.555885C32.4468 0.409278 26.0609 0.113616 20.9656 0.0195992C20.7445 0.00416498 20.5059 -0.00235052 20.2489 0.000754643L19.9958 0.00399714L19.7428 0.000754643C19.4858 -0.00235052 19.2472 0.00416498 19.026 0.0195992C13.9308 0.113616 7.54488 0.409278 4.80888 0.555885C0.713989 0.809593 -0.0973457 4.20207 0.00879637 5.86795H0V74.3834V74.3872ZM2.52035 8.76929C2.46084 8.76929 2.41259 8.81754 2.41259 8.87706V70.2322C2.41259 70.2917 2.46084 70.34 2.52036 70.34H37.6817C37.7412 70.34 37.7895 70.2917 37.7895 70.2322V8.87706C37.7895 8.81754 37.7412 8.76929 37.6817 8.76929H2.52035Z"
        fill="#A6AEBA"
      />
      <rect x="15.0636" y="71.7335" width="10.2513" height="6.17699" rx="1.07769" fill="white" />
      <rect x="15.0636" y="71.7335" width="10.2513" height="6.17699" rx="1.07769" fill="white" />
      <path
        d="M18.8935 63.1781C19.2868 63.5714 19.9244 63.5714 20.3177 63.1781L26.7268 56.7691C27.1201 56.3758 27.1201 55.7381 26.7268 55.3448C26.3335 54.9516 25.6958 54.9516 25.3025 55.3448L19.6056 61.0418L13.9087 55.3448C13.5154 54.9516 12.8777 54.9516 12.4844 55.3448C12.0911 55.7381 12.0911 56.3758 12.4844 56.7691L18.8935 63.1781ZM18.5985 29.128L18.5985 45.797L20.6127 45.797L20.6127 29.128L18.5985 29.128ZM18.5985 45.797L18.5985 62.466L20.6127 62.466L20.6127 45.797L18.5985 45.797Z"
        fill="#088037"
      />
      <circle opacity="0.5" cx="19.6074" cy="29.8232" r="8.3866" stroke="#DCF2E4" strokeWidth="7.0496" />
      <circle opacity="0.5" cx="19.6133" cy="29.8999" r="4.02835" fill="#14AA4B" />
    </svg>
  )
}
