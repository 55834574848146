import { parseSubjectToPerson } from '~/domains/platform/infra/deus-ex-machina/helpers/subject-to-person'
import { type Person, type Subject } from '~/domains/platform/infra/deus-ex-machina/types'

export function entitiesListFactory({
  organizations,
  subject,
  users
}: {
  organizations: Person[]
  subject: Subject
  users: Person[]
}) {
  let list: Person[] = [...organizations]

  if (subject.unsafe.roles?.acquirer || subject.unsafe.roles?.banking) {
    list = list.concat([parseSubjectToPerson(subject)])
  }

  list = list.concat([...users].filter(entity => entity.roles.banking || entity.roles.acquirer))

  return list
}
