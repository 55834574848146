import { type Metadata, type RichEvent, Analitica } from '@stone-payments/analitica'
import { AmplitudePlugin } from '@stone-payments/analitica/plugin-amplitude'
import { DatadogPlugin } from '@stone-payments/analitica/plugin-datadog'
import { LogrPlugin } from '@stone-payments/analitica/plugin-logr'
import { Experiment } from '@stone-payments/experiment'
import { AmplitudeExperimentJS } from '@stone-payments/experiment/plugins/amplitude-experiment-js'
import { type AxiosError } from 'axios'

import { isAxiosError } from '~/domains/banking/transfer/helpers/is-axios-error'
import {
  AMPLITUDE_API_KEY,
  APP_ENV,
  APP_VERSION,
  DATADOG_APPLICATION_ID,
  DATADOG_CLIENT_TOKEN,
  EXPERIMENT_API_KEY,
  LOG_API_TOKEN
} from '~/lib/constants'

import { errorMultiplexer, userFactory } from './analitica.utils'

const appEnv = APP_ENV() as string
const appVersion = APP_VERSION() as string
const apiKey = AMPLITUDE_API_KEY() as string
const apiKeyExperiment = EXPERIMENT_API_KEY() as string
class AmplitudeDashboard extends AmplitudePlugin {
  name = 'AMPLITUDE'
  constructor(apiKey: string, options: unknown) {
    super(apiKey, options)
  }

  sendEvent(event: RichEvent): void {
    const { metadata, label, ...rest } = event

    this.instance?.logEvent(label, { ...metadata, ...rest })
  }
}
class LogrDashboard extends LogrPlugin {
  name = 'LOGR'
  constructor(options: unknown) {
    super(options)
  }
  sendEvent(event: RichEvent): void {
    if (event.metadata?.error) {
      const {
        metadata: { error }
      } = event
      const user = userFactory()
      const errorStructure = {
        error: errorMultiplexer(error, window?.location?.pathname),
        user,
        response: isAxiosError(error) ? (error as AxiosError)?.response : null
      }
      super.sendEvent({
        ...event,
        metadata: {
          ...event.metadata,
          ...errorStructure,
          user: errorStructure.user as unknown as Record<string, unknown>
        }
      })
    }
  }
}

export const analiticaInstance = new Analitica<Metadata & { id?: string }>(
  {
    app: { version: appVersion },
    user: {
      id: undefined
    }
  },
  [
    new AmplitudeDashboard(apiKey, { defaultTracking: false }),
    new LogrDashboard({ apiToken: LOG_API_TOKEN() }),
    new DatadogPlugin({
      applicationId: DATADOG_APPLICATION_ID(),
      clientToken: DATADOG_CLIENT_TOKEN(),
      site: 'datadoghq.com',
      service: 'conta-stone',
      version: APP_VERSION(),
      env: appEnv,
      costCenter: '830190020',
      githubOrg: 'dlpco',
      githubRepo: 'dashboard',
      owner: 'shared-owner',
      project: 'dashboard',
      support: 'interaction-plataforms',
      sessionSampleRate: 10,
      sessionReplaySampleRate: appEnv === 'prod' ? 5 : 0,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask'
    })
  ],
  { dryRun: !['prod', 'sdb'].includes(appEnv) }
)
export const experimentInstance = new Experiment([new AmplitudeExperimentJS(apiKeyExperiment)])
