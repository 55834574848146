export function RegistrationStoreIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={20} cy={20} r={20} fill="#00A868" fillOpacity={0.1} />
      <mask
        id="store_illustration"
        style={{
          maskType: 'alpha'
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={40}
        height={40}
      >
        <circle cx={20} cy={20} r={20} fill="#C4C4C4" />
      </mask>
      <g clipPath="url(#store_illustration_2)" mask="url(#store_illustration)">
        <path d="M51.961 3.511H15.108v36.565H51.96V3.51Z" fill="#00A868" />
        <path d="M35.585 40.076h16.377V3.51H35.585v36.565Z" fill="#008E5A" />
        <path d="M18.006 40.076h14.242V17.053H18.006v23.023Z" fill="#E8E9EA" />
        <path d="M32.248 17.25H17.94v22.826h14.308V17.25Z" fill="#008E5A" />
        <path d="M32.248 17.25h-10.3v22.826h10.3V17.25Z" fill="#00A868" />
        <path d="M32.248 15.1h-8.623v15.627h8.623V15.099Z" fill="#42EC9A" />
        <path
          d="M32.147 15.2v6.978l-5.714 8.423h-2.707v-7.162l5.787-8.239h2.634Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
          strokeWidth={0.202}
        />
        <path d="M7.377 11.421v5.631H32.25V6.716H12.043c-2.577 0-4.666 2.107-4.666 4.705Z" fill="#42EC9A" />
        <path d="M28.34 10.904H7.377v6.352H28.34v-6.352Z" fill="#00A868" />
        <path d="M26.146 11.421v5.836h6.102V6.717h-1.435c-2.578 0-4.666 2.106-4.666 4.704Z" fill="#1D232A" />
        <path
          d="M19.903 10.932h3.134c.243-2.368 2.228-4.215 4.641-4.215h-3.133a4.629 4.629 0 0 0-3.22 1.3 4.713 4.713 0 0 0-1.422 2.915Z"
          fill="#00A868"
        />
        <path
          d="M19.879 11.421v5.836h3.134v-5.836c0-.166.008-.329.024-.49h-3.133c-.017.161-.025.324-.025.49Z"
          fill="#008E5A"
        />
        <path
          d="M13.65 10.932h3.134c.242-2.359 2.21-4.2 4.61-4.215H18.29a4.629 4.629 0 0 0-3.219 1.3 4.708 4.708 0 0 0-1.421 2.915Z"
          fill="#00A868"
        />
        <path
          d="M13.625 11.421v5.836h3.134v-5.836c0-.166.008-.329.024-.49H13.65a4.64 4.64 0 0 0-.025.49Z"
          fill="#008E5A"
        />
        <path
          d="M7.396 10.932h3.133c.243-2.359 2.212-4.201 4.612-4.215h-3.104c-2.413 0-4.398 1.846-4.641 4.215Z"
          fill="#00A868"
        />
        <path
          d="M7.37 11.421v5.836h3.134v-5.836c0-.166.009-.329.025-.49H7.395a4.805 4.805 0 0 0-.024.49Z"
          fill="#008E5A"
        />
        <path
          d="M35.585 20.246v.304c.896.538 1.792 1.076 2.69 1.613l3.915 2.351 3.93 2.36 3.917 2.35 1.924 1.155v-.304l-2.252-1.351-3.916-2.351-3.93-2.36-3.916-2.35-2.362-1.417ZM42.223 25.266l-1.963-1.263 1.72 1.206 3.737 2.618 3.725 2.61 2.52 1.765v.32l-2.233-1.563-3.724-2.61-3.738-2.619-.215-.15 9.91 7.162v7.334H35.586v-19.39c.83.535 1.661 1.07 2.493 1.603 1.277.823 2.556 1.644 3.833 2.466l3.85 2.474 3.834 2.466c.789.507 1.577 1.015 2.366 1.521v.311l-2.056-1.322-3.835-2.465-3.848-2.474Z"
          fill="#1D232A"
        />
        <path d="M32.016 32.966v4.578h-8.16v-4.578h8.16Zm.232-.233h-8.623v5.044h8.623v-5.044Z" fill="#008E5A" />
      </g>
      <defs>
        <clipPath id="store_illustration_2">
          <path fill="#fff" transform="translate(7.37 3.511)" d="M0 0h44.591v36.565H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
