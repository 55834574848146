export const SVA_ROUTES = [
  '/recargas',
  //tv
  '/recargas/tv/valores-de-recarga',
  '/recargas/tv/codigo-do-assinante',
  '/recargas/tv/revisão-de-dados',
  '/recargas/tv/recarga-em-processamento',
  '/recargas/tv/recarga-finalizada',
  '/recargas/tv/provedores',
  //jogos
  '/recargas/jogos/valores-de-recarga',
  '/recargas/jogos/codigo-do-assinante',
  '/recargas/jogos/revisão-de-dados',
  '/recargas/jogos/recarga-em-processamento',
  '/recargas/jogos/recarga-finalizada',
  '/recargas/jogos/provedores',
  //celular
  '/recargas/celular/valores-de-recarga',
  '/recargas/celular/revisão-de-dados',
  '/recargas/celular/recarga-em-processamento',
  '/recargas/celular/recarga-finalizada',
  '/recargas/celular/provedores',
  //serviços digitais
  '/recargas/servicos-digitais/recarga-em-processamento',
  '/recargas/servicos-digitais/recarga-finalizada',
  '/recargas/servicos-digitais/revisão-de-dados',
  '/recargas/servicos-digitais/provedores',
  //urls dinâmicas
  '/recargas/${type}/confirmar-recarga',
  '/recargas/${type}/provedores',
  '/recargas/${type}/recarga-em-processamento',
  '/recargas/${type}/recarga-finalizada',
  '/recargas/${type}/erro-na-recarga',
  '/recargas/${type}/valores-de-recarga',
  '/recargas/${type}/inserir-valor-de-recarga',
  '/recargas/${type}/revisão-de-dados',
  //seguros
  '/seguros',
  '/seguros/ofertas',
  '/seguros/autocontratacao-loja',
  '/seguros/${id}/detalhes',
  '/seguros/${id}/detalhes/historico-cobranca',
  '/seguros/${id}/detalhes/beneficiarios',
  '/seguros/${id}/detalhes/cancelamento'
] as const
