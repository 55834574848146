import { type SVGProps } from 'react'

export function InsuranceCardLifeIllustration({ width = 250, height = 176, ...rest }: SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox="0 0 250 178" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M23.5175 85.4764C22.79 81.4333 26.9421 80.0231 29.5183 82.1371C30.4307 82.886 32.111 84.6327 34.7606 85.9717C39.0478 88.1402 45.8046 87.6094 46.8403 91.925C46.762 91.7876 39.6356 93.1337 39.2635 93.1408C37.9885 93.1622 32.3124 93.7428 31.1085 93.3304C25.3945 91.3847 23.7877 86.9742 23.5175 85.4764Z"
        fill="#8F5049"
      />
      <path
        d="M234.067 37.1645V44.8005H236.248V58.8709H195.873V24.0824H216.058V30.9884H236.248V37.1645H234.067Z"
        fill="#42EC9A"
      />
      <path d="M216.06 44.4352H213.877V58.7971H216.06V44.4352Z" fill="#1D232A" />
      <path d="M205.029 44.8004H193.686V58.2523H205.029V44.8004Z" fill="#42EC9A" />
      <path d="M205.029 30.9854H193.686V37.1638H205.029V30.9854Z" fill="#42EC9A" />
      <path d="M201.357 24.0824H198.231V58.4444H201.357V24.0824Z" fill="white" />
      <path d="M203.567 24.0824H202.42V58.4444H203.567V24.0824Z" fill="white" />
      <path d="M216.06 30.2584H195.87V30.9884H216.06V30.2584Z" fill="#1D232A" />
      <path d="M234.069 37.1638H195.87V37.8938H234.069V37.1638Z" fill="#1D232A" />
      <path d="M234.069 44.0719H195.87V44.8018H234.069V44.0719Z" fill="#1D232A" />
      <path d="M236.245 50.9766H193.686V51.7065H236.245V50.9766Z" fill="#1D232A" />
      <path d="M215.308 30.9879L215.279 37.164H216.061V61.302H220.394L216.121 30.9879H215.308Z" fill="#1D232A" />
      <path
        d="M66.7881 153.325C57.9387 163.954 47.4635 183.404 28.2953 174.915C17.4172 170.097 17.0404 157.932 19.0952 147.862C22.0173 133.536 28.703 105.397 28.703 105.397C22.2733 105.492 11.1653 102.579 5.11481 98.8705C-3.31274 93.7063 0.61427 88.2507 3.26151 87.7293C6.23817 87.1416 10.7126 89.1252 13.2058 90.1561C19.0857 92.5901 24.6172 92.5853 30.0846 92.263C35.18 91.962 39.5099 92.1682 45.2144 90.5496C48.575 89.5968 59.363 94.2348 56.967 105.125L54.161 120.063L83.5105 131.893L66.7881 153.325Z"
        fill="#8F5049"
      />
      <path
        d="M22.0823 102.684C14.9109 101.191 13.368 100.795 10.9104 97.8799C9.37702 98.9061 7.27487 99.072 4.7959 98.6714C4.7959 98.6714 10.0999 102.127 15.9418 103.101C18.0747 103.456 19.8617 103.139 22.0823 102.684Z"
        fill="#1D232A"
      />
      <path
        d="M21.0777 103.423C13.1479 103.214 7.88895 98.0901 6.27027 94.3527C5.15403 91.7766 4.59472 88.0226 6.94334 86.4869C11.1026 83.7685 13.8138 88.6151 17.4707 91.7576C19.8951 93.8408 25.3413 94.7817 28.8654 94.412"
        fill="#8F5049"
      />
      <path
        d="M69.0354 150.088L50.6611 135.543L54.6521 115.972L63.248 105.241C66.8242 100.859 72.2751 95.6686 88.2984 94.6543L100.312 110.732L69.0354 150.088Z"
        fill="#00A868"
      />
      <path
        d="M38.5691 92.9605C38.4506 92.9605 38.3344 92.9013 38.2681 92.7946C38.1638 92.6287 38.2136 92.4083 38.3795 92.304C38.4885 92.2353 41.1239 90.6048 45.1765 90.1971C45.3708 90.1782 45.5462 90.3204 45.5652 90.5147C45.5841 90.709 45.4419 90.8844 45.2476 90.9034C41.3703 91.2921 38.7824 92.8894 38.7563 92.9036C38.6994 92.9439 38.633 92.9605 38.5691 92.9605Z"
        fill="#1D232A"
      />
      <path
        d="M48.6971 145.321C48.6734 145.321 48.6497 145.319 48.6237 145.314C48.4317 145.274 48.3085 145.087 48.3488 144.895L50.3111 135.472C50.3514 135.28 50.5386 135.157 50.7306 135.197C50.9225 135.237 51.0458 135.424 51.0055 135.616L49.0432 145.039C49.01 145.208 48.8607 145.321 48.6971 145.321Z"
        fill="#1D232A"
      />
      <path
        d="M221.032 141.166C232.034 134.028 233.422 121.787 228.5 108.273C223.495 94.5347 214.098 71.4987 214.098 71.4987C220.525 71.5983 231.06 67.6713 237.385 64.4529C246.194 59.9713 242.71 54.2218 240.113 53.4918C237.191 52.6718 232.574 54.2929 230.007 55.1247C218.314 58.9119 207.713 58.7223 195.522 57.1439C190.626 56.5088 184.123 59.9452 186.692 70.7783L195.802 102.066L173.764 125.972C194.041 143.491 207.346 150.048 221.032 141.166Z"
        fill="#8F5049"
      />
      <path
        d="M220.127 67.7798C227.805 65.789 231.773 59.6105 232.507 55.6053C233.015 52.8443 232.486 49.5359 230.076 48.0949C228.325 47.045 225.673 47 224.061 49.3415C222.542 51.5479 221.93 52.7661 220.124 54.745C216.866 58.3189 210.183 58.909 204.98 57.0865"
        fill="#8F5049"
      />
      <path
        d="M220.127 68.1369C219.968 68.1369 219.824 68.0303 219.783 67.8715C219.734 67.6819 219.847 67.4876 220.039 67.4378C224.232 66.35 230.922 62.2855 232.162 55.543C232.197 55.351 232.384 55.2231 232.576 55.2586C232.768 55.2942 232.896 55.479 232.861 55.6734C232.15 59.5459 228.194 66.0609 220.219 68.1275C220.186 68.1322 220.158 68.1369 220.127 68.1369Z"
        fill="#1D232A"
      />
      <path
        d="M177.811 129.706L196.42 104.155L195.799 102.067L176.844 90.0752C163.977 82.8279 153.246 88.293 141.975 89.5799L142.567 103.617L176.161 131.926L177.811 129.706Z"
        fill="#00A868"
      />
      <path
        d="M201.105 121.47C200.949 121.47 200.806 121.366 200.764 121.21L195.405 101.878C195.353 101.688 195.465 101.494 195.652 101.44C195.841 101.387 196.036 101.499 196.09 101.686L201.449 121.018C201.501 121.207 201.389 121.402 201.202 121.456C201.169 121.466 201.138 121.47 201.105 121.47Z"
        fill="#1D232A"
      />
      <path
        d="M186.754 71.0193C184.979 65.6039 186.195 60.0559 190.757 57.1361C193.364 55.4676 198.116 54.9676 201.846 56.0743C205.576 57.1787 210.887 58.5486 217.12 56.9939L215.957 65.5376L186.754 71.0193Z"
        fill="#8F5049"
      />
      <path
        d="M118.22 64.4125C137.878 63.8009 153.381 49.3568 152.846 32.1509C152.31 14.945 135.94 1.49277 116.281 2.10446C96.6219 2.71616 81.1193 17.1602 81.6547 34.3661C82.19 51.572 98.5607 65.0242 118.22 64.4125Z"
        fill="#1D232A"
      />
      <path
        d="M137.732 62.747C138.493 62.9034 139.251 62.9698 139.996 62.9532C140.261 62.9461 140.522 62.9295 140.78 62.901C140.851 62.8963 140.922 62.8868 140.993 62.8773C141.268 62.8418 141.541 62.7968 141.811 62.7375C141.891 62.7209 141.97 62.7043 142.05 62.683C142.235 62.6403 142.42 62.5906 142.605 62.5337C145.984 61.5146 148.715 58.7228 149.48 55.0209C150.592 49.6435 147.131 44.3846 141.754 43.2731C136.377 42.1639 131.12 45.6217 130.006 50.9991C128.897 56.3766 132.355 61.6378 137.732 62.747Z"
        fill="#8F5049"
      />
      <path
        d="M138.839 55.7447C139.363 57.2875 139.887 58.8304 140.413 60.3708C140.086 59.6622 139.771 58.9489 139.472 58.2284C139.337 57.899 138.806 58.0246 138.925 58.3777C139.242 59.3186 139.529 60.2713 139.787 61.2311C139.726 61.1198 139.662 61.006 139.598 60.897C139.413 60.5818 138.922 60.8662 139.107 61.1814C139.441 61.7549 139.74 62.345 140 62.9541C140.266 62.947 140.527 62.9304 140.785 62.902C140.721 62.6128 140.65 62.3261 140.581 62.0393C140.719 62.3189 140.856 62.5986 140.998 62.8783C141.273 62.8427 141.546 62.7977 141.816 62.7384C141.572 62.0251 141.33 61.3117 141.088 60.5984C141.411 61.2951 141.731 61.9872 142.055 62.6839C142.24 62.6413 142.425 62.5915 142.61 62.5346C141.527 60.1978 140.444 57.8634 139.361 55.5267C139.221 55.2352 138.737 55.4437 138.839 55.7447Z"
        fill="#1D232A"
      />
      <path
        d="M141.636 56.4302C141.622 56.4302 141.608 56.4302 141.594 56.4278C141.399 56.4041 141.259 56.2288 141.283 56.0321C141.532 53.9465 142.359 51.9415 143.679 50.2399C143.8 50.0835 144.023 50.0574 144.177 50.1759C144.331 50.2968 144.359 50.5196 144.241 50.6736C143.001 52.2757 142.222 54.1574 141.989 56.115C141.968 56.2975 141.814 56.4302 141.636 56.4302Z"
        fill="#1D232A"
      />
      <path
        d="M97.6114 66.1619C103.102 66.1619 107.553 61.7107 107.553 56.2199C107.553 50.7291 103.102 46.278 97.6114 46.278C92.1206 46.278 87.6694 50.7291 87.6694 56.2199C87.6694 61.7107 92.1206 66.1619 97.6114 66.1619Z"
        fill="#8F5049"
      />
      <path
        d="M95.7702 59.2615C95.5901 59.2615 95.436 59.1264 95.4171 58.9439C95.1801 56.7612 94.1562 54.7325 92.6821 53.5143C92.5305 53.3887 92.5091 53.166 92.6347 53.0143C92.7603 52.8626 92.9831 52.8413 93.1348 52.9669C94.7487 54.3012 95.865 56.5076 96.1233 58.8681C96.1446 59.0624 96.0048 59.2378 95.8081 59.2591C95.7963 59.2615 95.782 59.2615 95.7702 59.2615Z"
        fill="#1D232A"
      />
      <path d="M92.1837 42.4473L98.3645 54.6454L98.1725 42.67L92.1837 42.4473Z" fill="#1D232A" />
      <path d="M144.083 40.7068L138.668 53.2628L138.12 41.2993L144.083 40.7068Z" fill="#1D232A" />
      <path
        d="M120.069 84.5737C131.649 84.0429 140.607 74.2218 140.076 62.6422L139.574 44.813C139.043 33.2334 129.222 24.275 117.642 24.8059C106.063 25.3367 97.1044 35.1578 97.6353 46.7374L98.1377 64.5666C98.6686 76.1485 108.487 85.1046 120.069 84.5737Z"
        fill="#8F5049"
      />
      <path
        d="M119.752 77.6307C119.556 77.6307 119.397 77.4719 119.397 77.2752C119.397 76.3296 118.627 75.5594 117.681 75.5594C117.484 75.5594 117.326 75.4006 117.326 75.2039C117.326 75.0072 117.484 74.8484 117.681 74.8484C119.02 74.8484 120.108 75.9362 120.108 77.2752C120.108 77.4719 119.949 77.6307 119.752 77.6307Z"
        fill="#1D232A"
      />
      <path
        d="M135.057 47.5233C135.057 48.6159 134.616 49.6018 133.898 50.3175C133.721 50.4952 133.526 50.654 133.318 50.7986C132.685 51.2252 131.922 51.4764 131.102 51.4764H122.489C120.304 51.4764 118.534 49.7061 118.534 47.5233C118.534 46.4308 118.975 45.4425 119.693 44.7268C120.409 44.0111 121.397 43.5679 122.489 43.5679H131.102C131.922 43.5679 132.685 43.8167 133.318 44.2457C134.368 44.959 135.057 46.1582 135.057 47.5233Z"
        fill="white"
      />
      <path
        d="M135.06 47.5227C135.06 48.6152 134.619 49.6011 133.901 50.3168C133.723 50.4946 133.529 50.6534 133.32 50.7956C132.78 51.1629 132.145 51.3975 131.457 51.4592C131.341 51.4686 131.223 51.4757 131.104 51.4757H127.31V43.5696H131.104C131.223 43.5696 131.341 43.5767 131.457 43.5862C132.142 43.6454 132.78 43.88 133.32 44.2474C134.37 44.956 135.06 46.1576 135.06 47.5227Z"
        fill="#1D232A"
      />
      <path
        d="M120.172 47.3221L122.572 54.2827C123.053 55.681 122.015 57.1361 120.537 57.1361H114.941"
        fill="#8F5049"
      />
      <path
        d="M120.538 57.4936H114.943C114.746 57.4936 114.587 57.3348 114.587 57.1381C114.587 56.9414 114.746 56.7826 114.943 56.7826H120.538C121.126 56.7826 121.659 56.5077 122 56.0289C122.342 55.5502 122.427 54.9553 122.235 54.4008L119.834 47.4402C119.77 47.2554 119.87 47.0515 120.055 46.9875C120.239 46.9236 120.443 47.0231 120.507 47.208L122.908 54.1685C123.176 54.9459 123.055 55.773 122.579 56.4413C122.102 57.1096 121.358 57.4936 120.538 57.4936Z"
        fill="#1D232A"
      />
      <path
        d="M120.629 47.5233C120.629 48.6159 120.188 49.6018 119.47 50.3175C119.293 50.4952 119.098 50.654 118.89 50.7962C118.349 51.1636 117.714 51.3982 117.027 51.4598C116.911 51.4693 116.792 51.4764 116.674 51.4764H108.061C105.876 51.4764 104.106 49.7061 104.106 47.5233C104.106 46.4308 104.547 45.4425 105.265 44.7268C105.983 44.0111 106.969 43.5679 108.061 43.5679H116.674C116.792 43.5679 116.911 43.575 117.027 43.5845C117.712 43.6437 118.349 43.8783 118.89 44.2457C119.939 44.9567 120.629 46.1582 120.629 47.5233Z"
        fill="white"
      />
      <path
        d="M120.63 47.5227C120.63 48.6152 120.19 49.6011 119.472 50.3168C119.294 50.4946 119.099 50.6534 118.891 50.7956C118.351 51.1629 117.715 51.3975 117.028 51.4592C116.912 51.4686 116.794 51.4757 116.675 51.4757H112.881V43.5696H116.675C116.794 43.5696 116.912 43.5767 117.028 43.5862C117.713 43.6454 118.351 43.88 118.891 44.2474C119.941 44.956 120.63 46.1576 120.63 47.5227Z"
        fill="#1D232A"
      />
      <path
        d="M121.984 36.6599C121.572 36.6599 121.155 36.5343 120.797 36.2712C119.899 35.6148 119.702 34.354 120.359 33.4557C122.103 31.0692 125.198 31.4176 126.753 32.0006C127.795 32.3916 128.322 33.5529 127.931 34.5933C127.542 35.6314 126.388 36.1575 125.35 35.7783C124.999 35.6551 123.902 35.4323 123.608 35.8328C123.219 36.3732 122.605 36.6599 121.984 36.6599Z"
        fill="#1D232A"
      />
      <path
        d="M111.609 44.0797C110.77 44.0797 109.734 43.8475 108.606 43.0749C107.689 42.4468 107.452 41.1931 108.082 40.2736C108.71 39.3564 109.964 39.1194 110.884 39.7498C111.123 39.9134 111.462 40.0982 111.713 40.039C112.081 39.9537 112.448 39.4512 112.483 39.3872C113.031 38.4179 114.259 38.0743 115.228 38.6217C116.197 39.1668 116.541 40.3968 115.993 41.3661C115.872 41.5794 114.756 43.4683 112.626 43.9636C112.334 44.0323 111.993 44.0797 111.609 44.0797Z"
        fill="#1D232A"
      />
      <path
        d="M91.0147 39.9629C90.9436 39.9629 90.8749 39.9416 90.8133 39.8989C88.7799 38.4841 86.4407 35.7563 86.8531 30.796C86.8697 30.5992 87.0403 30.4547 87.237 30.4713C87.4337 30.4879 87.5783 30.6585 87.5617 30.8552C87.1778 35.4766 89.3392 38.0054 91.2185 39.3136C91.3797 39.425 91.42 39.6477 91.3086 39.8089C91.2375 39.9108 91.1285 39.9629 91.0147 39.9629Z"
        fill="white"
      />
      <path
        d="M90.4474 34.4731C90.3289 34.4731 90.2128 34.4139 90.1441 34.3048C88.4733 31.6078 90.0754 28.9582 90.1441 28.8468C90.2484 28.6809 90.4664 28.6288 90.6323 28.7307C90.7982 28.835 90.8503 29.053 90.7484 29.2189C90.6892 29.3161 89.3146 31.6102 90.7484 33.928C90.8527 34.0939 90.8006 34.3143 90.6323 34.4162C90.5754 34.4565 90.5114 34.4731 90.4474 34.4731Z"
        fill="white"
      />
      <path
        d="M117.077 62.7311C115.209 62.7311 113.491 61.9894 111.944 60.5105C111.801 60.3754 111.797 60.1503 111.932 60.0081C112.067 59.8659 112.292 59.8611 112.434 59.9962C114.477 61.9467 116.847 62.487 119.478 61.5983C119.665 61.5367 119.866 61.6362 119.928 61.8211C119.99 62.0059 119.89 62.2098 119.705 62.2714C118.8 62.5795 117.923 62.7311 117.077 62.7311Z"
        fill="#1D232A"
      />
      <path
        d="M178.306 164.092L70.504 168.341L69.5608 149.182L68.0085 110.934C68.8877 98.1621 76.4645 95.6689 88.2977 94.6546C91.5303 94.3773 94.7368 94.2517 97.9031 94.0313C111.146 93.107 123.747 91.9671 135.379 90.5048C142.88 89.5592 149.978 88.4785 156.593 87.2343C163.432 87.2343 171.957 100.828 171.957 107.666L178.306 164.092Z"
        fill="#00A868"
      />
      <path d="M52.9321 10.4955L3.98657 13.7032L8.14013 77.0799L57.0857 73.8721L52.9321 10.4955Z" fill="white" />
      <path
        d="M12.2537 26.1931C12.0688 26.1931 11.9124 26.0486 11.8982 25.8613C11.8863 25.6646 12.0333 25.4964 12.23 25.4845L46.2957 23.252C46.4971 23.2402 46.6607 23.3871 46.6725 23.5838C46.6844 23.7805 46.5374 23.9488 46.3407 23.9606L12.275 26.1908C12.2679 26.1931 12.2608 26.1931 12.2537 26.1931Z"
        fill="#1D232A"
      />
      <path
        d="M36.117 34.1306C35.9322 34.1306 35.7758 33.986 35.7615 33.7988C35.7497 33.602 35.8966 33.4338 36.0933 33.4219L46.9169 32.7133C47.116 32.7015 47.2819 32.8484 47.2937 33.0451C47.3056 33.2418 47.1586 33.4101 46.9619 33.4219L36.1384 34.1306C36.1336 34.1306 36.1265 34.1306 36.117 34.1306Z"
        fill="#1D232A"
      />
      <path
        d="M12.8727 35.6522C12.6878 35.6522 12.5314 35.5077 12.5172 35.3204C12.5054 35.1237 12.6523 34.9555 12.849 34.9436L29.3131 33.8653C29.5098 33.8534 29.678 34.0004 29.6899 34.1971C29.7017 34.3938 29.5548 34.5621 29.3581 34.5739L12.894 35.6522C12.8893 35.6522 12.8798 35.6522 12.8727 35.6522Z"
        fill="#1D232A"
      />
      <path
        d="M36.4557 39.3465C36.2709 39.3465 36.1144 39.2019 36.1002 39.0147C36.0884 38.818 36.2353 38.6497 36.432 38.6379L47.2556 37.9293C47.4523 37.9174 47.6206 38.0643 47.6324 38.261C47.6443 38.4578 47.4973 38.626 47.3006 38.6379L36.477 39.3465C36.4699 39.3465 36.4628 39.3465 36.4557 39.3465Z"
        fill="#1D232A"
      />
      <path
        d="M13.2155 40.8693C13.0307 40.8693 12.8743 40.7247 12.86 40.5375C12.8482 40.3408 12.9951 40.1725 13.1918 40.1607L29.6559 39.0824C29.855 39.0681 30.0209 39.2174 30.0327 39.4142C30.0446 39.6109 29.8976 39.7791 29.7009 39.791L13.2369 40.8693C13.2321 40.8693 13.2226 40.8693 13.2155 40.8693Z"
        fill="#1D232A"
      />
      <path
        d="M37.49 49.6497C37.3051 49.6497 37.1487 49.5051 37.1345 49.3179C37.1226 49.1212 37.2695 48.9529 37.4663 48.9411L48.2898 48.2325C48.4865 48.2206 48.6548 48.3676 48.6667 48.5643C48.6785 48.761 48.5316 48.9292 48.3349 48.9411L37.5113 49.6497C37.5042 49.6497 37.4971 49.6497 37.49 49.6497Z"
        fill="#1D232A"
      />
      <path
        d="M14.2495 51.1725C14.0647 51.1725 13.9083 51.028 13.894 50.8407C13.8822 50.644 14.0291 50.4758 14.2258 50.4639L30.6899 49.3856C30.889 49.3714 31.0549 49.5207 31.0667 49.7174C31.0786 49.9141 30.9316 50.0823 30.7349 50.0942L14.2709 51.1725C14.2661 51.1725 14.2566 51.1725 14.2495 51.1725Z"
        fill="#1D232A"
      />
      <path
        d="M38.0911 54.8012C37.9062 54.8012 37.7498 54.6566 37.7356 54.4694C37.7237 54.2727 37.8707 54.1044 38.0674 54.0926L48.891 53.384C49.0877 53.3721 49.2559 53.5191 49.2678 53.7158C49.2796 53.9125 49.1327 54.0807 48.936 54.0926L38.1124 54.8012C38.1053 54.8012 38.0982 54.8012 38.0911 54.8012Z"
        fill="#1D232A"
      />
      <path
        d="M14.8507 56.3241C14.6659 56.3241 14.5095 56.1796 14.4952 55.9923C14.4834 55.7956 14.6303 55.6274 14.827 55.6155L31.2911 54.5372C31.4902 54.523 31.6561 54.6723 31.6679 54.869C31.6798 55.0657 31.5328 55.234 31.3361 55.2458L14.8721 56.3241C14.8673 56.3241 14.8578 56.3241 14.8507 56.3241Z"
        fill="#1D232A"
      />
      <path
        d="M36.8887 44.498C36.7038 44.498 36.5474 44.3534 36.5332 44.1662C36.5214 43.9695 36.6683 43.8012 36.865 43.7894L47.6886 43.0807C47.8853 43.0689 48.0535 43.2158 48.0654 43.4125C48.0772 43.6092 47.9303 43.7775 47.7336 43.7894L36.91 44.498C36.9029 44.498 36.8958 44.498 36.8887 44.498Z"
        fill="#1D232A"
      />
      <path
        d="M13.6487 46.0209C13.4638 46.0209 13.3074 45.8763 13.2932 45.6891C13.2814 45.4924 13.4283 45.3241 13.625 45.3123L30.0891 44.234C30.2881 44.2197 30.454 44.3691 30.4659 44.5658C30.4777 44.7625 30.3308 44.9307 30.1341 44.9426L13.67 46.0209C13.6653 46.0209 13.6558 46.0209 13.6487 46.0209Z"
        fill="#1D232A"
      />
      <path
        d="M135.378 90.5048C134.524 94.8465 131.455 106.786 118.819 108.877C104.123 111.306 99.1718 98.7546 97.9015 94.0313C99.5889 93.9128 101.262 93.7919 102.928 93.6663C103.205 93.6473 103.483 93.6236 103.76 93.6023C104.165 93.5715 104.573 93.543 104.978 93.5075C111.768 92.9837 118.364 92.3984 124.725 91.7277C124.912 91.7111 125.099 91.6897 125.286 91.6684C125.604 91.6352 125.921 91.602 126.237 91.5665C129.344 91.2323 132.394 90.8768 135.378 90.5048Z"
        fill="#8F5049"
      />
      <path
        d="M69.6947 152.228C69.5051 152.228 69.3463 152.079 69.3392 151.887L68.5334 131.816C68.5263 131.619 68.678 131.453 68.8747 131.446C69.0714 131.441 69.2373 131.59 69.2444 131.787L70.0502 151.858C70.0573 152.055 69.9057 152.221 69.7089 152.228C69.7042 152.228 69.6995 152.228 69.6947 152.228Z"
        fill="#1D232A"
      />
      <path d="M172.026 112.94L174.218 130.288L176.164 131.926L179.179 127.866L172.026 112.94Z" fill="#1D232A" />
      <path
        d="M179.404 125.987C179.281 125.987 179.163 125.923 179.096 125.81L171.719 113.119C171.619 112.948 171.678 112.732 171.847 112.633C172.017 112.533 172.233 112.592 172.332 112.761L179.71 125.452C179.81 125.622 179.75 125.838 179.582 125.938C179.525 125.971 179.464 125.987 179.404 125.987Z"
        fill="#1D232A"
      />
      <path
        d="M125.287 91.668C124.879 93.2274 123.654 94.6636 121.907 95.7324C123.569 94.4289 124.36 92.815 124.723 91.7272C118.362 92.3979 111.766 92.9856 104.976 93.507C107.318 98.3109 111.415 100.003 115.07 100.003C115.729 100.003 116.371 99.9486 116.985 99.8467C118.089 99.6665 119.139 99.3419 120.108 98.901C118.855 99.612 117.44 100.119 115.928 100.361C113.937 100.679 107.313 101.091 103.758 93.6018C103.481 93.6232 103.204 93.6469 102.926 93.6658C105.495 99.3039 110.074 101.288 114.12 101.288C114.781 101.288 115.43 101.235 116.051 101.136C121.395 100.283 125.604 96.2988 126.237 91.566C125.922 91.6016 125.604 91.6348 125.287 91.668ZM116.862 99.0764C113.029 99.7068 109.636 98.4555 107.296 95.6684C108.266 96.5121 109.392 97.1757 110.657 97.6331C112.008 98.1213 113.51 98.3678 115.051 98.3678C115.966 98.3678 116.892 98.2801 117.807 98.1047C119.831 97.7161 121.613 96.9601 123.012 95.9599C121.471 97.5288 119.307 98.6735 116.862 99.0764Z"
        fill="#1D232A"
      />
      <path
        d="M230.72 26.8693C235.472 28.1064 239.629 31.6305 240.738 35.9533C242.106 31.6163 245.293 28.104 250 26.8693C245.424 25.6298 241.883 21.9256 240.738 16.9913C239.835 21.9659 236.356 25.6843 230.72 26.8693Z"
        fill="#F1BD38"
      />
      <path
        d="M220.391 21.9535C223.405 22.738 226.027 24.9586 226.73 27.7007C227.598 24.9515 229.634 22.7238 232.617 21.9393C229.714 21.1525 227.47 18.8039 226.745 15.6755C225.828 18.7991 223.415 21.1525 220.391 21.9535Z"
        fill="#F1BD38"
      />
      <path
        d="M36.9169 107.038C25.8492 113.634 16.8481 106.605 15.1962 104.474C13.6676 102.502 12.7149 98.6913 14.3383 96.537C15.5707 94.9018 19.2607 93.2878 23.4863 97.4234C24.8656 98.8241 27.2214 99.9048 30.8 97.8642"
        fill="#8F5049"
      />
      <path
        d="M13.9405 102.385C13.7936 102.385 13.6538 102.293 13.604 102.146C12.8219 99.8634 12.9878 97.7399 14.0567 96.3227C14.6681 95.5122 15.9384 94.5974 17.6685 94.467C19.0715 94.3627 21.259 94.7443 23.7356 97.1688C24.9798 98.4319 27.1791 99.5198 30.625 97.5551C30.7956 97.4579 31.0137 97.5171 31.1108 97.6878C31.208 97.8584 31.1488 98.0765 30.9781 98.1736C27.1388 100.361 24.648 99.1098 23.2331 97.6736C20.9509 95.4387 18.9767 95.0832 17.7206 95.1756C16.2275 95.287 15.1397 96.062 14.6231 96.7493C13.6988 97.9769 13.5708 99.8587 14.2747 101.916C14.3387 102.101 14.2392 102.304 14.0543 102.368C14.0187 102.378 13.9785 102.385 13.9405 102.385Z"
        fill="#1D232A"
      />
      <path
        d="M13.0168 102.048C12.9575 102.048 12.8959 102.034 12.839 102C6.29086 98.2322 5.29785 92.5609 5.25756 92.3216C5.22675 92.1272 5.3571 91.9447 5.55143 91.9139C5.74577 91.8831 5.92825 92.0135 5.95906 92.2078C5.96854 92.2623 6.9497 97.7914 13.1922 101.384C13.3628 101.481 13.422 101.699 13.3225 101.87C13.2585 101.984 13.14 102.048 13.0168 102.048Z"
        fill="#1D232A"
      />
      <path
        d="M28.9837 110.166C28.3722 110.166 28.0262 110.15 28.0096 110.15C27.8129 110.14 27.6636 109.972 27.6731 109.775C27.6826 109.579 27.8461 109.429 28.0475 109.439C28.1329 109.443 36.6671 109.844 45.0851 106.455C45.2676 106.381 45.4738 106.469 45.5473 106.652C45.6208 106.834 45.5331 107.04 45.3506 107.114C38.4588 109.889 31.4935 110.166 28.9837 110.166Z"
        fill="#1D232A"
      />
      <path
        d="M26.9877 95.1005C20.8685 95.1005 17.3918 92.1617 17.2377 92.029C17.0884 91.9011 17.0718 91.6759 17.1998 91.529C17.3278 91.3797 17.5529 91.3631 17.6999 91.4911C17.7378 91.5242 21.6553 94.8185 28.3291 94.3421C28.5258 94.3279 28.6941 94.4748 28.7083 94.6715C28.7225 94.8682 28.5756 95.0365 28.3789 95.0507C27.9025 95.0839 27.438 95.1005 26.9877 95.1005Z"
        fill="#1D232A"
      />
      <path d="M216.06 30.9854H213.877V37.1638H216.06V30.9854Z" fill="#1D232A" />
    </svg>
  )
}
