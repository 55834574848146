import styled from 'styled-components'

export const Overlay = styled.div<{ zIndex?: number }>`
  background-color: rgba(0, 0, 0, 0.5);
  z-index: ${props => props.zIndex || props.theme.zIndices.overlay};
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  animation: 0.3s fade-in ease-in;

  @keyframes fade-in {
    from {
      opacity: 0%;
    }
    to {
      opacity: 100%;
    }
  }
`
