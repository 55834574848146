import { type ReactNode } from 'react'
import {
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow
} from '@dlpco/ginga-stone'

import { If } from '~/domains/platform/lib/utilities-components'
import { type ExtractFirstParam } from '~/lib/types'

import { TableContent } from './styles'

function getValue(item: Record<string, string | boolean | number>, key: string): ReactNode {
  const value = item[key]

  if (typeof value === 'boolean') {
    return <Checkbox disabled={!value} checked={value} />
  }

  return <span>{String(value).substring(0, 100)}</span>
}

interface Actions {
  onClick: (...args: any) => void | Promise<void>
  label: string
  buttonProps?: ExtractFirstParam<typeof Button>
}

interface TableProps {
  data: Record<string, any>[]
  onClick?: (...args: any) => void | Promise<void>
  actions?: Actions[]
}

const sanitizeData = (data: Record<string, any>[]): Record<string, any>[] => {
  return data.map(item => {
    const newItem: Record<any, any> = {}

    Object.keys(item).forEach(key => {
      if (typeof item[key] === 'object') {
        newItem[key] = JSON.stringify(item[key])
      } else {
        newItem[key] = item[key]
      }
    })

    return newItem
  })
}

export default function CustomTable(props: TableProps) {
  const { data: rawData, actions } = props

  const data = sanitizeData(rawData)

  const [sampleMock] = data

  const tableKeys = sampleMock ? Object.keys(sampleMock) : []

  return (
    <If condition={tableKeys.length > 0}>
      <TableContent>
        <Table>
          <TableHeader>
            <TableHeaderRow>
              {tableKeys.map(key => (
                <TableHeaderCell key={key} alignment={typeof sampleMock[key] === 'boolean' ? 'center' : 'start'}>
                  {key}
                </TableHeaderCell>
              ))}

              {actions?.map(action => (
                <TableHeaderCell key={action.label} />
              ))}
            </TableHeaderRow>
          </TableHeader>
          <TableBody>
            {data.map(item => (
              <TableRow key={item.email} uniqueId={item.email}>
                {tableKeys.map(key => (
                  <TableCell
                    key={key}
                    title={String(item[key])}
                    alignment={typeof item[key] === 'boolean' ? 'center' : 'start'}
                  >
                    {getValue(item, key)}
                  </TableCell>
                ))}
                {actions?.map(action => (
                  <TableCell key={action.label} alignment="center">
                    <Button onClick={() => action.onClick(item)} {...action.buttonProps}>
                      {action.label.toUpperCase()}
                    </Button>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContent>
    </If>
  )
}
