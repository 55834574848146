export const HighFiveIllustration = () => (
  <svg width="160" height="163" viewBox="0 0 160 163" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1849_94091)">
      <path
        d="M83.7804 22.3503C83.7629 22.3503 83.7429 22.3478 83.7254 22.3453C83.5729 22.3153 83.4754 22.1678 83.5054 22.0153L87.8304 0.320267C87.8604 0.167767 88.0079 0.0702672 88.1604 0.100267C88.3129 0.130267 88.4104 0.277767 88.3804 0.430267L84.0554 22.1253C84.0279 22.2603 83.9104 22.3503 83.7804 22.3503Z"
        fill="#1D232A"
      />
      <path
        d="M57.9776 31.5677C57.9226 31.5677 57.8676 31.5502 57.8176 31.5177L39.6926 18.8327C39.5651 18.7452 39.5351 18.5702 39.6251 18.4427C39.7126 18.3177 39.8876 18.2852 40.0151 18.3752L58.1401 31.0602C58.2676 31.1477 58.2976 31.3227 58.2076 31.4502C58.1526 31.5277 58.0676 31.5677 57.9776 31.5677Z"
        fill="#1D232A"
      />
      <path
        d="M70.0128 25.2952C69.8978 25.2952 69.7928 25.2252 69.7503 25.1127L62.1403 4.34023C62.0878 4.19523 62.1628 4.03523 62.3078 3.98273C62.4528 3.93023 62.6128 4.00273 62.6678 4.15023L70.2778 24.9227C70.3303 25.0677 70.2553 25.2277 70.1103 25.2802C70.0778 25.2902 70.0453 25.2952 70.0128 25.2952Z"
        fill="#1D232A"
      />
      <path
        d="M83.7799 22.4453C83.7549 22.4453 83.7299 22.4428 83.7074 22.4378C83.5049 22.3978 83.3724 22.2003 83.4124 21.9978L87.7374 0.302832C87.7774 0.100332 87.9749 -0.0346679 88.1774 0.00783214C88.3799 0.0478321 88.5124 0.245332 88.4724 0.447832L84.1474 22.1428C84.1124 22.3228 83.9549 22.4453 83.7799 22.4453Z"
        fill="#1D232A"
      />
      <path
        d="M57.9778 31.6629C57.9028 31.6629 57.8278 31.6404 57.7628 31.5954L39.6378 18.9104C39.4678 18.7929 39.4278 18.5579 39.5453 18.3879C39.6653 18.2179 39.8978 18.1779 40.0678 18.2954L58.1928 30.9804C58.3628 31.0979 58.4028 31.3329 58.2853 31.5029C58.2128 31.6079 58.0978 31.6629 57.9778 31.6629Z"
        fill="#1D232A"
      />
      <path
        d="M70.0125 25.3929C69.86 25.3929 69.715 25.2979 69.66 25.1479L62.05 4.37543C61.98 4.18043 62.0775 3.96543 62.2725 3.89543C62.4675 3.82543 62.6825 3.92293 62.7525 4.11793L70.3625 24.8904C70.4325 25.0854 70.335 25.3004 70.14 25.3704C70.1 25.3854 70.0575 25.3929 70.0125 25.3929Z"
        fill="#1D232A"
      />
      <path
        d="M127.799 144.163C127.739 144.163 127.681 144.145 127.629 144.105L110.041 130.685C109.919 130.593 109.894 130.415 109.989 130.293C110.084 130.17 110.259 130.148 110.381 130.24L127.969 143.66C128.091 143.753 128.116 143.93 128.021 144.053C127.966 144.125 127.884 144.163 127.799 144.163Z"
        fill="#1D232A"
      />
      <path
        d="M79.5459 162.385C79.5234 162.385 79.5009 162.383 79.4809 162.378C79.3309 162.34 79.2384 162.19 79.2759 162.04L84.4884 140.54C84.5259 140.39 84.6759 140.298 84.8259 140.335C84.9759 140.373 85.0684 140.523 85.0309 140.673L79.8184 162.173C79.7859 162.3 79.6734 162.385 79.5459 162.385Z"
        fill="#1D232A"
      />
      <path
        d="M105.958 158.25C105.846 158.25 105.741 158.183 105.698 158.073L97.6759 137.458C97.6209 137.313 97.6909 137.153 97.8359 137.095C97.9809 137.04 98.1409 137.11 98.1984 137.255L106.221 157.87C106.276 158.015 106.206 158.175 106.061 158.233C106.028 158.245 105.993 158.25 105.958 158.25Z"
        fill="#1D232A"
      />
      <path
        d="M127.798 144.258C127.718 144.258 127.638 144.233 127.571 144.18L109.983 130.76C109.818 130.635 109.785 130.4 109.913 130.235C110.038 130.07 110.273 130.04 110.438 130.165L128.026 143.585C128.191 143.71 128.223 143.945 128.096 144.11C128.023 144.208 127.911 144.258 127.798 144.258Z"
        fill="#1D232A"
      />
      <path
        d="M79.5455 162.48C79.5155 162.48 79.4855 162.478 79.458 162.47C79.2555 162.42 79.133 162.218 79.183 162.018L84.3955 140.518C84.4455 140.315 84.648 140.195 84.848 140.243C85.0505 140.293 85.173 140.495 85.123 140.695L79.9105 162.195C79.868 162.365 79.7155 162.48 79.5455 162.48Z"
        fill="#1D232A"
      />
      <path
        d="M105.958 158.345C105.808 158.345 105.666 158.255 105.608 158.105L97.5857 137.49C97.5107 137.298 97.6057 137.08 97.7982 137.005C97.9907 136.93 98.2082 137.025 98.2832 137.218L106.306 157.833C106.381 158.025 106.286 158.243 106.093 158.318C106.051 158.338 106.006 158.345 105.958 158.345Z"
        fill="#1D232A"
      />
      <path
        d="M92.8654 117.003C91.1004 122.845 87.5104 127.26 83.1529 130.485C81.0329 132.06 78.7254 133.353 76.3629 134.388C71.5054 136.52 66.3954 137.573 62.0829 137.798C47.3904 138.56 35.9429 128.295 29.7629 121.2C25.8154 116.668 17.5429 112.25 11.1329 111.045C2.73795 109.468 -0.802054 104.043 1.44795 98.9628C4.22795 92.6853 19.3029 90.6453 28.2079 95.0153C17.8079 79.8853 7.36795 63.5903 16.7354 58.2003C28.1754 51.6228 42.3729 78.1878 42.9404 79.2578C42.4354 78.0628 29.2654 46.7278 41.5429 44.0678C53.8629 41.3953 64.6429 73.4153 64.9704 74.4003C64.8029 73.5853 60.0429 50.2878 71.2254 48.2828C77.4554 47.1678 80.3779 52.7678 82.1929 58.6953C82.9679 61.2253 84.6154 69.8303 86.2179 77.1003C86.2254 77.1228 86.2279 77.1503 86.2354 77.1728C86.4479 78.1478 86.6629 79.1003 86.8704 80.0078C88.2354 85.8953 97.3504 102.145 92.8654 117.003Z"
        fill="#8F5049"
      />
      <path
        d="M71.6329 112.303C71.4704 112.303 71.3204 112.195 71.2729 112.033C69.4654 105.805 64.1679 100.573 57.4429 98.3803C51.3404 96.3903 44.2879 96.8203 37.5779 99.5928C37.3879 99.6703 37.1679 99.5803 37.0879 99.3903C37.0079 99.2003 37.1004 98.9803 37.2904 98.9003C44.1629 96.0603 51.4004 95.6228 57.6729 97.6678C64.6304 99.9378 70.1154 105.36 71.9904 111.823C72.0479 112.023 71.9329 112.23 71.7354 112.288C71.7029 112.298 71.6679 112.303 71.6329 112.303Z"
        fill="#1D232A"
      />
      <path
        d="M92.8654 117.003C91.9179 120.138 90.4454 122.863 88.6104 125.213C78.9829 121.223 72.8629 108.698 75.5404 94.0278C76.4704 88.9328 84.4454 83.1679 86.2354 77.1729C86.4479 78.1479 86.6629 79.1004 86.8704 80.0079C88.2354 85.8954 97.3504 102.145 92.8654 117.003Z"
        fill="#1D232A"
      />
      <path
        d="M150.883 82.8903C151.648 82.2453 152.423 81.6228 153.203 81.0203C157.858 77.4278 161.678 73.2528 158.015 67.3603C154.6 61.8678 144.71 63.3578 137.143 68.9703C141.353 52.7978 143.45 29.8178 134.213 27.1703C121.263 23.4578 119.148 56.6053 119.148 56.6053C119.148 56.6053 120.34 46.0803 119.355 36.5378C118.533 28.5678 117.768 22.0478 111.31 21.5253C104.583 20.9803 100.19 26.9403 98.9954 34.7503C97.4204 45.0503 97.2129 55.7303 97.2129 55.7303C97.2129 55.7303 97.6304 31.1178 86.0679 31.0778C79.7379 31.0553 77.8254 37.0778 77.0654 43.2278C76.6954 46.2178 77.3779 55.3603 76.1429 65.0328C75.3779 71.0278 70.6129 88.2028 76.6379 102.505C83.3354 118.398 96.1579 123.04 113.06 119.898C130.27 116.7 137.178 100.665 139.898 95.7428C142.618 90.8203 146.578 86.5228 150.883 82.8903Z"
        fill="#E09661"
      />
      <path
        d="M96.9579 65.4479C96.9404 65.4479 96.9254 65.4479 96.9079 65.4454C96.7029 65.4179 96.5579 65.2279 96.5854 65.0229C96.9029 62.6829 97.2529 55.8304 96.6279 50.5929C96.6029 50.3879 96.7504 50.2004 96.9554 50.1754C97.1629 50.1479 97.3479 50.2979 97.3729 50.5029C98.0054 55.8054 97.6504 62.7504 97.3279 65.1229C97.3054 65.3104 97.1429 65.4479 96.9579 65.4479Z"
        fill="#1D232A"
      />
      <path
        d="M118.019 66.9129C117.996 66.9129 117.974 66.9104 117.951 66.9079C117.749 66.8704 117.614 66.6754 117.649 66.4729C118.316 62.8104 118.999 58.7154 119.366 52.4879C119.379 52.2804 119.556 52.1204 119.764 52.1354C119.971 52.1479 120.129 52.3254 120.116 52.5329C119.744 58.8004 119.059 62.9229 118.386 66.6079C118.354 66.7879 118.196 66.9129 118.019 66.9129Z"
        fill="#1D232A"
      />
      <path
        d="M137.148 68.9254C136.375 73.1179 135.76 76.5929 134.775 81.8904L143.505 65.4204C141.81 66.0729 139.243 67.3454 137.148 68.9254Z"
        fill="#1D232A"
      />
    </g>
    <defs>
      <clipPath id="clip0_1849_94091">
        <rect width="158.693" height="162.48" fill="white" transform="translate(0.785156)" />
      </clipPath>
    </defs>
  </svg>
)
