export function CalendarSuccessBalloonIllustration({
  width = 297,
  height = 294,
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox="0 0 297 294" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M282.893 215.753c28.787-68.53 10.693-118.784-28.787-168.125C184.194-39.176 33.676 5.597 6.534 86.918c-27.142 81.321 36.19 154.419 81.427 190.968 45.238 36.549 166.145 6.396 194.932-62.133z"
        fill="#14AA4B"
        fillOpacity={0.05}
      />
      <path
        d="M212.429 89.637a2.964 2.964 0 00-2.988-3.175l-113.37 1.206a2.964 2.964 0 00-2.932 2.898l-2.384 107.831a2.965 2.965 0 002.964 3.03h23.489c.58 0 1.005.546.862 1.108l-7.431 29.274c-.264 1.042.882 1.87 1.788 1.291l50.344-32.169a.892.892 0 01.479-.14h38.475a2.964 2.964 0 002.957-2.753l7.747-108.4z"
        fill="#14AA4B"
      />
      <path
        d="M170.862 123.404h-39.513a5.645 5.645 0 00-5.645 5.645v39.513a5.645 5.645 0 005.645 5.645h39.513a5.646 5.646 0 005.645-5.645v-39.513a5.645 5.645 0 00-5.645-5.645zM162.395 117.759v11.29M139.816 117.759v11.29M125.704 140.338h50.803"
        stroke="#fff"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
