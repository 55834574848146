import { type ComponentProps } from 'react'
import NextLink from 'next/link'
import { Link as JadeLink } from '@stone-payments/jade'

import { experimentAnalytics } from '../analitica'
import { useComponentView } from '../hooks/use-component-view'
import { type AnalyticsEvent, type Navigation } from '../types'

type LinkProps = {
  analytics?: AnalyticsEvent[]
  navigation?: Navigation
} & ComponentProps<typeof JadeLink>

export function Link({ href, children, analytics = [], navigation, external, ...rest }: LinkProps) {
  useComponentView(analytics.find(event => event.type === 'VIEWED'))

  return (
    <NextLink legacyBehavior passHref scroll={false} href={navigation?.uri ?? href}>
      <JadeLink
        {...rest}
        external={external ?? navigation?.type === 'LINK_EXTERNAL'}
        onClick={() => {
          const clickEvent = analytics.find(event => event.type === 'CLICKED')
          if (clickEvent != null) {
            experimentAnalytics.events.experiment.click(clickEvent)
          }
        }}
      >
        {navigation?.text ?? children}
      </JadeLink>
    </NextLink>
  )
}
