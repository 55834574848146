export function AvatarPfIllustration() {
  return (
    <svg width={40} height={40} viewBox="0 0 40 40">
      <circle cx={20} cy={20} r={20} fill="#00A868" fillOpacity={0.1} />
      <mask
        id="prefix__avatar-pf-mask"
        style={{
          maskType: 'alpha'
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={40}
        height={40}
      >
        <circle cx={20} cy={20} r={20} fill="#C4C4C4" />
      </mask>
      <g clipPath="url(#prefix__avatar-pf)" mask="url(#prefix__avatar-pf-mask)">
        <path
          d="M25.257 7.753c-.728-.2-1.528-.12-2.212-.44-1.353-.634-1.942-2.678-3.438-2.692-.9-.008-1.703.8-2.59.642-.713-.128-1.16-.822-1.75-1.242-1.37-.976-3.627-.05-3.917 1.606-.084.48-.037 1.007-.304 1.413-.242.368-.682.54-1.045.79-1.054.726-1.44 2.272-.852 3.408.418.805 1.258 1.431 1.334 2.336.025.3-.04.601-.047.903-.026 1.437 1.324 2.568 2.714 2.933 1.39.364 2.859.191 4.292.296 1.435.105 2.845.49 4.284.512 1.438.023 2.997-.4 3.848-1.56 1.014-1.38 1.123-3.736 1.601-5.352.438-1.472-.311-3.113-1.918-3.553z"
          fill="#1D232A"
        />
        <path
          d="M20.1 44.958h22.186c-.616-1.557-1.496-3.908-2.52-6.516-1.625-4.138-4.8-6.324-8.264-7.402a19.792 19.792 0 00-3.196-.698 25.967 25.967 0 00-3.512-.23h-9.387c-1.128 0-2.314.066-3.511.23a19.794 19.794 0 00-3.196.698c-3.464 1.078-6.64 3.264-8.264 7.402-1.024 2.608-1.904 4.959-2.52 6.516H20.1z"
          fill="#008E5A"
        />
        <path
          d="M26.683 30.176c-.015 1.214-.448 2.558-.924 3.587-.984 2.135-3.263 3.777-6.69 3.264-3.03-.453-4.809-2.88-5.238-4.398a9.603 9.603 0 01-.343-2.45 27.32 27.32 0 011.92-.067h9.386c.615 0 1.247.02 1.89.064z"
          fill="#E09661"
        />
        <path
          d="M24.583 30.112c-1.119 2.333-3.083 2.597-3.904 2.597h-.005c-1.357 0-2.403-.491-3.108-1.459l-.072-.102c.576.685 1.516 1.378 2.965 1.378 1.71 0 2.965-.79 3.828-2.414h-.23c-.415.663-1.517 2.004-3.6 1.888-1.019-.057-1.817-.416-2.374-1.07.498.421 1.355.877 2.726.895h.057c1.798 0 2.699-1.1 3.061-1.714h-6.544c.233.615.98 1.963 3.063 2.079.936.052 1.683-.177 2.266-.51-.934.654-1.853.654-2.253.654-2.09 0-3.068-1.52-3.409-2.223h-.257c.088.296.269.768.613 1.244.512.705 1.494 1.545 3.269 1.545h.005c.867 0 2.957-.28 4.114-2.789h-.211z"
          fill="#1D232A"
        />
        <path
          d="M29.096 21.74a3.167 3.167 0 01-.427.33 2.882 2.882 0 01-.337.196c-.11.055-.22.104-.335.147l-.082.03a3.411 3.411 0 01-1.047.187 3.42 3.42 0 112.228-.89z"
          fill="#E09661"
        />
        <path
          d="M28.475 22.19l-.64-.342.496.418c-.108.055-.22.104-.334.147l-.275-.178c.064.07.127.139.193.208a3.932 3.932 0 01-.218.068l-.463-.454a.096.096 0 01-.02-.112c-.039-.045-.077-.089-.114-.135-.076-.093.061-.186.144-.129.149.103.299.203.45.303-.232-.195-.465-.39-.696-.586-.084-.07.008-.214.105-.161l1.565.835c-.063.042-.127.08-.193.118zM29.096 21.741a2.889 2.889 0 01-.164.14 32.462 32.462 0 01-2.067-1.049c-.11-.06-.025-.236.084-.175.702.387 1.419.749 2.147 1.084z"
          fill="#1D232A"
        />
        <path d="M13.395 22.917a3.42 3.42 0 100-6.84 3.42 3.42 0 000 6.84z" fill="#E09661" />
        <path
          d="M20.308 28.625a7.222 7.222 0 01-7.375-7.065l-.104-4.866a7.222 7.222 0 0114.44-.31l.104 4.866a7.222 7.222 0 01-7.065 7.375z"
          fill="#E09661"
        />
        <path
          d="M22.273 12.194c-.39.47-.652 1.085-1.186 1.356-.65.33-1.434.019-2.147.151-.409.076-.79.304-1.056.633-.36.443-.505 1.042-.864 1.487-.558.693-1.527.876-2.394.778-.54-.06-1.064-.213-1.59-.351-.438-.115-1.126-.102-1.447-.465-.242-.275-.272-.69-.202-1.053.132-.685 1.73-4.696 2.797-5.073 1.191-.421 4.04-1.535 8.01-.363 1.247.368 2.62 1.494 3.46 2.555-.939-.3-2.215-.633-3.077.042a2.104 2.104 0 00-.305.303z"
          fill="#1D232A"
        />
        <path
          d="M28.888 16.505c-.87-.626-1.653-.687-1.653-.687s-1.895-.945-1.785-4.073l1.576-.695c1.835.243 2.5 1.316 2.306 2.805-.126.976-.444 2.65-.444 2.65z"
          fill="#1D232A"
        />
        <path
          d="M35.535 44.958H4.667c.745-.664 1.933-2.125 2.434-4.956l1.6-8.962a19.792 19.792 0 013.194-.699l-1.724 9.662 9.93.003 9.93-.003-1.725-9.661.174.024.027.004.2.03c.134.021.267.043.401.067l.138.024.042.008c.022.003.043.008.064.012a14.126 14.126 0 01.388.076l.17.036.216.048a23.149 23.149 0 01.593.145c.263.069.523.144.782.224l1.6 8.962c.5 2.832 1.689 4.293 2.434 4.956z"
          fill="#E8E9EA"
        />
      </g>
      <defs>
        <clipPath id="prefix__avatar-pf">
          <path fill="#fff" transform="translate(-2.085 3.622)" d="M0 0h44.371v41.336H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
