export function SpeakerIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="81" height="68" viewBox="0 0 81 68" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_3312_1731)">
        <path
          d="M29.2315 67.0208L23.5815 67.9713C22.4654 68.1585 21.4082 67.4061 21.2197 66.29L17.3423 43.2437L27.0341 41.6128L30.9116 64.6591C31.1 65.7763 30.3475 66.8336 29.2315 67.0208Z"
          fill="#00A868"
        />
        <path d="M50.2648 35.7012L57.4953 25.9925L54.7639 19.0333L42.8604 16.8359L50.2648 35.7012Z" fill="#008E5A" />
        <path d="M49.2993 37.0909L57.4959 25.9914L56.4026 23.2048L47.4536 32.389L49.2993 37.0909Z" fill="#1D232A" />
        <path
          d="M47.4534 32.1308C47.4102 32.1308 47.367 32.1152 47.3334 32.084C47.259 32.018 47.253 31.904 47.3202 31.8296L53.5462 24.9208C53.6122 24.8464 53.7262 24.8404 53.8006 24.9076C53.875 24.9736 53.881 25.0876 53.8138 25.162L47.5878 32.0708C47.5506 32.1104 47.5026 32.1308 47.4534 32.1308Z"
          fill="#1D232A"
        />
        <path
          d="M41.6259 3.18854L58.643 46.5425C59.2658 48.1302 57.7945 49.7383 56.16 49.2583L33.0177 42.4707L7.40448 52.5237L0 33.6585L25.6132 23.6055L37.9584 2.88732C38.8297 1.42322 41.003 1.60084 41.6259 3.18854Z"
          fill="#00A868"
        />
        <path
          d="M53.4297 23.5479C54.2146 25.5484 56.0087 26.5745 57.4944 25.9913L54.7474 18.9912C53.2617 19.5744 52.6449 21.5474 53.4297 23.5479Z"
          fill="#00A868"
        />
        <path
          d="M9.16859 40.9453C10.8763 45.2956 9.85983 49.9639 7.40447 52.5237L0 33.6584C3.54143 33.8673 7.46208 36.5974 9.16859 40.9453Z"
          fill="#42EC9A"
        />
        <path
          d="M32.869 39.5198C32.7706 39.5198 32.6902 39.4406 32.689 39.3434C32.6878 39.2438 32.767 39.1622 32.8654 39.1598L53.5823 38.8094C53.6819 38.807 53.7635 38.8874 53.7659 38.9858C53.7671 39.0854 53.6879 39.167 53.5895 39.1694L32.8726 39.5198C32.8714 39.5198 32.8702 39.5198 32.869 39.5198Z"
          fill="#1D232A"
        />
        <path
          d="M80.4193 31.6411C80.4049 31.6411 80.3893 31.6387 80.3737 31.6351L66.5884 28.0493C66.4924 28.0241 66.4348 27.9256 66.46 27.8296C66.4852 27.7336 66.5824 27.676 66.6796 27.7012L80.4649 31.2871C80.5609 31.3123 80.6185 31.4107 80.5933 31.5067C80.5717 31.5883 80.4985 31.6411 80.4193 31.6411Z"
          fill="#1D232A"
        />
        <path
          d="M65.4259 18.6131C65.3539 18.6131 65.2855 18.5699 65.2579 18.4991C65.2219 18.4067 65.2675 18.3023 65.3599 18.2663L78.622 13.0712C78.7144 13.0352 78.8188 13.0808 78.8548 13.1732C78.8908 13.2656 78.8452 13.37 78.7528 13.406L65.4919 18.6011C65.4703 18.6095 65.4475 18.6131 65.4259 18.6131Z"
          fill="#1D232A"
        />
        <path
          d="M56.1601 49.2594L33.0179 42.4694L27.5611 44.6116L27.7579 45.91L27.6379 45.9544C27.6715 46.0432 27.6307 46.1404 27.5431 46.1801L20.7807 49.2198L27.2959 47.6477C27.3871 47.6261 27.4795 47.6777 27.5083 47.7666C27.5383 47.8566 27.4939 47.9526 27.4063 47.9886L20.9835 50.6587L26.4162 49.4983C26.5134 49.4779 26.6094 49.5403 26.6298 49.6375C26.6514 49.7347 26.589 49.8295 26.4918 49.8511L19.1462 51.4196C19.1342 51.422 19.121 51.4232 19.109 51.4232C19.031 51.4232 18.9602 51.374 18.9374 51.2972C18.9086 51.2084 18.9542 51.1124 19.0394 51.0776L25.1574 48.5346L18.9842 50.0239C18.8953 50.0455 18.8017 49.9951 18.7717 49.9075C18.7417 49.8187 18.7837 49.7227 18.8689 49.6843L25.0169 46.9217L18.3745 49.3723L18.1873 48.291L9.88037 51.5516C10.5692 50.7067 11.0552 49.7167 11.2209 48.4962L32.1574 40.2781L56.4818 41.0365L58.6431 46.5425C59.2659 48.1302 57.7946 49.7383 56.1601 49.2594Z"
          fill="#1D232A"
        />
        <path
          d="M60.244 12.1736C60.2092 12.1736 60.1744 12.164 60.1432 12.1424C60.0604 12.0872 60.0388 11.9756 60.094 11.8928L68.0505 0.0780193C68.1057 -0.00478608 68.2185 -0.0263875 68.3001 0.0288161C68.3829 0.0840197 68.4045 0.195627 68.3493 0.278432L60.3928 12.0932C60.358 12.146 60.3016 12.1736 60.244 12.1736Z"
          fill="#1D232A"
        />
      </g>
      <defs>
        <clipPath id="clip0_3312_1731">
          <rect width="80.5996" height="68" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
