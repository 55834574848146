export function PinRegisterIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={316} height={312} viewBox="0 0 316 312" fill="none" {...props}>
      <g clipPath="url(#prefix__pin_register)">
        <path
          d="M300.991 229.072c30.629-72.913 11.376-126.383-30.629-178.88C195.977-42.165 35.831 5.472 6.952 91.995-21.927 178.52 45.457 256.293 93.588 295.18c48.132 38.887 176.774 6.805 207.403-66.108z"
          fill="#F3FBF6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M127.708 117.281c.252-6.539 1.632-14.142 7.007-20.959 5.275-6.691 11.743-11.019 25.858-10.475 14.123.544 20.892 5.34 25.937 12.47 5.126 7.244 6.406 14.997 6.156 21.466l-.736 19.111-.906-.055c-17.807-1.082-47.233-2.868-64.031-2.988l.715-18.57zm-10.018 18.782l.737-19.14c.279-7.244 2.159-18.395 8.373-26.275 6.311-8.006 18.509-14.826 34.136-14.224 15.619.602 26.782 8.363 32.753 16.8 5.891 8.325 8.312 19.594 8.031 26.908l-.744 19.301a243.1 243.1 0 003.894.199c9.463.364 15.529 4.171 15.36 8.572 0 0-.746 56.626-2.659 69.021-1.913 12.396-5.995 20.219-13.484 20.43-7.49.21-95.119-3.837-101.581-4.412-6.462-.576-9.781-5.038-9.48-12.835.067-1.756.155-6.02.272-11.666.402-19.418 1.143-55.19 2.547-61.545 1.813-8.203 7.839-9.262 20.374-10.98.429-.059.92-.11 1.471-.154z"
          fill="#14AA4B"
        />
        <path
          d="M155.799 184.577c-2.141-.209-6.366-2.14-6.132-8.195.189-4.919 4.457-7.407 9.774-7.581 5.316-.174 8.171 4.483 8.025 8.267-.116 3.027-2.777 6.462-4.093 7.8 1.461 4.73 4.142 14.56 3.175 16.038-1.209 1.849-14.406-.175-15.454-2.49-.839-1.851 2.787-9.998 4.705-13.839z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="prefix__pin_register">
          <path fill="#fff" d="M0 0h316v311.842H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export function PinRegisterSuccessIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={460} height={426} viewBox="0 0 460 426" fill="none" {...props}>
      <path
        d="M297.367 55.935C224.251 21.87 170.632 43.282 117.988 90.001c-92.614 82.73-44.845 260.844 41.92 292.962 86.765 32.119 164.756-42.825 203.752-96.356 38.995-53.532 6.824-196.606-66.293-230.672z"
        fill="#ECFDF2"
      />
      <path
        d="M213.951 74.64c-.141-2.35 6.269-1.418 7.335-.439 1.066.98.502 8.393.565 9.404.062 1.011-6.27.369-7.343.431-1.074.063-.29-5.203-.557-9.396zM395.28 61.699c.967-2.148 6.211 1.655 6.7 3.018.489 1.362-3.454 7.666-3.868 8.59-.414.924-5.723-2.586-6.703-3.029-.98-.443 2.16-4.743 3.871-8.58zM278.647 41.216c-2.273-.618.646-6.357 1.928-7.09 1.281-.735 8.113 2.206 9.128 2.482 1.015.276-1.654 6.058-1.938 7.096-.284 1.037-5.06-1.4-9.118-2.488zM321.4 328.628c-2.241-.723.943-6.319 2.258-6.992 1.314-.674 8.001 2.583 9.002 2.907 1.001.323-1.936 5.974-2.268 6.997-.332 1.023-4.989-1.636-8.992-2.912z"
        fill="#103A21"
      />
      <path
        d="M94.294 289.497c1.768 1.557-3.412 5.448-4.858 5.514-1.446.065-6.308-5.559-7.07-6.227-.76-.668 4.158-4.708 4.87-5.514.712-.806 3.896 3.462 7.058 6.227z"
        fill="#1E6839"
      />
      <path
        d="M275.853 110.407c.886-1.513 4.459 1.7 4.702 2.774.243 1.074-3.134 5.407-3.558 6.081-.423.674-4.059-2.351-4.702-2.766-.642-.415 1.975-3.386 3.558-6.089zM316.102 32.734c-1.092-1.371 3.192-3.548 4.281-3.387 1.089.16 3.916 4.871 4.392 5.509.476.637-3.655 2.94-4.274 3.39-.619.45-2.447-3.062-4.399-5.512z"
        fill="#85AD38"
      />
      <path
        d="M387.134 158.994c.886-1.512 4.459 1.701 4.702 2.775.243 1.073-3.135 5.407-3.558 6.081-.423.674-4.059-2.351-4.702-2.767-.642-.415 1.975-3.385 3.558-6.089zM99.02 163.67c.885-1.513 4.458 1.7 4.701 2.774.243 1.073-3.134 5.407-3.558 6.081-.423.674-4.059-2.351-4.702-2.766-.642-.416 1.975-3.386 3.558-6.089zM95.573 122.936c1.387 1.072-2.259 4.204-3.355 4.307-1.096.103-4.96-3.803-5.574-4.31-.614-.506 2.853-3.723 3.348-4.307.494-.584 3.103 2.393 5.581 4.31z"
        fill="#ACE048"
      />
      <path
        d="M321.141 145.288c1.646-1.262 4.122 3.848 3.919 5.149-.204 1.301-5.878 4.506-6.607 5.07-.729.564-3.385-4.404-3.918-5.141-.533-.736 3.667-2.821 6.606-5.078zM159.705 15.199c1.646-1.262 4.122 3.848 3.919 5.148-.204 1.301-5.878 4.506-6.607 5.07-.729.565-3.385-4.404-3.918-5.14-.533-.737 3.667-2.821 6.606-5.078zM68.015 79.46c1.646-1.262 4.122 3.848 3.919 5.148-.204 1.301-5.878 4.507-6.607 5.07-.728.565-3.385-4.403-3.918-5.14-.533-.737 3.667-2.821 6.606-5.078z"
        fill="#14AA4B"
      />
      <path
        d="M140.961 96.293c-2.163-.431.29-6.497 1.465-7.359 1.176-.862 7.758 1.567 8.73 1.74.972.172-1.254 6.27-1.465 7.359-.212 1.089-4.851-.964-8.73-1.74zM387.487 287.424c-2.162-.431.29-6.497 1.466-7.359 1.175-.862 7.758 1.568 8.73 1.74.972.173-1.254 6.309-1.465 7.359-.212 1.05-4.851-.964-8.731-1.74zM340.796 81.403c-2.163-.43.29-6.497 1.465-7.359 1.176-.862 7.758 1.568 8.73 1.74.972.173-1.254 6.27-1.465 7.359-.212 1.09-4.851-.964-8.73-1.74z"
        fill="#C4F752"
      />
      <path
        d="M107.991 133.266c-1.45-.987 1.998-4.325 3.087-4.529 1.09-.204 5.18 3.534 5.831 3.981.65.447-2.657 3.879-3.135 4.529-.478.651-3.197-2.209-5.783-3.981zM400.685 308.37c.477-1.688 4.736.524 5.274 1.493.539.969-1.71 6.033-1.928 6.792-.218.758-4.521-1.291-5.29-1.538-.768-.247 1.083-3.733 1.944-6.747z"
        fill="#85AD38"
      />
      <path
        d="M52.35 227.307c-1.45-.987 1.999-4.326 3.088-4.53 1.09-.203 5.18 3.535 5.83 3.981.651.447-2.656 3.919-3.095 4.53-.439.611-3.236-2.21-5.823-3.981z"
        fill="#ACE048"
      />
      <path
        d="M391.071 210.777c1.731.235.156 4.765-.721 5.439-.878.674-6.215-.823-6.991-.925-.776-.102.611-4.663.721-5.439.11-.775 3.879.518 6.991.925zM129.815 224.36c-1.442 1.004-5.015-5.814-5.102-7.303-.086-1.489 5.141-3.59 5.784-4.036.643-.447 4.373 6.269 5.102 7.304.728 1.034-3.213 2.241-5.784 4.035z"
        fill="#138E3C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M230.199 147.591c4.238 3.968 8.714 6.401 11.079 6.299 5.93-.256 10.823-20.732 8.198-29.772s-24.235-17.33-28.78-1.49c-1.577 5.496-.295 10.973 2.299 15.772l-12.353 27.618 18.015 3.829 1.542-22.256z"
        fill="#B28B67"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M213.978 115.605c-.459-.701-.601-1.593-.71-2.433-.112-.873-.214-1.753-.224-2.639-.019-1.771.39-3.715 1.874-4.771 1.189-.847 2.739-1.011 4.099-.829.905.121 1.794.44 2.589.903.788.459 1.4 1.17 2.149 1.69.418-1.815 1.135-3.654 2.389-5.013 1.206-1.308 2.8-1.846 4.46-1.725 1.704.123 3.256.859 4.568 1.976.328.28.651.568.952.881.252.262.507.567.836.722.366.172.604-.01.914-.238a6.506 6.506 0 011.161-.671c1.79-.809 3.92-.846 5.568.218.759.49 1.449 1.195 1.853 2.05.36.762.495 1.878 1.088 2.482.251.256.512.043.819-.091.461-.2.909-.434 1.373-.625a5.53 5.53 0 012.022-.443c1.013-.018 2.337.318 2.344 1.642.002.498-.185.989-.321 1.461-.185.648-.373 1.296-.57 1.941a14.58 14.58 0 01-.504 1.429c-.106.25-.367.704-.31.989.07.357.755.308 1.028.378.539.138 1.066.35 1.52.69.351.263.705.616.775 1.098.042.297-.044.573-.152.846-.13.327-.103.521-.132.873.807-.205 3.511-.467 3.551.912.013.459-.333.901-.596 1.23-.461.578-1.01 1.078-1.563 1.549-1.154.982-2.449 1.766-3.795 2.385 1.232.895 1.208 2.757-.196 3.57-.62.36-1.342.316-1.992.146-.238-.062-.564-.228-.762-.226-.107.001-.234.064-.377.043-.939-.133-1.937-.578-2.799-.976-1.535-.71-2.899-1.799-3.898-3.229-.257-.367-.485-.595-.916-.683-.378-.076-.77-.051-1.149-.122-1.003-.186-1.799-.865-2.656-1.387-.323 1.24-.972 2.544-1.723 3.562-.613.83-1.513.778-2.387.677-2.793-.324-5.48-1.197-8.223-1.776 2.526 1.165 5.217 1.996 7.698 3.262 1.121.572.042 1.181-.584 1.629-1.032.738-1.896 1.741-2.582 2.844-.939-1.97-2.997-3.574-5.141-3.486-2.369.097-4.597 2.803-3.625 5.294.564 1.447 1.966 2.257 3.104 3.069-1.194.81-1.75 2.262-2.608 3.403-.423.563-.98 1.182-1.676 1.261-.257.03-.54-.023-.771.133-.286.192-.4.452-.744.597-1.373.578-3.227.116-4.056-1.195-.75-1.186-.17-2.687.842-3.513-1.162-.803-3.338-1.98-2.409-3.86-2.723-.77-8.475-6.679-4.072-9.167-1.789-1.583-3.06-4.792-1.227-6.993 1.412-1.695 3.933-2.051 5.874-1.744z"
        fill="#191847"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M174.754 223.563l58.465 97.742 43.831 82.632h9.441l-73.44-180.374h-38.297z"
        fill="#997659"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M273.219 399.137c-.711-.32-1.559-.002-1.828.73-.579 1.57-1.433 4.124-1.433 5.531 0 2.128.71 6.211.71 6.211h44.377s1.73-5.719-1.997-6.175c-3.727-.456-6.192-.823-6.192-.823l-20.74-9.246a.666.666 0 00-.887.354l-1.373 3.332s-3.45 1.609-5.607 1.609c-1.39 0-3.62-.89-5.03-1.523z"
        fill="#5D5343"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M264.878 390.705l18.207-5.507s-11.965-35.77-29.33-78.727c-17.365-42.956-37.299-82.908-37.299-82.908h-51.422s22.162 50.537 46.989 91.839c24.827 41.302 52.855 75.303 52.855 75.303z"
        fill="#191847"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M170.913 223.563c-5.518 34.347-16.16 78.229-17.165 79.584-.669.902-24.775 8.98-72.315 24.232l2.039 8.325c56.692-8.18 86.564-14.526 89.616-19.039 4.578-6.768 27.434-60.243 36.287-93.102h-38.462z"
        fill="#B28B67"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98.144 320.182l3.592 13.409c42.403 3.719 68.384 1.031 77.943-8.062 9.56-9.093 20.454-43.082 32.685-101.966H163.33c-7.749 48.218-12.081 73.339-12.997 75.363-.915 2.024-18.312 9.11-52.189 21.256z"
        fill="#2F3676"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.069 325.65c.19-.756-.27-1.535-1.037-1.673-1.646-.298-4.31-.695-5.695-.45-2.096.369-5.994 1.777-5.994 1.777l7.706 43.703s5.932.71 5.734-3.039c-.198-3.75-.264-6.241-.264-6.241l5.504-22.031a.665.665 0 00-.503-.811l-3.52-.774s-2.184-3.118-2.558-5.243c-.242-1.368.248-3.719.627-5.218z"
        fill="#5D5343"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M262.665 226.716l31.208-4.757c6.196-3.555 11.911-5.987 17.146-7.296 1.478-.068 3.841.425.387 3.379-3.453 2.955-6.952 6.218-6.3 7.752.653 1.535 3.902.905 4.485 3.272.389 1.578-4.897 1.925-15.858 1.041l-25.205 10.4-5.863-13.791zM148.851 199.833l14.956 7.359c-23.8 25.286-36.513 38.661-38.141 40.125-3.662 3.293-3.201 11.251-3.338 15.34-5.957-.2-1.488-8.03-9.055-8.173-6.907-.131-13.503 2.517-18.053-4.656-.56-.882-.531-3.163 2.294-3.58 7.038-1.038 17.725-3.649 19.767-5.228 2.785-2.152 13.308-15.881 31.57-41.187z"
        fill="#B28B67"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M223.374 156.592l6.971.645c10.751 17.943 7.628 53.294 16.105 60.356 8.015 6.676 22.077 6.061 38.968 4.67l1.9 11.789c-18.055 11.368-51.311 16.148-61.449 5.615-16.21-16.839-9.515-59.678-2.495-83.075z"
        fill="#96A0AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M171.924 232.396l58.972 15.802c2.695.722 3.228-3.297 3.363-5.361 1.564-23.82-9.454-52.901-.828-85.093l-16.297-7.22c-20.121 18.813-32.436 45.313-45.21 81.872z"
        fill="#DDE3E9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M219.107 151.053l-3.07-.823c-44.442 13.241-77.246 43.436-98.414 90.585l11.714 5.729c10.739-7.326 25.018-20.199 42.838-38.618-7.181 12.047-13.457 24.696-17.684 37.114l53.084 14.224c17.222-23.165 15.196-107.229 15.196-107.229l-3.664-.982z"
        fill="#C3C8D2"
      />
    </svg>
  )
}
