export function AlgarIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="32" height="32" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00117 7.91806C8.00349 7.74877 8.04743 7.58265 8.12912 7.43436C8.21081 7.28607 8.32774 7.16016 8.46959 7.06774C8.7816 6.87388 9.15518 6.89301 9.43973 7.08937L14.8745 10.4798L14.8819 10.4832C15.1482 10.662 15.3271 10.9824 15.3271 11.3485C15.3271 11.7378 15.1241 12.0756 14.8262 12.2454L9.39313 15.6375L9.38648 15.64C9.24758 15.7232 9.08829 15.7662 8.92639 15.7641C8.76448 15.7621 8.60633 15.7151 8.46959 15.6283C8.31835 15.5292 8.19558 15.3924 8.11343 15.2313C8.03128 15.0702 7.99258 14.8904 8.00117 14.7098V7.91806Z"
        fill="#00A3DA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7931 13.0716C16.8014 12.7304 16.9678 12.4035 17.2623 12.2196C17.408 12.1278 17.5775 12.0808 17.7497 12.0845C17.9219 12.0882 18.0892 12.1424 18.2308 12.2404L23.6655 15.6309L23.673 15.6358C23.9409 15.8156 24.119 16.1351 24.119 16.5011C24.119 16.8905 23.9151 17.2267 23.6181 17.3981C23.6181 17.3981 18.1933 20.7827 18.185 20.7902L18.1767 20.7935C18.0378 20.876 17.8787 20.9184 17.7171 20.9159C17.5555 20.9134 17.3978 20.8661 17.2615 20.7794C16.9495 20.5872 16.7806 20.2252 16.7931 19.8608V13.0716Z"
        fill="#F9C623"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00116 18.2226C8.01031 17.8823 8.17838 17.5561 8.46958 17.3714C8.61555 17.2792 8.78555 17.2322 8.95817 17.2363C9.1308 17.2405 9.29834 17.2956 9.43971 17.3947L14.8744 20.7852L14.8819 20.7893C15.1482 20.9666 15.3271 21.2886 15.3271 21.6521C15.3271 22.0415 15.124 22.381 14.8262 22.5507L9.39312 25.942L9.38647 25.9453C9.24733 26.028 9.08799 26.0706 8.92615 26.0682C8.7643 26.0659 8.60627 26.0187 8.46958 25.932C8.15841 25.7373 7.98868 25.3787 8.00116 25.0143V18.2217"
        fill="#009949"
      />
    </svg>
  )
}
