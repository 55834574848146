export function KycAnalysisIllustration() {
  return (
    <svg width="369" height="263" viewBox="0 0 369 263" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M341.269 124.077C338.621 125.339 335.266 126.715 331.671 131.423C331.073 132.207 326.048 138.95 324.944 140.493C324.944 140.493 280.751 166.942 280.212 166.725C279.676 166.506 264.271 175.985 264.271 175.985L246.234 149.7C246.234 149.7 256.533 142.744 289.636 123.52C302.813 115.745 308.978 111.069 311.465 103.858C315.507 92.1438 315.221 72.2156 315.182 70.8821C315.007 65.4069 317.883 61.7109 326.216 63.4573C331.746 69.2077 339.383 88.4871 346.336 105.745C351.673 118.979 345.663 121.984 341.269 124.077Z"
        fill="#8F5049"
      />
      <path
        d="M338.855 94.7062C322.721 94.7062 312.169 83.461 310.589 74.5683C310.352 73.2315 311.246 71.9569 312.587 71.721C313.931 71.485 315.206 72.3763 315.442 73.7131C316.77 81.1739 326.97 91.1347 341.941 89.6373C343.298 89.4997 344.504 90.486 344.638 91.8359C344.773 93.1859 343.787 94.3916 342.433 94.526C341.214 94.6472 340.018 94.7062 338.855 94.7062Z"
        fill="#42EC9A"
      />
      <path
        d="M353.964 120.106C353.031 120.106 352.137 119.575 351.723 118.674L326.378 63.6342C325.81 62.4022 326.352 60.9408 327.591 60.374C328.829 59.8071 330.292 60.3478 330.86 61.5831L356.205 116.623C356.774 117.855 356.231 119.316 354.993 119.883C354.657 120.034 354.306 120.106 353.964 120.106Z"
        fill="#42EC9A"
      />
      <path
        d="M341.549 126.02C341.457 126.02 341.368 125.994 341.286 125.945C341.056 125.801 340.987 125.496 341.135 125.267C351.995 108.179 366.536 71.3243 366.683 70.954C366.782 70.705 367.061 70.5805 367.314 70.6722C367.567 70.7672 367.696 71.0425 367.607 71.2948C361.774 87.8481 356.595 100.145 352.025 110.997C350.02 115.755 348.127 120.247 346.376 124.638C346.274 124.89 345.988 125.014 345.735 124.913C345.482 124.811 345.357 124.526 345.459 124.274C347.214 119.873 349.107 115.375 351.114 110.614C353.112 105.869 355.228 100.846 357.463 95.3154C352.678 106.023 346.944 117.963 341.966 125.791C341.874 125.938 341.713 126.02 341.549 126.02Z"
        fill="#1D232A"
      />
      <path
        d="M367.139 72.9889C368.166 72.9889 368.999 72.1586 368.999 71.1343C368.999 70.1101 368.166 69.2798 367.139 69.2798C366.112 69.2798 365.279 70.1101 365.279 71.1343C365.279 72.1586 366.112 72.9889 367.139 72.9889Z"
        fill="#00A868"
      />
      <path
        d="M16.713 135.821C16.4961 135.821 16.2956 135.676 16.2365 135.457C13.1805 124.094 9.3457 106.898 5.28418 88.6902C4.03221 83.0741 2.73752 77.2713 1.43297 71.4914C1.40011 71.344 1.38039 71.2588 1.37711 71.2424C1.31796 70.977 1.48555 70.7149 1.75171 70.6559C2.01788 70.5969 2.28076 70.764 2.33991 71.0261C2.35963 71.1113 2.37606 71.1932 2.39577 71.2784C3.22385 74.9318 12.8617 117.02 21.3134 134.533C21.4317 134.779 21.3265 135.07 21.0834 135.188C20.8369 135.306 20.5444 135.201 20.4262 134.959C16.8181 127.485 13.003 115.578 9.74002 104.07C12.4838 116.233 15.0338 127.18 17.1894 135.201C17.2584 135.463 17.104 135.732 16.8411 135.804C16.7984 135.817 16.7557 135.821 16.713 135.821Z"
        fill="#1D232A"
      />
      <path
        d="M304.205 149.825C303.538 150.549 302.917 151.273 302.316 152.004L254.701 170.297L246.536 147.839L245.504 144.992C246.848 145.398 248.221 145.781 249.621 146.142C265.562 150.316 284.916 151.964 304.205 149.825Z"
        fill="#1D232A"
      />
      <path
        d="M296.138 150.791C283.701 150.791 266.804 148.819 256.322 146.666C256.056 146.61 255.885 146.352 255.937 146.086C255.993 145.821 256.253 145.65 256.519 145.703C268.109 148.085 287.565 150.241 300.055 149.733C300.335 149.72 300.558 149.933 300.568 150.205C300.578 150.477 300.367 150.706 300.095 150.716C298.846 150.768 297.519 150.791 296.138 150.791Z"
        fill="#1D232A"
      />
      <path
        d="M148.999 130.411C147.477 130.087 145.962 129.72 144.369 129.287C144.204 129.241 144.037 129.209 143.866 129.173C143.232 129.038 142.515 128.888 142.029 128.301C141.891 128.137 141.783 127.947 141.707 127.738L142.634 127.403C142.67 127.508 142.722 127.6 142.788 127.675C143.054 127.996 143.547 128.101 144.07 128.209C144.257 128.249 144.448 128.288 144.628 128.337C146.206 128.763 147.704 129.127 149.206 129.448L148.999 130.411Z"
        fill="#BBBDBF"
      />
      <path
        d="M175.801 139.92C173.553 141.191 170.3 141.561 167.478 141.345C174.901 143.344 173.13 149.671 168.828 151.247C165.365 152.518 159.131 153.226 154.215 153.898C152.046 154.199 150.16 155.539 149.168 157.486C147.614 160.536 142.622 199.465 135.62 212.08C129.242 223.571 111.642 220.871 102.72 215.393C101.074 214.387 108.382 168.757 108.382 168.757C108.382 168.757 114.569 136.443 117.073 129.31C122.193 114.739 128.006 105.499 130.263 101.961C133.3 97.2161 138.005 96.8623 139.75 100.647C140.894 103.121 138.574 108.868 136.96 112.593C136.642 113.337 136.497 114.267 136.878 114.933C136.957 115.07 137.056 115.198 137.181 115.306C137.756 115.814 138.603 115.765 139.372 115.794C141.482 115.876 160.488 115.88 172.423 116.863C182.958 117.734 182.432 125.07 177.493 127.131C175.157 128.108 169.913 128.888 166.633 129.14C168.072 129.117 171.362 129.651 174.286 130.437C178.64 131.604 180.497 137.253 175.801 139.92Z"
        fill="#8F5049"
      />
      <path
        d="M156.345 142.164C156.141 142.164 155.947 142.036 155.878 141.83C155.79 141.574 155.928 141.292 156.187 141.207C159.138 140.208 162.749 140.1 167.554 140.863C167.823 140.906 168.007 141.158 167.964 141.427C167.922 141.695 167.669 141.876 167.399 141.836C162.759 141.099 159.299 141.194 156.503 142.141C156.45 142.154 156.398 142.164 156.345 142.164Z"
        fill="#1D232A"
      />
      <path
        d="M157.404 130.218C157.2 130.218 157.006 130.09 156.937 129.883C156.848 129.628 156.986 129.346 157.246 129.261C160.2 128.262 163.811 128.153 168.612 128.917C168.882 128.959 169.066 129.212 169.023 129.48C168.98 129.749 168.724 129.933 168.458 129.89C163.818 129.153 160.358 129.248 157.561 130.195C157.509 130.211 157.456 130.218 157.404 130.218Z"
        fill="#1D232A"
      />
      <path
        d="M173.538 111.125L170.114 117.963L169.414 119.359L169.043 120.103L155.879 146.401C154.63 148.894 151.591 149.906 149.09 148.661L130.741 139.53C128.24 138.288 127.225 135.257 128.474 132.763L146.133 97.4878C147.378 94.9943 150.418 93.9818 152.918 95.2269L162.517 100.004L171.264 104.359C173.768 105.604 174.783 108.635 173.538 111.125Z"
        fill="#1D232A"
      />
      <path
        d="M173.539 111.125L170.115 117.963L162.469 107.085L159.137 102.34C159.718 102.111 160.993 101.19 162.521 100.001L171.269 104.356C173.769 105.604 174.785 108.635 173.539 111.125Z"
        fill="#42EC9A"
      />
      <path
        d="M169.415 119.362L169.044 120.106C166.832 116.997 164.621 113.884 162.409 110.774C162.294 110.611 162.403 110.358 162.564 110.273C162.757 110.175 162.948 110.26 163.066 110.427C163.799 111.463 164.539 112.498 165.271 113.533C166.651 115.473 168.032 117.416 169.415 119.362Z"
        fill="#42EC9A"
      />
      <path
        d="M143.363 145.811L124.794 136.568C122.293 135.323 121.278 132.295 122.526 129.802L140.185 94.5259C141.434 92.0324 144.47 91.0199 146.971 92.265L165.54 101.508C168.041 102.753 169.056 105.781 167.808 108.274L150.149 143.55C148.9 146.044 145.86 147.056 143.363 145.811Z"
        fill="#00A868"
      />
      <path d="M163.69 108.607L142.375 97.9976L126.643 129.424L147.958 140.033L163.69 108.607Z" fill="#42EC9A" />
      <path
        d="M127.316 129.202L142.601 98.6699L144.504 99.6174L129.22 130.15L127.316 129.202Z"
        fill="#FCFCFC"
        stroke="#FCFCFC"
      />
      <path
        d="M154.679 101.086C155.264 101.086 155.737 100.613 155.737 100.031C155.737 99.4479 155.264 98.9756 154.679 98.9756C154.095 98.9756 153.621 99.4479 153.621 100.031C153.621 100.613 154.095 101.086 154.679 101.086Z"
        fill="#1D232A"
      />
      <path
        d="M143.656 123.759C143.57 123.93 143.649 124.139 143.827 124.212C146.521 125.322 149.653 124.169 150.951 121.521C152.249 118.874 151.243 115.699 148.71 114.261C148.542 114.166 148.332 114.231 148.246 114.401L147.329 116.269C147.244 116.443 147.306 116.649 147.471 116.754C148.634 117.508 149.074 119.025 148.45 120.299C147.826 121.574 146.35 122.157 145.042 121.702C144.858 121.639 144.658 121.715 144.572 121.888L143.656 123.759Z"
        fill="#1D232A"
      />
      <path
        d="M144.54 115.414C144.478 115.233 144.28 115.135 144.1 115.197L141.33 116.298C141.172 116.361 141.037 116.475 140.955 116.639C140.876 116.803 140.866 116.98 140.912 117.144L141.74 120.001C141.803 120.181 142 120.279 142.181 120.217C142.273 120.184 142.342 120.122 142.381 120.04L144.52 115.679C144.563 115.6 144.57 115.505 144.54 115.414Z"
        fill="#1D232A"
      />
      <path
        d="M168.291 231.687C177.123 202.892 185.388 174.219 196.471 144.933C202.386 132.436 217.341 127.085 229.874 132.983C242.407 138.881 247.773 153.793 241.858 166.29L203.208 247.949C203.07 248.24 202.718 248.368 202.426 248.227L168.573 232.467C168.277 232.329 168.152 231.978 168.291 231.687Z"
        fill="#8F5049"
      />
      <path
        d="M89.4104 96.2941C92.0073 89.0793 91.4206 82.2673 88.1001 81.0789C84.7795 79.8906 79.9825 84.776 77.3856 91.9907C74.7888 99.2055 75.3754 106.018 78.696 107.206C82.0166 108.394 86.8136 103.509 89.4104 96.2941Z"
        fill="#1D232A"
      />
      <path
        d="M95.8026 111.644C101.82 109.002 105.74 104.69 104.558 102.014C103.376 99.3374 97.5401 99.3097 91.5226 101.952C85.5051 104.594 81.5851 108.905 82.767 111.582C83.9489 114.258 89.7851 114.286 95.8026 111.644Z"
        fill="#1D232A"
      />
      <path
        d="M84.2494 106.446C85.5211 107.652 90.2234 104.772 94.7482 100.014C99.2731 95.2567 100.933 91.6492 99.6576 90.4467C98.3826 89.2442 94.6628 90.8988 90.138 95.6532C85.6131 100.411 82.9777 105.24 84.2494 106.446Z"
        fill="#1D232A"
      />
      <path
        d="M69.9228 100.42C68.6576 97.1536 63.9915 90.6267 60.367 93.0644C55.0831 96.6163 60.8468 112.685 63.101 119.444C68.0366 114.769 72.3938 106.813 69.9228 100.42Z"
        fill="#1D232A"
      />
      <path
        d="M79.8518 97.7539C80.5787 90.9537 78.2714 85.1332 74.6982 84.7534C71.1249 84.3736 67.639 89.5783 66.912 96.3785C66.185 103.179 68.4924 108.999 72.0656 109.379C75.6388 109.759 79.1248 104.554 79.8518 97.7539Z"
        fill="#1D232A"
      />
      <path
        d="M74.9758 119.237C75.6848 115.725 70.8094 111.784 64.0863 110.434C57.3632 109.085 51.3384 110.839 50.6294 114.351C49.9204 117.864 54.7958 121.805 61.5189 123.154C68.242 124.503 74.2669 122.75 74.9758 119.237Z"
        fill="#1D232A"
      />
      <path
        d="M65.6731 129.806C69.7361 128.989 72.6817 126.606 72.2524 124.485C71.8231 122.363 68.1814 121.306 64.1184 122.124C60.0554 122.941 57.1098 125.324 57.5391 127.445C57.9684 129.567 61.6101 130.624 65.6731 129.806Z"
        fill="#1D232A"
      />
      <path
        d="M60.2562 140.808C61.8532 144.671 65.9246 146.814 69.9039 146.168C70.0945 146.139 70.2818 146.103 70.4724 146.057C70.6794 146.011 70.8865 145.955 71.0902 145.893C71.2841 145.834 71.4779 145.769 71.6718 145.693C71.6718 145.693 71.6718 145.693 71.6751 145.693C71.7211 145.677 71.7671 145.657 71.8164 145.637L71.8624 145.621L72.3717 145.411L72.4079 145.398L72.9599 145.172L72.9665 145.169L74.0509 144.723L74.1002 144.704L74.5931 144.5L76.2 143.839L73.7322 137.875L73.4101 137.095L73.3214 136.879L73.0191 136.152L69.4209 127.459L66.9498 128.478L66.8282 128.527L66.7395 128.563L66.1316 128.812L65.7734 128.96L65.2312 129.182L65.2214 129.186L65.0899 129.241C64.9552 129.297 64.8205 129.356 64.689 129.418C64.6759 129.425 64.6627 129.428 64.6496 129.438C64.4722 129.523 64.298 129.612 64.1304 129.707C63.9595 129.802 63.7952 129.9 63.6342 130.005C63.4765 130.106 63.3221 130.215 63.1742 130.326C59.99 132.688 58.6789 137 60.2562 140.808Z"
        fill="#8F5049"
      />
      <path
        d="M63.1641 130.326C65.8422 131.951 68.5466 133.534 71.2707 135.077C71.4843 135.198 71.6979 135.319 71.9082 135.437C72.1973 135.601 72.4208 135.139 72.1316 134.979C71.7537 134.766 71.3758 134.553 71.0012 134.336C70.505 134.055 70.0088 133.77 69.5159 133.484C67.541 132.344 65.5727 131.184 63.6241 130.001C63.4664 130.103 63.3119 130.211 63.1641 130.326Z"
        fill="#1D232A"
      />
      <path d="M66.9399 128.471L66.8203 128.52C66.8623 128.516 66.9044 128.497 66.9399 128.471Z" fill="#1D232A" />
      <path
        d="M68.1716 129.936L67.2909 129.156C67.1036 128.959 66.9163 128.759 66.729 128.56L66.1211 128.809L66.9557 129.546C67.0806 129.674 67.2022 129.805 67.3238 129.932C67.3731 129.982 67.4224 130.001 67.4716 130.005L67.8364 130.326C68.0861 130.545 68.4213 130.155 68.1716 129.936Z"
        fill="#1D232A"
      />
      <path
        d="M64.1191 129.703C66.3865 131.175 68.6571 132.646 70.9212 134.117C70.9606 134.143 71.0001 134.169 71.0395 134.192C71.3155 134.373 71.539 133.911 71.2662 133.734L70.6353 133.324L70.2936 133.101C70.3297 133.016 70.3133 132.918 70.2081 132.846C69.035 132.05 67.8849 131.224 66.7578 130.369C67.3592 130.814 67.9769 131.243 68.6046 131.656C68.7196 131.732 68.8707 131.745 68.966 131.63C69.0482 131.532 69.0547 131.342 68.9397 131.266C67.8422 130.549 66.7841 129.776 65.7654 128.953L65.2232 129.176C65.4631 129.369 65.703 129.563 65.9429 129.753C65.6997 129.563 65.4533 129.373 65.2134 129.182L65.0819 129.238C64.9472 129.294 64.8125 129.353 64.681 129.415C64.7829 129.494 64.8815 129.572 64.9834 129.654C64.8684 129.582 64.7566 129.507 64.6416 129.435C64.4609 129.52 64.2867 129.608 64.1191 129.703Z"
        fill="#1D232A"
      />
      <path
        d="M63.3601 120.195C63.4225 120.355 63.485 120.516 63.5507 120.676L63.7971 121.276L63.8596 121.427L64.29 122.465L64.3525 122.616L64.5923 123.203L65.013 124.222L65.0622 124.343L65.0852 124.395L65.3908 125.132L65.5551 125.532L65.8805 126.325L66.5902 128.045L66.6724 128.242L66.7907 128.53L66.8564 128.688L67.3855 129.972L67.5136 130.287L67.8751 131.165L67.8948 131.211L68.4238 132.492L68.6144 132.96L68.9365 133.737L73.5435 144.92L75.0156 148.491C78.6335 157.272 88.6821 161.663 97.515 158.118C106.413 154.543 110.672 144.432 107.038 135.604L98.1492 114.025L96.7164 110.548L95.4973 107.59C94.6923 105.637 93.5717 103.91 92.2146 102.449C91.0514 101.19 89.7172 100.129 88.2648 99.2802C87.3612 98.7527 86.4148 98.3071 85.4323 97.9499C84.1639 97.4879 82.8429 97.1766 81.4923 97.0226C80.9731 96.9637 80.4507 96.9276 79.9249 96.9145C77.9927 96.8686 76.031 97.1472 74.1086 97.7861C73.8359 97.8746 73.5632 97.9761 73.2904 98.081C73.182 98.1236 73.0768 98.1629 72.9717 98.2088C72.8764 98.2481 72.7811 98.2874 72.6891 98.33C71.6343 98.7822 70.6485 99.3294 69.7382 99.9519C68.7557 100.624 67.8586 101.39 67.0536 102.229C63.3042 106.138 61.5561 111.676 62.5287 117.121C62.578 117.406 62.6372 117.688 62.7029 117.973C62.8639 118.72 63.0873 119.464 63.3601 120.195Z"
        fill="#8F5049"
      />
      <path
        d="M65.5566 125.536L73.5417 144.923L75.0138 148.495C78.6317 157.276 88.6803 161.667 97.5131 158.121C106.412 154.547 110.67 144.435 107.036 135.608L103.678 127.456C99.5439 127.659 97.845 129.71 93.3333 133.439L88.47 135.375C83.6922 135.077 76.8901 136.299 73.8604 137.515L67.7583 124.52C67.755 124.52 65.9773 125.261 65.5566 125.536Z"
        fill="#1D232A"
      />
      <path
        d="M67.8209 142.489C67.6993 142.489 67.5777 142.443 67.4824 142.354C67.2852 142.168 67.2754 141.856 67.4627 141.66C68.5142 140.552 68.8362 138.917 68.2842 137.492C67.7321 136.07 66.3914 135.077 64.8634 134.962C64.5907 134.943 64.387 134.707 64.41 134.435C64.433 134.163 64.6663 133.963 64.939 133.983C66.8416 134.124 68.5142 135.365 69.2043 137.138C69.891 138.914 69.4902 140.955 68.179 142.335C68.0805 142.436 67.9523 142.489 67.8209 142.489Z"
        fill="#1D232A"
      />
      <path
        d="M89.6103 125.968L93.6685 130.762C94.4835 131.725 94.1056 133.2 92.9259 133.655L88.4668 135.375"
        fill="#8F5049"
      />
      <path
        d="M88.466 135.863C88.2688 135.863 88.0815 135.745 88.0059 135.549C87.9074 135.296 88.0355 135.011 88.2885 134.913L92.7477 133.193C93.1584 133.036 93.4574 132.698 93.5659 132.272C93.6743 131.846 93.5757 131.41 93.2899 131.073L89.2316 126.279C89.0575 126.073 89.0838 125.762 89.2908 125.588C89.4978 125.414 89.81 125.441 89.9841 125.647L94.0424 130.441C94.5353 131.021 94.7094 131.777 94.5188 132.515C94.3282 133.252 93.8123 133.832 93.0993 134.107L88.6401 135.827C88.5843 135.854 88.5251 135.863 88.466 135.863Z"
        fill="#1D232A"
      />
      <mask id="path-40-inside-1_2006_20648" fill="white">
        <path d="M88.0562 143.753C86.2358 143.753 84.136 143.311 81.987 141.951C81.7569 141.807 81.6879 141.502 81.8358 141.273C81.9804 141.044 82.286 140.975 82.516 141.122C85.7823 143.19 88.9237 142.937 90.9841 142.364C91.247 142.292 91.5164 142.443 91.592 142.705C91.6643 142.967 91.5131 143.239 91.2503 143.311C90.3499 143.56 89.2655 143.753 88.0562 143.753Z" />
      </mask>
      <path
        d="M88.0562 143.753C86.2358 143.753 84.136 143.311 81.987 141.951C81.7569 141.807 81.6879 141.502 81.8358 141.273C81.9804 141.044 82.286 140.975 82.516 141.122C85.7823 143.19 88.9237 142.937 90.9841 142.364C91.247 142.292 91.5164 142.443 91.592 142.705C91.6643 142.967 91.5131 143.239 91.2503 143.311C90.3499 143.56 89.2655 143.753 88.0562 143.753Z"
        fill="#FCFCFC"
      />
      <path
        d="M81.987 141.951L82.5217 141.106L82.518 141.104L81.987 141.951ZM81.8358 141.273L82.6763 141.815L82.6817 141.806L81.8358 141.273ZM82.516 141.122L81.9763 141.964L81.9812 141.967L82.516 141.122ZM90.9841 142.364L90.7196 141.4L90.716 141.401L90.9841 142.364ZM91.592 142.705L92.5561 142.439L92.5529 142.428L91.592 142.705ZM91.2503 143.311L90.9858 142.347L90.9837 142.347L91.2503 143.311ZM88.0562 142.753C86.4053 142.753 84.4948 142.355 82.5217 141.106L81.4523 142.796C83.7773 144.267 86.0663 144.753 88.0562 144.753V142.753ZM82.518 141.104C82.7583 141.254 82.8316 141.574 82.6763 141.815L80.9953 140.731C80.5443 141.431 80.7556 142.36 81.4559 142.798L82.518 141.104ZM82.6817 141.806C82.5302 142.047 82.2137 142.116 81.9764 141.964L83.0557 140.28C82.3583 139.833 81.4306 140.041 80.9899 140.74L82.6817 141.806ZM81.9812 141.967C85.5568 144.23 89.0117 143.951 91.2522 143.327L90.716 141.401C88.8358 141.924 86.0079 142.149 83.0509 140.277L81.9812 141.967ZM91.2485 143.328C90.9848 143.401 90.7075 143.247 90.6311 142.982L92.5529 142.428C92.3254 141.639 91.5091 141.183 90.7196 141.4L91.2485 143.328ZM90.628 142.971C90.5561 142.71 90.7055 142.423 90.9858 142.347L91.5147 144.275C92.3208 144.054 92.7725 143.224 92.556 142.439L90.628 142.971ZM90.9837 142.347C90.1531 142.577 89.1593 142.753 88.0562 142.753V144.753C89.3717 144.753 90.5467 144.543 91.5168 144.275L90.9837 142.347Z"
        fill="#FCFCFC"
        mask="url(#path-40-inside-1_2006_20648)"
      />
      <path d="M89.6562 125.942C89.6562 125.942 94.789 128.167 98.3675 122.541L89.6562 125.942Z" fill="#8F5049" />
      <path
        d="M92.0782 126.853C90.5601 126.853 89.4823 126.404 89.4593 126.391C89.2095 126.283 89.0945 125.994 89.2029 125.745C89.3114 125.496 89.6006 125.382 89.8503 125.49C90.0442 125.572 94.6512 127.466 97.9503 122.279C98.0949 122.049 98.4005 121.981 98.6305 122.128C98.8606 122.272 98.9296 122.577 98.7817 122.806C96.6754 126.119 94.017 126.853 92.0782 126.853Z"
        fill="#1D232A"
      />
      <path d="M78.2383 130.801C78.2383 130.801 83.371 133.026 86.9495 127.4L78.2383 130.801Z" fill="#8F5049" />
      <path
        d="M80.6642 131.712C79.146 131.712 78.0682 131.263 78.0452 131.25C77.7955 131.142 77.6804 130.853 77.7889 130.604C77.8973 130.355 78.1865 130.241 78.4362 130.349C78.6367 130.434 83.2371 132.321 86.533 127.138C86.6776 126.908 86.9832 126.839 87.2132 126.987C87.4432 127.131 87.5122 127.436 87.3643 127.665C85.258 130.978 82.6029 131.712 80.6642 131.712Z"
        fill="#1D232A"
      />
      <path
        d="M73.5409 144.923L68.9339 133.74L68.6152 132.963L68.4246 132.498L67.8955 131.217L67.8758 131.171L67.5176 130.293L67.3895 129.979L66.8604 128.694L66.798 128.54L66.6764 128.252L64.4222 128.14C64.4222 128.14 61.4484 117.082 61.3958 116.8C60.4231 111.354 60.3705 103.484 64.1199 99.5784C64.925 98.7396 80.7537 85.453 92.705 103.022C78.9891 89.4013 61.5469 103.959 67.2646 120.208C67.3205 120.368 67.3895 120.526 67.4552 120.686L67.7016 121.286L67.7641 121.436L68.1945 122.478L68.257 122.629L68.5002 123.212L68.9208 124.231L68.9668 124.353L68.9898 124.408L69.2954 125.149L69.4597 125.545L69.7883 126.338L70.4948 128.062L70.5769 128.255L70.6985 128.543L70.7609 128.697L71.29 129.982L71.4181 130.296L71.7763 131.174L71.796 131.22L72.3251 132.501L72.5157 132.967L72.8344 133.743L77.4414 144.926H73.5409V144.923Z"
        fill="#1D232A"
      />
      <path
        d="M80.4513 116.626L76.439 119.326C75.5617 119.955 74.336 119.759 73.7051 118.884C73.0742 118.009 73.2713 116.787 74.1487 116.158L78.1609 113.458C79.0383 112.829 80.264 113.025 80.8949 113.9C81.5291 114.775 81.3286 115.997 80.4513 116.626Z"
        fill="#1D232A"
      />
      <path
        d="M93.8853 118.176L89.0548 118.491C87.977 118.596 87.0208 117.806 86.9156 116.735C86.8105 115.66 87.6024 114.707 88.6769 114.602L93.5074 114.287C94.5852 114.182 95.5414 114.972 95.6466 116.043C95.7517 117.121 94.9631 118.075 93.8853 118.176Z"
        fill="#1D232A"
      />
      <path
        d="M213.893 236.291C213.728 236.291 213.571 236.209 213.475 236.061C210.294 231.061 207.705 221.641 207.705 215.068C207.705 214.796 207.925 214.577 208.198 214.577C208.471 214.577 208.691 214.796 208.691 215.068C208.691 221.484 211.211 230.668 214.307 235.537C214.451 235.766 214.386 236.071 214.156 236.215C214.073 236.268 213.981 236.291 213.893 236.291Z"
        fill="#1D232A"
      />
      <path d="M107.86 172.083L146.95 167.856L144.417 181.51L107.262 175.903L107.86 172.083Z" fill="#1D232A" />
      <path
        d="M14.5859 128.976C108.517 195.458 230.77 203.325 345.199 119.628C346.52 118.661 348.291 120.018 347.696 121.545C337.785 147.046 288.292 257.978 176.432 259.957C41.8796 262.339 14.5859 128.976 14.5859 128.976Z"
        fill="#00A868"
      />
      <path
        d="M142.634 238.587L80.5806 260.816C78.2573 261.648 75.8388 262.041 73.4335 262.012C69.4673 261.966 65.547 260.763 62.1821 258.479C56.7799 254.806 53.4676 248.774 53.277 242.254L52.4062 212.457L92.9458 202.194L93.2613 213.02L128.832 200.278C139.44 196.477 151.131 201.972 154.943 212.552C158.755 223.132 153.244 234.786 142.634 238.587Z"
        fill="#8F5049"
      />
      <path
        d="M118.984 203.951C126.618 201.093 130.108 197.145 130.63 193.171C131.294 188.148 130.535 185.238 130.367 180.048C130.311 178.321 130.295 176.558 130.847 174.92C131.399 173.285 132.628 171.778 134.304 171.345C136.745 170.719 139.706 171.847 141.411 173.701C145.138 177.758 146.436 184.881 147.494 190.281C151.582 188.023 155.64 186.545 159.964 185.792C162.432 185.363 164.785 185.346 166.681 186.198C168.577 187.05 173.24 190.094 171.469 196.274C173.775 196.994 179.197 200.536 175.56 208.079C177.203 208.888 178.162 210.694 178.53 212.483C178.898 214.272 179.598 220.904 172.921 225.76C164.729 231.716 155.328 234.649 141.687 238.954C137.389 240.311 132.671 241.805 127.439 243.571"
        fill="#8F5049"
      />
      <path
        d="M165.277 202.234C165.073 202.234 164.882 202.106 164.81 201.903C164.721 201.647 164.859 201.365 165.116 201.277C168.083 200.258 170.084 198.443 171.067 195.877C171.165 195.625 171.448 195.497 171.704 195.592C171.957 195.69 172.085 195.972 171.99 196.228C170.916 199.036 168.651 201.103 165.441 202.204C165.385 202.227 165.329 202.234 165.277 202.234Z"
        fill="#1D232A"
      />
      <path
        d="M169.883 212.876C169.686 212.876 169.502 212.758 169.426 212.565C169.324 212.313 169.449 212.028 169.702 211.926C171.703 211.13 173.024 210.606 175.17 207.781C175.334 207.565 175.643 207.522 175.86 207.686C176.077 207.85 176.12 208.158 175.955 208.374C173.757 211.271 172.285 211.959 170.067 212.84C170.005 212.863 169.942 212.876 169.883 212.876Z"
        fill="#1D232A"
      />
      <path
        d="M51.1532 169.799L52.4019 212.454L99.8848 200.438L91.9393 165.566L91.3872 163.239C88.8044 154.632 80.3955 148.586 70.9646 148.858C59.6969 149.189 50.8279 158.564 51.1532 169.799Z"
        fill="#E8E9EA"
      />
      <path
        d="M352.685 194.409C347.276 207.175 340.073 225.284 334.159 237.732C332.098 242.077 325.776 253.65 311.101 246.199L305.672 243.397L296.534 238.682C296.534 238.682 284.812 231.71 293.98 214.363C296.077 210.402 320.978 163.56 326.864 156.463C329.667 153.085 333.153 152.266 336.646 152.767C336.919 152.803 337.195 152.849 337.468 152.908C341.45 153.707 353.589 162.44 355.679 165.149C357.765 167.852 360.897 172.78 357.065 182.951C355.633 186.765 354.279 190.657 352.685 194.409Z"
        fill="#42EC9A"
      />
      <path
        d="M308.232 235.452C298.098 233.866 291.27 224.528 276.729 219.462C229.24 202.928 219.914 188.328 211.049 176.906C202.853 165.359 191.234 153.203 198.93 141.709C206.626 130.218 222.208 127.121 233.732 134.795C250.326 146.162 269.316 158.599 286.226 171.706C292.469 176.545 298.549 178.809 300.261 176.866L308.232 235.452Z"
        fill="#8F5049"
      />
      <path
        d="M286.826 191.985C289.872 188.659 294.9 182.938 300.253 176.87C308.534 167.486 317.593 157.272 321.323 153.806C327.813 147.774 337.017 153.419 342.324 157.78C347.148 161.745 352.947 167.325 351.988 173.485C351.025 179.645 347.982 185.258 344.985 190.73C339.048 201.578 331.089 217.024 324.671 227.535C322.43 231.202 315.904 238.299 305.087 234.695L286.826 191.985Z"
        fill="#8F5049"
      />
      <path
        d="M334.098 215.668C333.224 215.668 332.38 215.206 331.933 214.387C321.733 195.736 319.739 184.426 323.577 164.075C317.915 164.602 309.184 166.309 305.204 171.748C305.096 171.896 305.017 171.997 304.965 172.06C304.084 173.095 302.53 173.226 301.491 172.348C300.466 171.483 300.328 169.963 301.166 168.927L301.225 168.849C303.752 165.398 310.396 159.524 326.534 158.96C327.283 158.934 328.016 159.255 328.506 159.832C328.992 160.408 329.189 161.175 329.035 161.915C324.625 183.161 326.11 193.466 336.264 212.034C336.914 213.227 336.474 214.718 335.281 215.37C334.9 215.573 334.496 215.668 334.098 215.668ZM301.199 168.885C301.192 168.891 301.186 168.901 301.176 168.911C301.186 168.901 301.192 168.891 301.199 168.885Z"
        fill="#42EC9A"
      />
      <path
        d="M304.381 224.056C303.455 224.056 302.531 223.898 301.657 223.564C301.404 223.466 301.276 223.184 301.371 222.929C301.47 222.676 301.756 222.549 302.009 222.644C305.15 223.84 309.166 222.365 310.776 219.419C310.904 219.18 311.206 219.092 311.446 219.223C311.686 219.354 311.775 219.652 311.643 219.891C310.224 222.483 307.299 224.056 304.381 224.056Z"
        fill="#1D232A"
      />
      <path
        d="M211.045 177.4C210.914 177.4 210.785 177.348 210.687 177.246C207.782 174.183 203.537 167.01 202.906 163.318C202.86 163.049 203.04 162.797 203.31 162.751C203.579 162.705 203.832 162.885 203.878 163.154C204.466 166.597 208.62 173.632 211.407 176.575C211.594 176.771 211.584 177.083 211.387 177.269C211.292 177.355 211.167 177.4 211.045 177.4Z"
        fill="#1D232A"
      />
      <path
        d="M1.85989 72.9889C2.88707 72.9889 3.71977 72.1586 3.71977 71.1343C3.71977 70.1101 2.88707 69.2798 1.85989 69.2798C0.832699 69.2798 0 70.1101 0 71.1343C0 72.1586 0.832699 72.9889 1.85989 72.9889Z"
        fill="#00A868"
      />
      <path
        d="M193.625 40.6262L142.685 53.9619C139.613 54.7646 136.468 52.933 135.663 49.8727L127.937 20.5309C127.132 17.4673 128.969 14.3316 132.038 13.5288L182.978 0.193164C186.051 -0.609598 189.195 1.22201 190 4.28234L197.726 33.6241C198.534 36.6877 196.697 39.8201 193.625 40.6262Z"
        fill="#E8E9EA"
      />
      <path d="M213.089 70.0837L201.811 72.6587L208.571 102.101L219.85 99.5261L213.089 70.0837Z" fill="#42EC9A" />
      <path d="M231.868 76.4709L220.59 79.0459L224.985 98.1876L236.264 95.6126L231.868 76.4709Z" fill="#42EC9A" />
      <path d="M247.263 67.3869L235.984 69.9619L241.595 94.3961L252.874 91.8211L247.263 67.3869Z" fill="#42EC9A" />
      <path
        d="M203.611 103.724C203.519 103.724 203.427 103.697 203.348 103.648C203.236 103.579 203.157 103.468 203.128 103.34L194.433 65.4695C194.371 65.2041 194.538 64.9419 194.804 64.8797C195.074 64.8207 195.333 64.9845 195.396 65.2499L203.982 102.642L260.235 89.8014C260.505 89.7424 260.765 89.9062 260.827 90.1716C260.889 90.437 260.722 90.6991 260.456 90.7614L203.723 103.714C203.683 103.72 203.647 103.724 203.611 103.724Z"
        fill="#1D232A"
      />
      <path
        d="M241.981 53.0215C253.585 53.0215 262.992 43.6417 262.992 32.0711C262.992 20.5004 253.585 11.1206 241.981 11.1206C230.378 11.1206 220.971 20.5004 220.971 32.0711C220.971 43.6417 230.378 53.0215 241.981 53.0215Z"
        fill="#00A868"
      />
      <path
        d="M241.982 32.0707L258.077 18.604C259.651 20.4749 260.887 22.608 261.725 24.9049C262.563 27.2018 262.993 29.6264 262.993 32.0707H241.982Z"
        fill="#42EC9A"
      />
      <path
        d="M241.983 32.0708L240.166 11.199C243.537 10.9074 246.932 11.4317 250.06 12.7292C253.188 14.0267 255.952 16.0582 258.121 18.65L241.983 32.0708Z"
        fill="#008E5A"
      />
      <path
        d="M192.025 11.9724L129.962 28.2177L127.994 20.7438C127.156 17.5622 129.065 14.302 132.256 13.4665L182.762 0.245499C185.953 -0.590029 189.222 1.31366 190.06 4.49523L192.025 11.9724Z"
        fill="#42EC9A"
      />
      <path
        d="M129.961 28.709C129.741 28.709 129.544 28.5615 129.485 28.342C129.416 28.0799 129.574 27.8112 129.837 27.7424L191.903 11.4971C192.166 11.4283 192.435 11.5856 192.504 11.8477C192.573 12.1098 192.416 12.3785 192.153 12.4473L130.086 28.6926C130.044 28.7057 130.001 28.709 129.961 28.709Z"
        fill="#1D232A"
      />
      <path
        d="M133.678 42.8281C133.458 42.8281 133.261 42.6807 133.202 42.4612C133.133 42.199 133.29 41.9304 133.553 41.8616L195.62 25.6163C195.883 25.5475 196.152 25.7047 196.221 25.9669C196.29 26.229 196.132 26.4977 195.869 26.5665L133.803 42.8118C133.76 42.8249 133.721 42.8281 133.678 42.8281Z"
        fill="#1D232A"
      />
      <path
        d="M155.395 51.1241C155.175 51.1241 154.978 50.9767 154.919 50.7571L144.272 10.3209C144.203 10.0587 144.361 9.79005 144.624 9.72124C144.887 9.65243 145.156 9.80971 145.225 10.0718L155.872 50.5081C155.941 50.7703 155.783 51.0389 155.52 51.1077C155.481 51.1176 155.438 51.1241 155.395 51.1241Z"
        fill="#1D232A"
      />
      <path
        d="M108.393 172.358C108.156 172.358 107.946 172.184 107.907 171.945C107.864 171.676 108.048 171.424 108.317 171.381L140.547 166.25C140.813 166.208 141.069 166.391 141.112 166.66C141.154 166.929 140.97 167.181 140.701 167.223L108.472 172.355C108.445 172.358 108.419 172.358 108.393 172.358Z"
        fill="#1D232A"
      />
    </svg>
  )
}
