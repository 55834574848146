export function DocumentSuccessIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={140} height={140} fill="none" viewBox="0 0 146 150" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#document_success_clip0)">
        <g clipPath="url(#document_success_clip1)">
          <path
            d="M139.83 19.2175L127.535 122.665L60.2123 114.662L50.0848 113.46L47.7998 113.187L47.6423 113.17L48.0223 109.977L48.3523 107.212L54.4323 56.0325L59.6198 12.3825L59.9348 9.72498L80.6248 12.1825L139.83 19.2175Z"
            fill="#00A868"
          />
          <path
            d="M106.835 104.567C106.78 104.657 106.69 104.717 106.588 104.735L55.8826 114.135C55.8601 114.137 55.8376 114.14 55.8126 114.14C55.7976 114.14 55.7826 114.14 55.7676 114.137L50.0401 113.45C49.8401 113.427 49.6951 113.247 49.7126 113.047L49.9576 110.162L54.0601 55.455L57.3726 11.3C57.3826 11.1625 57.4651 11.0425 57.5901 10.9875L59.6151 10.0575C59.6776 10.03 59.7451 10.0175 59.8101 10.025L82.5726 12.37L82.6101 12.3725C82.6401 12.375 82.6701 12.3825 82.6976 12.3925C82.7101 12.3975 82.7251 12.4025 82.7376 12.41C82.7601 12.42 82.7801 12.4325 82.8001 12.4475C82.8126 12.455 82.8226 12.465 82.8326 12.4775C82.8526 12.495 82.8676 12.5125 82.8801 12.5325C82.8926 12.5475 82.9026 12.565 82.9101 12.585C82.9201 12.605 82.9301 12.6275 82.9351 12.65L82.9401 12.6675L106.328 95.25C106.385 95.45 106.268 95.6575 106.068 95.715C106.035 95.7225 106 95.7275 105.968 95.7275C105.803 95.7275 105.653 95.62 105.605 95.455L92.2651 48.35L106.883 104.272C106.908 104.372 106.893 104.48 106.835 104.567Z"
            fill="#1D232A"
          />
          <path
            d="M78.5323 0.501896L0.00012207 18.0009L22.6582 119.686L101.19 102.187L78.5323 0.501896Z"
            fill="#E8E9EA"
          />
          <path
            d="M15.8427 35.305C15.6702 35.305 15.5152 35.1875 15.4777 35.0125C15.4327 34.81 15.5602 34.61 15.7627 34.565L71.5026 22.1C71.7051 22.0575 71.9051 22.1825 71.9501 22.385C71.9951 22.5875 71.8676 22.7875 71.6651 22.8325L15.9252 35.295C15.8977 35.3 15.8702 35.305 15.8427 35.305Z"
            fill="#1D232A"
          />
          <path
            d="M57.335 42.2799C57.1625 42.2799 57.0075 42.1624 56.97 41.9874C56.925 41.7849 57.0525 41.5849 57.255 41.5399L74.9649 37.5799C75.1674 37.5374 75.3674 37.6624 75.4124 37.8649C75.4574 38.0674 75.3299 38.2674 75.1274 38.3124L57.4175 42.2724C57.39 42.2749 57.3625 42.2799 57.335 42.2799Z"
            fill="#1D232A"
          />
          <path
            d="M19.3027 50.7825C19.1302 50.7825 18.9752 50.665 18.9377 50.49C18.8927 50.2875 19.0202 50.0875 19.2227 50.0425L46.1602 44.02C46.3627 43.975 46.5627 44.1025 46.6077 44.305C46.6527 44.5075 46.5252 44.7075 46.3227 44.7525L19.3852 50.7725C19.3577 50.78 19.3302 50.7825 19.3027 50.7825Z"
            fill="#1D232A"
          />
          <path
            d="M59.2427 50.815C59.0702 50.815 58.9152 50.6975 58.8777 50.5225C58.8327 50.32 58.9602 50.12 59.1627 50.075L76.8726 46.115C77.0751 46.07 77.2751 46.1975 77.3201 46.4C77.3651 46.6025 77.2376 46.8025 77.0351 46.8475L59.3252 50.8075C59.2977 50.81 59.2702 50.815 59.2427 50.815Z"
            fill="#1D232A"
          />
          <path
            d="M21.2125 59.3176C21.04 59.3176 20.885 59.2001 20.8475 59.0251C20.8025 58.8226 20.93 58.6226 21.1325 58.5776L48.07 52.5551C48.2725 52.5101 48.4725 52.6376 48.5175 52.8401C48.5625 53.0426 48.435 53.2426 48.2325 53.2876L21.295 59.3076C21.2675 59.3151 21.24 59.3176 21.2125 59.3176Z"
            fill="#1D232A"
          />
          <path
            d="M49.0197 92.26C48.8472 92.26 48.6922 92.1425 48.6547 91.9675L46.0847 80.5176C46.0397 80.3151 46.1672 80.1151 46.3672 80.0701C46.5697 80.0276 46.7697 80.1526 46.8147 80.3526L49.3847 91.8025C49.4297 92.005 49.3022 92.205 49.1022 92.25C49.0747 92.2575 49.0472 92.26 49.0197 92.26Z"
            fill="#1D232A"
          />
          <path
            d="M54.8649 90.955C54.6924 90.955 54.5374 90.8375 54.4999 90.6625L51.9299 79.2125C51.8849 79.01 52.0124 78.81 52.2124 78.765C52.4149 78.72 52.6149 78.8475 52.6599 79.0475L55.2299 90.4975C55.2749 90.7 55.1474 90.9 54.9474 90.945C54.9199 90.9525 54.8924 90.955 54.8649 90.955Z"
            fill="#1D232A"
          />
          <path
            d="M60.7122 89.6475C60.5397 89.6475 60.3847 89.53 60.3472 89.355L57.7772 77.905C57.7322 77.7025 57.8597 77.5025 58.0597 77.4575C58.2622 77.415 58.4622 77.54 58.5072 77.7425L61.0772 89.1925C61.1222 89.395 60.9947 89.595 60.7947 89.64C60.7647 89.645 60.7372 89.6475 60.7122 89.6475Z"
            fill="#1D232A"
          />
          <path
            d="M66.5571 88.3426C66.3846 88.3426 66.2296 88.2251 66.1921 88.0501L63.6221 76.6001C63.5771 76.3976 63.7046 76.1976 63.9046 76.1526C64.1071 76.1076 64.3071 76.2351 64.3521 76.4351L66.9221 87.8851C66.9671 88.0876 66.8396 88.2876 66.6396 88.3326C66.6121 88.3376 66.5846 88.3426 66.5571 88.3426Z"
            fill="#1D232A"
          />
          <path
            d="M72.4023 87.035C72.2298 87.035 72.0748 86.9175 72.0373 86.7425L69.4673 75.2925C69.4223 75.09 69.5498 74.89 69.7498 74.845C69.9523 74.8 70.1523 74.9275 70.1973 75.1275L72.7673 86.5775C72.8123 86.78 72.6848 86.98 72.4848 87.025C72.4573 87.0325 72.4298 87.035 72.4023 87.035Z"
            fill="#1D232A"
          />
          <path
            d="M78.2471 85.7274C78.0746 85.7274 77.9196 85.6099 77.8821 85.4349L75.312 73.985C75.267 73.7825 75.3945 73.5825 75.5945 73.5375C75.797 73.4925 75.997 73.62 76.042 73.82L78.6121 85.2699C78.6571 85.4724 78.5296 85.6724 78.3296 85.7174C78.3021 85.7249 78.2746 85.7274 78.2471 85.7274Z"
            fill="#1D232A"
          />
          <path
            d="M84.092 84.4225C83.9195 84.4225 83.7645 84.305 83.727 84.13L81.157 72.6801C81.112 72.4776 81.2395 72.2776 81.4395 72.2326C81.642 72.1876 81.842 72.3151 81.887 72.5151L84.457 83.965C84.502 84.1675 84.3745 84.3675 84.1745 84.4125C84.147 84.42 84.1195 84.4225 84.092 84.4225Z"
            fill="#1D232A"
          />
          <path
            d="M35.0826 96.4525C33.1326 96.4525 31.3726 95.2475 30.6526 93.86C30.5576 93.675 30.6301 93.45 30.8126 93.355C30.9976 93.26 31.2226 93.3325 31.3176 93.515C32.0326 94.89 33.9526 96.05 35.9351 95.61C37.3601 95.2925 38.4676 94.395 38.8976 93.205C39.2151 92.3225 39.0851 91.41 38.5376 90.705C37.4826 89.345 36.1626 89.2725 34.4926 89.18L34.3351 89.1725C32.5326 89.0725 30.9651 88.8775 30.0326 87.505C29.4151 86.6 29.2976 85.485 29.7101 84.45C30.1926 83.2325 31.3426 82.315 32.7876 81.995C34.2276 81.675 35.8151 82.1425 36.9326 83.215C37.0826 83.3575 37.0876 83.595 36.9426 83.745C36.8001 83.895 36.5626 83.9 36.4126 83.755C35.6776 83.0475 34.3826 82.4075 32.9501 82.725C31.7301 82.995 30.8026 83.725 30.4076 84.725C30.0901 85.5275 30.1801 86.385 30.6551 87.0825C31.3976 88.1725 32.7226 88.33 34.3801 88.4225L34.5351 88.4325C36.3226 88.53 37.8651 88.615 39.1326 90.245C39.8401 91.1575 40.0151 92.33 39.6076 93.46C39.0901 94.8925 37.7801 95.97 36.1026 96.3425C35.7551 96.4175 35.4151 96.4525 35.0826 96.4525Z"
            fill="#1D232A"
          />
          <path
            d="M36.2825 97.965C36.11 97.965 35.955 97.8475 35.9175 97.6725L32.16 80.8425C32.115 80.64 32.2425 80.44 32.445 80.395C32.6475 80.35 32.8475 80.4775 32.8925 80.68L36.65 97.51C36.695 97.7125 36.5675 97.9125 36.365 97.9575C36.3375 97.9625 36.31 97.965 36.2825 97.965Z"
            fill="#1D232A"
          />
        </g>
        <g filter="url(#filter0_d_701:31588)">
          <path
            d="M118 147.5C133.464 147.5 146 134.964 146 119.5C146 104.036 133.464 91.5 118 91.5C102.536 91.5 90 104.036 90 119.5C90 134.964 102.536 147.5 118 147.5Z"
            fill="#E8E9EA"
          />
        </g>
        <path
          d="M113.352 133.628C112.639 133.628 111.922 133.369 111.358 132.847L102.029 124.226C100.837 123.123 100.764 121.266 101.864 120.074C102.967 118.882 104.824 118.809 106.016 119.909L113.187 126.537L129.817 108.541C130.92 107.349 132.777 107.276 133.969 108.377C135.161 109.479 135.234 111.337 134.134 112.529L115.51 132.683C114.932 133.311 114.144 133.628 113.352 133.628Z"
          fill="#00A868"
        />
      </g>
      <defs>
        <filter id="filter0_d_701:31588" x="87" y="90.5" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.290196 0 0 0 0 0.309804 0 0 0 0 0.333333 0 0 0 0.32 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_701:31588" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_701:31588" result="shape" />
        </filter>
        <clipPath id="document_success_clip0">
          <rect width="146" height="147" fill="white" transform="translate(0 0.5)" />
        </clipPath>
        <clipPath id="document_success_clip1">
          <rect width="139.83" height="122.165" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
