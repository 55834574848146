export const ACQUIRER_ROUTES = [
  //receivables
  '/recebimentos',
  '/recebimentos/${date}/detalhes',
  '/recebimentos/${date}/detalhes-do-pagamento',
  '/recebimentos/detalhes-da-venda-split/${id}',
  '/contas-de-recebimento',
  '/contas-de-recebimento/detalhes-da-conta/${id}',
  '/contas-de-recebimento/alterar-conta/${id}/escolha-as-unidades',
  '/contas-de-recebimento/alterar-conta/${id}/onde-quer-receber',
  '/contas-de-recebimento/alterar-conta/${id}/insira-o-cpf-do-titular',
  '/contas-de-recebimento/alterar-conta/${id}/informacoes-da-conta',
  '/contas-de-recebimento/alterar-conta/${id}/confirmacao-dos-dados',
  '/contas-de-recebimento/alterar-conta/${id}/autenticacao',
  '/contas-de-recebimento/alterar-conta/${id}/sucesso-na-alteracao',
  '/contas-de-recebimento/alterar-conta/${id}/falha-na-alteracao',
  // terminals
  '/maquininhas',
  '/maquininhas/cobrancas',
  '/maquininhas/isencao-por-volume',
  '/maquininhas/${type}/ajuda',
  '/maquininhas/pedidos/${order}',
  //rav
  '/antecipacao',
  '/antecipacao/historico',
  '/antecipacao/pontual/formulario',
  '/antecipacao/pontual/confirmar',
  '/antecipacao/pontual/challenge',
  '/antecipacao/pontual/sucesso',
  '/antecipacao/automatica/selecionar',
  '/antecipacao/automatica/confirmar',
  '/antecipacao/automatica/challenge',
  '/antecipacao/automatica/sucesso',
  '/antecipacao/automatica/cancelamento',
  '/antecipacao/automatica/cancelamento-sucesso',
  '/antecipacao/automatica/detalhes',
  '/antecipacao/recebimento-diario/confirmar',
  '/antecipacao/recebimento-diario/challenge',
  '/antecipacao/recebimento-mesmo-dia/confirmar',
  '/antecipacao/recebimento-mesmo-dia/challenge',
  '/antecipacao/recebimento-mesmo-dia/detalhes',
  '/antecipacao/novo-recebimento-diario/confirmar',
  '/antecipacao/novo-recebimento-diario/challenge',
  '/antecipacao/nova-automatica/selecionar',
  '/antecipacao/nova-automatica/confirmar',
  '/antecipacao/nova-automatica/challenge',
  '/antecipacao/nova-automatica/sucesso',
  '/antecipacao/nova-automatica/detalhes',

  //vendas
  '/vendas',
  '/vendas/v2',
  '/vendas/detalhes/cartao/${atk}',
  '/vendas/detalhes/boleto/${atk}',
  '/vendas/cancelamento/formulario',
  '/simular-venda',
  //smb
  '/meu-plano',
  '/tarifas',
  '/tarifas/tarifas-de-vendas',
  '/tarifas/tarifas-de-contas',
  //receivables-contracts
  '/contratos-de-recebiveis/contratos',
  '/contratos-de-recebiveis/detalhes-do-contrato',
  '/contratos-de-recebiveis/contestar-contrato',
  '/contratos-de-recebiveis/contratos-por-instituicao',
  //reports
  '/relatorios-v2/tipos-de-relatorio',
  '/relatorios-v2/enviar-relatorio'
] as const
