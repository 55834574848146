import { useEffect } from 'react'

import { experimentAnalytics } from '../analitica'
import { type AnalyticsEvent } from '../types'

export function useComponentView(analyticsEvent?: AnalyticsEvent) {
  useEffect(() => {
    if (analyticsEvent != null) {
      experimentAnalytics.events.experiment.view(analyticsEvent)
    }
  }, [analyticsEvent])

  return null
}
