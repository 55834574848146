export function WomanOnRollerSkaterIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="347" height="276" viewBox="0 0 347 276" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_13403_461)">
        <path
          d="M207.831 18.7132L204.936 18.7545L205.191 22.989L202.296 23.0303L202.547 27.2648L199.653 27.3061L199.894 31.4856C197.48 28.0897 192.905 26.9245 188.722 26.9314C184.497 26.9417 180.303 27.5879 176.079 27.5226C168.647 27.4057 159.582 23.0303 156.746 18.2561L156.756 18.2389L156.76 18.2492L160.084 17.8162L159.654 14.5165L162.958 14.0869L162.528 10.7838L165.831 10.3576L165.402 7.0545L168.702 6.62486L168.272 3.32178C168.808 3.7308 174.246 11.375 189.189 10.3301C192.895 10.0723 196.635 9.8042 200.327 10.2648C204.008 10.7219 207.996 11.6878 210.505 14.4203L207.583 14.4684L207.831 18.7132Z"
          fill="#E8E9EA"
        />
        <path
          d="M211.066 132.383C215.178 126.798 220.537 120.284 226.356 116.51C230.227 114.001 234.486 112.159 238.553 109.98C242.619 107.801 246.583 105.212 249.419 101.576C252.254 97.9395 253.836 93.0863 252.67 88.6214C255.792 88.5492 258.72 86.9166 260.872 84.6515C263.024 82.3865 264.227 79.2484 264.138 76.1275C265.589 75.7769 267.043 75.3025 268.39 74.6667C269.741 74.0274 270.985 73.0822 271.731 71.7898C272.491 70.4699 272.677 68.8339 272.226 67.3765C271.776 65.9226 270.418 64.5546 267.239 64.3244C256.225 63.5269 245.084 65.3555 233.534 69.4869C227.752 71.5561 221.857 73.6837 217.01 77.4542C214.817 79.159 212.881 81.1697 210.956 83.1736C201.658 92.8354 192.363 102.497 183.064 112.159C192.504 118.549 201.63 125.997 211.066 132.383Z"
          fill="#8F5049"
        />
        <path d="M221.106 102.634L234.444 104.205L235.448 103.081L223.286 95.564L221.106 102.634Z" fill="#1D232A" />
        <path
          d="M232.062 39.0718C230.711 42.9695 229.687 49.9297 228.185 53.4837L224.998 59.9765V59.9799L211.967 86.5352C211.193 88.3637 211.809 90.6047 213.349 91.5396L222.589 97.1524L233.327 79.2106L255.059 42.8974L242.268 35.126C238.518 32.8506 233.95 34.6173 232.062 39.0718Z"
          fill="#00A868"
        />
        <path
          d="M230.869 68.1982L221.172 67.7686L211.963 86.5353C211.189 88.3638 211.805 90.6048 213.345 91.5397L222.585 97.1526L230.869 68.1982Z"
          fill="#1D232A"
        />
        <path
          d="M217.882 89.4805L248.51 38.9238L262.291 47.2967C265.701 49.3693 266.781 54.5696 264.563 58.268L237.569 102.751C236.671 104.229 235.004 104.704 233.636 103.872L219.006 94.9834C217.298 93.9419 216.765 91.3263 217.882 89.4805Z"
          fill="#42EC9A"
        />
        <path
          d="M250.796 40.3091L241.982 34.9541C245.392 37.0267 246.472 42.2271 244.255 45.9254L249.28 43.8872L250.796 40.3091Z"
          fill="#42EC9A"
        />
        <path
          d="M220.279 89.7629L225.153 81.8747C225.565 81.1942 226.38 81.0017 227.068 81.421L240.694 89.6976C241.546 90.2166 241.852 91.4368 241.344 92.2789L236.466 100.167C236.053 100.848 235.239 101.04 234.551 100.621L220.925 92.3442C220.076 91.8252 219.77 90.6016 220.279 89.7629Z"
          fill="#1D232A"
        />
        <path
          d="M257.692 66.0706C257.599 66.0706 257.51 66.0465 257.424 65.9949L240.979 56.0032C240.735 55.8554 240.659 55.5392 240.807 55.2952C240.955 55.0511 241.271 54.9755 241.515 55.1233L257.96 65.115C258.204 65.2628 258.28 65.5791 258.132 65.8231C258.036 65.9812 257.867 66.0706 257.692 66.0706Z"
          fill="#1D232A"
        />
        <path
          d="M234.671 100.559L220.773 92.1138C220.044 91.6704 219.81 90.7183 220.254 89.9897L225.094 82.0293C225.537 81.3006 226.49 81.0669 227.218 81.5103L241.116 89.9553C241.845 90.3987 242.079 91.3508 241.635 92.0794L236.795 100.04C236.352 100.772 235.4 101.002 234.671 100.559Z"
          fill="#1D232A"
        />
        <path
          d="M228.071 80.1629L241.968 88.6079C242.697 89.0513 243.649 88.8175 244.093 88.0889L255.877 68.7C256.32 67.9714 256.086 67.0193 255.358 66.5759L241.46 58.1309C240.731 57.6875 239.779 57.9212 239.335 58.6499L227.552 78.0387C227.108 78.7674 227.338 79.7195 228.071 80.1629Z"
          fill="#1D232A"
        />
        <path
          d="M243.374 51.136L246.478 45.5472C247.183 44.2755 248.822 43.973 250.259 44.846L262.026 51.9953C263.666 52.992 264.387 55.1024 263.58 56.5529"
          fill="#42EC9A"
        />
        <path
          d="M263.583 57.0721C263.497 57.0721 263.411 57.0515 263.332 57.0068C263.085 56.8693 262.992 56.5531 263.133 56.3056C263.803 55.0957 263.164 53.2912 261.761 52.4388L249.995 45.2896C249.362 44.9047 248.651 44.774 248.046 44.9356C247.561 45.0628 247.176 45.3618 246.932 45.8017L243.828 51.3905C243.691 51.6414 243.374 51.7274 243.127 51.5899C242.879 51.4524 242.786 51.1362 242.927 50.8887L246.031 45.2999C246.41 44.6228 247.032 44.1382 247.785 43.9388C248.671 43.7051 249.648 43.8701 250.535 44.4097L262.301 51.5589C264.188 52.7035 264.982 55.1095 264.037 56.8074C263.941 56.9758 263.762 57.0721 263.583 57.0721Z"
          fill="#00A868"
        />
        <path d="M223.783 83.3888L223.247 84.27L240.527 94.7688L241.062 93.8876L223.783 83.3888Z" fill="#42EC9A" />
        <path d="M231.235 83.3345L224.085 95.106L224.966 95.6412L232.117 83.8698L231.235 83.3345Z" fill="#42EC9A" />
        <path d="M236.75 86.6851L229.6 98.4565L230.481 98.9918L237.631 87.2204L236.75 86.6851Z" fill="#42EC9A" />
        <path d="M221.379 87.3468L220.844 88.228L232.227 95.1445L232.763 94.2633L221.379 87.3468Z" fill="#42EC9A" />
        <path
          d="M222.51 105.446C228.381 97.7021 229.285 88.6212 225.277 78.2445C225.913 76.3094 227.676 74.9621 229.498 74.0547C231.32 73.1473 233.3 72.5458 235.012 71.4425C236.724 70.3391 238.188 68.5518 238.157 66.5136C238.133 64.7435 236.944 63.1246 235.407 62.2413C233.871 61.3579 232.042 61.1173 230.268 61.1757C226.353 61.3064 221.815 62.8599 218.904 65.479C215.143 68.868 213.369 71.8308 210.733 76.1513C204.418 86.4936 194.848 95.8186 185.075 102.985L179.283 109.241L203.219 128.231"
          fill="#8F5049"
        />
        <path
          d="M242.821 95.9667C242.887 95.2517 242.952 94.5402 243.014 93.8253C243.134 92.4814 243.258 91.1341 243.516 89.8073C243.743 88.649 244.083 87.4976 244.702 86.4905C245.32 85.4834 246.248 84.6379 247.376 84.2839C248.503 83.9298 249.834 84.1464 250.679 84.9678C251.711 85.9681 252.037 86.7002 252.67 88.6181"
          fill="#8F5049"
        />
        <path
          d="M249.755 82.6854C250.374 81.6646 252.398 78.7327 252.67 77.2444C253.526 72.5699 254.743 70.5661 257.407 69.4009C260.136 68.2082 263.907 70.054 264.141 74.4363"
          fill="#8F5049"
        />
        <path
          d="M252.67 89.1367C252.454 89.1367 252.251 88.9992 252.182 88.7826C251.557 86.8888 251.258 86.2461 250.323 85.3421C250.034 85.0602 249.656 84.854 249.229 84.7406C248.954 84.6684 248.789 84.3866 248.862 84.1116C248.934 83.8366 249.219 83.6751 249.491 83.7438C250.089 83.9019 250.622 84.1975 251.038 84.6031C252.175 85.7064 252.523 86.5382 253.159 88.463C253.248 88.7345 253.1 89.0267 252.829 89.1126C252.777 89.1298 252.725 89.1367 252.67 89.1367Z"
          fill="#1D232A"
        />
        <path
          d="M264.137 76.6429C263.852 76.6429 263.622 76.4126 263.622 76.1274C263.622 73.5942 263.03 71.7519 261.869 70.6554C260.782 69.6346 259.49 69.5521 258.816 69.5899C258.52 69.6071 258.287 69.3906 258.27 69.1053C258.252 68.82 258.469 68.576 258.754 68.5588C259.596 68.5072 261.209 68.6138 262.577 69.9027C263.955 71.2019 264.656 73.2952 264.656 76.1239C264.653 76.4126 264.423 76.6429 264.137 76.6429Z"
          fill="#1D232A"
        />
        <path
          d="M250.852 140.769L235.033 184.307C229.44 182.616 178.358 167.249 178.358 167.249L203.603 127.674C203.603 127.674 203.658 127.688 203.758 127.719C203.796 127.732 203.841 127.746 203.896 127.76C204.061 127.811 204.294 127.88 204.59 127.966C204.648 127.983 204.71 128.004 204.772 128.021C205.525 128.244 206.618 128.571 207.972 128.973C208.148 129.025 208.323 129.08 208.509 129.135C208.677 129.179 208.856 129.234 209.035 129.286C209.282 129.358 209.533 129.437 209.794 129.509C210.111 129.605 210.434 129.702 210.767 129.798C210.846 129.822 210.922 129.843 211.001 129.867C211.386 129.98 211.781 130.094 212.19 130.217C223.472 133.531 244.706 139.415 250.852 140.769Z"
          fill="#8F5049"
        />
        <path
          d="M240.597 138.167L225.805 186.978C211.096 182.073 174.793 169.985 174.793 169.985L200.244 125.433L240.597 138.167Z"
          fill="#008E5A"
        />
        <path
          d="M217.03 184.057C216.562 183.898 216.095 183.74 215.631 183.589L211.097 131.774C211.097 131.774 213.413 170.971 214.197 183.111C213.73 182.953 213.262 182.799 212.798 182.647C212.661 180.523 212.327 174.319 211.757 165.111L211.671 182.266C193.624 176.244 178.203 171.112 178.203 171.112L203.654 126.516C203.654 126.516 203.709 126.529 203.809 126.567C203.847 126.581 203.892 126.598 203.947 126.612C204.112 126.667 204.345 126.742 204.641 126.839C204.699 126.859 204.761 126.88 204.823 126.901C205.576 127.148 206.666 127.506 208.017 127.952C208.188 128.007 208.367 128.069 208.549 128.128C208.718 128.179 208.897 128.238 209.075 128.293C209.323 128.375 209.574 128.461 209.832 128.54C210.148 128.647 210.471 128.753 210.804 128.86C210.883 128.884 210.959 128.911 211.038 128.935C211.423 129.063 211.818 129.19 212.224 129.324C212.568 132.823 212.96 136.311 213.269 139.824C214.493 153.758 215.665 167.878 217.03 184.057Z"
          fill="#1D232A"
        />
        <path
          d="M251.171 141.352C251.181 141.354 251.191 141.357 251.202 141.359C251.486 141.423 251.877 141.511 252.303 141.606C252.942 141.749 253.664 141.909 254.23 142.031L243.364 186.205C243.143 186.141 242.923 186.078 242.701 186.014C240.759 185.453 238.768 184.878 235.684 183.974L251.171 141.352Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <path
          d="M152.556 83.964C152.47 84.0053 152.381 84.0499 152.295 84.0878C151.841 84.2974 151.38 84.4727 150.916 84.6033C150.847 84.6205 150.775 84.6446 150.703 84.6583C150.603 84.6892 150.497 84.7133 150.397 84.7374V84.7408C150.191 84.7855 149.992 84.8233 149.785 84.8542C149.703 84.868 149.624 84.8783 149.548 84.8852C149.452 84.8989 149.352 84.9092 149.256 84.9127C149.242 84.9161 149.228 84.923 149.215 84.9161C148.995 84.9401 148.775 84.9539 148.551 84.9573H148.548C148.4 84.9608 148.252 84.9608 148.104 84.9573C147.998 84.9539 147.895 84.947 147.785 84.9436C147.688 84.9402 147.589 84.9298 147.489 84.9195H147.486C147.297 84.9023 147.111 84.8783 146.929 84.8474C146.819 84.8336 146.709 84.8164 146.606 84.7924C146.413 84.7546 146.221 84.7133 146.032 84.6652C145.884 84.6274 145.739 84.5861 145.592 84.5414C143.237 83.8162 141.178 82.1698 139.985 79.7982C137.665 75.1753 139.524 69.5453 144.148 67.2218C148.771 64.8948 154.409 66.7612 156.729 71.3841C159.042 76.0105 157.176 81.6405 152.556 83.964Z"
          fill="#8F5049"
        />
        <path
          d="M149.889 74.6873C148.988 78.1003 148.002 81.4928 146.922 84.8508C146.812 84.8371 146.702 84.8199 146.599 84.7958C146.407 84.758 146.214 84.7168 146.025 84.6687C145.877 84.6309 145.733 84.5896 145.585 84.5449C146.671 81.1628 147.665 77.76 148.569 74.3229C148.792 73.4705 150.112 73.8314 149.889 74.6873Z"
          fill="#1D232A"
        />
        <path
          d="M152.295 84.0843C151.841 84.294 151.38 84.4693 150.916 84.5999C150.927 84.1565 150.93 83.7131 150.933 83.2697C150.854 83.7303 150.772 84.1943 150.7 84.6549C150.6 84.6858 150.493 84.7098 150.394 84.7339V84.7373C150.187 84.782 149.988 84.8198 149.782 84.8508C149.699 84.8645 149.62 84.8748 149.545 84.8817C149.448 84.8955 149.349 84.9058 149.252 84.9092C149.263 84.8267 149.27 84.7477 149.28 84.6686C149.256 84.7511 149.235 84.8336 149.211 84.9161C148.991 84.9401 148.771 84.9539 148.548 84.9573H148.544C148.397 84.9608 148.249 84.9608 148.101 84.9573C147.994 84.9539 147.891 84.947 147.781 84.9436C148.678 81.9292 149.387 78.8667 149.912 75.763C150.036 75.055 150.94 75.1718 151.184 75.7115C151.614 75.6152 152.181 75.9727 152.082 76.5536C152.057 76.6876 152.04 76.8148 152.016 76.9454C152.137 77.0588 152.216 77.2204 152.226 77.4163C152.305 79.6401 152.329 81.8639 152.295 84.0843Z"
          fill="#1D232A"
        />
        <path
          d="M177.079 90.4913C167.317 95.3926 155.426 91.4571 150.521 81.6957L144.904 70.525C144.901 70.525 144.901 70.525 144.901 70.525L144.639 70.0026L144.533 69.786C142.429 65.6065 141.951 61.0316 142.855 56.7868C143.797 52.3701 146.238 48.3074 149.913 45.3996C150.954 44.5781 152.092 43.846 153.326 43.2308C156.011 41.8765 158.864 41.196 161.679 41.1272C161.72 41.1272 161.762 41.1238 161.803 41.1238C161.868 41.1204 161.93 41.1204 161.995 41.1204C164.453 41.0929 166.88 41.5294 169.159 42.3852C169.362 42.4608 169.561 42.5399 169.761 42.6224C174.002 44.3684 177.66 47.5959 179.884 52.0195L185.869 63.9326C190.777 73.6975 186.842 85.5865 177.079 90.4913Z"
          fill="#8F5049"
        />
        <path
          d="M174.109 81.9082C170.788 83.5615 166.852 82.7331 164.395 80.2378C169.441 76.9931 173.542 75.0821 178.619 73.147C179.134 76.6082 177.429 80.255 174.109 81.9082Z"
          fill="#E09661"
        />
        <path
          d="M165.918 80.8156C165.409 80.8156 164.89 80.7949 164.358 80.7537C164.072 80.7331 163.863 80.4856 163.883 80.2003C163.904 79.915 164.158 79.7054 164.437 79.726C170.428 80.1831 175.052 77.8768 178.183 72.8758C178.334 72.6352 178.654 72.563 178.895 72.7108C179.135 72.8621 179.211 73.1817 179.06 73.4223C175.987 78.334 171.569 80.8156 165.918 80.8156Z"
          fill="#1D232A"
        />
        <path d="M173.907 51.8301C173.982 54.6588 171.927 57.9207 167.571 57.6629Z" fill="#1D232A" />
        <path d="M151.906 61.9354C153.36 59.5088 156.843 57.8555 160.383 60.4093Z" fill="#1D232A" />
        <path
          d="M186.34 50.8472L145.695 71.9993C145.217 72.2502 145.132 72.9308 145.51 73.3948C147.954 76.3645 151.573 78.0074 155.252 77.9593C155.561 77.9559 155.867 77.9421 156.176 77.9146C156.781 77.8596 157.383 77.76 157.981 77.6087C158.569 77.4644 159.15 77.2719 159.717 77.0278C160.016 76.9041 160.312 76.7666 160.604 76.6154C165.671 73.9791 168.056 68.1979 166.719 62.7672C169.156 65.5547 172.656 67.098 176.217 67.0499C176.526 67.0464 176.832 67.0327 177.142 67.0052C177.747 66.9502 178.348 66.8505 178.946 66.6993C179.534 66.5549 180.115 66.3624 180.682 66.1184C180.981 65.9947 181.277 65.8572 181.569 65.7059C186.749 63.0112 189.132 57.0272 187.592 51.4968C187.427 50.9194 186.818 50.5998 186.34 50.8472Z"
          fill="#1D232A"
        />
        <mask id="path-36-inside-1_13403_461" fill="white">
          <path d="M156.73 66.2559C156.544 70.1433 156.358 74.0272 156.173 77.9112C155.863 77.9387 155.557 77.9559 155.248 77.9559C155.427 74.2197 155.602 70.4835 155.781 66.7474L156.73 66.2559Z" />
        </mask>
        <path
          d="M156.73 66.2559C156.544 70.1433 156.358 74.0272 156.173 77.9112C155.863 77.9387 155.557 77.9559 155.248 77.9559C155.427 74.2197 155.602 70.4835 155.781 66.7474L156.73 66.2559Z"
          fill="#FCFCFC"
        />
        <path
          d="M156.73 66.2559L157.728 66.3036L157.811 64.5693L156.27 65.3679L156.73 66.2559ZM156.173 77.9112L156.261 78.9073L157.13 78.83L157.172 77.9589L156.173 77.9112ZM155.248 77.9559L154.249 77.9081L154.199 78.9559H155.248V77.9559ZM155.781 66.7474L155.321 65.8595L154.81 66.1244L154.782 66.6996L155.781 66.7474ZM155.731 66.2082C155.545 70.0955 155.359 73.9795 155.174 77.8634L157.172 77.9589C157.357 74.075 157.543 70.191 157.728 66.3036L155.731 66.2082ZM156.084 76.9151C155.796 76.9407 155.521 76.9559 155.248 76.9559V78.9559C155.594 78.9559 155.931 78.9366 156.261 78.9073L156.084 76.9151ZM156.247 78.0037C156.336 76.1353 156.425 74.267 156.513 72.3989C156.602 70.5308 156.69 68.663 156.78 66.7952L154.782 66.6996C154.693 68.5679 154.604 70.4362 154.516 72.3043C154.427 74.1724 154.339 76.0403 154.249 77.9081L156.247 78.0037ZM156.241 67.6353L157.19 67.1438L156.27 65.3679L155.321 65.8595L156.241 67.6353Z"
          fill="#FCFCFC"
          mask="url(#path-36-inside-1_13403_461)"
        />
        <path
          d="M159.602 66.291L159.602 66.2925L159.602 66.2971C159.475 69.7594 159.349 73.222 159.225 76.6878C158.984 76.783 158.741 76.8687 158.496 76.9452C158.557 74.7347 158.612 72.5222 158.667 70.3101C158.701 68.9307 158.736 67.5514 158.772 66.1728L159.688 66.2337C159.687 66.2366 159.685 66.2414 159.681 66.2475C159.673 66.262 159.66 66.2751 159.647 66.2845C159.642 66.2878 159.637 66.2903 159.634 66.2922C159.633 66.2927 159.632 66.2931 159.631 66.2935C159.625 66.2962 159.62 66.2973 159.617 66.2977C159.616 66.2968 159.614 66.2954 159.612 66.2931C159.612 66.293 159.612 66.2929 159.612 66.2928C159.611 66.2923 159.611 66.2918 159.61 66.2913C159.607 66.2868 159.604 66.2818 159.602 66.2776C159.602 66.2762 159.602 66.2755 159.602 66.2755C159.602 66.2755 159.603 66.2801 159.602 66.291Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <mask id="path-39-inside-2_13403_461" fill="white">
          <path d="M177.695 55.3462C177.51 59.2336 177.324 63.1175 177.139 67.0015C176.829 67.029 176.523 67.0462 176.214 67.0462C176.393 63.31 176.568 59.5739 176.747 55.8377L177.695 55.3462Z" />
        </mask>
        <path
          d="M177.695 55.3462C177.51 59.2336 177.324 63.1175 177.139 67.0015C176.829 67.029 176.523 67.0462 176.214 67.0462C176.393 63.31 176.568 59.5739 176.747 55.8377L177.695 55.3462Z"
          fill="#FCFCFC"
        />
        <path
          d="M177.695 55.3462L178.694 55.3939L178.777 53.6596L177.235 54.4583L177.695 55.3462ZM177.139 67.0015L177.227 67.9976L178.096 67.9204L178.137 67.0492L177.139 67.0015ZM176.214 67.0462L175.215 66.9984L175.165 68.0462H176.214V67.0462ZM176.747 55.8377L176.287 54.9498L175.775 55.2147L175.748 55.7899L176.747 55.8377ZM176.697 55.2985C176.511 59.1859 176.325 63.0698 176.14 66.9538L178.137 67.0492C178.323 63.1653 178.509 59.2813 178.694 55.3939L176.697 55.2985ZM177.05 66.0054C176.762 66.031 176.486 66.0462 176.214 66.0462V68.0462C176.56 68.0462 176.897 68.0269 177.227 67.9976L177.05 66.0054ZM177.213 67.094C177.302 65.2256 177.391 63.3573 177.479 61.4893C177.568 59.6211 177.656 57.7533 177.746 55.8855L175.748 55.7899C175.658 57.6583 175.57 59.5266 175.481 61.3946C175.393 63.2627 175.304 65.1306 175.215 66.9984L177.213 67.094ZM177.207 56.7256L178.155 56.2341L177.235 54.4583L176.287 54.9498L177.207 56.7256Z"
          fill="#FCFCFC"
          mask="url(#path-39-inside-2_13403_461)"
        />
        <path
          d="M180.19 65.7786C179.948 65.8738 179.706 65.9595 179.461 66.036C179.522 63.8257 179.577 61.6135 179.632 59.4017C179.666 58.0219 179.701 56.6423 179.736 55.2634L180.653 55.3216C180.652 55.3245 180.65 55.3295 180.646 55.3358C180.638 55.3506 180.625 55.3639 180.611 55.3736C180.604 55.3788 180.597 55.3821 180.592 55.3842L180.576 55.3836C180.576 55.3834 180.576 55.3833 180.575 55.3831C180.571 55.3785 180.568 55.3731 180.567 55.3691C180.566 55.368 180.566 55.3675 180.566 55.3675C180.566 55.3675 180.567 55.3726 180.567 55.3847L180.567 55.3885C180.44 58.8506 180.314 62.313 180.19 65.7786ZM180.577 55.3851L180.589 55.3855C180.585 55.3866 180.582 55.3872 180.58 55.3874C180.579 55.3869 180.578 55.3861 180.577 55.3851ZM180.577 55.3876C180.577 55.3876 180.577 55.3876 180.578 55.3876C180.577 55.3877 180.577 55.3876 180.577 55.3876Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <path
          d="M161.683 46.5301C159.717 48.259 155.688 47.8637 153.409 46.9048C154.798 49.3211 154.715 52.5073 153.203 54.8514C151.69 57.1921 148.816 58.5738 146.046 58.3023C147.101 60.4539 147.854 62.8255 147.71 65.2212C147.6 67.0979 146.489 69.9954 144.908 70.5247C144.905 70.5247 144.905 70.5247 144.905 70.5247L144.643 70.0023L144.537 69.7892C142.433 65.6096 141.955 61.0348 142.859 56.7899C143.801 52.3698 146.242 48.3106 149.916 45.4027C150.958 44.5813 152.096 43.8492 153.33 43.2339C156.118 41.8316 159.077 41.151 161.999 41.127C162.236 41.591 164.086 44.4163 161.683 46.5301Z"
          fill="#1D232A"
        />
        <path
          d="M149.126 46.3136C148.621 53.865 139.412 57.3124 128.219 55.2192C117.446 53.205 111.54 46.7192 111.874 41.7938C112.204 36.8718 118.883 32.132 129.745 32.5204C141.911 32.9535 149.635 38.7656 149.126 46.3136Z"
          fill="#1D232A"
        />
        <path
          d="M93.7513 58.4603C92.9194 52.215 99.1481 47.5336 107.718 46.9528C115.968 46.3925 121.351 50.4655 121.891 54.5351C122.434 58.6046 118.271 63.8222 110.217 65.7229C101.193 67.8539 94.5831 64.7021 93.7513 58.4603Z"
          fill="#1D232A"
        />
        <path
          d="M76.2401 42.1445C78.7736 37.9821 85.7689 37.0094 91.8154 40.4362C97.6351 43.7359 99.0239 48.95 97.1814 52.0056C95.3389 55.0612 89.7289 56.8004 83.3111 54.5147C76.1233 51.9575 73.7067 46.3068 76.2401 42.1445Z"
          fill="#1D232A"
        />
        <path
          d="M162.264 155.731L130.911 172.827C130.911 172.827 124.441 176.35 106.92 185.898C89.4991 195.395 66.846 195.347 54.3816 179.849C47.95 171.854 45.2929 159.522 49.4041 146.66C52.6629 136.459 61.7757 131.018 70.971 131.018C73.5457 131.018 76.1272 131.444 78.585 132.31C79.8191 132.747 81.0291 133.29 82.1841 133.95C85.2813 135.713 88.045 138.277 90.1797 141.687L108.643 132.664L137.383 118.62L162.264 155.731Z"
          fill="#008E5A"
        />
        <path d="M125.242 124.553L147.878 163.176L155.826 152.497L125.242 124.553Z" fill="#1D232A" />
        <path
          d="M157.977 108.581L90.1826 141.694L82.0013 128.609C77.756 121.821 79.7429 112.877 86.4701 108.529L109.677 93.5228C120.388 86.5969 134.403 87.6625 143.942 96.1281L157.977 108.581Z"
          fill="#42EC9A"
        />
        <path
          d="M198.736 158.027C191.912 166.798 186.031 174.439 181.782 181.073L138.848 172.264C148.153 160.931 169.754 134.926 169.754 134.926L161.099 107.058C161.099 107.058 162.945 106.126 168.163 103.614C175.876 99.8982 193.621 99.8707 202.41 113.602C211.193 127.327 209.894 143.673 198.736 158.027Z"
          fill="#008E5A"
        />
        <path
          d="M181.782 181.073C181.101 182.139 180.459 183.177 179.864 184.19C178.554 186.407 177.447 188.58 176.543 190.776L131.887 180.942C132.904 179.643 133.942 178.33 134.984 177.01C135.218 176.715 137.703 173.656 138.848 172.264L181.782 181.073Z"
          fill="#8F5049"
        />
        <path
          d="M135.194 177.547L179.064 184.569C177.903 186.585 176.908 188.574 176.08 190.586L176.08 190.586L176.076 190.597C175.58 191.887 175.126 193.252 174.746 194.647L130.624 183.344C131.754 181.913 134.169 178.85 135.194 177.547Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <path
          d="M201.73 237.449H103.641C103.641 228.591 104.459 215.956 117.14 199.716C120.646 195.227 125.032 189.642 129.755 183.637C130.46 182.75 131.168 181.85 131.887 180.939L152.233 185.421L176.543 190.773C175.997 192.196 175.502 193.708 175.103 195.255C173.546 201.235 173.436 207.759 177.657 211.907C188.55 222.624 201.73 224.405 201.73 237.449Z"
          fill="#008E5A"
        />
        <path
          d="M150.624 264.956C150.342 266.548 149.909 268.101 149.339 269.6C145.317 280.207 134.533 288.184 123.3 288.184H123.186C120.477 288.184 114.661 288.184 114.661 288.184L118.267 268.27L123.069 241.728C123.069 241.728 127.841 241.728 131.491 241.728C139.944 241.728 146.544 246.244 149.414 252.995C149.524 253.256 149.627 253.514 149.727 253.779C149.892 254.208 150.033 254.645 150.167 255.092C150.25 255.37 150.329 255.655 150.391 255.941C150.511 256.401 150.607 256.872 150.686 257.346C150.796 257.979 150.879 258.621 150.923 259.271C150.961 259.776 150.985 260.285 150.982 260.804C150.992 262.148 150.872 263.54 150.624 264.956Z"
          fill="#42EC9A"
        />
        <path
          d="M149.339 269.6C145.317 280.207 134.534 288.184 123.3 288.184H114.878L133.795 278.863L149.339 269.6Z"
          fill="#1D232A"
        />
        <path
          d="M138.947 261.199C142.704 259.209 146.441 257.178 150.163 255.095C150.033 254.648 149.888 254.211 149.723 253.782C145.976 255.878 142.212 257.927 138.424 259.931C137.524 260.405 138.046 261.673 138.947 261.199Z"
          fill="#1D232A"
        />
        <path
          d="M139.648 263.949C143.505 261.798 147.359 259.649 151.219 257.501C151.154 257.023 151.071 256.546 150.965 256.082C147.035 258.274 143.103 260.467 139.167 262.66C138.277 263.152 138.755 264.448 139.648 263.949Z"
          fill="#1D232A"
        />
        <path
          d="M136.954 275.439C140.769 273.195 144.578 270.95 148.394 268.713C148.315 268.239 148.218 267.768 148.098 267.307C144.21 269.596 140.326 271.882 136.431 274.171C135.551 274.687 136.067 275.958 136.954 275.439Z"
          fill="#1D232A"
        />
        <path
          d="M140.703 268.201C144.519 265.956 148.328 263.712 152.143 261.474C152.064 261 151.968 260.529 151.848 260.068C147.96 262.357 144.075 264.643 140.181 266.932C139.304 267.448 139.816 268.72 140.703 268.201Z"
          fill="#1D232A"
        />
        <path
          d="M118.264 268.273L149.411 252.998C146.541 246.248 139.937 241.731 131.488 241.731C127.837 241.731 123.066 241.731 123.066 241.731L118.264 268.273Z"
          fill="#1D232A"
        />
        <path
          d="M133.323 280.647C143.656 271.186 145.601 256.494 137.668 247.831C129.735 239.168 114.927 239.815 104.594 249.275C94.2613 258.736 92.316 273.428 100.249 282.091C108.183 290.754 122.99 290.107 133.323 280.647Z"
          fill="#42EC9A"
        />
        <path
          d="M117.239 276.079C113.585 276.079 111.152 273.274 110.911 270.565C110.887 270.28 111.097 270.033 111.379 270.005C111.664 269.981 111.911 270.191 111.939 270.473C112.138 272.721 114.177 275.047 117.239 275.047C119.58 275.047 121.853 273.951 123.169 272.188C124.07 270.985 124.372 269.668 124.022 268.483C123.334 266.156 121.598 265.643 119.199 264.939L118.982 264.874C116.456 264.128 114.318 263.317 113.792 260.941C113.469 259.484 113.898 257.886 114.964 256.556C116.332 254.854 118.518 253.837 120.818 253.837C123.069 253.837 125.07 255.067 126.039 257.044C126.163 257.298 126.06 257.607 125.802 257.735C125.544 257.858 125.239 257.752 125.111 257.497C124.489 256.226 123.035 254.868 120.818 254.868C118.828 254.868 116.94 255.741 115.768 257.202C114.895 258.288 114.544 259.57 114.799 260.718C115.167 262.385 116.597 263.093 119.275 263.884L119.491 263.949C121.894 264.657 124.163 265.324 125.012 268.19C125.452 269.672 125.08 271.353 123.998 272.803C122.489 274.824 119.9 276.079 117.239 276.079Z"
          fill="#1D232A"
        />
        <path
          d="M116.666 278.557C116.635 278.557 116.607 278.553 116.576 278.55C116.295 278.502 116.109 278.234 116.157 277.952L120.77 251.785C120.818 251.503 121.086 251.317 121.368 251.365C121.65 251.414 121.836 251.682 121.788 251.964L117.174 278.13C117.13 278.381 116.91 278.557 116.666 278.557Z"
          fill="#1D232A"
        />
        <path
          d="M201.746 264.956C201.688 265.283 201.626 265.616 201.547 265.939C201.409 266.572 201.251 267.194 201.066 267.806C200.884 268.414 200.681 269.012 200.461 269.6C196.439 280.207 185.655 288.184 174.422 288.184H174.308C171.599 288.184 165.783 288.184 165.783 288.184L169.389 268.27L174.191 241.728C174.191 241.728 178.963 241.728 182.613 241.728C191.066 241.728 197.666 246.244 200.536 252.995C200.646 253.256 200.749 253.514 200.849 253.779C201.014 254.208 201.155 254.645 201.289 255.092C201.372 255.37 201.451 255.655 201.513 255.941C201.633 256.401 201.729 256.872 201.808 257.346C201.918 257.979 202.001 258.621 202.045 259.271C202.083 259.776 202.107 260.285 202.104 260.804C202.114 262.148 201.997 263.54 201.746 264.956Z"
          fill="#42EC9A"
        />
        <path
          d="M200.461 269.6C196.439 280.207 185.656 288.184 174.422 288.184H166L184.917 278.863L200.461 269.6Z"
          fill="#1D232A"
        />
        <path
          d="M190.07 261.199C193.827 259.209 197.564 257.178 201.286 255.095C201.156 254.648 201.011 254.211 200.846 253.782C197.1 255.878 193.335 257.927 189.547 259.931C188.647 260.405 189.169 261.673 190.07 261.199Z"
          fill="#1D232A"
        />
        <path
          d="M201.551 265.939C201.413 266.571 201.255 267.194 201.069 267.805C196.889 270.256 191.094 273.666 188.079 275.439C187.192 275.958 186.68 274.686 187.557 274.171C190.836 272.239 197.281 268.455 201.551 265.939Z"
          fill="#1D232A"
        />
        <path
          d="M169.39 268.273L200.537 252.998C197.667 246.248 191.063 241.731 182.614 241.731C178.963 241.731 174.192 241.731 174.192 241.731L169.39 268.273Z"
          fill="#1D232A"
        />
        <path
          d="M184.445 280.649C194.778 271.189 196.723 256.497 188.79 247.834C180.857 239.171 166.049 239.817 155.716 249.278C145.383 258.738 143.438 273.43 151.371 282.093C159.305 290.756 174.112 290.11 184.445 280.649Z"
          fill="#42EC9A"
        />
        <path
          d="M156.024 259.051C155.935 259.051 155.842 259.027 155.76 258.979C155.516 258.831 155.436 258.515 155.581 258.271C159.341 251.998 166.179 247.946 173.002 247.946C179.025 247.946 183.951 251.029 186.181 256.195C186.295 256.456 186.175 256.759 185.913 256.872C185.652 256.985 185.35 256.865 185.236 256.604C183.174 251.83 178.602 248.977 173.002 248.977C166.533 248.977 160.043 252.833 156.468 258.8C156.371 258.962 156.2 259.051 156.024 259.051Z"
          fill="#1D232A"
        />
        <path
          d="M104.875 259.051C104.786 259.051 104.693 259.027 104.61 258.979C104.366 258.831 104.287 258.515 104.431 258.271C108.192 251.998 115.029 247.946 121.853 247.946C127.875 247.946 132.801 251.029 135.032 256.195C135.146 256.456 135.025 256.759 134.764 256.872C134.503 256.985 134.2 256.865 134.087 256.604C132.024 251.83 127.452 248.977 121.853 248.977C115.383 248.977 108.893 252.833 105.318 258.8C105.219 258.962 105.05 259.051 104.875 259.051Z"
          fill="#1D232A"
        />
        <path
          d="M168.365 276.079C164.711 276.079 162.278 273.274 162.037 270.565C162.013 270.28 162.223 270.033 162.505 270.005C162.79 269.981 163.037 270.191 163.065 270.473C163.264 272.721 165.303 275.047 168.365 275.047C170.706 275.047 172.979 273.951 174.295 272.188C175.196 270.985 175.498 269.668 175.148 268.483C174.46 266.156 172.724 265.643 170.325 264.939L170.108 264.874C167.578 264.128 165.444 263.317 164.918 260.941C164.595 259.484 165.024 257.886 166.09 256.556C167.458 254.854 169.644 253.837 171.944 253.837C174.195 253.837 176.196 255.067 177.165 257.044C177.289 257.302 177.186 257.607 176.928 257.735C176.674 257.858 176.365 257.755 176.237 257.497C175.615 256.226 174.161 254.868 171.944 254.868C169.954 254.868 168.066 255.741 166.894 257.202C166.025 258.288 165.67 259.57 165.925 260.718C166.293 262.385 167.723 263.093 170.4 263.884L170.617 263.949C173.02 264.657 175.289 265.324 176.138 268.19C176.578 269.672 176.206 271.353 175.124 272.803C173.611 274.824 171.023 276.079 168.365 276.079Z"
          fill="#1D232A"
        />
        <path
          d="M167.788 278.557C167.757 278.557 167.729 278.553 167.698 278.55C167.417 278.502 167.231 278.234 167.279 277.952L171.892 251.785C171.94 251.503 172.208 251.317 172.49 251.365C172.772 251.414 172.958 251.682 172.91 251.964L168.297 278.13C168.252 278.381 168.035 278.557 167.788 278.557Z"
          fill="#1D232A"
        />
        <path
          d="M200.973 242.206H104.376C103.063 242.206 101.997 241.14 101.997 239.827C101.997 238.514 103.063 237.449 104.376 237.449H200.976C202.29 237.449 203.355 238.514 203.355 239.827C203.352 241.14 202.286 242.206 200.973 242.206Z"
          fill="#42EC9A"
        />
        <path
          d="M133.148 237.448V228.124C133.148 227.443 133.898 227.031 134.472 227.391L150.539 237.452H133.148V237.448Z"
          fill="#1D232A"
        />
        <path
          d="M174.137 203.823H160.906C160.621 203.823 160.391 203.593 160.391 203.308C160.391 203.022 160.621 202.792 160.906 202.792H174.137C174.423 202.792 174.653 203.022 174.653 203.308C174.653 203.593 174.423 203.823 174.137 203.823Z"
          fill="#1D232A"
        />
        <path
          d="M176.038 210.14H160.906C160.621 210.14 160.391 209.91 160.391 209.625C160.391 209.34 160.621 209.109 160.906 209.109H176.038C176.323 209.109 176.554 209.34 176.554 209.625C176.554 209.91 176.323 210.14 176.038 210.14Z"
          fill="#1D232A"
        />
        <path
          d="M197.852 233.059H155.021C154.921 233.059 154.825 233.032 154.742 232.977L138.136 222.394H107.391C107.105 222.394 106.875 222.164 106.875 221.878C106.875 221.593 107.105 221.363 107.391 221.363H138.287C138.387 221.363 138.483 221.39 138.565 221.445L155.172 232.028H197.852C198.137 232.028 198.368 232.259 198.368 232.544C198.368 232.829 198.137 233.059 197.852 233.059Z"
          fill="#1D232A"
        />
        <path
          d="M155.421 158.598C155.28 158.594 155.139 158.587 155.001 158.584C155.324 158.656 155.579 158.907 155.572 159.268C155.565 159.615 155.242 159.973 154.871 159.938C153.069 159.753 151.268 159.57 149.467 159.388C150.127 158.587 150.787 157.797 151.433 157.017C152.77 157.151 154.107 157.219 155.452 157.226C156.338 157.226 156.304 158.598 155.421 158.598Z"
          fill="#1D232A"
        />
        <path
          d="M161 162.784C156.689 162.217 152.375 161.653 148.064 161.086C148.408 160.674 148.749 160.261 149.089 159.845C153.069 160.364 157.054 160.887 161.031 161.413C161.894 161.529 161.876 162.897 161 162.784Z"
          fill="#1D232A"
        />
        <path
          d="M165.64 166.458C159.549 164.802 153.351 163.609 147.084 162.901C146.926 162.884 146.799 162.822 146.699 162.736C147.019 162.354 147.339 161.973 147.651 161.591C153.856 162.317 159.999 163.506 166.028 165.142C166.881 165.372 166.489 166.685 165.64 166.458Z"
          fill="#1D232A"
        />
        <path
          d="M125.242 124.553C150.363 160.577 179.056 188.514 195.752 178.33C212.448 168.146 201.414 147.197 159.04 104.996L125.242 124.553Z"
          fill="#8F5049"
        />
        <path
          d="M168.162 103.611L125.238 124.553C116.129 110.203 113.915 98.0493 121.938 92.6014C140.236 80.1728 168.162 103.611 168.162 103.611Z"
          fill="#1D232A"
        />
        <path
          d="M76.0374 149.878C75.8552 149.878 75.6764 149.781 75.5836 149.606C75.4461 149.355 75.5424 149.042 75.7899 148.908L87.3914 142.618L61.7305 146.355C61.452 146.396 61.1874 146.2 61.1461 145.918C61.1049 145.636 61.3008 145.375 61.5827 145.334L90.1105 141.182C90.358 141.144 90.5952 141.292 90.6742 141.529C90.7533 141.766 90.6502 142.024 90.4302 142.144L76.2849 149.816C76.2058 149.857 76.1199 149.878 76.0374 149.878Z"
          fill="#1D232A"
        />
        <path
          d="M159.923 166.414C159.802 166.414 159.682 166.372 159.586 166.287C156.148 163.31 152.584 159.893 148.995 156.13C148.799 155.924 148.806 155.597 149.012 155.401C149.218 155.205 149.545 155.212 149.741 155.418C153.309 159.158 156.846 162.55 160.26 165.506C160.476 165.692 160.5 166.018 160.311 166.235C160.208 166.355 160.067 166.414 159.923 166.414Z"
          fill="#1D232A"
        />
        <path
          d="M308.749 118.373L286.591 186.28L280.655 204.466C266.819 198.816 260.336 193.175 250.154 189.054C248.174 188.25 246.06 187.511 243.726 186.834L254.839 141.653C268.548 144.574 281.139 133.981 287.956 126.925C294.484 120.171 301.173 116.792 308.749 118.373Z"
          fill="#008E5A"
        />
        <path
          d="M300.672 178.901C300.551 178.901 300.431 178.86 300.331 178.774C300.118 178.588 300.094 178.262 300.283 178.045C304.322 173.412 310.716 171.662 316.567 173.587C318.248 174.14 319.805 174.965 321.19 176.045C321.414 176.22 321.455 176.543 321.279 176.77C321.104 176.993 320.781 177.034 320.558 176.859C319.265 175.856 317.814 175.082 316.244 174.567C310.788 172.772 304.828 174.405 301.06 178.722C300.96 178.842 300.816 178.901 300.672 178.901Z"
          fill="#1D232A"
        />
        <path
          d="M268.96 151.033C268.727 151.033 268.517 150.875 268.459 150.637L266.183 141.233C266.118 140.955 266.286 140.677 266.565 140.611C266.84 140.543 267.121 140.714 267.187 140.993L269.462 150.397C269.528 150.672 269.359 150.954 269.081 151.019C269.04 151.026 268.998 151.033 268.96 151.033Z"
          fill="#1D232A"
        />
        <path
          d="M276.238 147.898C276.025 147.898 275.825 147.768 275.75 147.554L272.622 138.669C272.525 138.401 272.67 138.106 272.938 138.013C273.209 137.92 273.502 138.061 273.594 138.329L276.723 147.214C276.819 147.482 276.674 147.778 276.406 147.871C276.355 147.888 276.296 147.898 276.238 147.898Z"
          fill="#1D232A"
        />
        <path
          d="M267.015 196.805C266.953 196.805 266.891 196.794 266.829 196.77C266.565 196.667 266.431 196.372 266.534 196.104L276.839 169.222C276.87 169.143 276.919 169.074 276.98 169.019L290.851 156.91L303.006 120.126C303.095 119.854 303.387 119.71 303.655 119.799C303.927 119.889 304.071 120.181 303.982 120.452L291.782 157.371C291.755 157.457 291.7 157.536 291.631 157.597L277.75 169.713L267.489 196.478C267.417 196.678 267.221 196.805 267.015 196.805Z"
          fill="#1D232A"
        />
        <path
          d="M294.766 161.227L284.209 170.201C283.68 170.652 283.838 171.507 284.494 171.734L290.637 173.872L294.766 161.227Z"
          fill="#1D232A"
        />
        <path
          d="M286.591 186.28L280.654 204.466C266.818 198.815 260.335 193.175 250.153 189.054L286.591 186.28Z"
          fill="#1D232A"
        />
        <path
          d="M253.062 190.044C252.811 190.044 252.591 189.862 252.553 189.607C252.509 189.325 252.704 189.064 252.986 189.019L287.626 183.726C287.911 183.685 288.169 183.878 288.214 184.159C288.259 184.441 288.063 184.702 287.781 184.747L253.141 190.04C253.114 190.04 253.089 190.044 253.062 190.044Z"
          fill="#1D232A"
        />
        <path
          d="M345.861 150.978C345.861 150.978 345.658 151.61 345.352 152.524C344.812 154.167 343.973 156.742 343.489 158.22L343.458 158.316C342.911 159.983 342.169 161.54 341.272 162.96C340.863 163.602 340.419 164.224 339.952 164.809C339.694 165.128 339.429 165.441 339.158 165.744C338.934 165.988 338.707 166.225 338.474 166.462C338.071 166.871 337.652 167.259 337.219 167.624C337.215 167.631 337.209 167.634 337.205 167.637C336.813 167.974 336.411 168.294 335.995 168.59C335.944 168.627 335.885 168.669 335.833 168.703C331.708 171.666 326.542 173.072 321.307 172.456C319.952 172.291 318.584 171.996 317.226 171.549C316.02 171.154 314.875 170.655 313.799 170.074C313.383 169.854 312.981 169.614 312.586 169.366C312.074 169.047 311.582 168.7 311.108 168.335C310.75 168.067 310.403 167.782 310.066 167.479C309.856 167.307 309.65 167.118 309.451 166.926C309.131 166.623 308.818 166.318 308.519 165.988C308.334 165.788 308.155 165.596 307.979 165.386C303.363 160.052 301.575 152.5 303.927 145.32C304.941 142.223 306.271 138.164 306.271 138.164L328.886 145.485L345.022 150.706L345.861 150.978Z"
          fill="#1D232A"
        />
        <path
          d="M345.799 151.163C345.187 153.04 344.328 154.769 343.276 156.326C341.578 158.835 339.371 160.907 336.844 162.433C331.836 165.465 325.58 166.372 319.578 164.396C308.664 160.808 302.71 149.066 306.278 138.167C307.21 135.318 308.702 132.809 310.592 130.719C312.29 128.846 314.301 127.313 316.519 126.175C316.941 125.959 317.374 125.756 317.811 125.567C318.474 125.281 319.159 125.03 319.849 124.817C320.155 124.721 320.461 124.632 320.774 124.549C321.805 124.281 322.857 124.089 323.926 123.982C326.697 123.704 329.574 123.982 332.396 124.893C332.427 124.903 332.462 124.914 332.493 124.924C340.399 127.522 345.703 134.4 346.669 142.137C347.044 145.086 346.782 148.159 345.799 151.163Z"
          fill="#42EC9A"
        />
        <path
          d="M337.712 152.561L337.711 152.561C336.564 154.262 335.067 155.663 333.357 156.695L333.357 156.695C333.219 156.779 333.081 156.86 332.942 156.938L315.259 135.578C315.36 135.457 315.465 135.338 315.571 135.221L315.572 135.22C316.939 133.707 318.612 132.526 320.462 131.732L320.462 131.732C320.911 131.538 321.371 131.368 321.839 131.223L338.466 151.301C338.236 151.737 337.984 152.158 337.712 152.561Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <path
          d="M317.31 126.343C317.542 126.232 317.775 126.126 318.009 126.027C318.138 125.972 318.269 125.919 318.4 125.866L321.808 129.979C321.413 130.092 321.023 130.222 320.64 130.366L317.31 126.343Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <path
          d="M303.012 214.98C303.29 215.073 303.575 215.162 303.857 215.234C304.4 215.389 304.944 215.523 305.487 215.626C306.023 215.733 306.559 215.819 307.092 215.881C316.714 217.022 326.112 211.289 329.236 201.744L329.267 201.647C330.02 199.345 331.639 194.405 331.639 194.405L314.672 188.913L292.056 181.592C292.056 181.592 290.73 185.644 289.715 188.744C287.364 195.925 289.148 203.473 293.765 208.81C293.944 209.017 294.122 209.213 294.308 209.412C294.607 209.735 294.916 210.044 295.24 210.35C295.439 210.539 295.645 210.728 295.858 210.904C296.195 211.203 296.542 211.488 296.903 211.76C297.381 212.124 297.873 212.471 298.381 212.791C298.777 213.042 299.179 213.279 299.595 213.499C300.66 214.086 301.809 214.581 303.012 214.98Z"
          fill="#1D232A"
        />
        <path
          d="M328.614 200.389C335.387 191.137 333.361 178.135 324.089 171.348C314.816 164.561 301.808 166.559 295.035 175.81C288.261 185.062 290.287 198.064 299.56 204.851C308.832 211.638 321.84 209.641 328.614 200.389Z"
          fill="#42EC9A"
        />
        <path
          d="M329.193 152.06C329.086 152.06 328.983 152.057 328.88 152.05C328.595 152.033 328.378 151.789 328.395 151.504C328.412 151.218 328.663 151.005 328.942 151.019C331.042 151.15 333.734 149.716 334.603 147.104C335.229 145.224 334.954 143.306 333.868 141.976C333.063 140.989 331.908 140.508 330.705 140.653C328.385 140.934 327.381 142.419 326.109 144.299L325.989 144.478C324.614 146.51 323.29 148.201 321.035 148.331C319.544 148.421 318.165 147.778 317.24 146.571C316.158 145.159 315.893 143.175 316.525 141.264C317.158 139.36 318.815 137.834 320.846 137.281C321.121 137.205 321.403 137.367 321.479 137.642C321.555 137.917 321.393 138.199 321.118 138.274C319.784 138.638 318.134 139.701 317.505 141.588C316.969 143.196 317.172 144.784 318.059 145.942C318.77 146.87 319.836 147.362 320.974 147.3C322.761 147.197 323.871 145.76 325.133 143.897L325.253 143.722C326.618 141.704 327.794 139.965 330.581 139.628C332.142 139.439 333.631 140.058 334.665 141.323C335.975 142.928 336.319 145.21 335.58 147.427C334.596 150.39 331.63 152.06 329.193 152.06Z"
          fill="#1D232A"
        />
        <path
          d="M337.16 148.603C337.105 148.603 337.05 148.596 336.999 148.575L314.751 141.206C314.48 141.117 314.335 140.825 314.425 140.553C314.514 140.282 314.806 140.137 315.078 140.227L337.325 147.596C337.597 147.685 337.741 147.977 337.652 148.249C337.58 148.465 337.377 148.603 337.16 148.603Z"
          fill="#1D232A"
        />
        <path
          d="M312.867 120.102L284.907 205.762C284.525 206.927 283.27 207.563 282.105 207.185C280.936 206.807 280.3 205.552 280.678 204.39L308.639 118.73C309.021 117.565 310.275 116.929 311.441 117.307C312.609 117.685 313.245 118.937 312.867 120.102Z"
          fill="#42EC9A"
        />
        <path
          d="M93.675 248.259H62.5381C62.2528 248.259 62.0225 248.028 62.0225 247.743C62.0225 247.458 62.2528 247.228 62.5381 247.228H93.675C93.9603 247.228 94.1906 247.458 94.1906 247.743C94.1906 248.028 93.9603 248.259 93.675 248.259Z"
          fill="#1D232A"
        />
        <path
          d="M45.7982 200.046H24.6025C24.3172 200.046 24.0869 199.816 24.0869 199.531C24.0869 199.245 24.3172 199.015 24.6025 199.015H45.7982C46.0835 199.015 46.3138 199.245 46.3138 199.531C46.3138 199.816 46.0835 200.046 45.7982 200.046Z"
          fill="#1D232A"
        />
        <path
          d="M93.6752 214.953H38.6992C38.4139 214.953 38.1836 214.723 38.1836 214.437C38.1836 214.152 38.4139 213.922 38.6992 213.922H93.6786C93.9639 213.922 94.1942 214.152 94.1942 214.437C94.1942 214.723 93.9605 214.953 93.6752 214.953Z"
          fill="#1D232A"
        />
        <path
          d="M89.9148 253.92H30.9961C30.7108 253.92 30.4805 253.69 30.4805 253.404C30.4805 253.119 30.7108 252.889 30.9961 252.889H89.9148C90.2002 252.889 90.4305 253.119 90.4305 253.404C90.4305 253.69 90.2002 253.92 89.9148 253.92Z"
          fill="#1D232A"
        />
        <path
          d="M170.005 74.7935C169.269 74.7935 168.547 74.6011 167.915 74.2333C167.667 74.0924 167.585 73.7761 167.726 73.5287C167.867 73.2812 168.183 73.1987 168.431 73.3396C168.908 73.6146 169.451 73.759 170.005 73.759C171.744 73.759 173.161 72.3429 173.161 70.6037C173.161 69.655 172.741 68.7683 172.009 68.1668C171.789 67.9846 171.758 67.6615 171.937 67.4415C172.116 67.2215 172.442 67.1906 172.662 67.3693C173.635 68.1668 174.192 69.3491 174.192 70.6037C174.192 72.9134 172.311 74.7935 170.005 74.7935Z"
          fill="#1D232A"
        />
        <path
          d="M169.761 42.6258C166.186 46.8019 163.838 45.3033 161.68 41.1307C161.721 41.1307 161.762 41.1272 161.803 41.1272C161.869 41.1238 161.931 41.1238 161.996 41.1238C164.454 41.0963 166.881 41.5328 169.16 42.3886C169.363 42.4643 169.565 42.5399 169.761 42.6258Z"
          fill="#1D232A"
        />
        <path
          d="M296.712 125.111C296.876 125.432 296.866 125.857 296.627 126.32C296.389 126.782 295.939 127.24 295.319 127.558C294.699 127.876 294.064 127.974 293.55 127.898C293.035 127.822 292.683 127.583 292.519 127.262C292.354 126.941 292.365 126.516 292.603 126.053C292.842 125.591 293.292 125.133 293.911 124.815C294.531 124.497 295.166 124.399 295.68 124.475C296.196 124.551 296.547 124.79 296.712 125.111Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <mask id="path-109-inside-3_13403_461" fill="white">
          <path d="M324.661 129.942C324.579 130.011 324.479 130.045 324.376 130.045C324.249 130.045 324.121 129.99 324.032 129.884L319.893 124.886C319.876 124.866 319.862 124.845 319.849 124.821C320.155 124.725 320.461 124.635 320.773 124.553L324.716 129.317C324.874 129.506 324.85 129.784 324.661 129.942Z" />
        </mask>
        <path
          d="M324.661 129.942C324.579 130.011 324.479 130.045 324.376 130.045C324.249 130.045 324.121 129.99 324.032 129.884L319.893 124.886C319.876 124.866 319.862 124.845 319.849 124.821C320.155 124.725 320.461 124.635 320.773 124.553L324.716 129.317C324.874 129.506 324.85 129.784 324.661 129.942Z"
          fill="#FCFCFC"
        />
        <path
          d="M324.661 129.942L325.301 130.71L325.303 130.709L324.661 129.942ZM324.032 129.884L323.262 130.522L323.266 130.526L324.032 129.884ZM319.893 124.886L320.664 124.248L320.662 124.246L319.893 124.886ZM319.849 124.821L319.549 123.867L318.365 124.239L318.98 125.317L319.849 124.821ZM320.773 124.553L321.544 123.915L321.136 123.423L320.518 123.586L320.773 124.553ZM324.716 129.317L323.946 129.954L323.949 129.958L324.716 129.317ZM324.021 129.174C324.123 129.089 324.251 129.045 324.376 129.045V131.045C324.707 131.045 325.034 130.933 325.301 130.71L324.021 129.174ZM324.376 129.045C324.536 129.045 324.693 129.115 324.798 129.241L323.266 130.526C323.55 130.866 323.961 131.045 324.376 131.045V129.045ZM324.802 129.246L320.664 124.248L319.123 125.524L323.262 130.522L324.802 129.246ZM320.662 124.246C320.674 124.261 320.686 124.277 320.697 124.292C320.707 124.307 320.713 124.319 320.717 124.325L318.98 125.317C319.016 125.38 319.063 125.451 319.125 125.526L320.662 124.246ZM320.149 125.775C320.442 125.683 320.732 125.598 321.028 125.52L320.518 123.586C320.189 123.673 319.868 123.767 319.549 123.867L320.149 125.775ZM320.003 125.19L323.946 129.954L325.487 128.679L321.544 123.915L320.003 125.19ZM323.949 129.958C323.755 129.726 323.783 129.373 324.02 129.175L325.303 130.709C325.918 130.195 325.994 129.285 325.483 128.675L323.949 129.958Z"
          fill="#FCFCFC"
          mask="url(#path-109-inside-3_13403_461)"
        />
        <path
          d="M319.064 200.15L319.064 200.151C318.926 200.234 318.788 200.315 318.649 200.394L300.966 179.034C301.067 178.913 301.172 178.793 301.278 178.676L301.279 178.676C301.898 177.991 302.579 177.374 303.31 176.83L321.262 198.506C320.583 199.128 319.847 199.678 319.064 200.15Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <path
          d="M303.712 169.48C303.841 169.425 303.972 169.371 304.103 169.319L307.512 173.431C307.116 173.545 306.727 173.674 306.343 173.818L303.013 169.795C303.245 169.685 303.478 169.579 303.712 169.48Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <mask id="path-113-inside-4_13403_461" fill="white">
          <path d="M310.368 173.391C310.286 173.46 310.186 173.494 310.083 173.494C309.956 173.494 309.828 173.439 309.739 173.332L305.6 168.335C305.583 168.314 305.569 168.294 305.556 168.27C305.862 168.173 306.168 168.084 306.48 168.001L310.423 172.765C310.581 172.958 310.554 173.236 310.368 173.391Z" />
        </mask>
        <path
          d="M310.368 173.391C310.286 173.46 310.186 173.494 310.083 173.494C309.956 173.494 309.828 173.439 309.739 173.332L305.6 168.335C305.583 168.314 305.569 168.294 305.556 168.27C305.862 168.173 306.168 168.084 306.48 168.001L310.423 172.765C310.581 172.958 310.554 173.236 310.368 173.391Z"
          fill="#FCFCFC"
        />
        <path
          d="M310.368 173.391L309.728 172.623L310.368 173.391ZM309.739 173.332L308.969 173.97L308.973 173.975L309.739 173.332ZM305.6 168.335L306.371 167.697L306.369 167.695L305.6 168.335ZM305.556 168.27L305.256 167.316L304.072 167.688L304.687 168.766L305.556 168.27ZM306.48 168.001L307.251 167.364L306.843 166.872L306.225 167.035L306.48 168.001ZM310.423 172.765L311.196 172.131L311.194 172.128L310.423 172.765ZM309.728 172.623C309.83 172.538 309.958 172.494 310.083 172.494V174.494C310.414 174.494 310.741 174.382 311.008 174.159L309.728 172.623ZM310.083 172.494C310.243 172.494 310.399 172.564 310.505 172.69L308.973 173.975C309.257 174.314 309.668 174.494 310.083 174.494V172.494ZM310.509 172.695L306.371 167.697L304.83 168.973L308.969 173.97L310.509 172.695ZM306.369 167.695C306.381 167.71 306.393 167.726 306.404 167.741C306.414 167.756 306.421 167.768 306.424 167.773L304.687 168.766C304.723 168.829 304.77 168.9 304.832 168.975L306.369 167.695ZM305.856 169.223C306.149 169.131 306.439 169.046 306.735 168.968L306.225 167.035C305.896 167.121 305.575 167.215 305.256 167.316L305.856 169.223ZM305.71 168.639L309.653 173.403L311.194 172.128L307.251 167.364L305.71 168.639ZM309.65 173.4C309.464 173.173 309.487 172.824 309.728 172.623L311.008 174.159C311.621 173.649 311.699 172.743 311.196 172.131L309.65 173.4Z"
          fill="#FCFCFC"
          mask="url(#path-113-inside-4_13403_461)"
        />
        <path
          d="M314.899 135.449C314.779 135.449 314.659 135.407 314.562 135.322C314.349 135.136 314.325 134.809 314.511 134.593C316.071 132.802 318.069 131.383 320.286 130.482C323.118 129.334 326.191 129.062 329.179 129.698C329.701 129.808 330.244 129.953 330.791 130.131C332.482 130.688 334.036 131.517 335.418 132.592C335.641 132.768 335.682 133.091 335.507 133.314C335.332 133.538 335.009 133.579 334.782 133.404C333.496 132.4 332.045 131.63 330.471 131.108C329.959 130.943 329.454 130.805 328.965 130.702C326.178 130.111 323.311 130.365 320.674 131.434C318.605 132.273 316.742 133.596 315.288 135.267C315.188 135.387 315.044 135.449 314.899 135.449Z"
          fill="#1D232A"
        />
        <path
          d="M322.792 178.901C322.648 178.901 322.507 178.842 322.407 178.725C320.75 176.852 318.609 175.446 316.216 174.659C311.971 173.264 307.262 173.968 303.614 176.55C303.384 176.715 303.061 176.66 302.896 176.426C302.731 176.192 302.786 175.872 303.02 175.707C306.932 172.941 311.985 172.184 316.539 173.68C319.104 174.522 321.4 176.031 323.177 178.041C323.366 178.254 323.346 178.581 323.132 178.77C323.036 178.856 322.912 178.901 322.792 178.901Z"
          fill="#1D232A"
        />
        <path
          d="M314.94 195.846C314.834 195.846 314.731 195.842 314.627 195.835C314.342 195.818 314.126 195.574 314.143 195.289C314.16 195.004 314.404 194.79 314.689 194.804C316.786 194.928 319.481 193.502 320.351 190.889C320.977 189.009 320.702 187.091 319.615 185.761C318.811 184.775 317.652 184.293 316.453 184.438C314.132 184.72 313.129 186.204 311.853 188.088L311.733 188.263C310.358 190.295 309.035 191.989 306.78 192.12C305.291 192.209 303.909 191.566 302.985 190.36C301.902 188.947 301.637 186.964 302.27 185.053C302.902 183.149 304.559 181.623 306.591 181.069C306.862 180.994 307.147 181.155 307.223 181.43C307.299 181.705 307.137 181.987 306.862 182.063C305.528 182.427 303.878 183.489 303.249 185.376C302.713 186.985 302.916 188.573 303.803 189.731C304.514 190.659 305.58 191.151 306.718 191.089C308.505 190.986 309.612 189.549 310.877 187.686L310.997 187.511C312.362 185.493 313.538 183.754 316.326 183.417C317.89 183.228 319.378 183.847 320.409 185.111C321.719 186.717 322.063 188.999 321.324 191.216C320.347 194.172 317.377 195.846 314.94 195.846Z"
          fill="#1D232A"
        />
        <path
          d="M322.909 192.384C322.854 192.384 322.799 192.377 322.747 192.357L300.499 184.987C300.228 184.898 300.084 184.606 300.173 184.334C300.262 184.063 300.554 183.919 300.826 184.008L323.074 191.377C323.345 191.466 323.489 191.759 323.4 192.03C323.328 192.247 323.125 192.384 322.909 192.384Z"
          fill="#1D232A"
        />
        <path
          d="M52.3045 237.308L41.1086 236.246L42.5661 247.396L37.8877 237.17L28.9605 244.01L35.478 234.847L25.0898 230.533L36.2858 231.595L34.8283 220.445L39.5067 230.671L48.4339 223.831L41.9164 232.998L52.3045 237.308Z"
          fill="#E8E9EA"
        />
        <path
          d="M6.56906 207.601L4.31406 203.868L0 203.222L3.73312 200.967L4.37937 196.653L6.63781 200.386L10.9519 201.032L7.21875 203.287L6.56906 207.601Z"
          fill="#42EC9A"
        />
        <path
          d="M175.271 22.5357C175.072 22.5357 174.883 22.4223 174.8 22.2263C174.687 21.9651 174.804 21.6627 175.065 21.5458L186.814 16.366C187.075 16.2526 187.378 16.3695 187.495 16.6307C187.608 16.8919 187.491 17.1944 187.23 17.3112L175.481 22.491C175.412 22.5219 175.34 22.5357 175.271 22.5357Z"
          fill="#1D232A"
        />
        <path
          d="M180.493 17.7062C180.28 17.7062 180.081 17.5722 180.005 17.3591C179.912 17.091 180.053 16.7954 180.321 16.7026L190.72 13.073C190.988 12.9802 191.283 13.1211 191.376 13.3892C191.469 13.6573 191.328 13.9529 191.06 14.0457L180.662 17.6753C180.607 17.6993 180.548 17.7062 180.493 17.7062Z"
          fill="#1D232A"
        />
        <path
          d="M188.226 226.329C188.65 226.692 188.913 227.13 189.011 227.526C189.11 227.923 189.037 228.234 188.868 228.432C188.699 228.63 188.403 228.75 187.995 228.714C187.589 228.679 187.115 228.487 186.691 228.125C186.267 227.762 186.004 227.324 185.906 226.928C185.807 226.531 185.879 226.22 186.049 226.022C186.218 225.824 186.514 225.704 186.921 225.74C187.328 225.775 187.801 225.967 188.226 226.329Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <path
          d="M116.061 208.232L116.06 208.233C115.825 208.654 115.977 209.187 116.398 209.421C116.536 209.496 116.68 209.531 116.824 209.531C117.131 209.531 117.43 209.367 117.594 209.078C118.802 206.906 120.241 204.657 123.333 200.743M116.061 208.232L123.726 201.053M116.061 208.232C117.304 205.998 118.78 203.683 121.959 199.658M116.061 208.232L121.567 199.348M123.333 200.743C123.334 200.743 123.334 200.743 123.334 200.743L123.726 201.053M123.333 200.743C123.333 200.744 123.333 200.744 123.333 200.744L123.726 201.053M123.333 200.743C123.633 200.362 123.568 199.814 123.189 199.514M123.726 201.053C124.197 200.455 124.094 199.592 123.499 199.121C122.901 198.65 122.038 198.754 121.567 199.348M123.189 199.514C123.19 199.514 123.19 199.514 123.19 199.514L123.499 199.121L123.189 199.513C123.189 199.514 123.189 199.514 123.189 199.514ZM123.189 199.514C122.808 199.214 122.259 199.28 121.959 199.658M121.959 199.658C121.959 199.658 121.96 199.658 121.96 199.658L121.567 199.348M121.959 199.658C121.959 199.659 121.959 199.659 121.959 199.659L121.567 199.348"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <path
          d="M269.428 34.624C264.595 29.2931 257.72 28.75 252.766 29.2346C250.267 29.4787 248.253 29.9839 247.242 30.2692L248.466 27.9216L246.142 25.7597L247.349 23.4362L245.025 21.2708L246.232 18.9473L243.908 16.7853L245.114 14.4618L242.767 12.2861C246.513 12.7742 250.147 13.7332 253.175 15.7061C254.241 16.4004 255.228 17.2184 256.121 18.1842L263.086 26.7427L269.428 34.624Z"
          fill="#BBBDBF"
        />
        <path
          d="M269.428 34.6239C264.595 29.2929 257.72 28.7498 252.767 29.2345L256.122 18.1841L263.086 26.7425L269.428 34.6239Z"
          fill="#1D232A"
        />
        <path
          d="M269.476 34.5964L256.121 18.1841C257.844 20.0401 267.101 33.7302 241.392 55.6179L257.345 65.2831C257.345 65.2831 278.362 46.5232 269.476 34.5964Z"
          fill="#E8E9EA"
        />
        <path
          d="M214.077 27.3267L214.101 27.3198C214.094 27.3302 214.087 27.3406 214.084 27.351L214.077 27.3267Z"
          fill="#E8E9EA"
        />
        <path
          d="M217.137 22.3707L217.161 22.3638C217.157 22.3741 217.151 22.3845 217.144 22.3949L217.137 22.3707Z"
          fill="#E8E9EA"
        />
        <path
          d="M220.199 17.4176L220.223 17.4106C220.216 17.421 220.21 17.4314 220.206 17.4418L220.199 17.4176Z"
          fill="#E8E9EA"
        />
        <path d="M170.951 3.86222H170.939L170.951 3.85107V3.86222Z" fill="#E8E9EA" />
        <path
          d="M223.262 12.4442C223.262 12.4478 223.258 12.4478 223.258 12.4513L223.255 12.4336C223.255 12.4371 223.258 12.4407 223.262 12.4442Z"
          fill="#E8E9EA"
        />
        <path
          d="M223.255 12.4342C223.252 12.4305 223.248 12.4305 223.248 12.4268H223.252L223.255 12.4342Z"
          fill="#E8E9EA"
        />
        <path
          d="M125.173 17.9574L122.994 13.8466L120.364 15.1183L118.126 10.7669L115.496 12.0593L113.259 7.69068L110.636 8.99335L108.394 4.61101L105.748 5.92056C105.765 4.3326 105.675 2.95431 105.49 1.74101C105.033 -1.29398 103.994 -3.31845 102.602 -5.10919C102.334 -5.44946 102.059 -5.78287 101.77 -6.10939C101.722 -6.16782 101.671 -6.22282 101.619 -6.28125L103.097 -5.76912L119.625 -0.0359877C120.178 0.180551 121.044 0.706432 121.942 1.51072C122.904 2.37688 123.904 3.56956 124.589 5.06127C125.042 6.04773 125.369 7.18542 125.572 8.42278C126.056 11.3237 125.881 14.754 125.173 17.9574Z"
          fill="#BBBDBF"
        />
        <path
          d="M119.625 -0.0360829C117.425 -0.840371 115.08 -1.37313 112.774 -0.891928C109.054 -0.115137 105.758 2.05025 102.973 4.63154C97.6728 9.53632 93.9293 16.1012 92.4065 23.1611L90.87 18.9334L88.0031 20.212L86.4803 16.1115L83.61 17.3902L82.0872 13.2897L79.2203 14.5683L77.694 10.4678L74.8271 11.7464C76.7487 6.44978 79.9387 1.6103 84.074 -2.2221C86.8584 -4.79994 90.155 -6.96877 93.8743 -7.74556C95.655 -8.11677 98.6215 -8.13396 100.285 -7.5084L119.625 -0.0360829Z"
          fill="#E8E9EA"
        />
        <path
          d="M65.3707 66.5341C55.072 80.1245 39.0326 84.7475 17.8954 81.3997L20.2466 78.8116L17.0016 75.7972L19.3288 73.2365L16.0838 70.2222L18.4145 67.6615L15.166 64.6472L17.4966 62.0865L14.2207 59.0481C29.0982 62.6846 43.0923 60.1205 52.1432 48.1455L52.1191 52.8166L55.4363 52.765L55.4157 57.4051L58.7329 57.3536L58.7123 61.9937L62.0329 61.9421L62.0088 66.5823L65.3707 66.5341Z"
          fill="#E8E9EA"
        />
      </g>
      <defs>
        <clipPath id="clip0_13403_461">
          <rect width="346.833" height="296.184" fill="white" transform="translate(0 -8)" />
        </clipPath>
      </defs>
    </svg>
  )
}
