import { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, Heading, Icon, Text, theme } from '@dlpco/ginga-stone'
import { type SetOptional } from 'type-fest'

import { Flex } from '~/domains/platform/design-system'
import { type __PubSubLog, __LOG_KEY } from '~/domains/platform/lib/pub-sub/logging'

import Table from '../../ui/components/table'

import { LogWrapper } from './styles'

const EmptyEvents = ({ section }: { section: 'publishing' | 'subscribers' }) => {
  return (
    <Flex
      flex={1}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      backgroundColor={theme.dark.color.surface}
      sx={{ borderRadius: '12px' }}
      gap=".5rem"
      padding="1rem"
    >
      <Icon use="alert-square-solid" size="large" />
      <Text>no {section} yet</Text>
    </Flex>
  )
}

export const PubSubLog = () => {
  const [logging, setLogging] = useState<__PubSubLog[]>([])

  function generateOpenEditor(
    log: Omit<__PubSubLog, 'type'>
  ): NonNullable<Parameters<typeof Table>[0]['actions']>[number] {
    const [filePath] = log['stack trace']?.match(/src\/[\w/-]+\.\w+/) || []

    if (!filePath) {
      return {
        label: '',
        onClick: () => {},
        buttonProps: { icon: 'error-round-solid', disabled: true, color: 'negative' }
      }
    }

    return {
      label: '',
      onClick: async () => {
        await fetch(`${location.origin}/__nextjs_launch-editor?file=${filePath}`)
      },
      buttonProps: { icon: 'file-2-solid' }
    }
  }

  function cleanType(log: SetOptional<__PubSubLog, 'type'>) {
    delete log.type

    return log
  }

  const subscribersList = logging.filter(log => log.type === 'SUB').map(cleanType)
  const publishingList = logging.filter(log => log.type === 'PUB').map(cleanType)

  useEffect(() => {
    function updateSubscribersList() {
      const previousLog = sessionStorage.getItem(__LOG_KEY)
      const parsedPreviousLog = JSON.parse(previousLog || '[]') as __PubSubLog[]

      setLogging(parsedPreviousLog)
    }

    /**
     * fetch the log for the very first time to then start listening for changes on storage
     */
    updateSubscribersList()

    window.addEventListener('storage', updateSubscribersList)

    return () => {
      window.addEventListener('storage', updateSubscribersList)
    }
  }, [])

  return (
    <LogWrapper>
      <Card>
        <CardHeader>
          <Heading as="h2">Subscribers</Heading>
        </CardHeader>
        <CardBody removeSpacing="top">
          {subscribersList.length > 0 ? (
            <Table data={subscribersList} actions={subscribersList.map(generateOpenEditor)} />
          ) : (
            <EmptyEvents section="subscribers" />
          )}
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <Heading as="h2">Publishing</Heading>
        </CardHeader>
        <CardBody removeSpacing="top">
          {publishingList.length > 0 ? (
            <Table data={publishingList} actions={publishingList.map(generateOpenEditor)} />
          ) : (
            <EmptyEvents section="publishing" />
          )}
        </CardBody>
      </Card>
    </LogWrapper>
  )
}
