/**
 * @param original function that will be wrapping the value that can only be accessed on client side
 * @param fallback replace value that will be returned if running on server side
 *
 * @example
 * const isomorphicCleiton = clientSideOnly(
 *  () => window.Cleiton,
 *  { pimba: true }
 * )
 */
function clientSideOnly<T extends () => any>(original: T): ReturnType<T> | undefined
function clientSideOnly<T extends () => any, U extends ReturnType<T>>(
  original: T,
  fallback: U
): NonNullable<ReturnType<T>> | U
function clientSideOnly<T extends () => any, U extends ReturnType<T>>(original: T, fallback?: U) {
  const canUseWindowOrDOM = typeof window === 'object' && window !== null && 'document' in window

  if (canUseWindowOrDOM) {
    return original()
  }

  return fallback
}

export { clientSideOnly }
