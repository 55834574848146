export const HouseIllustration = () => (
  <svg width="91" height="102" viewBox="0 0 91 102" fill="none">
    <g clipPath="url(#clip0)">
      <mask id="mask0" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="-1" y="0" width="92" height="103">
        <path
          d="M24.1275 4.8448C45.3821 -5.04178 60.9688 1.17264 76.2721 14.7314C103.195 38.7416 89.3083 90.4343 64.0862 99.7559C38.864 109.078 16.1924 87.3271 4.85661 71.791C-6.47917 56.255 2.87285 14.7314 24.1275 4.8448Z"
          fill="#F3FBF6"
        />
      </mask>
      <g mask="url(#mask0)">
        <path
          d="M24.1275 4.8448C45.3821 -5.04178 60.9688 1.17264 76.2721 14.7314C103.195 38.7416 89.3083 90.4343 64.0862 99.7559C38.864 109.078 16.1924 87.3271 4.85661 71.791C-6.47917 56.255 2.87285 14.7314 24.1275 4.8448Z"
          fill="#F3FBF6"
        />
        <path
          d="M46.6479 12.8344C51.1291 3.01853 75.0286 8.13987 78.6562 8.35326L96.5809 37.8009C84.4177 33.9599 74.3884 47.1901 59.6646 42.4955C48.8722 39.0544 42.1667 22.6503 46.6479 12.8344Z"
          fill="#F1F8F4"
        />
        <path
          d="M51.2881 8.38991C39.8735 7.19331 28.0963 -3.49201 23.6345 -8.68509C12.5036 -7.41163 -7.44715 -3.55525 1.79741 1.68264C13.3531 8.23001 4.53202 32.9511 25.8387 40.7951C47.1453 48.6391 67.2143 29.4438 68.9419 21.1231C70.6694 12.8024 65.5562 9.88567 51.2881 8.38991Z"
          fill="#ECF6F0"
        />
        <path
          d="M37.9831 19.2052C34.9957 8.32236 17.4978 -0.213204 6.82831 0.213575C1.0668 3.62771 -13.657 21.5526 -10.4562 30.9417C-7.25537 40.3308 14.2969 44.812 24.3262 43.9584C34.3555 43.1049 40.9706 30.0881 37.9831 19.2052Z"
          fill="#E6F2EB"
        />
        <path d="M76.3929 43.1045H7.68164V83.2217H76.3929V43.1045Z" fill="#CCE4D5" />
        <path d="M7.68164 43.1045H76.3929V83.2216H7.68164V43.1045Z" fill="#CCE4D5" />
        <path
          d="M47.3291 28.251H57.9132V46.1757C57.9132 46.1757 56.5902 50.102 52.6212 50.102C48.6521 50.102 47.3291 46.1757 47.3291 46.1757V28.251Z"
          fill="#14AA4B"
        />
        <path
          d="M36.7451 28.251H47.3292V46.1757C47.3292 46.1757 46.0062 50.102 42.0372 50.102C38.0681 50.102 36.7451 46.1757 36.7451 46.1757V28.251Z"
          fill="white"
        />
        <path
          d="M68.498 28.251H79.0821V46.1757C79.0821 46.1757 77.7591 50.102 73.7901 50.102C69.8211 50.102 68.498 46.1757 68.498 46.1757V28.251Z"
          fill="#14AA4B"
        />
        <path
          d="M57.9141 28.251H68.4982V46.1757C68.4982 46.1757 67.1751 50.102 63.2061 50.102C59.2371 50.102 57.9141 46.1757 57.9141 46.1757V28.251Z"
          fill="white"
        />
        <path
          d="M26.1611 28.251H36.7452V46.1757C36.7452 46.1757 35.4222 50.102 31.4532 50.102C27.4841 50.102 26.1611 46.1757 26.1611 46.1757V28.251Z"
          fill="#14AA4B"
        />
        <path
          d="M15.5771 28.251H26.1612V46.1757C26.1612 46.1757 24.8382 50.102 20.8692 50.102C16.9002 50.102 15.5771 46.1757 15.5771 46.1757V28.251Z"
          fill="white"
        />
        <path
          d="M4.99316 28.252H15.5773V46.1766C15.5773 46.1766 14.2543 50.103 10.2852 50.103C6.31618 50.103 4.99316 46.1766 4.99316 46.1766V28.252Z"
          fill="#14AA4B"
        />
        <path
          d="M1.70703 26.46C1.70703 26.2243 1.89811 26.0332 2.13381 26.0332H81.5146C81.7503 26.0332 81.9413 26.2243 81.9413 26.46V30.301C81.9413 30.5367 81.7503 30.7278 81.5146 30.7278H2.13381C1.8981 30.7278 1.70703 30.5367 1.70703 30.301V26.46Z"
          fill="#677481"
        />
        <path
          d="M17.9248 55.9073C17.9248 55.4359 18.307 55.0537 18.7784 55.0537H36.2763C36.7477 55.0537 37.1298 55.4359 37.1298 55.9073V83.2211H17.9248V55.9073Z"
          fill="#85909A"
        />
        <path
          d="M46.9453 55.9073C46.9453 55.4359 47.3275 55.0537 47.7989 55.0537H67.4307C67.9021 55.0537 68.2842 55.4359 68.2842 55.9073V68.7106C68.2842 69.182 67.9021 69.5642 67.4307 69.5642H47.7989C47.3275 69.5642 46.9453 69.182 46.9453 68.7106V55.9073Z"
          fill="white"
        />
        <path
          d="M45.2383 68.2842C45.2383 68.0485 45.4294 67.8574 45.6651 67.8574H69.5646C69.8003 67.8574 69.9914 68.0485 69.9914 68.2842V69.9913C69.9914 70.227 69.8003 70.4181 69.5646 70.4181H45.6651C45.4294 70.4181 45.2383 70.227 45.2383 69.9913V68.2842Z"
          fill="#B28B67"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M70.2819 55.3275C72.4311 58.9331 74.9343 62.4243 76.4375 66.3665C76.377 65.9019 76.3127 65.4377 76.2408 64.9741C75.8594 62.517 75.3854 60.0778 74.8872 57.6416C74.6335 56.4012 74.3652 55.1653 74.09 53.9295C73.942 53.2657 73.7959 52.6016 73.6448 51.9385C73.6082 51.7777 73.5768 51.4972 73.531 51.2129C74.9919 56.0069 76.4007 60.8044 77.4312 65.7136C77.5664 66.3579 77.6815 67.0011 77.7758 67.6431C77.808 67.4966 77.8398 67.3501 77.8745 67.2041C78.2915 65.4531 78.9169 63.7161 79.6616 62.079C80.0176 61.296 80.4266 60.5303 80.908 59.8167C81.2083 59.3719 81.9163 58.8432 82.1902 58.3877C80.6923 61.135 80.1515 64.5725 79.2866 67.5594C79.0754 68.2883 78.863 69.016 78.6549 69.745C79.9592 67.1293 81.9829 64.9801 84.7965 64.0488C81.3907 66.2991 79.3479 70.2399 77.9713 74.2704C77.9668 74.3182 77.9614 74.366 77.9567 74.4139C78.3618 73.6451 78.8721 72.9497 79.5636 72.4627C81.0437 71.4201 82.6857 71.7239 84.1214 72.6182C82.9946 71.9019 80.9581 72.5541 80.0099 73.2822C79.3312 73.8033 78.7511 74.5025 78.2474 75.1846C78.1267 75.3481 77.962 75.5389 77.7881 75.7424C77.5442 77.3256 77.1467 78.898 76.584 80.4579C76.6768 80.0102 76.6097 79.2941 76.6354 78.9604C76.6382 78.9242 76.6405 78.8881 76.6433 78.8521C76.5577 79.1936 76.4751 79.5313 76.3955 79.8635C76.5537 79.2295 76.6397 78.5678 76.7068 77.8991C76.7241 77.6118 76.7409 77.3245 76.7587 77.0371C76.7531 76.4918 76.7306 75.9444 76.6926 75.396C76.5579 74.8631 76.4071 74.3605 76.2828 73.9424C75.834 72.434 75.176 70.9889 74.3662 69.6408C73.6274 68.4106 72.7429 67.2962 71.7219 66.2884C71.1715 65.7453 70.5955 65.228 70.0276 64.7036C69.8222 64.514 69.4552 64.0478 69.1377 63.8048C71.4497 65.0794 73.8294 66.4584 75.5161 68.5121C75.3183 67.7528 75.1036 67.0042 74.8764 66.2706C73.7238 62.5487 72.2685 58.6914 70.2819 55.3275Z"
          fill="#E1F1E7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M72.5918 75.7373C72.5918 79.8706 72.9191 83.2213 77.3125 83.2213C81.7058 83.2213 82.0331 79.8706 82.0331 75.7373"
          fill="#D8EBDF"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="91" height="102" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
