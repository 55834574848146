import { type PaymentLinkCharge, type PaymentLinkPaymentMethod, type Phone } from '~/lib/types/banking/PaymentLink'

export interface InstallmentForms {
  text: string
  value: string
  disabled?: boolean
  moreInfo?: string
}

export function formatCardBrandAndLastFourDigits(cardBrand?: string, lastFourDigits?: string) {
  if (cardBrand && lastFourDigits) return `${cardBrand?.toUpperCase()} final ${lastFourDigits}`
}

export function formatDuration(linkDuration: string) {
  return linkDuration.replace(/\um(a)?/i, '1')
}

export function formatPhoneNumber(phoneNumber: Phone) {
  const { areaCode, number } = phoneNumber
  return areaCode + number
}

export function generateInstallments(maxQuantity: number, options = {}, minQuantity = 12) {
  const qty = maxQuantity || minQuantity
  const items = Array(qty).keys()
  return Array.from(items).map(item => {
    const value = item + 1

    if (value === 1) {
      return { value: value.toString(), text: 'À vista' }
    }

    if (value >= 2 && value <= minQuantity) {
      return { value: value.toString(), text: `Até ${value}x sem juros` }
    }

    if (value >= minQuantity && value <= maxQuantity && options) {
      return {
        ...options,
        value: value.toString(),
        text: `Até ${value}x sem juros`,
        disabled: true,
        moreInfo: `Disponível a partir de R$ ${value}0,00`
      }
    }
  }) as InstallmentForms[]
}

export function onlyOrderCode(code: string) {
  return code.replace('cod_', '')
}

export const translatePaymentMethods = (paymentMethod: PaymentLinkPaymentMethod) => {
  const paymentMethodTranslationMap: Record<PaymentLinkPaymentMethod, string> = {
    credit_card: 'Cartão de crédito'
  }

  return paymentMethodTranslationMap[paymentMethod]
}

export const lastSortedChargeStatus = (charges: PaymentLinkCharge[]) => {
  const sortedChargesByDate = charges.sort(
    (initialDate, finalDate) => Date.parse(finalDate.createdAt) - Date.parse(initialDate.createdAt)
  )
  return sortedChargesByDate[0].status
}
