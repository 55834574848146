export function RavDailyReceivableBannerOfferIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="67" height="57" viewBox="0 0 358 256" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_2314_77064)">
        <path
          d="M114.445 15.4686C114.445 15.4686 113.255 30.4052 131.825 36.9777C142.57 40.7805 151.171 36.2982 151.171 36.2982C151.171 36.2982 157.187 12.7881 151.109 6.26707C144.605 -0.707008 136.108 1.52044 132.952 4.17691C128.542 7.8939 124.929 14.3703 114.445 15.4686Z"
          fill="#E09661"
        />
        <path
          d="M52.2912 127.548C56.8528 122.448 60.2868 107.546 64.4153 90.8793C68.3271 75.1018 77.6187 74.4771 83.6275 73.6122C88.1684 72.9567 112.32 73.7255 124.953 73.7255C130.116 73.7255 136.403 77.7754 133.939 87.3339C131.735 95.8833 125.548 96.7036 125.548 96.7036H88.7837L69.2621 154.785L23.9456 142.141C26.9293 130.163 47.7296 132.648 52.2912 127.548Z"
          fill="#FFCBD9"
        />
        <path
          d="M52.2912 127.548C53.9825 125.657 59.2728 127.74 58.135 134.392C64.429 138.105 74.4253 139.423 74.4253 139.423L69.2622 154.785L23.7496 142.21C23.3784 142.107 23.1618 141.719 23.2718 141.352C26.589 130.256 47.819 132.549 52.2912 127.548Z"
          fill="#00A868"
        />
        <path
          d="M63.6111 150.379C63.563 150.379 63.5149 150.372 63.4668 150.358L25.2177 139.258C24.9427 139.18 24.788 138.895 24.8671 138.62C24.9461 138.346 25.2315 138.191 25.5065 138.27L63.7555 149.369C64.0305 149.448 64.1852 149.733 64.1061 150.008C64.0408 150.231 63.8346 150.379 63.6111 150.379Z"
          fill="#1D232A"
        />
        <path
          d="M52.8584 116.002C58.4443 108.599 60.7303 103.115 63.8859 90.5257C67.8356 74.7585 76.6803 73.7083 84.0709 72.9395C88.6359 72.4624 112.709 72.8331 125.342 72.8331C130.505 72.8331 136.953 78.146 134.489 87.7045C132.285 96.2539 125.544 96.707 125.544 96.707H88.7837L79.2206 125.159L52.8584 116.002Z"
          fill="#008E5A"
        />
        <path d="M79.3376 96.1579L64.4841 120.039L75.0751 123.512L79.3376 96.1579Z" fill="#1D232A" />
        <path
          d="M76.0855 85.0206C76.0855 85.0206 70.8261 67.8908 69.3343 61.7611C67.4333 53.9461 74.8343 42.5 88.4777 50.3252C109.467 62.3617 128.188 74.4325 128.188 74.4325C128.188 74.4325 93.5343 88.3395 93.0152 88.3395C92.4961 88.3395 76.0855 85.0206 76.0855 85.0206Z"
          fill="#008E5A"
        />
        <path d="M95.5969 72.6959L126.057 73.0082L96.5663 54.6978L95.5969 72.6959Z" fill="#1D232A" />
        <path
          d="M94.576 71.5873C94.5554 71.5873 94.5348 71.5873 94.5142 71.5839C94.2323 71.5495 94.0295 71.2921 94.0639 71.0107L96.0542 54.636C96.0885 54.3546 96.3464 54.1521 96.6282 54.1864C96.9101 54.2207 97.1129 54.4781 97.0785 54.7596L95.0882 71.1342C95.0539 71.3951 94.8339 71.5873 94.576 71.5873Z"
          fill="#1D232A"
        />
        <path
          d="M72.294 76.8831C72.129 76.8831 71.9674 76.8042 71.8678 76.66C71.7062 76.4266 71.7646 76.104 72.0018 75.9427C75.8965 73.2691 80.3996 72.8023 84.0193 72.4248C86.3327 72.1845 115.51 72.1502 123.1 72.1777C123.386 72.1777 123.616 72.411 123.612 72.6959C123.612 72.9808 123.379 73.2073 123.093 73.2073C114.273 73.173 86.2606 73.2245 84.1259 73.4476C80.4409 73.832 76.2643 74.2678 72.5862 76.7905C72.4968 76.8522 72.3971 76.8831 72.294 76.8831Z"
          fill="#1D232A"
        />
        <path
          d="M192.143 96.7001H160.349C157.159 96.7001 156.266 89.7055 154.983 85.8443C154.464 84.2861 152.979 79.9205 151.278 74.9302C149.095 68.5224 146.555 61.0918 145.255 57.2719C144.788 55.8956 144.482 54.9895 144.41 54.763C144.162 53.9908 143.928 53.2254 143.815 52.4188C143.437 49.8104 143.519 47.1196 144.643 44.6931C145.97 41.8239 148.761 39.5621 151.914 39.291C156.018 38.9375 160.023 40.4682 161.711 45.5889C163 49.4981 172.92 85.9164 177.97 88.0443C180.266 89.0122 188.802 89.9869 191.022 91.1195C193.762 92.5164 192.143 96.7001 192.143 96.7001Z"
          fill="#FFCBD9"
        />
        <path
          d="M158.149 56.1598L151.277 74.9301C149.094 68.5224 146.554 61.0918 145.255 57.2718L143.814 52.4188L158.149 56.1598Z"
          fill="#1D232A"
        />
        <path
          d="M221.829 90.8758C221.757 89.8084 221.598 88.7616 221.365 87.7457C219.299 78.781 211.238 72.0746 201.647 72.0746C190.489 72.0746 181.417 81.1354 181.417 92.2726C181.417 103.406 190.492 112.467 201.647 112.467C208.086 112.467 213.833 109.447 217.542 104.755C220.254 101.313 221.877 96.9747 221.877 92.2726C221.877 91.8059 221.863 91.3357 221.829 90.8758ZM201.647 107.679C193.139 107.679 186.216 100.771 186.216 92.2795C186.216 83.785 193.139 76.8727 201.647 76.8727C209.186 76.8727 215.48 82.2989 216.813 89.4411C216.985 90.361 217.078 91.3116 217.078 92.2795C217.078 96.0102 215.748 99.4252 213.534 102.095C210.698 105.5 206.418 107.679 201.647 107.679Z"
          fill="#42EC9A"
        />
        <path
          d="M221.877 92.276C221.877 96.978 220.254 101.316 217.542 104.759H213.534V102.092C215.748 99.4217 217.078 96.0033 217.078 92.276L221.832 90.8757C221.863 91.3356 221.877 91.8058 221.877 92.276Z"
          fill="#1D232A"
        />
        <path
          d="M149.301 90.8758C149.229 89.8084 149.071 88.7616 148.837 87.7457C146.771 78.781 138.71 72.0746 129.119 72.0746C117.961 72.0746 108.89 81.1354 108.89 92.2726C108.89 103.406 117.965 112.467 129.119 112.467C135.558 112.467 141.305 109.447 145.014 104.755C147.727 101.313 149.349 96.9747 149.349 92.2726C149.349 91.8059 149.335 91.3357 149.301 90.8758ZM129.116 107.679C120.608 107.679 113.685 100.771 113.685 92.2795C113.685 83.785 120.608 76.8727 129.116 76.8727C136.654 76.8727 142.948 82.2989 144.282 89.4411C144.454 90.361 144.547 91.3116 144.547 92.2795C144.547 96.0102 143.217 99.4252 141.003 102.095C138.167 105.5 133.891 107.679 129.116 107.679Z"
          fill="#42EC9A"
        />
        <path
          d="M149.349 92.276C149.349 96.978 147.727 101.316 145.014 104.759H141.006V102.092C143.22 99.4217 144.55 96.0033 144.55 92.276L149.304 90.8757C149.335 91.3356 149.349 91.8058 149.349 92.276Z"
          fill="#1D232A"
        />
        <path
          d="M265.87 96.1579L245.111 167.062L244.815 168.068L232.354 210.623L232.062 211.628L219.457 254.674H32.9243L45.5296 211.628L45.8287 210.599L58.2828 168.068L58.5853 167.038L79.3375 96.1579H265.87Z"
          fill="#E8E9EA"
        />
        <path d="M265.866 96.1579L257.716 123.996H71.1836L79.3373 96.1579H265.866Z" fill="#42EC9A" />
        <path d="M265.939 96.1579L219.457 254.674H312.349L265.939 96.1579Z" fill="#00A868" />
        <path
          d="M245.111 167.062C245.063 167.045 245.015 167.038 244.963 167.038H180.115L198.165 105.394C198.244 105.119 198.089 104.834 197.814 104.755C197.543 104.673 197.254 104.831 197.175 105.105L179.042 167.038H117.937L135.736 106.245C135.819 105.97 135.661 105.685 135.389 105.606C135.114 105.524 134.829 105.682 134.75 105.957L116.865 167.038H58.5852L58.2827 168.068H116.562L104.108 210.599H45.8286L45.5295 211.628H103.809L91.2483 254.53C91.2346 254.578 91.2277 254.626 91.2277 254.674C91.2242 254.897 91.372 255.103 91.5955 255.168C91.647 255.182 91.6952 255.189 91.7433 255.189C91.9667 255.189 92.1695 255.045 92.2349 254.818L92.2761 254.674L104.881 211.628H165.986L153.426 254.53C153.412 254.578 153.405 254.626 153.405 254.674C153.402 254.897 153.55 255.103 153.773 255.168C153.825 255.182 153.873 255.189 153.921 255.189C154.144 255.189 154.347 255.045 154.416 254.818L154.457 254.674L167.059 211.628H232.21C232.495 211.628 232.725 211.398 232.725 211.113C232.725 210.88 232.567 210.681 232.354 210.623C232.31 210.606 232.261 210.599 232.21 210.599H167.361L179.815 168.068H244.963C245.248 168.068 245.479 167.838 245.479 167.553C245.479 167.32 245.324 167.124 245.111 167.062ZM166.285 210.599H105.184L117.638 168.068H178.74L166.285 210.599Z"
          fill="#1D232A"
        />
        <path d="M265.939 96.1579L219.457 254.674H249.071L267.555 101.677L265.939 96.1579Z" fill="#1D232A" />
        <path d="M265.866 96.1579L257.716 123.996H71.1836L79.3373 96.1579H265.866Z" fill="#00A868" />
        <path
          d="M126.514 118.978C124.517 118.978 123.365 117.379 123.943 115.409L125.201 111.112C125.778 109.142 127.865 107.542 129.862 107.542C131.859 107.542 133.011 109.142 132.433 111.112L131.175 115.409C130.598 117.382 128.511 118.978 126.514 118.978Z"
          fill="#1D232A"
        />
        <path
          d="M126.514 119.493C125.4 119.493 124.465 119.06 123.877 118.278C123.269 117.468 123.114 116.397 123.448 115.265L124.706 110.967C125.352 108.757 127.617 107.027 129.862 107.027C130.976 107.027 131.911 107.46 132.499 108.242C133.107 109.052 133.262 110.123 132.928 111.256L131.67 115.553C131.024 117.763 128.759 119.493 126.514 119.493ZM129.859 108.06C128.078 108.06 126.208 109.495 125.692 111.259L124.438 115.556C124.2 116.37 124.293 117.118 124.702 117.664C125.091 118.182 125.734 118.467 126.514 118.467C128.294 118.467 130.164 117.032 130.68 115.268L131.938 110.971C132.175 110.157 132.083 109.409 131.674 108.864C131.282 108.345 130.639 108.06 129.859 108.06Z"
          fill="#1D232A"
        />
        <path
          d="M129.116 112.44C117.961 112.44 108.886 103.379 108.886 92.2419C108.886 81.1046 117.961 72.0438 129.116 72.0438C130.443 72.0438 131.516 73.1181 131.516 74.4394C131.516 75.7642 130.44 76.8351 129.116 76.8351C120.608 76.8351 113.689 83.7474 113.689 92.2384C113.689 100.733 120.612 107.642 129.116 107.642C130.443 107.642 131.516 108.716 131.516 110.037C131.516 111.359 130.443 112.44 129.116 112.44Z"
          fill="#42EC9A"
        />
        <path
          d="M199.045 118.978C197.048 118.978 195.896 117.379 196.474 115.409L197.732 111.112C198.309 109.142 200.396 107.542 202.393 107.542C204.39 107.542 205.542 109.142 204.964 111.112L203.706 115.409C203.129 117.382 201.042 118.978 199.045 118.978Z"
          fill="#1D232A"
        />
        <path
          d="M199.045 119.493C197.931 119.493 196.996 119.06 196.409 118.278C195.8 117.468 195.645 116.397 195.979 115.265L197.237 110.967C197.883 108.757 200.149 107.027 202.393 107.027C203.507 107.027 204.442 107.46 205.03 108.242C205.638 109.052 205.793 110.123 205.459 111.256L204.201 115.553C203.552 117.763 201.286 119.493 199.045 119.493ZM202.39 108.06C200.609 108.06 198.739 109.495 198.224 111.259L196.965 115.556C196.728 116.37 196.821 117.118 197.23 117.664C197.619 118.182 198.261 118.467 199.042 118.467C200.822 118.467 202.692 117.032 203.208 115.268L204.466 110.971C204.703 110.157 204.61 109.409 204.201 108.864C203.813 108.345 203.17 108.06 202.39 108.06Z"
          fill="#1D232A"
        />
        <path
          d="M201.647 112.44C190.492 112.44 181.417 103.379 181.417 92.2419C181.417 81.1046 190.492 72.0438 201.647 72.0438C202.974 72.0438 204.047 73.1181 204.047 74.4394C204.047 75.7642 202.971 76.8351 201.647 76.8351C193.139 76.8351 186.22 83.7474 186.22 92.2384C186.22 100.733 193.143 107.642 201.647 107.642C202.974 107.642 204.047 108.716 204.047 110.037C204.047 111.359 202.971 112.44 201.647 112.44Z"
          fill="#42EC9A"
        />
        <path
          d="M249.037 254.403C249.013 254.403 248.985 254.399 248.961 254.396C248.679 254.355 248.487 254.09 248.528 253.809L268.318 122.053C268.359 121.779 268.613 121.586 268.888 121.617C269.163 121.652 269.366 121.895 269.342 122.173L259.445 233.851C259.421 234.136 259.17 234.342 258.885 234.318C258.6 234.294 258.39 234.043 258.418 233.759L266.812 139.001L249.542 253.963C249.508 254.221 249.288 254.403 249.037 254.403Z"
          fill="#1D232A"
        />
        <path
          d="M156.551 60.1102C155.265 62.3823 153.354 64.3832 150.927 65.358C148.531 66.319 145.85 66.2194 143.275 65.9792C137.737 65.4644 132.251 64.3764 126.936 62.7358C125.201 62.2004 123.427 61.5723 122.093 60.3368C120.13 58.5143 119.467 55.6794 119.36 53.0023C119.288 51.1764 119.426 49.3505 119.639 47.5349C119.848 45.7022 120.134 43.8729 120.35 42.0401C120.563 40.2245 120.615 38.4364 120.653 36.6139C120.663 36.0064 121.079 35.5843 121.498 35.1793C122 34.6885 122.598 34.2972 123.234 34.0021C124.544 33.4014 125.888 32.9758 128.047 33.1166C129.992 33.247 133.165 34.9493 135.654 36.0339C135.695 36.051 135.733 36.0682 135.774 36.0854C135.97 36.1712 136.17 36.257 136.366 36.3393C136.561 36.4217 136.757 36.5041 136.953 36.583C136.995 36.6002 137.036 36.6173 137.074 36.6345C137.091 36.6379 137.105 36.6448 137.118 36.6517C139.16 37.4822 141.243 38.203 143.357 38.8139C143.663 38.9031 143.969 38.9924 144.279 39.0747C144.719 39.1949 145.159 39.3116 145.602 39.4214C145.712 39.4488 145.822 39.4763 145.932 39.5038C146.365 39.6102 146.802 39.7097 147.238 39.8058C147.362 39.8332 147.486 39.8607 147.61 39.8882C150.545 40.5231 153.718 41.0413 155.884 43.1144C157.743 44.8956 158.52 47.5418 158.706 50.1056C158.96 53.5583 158.256 57.0968 156.551 60.1102Z"
          fill="#00A868"
        />
        <path
          d="M155.124 23.4689C154.481 24.8177 153.343 25.765 152.03 26.2043C151.948 26.2318 151.865 26.2558 151.786 26.2798C151.676 26.3107 151.57 26.3347 151.463 26.3588C151.432 26.3656 151.398 26.3725 151.363 26.3794C151.336 26.3828 151.308 26.3897 151.281 26.3931C151.178 26.4137 151.075 26.4274 150.968 26.4411H150.965C150.91 26.448 150.855 26.4549 150.8 26.4583C150.738 26.4617 150.679 26.4652 150.621 26.4686H150.59C150.518 26.4755 150.442 26.4755 150.37 26.4755H150.367C150.277 26.4755 150.191 26.472 150.102 26.4686C150.067 26.4686 150.03 26.4652 149.995 26.4617C149.988 26.4652 149.978 26.4617 149.971 26.4583C149.916 26.4583 149.861 26.4549 149.806 26.448C149.762 26.4411 149.72 26.4377 149.672 26.4308C149.555 26.4171 149.442 26.3999 149.325 26.3759V26.3725C149.27 26.3622 149.212 26.3485 149.153 26.3347C149.112 26.3279 149.071 26.3141 149.033 26.3038C148.857 26.2592 148.682 26.2043 148.507 26.1357C148.5 26.1357 148.493 26.1322 148.486 26.1288C148.407 26.1014 148.328 26.067 148.249 26.0327C148.201 26.0121 148.149 25.9881 148.101 25.9675C147.971 25.9057 147.843 25.8371 147.72 25.7684C145.368 24.4093 144.416 21.4543 145.602 18.9591C146.847 16.3335 149.992 15.2146 152.622 16.4605C155.255 17.7064 156.372 20.8433 155.124 23.4689Z"
          fill="#FFCBD9"
        />
        <path
          d="M151.364 26.3793C151.336 26.3828 151.309 26.3896 151.281 26.3931C151.178 26.4136 151.075 26.4274 150.968 26.4411H150.965C150.91 26.448 150.855 26.4548 150.8 26.4583C150.738 26.4617 150.68 26.4651 150.621 26.4686H150.59C150.075 24.725 149.604 22.9609 149.177 21.1934C149.06 20.7095 149.81 20.5241 149.923 21.0046C150.36 22.8099 150.838 24.5981 151.364 26.3793Z"
          fill="#1D232A"
        />
        <path
          d="M150.102 26.4684C150.068 26.4684 150.03 26.465 149.995 26.4616C149.988 26.465 149.978 26.4616 149.971 26.4581C149.916 26.4581 149.861 26.4547 149.806 26.4479C149.762 26.441 149.72 26.4376 149.672 26.4307C149.555 26.417 149.442 26.3998 149.325 26.3758V26.3723C149.27 26.362 149.212 26.3483 149.153 26.3346C149.112 26.3277 149.071 26.314 149.033 26.3037C148.858 26.2591 148.682 26.2042 148.507 26.1355C148.5 26.0909 148.493 26.0463 148.486 26.0017V26.1287C148.407 26.1012 148.328 26.0669 148.249 26.0326C148.201 26.012 148.149 25.9879 148.101 25.9674C147.971 25.9056 147.843 25.8369 147.72 25.7683C147.733 24.7455 147.771 23.7227 147.83 22.7C147.84 22.5867 147.888 22.5009 147.953 22.4357C147.943 22.3636 147.936 22.2916 147.923 22.216C147.874 21.89 148.198 21.6944 148.438 21.7527C148.579 21.4541 149.091 21.3992 149.153 21.7973C149.387 23.3727 149.7 24.9309 150.102 26.4684Z"
          fill="#1D232A"
        />
        <path
          d="M150.855 17.1228L150.813 17.2532L150.721 17.5656C150.721 17.5656 150.721 17.5656 150.717 17.5656L148.679 24.2857C146.898 30.158 140.686 33.4803 134.808 31.7025C128.927 29.9246 125.599 23.7228 127.38 17.8504L129.552 10.6841C130.357 8.02081 132.072 5.88603 134.258 4.51318C134.361 4.44797 134.465 4.38619 134.571 4.32441C135.75 3.63112 137.053 3.15749 138.421 2.9344C138.456 2.92754 138.493 2.92411 138.528 2.92067C138.548 2.91724 138.572 2.91381 138.6 2.91038C139.311 2.80741 140.04 2.76966 140.779 2.80741C141.659 2.8486 142.55 3.00304 143.43 3.27075C144.176 3.49384 144.873 3.789 145.53 4.14594C147.847 5.4021 149.593 7.41676 150.538 9.76776C150.999 10.9175 151.271 12.1497 151.326 13.4127C151.381 14.6345 151.233 15.8872 150.855 17.1228Z"
          fill="#FFCBD9"
        />
        <path
          d="M140.611 14.6119L140.611 14.6119L140.61 14.6116C140.312 14.5409 140.071 14.3551 139.92 14.1092C139.769 13.8633 139.714 13.567 139.787 13.2675C139.935 12.6647 140.54 12.2955 141.137 12.437C141.138 12.4371 141.138 12.4371 141.138 12.4372L144.923 13.3495C144.924 13.3495 144.924 13.3496 144.924 13.3496C145.227 13.4237 145.469 13.6108 145.619 13.8559C145.77 14.1014 145.826 14.4012 145.752 14.7013L145.752 14.7026C145.608 15.3005 145.003 15.6701 144.399 15.5249C144.399 15.5249 144.399 15.5248 144.399 15.5248L140.611 14.6119Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <path
          d="M142.075 12.1531L142.391 15.5577L144.286 16.0142C145.155 16.2236 146.032 15.6916 146.241 14.8233C146.348 14.3874 146.265 13.9515 146.049 13.598C145.832 13.2445 145.482 12.9734 145.045 12.867L142.075 12.1531Z"
          fill="#1D232A"
        />
        <path
          d="M131.458 11.0432L131.458 11.0419C131.603 10.442 132.21 10.0711 132.808 10.213C132.809 10.213 132.809 10.2131 132.809 10.2131L136.594 11.1254C136.595 11.1255 136.595 11.1255 136.595 11.1255C136.898 11.1996 137.14 11.3868 137.29 11.6319C137.441 11.8774 137.497 12.1771 137.423 12.4772L137.423 12.4786C137.279 13.0764 136.674 13.446 136.07 13.3008C136.07 13.3008 136.07 13.3008 136.07 13.3008L132.282 12.3879L132.282 12.3879L132.281 12.3875C131.983 12.3168 131.742 12.131 131.591 11.8851C131.44 11.6392 131.385 11.3428 131.458 11.0432Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <path
          d="M133.746 9.92914L134.062 13.3338L135.956 13.7903C136.826 13.9996 137.703 13.4677 137.912 12.5993C138.019 12.1635 137.936 11.7276 137.72 11.3741C137.503 11.0206 137.153 10.7494 136.716 10.643L133.746 9.92914Z"
          fill="#1D232A"
        />
        <path
          d="M137.304 17.9912C136.551 17.9912 135.823 17.5519 135.434 16.8586C135.09 16.2442 135.101 15.5509 135.458 15.0052L137.136 12.4757C137.294 12.2389 137.614 12.1737 137.851 12.3316C138.088 12.4895 138.153 12.8087 137.995 13.0455L136.321 15.575C136.122 15.877 136.245 16.1962 136.335 16.3575C136.51 16.6698 136.881 16.9787 137.332 16.9615C137.627 16.9512 137.926 16.8929 138.229 16.7831C138.497 16.687 138.793 16.8242 138.889 17.092C138.985 17.3597 138.848 17.6548 138.58 17.7509C138.177 17.8985 137.768 17.9774 137.363 17.9912C137.345 17.9912 137.325 17.9912 137.304 17.9912Z"
          fill="#1D232A"
        />
        <path
          d="M134.496 25.583C135.08 25.8919 135.682 26.1355 136.3 26.3071C137.558 26.6607 138.872 26.7259 140.174 26.4925C141.58 26.2419 142.883 25.6585 143.997 24.7936C144.695 24.2547 145.32 23.6026 145.846 22.8579C146.764 21.5605 146.496 19.7072 145.241 18.7222C144.928 18.475 144.581 18.3069 144.224 18.2108C143.155 17.9259 141.993 18.3103 141.302 19.2816C140.776 20.0229 140.006 20.5172 139.133 20.6716C138.39 20.802 137.641 20.6819 136.964 20.325C135.919 19.769 134.712 19.9543 133.884 20.6853C133.605 20.9324 133.368 21.2413 133.196 21.6086C132.519 23.0638 133.1 24.8416 134.496 25.583Z"
          fill="#1D232A"
        />
        <path
          d="M134.496 25.583C135.08 25.8919 135.682 26.1355 136.3 26.3071C137.558 26.6607 138.872 26.7259 140.174 26.4925C141.58 26.2419 142.883 25.6585 143.997 24.7936C144.695 24.2547 145.32 23.6026 145.846 22.8579C146.764 21.5605 146.496 19.7072 145.241 18.7222C144.928 18.475 144.581 18.3069 144.224 18.2108C143.155 17.9259 141.993 18.3103 141.302 19.2816C140.776 20.0229 140.006 20.5172 139.133 20.6716C138.39 20.802 137.641 20.6819 136.964 20.325C135.919 19.769 134.712 19.9543 133.884 20.6853C133.605 20.9324 133.368 21.2413 133.196 21.6086C132.519 23.0638 133.1 24.8416 134.496 25.583Z"
          fill="#1D232A"
        />
        <path
          d="M139.227 21.1607L139.226 21.1608C138.373 21.3107 137.511 21.1716 136.738 20.7641C136.062 20.409 135.307 20.4268 134.686 20.744C135.958 21.8186 137.631 22.3358 139.33 22.0328C141.259 21.6887 142.793 20.3691 143.545 18.6157C142.849 18.6113 142.165 18.9376 141.716 19.5679L139.227 21.1607ZM139.227 21.1607C140.233 20.9827 141.117 20.413 141.716 19.5681L139.227 21.1607Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <path
          d="M136.3 26.3072C137.558 26.6607 138.872 26.7259 140.174 26.4925C141.58 26.242 142.883 25.6585 143.997 24.7936C142.704 23.7228 141.051 23.661 140.291 24.6563C140.174 24.8108 140.085 24.9789 140.02 25.1643C139.92 25.0579 139.81 24.9652 139.686 24.8897C138.641 24.2204 137.125 24.8554 136.3 26.3072Z"
          fill="#D0572B"
        />
        <path
          d="M138.582 9.58717C138.896 9.35491 138.778 8.66264 138.317 8.04093C137.856 7.41922 137.228 7.1035 136.914 7.33575C136.599 7.56801 136.718 8.26028 137.178 8.88199C137.639 9.5037 138.268 9.81942 138.582 9.58717Z"
          fill="#E09661"
        />
        <path
          d="M151.322 13.4127C150.532 13.5191 149.72 13.526 148.895 13.4196C143.749 12.7709 140.102 8.07578 140.755 2.93445C140.762 2.88984 140.766 2.84865 140.776 2.80403C141.656 2.84522 143.66 1.02962 149.621 6.19841C152.869 9.01961 151.267 12.1497 151.322 13.4127Z"
          fill="#E09661"
        />
        <path
          d="M149.377 32.485C150.402 32.485 151.233 31.2742 151.233 29.7805C151.233 28.2868 150.402 27.076 149.377 27.076C148.352 27.076 147.521 28.2868 147.521 29.7805C147.521 31.2742 148.352 32.485 149.377 32.485Z"
          fill="#42EC9A"
        />
        <path
          d="M133 50.8092C129.264 58.1848 123.544 65.4541 116.961 70.6983C109.22 76.8693 98.5464 72.8022 95.9202 66.6347C93.1495 60.1308 91.9155 54.0011 88.282 40.7393C88.1377 40.2211 81.3623 21.6498 83.9267 16.2648C84.5833 14.8885 87.0342 14.6895 88.3577 17.7612C89.0245 19.3091 89.5608 20.7094 90.0008 21.921C93.703 13.1416 95.463 11.8408 97.1577 11.9712C98.2852 12.0536 101.248 13.4024 98.732 18.4305C98.6736 18.5438 98.6117 18.6605 98.5498 18.7806C98.5464 18.7874 98.5395 18.7977 98.5361 18.8046C98.5395 18.8046 98.5464 18.8012 98.5498 18.8012C98.873 18.7222 99.1823 18.6639 99.4745 18.6227C103.789 17.9775 104.552 20.9325 103.414 22.8374C102.991 23.5444 102.228 24.1896 101.465 24.7044C100.399 25.432 99.3439 25.9091 99.2442 25.9606C99.3405 25.9915 99.4367 26.0224 99.5295 26.0601C103.125 27.4192 103.885 32.9244 104.682 34.915C107.852 42.8123 110.736 48.9524 110.736 48.9524C110.736 48.9524 115.913 40.8251 120.815 35.5602C122.702 33.5284 125.854 32.7322 128.59 33.1921C132.041 33.7721 134.482 37.1768 134.953 40.6398C135.431 44.1028 134.582 47.6928 133 50.8092Z"
          fill="#FFCBD9"
        />
        <path
          d="M98.5533 18.7807L98.5396 18.4718L98.7355 18.4306C98.677 18.5439 98.6152 18.6606 98.5533 18.7807Z"
          fill="#1D232A"
        />
        <path
          d="M99.2473 25.9606L98.8864 26.4446L98.553 18.8012C98.8761 18.7223 99.1855 18.6639 99.4777 18.6227L101.468 24.7045C100.402 25.4321 99.347 25.9091 99.2473 25.9606Z"
          fill="#1D232A"
        />
        <path
          d="M98.4295 32.3236L90.9564 33.01C90.3892 33.0615 89.8874 32.6462 89.8358 32.0799L87.1408 2.87596C87.0892 2.30966 87.5052 1.80857 88.0724 1.75708L95.5455 1.07066C96.1127 1.01918 96.6145 1.43446 96.6661 2.00076L99.3611 31.2047C99.4161 31.771 98.9967 32.2721 98.4295 32.3236Z"
          fill="#42EC9A"
        />
        <path
          d="M98.4293 32.3236L90.9562 33.0101C90.389 33.0615 89.8871 32.6463 89.8355 32.08L88.0137 12.3555L98.1027 17.5518L99.3643 31.2048C99.4159 31.7711 98.9965 32.2722 98.4293 32.3236Z"
          fill="#1D232A"
        />
        <path
          d="M110.003 31.2598L96.5319 32.4988C95.9647 32.5502 95.4628 32.1349 95.4112 31.5686L92.7162 2.36469C92.6647 1.79839 93.0806 1.29729 93.6478 1.24581L107.119 0.00681555C107.687 -0.0446663 108.188 0.370621 108.24 0.936922L110.935 30.1409C110.99 30.7072 110.571 31.2083 110.003 31.2598Z"
          fill="#00A868"
        />
        <path
          d="M95.0937 5.14115L106.123 4.12597L108.27 27.3823L97.241 28.3974L95.0937 5.14115Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <path d="M104.524 3.76927L94.5488 4.68738L96.7881 28.9395L106.763 28.0213L104.524 3.76927Z" fill="#42EC9A" />
        <path
          d="M101.183 3.10255C101.544 3.10255 101.836 2.81059 101.836 2.45044C101.836 2.0903 101.544 1.79834 101.183 1.79834C100.822 1.79834 100.53 2.0903 100.53 2.45044C100.53 2.81059 100.822 3.10255 101.183 3.10255Z"
          fill="#1D232A"
        />
        <path
          d="M89.1583 34.5134C89.0861 32.5125 88.323 26.4205 92.1008 24.5568C94.3249 23.4586 95.4387 23.1874 97.0062 22.5422C97.7143 22.2505 98.3983 21.8352 99.1615 21.8455C99.9246 21.8558 101.076 22.5525 101.203 23.8567C101.272 24.5637 101.083 25.2947 100.636 25.8473C99.5499 27.1859 98.364 27.9993 97.3086 28.3768C100.017 28.3768 102.056 31.5893 102.056 33.7961"
          fill="#FFCBD9"
        />
        <path d="M106.899 52.8821C109.728 50.7062 110.086 49.903 112.238 46.6871Z" fill="#FFCBD9" />
        <path
          d="M106.899 53.397C106.745 53.397 106.593 53.3283 106.49 53.1979C106.315 52.9714 106.36 52.6488 106.583 52.4771C109.027 50.5963 109.563 49.7898 111.148 47.3942C111.351 47.0887 111.568 46.7626 111.808 46.4023C111.966 46.1655 112.286 46.1037 112.523 46.2616C112.76 46.4194 112.822 46.7386 112.664 46.9754C112.423 47.3324 112.207 47.6619 112.008 47.9639C110.395 50.3973 109.794 51.3068 107.212 53.294C107.119 53.3626 107.009 53.397 106.899 53.397Z"
          fill="#1D232A"
        />
        <path
          d="M170.961 137.594C170.779 137.374 170.452 137.347 170.235 137.529L146.458 157.267L137.483 146.49C137.301 146.27 136.974 146.243 136.758 146.425C136.541 146.607 136.51 146.933 136.692 147.149L145.998 158.321C146.101 158.444 146.245 158.506 146.393 158.506C146.51 158.506 146.627 158.468 146.723 158.386L170.895 138.318C171.112 138.14 171.143 137.814 170.961 137.594Z"
          fill="#1D232A"
        />
        <path
          d="M110.983 137.53L87.2059 157.268L78.2306 146.491C78.0484 146.271 77.7219 146.244 77.5053 146.426C77.2853 146.608 77.2578 146.934 77.44 147.15L86.7453 158.322C86.8313 158.428 86.9584 158.493 87.0959 158.503C87.1131 158.503 87.1269 158.507 87.1441 158.507C87.2644 158.507 87.3813 158.466 87.4741 158.387L111.647 138.319C111.867 138.137 111.894 137.811 111.712 137.595C111.526 137.379 111.203 137.348 110.983 137.53Z"
          fill="#1D232A"
        />
        <path
          d="M345.393 255.189H0.515625C0.230313 255.189 0 254.959 0 254.674C0 254.389 0.230313 254.159 0.515625 254.159H345.393C345.679 254.159 345.909 254.389 345.909 254.674C345.909 254.959 345.675 255.189 345.393 255.189Z"
          fill="#1D232A"
        />
        <path
          d="M194.514 219.756L195.106 217.343C195.174 217.068 195.006 216.787 194.727 216.722C194.452 216.653 194.171 216.821 194.105 217.099L193.473 219.666C190.853 219.608 188.32 220.641 186.632 222.477C185.25 223.984 184.611 225.858 184.879 227.619C185.295 230.323 187.55 231.494 190.31 232.537L187.227 245.092C183.789 244.58 181.692 241.714 181.631 239.065C181.624 238.78 181.383 238.557 181.105 238.564C180.819 238.571 180.596 238.807 180.603 239.089C180.675 242.154 183.061 245.469 186.983 246.097L186.388 248.513C186.319 248.788 186.488 249.069 186.766 249.135C186.807 249.145 186.849 249.148 186.89 249.148C187.12 249.148 187.333 248.991 187.392 248.757L188.021 246.19C188.158 246.197 188.296 246.204 188.433 246.204C191.403 246.204 194.311 244.93 196.144 242.785C197.577 241.114 198.137 239.188 197.725 237.359C196.948 233.913 194.446 232.956 191.534 231.885L194.27 220.748C196.756 221.084 198.299 222.776 198.928 224.307C199.035 224.571 199.337 224.694 199.602 224.588C199.866 224.482 199.99 224.18 199.884 223.915C198.956 221.681 196.934 220.13 194.514 219.756ZM185.9 227.468C185.676 226.016 186.223 224.451 187.392 223.177C188.832 221.609 190.977 220.706 193.222 220.696L190.561 231.531C187.711 230.436 186.202 229.441 185.9 227.468ZM196.718 237.596C197.058 239.099 196.573 240.705 195.36 242.126C193.686 244.083 191.001 245.229 188.268 245.181L191.283 232.904C193.968 233.889 196.071 234.73 196.718 237.596Z"
          fill="#1D232A"
        />
        <path
          d="M144.595 175.176L145.245 172.519C145.313 172.245 145.141 171.963 144.866 171.898C144.591 171.829 144.31 172.001 144.244 172.276L143.553 175.09C140.717 175.018 137.974 176.133 136.145 178.124C134.657 179.744 133.97 181.762 134.262 183.656C134.712 186.574 137.16 187.83 140.154 188.959L136.805 202.626C133.038 202.087 130.738 198.957 130.67 196.06C130.663 195.775 130.422 195.549 130.144 195.559C129.858 195.566 129.635 195.803 129.642 196.088C129.721 199.4 132.309 202.983 136.561 203.638L135.908 206.302C135.84 206.576 136.011 206.858 136.286 206.923C136.328 206.933 136.369 206.937 136.41 206.937C136.64 206.937 136.854 206.779 136.912 206.545L137.603 203.734C137.761 203.741 137.919 203.752 138.077 203.748C141.285 203.748 144.42 202.372 146.4 200.059C147.94 198.257 148.545 196.184 148.098 194.221C147.255 190.5 144.54 189.474 141.381 188.314L144.354 176.181C147.084 176.535 148.778 178.385 149.469 180.063C149.576 180.327 149.878 180.451 150.143 180.344C150.408 180.238 150.531 179.936 150.425 179.672C149.418 177.238 147.221 175.564 144.595 175.176ZM135.283 183.499C135.039 181.913 135.63 180.207 136.905 178.817C138.483 177.098 140.841 176.113 143.302 176.113L140.401 187.947C137.692 186.924 135.644 185.853 135.283 183.499ZM147.084 194.444C147.455 196.084 146.929 197.838 145.605 199.386C143.773 201.528 140.831 202.777 137.844 202.708L141.126 189.319C144.065 190.397 146.372 191.307 147.084 194.444Z"
          fill="#1D232A"
        />
        <path
          d="M224.881 136.08C223.915 133.874 221.877 132.381 219.467 132.065L219.997 129.666C220.058 129.388 219.883 129.113 219.605 129.051C219.326 128.99 219.051 129.165 218.989 129.443L218.422 132C215.823 132.003 213.335 133.084 211.705 134.948C210.368 136.472 209.777 138.346 210.086 140.089C210.561 142.763 212.822 143.871 215.583 144.843L212.816 157.353C209.399 156.924 207.257 154.133 207.137 151.511C207.123 151.226 206.882 151.007 206.597 151.02C206.312 151.034 206.092 151.274 206.106 151.559C206.247 154.597 208.687 157.83 212.592 158.365L212.059 160.771C211.997 161.049 212.173 161.324 212.451 161.385C212.489 161.392 212.527 161.399 212.561 161.399C212.798 161.399 213.012 161.234 213.063 160.994L213.627 158.441C213.682 158.441 213.74 158.444 213.795 158.444C216.827 158.444 219.787 157.099 221.605 154.875C222.987 153.183 223.499 151.257 223.049 149.455C222.197 146.058 219.694 145.162 216.782 144.17L219.237 133.074C221.702 133.352 223.269 134.989 223.929 136.492C224.042 136.753 224.348 136.873 224.61 136.757C224.878 136.647 224.995 136.341 224.881 136.08ZM211.104 139.911C210.849 138.476 211.351 136.914 212.482 135.627C213.871 134.042 215.975 133.101 218.195 133.036L215.81 143.83C212.97 142.811 211.451 141.86 211.104 139.911ZM222.056 149.706C222.427 151.185 221.987 152.791 220.815 154.226C219.199 156.203 216.569 157.397 213.864 157.414L216.569 145.182C219.247 146.099 221.351 146.885 222.056 149.706Z"
          fill="#1D232A"
        />
        <path d="M58.3583 151.741L59.0802 149.092L54.5117 150.667L58.3583 151.741Z" fill="#1D232A" />
        <path
          d="M47.9805 135.771C47.9289 135.771 47.8774 135.765 47.8258 135.747L40.5039 133.475C40.2324 133.39 40.0811 133.101 40.1636 132.83C40.2496 132.559 40.5383 132.408 40.8099 132.49L48.1317 134.762C48.4033 134.848 48.5546 135.136 48.4721 135.408C48.4033 135.631 48.2005 135.771 47.9805 135.771Z"
          fill="#1D232A"
        />
        <path
          d="M52.9478 134.261C52.8962 134.261 52.8447 134.255 52.7931 134.237L45.4712 131.965C45.1997 131.879 45.0484 131.591 45.1309 131.32C45.2134 131.049 45.5056 130.898 45.7772 130.98L53.099 133.252C53.3706 133.338 53.5218 133.626 53.4393 133.898C53.3706 134.121 53.1678 134.261 52.9478 134.261Z"
          fill="#1D232A"
        />
        <path
          d="M114.843 23.2938C114.582 23.2938 114.359 23.0981 114.331 22.8339C114.3 22.5524 114.507 22.2985 114.792 22.2676C117.556 21.9793 120.797 20.7368 122.677 19.2473C122.901 19.0723 123.224 19.1066 123.403 19.3297C123.578 19.5528 123.544 19.8754 123.32 20.0538C121.299 21.6532 117.837 22.9849 114.898 23.2903C114.881 23.2903 114.861 23.2938 114.843 23.2938Z"
          fill="#1D232A"
        />
        <path
          d="M145.602 39.4215C144.983 39.9432 144.231 40.3104 143.416 40.4992C142.972 40.609 142.512 40.6639 142.044 40.6639C141.477 40.6639 140.9 40.585 140.336 40.4168C138.624 39.9054 137.077 38.6389 136.032 36.9023C135.95 36.7719 135.874 36.6415 135.802 36.5042C135.73 36.3703 135.723 36.2159 135.774 36.0854C135.97 36.1713 136.17 36.2571 136.366 36.3394C136.561 36.4218 136.757 36.5042 136.953 36.5831C136.995 36.6003 137.036 36.6174 137.074 36.6346C137.091 36.638 137.105 36.6449 137.118 36.6518C139.16 37.4823 141.243 38.2031 143.357 38.814C142.388 39.2396 141.288 39.3597 140.257 39.1229C139.848 39.0302 139.452 38.8826 139.081 38.687C139.569 39.0199 140.092 39.2739 140.631 39.4318C141.869 39.799 143.223 39.648 144.279 39.0748C144.719 39.195 145.159 39.3116 145.602 39.4215Z"
          fill="#1D232A"
        />
        <path d="M89.1585 34.5134C89.0898 32.3717 88.2991 25.8438 92.352 23.8326Z" fill="#FFCBD9" />
        <path
          d="M89.1584 35.0282C88.88 35.0282 88.6531 34.8085 88.6428 34.5305C88.6359 34.3315 88.6222 34.0912 88.6084 33.8201C88.4606 31.0675 88.1512 25.3427 92.1215 23.3727C92.3759 23.2457 92.6853 23.3487 92.8125 23.6026C92.9397 23.8566 92.8365 24.1655 92.5821 24.2925C89.2168 25.9639 89.5022 31.2323 89.6397 33.7652C89.6534 34.0432 89.6672 34.2903 89.674 34.4996C89.6843 34.7845 89.4609 35.0213 89.1756 35.0316C89.1687 35.0282 89.1618 35.0282 89.1584 35.0282Z"
          fill="#1D232A"
        />
      </g>
      <defs>
        <clipPath id="clip0_2314_77064">
          <rect width="345.909" height="255.189" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
