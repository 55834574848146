export function PinBlockedLockerIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={117} height={162} viewBox="0 0 117 162" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M101.583 38.865C100.393 17.248 82.233.011 60.09 0 37.169-.011 18.517 18.445 18.507 41.134l-.026 63.01 83.143.033.026-63.01c0-.769-.022-1.54-.067-2.302zm-65.649 48.02l.019-45.744c.007-13.168 10.824-23.882 24.13-23.874 13.304.007 24.129 10.727 24.121 23.892l-.018 45.744-48.252-.018z"
        fill="#00A868"
      />
      <path
        d="M101.65 38.856l-.026 65.323-83.143-.033.022-50.303 17.45-12.7-.019 45.744 48.252.018.015-32.802 17.382-15.236.067-.01z"
        fill="#1D232A"
      />
      <path
        d="M116.097 59.186l-.04 100.511c0 1.266-1.047 2.301-2.326 2.301l-111.406-.044c-1.279 0-2.325-1.035-2.325-2.301l.04-100.51c0-1.278 1.047-2.302 2.326-2.302l111.406.047c1.283-.003 2.329 1.021 2.325 2.298z"
        fill="#00A868"
      />
      <path
        d="M116.101 56.883l-.044 102.813c0 1.266-1.047 2.301-2.326 2.301l-25.294-.011.044-105.114 27.62.011z"
        fill="#00A868"
      />
      <path
        d="M116.086 90.662l-.03 69.03c0 1.266-1.046 2.301-2.325 2.301l-25.294-.011.018-42.795 27.631-28.525zM88.456 112.339l27.63-26.44.004-8.144-27.63 25.177-.004 9.407zM57.405 130.769l-25.982-.011a1.16 1.16 0 01-1.094-1.544l4.784-13.164 2.236-6.147c-3.756-2.36-6.244-6.528-6.24-11.259v-.102c.063-7.322 6.066-13.224 13.471-13.22.592 0 1.176.037 1.745.117.592.08 1.176.198 1.734.333a13.745 13.745 0 014.326 2.016c2.78 1.958 4.776 4.94 5.427 8.383.151.805.233 1.635.233 2.488-.004 4.533-2.285 8.529-5.775 10.944l6.237 19.674c.236.735-.322 1.492-1.102 1.492z"
        fill="#1D232A"
      />
      <path
        d="M88.433 160.255a.55.55 0 01-.555-.549l.037-90.994c0-.303.248-.548.555-.548.307 0 .554.245.554.548l-.037 90.998a.55.55 0 01-.554.545z"
        fill="#1D232A"
      />
    </svg>
  )
}
