export function CellphoneIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="106" height="127" viewBox="0 0 106 127" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_7630_35747)">
        <path
          d="M105.645 29.6L101.457 46.7375L100.6 50.2375L100.145 52.1025L84.0374 117.992C82.5074 124.24 76.2074 128.065 69.9599 126.535L24.1199 115.327C17.8724 113.805 14.0474 107.502 15.5774 101.255L37.1849 12.8676C38.7099 6.62256 45.0099 2.79506 51.2574 4.32006L75.2399 10.1851L97.0974 15.5276C103.345 17.0575 107.17 23.3575 105.645 29.6Z"
          fill="#1D232A"
        />
        <path
          d="M105.645 29.6001L101.457 46.7376L78.7375 26.1526L68.8375 17.1751C70.025 16.3601 72.42 13.6426 75.2375 10.1876L97.0975 15.5276C103.345 17.0576 107.17 23.3576 105.645 29.6001Z"
          fill="#42EC9A"
        />
        <path
          d="M100.6 50.2351L100.145 52.1001C93.5926 46.2251 87.0426 40.3501 80.4901 34.4751C80.1501 34.1626 80.2651 33.5401 80.5776 33.2676C80.9626 32.9476 81.4376 33.0451 81.7851 33.3551C83.9576 35.3126 86.1476 37.2701 88.3201 39.2226C92.4101 42.8901 96.5026 46.5601 100.6 50.2351Z"
          fill="#42EC9A"
        />
        <path
          d="M57.2701 123.43L7.62254 111.293C2.27504 109.985 -0.999962 104.59 0.307541 99.2426L22.7101 7.60256C24.0176 2.25505 29.4126 -1.01995 34.7601 0.287548L84.4076 12.4251C89.7551 13.7326 93.0301 19.1276 91.7251 24.4751L69.3226 116.115C68.0126 121.463 62.6176 124.738 57.2701 123.43Z"
          fill="#00A868"
        />
        <path d="M63.03 107.696L9.77295 94.6753L29.0259 15.9287L82.2829 28.9497L63.03 107.696Z" fill="#42EC9A" />
        <path
          d="M29.3934 16.5338L10.3779 94.309L16.3981 95.7809L35.4136 18.0057L29.3934 16.5338Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <path
          d="M57.6578 16.6701C56.3144 16.6701 55.2253 15.581 55.2253 14.2376C55.2253 12.8941 56.3144 11.8051 57.6578 11.8051C59.0013 11.8051 60.0903 12.8941 60.0903 14.2376C60.0903 15.581 59.0013 16.6701 57.6578 16.6701Z"
          fill="#1D232A"
        />
      </g>
      <defs>
        <clipPath id="clip0_7630_35747">
          <rect width="105.96" height="126.872" fill="white" transform="matrix(-1 0 0 1 105.98 0)" />
        </clipPath>
      </defs>
    </svg>
  )
}
