export function KeyRejectedIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="10em" height="10em" viewBox="0 0 150 150" fill="none" {...props}>
      <path
        d="M65.088 26.27l-12.82 19.294c-3.19 4.798-1.898 11.284 2.876 14.482L84.615 79.82c4.775 3.205 11.229 1.908 14.412-2.89l12.819-19.293c2.965-4.461 2.084-10.46-2.042-13.864l-13.755-11.36a10.479 10.479 0 00-1.721-1.153l-15.716-8.408c-4.713-2.525-10.56-1.043-13.524 3.419zm15.368 7.146a3.579 3.579 0 014.965-.996l12.977 8.703c1.639 1.099 2.083 3.343.99 4.99l-2.574 3.871a3.579 3.579 0 01-4.966.995l-12.977-8.703c-1.639-1.098-2.083-3.342-.99-4.99l2.575-3.87z"
        fill="#1D232A"
      />
      <path
        d="M79.52 71.44l-14.247-9.561L31 113.472l2.022 6.109 9.814-1.558-.252-2.293 2.062-3.363-2.042-1.372 8.982-3.981-2.029-2.801.683-3.699 4.774-1.462 4.248-3.597-.662-2.059 5.3-7.975 3.135 1.764 3.702-2.526 8.783-13.22z"
        fill="#BBBDBF"
      />
      <path opacity={0.5} d="M70.368 61.172l-37.832 56.947-.799-2.409 36.971-55.65 1.66 1.112z" fill="#1D232A" />
      <path
        d="M66.181 25.646L53.361 44.94c-3.19 4.797-1.898 11.283 2.876 14.482l29.471 19.774c4.774 3.205 11.229 1.908 14.411-2.89l12.82-19.293c2.964-4.462 2.083-10.46-2.042-13.865L97.141 31.782a10.484 10.484 0 00-1.72-1.153l-15.717-8.401c-4.712-2.526-10.559-1.043-13.523 3.418zm15.368 7.145a3.579 3.579 0 014.965-.995l12.977 8.703c1.639 1.098 2.083 3.342.99 4.99l-2.575 3.87a3.579 3.579 0 01-4.965.996l-12.977-8.703c-1.64-1.098-2.083-3.343-.99-4.99l2.575-3.87z"
        fill="#BBBDBF"
      />
      <path
        d="M77.86 73.931l-9.5-5.134-34.465 51.505 1.981 6.156 9.876-1.654-.232-2.313 2.104-3.418-2.042-1.373 9.056-4.104-2.015-2.814.718-3.748 4.808-1.523 4.296-3.672-.649-2.073 5.389-8.113 3.142 1.757 3.736-2.58L77.86 73.93z"
        fill="#4A4F55"
      />
      <g filter="url(#prefix__filter0_d_10114_46910)">
        <path
          d="M96.49 129c12.985 0 23.51-10.577 23.51-23.625S109.475 81.75 96.49 81.75c-12.983 0-23.509 10.577-23.509 23.625S83.507 129 96.491 129z"
          fill="#4A4F55"
        />
      </g>
      <path
        d="M107.771 113.203a2.49 2.49 0 010 3.507 2.456 2.456 0 01-1.745.726 2.452 2.452 0 01-1.745-.726l-7.79-7.828-7.792 7.828a2.454 2.454 0 01-1.745.726 2.454 2.454 0 01-1.745-.726 2.49 2.49 0 010-3.507L93 105.375l-7.792-7.83a2.49 2.49 0 010-3.507 2.46 2.46 0 013.49 0l7.792 7.83 7.79-7.83a2.46 2.46 0 013.49 0 2.49 2.49 0 010 3.507l-7.79 7.83 7.79 7.828z"
        fill="#FCFCFC"
      />
      <defs>
        <filter
          id="prefix__filter0_d_10114_46910"
          x={69.981}
          y={80.75}
          width={53.019}
          height={53.25}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={1.5} />
          <feColorMatrix values="0 0 0 0 0.290196 0 0 0 0 0.309804 0 0 0 0 0.333333 0 0 0 0.32 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_10114_46910" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_10114_46910" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
