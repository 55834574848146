export function ServingCustomerIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={236} height={208} fill="none" {...props}>
      <g clipPath="url(#prefix__clip0_14730_94577)">
        <path
          d="M200.834 69.921a.384.384 0 01-.332-.58l3.244-5.596a.384.384 0 01.528-.139.384.384 0 01.139.529l-3.244 5.596a.392.392 0 01-.335.19z"
          fill="#1D232A"
        />
        <path d="M200.713 61.238c.085.464 1.921 8.157 1.921 8.157h-19.023v-6.33l17.102-1.827z" fill="#8F5049" />
        <path
          d="M193.487 69.168a.387.387 0 01-.35-.555l1.338-2.801-1.478 1.69a.387.387 0 01-.611-.472l.477-.697a.39.39 0 01-.322-.57l1.049-1.95a.387.387 0 01.717.1l.728-.753a.388.388 0 01.631.108l.083-.093a.386.386 0 01.639.42l-2.555 5.349a.375.375 0 01-.346.224z"
          fill="#1D232A"
        />
        <path d="M192.897 66.157l2.457-2.948s-3.082.275-3.082.306c0 .032.625 2.642.625 2.642z" fill="#1D232A" />
        <path
          d="M132.911 37.676s-13.144 31.797-13.01 32.382c.037.16 56.319 15.619 56.319 15.619h.003a4.33 4.33 0 005.168-3.173l6.127-18.028 7.947-23.382a9.872 9.872 0 00-3.133-1.615l-.008-.002c-1.101-.276-2.408-.604-3.827-.962l-.851-.212c-3.666-.918-7.942-1.986-11.377-2.833a61985.21 61985.21 0 00-16.204-3.993c-.08-.018-.16-.04-.238-.057l-.453-.114-.818-.2h-.005l-.356-.089c-.057-.012-.111-.028-.168-.04-.11-.03-.214-.052-.312-.078-.294-.073-.582-.142-.869-.214-5.936-1.486-10.013-2.307-14.234-1.063-2.055.603-3.992 2.064-6.081 4.133-1.144 1.137-2.338 2.458-3.62 3.921z"
          fill="#F1BD38"
        />
        <path
          d="M156.846 30.685c-2.047 9.899 11.826 13.466 19.426 4.785-1.3-.32-2.94-.725-4.712-1.16-2.485-.612-5.229-1.288-7.669-1.892l-.1-.023a7175.76 7175.76 0 01-6.945-1.71z"
          fill="#8F5049"
        />
        <path
          d="M179.635 44.724a9.902 9.902 0 00-.772 5.392l2.21 15.077 6.445-.715 15.113-1.677-3.566-16.044a9.864 9.864 0 00-3.6-5.663 9.876 9.876 0 00-3.133-1.615l-.008-.002a9.652 9.652 0 00-.495-.14l-3.332-.823-.85-.211c-3.492.513-6.669 3.418-8.012 6.421z"
          fill="#F1BD38"
        />
        <path
          d="M162.945 32.188c-.008.073-.01.137-.008.2 0 .469.153.936.341 1.361.688 1.556 2.104 2.766 3.865 2.73 1.723-.036 3.144-1.102 4.415-2.167-2.486-.612-5.23-1.288-7.669-1.891a.237.237 0 00-.072.129l-.021.007a1.714 1.714 0 01-.008-.16 76.968 76.968 0 00-.843-.209zm2.159 3.096c.11.062.224.114.345.158.725.252 1.522.25 2.277.167a8.12 8.12 0 001.661-.358c-.003 0-.003.002-.005.002-1.228.71-2.785 1.012-4.044.199a4.69 4.69 0 01-.234-.168zm-.289-.872c.425.302.938.42 1.452.478a9.69 9.69 0 002.795-.09 7.573 7.573 0 01-1.395.28c-.655.073-1.369.099-2.001-.123a2.344 2.344 0 01-.851-.545zm.423-1.034c-.096-.026-.189-.054-.281-.08l-.047-.06c.111.042.222.08.335.122-.002.008-.005.013-.007.018zm-.795.598a.314.314 0 00.171-.139c.033.023.069.046.108.07a.296.296 0 00-.09.307 3.523 3.523 0 01-.189-.238z"
          fill="#1D232A"
        />
        <path
          d="M191.36 20.961a5.029 5.029 0 01-1.432 2.58 4.86 4.86 0 01-2.003 1.16 4.665 4.665 0 01-2.275.086l-5.962-1.267.328-1.563.059-.281 1.653-7.797 5.962 1.264a4.67 4.67 0 011.091.377c1.944.952 3.058 3.17 2.579 5.441z"
          fill="#8F5049"
        />
        <path
          d="M187.925 24.702a4.665 4.665 0 01-2.275.085l-5.962-1.267.328-1.563.059-.282 4.784-2.479 3.066 5.506z"
          fill="#1D232A"
        />
        <path
          d="M187.502 15.102a14.11 14.11 0 01-1.328 5.06c-3.252 6.856-11.274 9.774-17.922 6.51-6.648-3.26-9.399-11.462-6.153-18.321 1.692-3.574 4.681-6.076 8.095-7.19a12.977 12.977 0 019.827.678c5.06 2.48 7.865 7.825 7.481 13.263z"
          fill="#8F5049"
        />
        <path
          d="M92.807 19.795v36.167c0 .384.312.694.694.694h61.435a.694.694 0 00.693-.694V19.795a.694.694 0 00-.693-.694H93.503a.696.696 0 00-.696.694z"
          fill="#00A868"
        />
        <path d="M92.807 24.268v12.646l60.95 18.846V43.114l-60.95-18.846z" fill="#42EC9A" />
        <path d="M127.248 41.161v25.376h4.25V41.445a.283.283 0 00-.284-.284h-3.966z" fill="#1D232A" />
        <path d="M127.375 41.161H114.77v25.376h12.605V41.16z" fill="#00A868" />
        <path
          d="M109.788 69.395h27.234v-3.37a.489.489 0 00-.488-.487h-26.259a.489.489 0 00-.487.488v3.369z"
          fill="#00A868"
        />
        <path
          d="M187.502 15.102l-1.304-.317a3.376 3.376 0 01-2.442-4.254l.649-2.73c-8.553-1.357-12.493-3.601-14.208-6.64a12.977 12.977 0 019.827.678c5.057 2.48 7.863 7.825 7.478 13.263zM151.888 42.536v14.122h3.05a.694.694 0 00.694-.694v-12.27l-3.744-1.158z"
          fill="#1D232A"
        />
        <path d="M151.888 40.596v.57l-59.08-17.94v-.565l59.08 17.935z" fill="#42EC9A" />
        <path
          d="M193.872 69.395h-17.775l-.609-3.854h-.005a13.802 13.802 0 01-1.96-.606c-5.949-2.369-3.765-7.415-3.069-7.835-2.385-2.843.485-6.277.485-6.277s-1.568-1.729-.046-4.084c3.027-4.68 10.284-3.648 10.284-3.648s-.036-1.615.717-4.035c.734-2.35 3.233-5.678 5.771-5.036 3.097.79 1.73 4.164 1.73 4.164-1.632 4.494-1.052 8.733-.034 12.497l4.511 18.714z"
          fill="#8F5049"
        />
        <path
          d="M170.424 57.664a.388.388 0 01-.256-.678c2.525-2.23 6.236-2.451 7.713-2.438a.386.386 0 01.384.39.388.388 0 01-.386.384c-.031.002-.06 0-.091 0-1.444 0-4.83.232-7.107 2.244a.378.378 0 01-.257.098zM171.032 51.035a.386.386 0 01-.237-.691c2.45-1.89 5.273-2.54 8.626-1.992a.386.386 0 01-.124.764c-3.133-.512-5.758.092-8.03 1.841a.388.388 0 01-.235.078zM175.486 65.538a13.815 13.815 0 01-1.96-.606l1.601-1.819.359 2.425z"
          fill="#1D232A"
        />
        <path d="M236.003 68.828H64.197v97.485h171.806V68.828z" fill="#E8E9EA" />
        <path
          d="M226.616 108.049l9.384-6.653V68.828h-9.466l.082 39.221zM235.966 166.312H96.196v8.615h139.77v-8.615z"
          fill="#BBBDBF"
        />
        <path
          d="M217.756 125.378v18.242a.547.547 0 01-.547.547h-28.118a.549.549 0 01-.549-.547v-18.242a.91.91 0 01.912-.911h27.389a.908.908 0 01.913.911z"
          fill="#42EC9A"
        />
        <path d="M217.756 133.551v4.796l-1.439 1.478v-6.274h1.439z" fill="#1D232A" />
        <path
          d="M189.088 144.167h28.121a.547.547 0 00.547-.547v-.941h-29.214v.941c0 .302.244.547.546.547zM187.647 140.364h28.12a.547.547 0 00.547-.547v-18.239a.913.913 0 00-.913-.913h-27.388a.913.913 0 00-.913.913v18.239c0 .302.245.547.547.547z"
          fill="#00A868"
        />
        <path
          d="M187.647 140.365h28.12a.547.547 0 00.547-.547v-1.043H187.1v1.043c0 .302.245.547.547.547z"
          fill="#008E5A"
        />
        <path
          d="M226.616 108.049h-29.237a.853.853 0 01-.854-.854v-33.54c0-.472.382-.854.854-.854h29.237v35.248z"
          fill="#00A868"
        />
        <path d="M220.154 84.72l2.143-2.258h-2.143v2.257z" fill="#1D232A" />
        <path
          d="M220.639 84.768a.385.385 0 01-.227-.7l3.171-2.292a.384.384 0 01.539.087.384.384 0 01-.087.54l-3.172 2.293a.38.38 0 01-.224.072z"
          fill="#1D232A"
        />
        <path
          d="M229.821 102.417h-29.015a1.077 1.077 0 01-1.076-1.076V68.244c0-.593.483-1.076 1.076-1.076h29.015v35.249z"
          fill="#42EC9A"
        />
        <path
          d="M200.832 100.714h28.489v1.203h-28.489a.601.601 0 110-1.203zM197.379 104.23h28.737v3.407h-28.737a.353.353 0 01-.354-.354v-2.699c0-.196.158-.354.354-.354z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <path d="M219.148 103.795l1.898 4.344h-2.019l.121-4.344z" fill="#E8E9EA" />
        <path
          d="M177.291 80.37c-.74 3.438-3.077 8.967-3.554 12.223l-.554 6.145v.003l-2.262 25.13c-.247 1.677-1.686 3.036-3.22 3.036h-9.217l.122-17.834.245-36.097h12.759c3.734-.003 6.279 3.31 5.681 7.394z"
          fill="#00A868"
        />
        <path
          d="M165.266 102.128l7.254 3.981-1.599 17.757c-.247 1.677-1.686 3.036-3.22 3.036h-9.217l6.782-24.774z"
          fill="#1D232A"
        />
        <path
          d="M165.305 123.394l.07-50.42h-13.747c-3.404 0-6.491 3.314-6.514 6.99l-.029 44.382c0 1.473 1.004 2.556 2.368 2.556h14.595c1.707.003 3.254-1.666 3.257-3.508z"
          fill="#42EC9A"
        />
        <path d="M163.092 72.973h8.794c-3.404 0-6.491 3.315-6.514 6.992l-2.759-3.71.479-3.282z" fill="#42EC9A" />
        <path
          d="M163.433 122.537l-.059-7.907c0-.678-.508-1.179-1.194-1.179h-13.593c-.848 0-1.614.756-1.614 1.595l.059 7.907c0 .678.508 1.179 1.194 1.179h13.593c.851 0 1.614-.754 1.614-1.595zM163.299 88.708h-16.406a.387.387 0 110-.774h16.406a.387.387 0 110 .774z"
          fill="#1D232A"
        />
        <path
          d="M162.167 124.034h-14.18c-.64 0-1.161-.519-1.161-1.161v-8.263c0-.64.518-1.161 1.161-1.161h14.18c.639 0 1.16.518 1.16 1.161v8.263c0 .642-.521 1.161-1.16 1.161z"
          fill="#1D232A"
        />
        <path
          d="M162.167 111.589h-14.18a.661.661 0 01-.661-.661V91.259c0-.365.296-.66.661-.66h14.18a.66.66 0 01.66.66v19.669a.66.66 0 01-.66.661z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <path
          d="M163.706 84.154l.212-5.448c.049-1.239-1.011-2.186-2.445-2.186h-11.735c-1.635 0-3.097 1.22-3.152 2.635"
          fill="#42EC9A"
        />
        <path
          d="M163.706 84.541h-.015a.388.388 0 01-.372-.402l.212-5.449a1.555 1.555 0 00-.444-1.163c-.379-.395-.967-.622-1.614-.622h-11.736c-1.426 0-2.717 1.058-2.764 2.262a.388.388 0 01-.774-.031c.065-1.63 1.684-3.005 3.538-3.005h11.736c.869 0 1.64.304 2.171.859a2.34 2.34 0 01.658 1.729l-.212 5.448a.383.383 0 01-.384.374z"
          fill="#00A868"
        />
        <path
          d="M158.131 99.827h-1.712a.231.231 0 01-.232-.232v-1.713c0-.129.103-.232.232-.232h1.712c.129 0 .232.103.232.232v1.713a.233.233 0 01-.232.232zM156.952 103.289h-.569a.076.076 0 01-.078-.077v-.57c0-.044.034-.078.078-.078h.569c.044 0 .078.034.078.078v.57a.076.076 0 01-.078.077zM156.952 104.742h-.569a.077.077 0 01-.078-.078v-.57c0-.044.034-.077.078-.077h.569c.044 0 .078.033.078.077v.57a.077.077 0 01-.078.078zM158.405 103.289h-.57a.076.076 0 01-.078-.077v-.57c0-.044.034-.078.078-.078h.57c.043 0 .077.034.077.078v.57a.076.076 0 01-.077.077zM157.68 104.017h-.57a.076.076 0 01-.077-.077v-.571c0-.043.033-.077.077-.077h.57c.044 0 .077.034.077.077v.571a.079.079 0 01-.077.077zM153.131 104.742h-1.712a.23.23 0 01-.232-.232v-1.713c0-.129.103-.233.232-.233h1.712c.129 0 .232.104.232.233v1.713a.23.23 0 01-.232.232zM153.131 99.827h-1.712a.231.231 0 01-.232-.232v-1.713c0-.129.103-.232.232-.232h1.712c.129 0 .232.103.232.232v1.713a.233.233 0 01-.232.232zM159.732 101.582h-9.827a.387.387 0 110-.774h9.827a.388.388 0 010 .774z"
          fill="#1D232A"
        />
        <path
          d="M154.817 106.496a.386.386 0 01-.386-.387v-9.832a.386.386 0 11.773 0v9.832a.387.387 0 01-.387.387z"
          fill="#1D232A"
        />
        <path
          d="M159.266 106.499h-8.897a.855.855 0 01-.853-.854v-8.903c0-.47.381-.854.853-.854h8.897c.469 0 .853.381.853.854v8.903a.855.855 0 01-.853.854zm-8.897-9.835a.08.08 0 00-.08.08v8.903c0 .044.036.08.08.08h8.897a.08.08 0 00.08-.08v-8.903a.08.08 0 00-.08-.08h-8.897z"
          fill="#1D232A"
        />
        <path d="M163.706 116.395H146.47v.773h17.236v-.773z" fill="#42EC9A" />
        <path d="M152.713 113h-.774v11.746h.774V113zM158.214 113h-.774v11.746h.774V113z" fill="#42EC9A" />
        <path d="M157.827 120.342H146.47v.774h11.357v-.774zM216.314 122.019H187.1v.774h29.214v-.774z" fill="#42EC9A" />
        <path
          d="M190.481 136.608h28.121a.547.547 0 00.546-.547v-18.24a.913.913 0 00-.913-.913h-27.39a.913.913 0 00-.913.913v18.24a.55.55 0 00.549.547z"
          fill="#008E5A"
        />
        <path
          d="M190.481 136.608h28.12a.547.547 0 00.547-.547v-1.006h-29.214v1.006c0 .302.245.547.547.547z"
          fill="#1D232A"
        />
        <path d="M219.148 118.415h-29.214v.774h29.214v-.774z" fill="#F1BD38" />
        <path d="M227.57 97.392l2.252-2.268V67.166h-8.049l5.797 30.226z" fill="#1D232A" />
        <path
          d="M227.534 60.5v35.138h-26.522v-34.71a.43.43 0 01.428-.428h.751c.113 0 .221.044.301.126l.165.166c.083.082.22.082.302 0l.165-.166a.427.427 0 01.302-.126h24.108z"
          fill="#00A868"
        />
        <path
          d="M203.45 94.46h23.584v2.432H203.45l-.14-.14a.718.718 0 00-1.006-.006h0l-.005.006-.14.14h-.652V94.46h.647l.142.143c.281.28.73.273 1.006.005h0l.006-.005.142-.143z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <path d="M218.955 110.931l2.091-2.792h-2.019l-.072 2.792z" fill="#1D232A" />
        <path
          d="M219.148 102.087v8.436c0 .33-.268.598-.598.598h-12.878a.598.598 0 01-.598-.598v-8.436a.6.6 0 01.598-.599h12.878c.191 0 .361.091.467.23.085.1.131.229.131.369z"
          fill="#42EC9A"
        />
        <path d="M217.226 101.988l-3.717 8.633h-.559l3.908-8.633h.368z" fill="#FCFCFC" stroke="#FCFCFC" />
        <mask id="prefix__a" fill="#fff">
          <path d="M219.017 101.715l-4.234 9.404h-.596l4.361-9.633h.005a.59.59 0 01.464.229z" />
        </mask>
        <path d="M219.017 101.715l-4.234 9.404h-.596l4.361-9.633h.005a.59.59 0 01.464.229z" fill="#FCFCFC" />
        <path
          d="M219.017 101.715l.912.411.244-.542-.36-.473-.796.604zm-4.234 9.404v1h.646l.266-.589-.912-.411zm-.596 0l-.911-.412-.639 1.412h1.55v-1zm4.361-9.633v-1h-.645l-.266.587.911.413zm.005 0l.013-1h-.013v1zm-.448-.181l-4.234 9.403 1.824.822 4.234-9.404-1.824-.821zm-3.322 8.814h-.596v2h.596v-2zm.315 1.412l4.361-9.633-1.822-.825-4.361 9.634 1.822.824zm3.45-9.045h.005v-2h-.005v2zm-.009 0a.413.413 0 01-.319-.166l1.593-1.209a1.591 1.591 0 00-1.247-.625l-.027 2z"
          fill="#FCFCFC"
          mask="url(#prefix__a)"
        />
        <path
          d="M204.132 94.384v4.404l.983-.594.928.56v-4.37h-1.911zM202.807 92.018a.387.387 0 01-.387-.387V63.005a.387.387 0 11.774 0V91.63a.387.387 0 01-.387.387z"
          fill="#008E5A"
        />
        <path
          d="M217.568 106.777h-5.898a.387.387 0 110-.774h5.898c.214 0 .386.173.386.387a.385.385 0 01-.386.387z"
          fill="#1D232A"
        />
        <path
          d="M83.266 110.495l-1.044 59.28a8.414 8.414 0 01-8.412 8.302s-49.405-14.719-55.52-16.682c-7.599-2.44-8.447-8.635-7.147-14.385l10.461-41.071c1.357-7.185 5.808-10.714 11.163-10.113 4.165.467 7.41.93 12.93 1.625.227.028.451.057.676.082.374.05.748.096 1.119.142 3.448.434 6.849.86 9.884 1.236l.446.057c.05.005.098.013.147.018l1.282.157c.172.02.343.044.513.065 4.363.541 7.636.941 8.618 1.045.013.002.026.005.039.005l2.09.216 2.07.22c.685.072 1.265.131 1.701.178l.89.093c4.76.583 8.094 4.734 8.094 9.53z"
          fill="#00A868"
        />
        <path
          d="M67.789 49.99c1.508-4.892 1.606-11.891-3.744-14.26-4.338-1.92-9.255-1.416-13.869 1.933-5.75 4.174-8.824 7.85-12.429 10.221-7.862 5.17-22.117 7.812-34.266-2.26-4.548 6.651-5.356 35.857 12.966 47.624 26.819-4.476 42.57-14.803 51.342-43.259z"
          fill="#E09661"
        />
        <path
          d="M84.555 57.133a15.044 15.044 0 01-.214 1.819l-.087.49-.02.118-4.4 24.35c-1.426 7.88-8.907 13.267-16.793 11.897-7.947-1.38-13.23-8.967-11.8-16.89l2.182-12.079s6.565-19.777 16.82-20.752c7.365-.702 14.704 3.846 14.313 11.047z"
          fill="#FFCBD9"
        />
        <path
          d="M64.71 67.235h0l4.837 2.155-4.837-2.155zm0 0c-1.07-.475-1.533-1.664-1.104-2.626a1.88 1.88 0 011.105-1.006h0m-.001 3.632l.002-3.633m0 0a2.141 2.141 0 011.584.073h0m-1.584-.072l1.584.072m0 0l4.838 2.155c1.066.475 1.528 1.659 1.1 2.621a1.895 1.895 0 01-1.104 1.01m-4.834-5.785l4.834 5.785m0 0a2.129 2.129 0 01-1.583-.071l1.583.07z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <path
          d="M72.691 68.654a2.395 2.395 0 01-1.392 1.278 2.629 2.629 0 01-1.955-.086l-1.885-.84 1.99-4.477 1.886.841c1.3.58 1.905 2.049 1.356 3.284z"
          fill="#1D232A"
        />
        <path
          d="M84.556 57.133a15.079 15.079 0 01-.214 1.819l-.088.49c-.1-.09-.199-.188-.297-.284-1.34-1.372-2.295-3.26-2.79-5.232.116.648.178 3.042-.91 3.653-.426.243-.947.214-1.387.008-.444-.201-.82-.555-1.153-.94-1.328-1.534-2.2-3.663-2.246-5.814-.872 3.1-3.365 5.343-6.078 6.403-2.71 1.055-5.49.903-8.358.795-.325 2.08-1.596 6.233-2.96 7.438-1.3 1.15-3.046 1.653-4.673 1.367l2.378-13.05c.015-.095.036-.188.054-.28.031-.16.067-.315.103-.473.524-2.208 1.596-4.267 3.04-6.041.01-.01.016-.021.026-.034 3.244-3.803 8.368-5.828 13.636-4.873 7.283 1.313 12.309 7.848 11.917 15.048z"
          fill="#E09661"
        />
        <path
          d="M47.549 76.582l7.084 1.455 2.155-10.507-7.083-1.456a5.362 5.362 0 00-6.331 4.177 5.365 5.365 0 004.175 6.331z"
          fill="#FFCBD9"
        />
        <path
          d="M50.587 74.08a.387.387 0 01-.379-.475 3.245 3.245 0 00-.439-2.51c-.402-.611-.972-1.011-1.604-1.125a.386.386 0 11.137-.76c.843.151 1.593.67 2.114 1.462.599.91.797 2.046.547 3.111a.385.385 0 01-.377.297z"
          fill="#1D232A"
        />
        <path
          d="M92.702 121.454l-9.505-5.812-17.647-10.789-2.2-1.344-1.056-.648-2.393-1.463-1.063-.65-2.086-1.274-.307-.189-1.003-.611-.02-.013c.185.028.373.054.556.077.173.026.346.047.516.065a30.33 30.33 0 001.555.147c.606.044 1.204.072 1.792.082.305.01.609.016.91.016.99.005 1.958-.02 2.907-.064.495-.024.985-.05 1.47-.078.853-.049 1.694-.106 2.527-.152 3.67-.212 7.158-.271 10.802.942 4.299 1.429 7.648 4.958 9.82 8.936 2.168 3.981 3.305 8.429 4.425 12.822z"
          fill="#00A868"
        />
        <path
          d="M139.345 87.758l-.144 6.782-.031 1.386-.016.738-.557 26.077a4.476 4.476 0 01-4.572 4.38l-18.133-.386a4.472 4.472 0 01-4.376-4.572l.748-34.978a4.474 4.474 0 014.569-4.38l9.487.203 8.646.184a4.472 4.472 0 014.379 4.566z"
          fill="#1D232A"
        />
        <path
          d="M139.345 87.759l-.144 6.782-10.24-5.836-4.461-2.546c.379-.405 1.049-1.625 1.82-3.155l8.646.183a4.48 4.48 0 014.379 4.572zM139.172 95.926l-.015.738c-2.948-1.659-5.895-3.32-8.843-4.98-.154-.087-.162-.332-.067-.459.119-.152.305-.154.459-.066.978.554 1.963 1.106 2.94 1.658 1.841 1.035 3.683 2.07 5.526 3.109z"
          fill="#42EC9A"
        />
        <path
          d="M128.365 126.998l-18.353-.392a4.477 4.477 0 01-4.378-4.572l.745-34.98a4.477 4.477 0 014.57-4.38l18.352.391a4.477 4.477 0 014.379 4.572l-.746 34.98a4.473 4.473 0 01-4.569 4.381z"
          fill="#00A868"
        />
        <path d="M109.311 119.687l.643-30.166 20.069.428-.643 30.166-20.069-.428z" fill="#FCFCFC" stroke="#FCFCFC" />
        <path d="M112.231 89.069l-2.766-.06-.664 31.167 2.767.059.663-31.166z" fill="#42EC9A" />
        <path d="M120.059 86.928a.936.936 0 100-1.873.936.936 0 000 1.873z" fill="#1D232A" />
        <path
          d="M126.321 111.189h0l-10.773-.194a1.046 1.046 0 01-1.028-1.067h0l.193-10.778s0 0 0 0a1.048 1.048 0 011.067-1.03h0l10.774.192s0 0 0 0 0 0 0 0c.577.01 1.038.487 1.028 1.067h0l-.191 10.777v.001a1.053 1.053 0 01-1.07 1.032z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <path
          d="M125.815 110.561h-.021l-9.853-.211a.903.903 0 01-.882-.924l.211-9.86a.906.906 0 01.921-.882l9.853.211a.892.892 0 01.634.279.897.897 0 01.251.642l-.212 9.86a.903.903 0 01-.902.885zm-9.64-11.103a.13.13 0 00-.128.126l-.212 9.86a.129.129 0 00.126.132l9.854.211a.132.132 0 00.131-.126l.212-9.86a.128.128 0 00-.037-.093.117.117 0 00-.09-.039l-9.853-.211h-.003z"
          fill="#1D232A"
        />
        <path
          d="M124.698 103.188l-1.898-.041a.26.26 0 01-.253-.263l.042-1.899a.258.258 0 01.263-.253l1.898.041a.26.26 0 01.252.263l-.041 1.899a.259.259 0 01-.263.253zM123.311 106.996l-.632-.013a.088.088 0 01-.085-.088l.013-.632c0-.046.041-.085.087-.085l.632.013c.047 0 .085.041.085.088l-.013.632a.088.088 0 01-.087.085zM123.275 108.603l-.632-.012c-.046 0-.085-.042-.085-.088l.013-.632c0-.047.041-.085.088-.085l.631.013c.047 0 .086.041.086.087l-.013.632a.083.083 0 01-.088.085zM124.92 107.03l-.632-.013a.088.088 0 01-.085-.088l.013-.632c0-.046.041-.085.088-.085l.631.013c.047 0 .086.041.086.088l-.013.632a.089.089 0 01-.088.085zM124.097 107.816l-.632-.013a.088.088 0 01-.085-.087l.013-.632c0-.047.042-.085.088-.085l.632.012c.046 0 .085.042.085.088l-.013.632a.084.084 0 01-.088.085zM119.04 108.513l-1.898-.041a.258.258 0 01-.252-.263l.041-1.899a.26.26 0 01.263-.253l1.898.041a.26.26 0 01.253.264l-.042 1.898a.256.256 0 01-.263.253zM119.159 103.067l-1.898-.041a.26.26 0 01-.253-.264l.042-1.898a.258.258 0 01.263-.253l1.898.041a.258.258 0 01.252.263l-.041 1.899a.257.257 0 01-.263.253zM126.439 105.128h-.008l-10.885-.235a.386.386 0 01-.379-.394c.005-.215.183-.393.395-.38l10.884.235a.386.386 0 01-.007.774z"
          fill="#1D232A"
        />
        <path
          d="M120.879 110.455h-.008a.386.386 0 01-.379-.394l.235-10.89a.382.382 0 01.395-.38c.214.006.384.181.379.396l-.235 10.889a.388.388 0 01-.387.379z"
          fill="#1D232A"
        />
        <path
          d="M104.623 155.955l-7.834 35.173-2.68 12.03a5.54 5.54 0 01-2.606 3.576 5.497 5.497 0 01-4.013.632l-64.14-14.298a5.548 5.548 0 01-4.206-6.622L30.2 136.817a3.063 3.063 0 013.657-2.324l66.561 14.839a5.548 5.548 0 014.206 6.623z"
          fill="#42EC9A"
        />
        <path
          d="M101.611 155.73l-69.127-15.412a2.991 2.991 0 011.3-5.841l69.127 15.412a2.993 2.993 0 01-1.3 5.841z"
          fill="#1D232A"
        />
        <path
          d="M78.093 185.31L64.94 146.388l32.758-7.778 13.713 40.576c2.881 8.802-2.29 15.226-11.15 17.912-10.962 3.323-19.854-4.721-22.167-11.788z"
          fill="#FFCBD9"
        />
        <path
          d="M93.12 149.004a.386.386 0 01-.372-.278l-1.779-6.081a.386.386 0 11.743-.217l.933 3.186-.167-5.717a.386.386 0 01.376-.397.378.378 0 01.397.377l.255 8.73a.39.39 0 01-.327.395c-.02.002-.041.002-.06.002z"
          fill="#1D232A"
        />
        <path
          d="M66.788 151.86l-9.778-28.931c-2.88-8.802 1.916-18.273 10.712-21.154 8.796-2.882 17.661 1.233 22.177 10.716l11.227 24.646-34.338 14.723z"
          fill="#00A868"
        />
        <path
          d="M64.844 100.18c-.15.186-.327.395-.36.473l-14.212 37.505-3.698-.83 14.072-37.589c.013.003.026.005.039.005.73.075 1.434.15 2.091.217l2.068.219z"
          fill="#008E5A"
        />
        <path
          d="M64.844 100.18c-.15.186-.327.395-.36.473l-14.212 37.505-3.698-.83 14.072-37.589c.013.003.026.005.039.005.73.075 1.434.15 2.091.217l2.068.219z"
          fill="#1D232A"
        />
        <path
          d="M54.934 124.983a.386.386 0 01-.387-.387v-7.321a.386.386 0 11.774 0v7.321a.386.386 0 01-.387.387zM65.128 98.906c-.224.526-.616 1.684-.645 1.746l-1.132 2.856-7.421 18.712.417-11.001 3.554-9.821.85-2.35c.991.005 1.958-.021 2.907-.065.495-.023.985-.049 1.47-.077z"
          fill="#008E5A"
        />
        <path
          d="M65.128 98.906c-.224.526-.616 1.684-.645 1.746l-1.132 2.856-7.421 18.712.417-11.001 3.554-9.821.85-2.35c.991.005 1.958-.021 2.907-.065.495-.023.985-.049 1.47-.077z"
          fill="#008E5A"
        />
        <path
          d="M100.26 197.098c13.76-3.148 18.378-22.308 20.346-28.9 1.968-6.591 4.523-13.245 9.296-18.201 2.133-2.213 4.675-4.05 6.483-6.537 1.764-2.425 2.672-5.039 2.697-8.036v-.377c.823-1.311 1.437-2.732 1.697-4.249.771-4.507-.851-7.154-.851-7.154 1.186-1.723 2.208-3.601 2.458-5.665.276-2.299-.908-6.146-3.979-5.33-8.66 6.635-20.24 7.695-24.085 7.056 0 0-2.909-.421-3.889-3.811-1.315-4.553-.959-8.217-4.446-11.434-1.235-1.14-5.118-1.981-6.882.717-1.122 1.716-.387 4.889.448 8.826 1.69 7.954 2.1 12.574-.765 22.246-4.727 15.957-15.522 27.146-14.792 33.399"
          fill="#FFCBD9"
        />
        <path
          d="M67.655 98.753l-2.104 6.099-14.098 40.842a1.937 1.937 0 01-2.452 1.215 1.935 1.935 0 01-1.212-2.453l14.505-41.595 1.333-3.826c.01-.018.018-.036.031-.052 1.364-.062 2.692-.155 3.997-.23z"
          fill="#42EC9A"
        />
        <path
          d="M124.484 132.661a.386.386 0 01-.036-.771c5.838-.573 11.934-3.976 15.168-8.468a.385.385 0 01.539-.087.388.388 0 01.088.539c-3.355 4.662-9.673 8.194-15.72 8.784l-.039.003zM128.347 144.923a.387.387 0 01-.211-.712c2.591-1.693 5.271-3.442 7.54-5.65 1.297-1.264 2.305-2.479 3.081-3.718a.391.391 0 01.534-.123.387.387 0 01.121.534c-.807 1.29-1.854 2.554-3.197 3.862-2.324 2.262-5.036 4.032-7.659 5.742a.368.368 0 01-.209.065zM11.349 63.81c-4.453 0-10.01-2.988-11.272-4.655a.387.387 0 01.617-.467c1.232 1.63 7.261 4.66 11.343 4.322a.387.387 0 01.065.771 10.3 10.3 0 01-.753.029zM13.37 81.801c-2.668 0-7.067-.766-8.86-2.162a.389.389 0 01.477-.611c1.764 1.375 6.543 2.108 8.928 1.986a.386.386 0 01.039.772c-.18.01-.377.015-.583.015zM15.416 84.464c-2.342 0-4.598-.279-6.388-.846a.387.387 0 11.232-.738c3.886 1.236 10.06 1.05 14.361-.431a.389.389 0 01.492.24.389.389 0 01-.24.493c-2.465.846-5.53 1.282-8.457 1.282zM48.65 47.043a.388.388 0 01-.346-.56c1.677-3.392 5.021-5.967 8.732-6.715a.387.387 0 01.155.758c-3.479.702-6.617 3.117-8.19 6.3a.389.389 0 01-.351.217zM83.999 170.035h-.013a.387.387 0 01-.374-.4c.212-6.209 2.862-10.345 5.668-14.723 1.766-2.758 3.595-5.609 4.86-9.109a.386.386 0 11.728.263c-1.297 3.581-3.148 6.47-4.938 9.264-2.746 4.288-5.34 8.336-5.547 14.334a.382.382 0 01-.384.371zM61.902 76.443a.387.387 0 01-.028-.774c2.576-.196 3.816-2.386 3.829-2.41a.386.386 0 01.678.371c-.057.107-1.444 2.581-4.448 2.808a.065.065 0 01-.031.005z"
          fill="#1D232A"
        />
        <path
          d="M69.627 81.407a.389.389 0 01-.167-.039c-.028-.01-.078-.023-.14-.041-3.986-1.13-5.02-4.002-5.185-6.21a.387.387 0 01.77-.06c.215 2.857 1.772 4.717 4.627 5.524.155.044.227.065.294.103a.39.39 0 01-.198.722zm-.198-.054l.023.013a.104.104 0 00-.023-.013zM74.14 78.67c-.216 0-.43-.024-.64-.073a6.414 6.414 0 01-1.866-.763.387.387 0 11.4-.664c.53.32 1.082.545 1.64.674.848.193 1.828-.16 2.439-.88.492-.58.63-1.27.376-1.886l-2.034-4.948a.388.388 0 01.717-.294l2.034 4.948c.361.88.176 1.883-.5 2.68-.647.762-1.622 1.205-2.566 1.205zM53.49 66.874a.388.388 0 01-.382-.454l2.453-13.454c.033-.222.925-5.678 5.65-8.583a.385.385 0 01.53.127c.112.183.055.42-.125.53-4.413 2.712-5.284 8.001-5.292 8.053L53.87 66.557a.386.386 0 01-.379.317z"
          fill="#1D232A"
        />
        <path
          d="M66.688 60.78c1.39-1.828 4.348-2.847 6.973-.471l-6.973.472zM80.912 64.33c-.807 1.825-3.102 3.277-5.794 1.796l5.794-1.795z"
          fill="#E09661"
        />
        <path
          d="M93.23 140.547l.8 7.541 4.085-9.703-4.884 2.162zM66.34 146.318a.388.388 0 01-.152-.743l29.332-12.54a.386.386 0 01.508.203.389.389 0 01-.204.509l-29.332 12.54a.354.354 0 01-.152.031zM77.684 71.42c-.106 0-.212-.002-.318-.01-1.142-.075-2.166-.55-2.805-1.303a.385.385 0 01.044-.544.385.385 0 01.544.043c.497.586 1.346.973 2.266 1.032 1.215.08 2.46-.394 3.5-1.333a.385.385 0 01.546.028.385.385 0 01-.028.547c-1.104.998-2.427 1.54-3.75 1.54zM65.365 148.034a.384.384 0 01-.366-.263l-5.795-17.141a.385.385 0 01.243-.49.388.388 0 01.49.242l5.794 17.141a.385.385 0 01-.366.511zM57.376 98.913c-.461.235-1.485.645-3.257.844a9.467 9.467 0 01-3.23-.175 7.197 7.197 0 01-2.206-.919l-.012-.007a6.075 6.075 0 01-1.179-.978c3.448.433 6.85.859 9.884 1.236z"
          fill="#1D232A"
        />
        <path
          d="M58.05 98.95a9.122 9.122 0 01-1.297.524c-1.883.608-3.96.639-5.865.108a9.553 9.553 0 01-2.218-.926 8.825 8.825 0 01-.626-.398.42.42 0 00-.658.382 13.59 13.59 0 01-1.013-1.104c-.224-.026-.449-.054-.676-.082a.408.408 0 00-.11.387c.696 3.209 3.697 5.74 6.977 5.884.103.003.204.005.302.005 2.313 0 4.56-1.171 5.972-2.982.372-.472.684-.988.926-1.538.026-.056.054-.118.077-.177a32.78 32.78 0 01-1.792-.083zm-.973 1.308c-1.315.869-3.043 1.269-4.732 1.016-1.472-.219-2.875-.872-4.185-1.942 2.643 1.566 6 1.88 8.917.926zm-4.477 2.624c-2.197-.098-4.252-1.404-5.394-3.261 1.544 1.372 3.252 2.226 5.015 2.489 1.87.279 3.737-.129 5.199-1.057-1.295 1.199-3.04 1.903-4.82 1.829z"
          fill="#1D232A"
        />
        <path
          d="M47.509 85.487c1.757-2.774 1.727-5.944-.066-7.08-1.792-1.136-4.67.192-6.426 2.966-1.757 2.775-1.727 5.945.065 7.08 1.793 1.137 4.67-.191 6.427-2.966z"
          fill="#F1BD38"
        />
        <path
          d="M208.442 108.735c-.836 0-1.455-.629-1.609-1.22a.386.386 0 11.747-.196c.083.309.449.642.862.642.368 0 .616-.18.722-.348a.442.442 0 00.036-.434c-.145-.307-.359-.376-.833-.51l-.044-.013c-.539-.152-1.042-.333-1.261-.885a1.192 1.192 0 01.126-1.117c.258-.382.717-.609 1.228-.609.508 0 1.003.273 1.294.712a.387.387 0 11-.644.428c-.111-.167-.348-.366-.65-.366-.253 0-.472.1-.588.271a.42.42 0 00-.049.397c.072.183.247.284.75.426l.042.013c.502.142 1.023.286 1.325.926.181.382.15.81-.085 1.176-.276.441-.789.707-1.369.707z"
          fill="#1D232A"
        />
        <path
          d="M208.416 109.192a.387.387 0 01-.387-.387l.008-4.83a.386.386 0 11.773 0l-.008 4.83a.386.386 0 01-.386.387zM208.405 146.956l2.092-2.791h-2.02l-.072 2.791z"
          fill="#1D232A"
        />
        <path
          d="M208.598 138.11v8.436c0 .33-.268.598-.598.598h-12.878a.598.598 0 01-.598-.598v-8.436a.6.6 0 01.598-.599H208c.191 0 .361.091.467.23a.57.57 0 01.131.369z"
          fill="#42EC9A"
        />
        <path d="M206.679 138.011l-3.719 8.633h-.559l3.908-8.633h.37z" fill="#FCFCFC" stroke="#FCFCFC" />
        <mask id="prefix__b" fill="#fff">
          <path d="M208.467 137.741l-4.234 9.403h-.596l4.361-9.633h.005c.188 0 .358.091.464.23z" />
        </mask>
        <path d="M208.467 137.741l-4.234 9.403h-.596l4.361-9.633h.005c.188 0 .358.091.464.23z" fill="#FCFCFC" />
        <path
          d="M208.467 137.741l.912.41.244-.541-.359-.474-.797.605zm-4.234 9.403v1h.646l.266-.589-.912-.411zm-.596 0l-.911-.412-.639 1.412h1.55v-1zm4.361-9.633v-1h-.645l-.266.588.911.412zm.005 0v-1 1zm-.448-.181l-4.234 9.404 1.824.821 4.234-9.404-1.824-.821zm-3.322 8.814h-.596v2h.596v-2zm.315 1.413l4.361-9.633-1.822-.825-4.361 9.633 1.822.825zm3.45-9.046h.005v-2h-.005v2zm.005 0a.412.412 0 01-.332-.166l1.593-1.209a1.584 1.584 0 00-1.261-.625v2z"
          fill="#FCFCFC"
          mask="url(#prefix__b)"
        />
        <path
          d="M207.018 142.8h-5.897a.387.387 0 110-.774h5.897a.387.387 0 110 .774zM197.892 144.758c-.836 0-1.455-.63-1.609-1.22a.386.386 0 11.748-.196c.082.309.448.642.861.642.369 0 .616-.181.722-.348a.442.442 0 00.036-.434c-.144-.307-.361-.376-.836-.511l-.041-.01c-.539-.152-1.042-.333-1.261-.885-.147-.374-.1-.779.127-1.117.257-.382.716-.609 1.227-.609.508 0 1.003.274 1.295.712a.388.388 0 01-.645.429c-.111-.168-.348-.367-.65-.367-.253 0-.472.101-.588.271a.425.425 0 00-.049.397c.072.184.248.284.751.426l.041.01c.503.142 1.024.287 1.325.927.181.381.15.81-.085 1.176-.276.441-.789.707-1.369.707z"
          fill="#1D232A"
        />
        <path
          d="M197.866 145.217a.387.387 0 01-.387-.387l.008-4.829a.387.387 0 11.774 0l-.008 4.829a.387.387 0 01-.387.387z"
          fill="#1D232A"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_14730_94577">
          <path fill="#fff" transform="translate(0 .5)" d="M0 0h236v207H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
