import { type AxiosResponse } from 'axios'
import { UAParser } from 'ua-parser-js'

import { simpleHttpClient } from '~/domains/platform/infra/http/simple-http-client'
import { LOG_API_TOKEN } from '~/lib/constants'

import packageInfo from '../../../../../package.json'
import { Bucket } from '../../lib/bucket'

interface Device {
  appVersion: string
  platform: string
  osVersion: string
  model: string
  timezone: string
  sdkVersion?: string
  bundleId?: string
  deviceGeneratedId?: string
}

export type LogErrorType = 'api_error' | 'internal_error'

export interface LogError {
  createdAt: string
  type: LogErrorType
  errorDescription?: Record<string, any>
  line?: string
  location?: string
}

export interface LogErrorInputs {
  createdAt?: Date | string
  errorDescription?: Record<string, any>
  trace?: Record<string, any>
  type: LogErrorType
  location?: string
  line?: string
  file?: string
}

export interface LogUser {
  userId?: string
  accountId: string
  organizationId?: string
  stonecode?: string
  email?: string
}

interface RequestInfo {
  request: {
    path: string
    'x-stone-idempotency-key'?: string
    body?: any
  }
  response: {
    statusCode: string
    'x-request-id': string
    body?: any
  }
}

export function requestInfoFactory(response: AxiosResponse): RequestInfo {
  const {
    status: statusCode,
    headers: { 'x-request-id': requestId },
    data,
    config: {
      data: requestBody,
      url,
      baseURL,
      headers: { 'x-stone-idempotency-key': idempotencyKey }
    }
  } = response

  return {
    request: {
      path: `${baseURL}${url}`,
      'x-stone-idempotency-key': idempotencyKey,
      body: requestBody
    },
    response: {
      statusCode: statusCode.toString(),
      'x-request-id': requestId,
      body: data
    }
  }
}

export function deviceFactory(): Device {
  const { browser, os } = new UAParser().getResult()

  const timezone = ((new Date().getTimezoneOffset() / 60) * -1).toString()
  return {
    appVersion: packageInfo.version,
    model: `${browser.name} ${browser.version}`,
    osVersion: `${os.name}`,
    platform: 'Web',
    timezone: `GMT ${timezone}`,
    deviceGeneratedId: Bucket.local.get('device::generated::id')
  }
}

interface LogProps {
  user: LogUser
  error: LogErrorInputs
  response?: AxiosResponse
}

export function log({ user, error, response }: LogProps) {
  const requestInfo = response ? requestInfoFactory(response) : {}

  return simpleHttpClient({
    url: '/logr/report',
    method: 'POST',
    headers: { Authorization: LOG_API_TOKEN() },
    data: [
      {
        user,
        error,
        device: deviceFactory(),
        requestInfo
      }
    ]
  })
}
