export function InvitationSignupMailExpired() {
  return (
    <svg width="131" height="147" viewBox="0 0 131 147" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_7845_118125)">
        <path
          d="M115.428 44.085L60.8075 1.0725C58.9925 -0.3575 56.435 -0.3575 54.62 1.0725L0 44.085V85.4001H115.428V44.085Z"
          fill="#4A4F55"
        />
        <path d="M115.428 60.6199L0 61.4224L16.5225 44.085H102.77L115.428 60.6199Z" fill="#1D232A" />
        <path
          d="M109.895 50.1103C109.812 50.1103 109.73 50.0828 109.662 50.0303L102.64 44.4653H16.6398L9.64225 49.2453C9.47225 49.3628 9.23726 49.3178 9.12226 49.1478C9.00476 48.9778 9.04975 48.7428 9.21975 48.6278L16.3123 43.7828C16.3748 43.7403 16.4473 43.7178 16.5248 43.7178H102.772C102.857 43.7178 102.94 43.7453 103.005 43.7978L110.13 49.4453C110.292 49.5728 110.32 49.8103 110.19 49.9728C110.115 50.0628 110.005 50.1103 109.895 50.1103Z"
          fill="#1D232A"
        />
        <path d="M102.77 5.41016H16.5225V117.33H102.77V5.41016Z" fill="#E8E9EA" />
        <path
          d="M102.77 110.835L64.3197 76.9947C60.5422 73.6697 54.8822 73.6697 51.1072 76.9947L12.6572 110.835"
          fill="#BBBDBF"
        />
        <path d="M115.428 121.975H0V44.085L56.8475 85.4L115.428 44.085V121.975Z" fill="#BBBDBF" />
        <path
          d="M12.6575 111.21C12.555 111.21 12.4525 111.168 12.3775 111.085C12.24 110.93 12.2525 110.693 12.4075 110.555L49.3075 77.5328C49.4625 77.3953 49.7 77.4078 49.8375 77.5628C49.975 77.7178 49.9625 77.9553 49.8075 78.0928L12.9075 111.115C12.835 111.178 12.7475 111.21 12.6575 111.21Z"
          fill="#1D232A"
        />
        <path
          d="M102.77 111.21C102.68 111.21 102.592 111.177 102.52 111.115L66.1424 78.5996C65.9874 78.4621 65.9749 78.2246 66.1124 78.0696C66.2499 77.9146 66.4874 77.9021 66.6424 78.0396L103.02 110.555C103.175 110.692 103.187 110.93 103.05 111.085C102.975 111.167 102.872 111.21 102.77 111.21Z"
          fill="#1D232A"
        />
        <path
          d="M29.9199 27.1301C29.7124 27.1301 29.5449 26.9626 29.5449 26.7551C29.5449 26.5476 29.7124 26.3801 29.9199 26.3801L89.3699 26.3701C89.5774 26.3701 89.7449 26.5376 89.7449 26.7451C89.7449 26.9526 89.5774 27.1201 89.3699 27.1201L29.9199 27.1301Z"
          fill="#1D232A"
        />
        <path
          d="M29.9224 44.4622C29.7149 44.4622 29.5474 44.2947 29.5474 44.0872C29.5474 43.8797 29.7149 43.7122 29.9224 43.7122L89.3724 43.7021C89.5799 43.7021 89.7474 43.8697 89.7474 44.0772C89.7474 44.2847 89.5799 44.4522 89.3724 44.4522L29.9224 44.4622Z"
          fill="#1D232A"
        />
        <path
          d="M29.9248 61.798C29.7173 61.798 29.5498 61.6305 29.5498 61.4231C29.5498 61.2156 29.7173 61.0481 29.9248 61.0481L89.3748 61.0381C89.5823 61.0381 89.7498 61.2056 89.7498 61.4131C89.7498 61.6205 89.5823 61.788 89.3748 61.788L29.9248 61.798Z"
          fill="#1D232A"
        />
        <g filter="url(#filter0_d_7845_118125)">
          <path
            d="M102.75 147C118.214 147 130.75 134.464 130.75 119C130.75 103.536 118.214 91 102.75 91C87.286 91 74.75 103.536 74.75 119C74.75 134.464 87.286 147 102.75 147Z"
            fill="#4A4F55"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M99.3839 103.172C90.9069 104.823 85.3651 113.046 87.0059 121.537C88.6466 130.029 96.8486 135.574 105.326 133.923C113.803 132.272 119.344 124.05 117.704 115.558C116.063 107.066 107.861 101.521 99.3839 103.172ZM83.1686 122.285C81.1177 111.67 88.045 101.392 98.6412 99.3285C109.237 97.2646 119.49 104.196 121.541 114.811C123.592 125.425 116.665 135.703 106.068 137.767C95.4721 139.831 85.2196 132.899 83.1686 122.285Z"
          fill="#E6E7E8"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M100.498 108.938C101.558 108.732 102.583 109.425 102.788 110.486L104.106 117.306L109.567 119.447C110.571 119.841 111.067 120.977 110.673 121.984C110.279 122.991 109.145 123.488 108.14 123.094L101.678 120.561C101.055 120.316 100.6 119.769 100.473 119.111L98.9507 111.234C98.7456 110.172 99.4384 109.144 100.498 108.938Z"
          fill="#E6E7E8"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_7845_118125"
          x="71.75"
          y="90"
          width="62"
          height="62"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.290196 0 0 0 0 0.309804 0 0 0 0 0.333333 0 0 0 0.32 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7845_118125" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7845_118125" result="shape" />
        </filter>
        <clipPath id="clip0_7845_118125">
          <rect width="130.75" height="147" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
