export function KycConfirmationEmailIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="325" height="338" viewBox="0 0 325 338" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M302.517 71.6624C254.986 -26.2285 99.0962 -11.7995 43.9265 43.3702C4.31747 82.4134 -15.487 149.183 14.5027 231.23C44.4923 313.277 153.134 361.374 235.747 325.726C318.36 290.078 350.048 169.553 302.517 71.6624Z"
        fill="#F2FBF6"
      />
      <g clipPath="url(#confirm-email-illustration-a)">
        <path
          d="M216.053 141.085L161.433 98.0725C159.618 96.6425 157.06 96.6425 155.245 98.0725L100.625 141.085V182.4H216.053V141.085Z"
          fill="#008E5A"
        />
        <path d="M216.053 157.62L100.625 158.423L117.148 141.085H203.395L216.053 157.62Z" fill="#1D232A" />
        <path
          d="M210.52 147.11C210.437 147.11 210.355 147.083 210.287 147.03L203.265 141.465H117.265L110.267 146.245C110.097 146.363 109.862 146.318 109.747 146.148C109.63 145.978 109.675 145.743 109.845 145.628L116.937 140.783C117 140.74 117.072 140.718 117.15 140.718H203.397C203.482 140.718 203.565 140.745 203.63 140.798L210.755 146.445C210.917 146.573 210.945 146.81 210.815 146.973C210.74 147.063 210.63 147.11 210.52 147.11Z"
          fill="#1D232A"
        />
        <path d="M203.395 102.41H117.148V214.33H203.395V102.41Z" fill="#E8E9EA" />
        <path
          d="M203.395 207.835L164.945 173.995C161.167 170.67 155.507 170.67 151.732 173.995L113.282 207.835"
          fill="#00A868"
        />
        <path d="M216.053 218.975H100.625V141.085L157.473 182.4L216.053 141.085V218.975Z" fill="#00A868" />
        <path
          d="M113.282 208.21C113.18 208.21 113.077 208.167 113.002 208.085C112.865 207.93 112.877 207.692 113.032 207.555L149.932 174.532C150.087 174.395 150.325 174.407 150.462 174.562C150.6 174.717 150.587 174.955 150.432 175.092L113.532 208.115C113.46 208.177 113.372 208.21 113.282 208.21Z"
          fill="#1D232A"
        />
        <path
          d="M203.395 208.21C203.305 208.21 203.218 208.177 203.145 208.115L166.768 175.6C166.613 175.462 166.6 175.225 166.738 175.07C166.875 174.915 167.113 174.902 167.268 175.04L203.645 207.555C203.8 207.692 203.813 207.93 203.675 208.085C203.6 208.167 203.498 208.21 203.395 208.21Z"
          fill="#1D232A"
        />
        <path
          d="M130.545 124.13C130.338 124.13 130.17 123.963 130.17 123.755C130.17 123.548 130.338 123.38 130.545 123.38L189.995 123.37C190.203 123.37 190.37 123.538 190.37 123.745C190.37 123.953 190.203 124.12 189.995 124.12L130.545 124.13Z"
          fill="#1D232A"
        />
        <path
          d="M130.548 141.462C130.34 141.462 130.173 141.295 130.173 141.087C130.173 140.88 130.34 140.712 130.548 140.712L189.998 140.702C190.205 140.702 190.373 140.87 190.373 141.077C190.373 141.285 190.205 141.452 189.998 141.452L130.548 141.462Z"
          fill="#1D232A"
        />
        <path
          d="M130.55 158.797C130.343 158.797 130.175 158.63 130.175 158.422C130.175 158.215 130.343 158.048 130.55 158.048L190 158.038C190.208 158.038 190.375 158.205 190.375 158.412C190.375 158.62 190.208 158.787 190 158.787L130.55 158.797Z"
          fill="#1D232A"
        />
        <g filter="url(#confirm-email-illustration-b)">
          <path
            d="M203.375 244C218.839 244 231.375 231.464 231.375 216C231.375 200.536 218.839 188 203.375 188C187.911 188 175.375 200.536 175.375 216C175.375 231.464 187.911 244 203.375 244Z"
            fill="#E8E9EA"
          />
        </g>
        <path
          d="M221.314 204.407L219.61 212.323C219.372 213.426 218.397 214.18 217.313 214.18C217.151 214.18 216.988 214.164 216.824 214.129L208.9 212.447C207.631 212.177 206.819 210.931 207.088 209.659C207.358 208.389 208.606 207.578 209.876 207.849L212.881 208.486C209.871 204.689 204.729 202.914 199.825 204.418C196.851 205.331 194.368 207.324 192.837 210.028C192.195 211.159 190.761 211.556 189.632 210.917C188.502 210.275 188.104 208.841 188.744 207.712C190.872 203.955 194.316 201.19 198.445 199.923C205.12 197.877 212.112 200.205 216.321 205.265L216.718 203.417C216.991 202.147 218.241 201.338 219.511 201.613C220.781 201.886 221.59 203.137 221.314 204.407Z"
          fill="#00A868"
        />
        <path
          d="M218.009 224.29C215.883 228.048 212.436 230.812 208.31 232.08C206.671 232.583 205.016 232.82 203.387 232.82C198.372 232.82 193.611 230.559 190.434 226.74L190.037 228.586C189.799 229.689 188.824 230.443 187.74 230.443C187.575 230.443 187.408 230.427 187.241 230.389C185.974 230.117 185.165 228.866 185.438 227.596L187.145 219.68C187.418 218.412 188.664 217.604 189.931 217.874L197.855 219.555C199.124 219.825 199.935 221.072 199.665 222.344C199.397 223.613 198.148 224.424 196.879 224.154L193.872 223.517C196.883 227.314 202.025 229.089 206.93 227.584C209.904 226.672 212.385 224.678 213.918 221.974C214.557 220.844 215.991 220.446 217.122 221.088C218.253 221.728 218.651 223.162 218.009 224.29Z"
          fill="#00A868"
        />
      </g>
      <defs>
        <filter
          id="confirm-email-illustration-b"
          x="172.375"
          y="187"
          width="62"
          height="62"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.290196 0 0 0 0 0.309804 0 0 0 0 0.333333 0 0 0 0.32 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_22853" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_22853" result="shape" />
        </filter>
        <clipPath id="confirm-email-illustration-a">
          <rect width="138" height="151" fill="white" transform="translate(97 95)" />
        </clipPath>
      </defs>
    </svg>
  )
}
