export function GooglePlayIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={40} height={40} viewBox="0 0 35 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="40" height="40" fill="white" />
      <path
        d="M8.58665 6.65911C8.28829 6.97599 8.11133 7.46777 8.11133 8.10462V30.8583C8.11133 31.4962 8.28829 31.987 8.58665 32.3039L8.66279 32.3779L21.4101 19.6317V19.4815V19.3302L8.66279 6.584L8.58665 6.65911Z"
        fill="url(#paint0_linear_2737_3039)"
      />
      <path
        d="M21.4099 19.3302V19.4815V19.6317L25.658 23.8828L25.7547 23.8283L30.7888 20.9681C32.2261 20.1502 32.2261 18.8127 30.7888 17.9968L25.7547 15.1357L25.659 15.0811L21.4099 19.3302Z"
        fill="url(#paint1_linear_2737_3039)"
      />
      <path
        d="M8.58643 32.3041C9.06072 32.8051 9.84366 32.8679 10.7254 32.3679L25.7546 23.8275L21.4098 19.4817L8.58643 32.3041Z"
        fill="url(#paint2_linear_2737_3039)"
      />
      <path
        d="M8.58643 6.65907L21.4098 19.4814L25.7546 15.1356L10.7254 6.59632C10.3118 6.36071 9.9198 6.2496 9.57205 6.2496C9.17698 6.2496 8.83849 6.3926 8.58643 6.65907"
        fill="url(#paint3_linear_2737_3039)"
      />
      <g opacity="0.199997">
        <path
          d="M25.6589 23.7319L10.7253 32.2167C9.88991 32.692 9.14297 32.6601 8.66354 32.228L8.5874 32.3052L8.66354 32.3792V32.3782C9.14297 32.8103 9.88991 32.8422 10.7253 32.3669L25.7545 23.8276L25.6589 23.7319Z"
          fill="black"
        />
      </g>
      <g opacity="0.119995">
        <path
          d="M8.58695 32.1545C8.28756 31.8376 8.1106 31.3458 8.1106 30.709V30.8592C8.1106 31.496 8.28756 31.9878 8.58695 32.3047L8.66411 32.2286L8.58695 32.1545Z"
          fill="black"
        />
      </g>
      <g opacity="0.119995">
        <path
          d="M30.7885 20.8168L25.6587 23.7315L25.7544 23.8271L30.7885 20.967C31.5076 20.5585 31.8667 20.0205 31.8667 19.4824C31.806 19.969 31.4407 20.4464 30.7885 20.8168Z"
          fill="black"
        />
      </g>
      <g opacity="0.25">
        <path
          d="M10.7249 6.7475L30.7882 18.147C31.4405 18.5174 31.8067 18.9948 31.8664 19.4824C31.8674 18.9443 31.5073 18.4052 30.7882 17.9968L10.7249 6.59729C9.28655 5.78039 8.1106 6.4584 8.1106 8.10556V8.25577C8.1106 6.60964 9.28655 5.9306 10.7249 6.7475Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2737_3039"
          x1="20.2791"
          y1="7.86461"
          x2="3.01277"
          y2="25.1309"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00A0FF" />
          <stop offset="0.00657445" stopColor="#00A1FF" />
          <stop offset="0.2601" stopColor="#00BEFF" />
          <stop offset="0.5122" stopColor="#00D2FF" />
          <stop offset="0.7604" stopColor="#00DFFF" />
          <stop offset="1" stopColor="#00E3FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2737_3039"
          x1="32.6606"
          y1="19.4819"
          x2="7.76599"
          y2="19.4819"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE000" />
          <stop offset="0.4087" stopColor="#FFBD00" />
          <stop offset="0.7754" stopColor="#FFA500" />
          <stop offset="1" stopColor="#FF9C00" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2737_3039"
          x1="23.3934"
          y1="21.843"
          x2="-0.021148"
          y2="45.2575"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF3A44" />
          <stop offset="1" stopColor="#C31162" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2737_3039"
          x1="5.35828"
          y1="-0.915028"
          x2="15.8138"
          y2="9.54053"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#32A071" />
          <stop offset="0.0685" stopColor="#2DA771" />
          <stop offset="0.4762" stopColor="#15CF74" />
          <stop offset="0.8009" stopColor="#06E775" />
          <stop offset="1" stopColor="#00F076" />
        </linearGradient>
      </defs>
    </svg>
  )
}
