export function NpsFeedbackSentIllustration({ width = 186, height = 200, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox="0 0 186 200" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.806 169.12c-1.407 1.72-2.539 2.815-3.346 4.061l-2.643-9.235-1.089-3.795-1.107-3.933-6.423-22.734c-.856-3.028 1.156-6.117 4.288-6.585l.288-.043 5.18 22.904.961 4.232.991 3.843 2.9 11.285zM52.671 64.254c-4.52-1.125-7.261-5.679-6.13-10.172l2.234-8.597c1.131-4.494 5.713-7.218 10.233-6.093 4.521 1.125 7.262 5.68 6.13 10.172l-2.233 8.598c-1.132 4.493-5.72 7.217-10.234 6.092z"
        fill="#1D232A"
      />
      <path
        d="M81.36 40.918c-8.068-7.886-9.034-17.413-7.31-23.183C77.153 7.33 84.059 12.22 84.23 16.45 89.766 8.718 95.49 4.115 103.81 1.196c5.077-1.781 8.38-1.416 11.243.067 5.775 2.992 1.884 15.918.147 18.697 16.302-7.072 21.502-2.22 22.707-.523 2.398 3.393 1.994 12.063-12.626 22.776 1.058-.054 6.778 1.983 8.203 4.554 1.114 2.007.783 7.145-8.704 9.115-20.138 4.17-31.602-4.414-43.42-14.964z"
        fill="#E0541E"
      />
      <path
        d="M56.923 50.72c-.068 0-.129 0-.196-.006a3.039 3.039 0 01-2.863-3.205c.012-.194 1.407-19.718 22.156-19.718a3.05 3.05 0 013.059 3.04 3.05 3.05 0 01-3.059 3.04c-6.27 0-10.845 2.274-13.598 6.762-2.172 3.532-2.447 7.21-2.447 7.247a3.063 3.063 0 01-3.052 2.84zM46.285 72.007h-.043a.602.602 0 01-.57-.639c.007-.158.282-3.891 5.347-6.78a.615.615 0 01.838.226.599.599 0 01-.226.827c-4.453 2.541-4.728 5.666-4.735 5.794a.618.618 0 01-.611.572z"
        fill="#1D232A"
      />
      <path
        d="M114.131 161.203l-45.145 3.259c-.906.067-1.811.098-2.704.098-19.061 0-35.162-14.642-36.569-33.831-1.352-18.532 11.47-34.846 29.332-38.409 1.505-.303 3.04-.51 4.613-.626l37.4-2.705 7.744-.56c20.224-1.46 37.799 13.644 39.273 33.739 1.468 20.095-13.727 37.57-33.944 39.035z"
        fill="#00A869"
      />
      <path
        d="M129.197 171.363c-.801 1.903-5.169 7.503-29.992 9.036-21.857 1.349-49.042-2.949-49.042-2.949 5.536-1.381 11.5-2.426 11.5-2.426s32.415-5.868 42.992-7.959c7.004-1.381 16.529-2.621 24.542 4.298z"
        fill="#1D232A"
      />
      <path
        d="M97.394 182.369c-20.48 0-41.903-4.445-42.203-4.512a.606.606 0 01-.47-.723.618.618 0 01.727-.469c.416.085 42.02 8.719 64.359 1.818a.607.607 0 01.765.402.606.606 0 01-.404.76c-6.484 2.006-14.559 2.724-22.774 2.724z"
        fill="#1D232A"
      />
      <path
        d="M29.958 171.564s-.624 25.306 31.418 21.451c32.042-3.849 26.591-27.416 26.591-27.416l-58.009 5.965z"
        fill="#FFCBD9"
      />
      <path
        d="M30.784 177.286l56.804-2.098s1.217-5.582.802-7.588c-.416-2.001-58.096 7.855-58.096 7.855l.49 1.831z"
        fill="#1D232A"
      />
      <path
        d="M178.704 177.638a21.431 21.431 0 01-14.314 5.278c-5.885-.055-11.721-2.505-15.899-7.254l-22.163-25.123.386-18.107-.098-15.991 14.693-12.878 39.383 44.045c7.793 8.841 6.9 22.284-1.988 30.03z"
        fill="#FFCBD9"
      />
      <path
        d="M72.008 155.135a7.2 7.2 0 01-.845-2.681c0-.012-.006-.018-.006-.03-.11-7.68 5.237-13.863 8.301-16.824 12.596-12.167 30.103-10.671 40.71-7.212 15.312 4.998-7.622 37.923-7.622 37.923l-12.436-1.63s-3.12.924-5.132-3.727c-3.047 1.313-8.485-2.31-7.947-8.378.857-9.711 5.983-10.598 5.983-10.598-.14.048-3.224 1.483-7.328 8.798-1.059 1.885-2.092 3.836-3.714 5.302-1.847 1.672-4.55 2.645-6.9 1.8-1.333-.487-2.385-1.502-3.064-2.743z"
        fill="#FFCBD9"
      />
      <path
        d="M54.635 186.582a.614.614 0 01-.587-.432.61.61 0 01.41-.76c.146-.042 14.577-4.42 17.697-5.648 2.318-.918 3.982-2.754 4.337-4.797.318-1.824-.422-3.582-2.148-5.083a.6.6 0 01-.055-.858.608.608 0 01.863-.054c2.03 1.769 2.93 3.976 2.545 6.207-.428 2.457-2.38 4.646-5.096 5.716-3.162 1.246-17.195 5.502-17.789 5.679a.442.442 0 01-.177.03z"
        fill="#1D232A"
      />
      <path
        d="M171.711 198.785a21.446 21.446 0 01-12.521.523c-11.984-3.886-27.448-11.729-44.442-21.324-11.445-2.961-23.35-6.068-20.37-17.444 2.979-11.376 14.675-18.192 26.114-15.231l49.488 12.811c11.446 2.961 18.303 14.587 15.324 25.957-1.86 7.101-7.121 12.428-13.593 14.708zM105.505 61.475a24.69 24.69 0 01-.654 4.123c-2.992 12.081-15.08 20.63-28.861 19.67-13.966-.967-24.842-11.37-25.87-23.98a23.566 23.566 0 01-.024-3.661 24.198 24.198 0 013.407-10.78 25.558 25.558 0 014.417-5.473c.74-.699 1.517-1.355 2.336-1.97 5.249-3.928 12.064-6.08 19.355-5.575 2.386.164 4.692.608 6.864 1.289 6 1.885 11.053 5.587 14.448 10.36.52.724.998 1.478 1.432 2.25a24.072 24.072 0 011.505 3.156c.459 1.149.832 2.335 1.107 3.55.514 2.263.71 4.628.538 7.041z"
        fill="#FFCBD9"
      />
      <path
        d="M106.661 68.906a.923.923 0 01-.868-.615 10.574 10.574 0 01-.092-6.724c.697-2.238 2.049-4.135 3.915-5.49a.928.928 0 011.285.194.915.915 0 01-.196 1.277 8.865 8.865 0 00-3.248 4.56c-.582 1.86-.557 3.794.073 5.581a.912.912 0 01-.563 1.162.93.93 0 01-.306.055z"
        fill="#1D232A"
      />
      <path
        d="M103.86 50.884v.018c-2.282.657-5.316.432-8.32-.809-3.34-1.374-10.442-7.703-11.414-10.026-.484 2.754-4.123 7.029-14.052 5.728-3.376-.444-9.903-2.378-12.154-4.42.74-.7 1.517-1.356 2.336-1.97 5.249-3.928 12.064-6.08 19.355-5.576 2.386.164 4.692.608 6.864 1.289 6 1.885 11.053 5.587 14.449 10.36.519.724.997 1.478 1.431 2.25a24.072 24.072 0 011.505 3.156z"
        fill="#E0541E"
      />
      <path
        d="M73.592 75.01c-1.376 0-2.68-.48-3.377-1.325a.606.606 0 01.086-.858.615.615 0 01.862.085c.661.797 2.331 1.113 3.726.694.66-.195 1.786-.73 1.933-2.074a.615.615 0 01.673-.54.609.609 0 01.544.668c-.159 1.496-1.18 2.627-2.796 3.113a5.895 5.895 0 01-1.651.237z"
        fill="#1D232A"
      />
      <path
        d="M72.417 59.056a3.567 3.567 0 00.233 2.742 3.605 3.605 0 002.116 1.775l8.29 2.62c1.902.603 3.939-.443 4.544-2.334a3.567 3.567 0 00-.232-2.742 3.602 3.602 0 00-2.117-1.776l-8.288-2.62c-1.91-.602-3.94.444-4.546 2.335z"
        fill="#fff"
      />
      <path
        d="M78.749 57.286l.159 7.594 4.141 1.314c1.902.602 3.94-.444 4.545-2.335a3.567 3.567 0 00-.232-2.742 3.602 3.602 0 00-2.117-1.776l-6.496-2.055z"
        fill="#1D232A"
      />
      <path
        d="M54.225 52.817a3.567 3.567 0 00.232 2.742 3.605 3.605 0 002.117 1.776l8.289 2.62c1.902.602 3.94-.443 4.545-2.334a3.567 3.567 0 00-.233-2.742 3.602 3.602 0 00-2.116-1.776l-8.289-2.62c-1.903-.602-3.94.443-4.545 2.334z"
        fill="#fff"
      />
      <path
        d="M60.556 51.048l.16 7.594 4.14 1.313c1.903.602 3.94-.443 4.546-2.334a3.567 3.567 0 00-.233-2.742 3.602 3.602 0 00-2.116-1.776l-6.497-2.055zM70.28 52.041c.695-.784.233-2.317-1.033-3.424-1.265-1.108-2.854-1.37-3.548-.586-.694.784-.232 2.317 1.034 3.424 1.265 1.108 2.853 1.37 3.548.586zM85.636 51.137c.276-.997-.868-2.18-2.555-2.64-1.688-.462-3.28-.028-3.556.97-.275.997.869 2.18 2.556 2.64 1.688.462 3.28.027 3.555-.97z"
        fill="#1D232A"
      />
      <path
        d="M67.658 68.948c-.104 0-.202 0-.3-.006-1.529-.06-2.96-1.046-3.645-2.505-.581-1.234-.471-2.56.293-3.545l4.13-5.344a.61.61 0 01.856-.11.6.6 0 01.11.851l-4.13 5.345c-.489.632-.544 1.465-.152 2.292.49 1.04 1.53 1.764 2.587 1.806.71.024 1.45-.06 2.19-.273a.606.606 0 11.33 1.167 8.185 8.185 0 01-2.269.322zM46.897 75.965c1.435 0 2.6-1.157 2.6-2.584a2.592 2.592 0 00-2.6-2.584 2.592 2.592 0 00-2.6 2.584 2.592 2.592 0 002.6 2.584zM104.961 57.475a3.055 3.055 0 01-3.053-2.87c-.055-.84-1.627-20.813-26.132-20.813a3.05 3.05 0 01-3.059-3.04 3.05 3.05 0 013.059-3.04c30.372 0 32.225 26.285 32.237 26.552a3.038 3.038 0 01-2.881 3.205c-.049.006-.11.006-.171.006z"
        fill="#1D232A"
      />
      <path
        d="M104.184 75.071c-4.606-.705-7.763-4.986-7.06-9.564l1.42-8.768c.71-4.578 5.016-7.716 9.622-7.017 4.607.706 7.763 4.986 7.06 9.565l-1.419 8.767c-.716 4.585-5.023 7.722-9.623 7.017zM87.894 147.821a.605.605 0 01-.563-.833c2.777-6.84 7.316-10.507 14.73-11.887a.613.613 0 01.716.486.614.614 0 01-.489.712c-6.956 1.295-11.213 4.73-13.82 11.145a.627.627 0 01-.574.377zM94.978 161.556a.615.615 0 01-.612-.553c-1.303-13.997 10.148-17.827 10.638-17.986a.612.612 0 01.771.396.607.607 0 01-.398.766c-.453.146-11.011 3.672-9.794 16.714a.608.608 0 01-.55.663h-.055z"
        fill="#1D232A"
      />
      <path
        d="M88.897 103.812c-17.397 2.773-26.812-7.381-29.852-11.491 1.505-.305 3.04-.511 4.613-.627l37.4-2.705c.483 3.593.434 12.811-12.16 14.823z"
        fill="#FFCBD9"
      />
      <path
        d="M153.941 138.001a.524.524 0 01-.165-.024l-34.366-9.564a.605.605 0 01-.422-.748.613.613 0 01.752-.42l34.366 9.565a.606.606 0 01-.165 1.191z"
        fill="#1D232A"
      />
      <path
        d="M4.064 127.969l.538 2.177.857 3.471 3.272 13.237 6.399 25.914c.697 2.809 3.364 4.688 6.258 4.408l59.9-5.849-2.796-11.328-3.273-13.236-.856-3.472-1.016-4.11-4.74-19.177c-.692-2.815-3.365-4.688-6.258-4.408l-53.171 5.192a5.844 5.844 0 00-5.267 5.314 5.72 5.72 0 00.153 1.867z"
        fill="#BBBDBF"
      />
      <path
        d="M17.142 170.068a.609.609 0 01-.605-.547.61.61 0 01.55-.663l57.722-5.636a.608.608 0 11.116 1.21l-57.721 5.636h-.062z"
        fill="#1D232A"
      />
      <path
        d="M79.067 171.54l46.68-4.56c3.218-.317 5.573-3.162 5.255-6.366l-.135-1.381a.89.89 0 00-.972-.796l-51.623 5.04c-2.24.219-3.872 2.201-3.652 4.421a4.06 4.06 0 004.447 3.642z"
        fill="#E8E9EA"
      />
      <path
        d="M126.561 130.377a.614.614 0 01-.6-.736c.832-3.861.967-12.775-2.239-20.278a.602.602 0 01.325-.796.61.61 0 01.801.322c3.316 7.759 3.181 17 2.312 21.007a.618.618 0 01-.599.481z"
        fill="#1D232A"
      />
      <path
        d="M164.626 86.382c10.892 0 19.722-8.777 19.722-19.603 0-10.826-8.83-19.603-19.722-19.603-10.893 0-19.722 8.776-19.722 19.603 0 10.826 8.829 19.603 19.722 19.603z"
        fill="#00A869"
      />
      <path
        d="M161.353 76.67a2.07 2.07 0 01-1.404-.547l-6.571-6.036a2.049 2.049 0 01-.116-2.907 2.078 2.078 0 012.924-.115l5.051 4.64 11.714-12.599a2.078 2.078 0 012.924-.115c.84.772.891 2.073.116 2.907l-13.118 14.11c-.372.4-.89.639-1.439.66-.026.002-.054.002-.081.002z"
        fill="#fff"
      />
    </svg>
  )
}
