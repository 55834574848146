export function PaperAirplaneIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 706 304" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#paper_airplane_clip0)">
        <path
          d="M0 -4H706V299.894C706 302.161 704.161 304 701.893 304H4.10646C1.83853 304 0 302.161 0 299.894V-4Z"
          fill="#F3FBF6"
        />
        <path
          d="M335.276 205.012C259.405 277.103 4.50209 225.496 -34.4885 134.479L-13.3016 -180H700.677L714.115 158.049C673.285 116.082 426.239 118.58 335.276 205.012Z"
          fill="#D8EBDF"
          fillOpacity="0.3"
        />
        <path
          d="M236.994 93.7992C365.717 174.166 657.532 57.8606 687.234 -45.1583V-180.329H-6.03613V59.7992C16.751 56.5798 149.535 39.1951 236.994 93.7992Z"
          fill="#D8EBDF"
          fillOpacity="0.3"
        />
        <g clipPath="url(#paper_airplane_clip1)">
          <path
            d="M490.034 180.247C501.174 116.945 473.635 80.0918 426.82 49.2077C344.043 -5.01388 216.804 68.4868 209.977 141.892C203.15 215.298 276.917 259.87 326.162 278.844C375.406 297.818 478.893 243.549 490.034 180.247Z"
            fill="#D2F4DE"
          />
          <g filter="url(#filter0_d)">
            <rect x="224" y="194.241" width="254.217" height="45.759" rx="5.08434" fill="white" />
          </g>
          <rect x="235.44" y="204.41" width="69.9096" height="10.1687" rx="5.08434" fill="#F4F4F9" />
          <rect x="235.44" y="220.934" width="80.0783" height="10.1687" rx="5.08434" fill="#F4F4F9" />
          <g opacity="0.5">
            <path
              d="M433.729 199.512C433.729 197.303 435.52 195.512 437.729 195.512H472.946C475.155 195.512 476.946 197.303 476.946 199.512V234.729C476.946 236.938 475.155 238.729 472.946 238.729H437.729C435.52 238.729 433.729 236.938 433.729 234.729V199.512Z"
              fill="white"
            />
            <rect x="443.898" y="208.223" width="1.27108" height="17.7952" rx="0.635542" fill="#3B4652" />
            <rect x="451.524" y="208.223" width="1.27108" height="17.7952" rx="0.635542" fill="#3B4652" />
            <rect x="460.331" y="208.223" width="3.68379" height="17.7952" rx="0.635542" fill="#3B4652" />
            <rect x="446.44" y="208.223" width="3.81325" height="17.7952" rx="0.635542" fill="#3B4652" />
            <rect x="455.337" y="208.223" width="2.28795" height="17.7952" rx="0.635542" fill="#3B4652" />
            <rect x="465.549" y="208.223" width="1.22793" height="17.7952" rx="0.613966" fill="#3B4652" />
          </g>
          <path d="M375.189 99.4929L383.86 137.918L482.202 64.7559L375.189 99.4929Z" fill="url(#paint0_linear)" />
          <path d="M386.216 110.269L384.821 137.967L482.225 64.7727L386.216 110.269Z" fill="#F2F2F2" />
          <path d="M386.216 110.269L423.255 132.96L482.225 64.7727L386.216 110.269Z" fill="#F8F9FD" />
          <path d="M350.687 88.2325L375.188 99.4936L482.225 64.773L350.687 88.2325Z" fill="white" />
          <path
            d="M230.991 191.063C233.062 165.801 234.663 115.886 262.628 120.869C297.584 127.098 312.692 208.223 349.202 208.223C394.273 208.223 375.141 148.988 381.614 146.22"
            stroke="#14AA4B"
            strokeWidth="3.81325"
            strokeLinecap="round"
            strokeDasharray="10.17 10.17"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="211.289"
          y="187.886"
          width="279.638"
          height="71.181"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6.35542" />
          <feGaussianBlur stdDeviation="6.35542" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.694118 0 0 0 0 0.756863 0 0 0 0 0.823529 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear"
          x1="480.178"
          y1="64.6691"
          x2="361.365"
          y2="97.7363"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A9A9A9" />
          <stop offset="0.30171" stopColor="#DFDFDF" />
          <stop offset="0.525836" stopColor="#E8E8E8" />
          <stop offset="1" stopColor="#CFCFCF" stopOpacity="0.21" />
        </linearGradient>
        <clipPath id="paper_airplane_clip0">
          <rect width="706" height="304" fill="white" />
        </clipPath>
        <clipPath id="paper_airplane_clip1">
          <rect width="335" height="313.171" fill="white" transform="translate(185)" />
        </clipPath>
      </defs>
    </svg>
  )
}
