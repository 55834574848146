import type CSS from 'csstype'

export function LogoLetteringWhiteIllustration(props: {
  width?: CSS.WidthProperty<1>
  height?: CSS.HeightProperty<1>
}) {
  return (
    <svg width={114} height={30} viewBox="0 0 114 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.343 29.823c5.694 0 9.762-3.49 9.762-8.037 0-5.112-3.396-6.592-8.028-7.58l-2.3-.493c-1.733-.387-2.688-1.199-2.688-2.573 0-1.762 1.768-2.714 4.21-2.714 3.43 0 5.764 1.55 6.117 1.55a.548.548 0 00.46-.317l.92-3.419c.177-.46.142-.564-.178-.74-2.051-1.057-4.244-1.657-7.356-1.657C4.497 3.843.5 7.157.5 11.563c0 4.163 3.22 6.451 7.818 7.44l2.335.493c1.874.387 2.932 1.128 2.932 2.714 0 1.622-1.273 3.032-4.28 3.032-4.244 0-7.144-1.692-7.604-1.692-.284 0-.39.14-.494.598l-.602 2.857c-.177.598 0 .987.39 1.163 1.45.81 5.41 1.655 8.348 1.655zM29.642 29.543c1.839 0 3.041-.212 3.571-.424a.456.456 0 00.285-.388v-4.163c0-.246-.107-.387-.425-.387-.248 0-.956.14-1.521.14-2.194 0-3.148-.81-3.148-2.714V9.87h4.668a.393.393 0 00.39-.388V4.936a.39.39 0 00-.39-.388h-4.845V.599c0-.423-.177-.599-.495-.599h-4.774c-.389 0-.566.177-.566.6v21.608c0 5.254 2.935 7.335 7.25 7.335zM47.928 24.923c-3.715 0-7.109-2.785-7.109-8.037 0-5.253 3.395-7.97 7.108-7.97 3.749 0 7.073 2.714 7.073 7.97 0 5.288-3.324 8.037-7.072 8.037zm0 5.077c7.426 0 12.837-5.534 12.837-13.114 0-7.58-5.41-13.078-12.837-13.078-7.393 0-12.84 5.499-12.84 13.078S40.535 30 47.928 30zM64.443 29.224H69.5a.372.372 0 00.27-.094.365.365 0 00.088-.117.365.365 0 00.031-.143V14.947c0-3.948 1.946-6.028 5.305-6.028 3.36 0 5.27 2.08 5.27 6.028v13.925c.001.049.012.097.033.142a.355.355 0 00.211.193.357.357 0 00.145.019h5.058a.358.358 0 00.27-.093.365.365 0 00.117-.26v-13.89C86.3 9.024 82.977 3.806 75.194 3.806c-7.781 0-11.139 5.217-11.139 11.175v13.89a.366.366 0 00.39.352zM113.833 16.604c0-7.793-4.491-12.796-11.639-12.796-6.719 0-12.519 5.146-12.519 12.973 0 8.354 5.94 13.219 12.307 13.219 5.341 0 8.422-2.098 10.328-4.653.368-.495.444-.709.444-.847 0-.137-.089-.246-.41-.53l-2.228-1.94c-.534-.457-.711-.6-.889-.6-.177 0-.283.108-.424.283-1.521 2.045-3.643 3.208-6.507 3.208-3.614 0-6.022-2.447-6.633-6.416h16.32c1.139.003 1.85-.354 1.85-1.9zm-11.848-7.896c3.859 0 5.902 2.595 6.013 5.464H95.749c.671-3.08 2.769-5.465 6.236-5.465z"
        fill="#fff"
      />
    </svg>
  )
}
