type IllustrationProps = {
  size?: string
}

export function SuccessPhoneOnHandIllustration({ size }: IllustrationProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 241 214" fill="none">
      <path
        d="M228.863 157.199c23.239-50.036 8.632-86.73-23.238-122.755-56.436-63.38-177.94-30.69-199.85 28.687-21.91 59.376 29.214 112.748 65.731 139.434 36.518 26.686 134.119 4.67 157.357-45.366z"
        fill="#F3FBF6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M126.717 41.732c2.587 4.822 2.279 17.053-1.064 17.915-1.334.343-4.174-.499-7.065-2.241l1.815 12.837h-10.715l3.689-17.192c-2.057-2.414-3.449-5.373-3.217-8.689.671-9.554 13.97-7.452 16.557-2.63z"
        fill="#B28B67"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M122.484 36.48c-1.464-.445-3.589-.597-4.483-.494a7.712 7.712 0 00.546-3.898c-.16-1.285-.734-2.378-1.265-3.524-.244-.529-.522-1.054-.935-1.458a3.194 3.194 0 00-1.513-.813 4.05 4.05 0 00-1.743-.001c-.254.053-.481.17-.73.234-.254.066-.378-.094-.562-.254-.446-.386-1.098-.576-1.662-.644-.632-.077-1.287.019-1.89.226-.587.203-1.15.531-1.574 1.01a2.63 2.63 0 00-.472.723c-.046.11-.053.174-.163.215-.101.037-.22.038-.325.073-1.011.343-1.876 1.125-2.347 2.128-.275.585-.405 1.616-.369 2.772.036 1.155 1.079 4.17 2.054 5.269.871.981 2.151 1.629 3.387 1.913-.82.937-1.366 2.108-1.77 3.3a16.204 16.204 0 00-.81 4.007c-.095 1.302-.104 2.678.202 3.952.285 1.191.895 2.168 1.914 2.788.467.284.969.497 1.474.692.498.193.998.38 1.519.487.987.204 2.124.17 2.884-.616.274-.284.575-.64.775-.985.072-.125.116-.206.028-.328-.104-.145-.274-.257-.408-.37a3.846 3.846 0 01-1.36-2.548c-.121-1.046.159-2.396 1.176-2.852 1.255-.563 2.637.083 3.752.686.475-1.673 1.448-3.18 2.974-3.947 1.688-.85 3.626-.948 5.36-1.677.453-.19.913-.405 1.246-.792.139-.162.302-.371.358-.585.073-.281-.108-.516-.248-.735-.742-1.161-3.556-3.507-5.02-3.953z"
        fill="#2D3844"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M106.118 107.301l16.185 56.797 11.809 41.863h8.613l-12.549-98.66h-24.058z"
        fill="#947C58"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.789 107.301c-.374 29.274-1.651 45.241-2.044 47.9-.393 2.66-6.336 19.58-17.83 50.76h8.922c14.633-29.912 22.801-46.832 24.505-50.76 1.704-3.928 6.962-19.894 14.882-47.9h-28.435z"
        fill="#B7A58A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81.118 213.996l.447-9.375h9.374c4.446 3.125 10.249 5.655 17.411 7.589v1.786H91.386l-5.804-.893v.893h-4.464zM132.903 213.996l.447-9.375h9.375c4.445 3.125 10.248 5.655 17.41 7.589v1.786h-16.964l-5.803-.893v.893h-4.465z"
        fill="#1D242B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81.791 97.031l9.148 1.815c-9.2 17.145-14.133 26.232-14.801 27.261-1.502 2.314-.1 6.556.417 8.79-3.248.756-1.967-4.132-6.077-3.111-3.751.931-6.932 3.322-10.43.098-.43-.397-.745-1.636.722-2.271 3.653-1.583 9.05-4.548 9.925-5.699 1.193-1.57 4.892-10.531 11.096-26.883zm90.481-13.725c-1.438 2.1-2.856 4.375-2.282 5.112.575.736 2.24-.077 2.898 1.12.438.799-2.368 1.754-8.419 2.865l-12.115 9.288-5.165-6.617 16.176-7.103c2.833-2.824 5.57-4.97 8.209-6.439.789-.251 2.137-.327.698 1.774z"
        fill="#B28B67"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M115.802 62.805l3.86-.662c8.408 8.156 11.839 27.751 17.443 30.345 5.298 2.452 12.808.079 21.734-3.125l2.734 6.108c-8.111 8.775-25.39 16.188-32.394 11.956-11.198-6.766-13.783-30.934-13.377-44.622z"
        fill="#1D242B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98.975 111.318h34.156c1.561 0 1.267-2.254 1.041-3.391-2.604-13.125-12.768-27.273-12.768-45.956l-9.853-1.545c-8.149 13.106-10.967 29.243-12.576 50.892z"
        fill="#634BCA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M112.694 60.426h2.122c8.845 30.15 11.283 50.239 7.315 60.267H91.385c.486-7.337 2.046-15.097 4.182-22.663-6.963 12.56-12.815 21.602-17.558 27.127l-7.16-1.402c4.612-28.602 17.968-49.711 40.066-63.33h1.779z"
        fill="#2D3844"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M105.11 107.303l10.517-4.889v4.889H105.11z"
        fill="#fff"
        fillOpacity={0.2}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M173.702 77.895l4.571 3.025a1.014 1.014 0 01.093 1.622l-13.355 11.224a1.014 1.014 0 01-1.614-.454l-2.105-6.273c-.141-.419.006-.88.362-1.141l10.888-7.975a1.016 1.016 0 011.16-.028z"
        fill="#242932"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M172.961 79.288l2.64 1.534a1.014 1.014 0 01.143 1.654l-10.373 8.715a1.014 1.014 0 01-1.635-.524l-.959-3.724a1.015 1.015 0 01.374-1.064l8.691-6.525c.326-.244.767-.27 1.119-.066z"
        fill="#0DB14B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M158.843 57.887l4.612 2.767 5.995-3.69 2.767 25.366-6.918 5.534-6.456-29.977z"
        fill="#0DB14B"
        fillOpacity={0.25}
      />
      <path
        d="M165.492 63.424v-2.277c2.522-.44 3.957-2.236 3.957-4.158 0-1.88-1.152-3.51-4.066-4.136l-1.892-.397c-.848-.188-1.283-.627-1.283-1.191 0-.669.609-1.358 1.827-1.358 1.718 0 2.087 1.086 2.218 1.734l3.022-.773c-.304-1.42-1.391-3.071-3.783-3.573v-2.318h-2.501v2.256c-2.283.376-4.11 1.985-4.11 4.324 0 1.985 1.5 3.406 3.936 3.97l1.935.438c.87.189 1.348.585 1.348 1.191 0 .815-.782 1.316-1.87 1.316-1.87 0-2.74-1.086-2.848-2.297l-3.001.647c.152 1.713 1.544 3.886 4.61 4.366v2.236h2.501z"
        fill="#14AA4B"
      />
    </svg>
  )
}
