import dayjs from 'dayjs'

import { type Loan } from '~/domains/credit/shared/entities'

export function getLoanPercentage(loan: Loan) {
  const totalEstimatedAmount = calculateEstimatedAmount(loan)
  const percentual = (loan.totalPaidAmount * 100) / totalEstimatedAmount

  return percentual < 1 ? Math.ceil(percentual) : Math.floor(percentual)
}

export function isLoanFirstMonth(loan: Loan) {
  return dayjs().diff(loan.disbursementDate, 'month') === 0
}

export function applyDecimal(loanValue: number) {
  const DECIMAL = 100

  return loanValue * DECIMAL
}

export function calculateEstimatedAmount(loan: Loan) {
  return loan.totalPaidAmount + loan.currentDebtAmount
}
