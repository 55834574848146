import dayjs from 'dayjs'
import groupBy from 'lodash.groupby'

type CreatedAtObject = { createdAt: string }

const sortByDate = ({ createdAt: first }: CreatedAtObject, { createdAt: second }: CreatedAtObject) =>
  dayjs(first).isBefore(second) ? 1 : 0

const byDate = ({ createdAt }: CreatedAtObject) => dayjs(createdAt).format('DD/MM/YYYY')

const formatResult = ([key, values]: [string, CreatedAtObject[]]) => ({ key, values })

export const groupByDate = (data: CreatedAtObject[]) => {
  const sortedData = data.sort(sortByDate)
  const groupedByDate = groupBy(sortedData, byDate)

  return Object.entries(groupedByDate).map(formatResult)
}
