import { type SVGProps } from 'react'

export function CalendarSuccessIllustration(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="280" height="280" viewBox="0 0 280 280" fill="none" {...props}>
      <path
        d="M188.143 41.5532C140.775 17.8958 106.038 32.7662 71.9333 65.2106C11.9337 122.664 42.8809 246.359 99.091 268.664C155.301 290.97 205.827 238.923 231.09 201.748C256.353 164.572 235.511 65.2106 188.143 41.5532Z"
        fill="#D2F4DE"
      />
      <path
        d="M133.612 54.1455C133.521 52.5128 137.674 53.1604 138.364 53.8407C139.055 54.521 138.689 59.6695 138.73 60.3716C138.77 61.0736 134.668 60.6274 133.973 60.6709C133.277 60.7144 133.785 57.0572 133.612 54.1455Z"
        fill="#103A21"
      />
      <path
        d="M251.086 45.158C251.712 43.6666 255.11 46.3079 255.426 47.2541C255.743 48.2004 253.189 52.5778 252.92 53.2198C252.652 53.8617 249.213 51.4241 248.578 51.1163C247.943 50.8086 249.977 47.8225 251.086 45.158Z"
        fill="#103A21"
      />
      <path
        d="M175.523 30.9335C174.051 30.5046 175.942 26.5189 176.772 26.0091C177.602 25.4994 182.028 27.5416 182.686 27.7334C183.344 27.9252 181.614 31.9408 181.43 32.6612C181.247 33.3816 178.152 31.6886 175.523 30.9335Z"
        fill="#103A21"
      />
      <path
        d="M203.222 230.533C201.77 230.03 203.834 226.144 204.685 225.676C205.536 225.209 209.869 227.471 210.517 227.695C211.166 227.92 209.263 231.844 209.048 232.555C208.833 233.265 205.816 231.419 203.222 230.533Z"
        fill="#103A21"
      />
      <path
        d="M173.715 78.9844C174.289 77.9341 176.604 80.1654 176.761 80.911C176.919 81.6566 174.731 84.6663 174.456 85.1343C174.182 85.6023 171.827 83.5016 171.41 83.2132C170.994 82.9247 172.69 80.8621 173.715 78.9844Z"
        fill="#85AD38"
      />
      <path
        d="M199.789 25.0429C199.082 24.0909 201.857 22.5794 202.563 22.6906C203.268 22.8017 205.099 26.0739 205.408 26.5167C205.717 26.9594 203.04 28.5586 202.639 28.871C202.238 29.1834 201.054 26.7448 199.789 25.0429Z"
        fill="#85AD38"
      />
      <path
        d="M245.809 112.727C246.383 111.677 248.698 113.908 248.855 114.653C249.012 115.399 246.824 118.409 246.55 118.877C246.276 119.345 243.92 117.244 243.504 116.956C243.088 116.667 244.783 114.604 245.809 112.727Z"
        fill="#ACE048"
      />
      <path
        d="M59.1527 115.974C59.7264 114.923 62.0415 117.155 62.1988 117.9C62.3562 118.646 60.168 121.656 59.8939 122.124C59.6197 122.592 57.264 120.491 56.8477 120.202C56.4314 119.914 58.1271 117.851 59.1527 115.974Z"
        fill="#ACE048"
      />
      <path
        d="M56.9207 87.6856C57.8187 88.4304 55.4571 90.605 54.7471 90.6766C54.0371 90.7481 51.5338 88.0354 51.136 87.6838C50.7382 87.3323 52.9842 85.0977 53.3045 84.6922C53.6249 84.2866 55.3153 86.3542 56.9207 87.6856Z"
        fill="#ACE048"
      />
      <path
        d="M203.056 103.209C204.122 102.332 205.726 105.881 205.594 106.784C205.462 107.688 201.787 109.914 201.314 110.305C200.842 110.697 199.121 107.247 198.776 106.735C198.431 106.224 201.152 104.776 203.056 103.209Z"
        fill="#14AA4B"
      />
      <path
        d="M98.4689 12.8653C99.5351 11.9891 101.139 15.5375 101.007 16.4409C100.875 17.3443 97.1997 19.5703 96.7275 19.9621C96.2554 20.354 94.5343 16.9035 94.1891 16.3919C93.8438 15.8803 96.5651 14.4327 98.4689 12.8653Z"
        fill="#14AA4B"
      />
      <path
        d="M39.0695 57.4927C40.1357 56.6165 41.74 60.1649 41.608 61.0683C41.476 61.9718 37.8003 64.1977 37.3281 64.5895C36.856 64.9814 35.1349 61.5309 34.7896 61.0194C34.4444 60.5078 37.1657 59.0601 39.0695 57.4927Z"
        fill="#14AA4B"
      />
      <path
        d="M86.3234 69.1826C84.9222 68.8833 86.5113 64.6709 87.2728 64.0722C88.0344 63.4735 92.299 65.1607 92.9286 65.2804C93.5581 65.4001 92.1163 69.6343 91.9792 70.3908C91.8421 71.1473 88.8365 69.7214 86.3234 69.1826Z"
        fill="#C4F752"
      />
      <path
        d="M246.036 201.917C244.635 201.618 246.224 197.406 246.986 196.807C247.747 196.208 252.012 197.896 252.641 198.015C253.271 198.135 251.829 202.396 251.692 203.126C251.555 203.855 248.549 202.456 246.036 201.917Z"
        fill="#C4F752"
      />
      <path
        d="M215.786 58.8422C214.385 58.5429 215.974 54.3305 216.736 53.7319C217.497 53.1332 221.762 54.8203 222.391 54.9401C223.021 55.0598 221.579 59.294 221.442 60.0504C221.305 60.8069 218.299 59.381 215.786 58.8422Z"
        fill="#C4F752"
      />
      <path
        d="M64.9643 94.8597C64.0251 94.174 66.2589 91.8556 66.9646 91.7141C67.6703 91.5726 70.3205 94.1686 70.7419 94.4788C71.1633 94.789 69.0208 97.1727 68.7111 97.6245C68.4014 98.0762 66.6397 96.0897 64.9643 94.8597Z"
        fill="#85AD38"
      />
      <path
        d="M254.585 216.464C254.895 215.291 257.654 216.828 258.003 217.501C258.351 218.173 256.894 221.691 256.753 222.217C256.612 222.744 253.824 221.321 253.327 221.149C252.829 220.978 254.028 218.557 254.585 216.464Z"
        fill="#85AD38"
      />
      <path
        d="M28.9194 160.168C27.9801 159.482 30.214 157.164 30.9197 157.022C31.6254 156.881 34.2756 159.477 34.697 159.787C35.1184 160.097 32.9759 162.508 32.6916 162.933C32.4073 163.357 30.5948 161.398 28.9194 160.168Z"
        fill="#ACE048"
      />
      <path
        d="M248.357 148.688C249.479 148.852 248.458 151.997 247.89 152.465C247.321 152.934 243.864 151.894 243.361 151.823C242.858 151.753 243.757 148.585 243.828 148.046C243.899 147.508 246.341 148.405 248.357 148.688Z"
        fill="#138E3C"
      />
      <path
        d="M79.1046 158.122C78.1705 158.818 75.8554 154.084 75.7995 153.05C75.7437 152.015 79.13 150.557 79.5463 150.247C79.9626 149.937 82.3793 154.601 82.8514 155.319C83.3236 156.037 80.7699 156.876 79.1046 158.122Z"
        fill="#138E3C"
      />
      <path
        d="M206.295 198.634L193.637 133.826C193.443 132.835 192.071 132.722 191.718 133.668L167.553 198.476C167.309 199.13 167.793 199.826 168.49 199.826H205.313C205.943 199.826 206.415 199.251 206.295 198.634Z"
        fill="#088037"
      />
      <path
        d="M79.7266 131.907C79.9319 130.994 80.7424 130.346 81.6779 130.346H190.456C191.738 130.346 192.689 131.535 192.407 132.785L175.582 207.434C175.377 208.347 174.566 208.995 173.631 208.995L76.8608 209C76.3113 209 75.786 208.774 75.4082 208.375L65.7327 198.155C65.2747 197.671 65.0877 196.991 65.2339 196.341L79.7266 131.907Z"
        fill="white"
      />
      <path d="M85.9537 137.74H179.102L165.076 199.97H71.9277L85.9537 137.74Z" fill="white" />
      <path
        d="M153.204 154.918C153.335 154.369 153.827 153.981 154.392 153.981H163.695C164.486 153.981 165.069 154.72 164.884 155.489L163.026 163.232C162.894 163.781 162.403 164.169 161.837 164.169H152.534C151.743 164.169 151.161 163.43 151.345 162.661L153.204 154.918Z"
        fill="#14AA4B"
      />
      <path
        d="M137.311 154.918C137.443 154.369 137.934 153.981 138.5 153.981H147.803C148.593 153.981 149.176 154.72 148.992 155.489L147.133 163.232C147.002 163.781 146.51 164.169 145.945 164.169H136.641C135.851 164.169 135.268 163.43 135.453 162.661L137.311 154.918Z"
        fill="#DCF2E4"
      />
      <path
        d="M121.418 154.918C121.55 154.369 122.042 153.981 122.607 153.981H131.91C132.701 153.981 133.284 154.72 133.099 155.489L131.241 163.232C131.109 163.781 130.617 164.169 130.052 164.169H120.749C119.958 164.169 119.376 163.43 119.56 162.661L121.418 154.918Z"
        fill="#DCF2E4"
      />
      <path
        d="M105.526 154.918C105.658 154.369 106.149 153.981 106.715 153.981H116.018C116.808 153.981 117.391 154.72 117.207 155.489L115.348 163.232C115.216 163.781 114.725 164.169 114.16 164.169H104.856C104.066 164.169 103.483 163.43 103.668 162.661L105.526 154.918Z"
        fill="#DCF2E4"
      />
      <path
        d="M89.6322 154.918C89.7641 154.369 90.2557 153.981 90.821 153.981H100.124C100.915 153.981 101.497 154.72 101.313 155.489L99.4547 163.232C99.3228 163.781 98.8313 164.169 98.266 164.169H88.9628C88.1722 164.169 87.5895 163.43 87.774 162.661L89.6322 154.918Z"
        fill="#DCF2E4"
      />
      <path
        d="M154.841 147.186C154.969 146.632 155.463 146.239 156.032 146.239H165.34C166.127 146.239 166.708 146.97 166.532 147.736L166.485 147.94C166.357 148.495 165.863 148.888 165.293 148.888H155.985C155.199 148.888 154.617 148.156 154.794 147.39L154.841 147.186Z"
        fill="#14AA4B"
      />
      <path
        d="M138.948 147.186C139.076 146.632 139.57 146.239 140.139 146.239H149.448C150.234 146.239 150.816 146.97 150.639 147.736L150.592 147.94C150.464 148.495 149.97 148.888 149.401 148.888H140.092C139.306 148.888 138.724 148.156 138.901 147.39L138.948 147.186Z"
        fill="#14AA4B"
      />
      <path
        d="M123.055 147.186C123.183 146.632 123.677 146.239 124.246 146.239H133.554C134.34 146.239 134.922 146.97 134.746 147.736L134.698 147.94C134.57 148.495 134.077 148.888 133.507 148.888H124.199C123.413 148.888 122.831 148.156 123.008 147.39L123.055 147.186Z"
        fill="#14AA4B"
      />
      <path
        d="M107.162 147.186C107.29 146.632 107.784 146.239 108.353 146.239H117.662C118.448 146.239 119.03 146.97 118.853 147.736L118.806 147.94C118.678 148.495 118.184 148.888 117.615 148.888H108.306C107.52 148.888 106.938 148.156 107.115 147.39L107.162 147.186Z"
        fill="#14AA4B"
      />
      <path
        d="M91.2695 147.186C91.3975 146.632 91.8915 146.239 92.4607 146.239H101.769C102.555 146.239 103.137 146.97 102.96 147.736L102.913 147.94C102.785 148.495 102.291 148.888 101.722 148.888H92.4137C91.6275 148.888 91.0457 148.156 91.2225 147.39L91.2695 147.186Z"
        fill="#14AA4B"
      />
      <path
        d="M149.944 167.959C150.076 167.409 150.567 167.021 151.133 167.021H160.436C161.226 167.021 161.809 167.761 161.624 168.529L159.766 176.272C159.634 176.822 159.143 177.209 158.577 177.209H149.274C148.484 177.209 147.901 176.47 148.086 175.701L149.944 167.959Z"
        fill="#DCF2E4"
      />
      <path
        d="M134.051 167.959C134.183 167.409 134.675 167.021 135.24 167.021H144.543C145.334 167.021 145.916 167.761 145.732 168.529L143.874 176.272C143.742 176.822 143.25 177.209 142.685 177.209H133.382C132.591 177.209 132.008 176.47 132.193 175.701L134.051 167.959Z"
        fill="#DCF2E4"
      />
      <path
        d="M118.159 167.959C118.291 167.409 118.782 167.021 119.347 167.021H128.651C129.441 167.021 130.024 167.761 129.839 168.529L127.981 176.272C127.849 176.822 127.358 177.209 126.792 177.209H117.489C116.699 177.209 116.116 176.47 116.3 175.701L118.159 167.959Z"
        fill="#DCF2E4"
      />
      <path
        d="M102.266 167.959C102.398 167.409 102.889 167.021 103.455 167.021H112.758C113.549 167.021 114.131 167.761 113.947 168.529L112.089 176.272C111.957 176.822 111.465 177.209 110.9 177.209H101.597C100.806 177.209 100.223 176.47 100.408 175.701L102.266 167.959Z"
        fill="#DCF2E4"
      />
      <path
        d="M86.3734 167.959C86.5053 167.409 86.9969 167.021 87.5622 167.021H96.8654C97.656 167.021 98.2387 167.761 98.0542 168.529L96.1959 176.272C96.064 176.822 95.5725 177.209 95.0072 177.209H85.704C84.9134 177.209 84.3307 176.47 84.5152 175.701L86.3734 167.959Z"
        fill="#DCF2E4"
      />
      <path
        d="M147.091 180.999C147.223 180.45 147.715 180.062 148.28 180.062H157.583C158.374 180.062 158.956 180.801 158.772 181.57L156.914 189.312C156.782 189.862 156.29 190.25 155.725 190.25H146.422C145.631 190.25 145.048 189.511 145.233 188.742L147.091 180.999Z"
        fill="#DCF2E4"
      />
      <path
        d="M131.199 180.999C131.331 180.45 131.822 180.062 132.387 180.062H141.691C142.481 180.062 143.064 180.801 142.879 181.57L141.021 189.312C140.889 189.862 140.398 190.25 139.832 190.25H130.529C129.739 190.25 129.156 189.511 129.34 188.742L131.199 180.999Z"
        fill="#DCF2E4"
      />
      <path
        d="M115.306 180.999C115.438 180.45 115.93 180.062 116.495 180.062H125.798C126.589 180.062 127.171 180.801 126.987 181.57L125.129 189.312C124.997 189.862 124.505 190.25 123.94 190.25H114.637C113.846 190.25 113.263 189.511 113.448 188.742L115.306 180.999Z"
        fill="#DCF2E4"
      />
      <path
        d="M99.4135 180.999C99.5454 180.45 100.037 180.062 100.602 180.062H109.905C110.696 180.062 111.279 180.801 111.094 181.57L109.236 189.312C109.104 189.862 108.613 190.25 108.047 190.25H98.744C97.9534 190.25 97.3707 189.511 97.5552 188.742L99.4135 180.999Z"
        fill="#DCF2E4"
      />
      <path
        d="M83.5209 180.999C83.6528 180.45 84.1444 180.062 84.7097 180.062H94.0129C94.8035 180.062 95.3861 180.801 95.2016 181.57L93.3434 189.312C93.2115 189.862 92.7199 190.25 92.1546 190.25H82.8514C82.0608 190.25 81.4782 189.511 81.6627 188.742L83.5209 180.999Z"
        fill="#DCF2E4"
      />
      <path
        d="M82 209L74.4263 199L73 204.259L74.6559 207.399C75.1751 208.384 76.1966 209 77.3096 209H82Z"
        fill="#EAF0EC"
      />
      <path
        d="M75.5 208.5L65 197.5C65 197.5 77.2064 197 75.3727 198.63C73.5389 200.26 75.5 208.5 75.5 208.5Z"
        fill="#14AA4B"
      />
      <path
        d="M213.192 91.1222C213.274 89.9794 212.362 89.0104 211.217 89.0226L162.017 89.546C160.96 89.5572 160.102 90.4053 160.079 91.4625L159.044 138.235C159.02 139.334 159.904 140.238 161.004 140.238H170.57C170.953 140.238 171.234 140.599 171.139 140.971L168.048 153.149C167.873 153.837 168.631 154.385 169.23 154.002L191.069 140.047C191.164 139.987 191.274 139.955 191.386 139.955H207.878C208.906 139.955 209.759 139.16 209.833 138.135L213.192 91.1222Z"
        fill="#14AA4B"
      />
      <path
        d="M186.171 120.131H184.128C182.964 120.131 182.017 119.062 182.017 117.749C182.017 116.435 182.964 115.366 184.128 115.366H188.214C188.741 115.366 189.168 114.884 189.168 114.29C189.168 113.695 188.741 113.213 188.214 113.213H186.103V110.831C186.103 110.236 185.676 109.754 185.149 109.754C184.623 109.754 184.195 110.236 184.195 110.831V113.213H184.128C181.912 113.213 180.109 115.248 180.109 117.749C180.109 120.249 181.912 122.284 184.128 122.284H186.171C187.335 122.284 188.282 123.353 188.282 124.666C188.282 125.98 187.335 127.049 186.171 127.049H182.085C181.558 127.049 181.131 127.531 181.131 128.125C181.131 128.72 181.558 129.202 182.085 129.202H184.195V131.585C184.195 132.179 184.623 132.661 185.149 132.661C185.676 132.661 186.103 132.179 186.103 131.585V129.202H186.171C188.386 129.202 190.189 127.167 190.189 124.666C190.189 122.166 188.386 120.131 186.171 120.131Z"
        fill="white"
      />
      <path
        d="M167.208 110.509V116.766H173.045"
        stroke="white"
        strokeWidth="2.03753"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M170.913 126.036C171.986 129.264 174.021 132.036 176.71 133.933C179.399 135.829 182.597 136.749 185.822 136.552C189.047 136.355 192.125 135.053 194.591 132.842C197.057 130.631 198.778 127.63 199.495 124.292C200.212 120.955 199.885 117.46 198.565 114.336C197.245 111.212 195.002 108.627 192.175 106.971C189.348 105.315 186.089 104.677 182.89 105.154C179.699 105.63 176.739 107.189 174.455 109.598L167.558 116.466"
        stroke="white"
        strokeWidth="2.03753"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="133" cy="135" r="3" fill="#1D242B" />
      <circle cx="111" cy="135" r="3" fill="#1D242B" />
      <circle cx="154" cy="135" r="3" fill="#1D242B" />
      <rect width="4" height="10.6667" rx="2" transform="matrix(1 0 0 -1 131 136.333)" fill="#14AA4B" />
      <rect width="4" height="10.6667" rx="2" transform="matrix(1 0 0 -1 109 136.333)" fill="#14AA4B" />
      <rect width="4" height="10.6667" rx="2" transform="matrix(1 0 0 -1 152 136.333)" fill="#14AA4B" />
      <path
        d="M98.5 151.5C92 149.5 87 154.357 87 158.5C87 162.642 90.3579 166 94.5 166C98.6421 166 102 162.642 102 158.5C102 156.046 100.5 153.5 98 153"
        stroke="#E74C3C"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
    </svg>
  )
}
