export function PinBlockedFaceAnalysisIllustration() {
  return (
    <svg width="211" height="183" viewBox="0 0 211 183" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M151.973 1.05907L8.92372 18.0345C3.99529 18.6194 0.474117 23.0888 1.05897 28.0172L17.6999 168.247C18.2848 173.176 22.7542 176.697 27.6826 176.112L170.732 159.137C175.66 158.552 179.181 154.082 178.596 149.154L170.276 79.0389L161.955 8.92382C161.371 3.99539 156.901 0.474219 151.973 1.05907Z"
        fill="#767F8D"
        fillOpacity="0.1"
      />
      <path
        d="M108.748 90.147C108.317 87.4825 108.844 84.8532 110.104 82.6204C111.174 80.7078 112.776 79.1041 114.781 78.0105C115.64 77.5366 116.55 77.1683 117.548 76.901C124.011 75.1297 130.662 78.8044 132.402 85.108C133.792 90.0767 131.693 95.1991 127.563 97.9955C127.351 98.1324 127.1 98.2736 126.888 98.4105C126.855 98.4516 126.779 98.4605 126.746 98.5017C126.354 98.7342 125.996 98.9255 125.557 99.0891C125.157 99.2483 124.756 99.4074 124.314 99.5343C124.168 99.5889 124.022 99.6434 123.871 99.6612C123.65 99.7247 123.429 99.7882 123.203 99.8149C123.166 99.8194 123.09 99.8283 123.053 99.8328C122.719 99.9096 122.343 99.9542 122.004 99.9944L121.967 99.9989C116.171 100.649 110.603 97.1065 109.013 91.4176C108.927 91.0185 108.838 90.5828 108.748 90.147Z"
        fill="#8F5049"
      />
      <path
        d="M125.675 92.4696C123.628 91.4427 122.128 89.6196 121.709 87.6298C121.289 85.6401 121.946 83.4457 123.443 81.7672C123.605 81.594 123.877 81.5618 124.121 81.6868C124.32 81.8171 124.347 82.0448 124.188 82.256C122.854 83.7612 122.261 85.7172 122.654 87.4792C123.005 89.2846 124.343 90.8961 126.191 91.7927C126.39 91.923 126.462 92.1454 126.349 92.3512C126.313 92.4324 126.227 92.4811 126.182 92.4865C126.005 92.5459 125.825 92.5674 125.675 92.4696Z"
        fill="#1D232A"
      />
      <path
        d="M130.762 104.765C128.97 101.259 125.574 99.4996 123.194 100.806C120.814 102.113 120.339 106.04 122.131 109.546C123.923 113.052 127.319 114.812 129.699 113.505C132.039 112.165 132.554 108.271 130.762 104.765Z"
        fill="#F1BD38"
      />
      <path
        d="M74.192 93.8507C73.9933 99.8887 69.4236 104.76 63.6418 105.523L63.6042 105.527C63.2657 105.567 62.8897 105.612 62.5468 105.614C62.5092 105.619 62.434 105.628 62.3964 105.632C62.1663 105.621 61.9407 105.648 61.7106 105.637C61.5557 105.617 61.4053 105.635 61.2504 105.615C60.8278 105.588 60.3631 105.529 59.9361 105.464C59.509 105.4 59.0774 105.298 58.6414 105.158C58.5993 105.125 58.5241 105.134 58.482 105.1C58.2429 105.014 57.9663 104.932 57.7272 104.845C53.0498 103.025 49.7928 98.3934 49.9638 93.0865C50.1569 86.3597 55.7903 81.1325 62.4497 81.3382C63.4829 81.3689 64.4543 81.5217 65.4016 81.7924C67.6104 82.4114 69.5526 83.6749 71.0417 85.2987C72.7969 87.2357 73.9335 89.7441 74.1479 92.5151C74.1972 92.9307 74.1758 93.3929 74.192 93.8507Z"
        fill="#8F5049"
      />
      <path
        d="M95.7135 117.056C82.9629 119.204 70.7414 113.105 64.601 102.658C64.601 102.658 64.601 102.658 64.5967 102.621C64.1702 101.887 63.781 101.148 63.4289 100.405C63.3284 100.193 63.2278 99.9806 63.1272 99.7683C63.0222 99.5191 62.9129 99.2331 62.8079 98.9839C62.7489 98.8041 62.6527 98.6286 62.5936 98.4487C62.4843 98.1627 62.3793 97.9136 62.27 97.6275C61.7058 96.05 61.2859 94.4179 60.9689 92.6989C60.3238 88.8511 60.4352 85.0256 61.2137 81.42C61.334 80.8451 61.4914 80.2658 61.6488 79.6865C61.6445 79.6496 61.6773 79.6084 61.6729 79.5715C64.7364 68.8551 73.7783 60.2324 85.604 58.231C88.1515 57.7792 90.7055 57.7004 93.1787 57.8928C101.057 58.5276 108.245 62.2344 113.334 67.9469C114.641 69.4362 115.813 71.0537 116.808 72.767C118.518 75.741 119.739 79.0347 120.349 82.5877C123.146 98.8502 112.122 114.286 95.7135 117.056Z"
        fill="#8F5049"
      />
      <path
        d="M97.3609 108.478C101.975 107.554 103.723 103.813 104.261 99.4257C101.152 99.3435 99.4513 100.297 97.5168 103.422C94.5015 101.261 87.3044 102.077 84.5032 103.312C86.6641 107.191 92.7465 109.401 97.3609 108.478Z"
        fill="#804BC3"
      />
      <path
        d="M105.379 87.9148C105.304 87.9237 105.266 87.9281 105.229 87.9326C105.116 87.946 104.966 87.9638 104.857 88.0138C104.782 88.0227 104.745 88.0272 104.67 88.0361C104.444 88.0628 104.219 88.0896 103.997 88.1529C103.734 88.1841 103.471 88.2154 103.208 88.2466C101.1 88.4597 99.1816 88.3534 97.7593 87.9286C96.3037 87.5448 95.3902 86.9113 95.1111 86.1653C95.1068 86.1287 95.1068 86.1287 95.1068 86.1287L95.1024 86.0921L95.0981 86.0555L95.0938 86.0189C95.0894 85.9824 95.0851 85.9458 95.0807 85.9092C94.9014 85.0401 95.4649 84.0086 96.7844 82.9246C98.1082 81.8771 100.051 80.9046 102.375 80.2578C102.596 80.1944 102.854 80.1266 103.113 80.0588C103.409 79.9866 103.743 79.9099 104.04 79.8376C104.077 79.8331 104.115 79.8287 104.19 79.8198C104.228 79.8153 104.265 79.8108 104.34 79.8019C104.453 79.7885 104.566 79.7752 104.674 79.7252C105.083 79.6396 105.534 79.5861 105.91 79.5415C108.273 79.2239 110.421 79.34 111.998 79.7836C113.612 80.2227 114.539 80.966 114.714 81.7986C114.808 82.2697 114.685 82.8408 114.333 83.402C113.98 83.9632 113.364 84.5557 112.556 85.1339C110.941 86.2902 108.543 87.2796 105.755 87.8702C105.717 87.8746 105.68 87.8791 105.642 87.8835C105.488 87.8648 105.412 87.8737 105.379 87.9148ZM95.0186 86.0279C94.981 86.0323 95.0186 86.0279 95.0186 86.0279V86.0279Z"
        fill="#FCFCFC"
      />
      <path
        d="M89.5445 87.0426C89.6514 87.943 89.0448 88.9281 87.707 89.8478C86.4068 90.763 84.446 91.5664 82.1822 92.0633C82.1071 92.0722 82.032 92.0811 81.9945 92.0856C81.9569 92.09 81.9569 92.09 81.9194 92.0945C81.8067 92.1078 81.6941 92.1212 81.5859 92.1721C81.2524 92.2497 80.9145 92.2898 80.5766 92.3299C80.3888 92.3522 80.2386 92.37 80.0884 92.3878C79.9758 92.4012 79.8631 92.4146 79.7505 92.4279C79.5628 92.4502 79.4126 92.468 79.2248 92.4903C79.1873 92.4948 79.1497 92.4992 79.1497 92.4992C79.1122 92.5037 79.1122 92.5037 79.0746 92.5081C76.3578 92.7164 73.921 92.3968 72.171 91.7675C72.1334 91.772 72.129 91.7344 72.0914 91.7389C70.6551 91.1865 69.8437 90.4457 69.6331 89.6337C69.6287 89.5962 69.6287 89.5962 69.6242 89.5587C69.6198 89.5212 69.6198 89.5212 69.6153 89.4836C69.6153 89.4836 69.6109 89.4461 69.6064 89.4086C69.504 88.5457 70.0775 87.6026 71.3402 86.6918C72.6029 85.7811 74.451 84.991 76.6397 84.503C76.7899 84.4852 76.8981 84.4343 77.0482 84.4165C77.0858 84.4121 77.1233 84.4076 77.1609 84.4031C77.236 84.3942 77.3111 84.3853 77.3862 84.3764C77.6115 84.3497 77.8699 84.281 78.0951 84.2542C78.4331 84.2141 78.729 84.141 79.0714 84.1384C79.1089 84.1339 79.1465 84.1295 79.1465 84.1295C79.3342 84.1072 79.522 84.0849 79.7097 84.0626C82.2719 83.8347 84.5827 84.055 86.3569 84.5674C88.1356 85.1172 89.2232 85.9393 89.4802 86.8219C89.4892 86.897 89.5356 86.9675 89.5445 87.0426Z"
        fill="#FCFCFC"
      />
      <path
        d="M89.673 97.5247C89.5115 97.5074 89.4158 97.3727 89.3594 97.2333C89.3338 97.0172 89.4696 96.8185 89.7054 96.7905L94.1987 96.0382C95.2161 95.8809 96.0643 95.3055 96.6033 94.4747C97.103 93.6485 97.1884 92.6889 96.8457 91.8166L93.6786 84.2678C93.6136 84.0564 93.7102 83.8624 93.8981 83.7671C94.1296 83.7031 94.3389 83.7878 94.4388 83.9585L97.606 91.5072C98.0528 92.5863 97.9581 93.8026 97.3226 94.8275C96.6871 95.8524 95.6159 96.5639 94.3627 96.7491L89.8694 97.5014C89.7951 97.5468 89.7516 97.5154 89.673 97.5247Z"
        fill="#1D232A"
      />
      <path
        d="M96.1744 106.554C92.9063 106.942 89.0234 105.959 84.7065 103.538C84.5054 103.431 84.4376 103.176 84.5234 102.947C84.6092 102.718 84.8269 102.649 85.0279 102.756C94.1753 107.798 101.16 106.357 104.182 98.82C104.268 98.5909 104.486 98.5213 104.687 98.6288C104.888 98.7362 104.956 98.9908 104.87 99.2198C103.133 103.628 100.111 106.087 96.1744 106.554Z"
        fill="#1D232A"
      />
      <path
        d="M57.7321 98.4956C57.6379 98.4684 57.5886 98.4358 57.5392 98.4032C57.3418 98.2729 57.3552 98.0024 57.5615 97.8242C59.1354 96.5232 60.0051 94.6525 59.9686 92.8125C59.8874 90.9778 58.9046 89.2117 57.2716 88.0991C57.0742 87.9688 57.0427 87.7036 57.2041 87.5308C57.3656 87.3579 57.6795 87.3206 57.8769 87.4509C59.7118 88.7317 60.8561 90.7092 60.915 92.7386C60.9739 94.7681 59.9967 96.882 58.2165 98.3613C58.0954 98.4909 57.9205 98.5501 57.7321 98.4956Z"
        fill="#1D232A"
      />
      <path
        d="M176.807 158.491C176.465 158.171 176.161 157.847 175.856 157.523C170.787 151.968 164.058 148.122 164.058 148.122C155.845 152.589 150.047 156.59 144.494 162.325L176.807 158.491Z"
        fill="#8F5049"
      />
      <path
        d="M75.657 170.458C75.6669 169.579 75.5557 168.642 75.2901 167.686C74.7042 165.636 73.2134 163.656 70.1109 162.525C58.2185 158.198 46.0526 157.338 33.3116 158.046C33.3116 158.046 32.0713 164.59 30.9378 172.036C30.7455 173.301 30.5532 174.567 30.3651 175.869L75.6613 170.494L75.657 170.458Z"
        fill="#8F5049"
      />
      <path
        opacity="0.47"
        d="M173.648 155.371C169.219 150.818 165.084 145.903 165.084 145.903C159.697 149.662 154.713 153.664 150.272 158.145L173.648 155.371Z"
        fill="#1D232A"
      />
      <path
        opacity="0.58"
        d="M49.1611 168.047L69.8057 161.59C57.9296 157.656 46.076 157.345 33.7159 158.697C33.7159 158.697 32.1006 161.942 31.5546 168.266L49.1611 168.047Z"
        fill="#1D232A"
      />
      <path
        d="M38.3255 168.413C42.3113 170.271 45.5508 171.551 48.6671 173.697L169.38 159.372C171.904 155.891 173.661 151.983 174.142 147.783C166.774 145.661 161.046 143.085 154.925 134.341C152.158 130.378 142.054 118.851 124.238 120.706C108.469 122.355 68.6848 126.3 63.1256 127.366C54.9104 128.896 49.9631 131.629 44.1499 137.535C41.428 140.299 35.5986 147.021 32.9597 149.85C29.9141 153.06 23.6643 154.652 23.6643 154.652C23.6643 154.652 25.6105 162.486 38.3255 168.413Z"
        fill="#FE6A34"
      />
      <path
        d="M57.365 171.966L56.6323 156.333C56.6114 156.158 56.3894 156.006 56.1379 156.036C55.8863 156.066 55.6515 156.236 55.6723 156.412L56.4092 172.079L57.365 171.966Z"
        fill="#1D232A"
      />
      <path
        d="M79.2259 82.0056C81.8641 81.6543 83.8995 80.2672 83.7036 78.954C83.5033 77.6031 81.2088 76.8063 78.575 77.1952C75.9368 77.5464 73.9014 78.9335 74.0973 80.2467C74.2931 81.5599 76.5876 82.3568 79.2259 82.0056Z"
        fill="#1D232A"
      />
      <path
        d="M103.692 78.3793C106.118 77.8647 107.877 76.4099 107.649 75.1151C107.384 73.8247 105.187 73.1414 102.76 73.6559C100.334 74.1705 98.5754 75.6254 98.8033 76.9201C99.0679 78.2105 101.261 78.8566 103.692 78.3793Z"
        fill="#1D232A"
      />
      <path
        d="M116.464 73.0087C113.06 73.2986 108.492 69.462 108.218 64.3163C105.795 67.8402 101.344 70.6529 97.5993 70.6023C96.4018 70.5921 90.749 68.6358 93.6128 63.1558C91.6723 66.9651 78.1462 75.8425 71.5694 71.1402C69.0382 77.5326 63.9108 81.2632 61.5608 80.3617C61.6783 79.7767 61.8326 79.1872 61.987 78.5978C61.9825 78.5602 62.015 78.5183 62.0105 78.4808C65.0157 67.5774 73.9585 58.8251 85.6838 56.8245C88.2096 56.3724 90.7431 56.3002 93.1972 56.504C101.015 57.1754 108.158 60.9729 113.224 66.8064C114.526 68.3271 115.468 71.2233 116.464 73.0087Z"
        fill="#1D232A"
      />
      <path
        d="M59.2088 119.773C61.8268 120.487 64.7143 117.981 65.6353 114.153C66.5563 110.326 65.1755 106.619 62.5574 105.905C59.9394 105.192 57.0519 107.697 56.1309 111.525C55.2099 115.353 56.5907 119.059 59.2088 119.773Z"
        fill="#F1BD38"
      />
      <path
        d="M101.898 33.1053C103.723 41.2515 98.6458 58.2047 87.5967 60.6785C76.5476 63.1522 63.7745 50.1549 61.9496 42.0086C60.1247 33.8623 67.6096 25.2488 78.6263 22.8164C89.6386 20.347 100.073 24.959 101.898 33.1053Z"
        fill="#1D232A"
      />
      <path
        d="M152.662 160.657L149.606 141.783C149.585 141.603 149.414 141.441 149.27 141.495C149.121 141.512 148.993 141.71 149.044 141.886L152.096 160.724L152.662 160.657V160.657Z"
        fill="#1D232A"
      />
      <path
        d="M81.0423 95.7491C81.2317 95.7883 81.4245 95.6831 81.4635 95.4933C81.5026 95.3035 81.3975 95.1102 81.2082 95.071C81.0188 95.0317 80.826 95.1369 80.787 95.3267C80.7479 95.5165 80.853 95.7098 81.0423 95.7491Z"
        fill="#1D232A"
      />
      <path
        d="M85.1675 95.2595C85.3568 95.2988 85.5496 95.1936 85.5886 95.0038C85.6277 94.814 85.5227 94.6207 85.3333 94.5815C85.1439 94.5422 84.9512 94.6474 84.9121 94.8372C84.8731 95.027 84.9781 95.2203 85.1675 95.2595Z"
        fill="#1D232A"
      />
      <path
        d="M102.478 94.251C102.667 94.2903 102.86 94.1851 102.899 93.9953C102.938 93.8055 102.833 93.6122 102.644 93.5729C102.454 93.5337 102.261 93.6389 102.222 93.8287C102.183 94.0185 102.286 94.1915 102.478 94.251Z"
        fill="#1D232A"
      />
      <path
        d="M103.493 96.9263C103.682 96.9655 103.875 96.8604 103.914 96.6706C103.953 96.4808 103.848 96.2875 103.658 96.2482C103.469 96.209 103.276 96.3141 103.237 96.504C103.198 96.6938 103.303 96.887 103.493 96.9263Z"
        fill="#1D232A"
      />
      <path
        d="M106.159 95.9111C106.349 95.9503 106.543 95.845 106.582 95.6552C106.621 95.4654 106.516 95.2721 106.326 95.2329C106.136 95.1937 105.943 95.299 105.903 95.4888C105.864 95.6787 105.99 95.8695 106.159 95.9111Z"
        fill="#1D232A"
      />
      <path
        d="M75.5827 96.7437C75.7721 96.7829 75.9649 96.6778 76.0039 96.488C76.043 96.2981 75.9379 96.1049 75.7486 96.0656C75.5592 96.0264 75.3664 96.1315 75.3274 96.3213C75.2883 96.5111 75.391 96.6841 75.5827 96.7437Z"
        fill="#1D232A"
      />
      <path
        d="M77.9711 99.2561C78.161 99.2952 78.3544 99.19 78.3936 99.0002C78.4329 98.8103 78.3276 98.6171 78.1377 98.5779C77.9477 98.5387 77.7543 98.644 77.7151 98.8338C77.6734 99.0033 77.799 99.1942 77.9711 99.2561Z"
        fill="#1D232A"
      />
      <path
        d="M107.813 92.2202C108.003 92.2594 108.196 92.1541 108.236 91.9643C108.275 91.7744 108.17 91.5812 107.98 91.542C107.79 91.5028 107.596 91.6081 107.557 91.7979C107.518 91.9877 107.643 92.1786 107.813 92.2202Z"
        fill="#1D232A"
      />
      <path
        d="M83.8098 91.5942C84.022 91.3832 84.2817 90.9916 84.4156 90.8234C85.0692 90.0023 84.9064 89.4365 84.7931 88.1488C84.6624 86.7145 83.8249 83.654 82.4215 83.8205C80.1192 83.9946 78.1464 84.1048 75.9895 84.5838C73.8376 86.4377 74.0563 90.6125 75.7856 92.5263C77.332 92.5659 80.7978 92.6506 83.8098 91.5942Z"
        fill="#1D232A"
      />
      <path
        d="M109.311 83.259C109.129 82.0313 108.32 80.2791 107.456 79.3527C106.453 79.3615 103.492 79.577 100.628 80.8355C98.9449 82.9826 99.5393 87.6888 102.23 88.2881C102.265 88.2839 102.3 88.2797 102.34 88.3118C103.764 88.2163 106.386 87.9418 108.781 86.9595C109.443 85.8888 109.509 84.6317 109.311 83.259Z"
        fill="#1D232A"
      />
      <g filter="url(#filter0_d_10688_19114)">
        <path
          d="M177.911 176.137C195.783 172.656 207.468 155.321 204.008 137.417C200.549 119.514 183.256 107.822 165.383 111.303C147.511 114.784 135.827 132.12 139.286 150.023C142.745 167.927 160.038 179.618 177.911 176.137Z"
          fill="#E8E9EA"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M168.402 128.094C159.925 129.745 154.383 137.967 156.024 146.459C157.665 154.95 165.867 160.495 174.344 158.844C182.821 157.193 188.363 148.971 186.722 140.479C185.081 131.988 176.879 126.443 168.402 128.094ZM152.187 147.206C150.136 136.592 157.063 126.314 167.659 124.25C178.256 122.186 188.508 129.118 190.559 139.732C192.61 150.346 185.683 160.624 175.086 162.688C164.49 164.752 154.238 157.82 152.187 147.206Z"
        fill="#00A868"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M169.516 133.859C170.576 133.653 171.601 134.346 171.806 135.408L173.124 142.228L178.585 144.368C179.59 144.762 180.085 145.898 179.691 146.905C179.297 147.912 178.163 148.409 177.158 148.015L170.696 145.482C170.073 145.238 169.618 144.691 169.491 144.032L167.969 136.155C167.764 135.094 168.457 134.066 169.516 133.859Z"
        fill="#00A868"
      />
      <defs>
        <filter
          id="filter0_d_10688_19114"
          x="135.679"
          y="109.69"
          width="71.936"
          height="72.0605"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.290196 0 0 0 0 0.309804 0 0 0 0 0.333333 0 0 0 0.32 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10688_19114" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_10688_19114" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
