import { stringFormat } from '~/lib/helpers/utils/string-format'

import { type Person, type Subject } from '../ports'

import { personFingerprint } from './assertions'

export const parseSubjectToPerson = (subject: Subject): Person => {
  return {
    [personFingerprint]: true,
    id: subject.id,
    email: subject.email,
    displayName: subject.displayName,
    documentType: stringFormat.documentType(subject.document),
    document: subject.document,
    roles: subject.unsafe.roles,
    approvalStatus: subject.approvalStatus,
    createdAt: subject.createdAt,
    unsafe: {
      fullName: subject.unsafe.fullName,
      legalName: subject.unsafe.legalName
    }
  }
}
