import { type NextPageContext } from 'next'
import authSDKFactory from '@dlpco/conta-stone-sdk'
import { Mutex } from 'async-mutex'

import { APP_VERSION, BANKING_API_ENDPOINT, DEPLOY_TYPE } from '~/lib/constants'
import { isomorphicCookies } from '~/lib/helpers/utils/cookies'

import { Bucket } from '../../lib/bucket'
import { clientSideOnly } from '../../lib/client-side-only'

const authSDK = authSDKFactory(BANKING_API_ENDPOINT())
const mutex = new Mutex()

interface ParseToken {
  document?: string
  email?: string
  iss: string
  name?: string
  stone_subject_id: `user:${string}`
  target?: string
  exp: number
  sub?: string
}

interface AuthenticationTokens {
  refreshToken: string
  token: string
}

async function getValidTokens(ctx?: NextPageContext, SDK = authSDK): Promise<AuthenticationTokens> {
  try {
    await mutex.acquire()
    const cookies = isomorphicCookies.getAll(ctx)
    if (isSessionValid()) return { token: cookies.token, refreshToken: cookies.refreshToken }
    const newTokens = await revalidateSession(ctx, SDK)
    return newTokens
  } finally {
    mutex.release()
  }
}

async function revalidateSession(ctx?: NextPageContext, SDK = authSDK) {
  const { token, refreshToken } = isomorphicCookies.getAll(ctx)
  const newTokens = (await SDK.services.requestRefreshToken({ token, refreshToken })) as AuthenticationTokens
  isomorphicCookies.set('token', newTokens.token, { ctx, path: '/', maxAge: 604800 })
  isomorphicCookies.set('refreshToken', newTokens.refreshToken, { ctx, path: '/', maxAge: 604800 })
  return newTokens
}

function isSessionValid(ctx?: NextPageContext, SDK = authSDK) {
  const { token } = isomorphicCookies.getAll(ctx)
  if (!token) return false
  return nowInSeconds() - expiration() <= 60

  function expiration() {
    return parseToken({ token }, SDK).exp
  }
  function nowInSeconds() {
    return new Date().getTime() / 1000
  }
}

async function encryptWithPublickey<T = unknown>(payload: T, SDK = authSDK): Promise<string> {
  const publicKey = await SDK.services.fetchPublicKey()
  return SDK.encrypt({ publicKey, payload })
}

async function login(
  {
    username,
    password,
    captcha,
    headers,
    ...rest
  }: {
    username: string
    password: string
    captcha?: string
    headers?: {
      [key: string]: string
    }
  },
  SDK = authSDK
): Promise<void> {
  let platformId = clientSideOnly(() => Bucket.local.get('platform::id'))
  let deviceGeneratedId = clientSideOnly(() => Bucket.local.get('device::generated::id'))

  if (!platformId) platformId = await SDK.deviceIds.requestPlatformId()
  if (!deviceGeneratedId) deviceGeneratedId = await SDK.deviceIds.requestDeviceId()

  clientSideOnly(() => Bucket.local.set('platform::id', String(platformId)))
  clientSideOnly(() => Bucket.local.set('device::generated::id', String(deviceGeneratedId)))

  const payload = {
    ...rest,
    username,
    password,
    captcha,
    client_id: 'abc_web@openbank.stone.com.br'
  }

  const { token, refreshToken } = await SDK.services.requestLogin({
    payload,
    headers,
    platformId,
    deviceGeneratedId
  })

  isomorphicCookies.set('token', token, { path: '/', maxAge: 10800 })
  isomorphicCookies.set('refreshToken', refreshToken, { path: '/', maxAge: 10800 })
}

async function logout({ redirectUrl }: { redirectUrl: string }, SDK = authSDK): Promise<void> {
  const { token, refreshToken } = isomorphicCookies.getAll()
  isomorphicCookies.batchRemove(['token', 'refreshToken', 'identityId', 'accountId', 'idAssessment'])

  // We check APP_VERSION to redirect on PR Preview
  const redirect = DEPLOY_TYPE() === 'preview' ? `/${APP_VERSION()}/${redirectUrl}` : redirectUrl
  await SDK.services.requestLogout(token, refreshToken, redirect)
}

async function resetPassword(
  {
    username,
    captcha,
    headers
  }: {
    username: string
    captcha?: string
    headers?: {
      [key: string]: string
    }
  },
  SDK = authSDK
): Promise<void> {
  return SDK.services.resetPassword({
    username,
    captcha,
    headers
  })
}

async function updatePassword(
  {
    token,
    newPassword
  }: {
    token: string
    newPassword: string
  },
  SDK = authSDK
): Promise<void> {
  return SDK.services.updatePassword(token, newPassword)
}

function parseToken({ token }: Pick<AuthenticationTokens, 'token'>, SDK = authSDK): ParseToken {
  return SDK.token.tryParseToken(token)
}

export const authenticator = {
  encryptWithPublickey,
  getValidTokens,
  login,
  logout,
  parseToken,
  resetPassword,
  updatePassword,
  isSessionValid,
  revalidateSession
}
