export function XboxLiveIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.985 12.053c.015 0 .03.015.045.03 4.488 3.407 12.158 11.842 9.816 14.214-5.643 4.938-14.094 4.938-19.737 0-2.356-2.387 5.328-10.822 9.816-14.214.03-.015.045-.03.06-.03zM22.806 2.206C20.509.841 18.018 0 14.986 0c-3.017 0-5.509.826-7.82 2.206-.03.015-.045.045-.03.075s.045.03.075.03c2.897-.63 7.324 1.892 7.73 2.132.015.015.045.015.06 0 .42-.255 4.818-2.762 7.73-2.132.03.015.06 0 .075-.03.03-.03.015-.06 0-.075zM4.494 4.323c-.03.015-.045.03-.06.045C-.91 9.666-1.495 18.116 3.068 24.09c.015.03.045.03.075.03.03-.015.03-.045.015-.075-1.14-3.527 4.698-12.098 7.73-15.685.015-.015.015-.03.015-.045s-.015-.045-.03-.045C6.31 3.707 4.749 4.188 4.493 4.323zM19.098 8.255c-.015.015-.03.03-.03.045s.015.03.015.045c3.002 3.587 8.855 12.158 7.73 15.685-.015.03-.015.06.015.075s.06 0 .075-.03c4.548-5.989 3.962-14.41-1.366-19.723-.015-.015-.045-.03-.06-.045-.255-.12-1.816-.6-6.38 3.948z"
        fill="#107C10"
      />
    </svg>
  )
}
