export const HomeModalNewPageIllustration = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={242} height={180} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#HomeModalNewPageIllustration)">
      <path
        d="M189.913 179.998h-78.721l10.763-47.221c1.865-6.243 6.161-8.943 9.964-9.838 5.686-1.339 30.492-1.785 37.223 0 6.73 1.786 8.965 5.611 9.969 10.002l10.802 47.057Z"
        fill="#1D232A"
      />
      <path
        d="m171.261 115.935-46.4 2.414c-6.382.333-11.824-4.584-12.154-10.98l-1.475-31.09c-.331-6.395 4.574-11.85 10.955-12.182l13.289-.692 23.548-1.225 9.564-.497c6.381-.33 11.823 4.584 12.152 10.98l1.476 31.09c.331 6.396-4.572 11.85-10.955 12.182Z"
        fill="#00A769"
      />
      <path
        d="m182.216 103.753-.103-2.134-34.475 7.215 34.163-13.776-.359-7.542-48.399 9.032 47.746-22.795-.052-1.09a11.558 11.558 0 0 0-1.965-5.891l-34.96 10.178 30.652-14.003a11.49 11.49 0 0 0-5.879-1.264l-9.564.497-16.643.865-29.659 6.916a11.553 11.553 0 0 0-1.491 6.3l41.142-9.593-40.715 18.6.331 6.985 42.536-12.383-42.166 20.134.297 6.248 48.197-8.995-44.519 17.953a11.517 11.517 0 0 0 8.528 3.141l7.48-.388 49.425-10.344c.357-1.224.521-2.523.452-3.866Z"
        fill="#42EC9A"
      />
      <path
        d="M177.516 31.017c1.929-12.388-8.793-24.353-23.948-26.724-15.156-2.371-29.005 5.75-30.935 18.139-1.929 12.388 8.794 24.353 23.949 26.724 15.156 2.37 29.005-5.75 30.934-18.139Z"
        fill="#1D232A"
      />
      <path
        d="m131.552 47.369-3.077-.713-1.232-.286-.241-.056-.55-.128a1.92 1.92 0 0 1-.152-.038c-.078-.018-.151-.04-.226-.059a7.43 7.43 0 0 1-.698-.233 8.21 8.21 0 0 1-.947-.44 7.309 7.309 0 0 1-.362-.214 6.438 6.438 0 0 1-.332-.216c-2.613-1.81-4.007-5.102-3.251-8.378.958-4.154 5.039-6.811 9.18-6.06.126.024.251.047.376.078l5.103 1.182-3.591 15.561ZM163.155 36.708l5.058.918c4.234.77 7.069 4.87 6.303 9.113-.767 4.244-4.859 7.085-9.091 6.318l-5.058-.918 2.788-15.431Z"
        fill="#8F5049"
      />
      <path
        d="M163.093 44.252a.288.288 0 0 0-.526.227c.534 1.788 1.051 3.63 1.55 5.412l.204.727a214.665 214.665 0 0 1-1.658-3.949.291.291 0 0 0-.335-.174.29.29 0 0 0-.22.31c.198 2.106.49 4.006.9 5.813l.914.167-.05-.344c.044.12.091.24.139.359l1.413.257c.066.012.133.023.199.033-.322-1.123-.64-2.252-.949-3.357-.264-.936-.53-1.89-.804-2.845a49.779 49.779 0 0 1 2.682 6.289 6.9 6.9 0 0 0 .61-.002 50.137 50.137 0 0 0-4.069-8.923ZM124.879 42.61a.29.29 0 0 1-.226-.472 6.6 6.6 0 0 0 1.417-3.913 6.609 6.609 0 0 0-1.151-4 .29.29 0 0 1 .477-.33 7.185 7.185 0 0 1 1.253 4.35 7.19 7.19 0 0 1-1.543 4.253.289.289 0 0 1-.227.113ZM167.357 49.773a.29.29 0 0 1-.282-.226 7.2 7.2 0 0 1 .438-4.505 7.162 7.162 0 0 1 3.002-3.382.29.29 0 1 1 .289.502 6.595 6.595 0 0 0-2.761 3.112 6.625 6.625 0 0 0-.405 4.144.29.29 0 0 1-.281.355ZM126.41 27.67l3.334 11.56 1.795-10.384-5.129-1.176ZM171.709 35.04l-6.726 9.965 1.485-10.43 5.241.465Z"
        fill="#1D232A"
      />
      <path
        d="M165.708 47.663c-3.028 8.243-12.1 13.299-21.61 11.558-9.64-1.765-16.394-9.908-16.126-18.831a17.141 17.141 0 0 1 3.48-9.867 18.068 18.068 0 0 1 3.498-3.496 18.258 18.258 0 0 1 1.778-1.199c3.955-2.35 8.864-3.33 13.895-2.408 1.649.302 3.216.789 4.676 1.438 4.026 1.786 7.252 4.778 9.242 8.388.303.547.577 1.112.822 1.688a17.59 17.59 0 0 1 1.292 4.909 17.268 17.268 0 0 1-.947 7.82Z"
        fill="#8F5049"
      />
      <path
        d="M145.901 49.232c3.818.872 6.897-1.132 8.532-3.916-1.961-.956-5.152-1.318-7.323.211-1.283-2.329-4.308-3.407-6.489-3.41.251 3.22 2.056 6.377 5.28 7.115Z"
        fill="#E09661"
      />
      <path
        d="M148.242 47.473a8.448 8.448 0 0 1-2.428-.34c-2.229-.669-3.993-2.288-5.241-4.812a.29.29 0 1 1 .519-.257c1.174 2.375 2.819 3.894 4.888 4.514 2.405.723 5.434.17 8.309-1.513a.287.287 0 0 1 .395.105.288.288 0 0 1-.104.396c-2.536 1.486-4.699 1.907-6.338 1.907ZM148.404 41.6c-.191 0-.376-.022-.55-.058-1.178-.255-2.327-1.369-2.237-3.286a.286.286 0 0 1 .303-.276.29.29 0 0 1 .276.303c-.075 1.577.841 2.488 1.78 2.69.833.18 2.022-.152 2.626-1.685a.29.29 0 1 1 .538.214c-.617 1.562-1.763 2.098-2.736 2.098ZM143.918 38.357c-.998 1.845-4.195 2.044-7.142.442-.004-.002-.009-.004-.013-.008-2.939-1.602-4.511-4.393-3.512-6.235.986-1.82 4.111-2.037 7.021-.508.041.021.081.045.122.066 2.948 1.6 4.525 4.397 3.524 6.243ZM159.161 42.205c-1.195.185-2.343.139-3.336-.093-1.76-.41-3.037-1.405-3.243-2.733-.322-2.076 2.104-4.177 5.414-4.693.207-.032.409-.058.612-.073 3.054-.28 5.663.953 5.966 2.9.324 2.075-2.102 4.176-5.413 4.692Z"
        fill="#1D232A"
      />
      <path
        d="m158.61 34.613-2.785 7.499c-1.76-.41-3.037-1.405-3.243-2.733-.322-2.076 2.104-4.177 5.414-4.693.207-.03.411-.056.614-.073ZM140.274 32.046l-3.509 6.743c-2.939-1.602-4.511-4.393-3.512-6.235.986-1.82 4.109-2.037 7.021-.508Z"
        fill="#fff"
      />
      <path
        d="M155.926 76.797c1.211-1.714 3.583-2.12 5.638-2.528 5.87-1.167 11.682-.992 11.682-.992s.198-4.51 1.845-9.45c.505-1.516 1.728-3.642 2.987-4.622 1.138-.887 3.214-.814 3.955.692.624 1.274.572 2.82.58 4.333.041 7.007.05 11.386 3.006 18.178 2.956 6.791 16.711 27.675 16.711 27.675 4.936 7.623 3.156 16.703-2.565 19.366-7.055 3.284-15.158.099-18.849-7.857l-8.877-20.406c-.587-.96-.959-1.795-4.57-2.392-4.443-.735-11.078-1.651-9.493-7.592 0 0-3.286-3.006-1.017-7.553-.003.001-3.576-3.251-1.033-6.852Z"
        fill="#8F5049"
      />
      <path
        d="M163.94 84.862c-2.196 0-4.767-.259-7.063-.935a.291.291 0 0 1-.197-.36.289.289 0 0 1 .359-.197c4.032 1.187 9.089 1.046 11.045.493a.29.29 0 0 1 .156.558c-.937.266-2.491.441-4.3.441ZM164.208 93.135c-2.187 0-4.597-.441-6.4-1.696a.29.29 0 0 1 .33-.477c2.086 1.45 5.58 1.971 8.901 1.33a.291.291 0 0 1 .11.57c-.862.168-1.875.272-2.941.272ZM147.299 28.823c.442-.867-.373-2.17-1.82-2.912s-2.978-.64-3.42.227c-.442.867.372 2.17 1.819 2.912 1.447.741 2.979.64 3.421-.227ZM156.551 33.153c1.357-.897 2.024-2.282 1.489-3.095-.535-.813-2.068-.745-3.425.151-1.356.897-2.023 2.283-1.488 3.095.534.813 2.067.745 3.424-.151Z"
        fill="#1D232A"
      />
      <path
        d="M166.761 59.905a4.216 4.216 0 0 0 4.211-4.221 4.216 4.216 0 0 0-4.211-4.221 4.217 4.217 0 0 0-4.212 4.22 4.217 4.217 0 0 0 4.212 4.222ZM125.206 51.63a3.923 3.923 0 0 0 3.919-3.927 3.923 3.923 0 0 0-3.919-3.927 3.923 3.923 0 0 0-3.918 3.927 3.923 3.923 0 0 0 3.918 3.928Z"
        fill="#FFC44F"
      />
      <path
        d="M159.74 62.18c-4.202 4.134-11.572 8.983-18.745 8.221-1.782-.189-3.533-1.088-4.41-2.52-.833-1.355-.767-2.926-.393-4.476l2.84-.147.004-.002 1.082-.056.416-.021 1.2-.062 1.17-.062 8.87-.462.305-.015 1.089-.056 6.572-.342Z"
        fill="#8F5049"
      />
      <path
        d="M152.079 62.578c-.365.375-.764.72-1.188 1.028.336-.313.633-.653.883-1.013l-8.87.462a7.39 7.39 0 0 0 1.685.398c1.414.178 2.856.054 4.229-.326-1.35.568-2.921.815-4.564.692-.75-.058-1.71-.232-2.52-.702l-1.2.062c1.053 1.013 2.563 1.326 3.664 1.409.368.029.733.038 1.093.033 1.63-.031 3.167-.408 4.478-1.088-.043.029-.081.061-.126.09-1.141.781-2.971 1.777-5.736 1.436a9.05 9.05 0 0 1-3.789-1.859l-1.082.056-.004.002c1.248 1.245 2.871 2.114 4.597 2.528a.373.373 0 0 0 .11.035c.027.004.054.004.081.008a9.7 9.7 0 0 0 2.223.226 9.014 9.014 0 0 0 1.269-.114c2.262-.37 4.385-1.637 5.856-3.42l-1.089.057Z"
        fill="#1D232A"
      />
      <path
        d="M80.695 179.998H15.712l6.587-23.941.083-.299.167-.613.103-.375.314-1.14.123-.451.402-1.459.065-.232.043-.156a6.466 6.466 0 0 1 .162-.449 7.565 7.565 0 0 1 .584-1.173 7.704 7.704 0 0 1 6.835-3.699l4.343.612 1.55.219 1.616.228.949.133.82.116 1.126.159 2.666.377.536.075 21.389 3.015a7.718 7.718 0 0 1 7.207 5.758l7.313 23.295Z"
        fill="#42EC9A"
      />
      <path
        d="m44.25 147.855-21.868 7.903.168-.613.102-.375 19.751-7.138c-2.138.615-4.261 1.222-6.363 1.821-4.258 1.22-8.616 2.466-12.95 3.726l.4-1.459.066-.232.043-.156c.029-.091.061-.182.094-.271l1.408-.499-1.34.321a7.565 7.565 0 0 1 .584-1.173l11.178-3.087 1.55.219 1.616.228-9.705 3.537c2.291-.659 4.572-1.313 6.826-1.956 1.537-.443 3.09-.885 4.647-1.332l1.127.159 2.666.377Z"
        fill="#1D232A"
      />
      <path
        d="M62.46 58.374c-.732.92-1.646 1.672-2.552 2.418a6.648 6.648 0 0 1-.133.108c-2.472 7.248-9.206 13.63-18.278 16.326-5.434 1.616-11.348 1.782-16.79.112a24.58 24.58 0 0 1-3.617-1.432c-.677-.336-1.311-.794-2.006-1.084a1.36 1.36 0 0 1-.453-.329c-.2-.207-.364-.462-.538-.678-1.384-1.718-2.726-3.49-3.733-5.462-1.205-2.366-1.907-5.074-1.473-7.694.434-2.621 2.148-5.11 4.643-6.007-1.136-4.08.316-8.764 3.558-11.479 1.404-1.177 3.081-1.987 4.801-2.611 3.672-1.332 8.488-1.434 12.38-1.108 0 0 3.442-2.55 4.7-3.248 3.15-1.746 6.59-2.886 10.093-2.376 2.14.313 4.347 1.415 5.184 3.413.566 1.355.418 2.894.204 4.347-.102.69-.226 1.38-.42 2.05-.095.325-.25.635-.342.956-.015.06-.054.12-.034.183.054.186.412.201.572.247.453.134.897.308 1.321.518a8.72 8.72 0 0 1 2.303 1.659 8.39 8.39 0 0 1 1.949 3.05c.974 2.696.447 5.883-1.338 8.121Z"
        fill="#E09661"
      />
      <path
        d="M54.819 72.448a7.071 7.071 0 0 0 2.138-.45c.049-.017.097-.037.145-.054.185-.075.364-.157.542-.248a6.086 6.086 0 0 0 .511-.286 7.138 7.138 0 0 0 3.394-6.468c-.209-3.935-3.564-6.956-7.49-6.746-3.926.211-6.938 3.572-6.73 7.507.21 3.935 3.564 6.956 7.49 6.745Z"
        fill="#FFCBD9"
      />
      <path
        d="M57.12 68.817a.288.288 0 0 1-.255-.153 8.488 8.488 0 0 1-.972-4.412.29.29 0 1 1 .577.029 7.906 7.906 0 0 0 .904 4.11.29.29 0 0 1-.12.393.302.302 0 0 1-.134.033Z"
        fill="#1D232A"
      />
      <path
        d="M25.403 82.45c3.932 0 7.12-3.194 7.12-7.135s-3.188-7.136-7.12-7.136c-3.931 0-7.119 3.195-7.119 7.136 0 3.94 3.188 7.135 7.12 7.135Z"
        fill="#FFCBD9"
      />
      <path
        d="M24.723 78.537a.287.287 0 0 1-.284-.238c-.287-1.54-1.122-2.928-2.233-3.717a.29.29 0 1 1 .334-.473c1.232.871 2.154 2.398 2.468 4.084a.29.29 0 0 1-.285.344ZM54.218 67.367c.6 1.003 1.199 2.006 1.8 3.007-.335-.448-.665-.9-.983-1.36-.145-.21-.497-.043-.36.187.364.613.71 1.237 1.035 1.871-.06-.07-.121-.139-.183-.207-.178-.193-.477.082-.301.275.32.352.619.719.893 1.107.185-.044.364-.096.542-.156a26.39 26.39 0 0 0-.274-.574c.139.176.277.352.418.526a6.87 6.87 0 0 0 .552-.223l-.833-1.391.99 1.316c.124-.058.246-.122.365-.19l-3.334-4.424c-.137-.181-.443.039-.328.236Z"
        fill="#1D232A"
      />
      <path
        d="m55.264 55.93-1.463 9.703-2.559-8.204 4.023-1.5ZM19.44 66.662 25.94 74l-2.312-8.278-4.189.94Z"
        fill="#E09661"
      />
      <path
        d="M46.423 90.817c7.924-2.483 12.34-10.933 9.863-18.877l-3.594-12.286c-2.478-7.942-10.908-12.368-18.833-9.886-7.923 2.483-12.34 10.933-9.863 18.877L27.59 80.93c2.478 7.944 10.91 12.37 18.833 9.886Z"
        fill="#FFCBD9"
      />
      <path
        d="M43.818 62.029a1.63 1.63 0 0 1-1.23-.557 1.647 1.647 0 0 1 .146-2.319c1.88-1.66 4.293-1.494 4.563-1.469.9.083 1.566.881 1.485 1.784a1.638 1.638 0 0 1-1.767 1.488c-.052-.004-1.286-.067-2.111.661-.315.277-.7.412-1.086.412ZM30.454 64.534a1.644 1.644 0 0 1-.118-3.073c2.308-.976 4.545-.05 4.792.058a1.643 1.643 0 0 1 .84 2.164 1.635 1.635 0 0 1-2.146.849c-.048-.02-1.197-.474-2.211-.046-.382.166-.793.17-1.157.048Z"
        fill="#E09661"
      />
      <path
        d="M80.407 142.207c-5.355 2.509-11.722.191-14.224-5.176L49.95 102.222c-2.503-5.365-.19-11.749 5.164-14.257 5.353-2.51 11.722-.192 14.225 5.175l16.232 34.811c2.503 5.365.191 11.749-5.164 14.256Z"
        fill="#FFCBD9"
      />
      <path
        d="M31.89 56.67c.658-2.119.737-4.394.588-6.613 1.983 3.075 11.196 3.938 14.034 1.593.808 2.028 3.884 6.115 8.334 6.52.137.01.32-.055.31-.192-.038-.505.43-.874.664-1.32.445-.852.413-1.879.218-2.82-.442-2.114-1.631-3.991-2.906-5.735-1.759-2.404-3.79-4.715-6.437-6.072-2.811-1.44-6.072-1.682-9.23-1.68-2.414 0-4.87.136-7.144.941-3.917 1.388-7.039 4.842-8.03 8.885-.168.683-.277 1.386-.239 2.088.043.746 1.508 5.164 3.891 6.519 3.521 1.998 5.209.263 5.947-2.115Z"
        fill="#E09661"
        stroke="#E09661"
        strokeMiterlimit={10}
      />
      <path
        d="M40.575 68.993c-.052 3.026-3.872 5.518-9.487 5.419-5.615-.099-10.126-2.632-10.073-5.659.052-3.027 4.647-5.402 10.264-5.303 5.615.098 9.35 2.516 9.296 5.543ZM42.663 69.058c1.819 2.397 6.18 2.264 10.386-.94 4.205-3.207 6.141-7.75 4.323-10.144-1.818-2.397-6.7-1.74-10.906 1.467-4.206 3.206-5.621 7.22-3.803 9.617Z"
        fill="#00A769"
      />
      <path
        d="M38.552 69.505c-.459 1.985-3.943 3.285-7.784 2.905-.006 0-.012 0-.018-.002-3.833-.383-6.565-2.298-6.108-4.28.453-1.957 3.849-3.248 7.626-2.922l.158.016c3.841.38 6.585 2.298 6.126 4.283Z"
        fill="#fff"
      />
      <path
        d="M38.552 69.505c-.459 1.985-3.943 3.285-7.784 2.905-.006 0-.012 0-.018-.002a12.7 12.7 0 0 1-1.915-.338s3.973-6.806 4.16-6.779c3.54.503 5.993 2.33 5.557 4.214Z"
        fill="#1D232A"
      />
      <path
        d="M51.906 66.517c-.98.868-2.048 1.53-3.08 1.962-.216.09-.43.17-.64.24-1.583.517-3.02.451-3.843-.318-1.462-1.362-.446-4.416 2.27-6.818.167-.15.339-.292.513-.43 2.614-2.07 5.678-2.73 7.05-1.451 1.46 1.359.445 4.412-2.27 6.815Z"
        fill="#fff"
      />
      <path
        d="M51.906 66.517c-.98.868-2.048 1.53-3.08 1.962-.216.09-.43.17-.64.24l.357-8.528c2.227-1.28 4.504-1.543 5.632-.49 1.462 1.36.446 4.413-2.27 6.816ZM48.26 83.076c-.608.526-1.597 1.22-3.38 1.663-.676.168-1.469.301-2.398.375-2.242.177-4.161-.31-5.765-1.195a9.297 9.297 0 0 1-.498-.294 10.97 10.97 0 0 1-2.046-1.671c-1.334-1.38-1.327-3.653.004-5.072.336-.36.723-.626 1.14-.808a3.252 3.252 0 0 1 3.677.74 4.03 4.03 0 0 0 2.364 1.216 4.072 4.072 0 0 0 3.018-.788 3.265 3.265 0 0 1 3.762-.14c.374.244.707.57.977.972 1.072 1.609.727 3.63-.856 5.002Z"
        fill="#1D232A"
      />
      <path
        d="M48.26 83.076c-.608.526-1.597 1.22-3.38 1.663-.676.168-1.469.301-2.398.375-2.242.177-4.161-.31-5.765-1.195a9.297 9.297 0 0 1-.498-.294 10.97 10.97 0 0 1-2.046-1.671c-1.334-1.38-1.327-3.653.004-5.072.336-.36.723-.626 1.14-.808a3.252 3.252 0 0 1 3.677.74 4.03 4.03 0 0 0 2.364 1.216 4.072 4.072 0 0 0 3.018-.788 3.265 3.265 0 0 1 3.762-.14c.374.244.707.57.977.972 1.072 1.609.727 3.63-.856 5.002Z"
        fill="#1D232A"
      />
      <path
        d="M35.316 76.072c1.08 2.213 3.134 3.848 5.666 4.204 2.832.398 5.532-.91 7.156-3.175a3.265 3.265 0 0 0-3.763.139 4.078 4.078 0 0 1-3.015.788 4.053 4.053 0 0 1-2.366-1.216 3.252 3.252 0 0 0-3.678-.74Z"
        fill="#fff"
      />
      <path
        d="m23.645 97.373 29.778-4.896s8.264-1.395 10.089 11.274c1.824 12.669 5.55 31.689 5.55 31.689l-53.925 5.59 8.508-43.657Z"
        fill="#00734D"
      />
      <path
        d="m60.355 121.445 1.762-3.664 2.806-5.837.642-1.334c.16-.334-.337-.628-.5-.291l-1.762 3.664-2.805 5.837-.642 1.334c-.16.334.337.628.499.291Z"
        fill="#1D232A"
      />
      <path
        d="M66.317 114.271c-.137.265-.272.534-.405.8l.718-2.211c.117-.363-.42-.489-.558-.154l-1.002 2.458.598-1.968c.092-.307-.396-.529-.529-.224-.368.837-.734 1.676-1.103 2.513.617-1.875 1.232-3.748 1.85-5.622.117-.356-.442-.509-.558-.155l-3.72 11.314c-.1.307.396.528.529.224.519-1.182 1.04-2.367 1.558-3.55l-1 3.288c-.113.365.42.489.556.154l.428-1.047-.478 1.472c-.115.358.438.505.557.155.074-.211.149-.419.224-.628-.008.263.42.423.542.147l.183-.422c.081.211.446.238.558-.031.013-.034.028-.069.044-.104.015.249.422.392.542.126l.245-.549a.295.295 0 0 0 .017-.201c.181-.53.364-1.057.546-1.587.057-.168-.058-.303-.2-.348.086-.284.175-.566.261-.85.068-.221-.096-.352-.27-.348.04-.134.081-.267.124-.4.061-.199-.122-.36-.295-.367l.57-1.661c.105-.311-.381-.516-.532-.224ZM34.24 112.595a.276.276 0 0 0-.075-.179c.018-.433.033-.866.05-1.299.014-.375-.555-.371-.578 0-.058.935-.13 1.869-.204 2.804-.116.652-.24 1.303-.367 1.952.04-.293.085-.587.122-.883.171-1.364.308-2.733.41-4.103.023-.323-.512-.4-.568-.077-.118.69-.243 1.38-.37 2.068a.295.295 0 0 0-.084.011c.158-.794.317-1.589.473-2.383.071-.361-.457-.518-.557-.155-1.22 4.423-2.438 8.847-3.656 13.269-.348 1.256-.693 2.514-1.04 3.771-.098.359.46.514.558.154l2.591-9.406-1.332 6.714-.81 4.076c-.072.363.451.516.557.155.95-3.199 1.795-6.429 2.538-9.681-.004.059-.008.119-.014.179a77.822 77.822 0 0 1-2.349 9.364c-.113.358.438.505.558.155a99.158 99.158 0 0 0 1.429-4.499l-.122 1.675-.266 3.7c-.023.324.503.398.569.077.628-3.058 1.15-6.138 1.564-9.233l-.15 3.982c-.049 1.285-.099 2.568-.147 3.854-.016.38.536.359.578 0a105.046 105.046 0 0 0 .692-16.062Zm-3.102 7.528 1.101-5.543.012-.056-.052.729c-.33 1.627-.683 3.253-1.061 4.87Zm1.7-.498a138.203 138.203 0 0 1-.751 5.185l.22-3.053c.183-.71.364-1.421.532-2.132Z"
        fill="#1D232A"
      />
      <path
        d="M63.562 140.437a11.08 11.08 0 0 1-.727 1.092l-.073.1-.066.087a10.398 10.398 0 0 1-.908 1.026 11.445 11.445 0 0 1-.858.783c-.241.199-.505.402-.793.603-.237.165-.488.327-.756.483a10.61 10.61 0 0 1-.842.449c-.962.46-2.083.823-3.355.968 0 0-39.25 4.803-43.005 4.66-1.585-.06-3.2-.294-4.747-.97-5.843-2.559-9.057-9.051-6.273-16.102l14.819-35.17c2.553-5.855 9.358-8.526 15.197-5.968 4.336 1.901 6.924 6.148 6.924 10.606 0 1.547-.312 3.12-.968 4.625l-9.72 20.971 24.795-5.597c6.329-.781 12.087 3.73 12.868 10.071.013.122.027.244.038.365a11.54 11.54 0 0 1-1.55 6.918Z"
        fill="#FFCBD9"
      />
      <path
        d="M63.562 140.437a11.058 11.058 0 0 1-.727 1.092l-.074.1-.653-.185c.195.09.391.179.588.272-.152.197-.426.536-.823.939l-.72-.255.635.342c-.22.221-.473.458-.76.7l-1.184-.578 1.086.661c-.241.199-.506.402-.793.603l-5.403-3.04a181.77 181.77 0 0 0 4.647 3.523c-.266.157-.545.308-.842.449l-11.053-7.474c-.467-.342-.047-.961.447-.665l2.25 1.521a.394.394 0 0 1 .03-.4.397.397 0 0 1 .526-.124l1.762.992.01-.02a.399.399 0 0 1 .52-.177c.791.363 1.736.809 2.748 1.291 0-.002 0-.006.002-.008a.4.4 0 0 1 .503-.211c.023.006.704.255 1.75.638-.456-.216-.728-.348-.743-.354a.404.404 0 0 1-.199-.504.397.397 0 0 1 .482-.244l5.1 1.452a.4.4 0 0 1 .548-.497l.34.161Z"
        fill="#1D232A"
      />
      <path
        d="m71.474 121.078-32.733 1.295s-18.035.536-28.582 3.336c-5.672 1.506-4.108 6.56-.587 6.689 6.379.238 11.074.153 11.074.153-1.36 1.001-4.817 9.443 1.267 13.058 1.223.724 3.864.825 4.013-1.763.484.972 1.09 1.923 2.02 2.478.932.554 2.207.634 3.057-.039.519-.412.83-1.074.812-1.739-.06-2.269.484-4.6 1.533-6.61.651-1.249 1.68-2.49 3.08-2.619.692-.064 1.372.156 2.05.301 0 0 23.61 7.213 36.885 7.586 9.473.267 12.888-8.939 10.468-14.642-.85-2.004-6.38-13.315-6.38-13.315l-7.977 5.831Z"
        fill="#FFCBD9"
      />
      <path
        d="M29.717 146.859a3.38 3.38 0 0 1-1.722-.479c-.777-.462-1.327-1.171-1.737-1.865a2.263 2.263 0 0 1-1.148 1.362c-1.03.522-2.397.279-3.16-.218-1.5-.974-2.49-2.584-2.943-4.786-.665-3.229.043-6.807.872-8.492-1.192.035-4.426.143-9.192.402-2.981.162-4.325-1.055-4.586-2.248-.378-1.732 1.236-3.637 4.017-4.739 6.523-2.584 14.337-2.984 21.228-3.338a.292.292 0 0 1 .303.275.288.288 0 0 1-.274.303c-6.847.352-14.61.748-21.045 3.297-2.086.828-4.028 2.409-3.666 4.077.268 1.225 1.796 1.911 3.99 1.793 6.093-.33 9.686-.415 9.722-.415.11 0 .209.056.26.15a.294.294 0 0 1-.013.302c-.895 1.343-1.726 5.232-1.049 8.517.42 2.045 1.327 3.53 2.692 4.417.636.413 1.778.597 2.584.187.603-.307.935-.887.983-1.724a.287.287 0 0 1 .232-.266.287.287 0 0 1 .316.154c.403.808.983 1.807 1.91 2.358.911.541 2.033.536 2.73-.015.449-.354.717-.932.701-1.504-.067-2.538-.052-5.412.991-7.408.895-1.715 3.2-2.055 3.886-2.119a.294.294 0 0 1 .285.159c.056.108 1.425 2.657 6.24 2.06.158-.019.318-.062.488-.106.255-.068.517-.135.798-.137.338 0 3.238.757 8.13 2.066 1.041.278 1.863.499 1.992.527a.29.29 0 0 1-.127.565c-.141-.033-.886-.232-2.014-.534-2.456-.657-7.57-2.025-7.983-2.046-.204 0-.418.058-.648.118a4.37 4.37 0 0 1-.563.121c-4.556.567-6.301-1.55-6.722-2.197-.763.094-2.549.456-3.245 1.789-1.058 2.03-.975 5.219-.925 7.125a2.482 2.482 0 0 1-.922 1.973c-.459.359-1.05.539-1.666.539Z"
        fill="#1D232A"
      />
      <path
        d="M39.702 67.785c1.218-.777 2.304-1.203 3.2-1.121.454-.565.313-.835 0-1.508-1.552-.423-2.803.081-4 1.063l.8 1.566Z"
        fill="#00A769"
      />
      <path d="m41.953 66.138 3.18 5.522a1.546 1.546 0 0 1-1.055 2.282l-3.501.746" fill="#FFCBD9" />
      <path
        d="M40.575 74.978a.287.287 0 0 1-.282-.23.29.29 0 0 1 .222-.344l3.502-.746c.409-.077.74-.334.912-.707.172-.373.154-.79-.048-1.148l-3.178-5.52a.29.29 0 1 1 .501-.29l3.18 5.522a1.82 1.82 0 0 1 .071 1.681 1.811 1.811 0 0 1-1.322 1.032l-3.496.745a.33.33 0 0 1-.062.005Z"
        fill="#1D232A"
      />
      <path
        d="M36.958 95.185s3.235 2.799 7.059 4.1c3.087 1.049 8.006 1.254 9.016-2.051.68-2.223-.852-4.552-.852-4.552l-15.223 2.503Z"
        fill="#FFCBD9"
      />
      <path
        d="M26.12 143.943a.29.29 0 0 1-.27-.184c-.848-2.178-1.046-5.385-.462-7.46a10.53 10.53 0 0 1 3.745-5.501.289.289 0 0 1 .405.056.29.29 0 0 1-.056.406 9.95 9.95 0 0 0-3.537 5.195c-.553 1.968-.362 5.018.446 7.091a.29.29 0 0 1-.164.375.252.252 0 0 1-.106.022ZM20.228 132.594a.29.29 0 0 1-.241-.451c1.834-2.762 4.354-4.468 8.424-5.707a.289.289 0 0 1 .361.193.291.291 0 0 1-.193.362c-3.931 1.196-6.357 2.833-8.112 5.473a.287.287 0 0 1-.239.13ZM39.744 94.737c.771.738 1.592 1.426 2.572 1.867.14.064.285.12.432.172.854.464 1.799.806 2.728.67.049.045.116.07.203.047.154-.039.304-.089.453-.149a2.702 2.702 0 0 0 1.276-.601.237.237 0 0 0 .19-.182c.133-.14.254-.297.35-.47.269-.465.39-1.008.346-1.544a3.496 3.496 0 0 0-.076-1.215l-1.162.191c.038.108.07.215.09.327a2.17 2.17 0 0 0-.221-.306l-1.97.327.05.267c-.047-.025-.101-.047-.155-.07l-.207-.145-1.945.321c.1.11.204.216.312.32.016.043.04.088.085.12l.245.186c.133.118.27.232.407.342l-.072-.04a16.207 16.207 0 0 1-1.263-.884l-1.344.223a43.173 43.173 0 0 0 2.282 1.532c.254.159.56.364.88.516a3.78 3.78 0 0 1-1.22-.228c-.39-.208-.767-.444-1.132-.684-.49-.32-.96-.667-1.413-1.036l-.721.126Zm5.441 2.22c.21-.046.42-.104.625-.189a.21.21 0 0 0 .118-.108c.058.017.115.035.175.05-.28.15-.594.228-.918.248ZM42.09 66.668a.285.285 0 0 1-.25-.147l-1.554-2.74a.29.29 0 1 1 .503-.288l1.554 2.74a.291.291 0 0 1-.253.434Z"
        fill="#1D232A"
      />
      <path
        d="M49.971 122.081c2.41-7.748 4.819-13.798 10.905-17.265 1.849-1.053 4.04-.984 4.836-.093.769.862.777 2.204.324 3.267-.452 1.063-1.283 1.911-2.093 2.733 2.098-1.002 5.714-.884 6.757.297 1.091 1.235.594 2.845-1.354 4.036 2.739 1.092 4.327 4.401 3.201 5.986l-22.576 1.039Z"
        fill="#FFCBD9"
      />
      <path
        d="M58.577 118.856a.29.29 0 0 1-.289-.29c0-2.515.931-4.699 2.694-6.315 1.448-1.327 3.424-2.216 5.424-2.439a.29.29 0 0 1 .064.578c-3.662.408-7.605 3.141-7.605 8.176a.287.287 0 0 1-.288.29Z"
        fill="#1D232A"
      />
      <path
        d="M72.914 120.63a.29.29 0 0 1-.283-.35c.4-1.894-1.057-4.024-3.39-4.954a.289.289 0 0 1-.182-.245.293.293 0 0 1 .137-.273c1.024-.626 1.65-1.387 1.765-2.145.077-.516-.083-1.005-.477-1.45-.935-1.059-4.413-1.182-6.415-.228a.29.29 0 0 1-.249-.523c2.08-.994 5.895-.994 7.096.367.505.572.718 1.235.615 1.919-.121.806-.678 1.593-1.587 2.256 2.3 1.126 3.68 3.368 3.251 5.394a.29.29 0 0 1-.281.232Z"
        fill="#1D232A"
      />
      <path
        d="M63.944 111.014a.29.29 0 0 1-.199-.502l.384-.358c1.75-1.631 2.628-2.45 2.707-3.892.023-.435-.21-.932-.611-1.295-.648-.588-1.66-.81-2.85-.624a.289.289 0 0 1-.089-.572c1.365-.211 2.547.061 3.326.769.535.485.833 1.142.8 1.755-.09 1.673-1.085 2.601-2.89 4.285l-.382.357a.295.295 0 0 1-.196.077ZM62.19 120.491a.29.29 0 0 1-.278-.371c.929-3.245 3.077-4.542 4.274-5.026 1.4-.564 2.875-.597 3.758-.085a.29.29 0 0 1-.29.503c-.602-.35-1.84-.448-3.25.12-1.099.444-3.073 1.639-3.933 4.648a.294.294 0 0 1-.28.211ZM48.49 122.163a.29.29 0 0 1-.015-.58l24.822-1.286a.29.29 0 0 1 .03.58l-24.821 1.286h-.016Z"
        fill="#1D232A"
      />
      <path
        d="M44.881 84.739c-.677.168-1.47.301-2.399.375-2.242.177-4.161-.31-5.765-1.195a9.405 9.405 0 0 1-.498-.293c2.54-1.719 5.411-2.022 8.662 1.113Z"
        fill="#E0541E"
      />
      <path
        d="m241.248 124.741-.347 2.422-.554 3.861-2.113 14.718-4.132 28.811a6.342 6.342 0 0 1-6.271 5.447h-65.112l1.807-12.592 2.111-14.72.554-3.861.656-4.569 3.06-21.324a6.339 6.339 0 0 1 6.27-5.449h57.8c3.153 0 5.704 2.285 6.234 5.209.12.657.137 1.345.037 2.047Z"
        fill="#00A769"
      />
      <path
        d="M231.578 171.461h-62.746a.29.29 0 1 1 0-.58h62.746c.16 0 .289.13.289.29 0 .161-.129.29-.289.29Z"
        fill="#1D232A"
      />
      <path
        d="M81.107 121.063a7.071 7.071 0 0 1 6.924-5.675h35.662s12.842.07 8.239 22.051c-4.603 21.981-8.239 23.438-14.296 23.438H81.691c-4.466 0-7.813-4.103-6.924-8.49l6.34-31.324Z"
        stroke="#fff"
        strokeWidth={0.822}
        strokeMiterlimit={10}
      />
      <path d="m92.937 126.511 6.153 32.623-9.983 2.333 3.83-34.956Z" fill="#1D232A" />
      <path d="M76.686 180H41.385v-40.634c0-5.336 4.317-9.664 9.641-9.664h25.66V180Z" fill="#E8E9EA" />
      <path
        d="M86.26 87.068c-3.117-9.297-4.584-16.241-4.465-20.746.149-5.57 2.993-7.952 6.86-5.975 5.67-17.029 19.064-18.194 18.912-6.718 4.577-4.128 10.019-5.199 8.781 4.716 5.474-1.33 5.769 6.419 1.168 18.651-12.67 12.7-31.257 10.072-31.257 10.072Z"
        fill="#1D232A"
      />
      <path
        d="M141.468 119.147v41.021a3.862 3.862 0 0 1-3.857 3.866h-65.55a3.861 3.861 0 0 1-3.839-4.223l.226-2.44.23-2.466.268-2.907.054-.576 2.013-21.72 1.01-10.912a3.859 3.859 0 0 1 3.84-3.508h61.748a3.861 3.861 0 0 1 3.857 3.865Z"
        fill="#00A769"
      />
      <path
        d="m82.447 143.457 3.425 15.072.49 2.153c.111.493.825.277.744-.205-1.01-6.011-2.021-12.021-3.033-18.032-.145-.856-.288-1.715-.432-2.571l-.745.205 4.507 17.122.646 2.457c.127.481.871.276.744-.205l-4.506-17.122-.646-2.457c-.13-.489-.826-.276-.745.205 1.01 6.011 2.021 12.022 3.034 18.033l.431 2.57c.25-.067.496-.137.745-.205l-3.425-15.071-.49-2.153c-.11-.485-.854-.281-.744.204Z"
        fill="#1D232A"
      />
      <path d="m81.786 141.925-.567 16.448 5.07 3.94c-1.256-6.849-2.616-13.471-3.87-20.32l-.633-.068Z" fill="#1D232A" />
      <path d="M92.775 115.282h21.66s.509 9.519-8.401 10.118c-11.163.752-13.26-10.118-13.26-10.118Z" fill="#E09661" />
      <path
        d="M114.007 116.473a9.524 9.524 0 0 1-1.332 1.7 9.402 9.402 0 0 1-1.711 1.371c-2.379 1.49-5.401 1.803-8.039.848-2.385-.864-4.306-2.694-5.34-5.002a5.847 5.847 0 0 1-.045-.108h.86a8.609 8.609 0 0 0 2.161 2.828c1.915 1.66 4.574 2.365 7.061 1.899a8.43 8.43 0 0 0 2.636-.974c-1.891.915-4.105 1.231-6.157.791-2.362-.508-4.336-2.277-5.36-4.436l-.03-.062c-.007-.015-.013-.031-.018-.046h.879c.017.037.037.071.058.108a7.668 7.668 0 0 0 1.801 2.236 9.206 9.206 0 0 1-1.684-2.236l-.005-.01a.399.399 0 0 1-.039-.098h13.988a.397.397 0 0 1 .092.108c.095.151.101.352-.054.478l-.029.021c.253.035.479.301.307.584Z"
        fill="#1D232A"
      />
      <path
        d="M148.425 155.06c-.702 2.862-1.602 5.307-2.649 7.389a26.68 26.68 0 0 1-.475.896c-6.502 11.792-17.954 10.977-23.046 9.247-1.183-.402-2.023-.853-2.378-1.206-2.316-2.308-3.38-4.83-3.706-7.352a15.016 15.016 0 0 1-.06-3.155c.058-.724.162-1.44.297-2.143.141-.733.316-1.454.511-2.153l.075-.261c.064-.219.127-.435.195-.648.029-.094.058-.189.089-.284.42-1.32.887-2.553 1.292-3.655a60.44 60.44 0 0 0 .414-1.148c.073-.21.154-.485.241-.817 1.315-5.018 4.071-22.965 4.642-25.783.027-.124.046-.218.063-.282a.505.505 0 0 1-.144.249c-.818.847-4.736 1.484-6.397-2.085a6.494 6.494 0 0 1-.269-.677 2.352 2.352 0 0 1-.286.062c-1.442.209-3.074-1.034-4.159-3.081a10.452 10.452 0 0 1-.989-2.783c-.008-.037-.016-.071-.022-.108a11.536 11.536 0 0 1-.057-4.078l.044-.238c.002-.015.004-.031.008-.046a2.7 2.7 0 0 0-.126.114c-.031.025-.059.052-.088.079-.893.806-1.744 1.664-2.748 2.327-1.082.713-2.395 1.185-3.672.959-.863-.155-1.673-.696-2.177-1.427a2.574 2.574 0 0 1-.119-.187 2.837 2.837 0 0 1-.363-.97c-1.184-6.927 8.091-12.708 16.393-14.152a23.062 23.062 0 0 1 2.267-.288c.095-.01.189-.021.288-.029 6.575-.618 22.229 1.832 24.959 25.593 1.196 10.416 1.784 19.372 2.034 25.165.01.218.02.433.027.642a.795.795 0 0 1 .002.069c.151 3.728.153 5.984.089 6.245Z"
        fill="#E09661"
      />
      <path
        d="m128.824 147.661-6.427 2.314c-1.222.438-2.443.879-3.666 1.32.089-.244.174-.48.253-.708.073-.21.154-.485.241-.817l9.262-3.334c.377-.135.649.091.738.388.093.31-.013.698-.401.837Z"
        fill="#1D232A"
      />
      <path
        d="m132.443 145.991-13.008 10.043-2.158-.644c.421-1.32.887-2.553 1.292-3.655 1.277-.589 2.551-1.175 3.826-1.76h.002c2.271-1.044 4.545-2.088 6.828-3.151.929-.433 1.861-.867 2.794-1.308l.424.475Z"
        fill="#1D232A"
      />
      <path
        d="M121.894 86.17a7.177 7.177 0 0 1-2.044 2.527 7.357 7.357 0 0 1-1.263.789h-.004c-.064.032-.129.06-.195.092-.314.14-.638.26-.974.352a6.947 6.947 0 0 1-.834.186l-3.306.53-2.269-14.203 3.303-.53a7.091 7.091 0 0 1 2.973.148 7.186 7.186 0 0 1 5.249 5.815 7.146 7.146 0 0 1-.636 4.295Z"
        fill="#E09661"
      />
      <path
        d="M119.456 88.981a7.251 7.251 0 0 1-.867.505h-.004a36.378 36.378 0 0 0-1.254-1.013l1.061 1.105c-.314.14-.638.26-.974.352-1.027-1.07-2.053-2.14-3.085-3.212-.193-.197-.156-.447-.023-.615 0-.332.337-.659.696-.429 1.56 1 3.045 2.103 4.45 3.307ZM120.59 88.03c-.233.24-.478.462-.74.667a49.008 49.008 0 0 1-5.997-4.142c-.472-.38.202-1.048.669-.669a47.592 47.592 0 0 0 6.068 4.144ZM121.894 86.17a7.184 7.184 0 0 1-.469.824c-2.387-1.328-4.772-2.65-7.161-3.977-.529-.294-.114-1.145.416-.85l7.214 4.004Z"
        fill="#1D232A"
      />
      <path
        d="M106.753 112.709c-7.796 1.253-15.13-4.068-16.377-11.878l-4.46-27.886a14.28 14.28 0 0 1 .657-7.107c1.705-4.75 5.87-8.455 11.194-9.308a14.86 14.86 0 0 1 1.942-.178 14.059 14.059 0 0 1 5.446.95 14.307 14.307 0 0 1 8.991 11.109l4.46 27.885c1.248 7.813-4.061 15.161-11.853 16.413Z"
        fill="#E09661"
      />
      <path
        d="m87.223 93.877 3.215-.516-2.21-13.823-3.214.516c-3.809.612-6.402 4.201-5.791 8.019.61 3.817 4.192 6.416 8 5.804Z"
        fill="#E09661"
      />
      <path
        d="M86.39 90.473a.29.29 0 0 1-.26-.415 3.936 3.936 0 0 0 .025-3.315c-.377-.823-1.228-1.913-3.133-2.42a.29.29 0 0 1 .146-.56c2.124.564 3.082 1.799 3.512 2.736a4.52 4.52 0 0 1-.03 3.81.283.283 0 0 1-.26.164ZM111.661 111.21a.29.29 0 0 1-.282-.358c.611-2.503 3.375-10.62 13.29-9.942.16.012.279.149.268.309a.291.291 0 0 1-.309.269c-9.464-.648-12.105 7.109-12.69 9.501a.286.286 0 0 1-.277.221ZM123.93 123.995a.29.29 0 0 1-.206-.493c1.22-1.235 1.34-2.304 1.504-3.782.019-.173.038-.35.062-.532.401-3.324 1.995-4.237 3.657-4.728a.29.29 0 0 1 .164.555c-1.702.503-2.906 1.417-3.245 4.241l-.06.525c-.171 1.537-.306 2.749-1.668 4.127a.295.295 0 0 1-.208.087Z"
        fill="#1D232A"
      />
      <path
        d="m56.013 156.125-1.344 8.326c-.941 5.908 1.336 15.547 16.097 15.547h51.489v-21.262H82.05s-3.347-6.452-26.037-2.611Z"
        fill="#E09661"
      />
      <path
        d="M121.637 180h9.405c4.194 0 9.259-1.061 11.079-1.859 9.281-4.076 9.476-8.783 8.68-12.306 1.592-2.285 1.793-5.634-.398-6.958.081-.139.22-.271.317-.394.329-.427.64-.87.921-1.332.579-.949 1.032-1.987 1.229-3.087.335-1.876-.193-3.995-1.686-5.175a2.418 2.418 0 0 0-.811-.447 2.338 2.338 0 0 0-.874-.058c-.86.087-1.566.547-2.246 1.081-5.014 3.942-10.882 6.26-16.861 8.469.977-.916 1.953-1.834 2.931-2.75 1.124-1.056 2.26-2.125 3.054-3.414.793-1.287 1.217-2.839.804-4.273-.412-1.432-1.805-2.667-3.403-2.679-1.333-.009-2.505.769-3.558 1.529-6.145 4.441-11.826 9.472-18.409 13.336L121.637 180Z"
        fill="#E09661"
      />
      <path
        d="M56.013 156.125s3.355-15.899 6.363-29.094c1.525-6.693 6.976-11.784 13.488-11.784 6.468 0 10.112 4.66 9.595 12.42l-3.407 31.069-26.04-2.611Z"
        fill="#00A769"
      />
      <path
        d="M126.126 158.95a.289.289 0 0 1-.03-.577c7.51-.812 17.668-6.62 22.483-10.484a.29.29 0 0 1 .363.453c-4.869 3.908-15.158 9.783-22.785 10.607l-.031.001ZM140.878 165.247a.291.291 0 0 1-.274-.193.29.29 0 0 1 .177-.369c5.926-2.076 9.773-5.226 10.957-7.426a.289.289 0 0 1 .391-.118.291.291 0 0 1 .118.393c-1.716 3.191-6.9 6.165-11.275 7.698a.28.28 0 0 1-.094.015ZM141.736 172.855a.29.29 0 0 1-.062-.574c6.356-1.402 9.51-6.578 10.189-8.454a.289.289 0 1 1 .544.197c-.883 2.437-4.235 7.416-10.61 8.823a.223.223 0 0 1-.061.008ZM117.295 121.886a.29.29 0 0 1-.264-.17c-1.545-3.409-1.41-7.255.358-10.288 1.747-2.994 4.875-4.83 8.811-5.17a.29.29 0 0 1 .048.578c-3.743.325-6.712 2.06-8.361 4.886-1.674 2.87-1.799 6.517-.332 9.754a.292.292 0 0 1-.144.385.269.269 0 0 1-.116.025ZM102.696 113.017a.29.29 0 0 1-.253-.147 3.058 3.058 0 0 1-.36-1.005c-.388-2.267.264-4.505 1.936-6.655 3.268-4.201 10.412-7.618 16.988-8.125a.29.29 0 0 1 .044.578c-6.427.495-13.398 3.817-16.575 7.903-1.568 2.016-2.181 4.101-1.823 6.2.049.28.147.557.295.82a.288.288 0 0 1-.252.431Z"
        fill="#1D232A"
      />
      <path
        d="M112.896 159.325a.293.293 0 0 1-.241-.129.291.291 0 0 1 .081-.402c3.321-2.213 7.305-5.209 11.159-8.104 2.21-1.66 4.296-3.23 6.155-4.573.681-.493 1.929-1.21 3.205-1.614 1.77-.56 3.195-.39 4.123.493 1.648 1.569 1.39 3.906-.768 6.946-.405.57-2.564 3.526-5.345 5.752a.29.29 0 1 1-.36-.454c2.72-2.176 4.836-5.075 5.233-5.634 1.992-2.808 2.267-4.832.841-6.191-1.761-1.675-5.357.277-6.593 1.172-1.855 1.341-3.94 2.906-6.148 4.565-3.86 2.901-7.851 5.9-11.185 8.123a.254.254 0 0 1-.157.05ZM87.883 79.62l.216-8.008s8.644 9.67 15.004-8.104c0 0 3.445 7.815 12.954 4.758-1.34-5.269-4.954-12.764-10.21-13.444-5.257-.68-10.511-1.53-16.022 4.248-5.511 5.777-5.754 8.751-5.039 13.255.351 2.207 1.415 7.564 1.415 7.564l1.682-.268Z"
        fill="#1D232A"
      />
      <path
        d="M102.513 80.237a2.93 2.93 0 0 0-3.188-2.64l-6.34.603a2.926 2.926 0 0 0-2.634 3.193c.077.806.47 1.504 1.047 1.983a2.91 2.91 0 0 0 2.14.66l6.34-.604a2.883 2.883 0 0 0 1.585-.655 2.936 2.936 0 0 0 1.05-2.54Z"
        fill="#fff"
      />
      <path
        d="M102.513 80.237a2.93 2.93 0 0 0-3.188-2.64l-2.794.265.552 5.833 2.794-.265a2.876 2.876 0 0 0 1.585-.655 2.927 2.927 0 0 0 1.051-2.538Z"
        fill="#1D232A"
      />
      <path
        d="M116.551 76.778a2.92 2.92 0 0 0-1.846-2.032 2.887 2.887 0 0 0-1.712-.079l-6.169 1.583a2.93 2.93 0 0 0-2.107 3.564 2.92 2.92 0 0 0 3.557 2.113l6.169-1.583a2.891 2.891 0 0 0 1.464-.895c.62-.702.894-1.691.644-2.671Z"
        fill="#fff"
      />
      <path
        d="M116.55 76.778a2.916 2.916 0 0 0-1.845-2.032 2.887 2.887 0 0 0-1.712-.079l-2.719.698 1.45 5.676 2.719-.697c.085-.022.17-.049.251-.078a2.927 2.927 0 0 0 1.856-3.489Z"
        fill="#1D232A"
      />
      <path
        d="M104.607 77.897s2.437 5.452 2.765 6.331c.576 1.547-.324 3.447-1.932 3.802-.614.135-2.681.12-2.681.12l1.848-10.253Z"
        fill="#E09661"
      />
      <path
        d="M102.956 88.442h-.2a.29.29 0 0 1 .002-.58h.001c.565.004 2.139-.008 2.617-.114.65-.143 1.198-.574 1.543-1.212.372-.686.439-1.51.179-2.205-.32-.862-2.732-6.26-2.757-6.314a.289.289 0 1 1 .528-.236c.1.222 2.443 5.471 2.771 6.349.316.85.237 1.853-.214 2.684-.421.777-1.124 1.324-1.926 1.502-.527.114-2.002.126-2.544.126ZM107.66 73.154c1.313-.832 1.976-2.144 1.48-2.931-.496-.787-1.963-.75-3.277.082-1.313.831-1.975 2.144-1.479 2.93.496.787 1.963.75 3.276-.081Z"
        fill="#1D232A"
      />
      <path d="M102.833 92.253c-1.786-.427-3.205-1.376-4.258-2.847l4.258 2.847Z" fill="#E09661" />
      <path
        d="M102.833 92.543a.267.267 0 0 1-.068-.008c-1.847-.44-3.336-1.438-4.427-2.96a.289.289 0 1 1 .469-.338c1.006 1.406 2.383 2.326 4.089 2.734a.29.29 0 0 1 .214.35.282.282 0 0 1-.277.222ZM82.052 158.637c-.01 0-.022 0-.033-.002a.29.29 0 0 1-.257-.319l2.569-23.494a.291.291 0 0 1 .318-.257.29.29 0 0 1 .257.319l-2.569 23.494a.288.288 0 0 1-.285.259ZM75.272 152.723c-.011 0-.02 0-.032-.002L57.615 150.7a.291.291 0 0 1-.254-.321.287.287 0 0 1 .32-.255l17.624 2.021a.29.29 0 0 1-.033.578ZM165.683 179.998v-1.562l68.417-3.884c-.064.56-.951 5.446-6.271 5.446h-62.146Z"
        fill="#1D232A"
      />
      <path
        d="M165.133 179.998h-50.746c-3.5 0-6.336-2.843-6.336-6.351v-1.509c0-.534.432-.967.964-.967h56.118a4.409 4.409 0 0 1 4.404 4.415 4.408 4.408 0 0 1-4.404 4.412Z"
        fill="#42EC9A"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(.143)" d="M0 0h241.714v180H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default HomeModalNewPageIllustration
