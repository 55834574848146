export function IfoodIllustration() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" fill="white" />
      <path
        d="M4 17.2812H5.82664L6.84144 12.2326H5.0148L4 17.2812ZM5.39535 11.7759H7.5518L7.90698 10H5.75053L5.39535 11.7759ZM6.537 18.7019H8.36364L9.47992 13.2474H10.8499L11.0529 12.2326H9.70824L9.75898 12.0042C9.83509 11.5729 9.98731 11.0909 10.6723 11.0909C11.0782 11.0909 11.4588 11.1163 11.8393 11.2939L12.0423 10.2283C11.611 10.0761 11.1543 10 10.723 10C9.25159 10 8.23679 10.8626 7.85624 12.2326H7.22199L7.01903 13.2474H7.65328L6.537 18.7019Z"
        fill="#EA1D2C"
      />
      <path
        d="M12.321 17.3827C14.4775 17.3827 15.9743 15.4293 15.9743 13.8309C15.9743 12.6386 14.8834 12.1312 13.8178 12.1312C11.4584 12.1312 10.1646 14.2369 10.1646 15.683C10.1646 16.8753 11.2808 17.3827 12.321 17.3827ZM18.1054 17.3827C20.2618 17.3827 21.7586 15.4293 21.7586 13.8309C21.7586 12.6386 20.6424 12.1312 19.5768 12.1312C17.2174 12.1312 15.9235 14.2369 15.9235 15.683C15.9235 16.8753 17.0652 17.3827 18.1054 17.3827ZM24.7269 17.2813H26.5282L27.9997 10.1016H26.173L25.7417 12.2326L24.9806 12.1565C23.2047 12.1565 21.5811 14.4398 21.5811 16.0635C21.5811 16.7231 22.0123 17.3827 22.7481 17.3827C23.7882 17.3827 24.524 16.8753 24.8284 16.3679H24.9299L24.7269 17.2813ZM19.0948 21.0614C17.6769 22.2361 15.8559 22.8097 14.0208 22.6597C11.6107 22.5075 9.88549 20.6555 9.58105 18.702H9.68253C10.2407 19.9197 11.5853 21.0614 13.2343 21.239C14.9088 21.4419 16.913 20.6808 18.0039 19.6407L16.7861 18.702H20.4648L19.6529 22.609L19.1202 21.0868L19.0948 21.0614Z"
        fill="#EA1D2C"
      />
    </svg>
  )
}
