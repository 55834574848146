import { appEnv } from '~/lib/helpers'

import { decrypt, encrypt, md5 } from '../crypto'

import { type BucketKeyMappings, type CleanUpOptions } from './types'

const parseKey = (rawKey: string) => {
  if (appEnv.isProduction()) {
    return md5(rawKey)
  }

  return rawKey
}

export function createStorage(storage: 'localStorage' | 'sessionStorage') {
  function set<K extends keyof BucketKeyMappings>(key: K, data: BucketKeyMappings[K]) {
    const shouldEncrypt = appEnv.isProduction()

    const finalData = shouldEncrypt ? encrypt(JSON.stringify(data)) : data

    const parsedKey = parseKey(key)

    window[storage].setItem(parsedKey, JSON.stringify({ data: finalData, crypto: shouldEncrypt }))

    return parsedKey
  }

  function get<K extends keyof BucketKeyMappings>(key: K): BucketKeyMappings[K] | undefined {
    const value = window[storage].getItem(parseKey(key))

    if (value !== null) {
      const parsedValue = JSON.parse(value)

      if (parsedValue.crypto) return JSON.parse(decrypt(parsedValue.data))

      return parsedValue.data as BucketKeyMappings[K]
    }
  }

  function remove(key: string) {
    window[storage].removeItem(parseKey(key))
  }

  function batchRemove(keys: string[]) {
    keys.forEach(key => window[storage].removeItem(parseKey(key)))
  }

  function clear() {
    window[storage].clear()
  }

  function getAllRawValues() {
    return JSON.parse(JSON.stringify(window[storage])) as BucketKeyMappings | Record<string, string>
  }

  function removeByPrefix(prefix: string) {
    const values = getAllRawValues()
    Object.keys(values).forEach(key => {
      if (parseKey(key).includes(prefix)) remove(parseKey(key))
    })
  }

  function cleanUp({ optOut = [] }: CleanUpOptions = {}) {
    const storaged = getAllRawValues()

    for (const key in storaged) {
      if (!optOut?.includes(key as keyof BucketKeyMappings)) {
        remove(key)
      }
    }
  }

  return { set, get, remove, batchRemove, clear, removeByPrefix, cleanUp }
}
