export function CpfAvatarIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="41" cy="41" r="41" fill="#F2F4F7" />
      <mask id="mask0_11918_43315" maskUnits="userSpaceOnUse" x="0" y="0" width="82" height="82">
        <circle cx="41" cy="41" r="41" fill="#ffffff" />
      </mask>
      <g mask="url(#mask0_11918_43315)">
        <g clipPath="url(#clip0_11918_43315)">
          <path
            d="M51.7768 15.8931C50.2853 15.4852 48.6441 15.6491 47.2435 14.9924C44.4681 13.6919 43.2608 9.50174 40.1954 9.47344C38.3502 9.45694 36.7032 11.1123 34.8863 10.788C33.4244 10.5263 32.5083 9.10442 31.2986 8.24256C28.4902 6.24179 23.8626 8.13881 23.2684 11.5355C23.0963 12.5176 23.1929 13.6 22.6447 14.4323C22.1483 15.1857 21.2464 15.5406 20.5025 16.0523C18.3413 17.539 17.549 20.7082 18.7563 23.0367C19.6135 24.6885 21.3348 25.9724 21.4905 27.827C21.5423 28.4424 21.4068 29.0591 21.395 29.6769C21.3407 32.6232 24.109 34.9423 26.9587 35.6898C29.8083 36.4373 32.8183 36.0824 35.7564 36.297C38.698 36.5115 41.5878 37.3015 44.5377 37.3475C47.4875 37.3946 50.6826 36.5281 52.4276 34.15C54.505 31.3204 54.729 26.49 55.7099 23.1782C56.6071 20.1599 55.0709 16.7951 51.7768 15.8931Z"
            fill="#1D232A"
          />
          <path
            d="M41.2069 92.1645H86.6871C85.4232 88.9717 83.6193 84.152 81.5207 78.8063C78.19 70.3234 71.6795 65.842 64.5784 63.6325C62.4267 62.9617 60.2184 62.5007 58.0278 62.2C55.5731 61.864 53.1408 61.7296 50.8288 61.7296H41.2069H31.5851C29.2731 61.7296 26.8408 61.864 24.3861 62.2C22.1955 62.5007 19.9872 62.9617 17.8355 63.6325C10.7344 65.842 4.2239 70.3234 0.893209 78.8063C-1.20542 84.152 -3.0093 88.9717 -4.27319 92.1645H41.2069Z"
            fill="#008E5A"
          />
          <path
            d="M54.7008 61.8617C54.6702 64.3494 53.7812 67.1047 52.8061 69.2151C50.7889 73.5904 46.1176 76.9565 39.0908 75.906C32.8821 74.9757 29.2343 70.0003 28.3536 66.8901C27.8997 65.2914 27.6415 63.3083 27.6509 61.8652C28.9891 61.7709 30.3048 61.7296 31.5852 61.7296H50.829C52.0893 61.7296 53.3839 61.7697 54.7008 61.8617Z"
            fill="#E09661"
          />
          <path
            d="M50.3949 61.7293C48.1018 66.5126 44.0743 67.0525 42.393 67.0537H42.3824C39.6011 67.0537 37.4565 66.0468 36.0099 64.0626C35.9592 63.993 35.9108 63.9223 35.8637 63.8539C37.0439 65.2581 38.9715 66.6776 41.9415 66.6776C45.4466 66.6776 48.0192 65.0588 49.7877 61.7293H49.3161C48.4661 63.0887 46.2071 65.837 41.9356 65.5988C39.8487 65.4833 38.2134 64.7464 37.0698 63.4071C38.092 64.2701 39.8487 65.205 42.6595 65.2416H42.7762C46.4618 65.2416 48.3081 62.9861 49.0509 61.7281H35.635C36.1136 62.9885 37.6452 65.7521 41.9143 65.9891C43.8338 66.0964 45.3641 65.6271 46.5596 64.9433C44.6461 66.2838 42.762 66.2838 41.9415 66.2838C37.657 66.2838 35.6515 63.1689 34.9535 61.7281H34.4253C34.6057 62.3342 34.9771 63.3021 35.6833 64.2783C36.7326 65.7238 38.7452 67.4451 42.3836 67.4451H42.3942C44.1721 67.444 48.4566 66.8698 50.8288 61.7281H50.3949V61.7293Z"
            fill="#1D232A"
          />
          <path
            d="M59.6467 44.5688C59.5394 44.6678 59.4263 44.7633 59.3119 44.8541C59.1386 44.9944 58.9582 45.1253 58.7719 45.2467H58.7707C58.641 45.334 58.5102 45.4129 58.3746 45.4896C58.2791 45.5438 58.1812 45.5969 58.081 45.6452H58.0798C57.857 45.7572 57.6294 45.8574 57.3936 45.9459C57.3382 45.9671 57.2828 45.9883 57.2262 46.0072C57.0788 46.0567 56.9303 46.105 56.7794 46.1463H56.7782C56.2359 46.2937 55.6676 46.3786 55.0804 46.3903C51.2086 46.4729 48.004 43.4027 47.9203 39.5309C47.8378 35.6591 50.9091 32.4533 54.781 32.3708C58.6528 32.2871 61.8574 35.3584 61.9399 39.2303C61.9847 41.3407 61.0945 43.2518 59.6467 44.5688Z"
            fill="#E09661"
          />
          <path
            d="M58.3742 45.4898C57.9356 45.2563 57.4994 45.0217 57.0608 44.7883C57.4003 45.0748 57.7411 45.3601 58.0794 45.6454C57.8566 45.7574 57.6291 45.8576 57.3932 45.946C57.2058 45.8246 57.0171 45.7032 56.8297 45.5806C56.9606 45.7244 57.0914 45.8659 57.2258 46.0074C57.0785 46.0569 56.9299 46.1052 56.779 46.1465H56.7778C56.4618 45.8364 56.1459 45.5275 55.8299 45.2174C55.7591 45.1479 55.7544 45.0583 55.7874 44.9875C55.7096 44.8944 55.6318 44.8048 55.5552 44.7104C55.3995 44.5194 55.6801 44.3284 55.8511 44.4463C56.1553 44.6562 56.463 44.8625 56.7731 45.0677C56.2968 44.668 55.8204 44.2671 55.3453 43.8663C55.1732 43.7224 55.363 43.4277 55.5622 43.535C56.6316 44.1056 57.7021 44.6763 58.7703 45.2481C58.6395 45.3341 58.5086 45.4131 58.3742 45.4898Z"
            fill="#1D232A"
          />
          <path
            d="M59.6464 44.5689C59.5391 44.6679 59.4259 44.7634 59.3115 44.8542C57.8731 44.1881 56.4595 43.4724 55.073 42.7061C54.8478 42.5823 55.0211 42.2215 55.2463 42.3465C56.6847 43.14 58.1537 43.8815 59.6464 44.5689Z"
            fill="#1D232A"
          />
          <path
            d="M27.4598 46.98C31.3322 46.98 34.4713 43.8408 34.4713 39.9684C34.4713 36.0961 31.3322 32.9569 27.4598 32.9569C23.5874 32.9569 20.4482 36.0961 20.4482 39.9684C20.4482 43.8408 23.5874 46.98 27.4598 46.98Z"
            fill="#E09661"
          />
          <path
            d="M41.6314 58.6815C33.4574 58.8572 26.6887 52.3715 26.513 44.1974L26.2996 34.2231C26.124 26.049 32.6097 19.2804 40.7837 19.1047C48.9578 18.929 55.7265 25.4147 55.9021 33.5888L56.1155 43.5631C56.29 51.7372 49.8055 58.5058 41.6314 58.6815Z"
            fill="#E09661"
          />
          <path
            d="M45.659 24.9987C44.8596 25.9595 44.3232 27.2211 43.2291 27.7776C41.8968 28.4555 40.2886 27.8165 38.8267 28.0876C37.9896 28.2433 37.2091 28.7102 36.662 29.3846C35.924 30.2936 35.6268 31.5197 34.8911 32.4323C33.7463 33.853 31.7609 34.2291 29.9841 34.0287C28.8759 33.9037 27.8018 33.5912 26.7254 33.3083C25.827 33.0725 24.4157 33.0996 23.7578 32.3545C23.2614 31.7921 23.2001 30.942 23.344 30.1957C23.6152 28.7927 26.8892 20.5703 29.0775 19.7969C31.5192 18.9339 37.3576 16.6501 45.4963 19.0518C48.0535 19.8063 50.8678 22.116 52.5915 24.2913C50.6662 23.6746 48.05 22.9932 46.2839 24.3773C46.0551 24.5589 45.85 24.7699 45.659 24.9987Z"
            fill="#1D232A"
          />
          <path
            d="M59.2199 33.8342C57.4361 32.5514 55.8326 32.4264 55.8326 32.4264C55.8326 32.4264 51.9478 30.4893 52.173 24.0779L55.4047 22.6525C59.1645 23.15 60.5274 25.35 60.1313 28.4025C59.8719 30.4032 59.2199 33.8342 59.2199 33.8342Z"
            fill="#1D232A"
          />
          <path
            d="M72.8479 92.1642H9.56714C11.0951 90.8025 13.531 87.8078 14.5567 82.0048L17.8355 63.6323C19.9872 62.9615 22.1955 62.5005 24.3861 62.1998L20.8502 82.0059C28.7354 82.0083 41.2069 82.013 41.2069 82.013C41.2069 82.013 53.6784 82.0083 61.5648 82.0059L58.0278 62.201C58.1469 62.2175 58.2659 62.234 58.385 62.2505C58.4027 62.2541 58.4204 62.2564 58.4393 62.2588C58.576 62.2788 58.7128 62.3 58.8496 62.3213C59.1243 62.3637 59.3978 62.4085 59.6725 62.4569C59.7668 62.4734 59.86 62.4899 59.9543 62.5064C59.9838 62.5123 60.0121 62.517 60.0415 62.5229C60.0851 62.53 60.1288 62.5382 60.1724 62.5465C60.2797 62.5665 60.3881 62.5877 60.4954 62.6089C60.6534 62.6396 60.8102 62.6714 60.967 62.7033C61.0838 62.728 61.2005 62.7528 61.3172 62.7775C61.4646 62.8094 61.612 62.8424 61.7593 62.8754C61.8784 62.9025 61.9987 62.9308 62.1178 62.9591C62.4031 63.0263 62.6896 63.0982 62.9749 63.1737C63.5125 63.314 64.0466 63.4673 64.5783 63.6323L67.8583 82.0048C68.8829 87.809 71.3199 90.8048 72.8479 92.1642Z"
            fill="#E8E9EA"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_11918_43315">
          <rect width="90.9603" height="84.7386" fill="white" transform="translate(-4.27319 7.4259)" />
        </clipPath>
      </defs>
    </svg>
  )
}
