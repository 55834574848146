export function KycOnboardingFreemiumIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="483"
      height="236"
      viewBox="0 0 483 236"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="pagina home freemium kyc"
      {...props}
    >
      <g clipPath="url(#clip0_1854_48226)">
        <path
          d="M470.309 76.363L469.865 79.4218L469.162 84.3036L466.477 102.908L461.845 135.048L461.229 139.324C460.66 143.275 457.263 146.208 453.263 146.208H359.069L361.364 130.292L364.045 111.688L364.749 106.806L365.583 101.032L369.469 74.0784C370.038 70.1272 373.435 67.1904 377.435 67.1904H462.342C466.347 67.1904 469.586 70.0776 470.259 73.7771C470.412 74.6047 470.435 75.4743 470.309 76.363Z"
          fill="#00A868"
        />
        <path
          d="M464.293 77.3813L455.822 135.529H366.417L367.568 127.546L369.939 111.104L370.559 106.791L371.293 101.688L374.877 76.8359C375.011 75.8977 375.818 75.1997 376.77 75.1997H462.396C463.567 75.1997 464.462 76.2295 464.293 77.3813Z"
          fill="white"
        />
        <path
          d="M410.999 121.025C411.032 121.558 411.518 121.958 412.049 121.885C420.146 120.819 426.119 113.643 425.545 105.399C424.971 97.154 418.068 90.8457 409.902 90.8904C409.362 90.8937 408.944 91.3466 408.976 91.8798L409.379 97.6975C409.416 98.2387 409.857 98.653 410.403 98.6782C414.284 98.884 417.484 101.954 417.762 105.923C418.04 109.891 415.282 113.369 411.469 114.089C410.936 114.195 410.556 114.658 410.592 115.2L410.999 121.025Z"
          fill="#008E5A"
        />
        <path
          d="M401.405 99.5244C401 99.1738 400.384 99.2105 400.033 99.6141L394.863 106.158C394.568 106.53 394.402 106.996 394.434 107.509C394.473 108.017 394.697 108.461 395.039 108.795L401.064 114.579C401.469 114.929 402.085 114.893 402.436 114.489C402.613 114.281 402.693 114.033 402.673 113.779L401.73 100.197C401.723 99.946 401.606 99.706 401.405 99.5244Z"
          fill="#008E5A"
        />
        <path
          d="M397.274 140.628C397.274 142.169 396.501 143.565 395.25 144.576C394 145.582 392.271 146.208 390.359 146.208H375.198C375.198 146.208 324.651 146.208 310.661 146.208C305.677 146.208 301.547 143.252 300.824 139.392C300.747 139 300.709 138.591 300.709 138.18V136.273C300.709 135.598 301.386 135.048 302.224 135.048H390.359C394.179 135.048 397.274 137.546 397.274 140.628Z"
          fill="#00A868"
        />
        <path
          d="M390.359 144.232V146.208C390.359 146.208 324.652 146.208 310.661 146.208C305.678 146.208 301.547 143.252 300.824 139.393L390.359 144.232Z"
          fill="#1D232A"
        />
        <path
          d="M461.844 135.048L461.228 139.324C460.658 143.275 457.262 146.208 453.261 146.208H393.819C387.638 146.208 382.628 141.212 382.628 135.048H461.844Z"
          fill="#42EC9A"
        />
        <path
          d="M348.906 72.2593C348.252 75.833 343.643 80.7758 335.89 82.8659C332.984 87.3625 328.26 89.853 326.619 95.0781C324.256 102.595 319.903 115.46 318.075 121.688L281.462 94.9904C294.691 80.5432 302.956 57.198 300.413 38.4105C300.134 36.3357 299.751 34.2189 300.214 32.1747C300.677 30.1266 302.218 28.1243 304.44 27.7314C306.307 27.3958 308.238 28.2844 309.519 29.585C310.797 30.8894 311.535 32.5599 312.193 34.2113C313.711 38.0443 315.463 42.3007 316.331 46.3092C317.674 45.6493 328.363 41.393 336.59 43.5288C339.704 44.5052 341.551 47.8424 341.31 50.9012C341.222 52.0682 340.434 53.6777 338.912 55.2262C345.318 55.169 347.46 59.3986 346.477 62.7397C346.171 63.7809 345.513 64.9022 344.542 66.0082C347.995 66.8816 349.365 69.723 348.906 72.2593Z"
          fill="#E09661"
        />
        <path
          d="M321.733 61.87C318.654 61.87 316.053 60.4246 314.978 58.0599C313.999 55.905 314.011 52.6746 316.971 50.062C318.237 48.9446 321.477 47.152 323.913 46.4235C324.215 46.332 324.536 46.5036 324.628 46.8087C324.72 47.1139 324.548 47.4304 324.242 47.522C321.978 48.197 318.883 49.9019 317.728 50.9202C315.235 53.1208 315.212 55.8097 316.019 57.5908C316.979 59.7037 319.48 60.9242 322.398 60.703C329.233 60.1881 334.656 58.201 338.527 54.8028C338.764 54.593 339.127 54.6159 339.338 54.8524C339.548 55.0888 339.525 55.4512 339.288 55.6609C335.23 59.227 329.577 61.3094 322.49 61.8433C322.234 61.8586 321.981 61.87 321.733 61.87Z"
          fill="#1D232A"
        />
        <path
          d="M327.465 73.1784C326.57 73.1784 325.649 73.1403 324.704 73.0602C320.833 72.7322 318.137 71.0235 317.303 68.3728C316.462 65.7031 317.617 63.163 320.745 60.8174C320.998 60.6267 321.357 60.6763 321.549 60.9318C321.74 61.1835 321.69 61.5421 321.434 61.7328C318.738 63.7542 317.716 65.8747 318.397 68.0296C319.372 71.1341 323.201 71.7863 324.8 71.9198C334.239 72.7169 340.962 69.1967 344.106 65.6306C344.313 65.3942 344.68 65.3713 344.917 65.5772C345.154 65.787 345.177 66.1493 344.971 66.3858C341.965 69.7993 335.895 73.1784 327.465 73.1784Z"
          fill="#1D232A"
        />
        <path
          d="M482.253 146.78H322.078C321.76 146.78 321.504 146.524 321.504 146.208C321.504 145.891 321.76 145.636 322.078 145.636H482.253C482.571 145.636 482.827 145.891 482.827 146.208C482.827 146.524 482.571 146.78 482.253 146.78Z"
          fill="#1D232A"
        />
        <path
          d="M328.533 84.422C324.907 84.422 322.238 83.0642 321.178 80.669C320.386 78.8803 320.256 76.0427 324.127 72.6865C324.368 72.4805 324.727 72.5034 324.938 72.7437C325.144 72.9801 325.121 73.3425 324.88 73.5522C322.195 75.8787 321.304 78.1213 322.23 80.2114C323.5 83.0833 327.278 83.3121 328.839 83.2778C331.241 83.2244 333.566 82.9002 335.746 82.3129C336.048 82.2328 336.366 82.412 336.45 82.7171C336.534 83.0222 336.35 83.335 336.044 83.4189C333.776 84.0291 331.359 84.3686 328.866 84.422C328.747 84.422 328.64 84.422 328.533 84.422Z"
          fill="#1D232A"
        />
        <path
          d="M347.626 180.964C347.626 180.964 220.678 179.713 219.424 180.964C218.169 182.215 203.54 73.9678 203.54 73.9678L232.638 80.2303L233.862 80.4934L234.305 80.5888L236.791 81.1266L236.876 81.1456L250.686 84.1167L251.543 84.2998L252.951 84.6049L293.442 93.3198C302.816 95.3373 310.703 101.623 314.741 110.299L347.626 180.964Z"
          fill="#008E5A"
        />
        <path
          d="M21.812 75.7946C21.6322 75.7946 21.4524 75.7107 21.3415 75.5505C21.1809 75.3217 17.341 69.8143 15.2642 61.4846C15.1877 61.1795 15.3751 60.8668 15.6811 60.7905C15.987 60.7142 16.3006 60.9011 16.3771 61.2062C18.3966 69.3185 22.2403 74.8335 22.2786 74.8907C22.4621 75.15 22.3971 75.5047 22.1371 75.6878C22.0414 75.7641 21.9267 75.7946 21.812 75.7946Z"
          fill="#1D232A"
        />
        <path
          d="M131.652 150.75L99.7732 185.991C85.7636 200.766 60.039 202.681 46.6184 181.018C36.4563 164.61 34.5631 143.912 24.7185 124.259C15.2028 105.257 4.16103 97.7249 3.30048 70.7756C3.21252 68.0943 3.46112 63.1972 4.52437 61.0004C6.89182 56.1033 13.1183 56.069 15.8224 61.3513C15.3405 57.831 15.0918 53.628 16.1704 50.3366C18.4384 43.4562 26.5696 43.9787 28.241 49.2115C29.5681 53.3649 30.4631 56.1224 32.0274 58.5633C31.6181 55.7524 31.7979 48.7729 31.8285 45.3785C31.8514 42.8002 32.1918 39.5774 33.9053 37.7506C37.0682 34.3714 42.9123 34.3523 45.9796 42.0451C49.0088 49.6348 51.3035 67.61 58.3906 73.5254C64.3264 78.4835 69.375 90.814 72.5685 106.055C71.8686 110.033 71.9222 113.957 73.2685 118.633L79.7627 136.94L96.1475 118.82C104.99 109.045 120.109 108.267 129.911 117.085C139.714 125.902 140.494 140.975 131.652 150.75Z"
          fill="#E09661"
        />
        <path
          d="M43.0612 122.733L53.774 125.197L73.1114 118.972C69.3594 104.411 77.2344 91.287 83.633 83.251C86.8648 79.1892 85.3655 73.4835 80.8945 72.5949C77.307 71.8817 73.1458 73.7276 66.8925 83.3349C65.879 84.8948 63.9743 89.0749 60.3715 90.7073C43.9982 98.1292 41.3784 114.796 43.0612 122.733Z"
          fill="#E09661"
        />
        <path
          d="M278.377 168.752H222.024V107.657H263.082C271.531 107.657 278.381 114.488 278.381 122.913V168.752H278.377Z"
          fill="white"
        />
        <path
          d="M130.287 43.8986C128.841 40.7712 127.376 37.5827 126.894 34.1731C126.412 30.7596 127.043 27.0295 129.365 24.4742C131.235 22.4147 134.019 21.3353 136.796 21.137C139.573 20.9387 142.353 21.5603 144.977 22.4833C143.44 10.0117 152.473 8.27253 156.023 9.04295C159.075 9.70658 162.425 12.2276 164.349 14.6838C166.273 17.14 167.604 19.9928 168.915 22.8189C168.95 19.8669 170.464 17.632 173.019 17.6129C177.659 17.5748 180.91 24.6267 180.948 29.5887C183.185 27.9182 188.762 28.1318 186.455 38.1358C186.455 38.1358 216.892 46.1641 201.479 65.4322C201.479 65.4322 218.823 70.0509 213.966 88.499C209.109 106.947 133.809 100.582 129.992 99.8874C126.175 99.1895 123.349 95.6768 126.745 86.6034C126.745 86.6034 108.192 85.0473 112.009 67.015C115.099 52.3885 130.287 43.8986 130.287 43.8986Z"
          fill="#1D232A"
        />
        <path d="M255.493 138.45L271.867 185.858H214.822V157.406L255.493 138.45Z" fill="#E09661" />
        <path
          d="M258.198 141.089L214.108 162.688L116.224 167.802L81.167 131.837L86.5444 125.593L88.2579 127.031L109.286 101.817C111.122 99.6547 113.692 98.2512 116.507 97.8736C126.63 96.5159 155.679 93.6783 183.457 91.1878C186.643 90.9017 189.798 90.6195 192.892 90.3449C201.636 89.5707 209.874 88.846 216.842 88.2396C229.127 87.1679 240.551 94.6013 244.517 106.241L253.704 133.183L255.223 132.584L258.198 141.089Z"
          fill="#E8E9EA"
        />
        <path d="M114.274 141.562L107.772 177.15L128.464 154.274L114.274 141.562Z" fill="#1D232A" />
        <path
          d="M112.896 122.996L116.763 175.026C117.058 179.008 120.385 182.09 124.393 182.09H209.928C214.835 182.09 218.472 177.547 217.386 172.776L198.985 110.475L112.896 122.996Z"
          fill="#008E5A"
        />
        <path d="M125.789 96.7598L125.2 121.207L135.152 119.758V95.7949L125.789 96.7598Z" fill="#008E5A" />
        <path d="M193.78 90.2725V111.181L183.828 112.63L184.352 91.1115L193.78 90.2725Z" fill="#008E5A" />
        <path
          d="M162.581 181.739H137.338C128.037 181.739 120.085 188.413 118.494 197.551L112.004 234.901H245.251L209.031 181.739H162.581Z"
          fill="#008E5A"
        />
        <path
          d="M167.535 174.233C163.527 176.338 158.329 175.705 154.73 172.959C151.131 170.213 149.173 165.586 149.357 161.071C149.54 156.559 151.747 152.23 155.055 149.141C154.134 145.735 154.696 142.432 156.685 139.514C158.673 136.596 161.974 134.606 165.489 134.209C167.669 129.483 170.549 127.039 175.693 126.589C180.837 126.139 187.045 127.958 191.822 131.688C192.441 128.133 194.166 123.961 195.86 120.769C197.551 117.58 200.5 114.834 204.079 114.327C207.663 113.82 211.691 116.177 212.192 119.75C212.658 123.057 210.272 125.99 208.967 129.064C206.409 135.09 208.099 142.237 211.691 147.714C215.282 153.191 220.537 157.333 225.696 161.372C233.342 167.356 240.987 173.34 248.633 179.328C242.502 196.422 234.837 212.853 223.539 227.086C223.539 227.086 202.997 208.672 188.276 187.082C175.104 186.666 169.853 177.741 167.535 174.233Z"
          fill="#E09661"
        />
        <path
          d="M167.535 174.805C167.355 174.805 167.179 174.721 167.069 174.565C164.223 170.602 162.452 163.706 162.376 163.413C162.299 163.107 162.483 162.795 162.793 162.718C163.099 162.642 163.412 162.825 163.489 163.134C163.508 163.203 165.279 170.114 168.002 173.901C168.185 174.156 168.128 174.515 167.868 174.698C167.768 174.77 167.654 174.805 167.535 174.805Z"
          fill="#1D232A"
        />
        <path
          d="M154.738 150.018C154.589 150.018 154.44 149.961 154.325 149.843C154.103 149.614 154.111 149.255 154.337 149.034C154.547 148.832 159.569 144.141 169.18 148.901C169.463 149.042 169.578 149.385 169.44 149.667C169.299 149.95 168.955 150.064 168.671 149.927C159.775 145.522 155.182 149.812 155.136 149.854C155.025 149.965 154.884 150.018 154.738 150.018Z"
          fill="#1D232A"
        />
        <path
          d="M182.058 139.488C181.962 139.488 181.863 139.465 181.771 139.411C171.766 133.664 165.661 134.758 165.6 134.77C165.29 134.831 164.988 134.629 164.927 134.32C164.866 134.011 165.065 133.709 165.375 133.648C165.635 133.595 171.919 132.432 182.341 138.42C182.616 138.576 182.708 138.927 182.551 139.202C182.448 139.385 182.257 139.488 182.058 139.488Z"
          fill="#1D232A"
        />
        <path
          d="M190.048 58.9485C189.092 65.2071 183.225 69.5131 176.948 68.5596C170.672 67.6061 166.354 61.7555 167.31 55.4968C168.121 50.1878 172.462 46.29 177.591 45.8056H177.595C178.513 45.7179 179.457 45.7446 180.41 45.8895C180.578 45.9162 180.742 45.9429 180.907 45.9772C186.912 47.1557 190.977 52.8576 190.048 58.9485Z"
          fill="#E09661"
        />
        <path
          d="M178.489 58.4681C176.088 74.2045 161.344 85.0246 145.559 82.6257C129.779 80.2305 118.928 65.5277 121.334 49.7876C121.996 45.4435 123.602 41.4731 125.908 38.0559C131.955 29.0854 142.836 23.8947 154.264 25.6262C156.731 26 159.076 26.675 161.271 27.6094C168.239 30.5691 173.67 36.1298 176.546 42.9072C178.558 47.6556 179.323 52.9951 178.489 58.4681Z"
          fill="#E09661"
        />
        <path
          d="M155.619 70.4723C155.354 70.4723 155.085 70.4585 154.817 70.431C154.546 70.4035 154.349 70.1525 154.375 69.8671C154.402 69.5817 154.647 69.3789 154.912 69.4029C157.003 69.6161 159.008 68.8837 160.281 67.4464C161.044 66.5903 161.875 65.1014 161.521 62.8802C161.475 62.5982 161.659 62.3335 161.924 62.2853C162.192 62.2372 162.444 62.4263 162.49 62.7082C162.817 64.7644 162.29 66.6969 161.001 68.1513C159.686 69.6367 157.716 70.4723 155.619 70.4723Z"
          fill="#1D232A"
        />
        <path
          d="M159.569 52.4689C159.462 52.4803 159.355 52.4917 159.248 52.4994C159.202 52.5032 159.152 52.507 159.106 52.5108C158.98 52.5223 158.854 52.5299 158.731 52.5413C158.662 52.5489 158.594 52.5489 158.529 52.5528C158.314 52.568 158.1 52.5795 157.886 52.5833C157.626 52.5909 157.37 52.5985 157.121 52.5947C153.017 52.5909 149.801 51.3743 149.3 49.5207C149.296 49.5169 149.296 49.5092 149.296 49.5054C149.284 49.4635 149.277 49.4253 149.273 49.3872C149.262 49.3338 149.25 49.2804 149.246 49.2308C149.009 47.095 152.359 44.9325 156.972 44.0782C157.213 44.0324 157.454 43.9905 157.699 43.9561C157.997 43.9104 158.303 43.8722 158.613 43.8379C158.705 43.8265 158.796 43.8188 158.884 43.8112C158.999 43.7998 159.114 43.7921 159.229 43.7807C159.642 43.7464 160.051 43.7235 160.449 43.712C165.053 43.5862 168.706 44.9668 168.939 47.0721C169.203 49.4558 165.008 51.8739 159.569 52.4689Z"
          fill="white"
        />
        <path
          d="M147.847 49.3567C147.832 49.4596 147.809 49.5664 147.774 49.6694C147.124 51.6527 143.391 52.2705 139.008 51.2865C138.939 51.2713 138.87 51.256 138.802 51.2408C138.675 51.2102 138.545 51.1797 138.415 51.1454C138.102 51.0691 137.784 50.9776 137.467 50.8822C137.31 50.8365 137.157 50.7869 137 50.7373C136.897 50.703 136.794 50.6687 136.694 50.6343C136.534 50.5809 136.377 50.5237 136.224 50.4665C136.174 50.4475 136.124 50.4322 136.075 50.4093C133.6 49.4902 131.562 48.2277 130.288 46.9272C130.288 46.9234 130.284 46.9234 130.28 46.9234C129.221 45.8402 128.685 44.7265 128.873 43.7654C128.88 43.7349 128.884 43.7082 128.892 43.6815C128.903 43.6319 128.919 43.5862 128.934 43.5404C129.565 41.6067 133.133 40.9698 137.379 41.8584C137.513 41.8851 137.647 41.9118 137.78 41.9462C137.888 41.9729 137.998 41.9957 138.102 42.0224C138.331 42.0796 138.557 42.1369 138.79 42.2017C139.092 42.2856 139.394 42.3771 139.7 42.4801C139.907 42.5488 140.113 42.6174 140.316 42.6937C144.959 44.3413 148.176 47.198 147.847 49.3567Z"
          fill="white"
        />
        <path
          d="M152.483 59.8603C152.452 59.8603 152.425 59.8565 152.395 59.8527L148.314 59.2348C147.113 59.0517 146.069 58.35 145.453 57.305C144.837 56.2599 144.726 55.009 145.147 53.8724L147.954 46.336C148.065 46.0385 148.394 45.8898 148.692 46.0004C148.991 46.111 149.14 46.439 149.029 46.7365L146.222 54.2729C145.919 55.0852 146 55.9777 146.44 56.7252C146.879 57.4728 147.625 57.9762 148.486 58.1059L152.567 58.7237C152.88 58.7695 153.095 59.0632 153.049 59.3759C153.007 59.6582 152.762 59.8603 152.483 59.8603Z"
          fill="#1D232A"
        />
        <path
          d="M147.846 49.3566C147.831 49.4596 147.808 49.5664 147.774 49.6694C147.124 51.6526 143.391 52.2705 139.008 51.2865L140.32 42.686C144.959 44.3413 148.175 47.1979 147.846 49.3566Z"
          fill="#1D232A"
        />
        <path
          d="M159.569 52.4687C159.462 52.4801 159.355 52.4916 159.248 52.4992C159.202 52.503 159.152 52.5068 159.106 52.5106L160.453 43.7042C165.058 43.5784 168.71 44.959 168.943 47.0643C169.203 49.4557 165.008 51.8737 159.569 52.4687Z"
          fill="#1D232A"
        />
        <path
          d="M179.843 62.9379C179.659 62.9379 179.483 62.8502 179.373 62.69C178.244 61.0653 177.839 58.9447 178.29 57.0187C178.742 55.0926 180.046 53.3726 181.782 52.4153C182.057 52.2627 182.409 52.3619 182.562 52.6403C182.715 52.9149 182.616 53.2658 182.337 53.4183C180.879 54.2231 179.786 55.6686 179.407 57.2819C179.028 58.899 179.369 60.6763 180.317 62.0417C180.497 62.301 180.432 62.6595 180.172 62.8388C180.072 62.9036 179.958 62.9379 179.843 62.9379Z"
          fill="#1D232A"
        />
        <path
          d="M158.471 36.7588C159.268 35.6461 158.127 33.4723 155.923 31.9034C153.719 30.3345 151.286 29.9646 150.49 31.0772C149.694 32.1898 150.835 34.3637 153.039 35.9326C155.243 37.5015 157.675 37.8714 158.471 36.7588Z"
          fill="#1D232A"
        />
        <path
          d="M139.591 36.3216C142.224 35.6881 144.097 34.0972 143.775 32.7683C143.454 31.4394 141.059 30.8757 138.427 31.5092C135.794 32.1427 133.921 33.7335 134.242 35.0624C134.564 36.3913 136.959 36.9551 139.591 36.3216Z"
          fill="#1D232A"
        />
        <path
          d="M348.876 235.473H68.0249C67.7074 235.473 67.4512 235.218 67.4512 234.901C67.4512 234.585 67.7074 234.329 68.0249 234.329H348.88C349.197 234.329 349.453 234.585 349.453 234.901C349.453 235.218 349.193 235.473 348.876 235.473Z"
          fill="#1D232A"
        />
        <path
          d="M158.045 26.4615C158.045 26.4615 155.834 36.3625 168.949 40.0468C168.949 40.0468 169.373 46.1072 177.589 45.802C187.808 43.2467 179.077 26.0687 173.416 23.9519C167.752 21.8352 158.045 26.4615 158.045 26.4615Z"
          fill="#1D232A"
        />
        <path
          d="M142.338 95.0627C142.338 95.0627 141.971 103.984 152.481 103.476C157.037 103.259 166.457 99.525 172.32 92.2061L142.338 95.0627Z"
          fill="#E09661"
        />
        <path
          d="M128.611 185.03C128.316 185.03 128.064 184.805 128.041 184.504C128.014 184.187 128.251 183.913 128.565 183.886L149.807 182.177L132.099 182.662C131.793 182.681 131.518 182.421 131.51 182.105C131.502 181.788 131.751 181.525 132.068 181.517L171.344 180.442C171.348 180.442 171.355 180.442 171.359 180.442C171.661 180.442 171.914 180.678 171.933 180.983C171.948 181.292 171.715 181.559 171.405 181.586L128.657 185.03C128.642 185.03 128.626 185.03 128.611 185.03Z"
          fill="#1D232A"
        />
        <path d="M222.216 158.965L233.181 167.23L231.338 154.274L222.216 158.965Z" fill="#1D232A" />
        <path
          d="M217.576 148.866C217.351 148.866 217.14 148.733 217.049 148.515C216.926 148.226 217.064 147.89 217.355 147.768L253.478 132.665C253.769 132.543 254.106 132.68 254.228 132.97C254.35 133.26 254.213 133.595 253.922 133.717L217.798 148.821C217.726 148.855 217.649 148.866 217.576 148.866Z"
          fill="#1D232A"
        />
        <path
          d="M113.707 148.782C113.585 148.782 113.459 148.744 113.355 148.664L88.6598 129.556C88.4112 129.362 88.3653 129.003 88.5565 128.751C88.7516 128.504 89.1111 128.458 89.3597 128.648L114.055 147.756C114.304 147.951 114.35 148.309 114.159 148.561C114.048 148.706 113.876 148.782 113.707 148.782Z"
          fill="#1D232A"
        />
        <path
          d="M115.409 122.592C115.111 122.592 114.862 122.363 114.839 122.066L114.227 114.308C114.204 113.991 114.437 113.717 114.755 113.694C115.072 113.671 115.348 113.904 115.371 114.22L115.983 121.978C116.006 122.294 115.772 122.569 115.455 122.592C115.44 122.592 115.424 122.592 115.409 122.592Z"
          fill="#1D232A"
        />
        <path
          d="M251.542 84.3C250.498 85.1466 249.354 85.8255 248.149 86.3061C245.342 87.4236 242.244 87.4503 239.425 86.3786C237.536 85.6615 236.01 84.5135 235.004 83.0604C234.469 82.2862 234.082 81.4014 233.861 80.4936L232.637 80.2305C232.855 81.4624 233.34 82.6714 234.059 83.7126C235.195 85.3564 236.913 86.6493 239.02 87.4503C240.527 88.0224 242.11 88.3084 243.69 88.3084C245.346 88.3084 247.002 87.9957 248.574 87.3664C250.154 86.7371 251.638 85.7988 252.949 84.6051L251.542 84.3ZM240.076 85.5509C242.875 86.5654 245.954 86.2374 248.551 84.6966C248.979 84.6165 249.4 84.5212 249.817 84.3991H249.966C250.119 84.3953 250.257 84.3305 250.36 84.2313C250.471 84.1932 250.578 84.155 250.685 84.1169L236.874 81.1458C237.379 81.6035 237.907 82.0268 238.454 82.412C237.865 82.0535 237.307 81.6187 236.79 81.1267L234.304 80.589C235.826 82.9079 237.941 84.7767 240.076 85.5509ZM243.013 84.853C243.415 84.853 243.816 84.8186 244.218 84.7691C244.321 84.7843 244.428 84.8034 244.531 84.8186C244.658 84.8339 244.784 84.8415 244.91 84.853C243.59 85.0856 242.233 85.017 240.932 84.6241C241.617 84.7729 242.313 84.853 243.013 84.853Z"
          fill="#1D232A"
        />
        <path
          d="M416.216 72.4309C416.928 72.4309 417.505 71.8555 417.505 71.1456C417.505 70.4358 416.928 69.8604 416.216 69.8604C415.504 69.8604 414.927 70.4358 414.927 71.1456C414.927 71.8555 415.504 72.4309 416.216 72.4309Z"
          fill="#1D232A"
        />
        <path
          d="M42.9232 123.313C42.6517 123.313 42.4069 123.118 42.361 122.84C42.3113 122.554 41.1333 115.799 42.755 108.362C42.8238 108.053 43.1298 107.859 43.4357 107.924C43.7455 107.992 43.9406 108.294 43.8756 108.602C42.3075 115.811 43.4778 122.569 43.4893 122.638C43.5467 122.951 43.3363 123.244 43.0265 123.301C42.9921 123.309 42.9577 123.313 42.9232 123.313Z"
          fill="#1D232A"
        />
        <path
          d="M83.8553 146.856C83.6334 146.856 83.4231 146.727 83.3275 146.513L79.2351 137.165C79.1089 136.875 79.2427 136.539 79.5296 136.41C79.8203 136.284 80.1568 136.417 80.283 136.703L84.3754 146.051C84.5016 146.341 84.3678 146.677 84.0809 146.807C84.0082 146.841 83.9317 146.856 83.8553 146.856Z"
          fill="#1D232A"
        />
        <path
          d="M268.097 20.2901C268.097 20.2901 270.193 17.8759 274.273 17.914C277.578 17.9445 279.261 20.2558 277.914 23.7227C276.587 27.1361 272.663 29.2376 272.663 29.2376L268.097 20.2901Z"
          fill="#1D232A"
        />
        <path
          d="M222.001 34.7872C220.307 24.7183 222.969 14.7525 226.465 12.1552C230.588 9.09258 233.774 13.2116 233.774 13.2116C233.774 13.2116 237.656 4.56542 245.228 1.04896C252.801 -2.46751 255.367 3.68058 253.612 7.16653C253.612 7.16653 263.441 -1.27755 271.66 2.89109C279.853 7.0483 269.178 23.2461 269.178 23.2461L222.001 34.7872Z"
          fill="#1D232A"
        />
        <path
          d="M258.74 69.0021C248.884 72.3317 238.481 70.0585 231.011 63.9028C227.018 60.6076 223.867 56.2063 222.081 50.9545C220.654 46.7553 220.252 42.457 220.742 38.3303H220.746C220.864 37.3043 221.04 36.2936 221.27 35.2944C221.377 34.8253 221.495 34.36 221.625 33.9023C223.496 27.2927 227.703 21.4269 233.631 17.5672C235.628 16.2666 237.819 15.1949 240.183 14.3978C242.21 13.7151 244.256 13.265 246.298 13.04C255.29 12.0446 264.144 15.3512 270.279 21.6938C273.151 24.6573 275.423 28.2882 276.838 32.4492C281.963 47.5257 273.859 63.8914 258.74 69.0021Z"
          fill="#E09661"
        />
        <path
          d="M252.047 33.723C252.058 33.826 252.078 33.9328 252.104 34.0358C252.667 36.0457 256.369 36.8276 260.79 36.0381C260.859 36.0267 260.928 36.0152 261.001 36C261.127 35.9733 261.257 35.9504 261.391 35.9199C261.708 35.8551 262.029 35.7788 262.351 35.6987C262.507 35.6605 262.664 35.6186 262.821 35.5728C262.924 35.5423 263.031 35.5118 263.131 35.4813C263.292 35.4355 263.452 35.3859 263.609 35.3364C263.659 35.3211 263.708 35.3058 263.762 35.2868C266.275 34.4744 268.367 33.3035 269.698 32.064C269.698 32.0602 269.702 32.0602 269.705 32.0602C270.811 31.0228 271.4 29.9358 271.251 28.967C271.243 28.9365 271.243 28.9098 271.235 28.8793C271.224 28.8297 271.212 28.784 271.201 28.7344C270.654 26.774 267.12 25.9845 262.836 26.6825C262.703 26.7054 262.569 26.7244 262.431 26.7511C262.324 26.7702 262.213 26.7893 262.106 26.8122C261.876 26.8579 261.643 26.9075 261.41 26.9609C261.104 27.0334 260.798 27.1097 260.488 27.1974C260.278 27.2584 260.067 27.3156 259.865 27.3804C255.153 28.8374 251.81 31.5491 252.047 33.723Z"
          fill="white"
        />
        <path
          d="M274.245 27.6626L281.848 33.6391C283.91 35.26 283.956 38.3607 281.948 40.0427L278.154 43.2197"
          fill="#E09661"
        />
        <path
          d="M252.047 33.7231C252.058 33.8261 252.078 33.9329 252.104 34.0359C252.667 36.0458 256.369 36.8277 260.79 36.0382L259.861 27.3882C255.153 28.8375 251.81 31.5492 252.047 33.7231Z"
          fill="#1D232A"
        />
        <path
          d="M259.663 23.4861C261.844 21.8845 262.952 19.6939 262.139 18.5933C261.326 17.4927 258.899 17.8988 256.719 19.5004C254.539 21.102 253.431 23.2926 254.244 24.3932C255.057 25.4938 257.483 25.0877 259.663 23.4861Z"
          fill="#1D232A"
        />
        <path
          d="M272.806 47.1675C270.787 47.1675 268.446 46.3056 266.618 43.4832C266.503 43.3078 266.553 43.0713 266.733 42.9569C266.909 42.8425 267.146 42.8921 267.261 43.0713C270.737 48.449 275.694 45.7411 275.904 45.6267C276.088 45.5237 276.321 45.5885 276.425 45.7716C276.528 45.9547 276.463 46.1873 276.279 46.2903C275.468 46.748 274.214 47.1675 272.806 47.1675Z"
          fill="#1D232A"
        />
        <path
          d="M264.851 44.3944C264.683 44.3944 264.53 44.2838 264.484 44.116C264.427 43.9138 264.545 43.7041 264.748 43.6469C267.131 42.9756 267.693 41.6217 267.716 41.5644C267.792 41.3699 268.014 41.2708 268.209 41.3471C268.404 41.4233 268.504 41.6407 268.431 41.8352C268.404 41.9077 267.739 43.5973 264.955 44.3829C264.924 44.3868 264.886 44.3944 264.851 44.3944Z"
          fill="#1D232A"
        />
        <path
          d="M224.748 31.2976C224.748 31.2976 222.798 28.0023 219.428 27.682C216.701 27.4188 215.523 30.2411 216.95 34.8217C218.357 39.3336 220.614 43.6891 220.614 43.6891L224.748 31.2976Z"
          fill="#1D232A"
        />
        <path
          d="M240.004 27.3917C239.671 33.3949 234.198 35.1035 234.198 35.1035L220.747 38.3301C220.866 37.3042 221.042 36.2935 221.271 35.2942C221.378 34.8251 221.657 33.8106 221.787 33.3529C223.658 26.7434 225.31 20.4275 231.238 16.5677C233.235 15.2672 237.182 12.7233 239.545 11.9262C241.572 11.2435 244.253 13.2649 246.296 13.0398C247.29 20.0232 244.399 25.9005 240.004 27.3917Z"
          fill="#1D232A"
        />
        <path
          d="M231.012 63.9028C227.02 60.6075 223.868 56.2062 222.082 50.9544C220.3 45.714 220.12 40.3173 221.271 35.2943C221.378 34.8252 231.112 30.4201 231.242 29.9624C237.017 36.4004 234.034 50.2717 234.634 58.8874C234.562 60.3215 233.831 62.9188 231.012 63.9028Z"
          fill="#1D232A"
        />
        <path
          d="M220.605 52.1141C222.934 58.0028 229.612 60.8976 235.521 58.5749C241.426 56.2522 244.329 49.5931 242 43.7043C240.023 38.7081 234.921 35.8705 229.811 36.5379C228.897 36.6562 227.983 36.8888 227.084 37.2435C221.179 39.5662 218.276 46.2254 220.605 52.1141Z"
          fill="#E09661"
        />
        <path d="M239.683 33.4019L227.967 36.9448L234.642 58.8956L246.358 55.3527L239.683 33.4019Z" fill="#E09661" />
        <path
          d="M232.366 53.1171C232.263 53.1171 232.16 53.0904 232.064 53.0331C231.792 52.8653 231.712 52.5144 231.877 52.2475C232.752 50.8363 232.997 49.0399 232.531 47.4495C232.068 45.8553 230.898 44.4708 229.402 43.7462C229.115 43.6089 229 43.2656 229.138 42.9834C229.276 42.6973 229.62 42.5791 229.903 42.7202C231.685 43.586 233.081 45.2336 233.632 47.133C234.183 49.0323 233.892 51.1681 232.852 52.8539C232.749 53.0179 232.561 53.1171 232.366 53.1171Z"
          fill="#1D232A"
        />
        <path
          d="M41.4308 94.5175C39.0979 93.9129 36.778 93.2427 34.3406 92.4657C34.0894 92.3836 33.8322 92.3216 33.5702 92.2543C32.5976 92.0036 31.4991 91.7221 30.7861 90.7804C30.5836 90.5172 30.4287 90.2154 30.3259 89.8853L31.783 89.4292C31.8318 89.5941 31.9069 89.7399 32.0036 89.8611C32.3937 90.3766 33.1496 90.5721 33.9509 90.7747C34.2382 90.8481 34.5305 90.9218 34.8069 91.0101C37.2194 91.7759 39.5141 92.4399 41.8167 93.0381L41.4308 94.5175Z"
          fill="#BBBDBF"
        />
        <path
          d="M80.7169 66.2756L74.9535 76.643L73.7756 78.7592L73.1504 79.8869L50.9924 119.757C48.8906 123.538 44.1179 124.903 40.3325 122.807L12.5561 107.43C8.77049 105.338 7.40496 100.574 9.50674 96.7933L39.2312 43.3116C41.3279 39.531 46.1006 38.1653 49.886 40.2619L64.4159 48.3063L77.6573 55.6389C81.4478 57.7357 82.8134 62.5001 80.7169 66.2756Z"
          fill="#1D232A"
        />
        <path
          d="M80.719 66.2848L74.9557 76.6522L63.861 59.2844L59.0267 51.7097C59.9423 51.3931 61.978 50.0517 64.4234 48.3108L77.6648 55.6433C81.45 57.7449 82.8155 62.5093 80.719 66.2848Z"
          fill="#42EC9A"
        />
        <path
          d="M73.7788 78.7711L73.1537 79.8988C69.9426 74.9327 66.7317 69.9616 63.5206 64.9955C63.3537 64.734 63.5387 64.3503 63.7936 64.229C64.1003 64.0897 64.3895 64.2344 64.5612 64.5013C65.6248 66.1546 66.6987 67.8085 67.7624 69.4618C69.7664 72.5598 71.7702 75.6628 73.7788 78.7711Z"
          fill="#42EC9A"
        />
        <path
          d="M31.6589 118.005L3.54927 102.44C-0.236121 100.344 -1.60188 95.5845 0.499907 91.8041L30.2244 38.3223C32.3262 34.542 37.0938 33.1761 40.8792 35.2726L68.9888 50.8372C72.7742 52.9338 74.14 57.6932 72.0382 61.4735L42.3137 114.955C40.2119 118.736 35.4392 120.101 31.6589 118.005Z"
          fill="#00A868"
        />
        <path d="M65.6475 61.7161L33.377 43.8545L6.89248 91.4922L39.163 109.354L65.6475 61.7161Z" fill="#42EC9A" />
        <path d="M37.6085 46.203L33.3721 43.8579L6.89389 91.4895L11.1304 93.8346L37.6085 46.203Z" fill="white" />
        <path
          d="M52.2071 49.4657C53.1115 49.5047 53.8768 48.8044 53.9163 47.9014C53.9559 46.9985 53.2548 46.2348 52.3504 46.1957C51.446 46.1567 50.6808 46.857 50.6412 47.76C50.6017 48.6629 51.3027 49.4266 52.2071 49.4657Z"
          fill="#1D232A"
        />
        <path
          d="M33.6105 83.8556C33.4667 84.1139 33.5745 84.4441 33.8442 84.5677C37.9382 86.4688 42.8624 84.8906 45.0509 80.8747C47.2395 76.8587 45.8995 71.8715 42.0769 69.4734C41.8241 69.315 41.4942 69.4025 41.3504 69.6608L39.8047 72.4937C39.6607 72.7571 39.7433 73.0812 39.9904 73.2546C41.7392 74.5001 42.3173 76.8804 41.2645 78.8138C40.2117 80.7473 37.889 81.5526 35.8963 80.7595C35.6158 80.6507 35.3004 80.7541 35.1564 81.0176L33.6105 83.8556Z"
          fill="#1D232A"
        />
        <path
          d="M35.5415 70.9812C35.4572 70.6978 35.1588 70.5323 34.8749 70.6167L30.5136 72.1378C30.2653 72.2237 30.049 72.3924 29.9108 72.6408C29.7776 72.8894 29.7503 73.1629 29.8104 73.4199L30.8974 77.9026C30.9817 78.1861 31.2801 78.3516 31.564 78.2671C31.7086 78.2225 31.8196 78.1306 31.8862 78.0063L35.493 71.3911C35.5644 71.2721 35.581 71.1253 35.5415 70.9812Z"
          fill="#1D232A"
        />
      </g>
      <defs>
        <clipPath id="clip0_1854_48226">
          <rect width="482.826" height="236" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
