export function TeamListSittingCustomerIllustration() {
  return (
    <svg
      width="201"
      height="170"
      viewBox="0 0 201 170"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="figure"
      aria-label="sitting-customer"
    >
      <path
        d="M182.775 128.622C178.41 132.927 174.132 137.619 169.619 141.715C168.044 143.145 163.518 146.819 158.92 141.41L157.228 139.392L154.379 135.993C154.379 135.993 150.883 131.259 157.546 125.704C159.068 124.436 175.386 110.947 178.967 109.022C180.672 108.105 182.209 108.338 183.524 109.109C183.628 109.168 183.73 109.231 183.83 109.299C185.287 110.269 188.648 115.787 189.018 117.227C189.39 118.663 189.795 121.172 186.506 124.682C185.272 125.996 184.059 127.358 182.775 128.622Z"
        fill="#D57228"
      />
      <path
        d="M83.0521 31.2137C83.9788 29.6633 85.281 27.5307 83.1039 24.9472C80.5033 21.8573 74.0072 22.7591 72.6625 23.0715C69.7197 23.7571 68.8874 25.1338 68.0365 26.2186C60.9745 24.2911 60.5231 29.8684 52.3125 32.0028C47.2277 33.3241 44.4033 37.3583 47.1963 41.9099C47.1963 41.9099 32.7707 47.0695 42.9938 58.5844C48.6002 64.8971 78.4964 64.1598 80.2073 64.2448C81.6482 64.3169 79.375 58.832 81.1396 54.4504C83.3092 49.0709 88.4846 46.5281 88.4828 40.2855C88.4772 33.6734 83.8012 31.2636 83.0521 31.2137Z"
        fill="#8F5049"
      />
      <path
        d="M53.4685 39.9289C53.2447 42.7212 55.3293 45.1679 58.1241 45.3916C60.919 45.6152 63.3679 43.5325 63.5917 40.7401C63.7822 38.371 62.3118 36.2551 60.155 35.5269H60.1532C59.7666 35.3957 59.3578 35.3126 58.9343 35.2793C58.8584 35.2738 58.7863 35.2682 58.7123 35.2664C56.0136 35.1666 53.6867 37.2123 53.4685 39.9289Z"
        fill="#E09661"
      />
      <path
        d="M58.4867 40.8898C57.9225 47.9104 63.1645 54.0586 70.1914 54.6223C77.2183 55.1859 83.3721 49.9487 83.9363 42.9282C84.0916 40.9896 83.8049 39.1194 83.1594 37.4137C81.4706 32.9397 77.3181 29.6041 72.2315 29.1957C71.1328 29.107 70.0563 29.1606 69.0187 29.3417C65.7244 29.9127 62.8241 31.7589 60.9005 34.3867C59.5539 36.2273 58.6827 38.4505 58.4867 40.8898Z"
        fill="#E09661"
      />
      <path
        d="M57.5082 42.7101C57.4324 42.7101 57.3565 42.6787 57.3029 42.6177C57.2012 42.5031 57.2085 42.3294 57.3232 42.2259C57.8633 41.7399 58.187 41.0136 58.1889 40.2874C58.1907 39.5611 57.8689 38.8348 57.3306 38.3451C57.2178 38.2416 57.2085 38.0661 57.3121 37.9534C57.4157 37.8406 57.5914 37.8314 57.7043 37.9349C58.3572 38.5281 58.7475 39.4077 58.7456 40.2892C58.7438 41.1707 58.3516 42.0485 57.6969 42.6399C57.6414 42.6861 57.5748 42.7101 57.5082 42.7101Z"
        fill="#1D232A"
      />
      <path
        d="M72.2098 33.7211C73.1238 33.3469 73.7109 32.6685 73.5212 32.2057C73.3314 31.743 72.4366 31.6712 71.5226 32.0453C70.6085 32.4195 70.0214 33.0979 70.2111 33.5607C70.4009 34.0234 71.2957 34.0952 72.2098 33.7211Z"
        fill="#8F5049"
      />
      <path
        d="M78.9593 34.9816C79.1851 34.5353 78.6534 33.8126 77.7717 33.3675C76.8901 32.9224 75.9924 32.9234 75.7666 33.3697C75.5409 33.8161 76.0726 34.5387 76.9542 34.9838C77.8358 35.4289 78.7336 35.4279 78.9593 34.9816Z"
        fill="#8F5049"
      />
      <path
        d="M75.4352 38.9125C75.4907 38.4597 75.7256 38.0716 76.0604 37.8111C76.3933 37.5505 76.828 37.4174 77.2811 37.4747L81.232 37.9626C82.1384 38.0753 82.7839 38.8995 82.6711 39.8069C82.6156 40.2597 82.3807 40.6477 82.0459 40.9083C81.7111 41.1689 81.2783 41.3019 80.8251 41.2446L76.8742 40.7568C75.9679 40.644 75.3242 39.818 75.4352 38.9125Z"
        fill="white"
      />
      <path
        d="M77.7288 40.8621L79.2566 37.7187L81.232 37.9626C82.1384 38.0753 82.7839 38.8995 82.6711 39.8069C82.6156 40.2597 82.3807 40.6477 82.0459 40.9083C81.7111 41.1689 81.2783 41.3019 80.8251 41.2446L77.7288 40.8621Z"
        fill="#1D232A"
      />
      <path
        d="M66.6714 38.0421C66.7269 37.5893 66.9618 37.2012 67.2966 36.9406C67.6296 36.6801 68.0642 36.547 68.5174 36.6043L72.4683 37.0922C73.3746 37.2049 74.0202 38.0291 73.9073 38.9365C73.8518 39.3892 73.6169 39.7773 73.2821 40.0379C72.9474 40.2985 72.5145 40.4315 72.0614 40.3742L68.1105 39.8864C67.2023 39.7736 66.5586 38.9476 66.6714 38.0421Z"
        fill="white"
      />
      <path
        d="M68.9632 39.9917L70.491 36.8483L72.4665 37.0922C73.3728 37.2049 74.0183 38.0291 73.9055 38.9365C73.85 39.3893 73.6151 39.7773 73.2803 40.0379C72.9455 40.2985 72.5127 40.4315 72.0595 40.3742L68.9632 39.9917Z"
        fill="#1D232A"
      />
      <path
        d="M71.0478 49.3093L71.9763 46.2361L71.9893 46.2398C71.8949 46.2084 69.6568 45.4673 69.0335 43.1388C68.8041 42.2832 67.9237 41.7732 67.0673 42.0023C66.2109 42.2315 65.7004 43.1111 65.9297 43.9667C67.021 48.0527 70.885 49.2594 71.0478 49.3093Z"
        fill="white"
      />
      <path
        d="M66.9212 46.1659C67.01 46.3063 69.0686 49.5144 73.0528 49.6826C73.1675 49.6881 73.2822 49.69 73.4006 49.69C73.4098 49.69 73.4191 49.69 73.4283 49.69C73.4635 49.69 73.5005 49.6881 73.5356 49.6844C73.6577 49.6771 73.7742 49.6549 73.8889 49.6198C73.9777 49.5939 74.0627 49.5588 74.1441 49.5163C74.2718 49.4516 74.392 49.3703 74.4974 49.2742C74.5215 49.2539 74.5437 49.2317 74.5659 49.2095C74.8544 48.9175 75.0283 48.5184 75.0264 48.0767C75.0227 47.3597 74.5474 46.7535 73.8944 46.5539C73.8667 46.5447 73.8371 46.5355 73.8075 46.5299C73.728 46.5096 73.6429 46.4948 73.5578 46.4893C73.5097 46.4837 73.4598 46.4819 73.4098 46.4819C70.9978 46.4948 69.692 44.5341 69.6365 44.4509L69.642 44.462L66.9212 46.1659Z"
        fill="white"
      />
      <path
        d="M73.395 48.6625C73.3876 48.6625 73.3802 48.6625 73.3728 48.6625C71.3992 48.5017 67.4039 47.0122 66.407 42.5327C66.3737 42.383 66.468 42.2352 66.6178 42.2019C66.7676 42.1686 66.9156 42.2629 66.9489 42.4126C67.8756 46.5761 71.586 47.9603 73.4191 48.1099C73.5726 48.1229 73.6854 48.2559 73.6725 48.4093C73.6595 48.5535 73.5374 48.6625 73.395 48.6625Z"
        fill="#1D232A"
      />
      <path
        d="M73.8056 46.5281C73.7945 46.8829 73.7742 47.2359 73.7501 47.5907C73.702 48.2911 73.6299 48.9896 73.5337 49.6845C73.6558 49.6771 73.7723 49.6549 73.887 49.6198C74.0109 48.6958 74.0942 47.7662 74.1386 46.833C74.1811 47.7256 74.183 48.6219 74.1423 49.5163C74.2699 49.4516 74.3901 49.3703 74.4956 49.2742C74.5048 49.0303 74.5104 48.7845 74.5141 48.5387C74.5344 48.7605 74.5529 48.9841 74.564 49.2095C74.8525 48.9175 75.0264 48.5184 75.0246 48.0767C75.0209 47.3597 74.5455 46.7535 73.8926 46.554C73.863 46.541 73.8334 46.5336 73.8056 46.5281Z"
        fill="#1D232A"
      />
      <path
        d="M73.5559 46.4874C73.4191 47.5555 73.2507 48.6218 73.0528 49.6826C73.1675 49.6881 73.2822 49.69 73.4006 49.69C73.53 48.9914 73.6466 48.2929 73.7502 47.5906C73.8001 47.2451 73.8482 46.8995 73.8926 46.5521C73.8648 46.5428 73.8352 46.5336 73.8056 46.5281C73.7243 46.5077 73.641 46.4929 73.5559 46.4874Z"
        fill="#1D232A"
      />
      <path
        d="M59.2376 35.1407C60.1939 35.8688 61.3278 36.3955 62.5263 36.5082C63.7249 36.6209 64.9846 36.2902 65.8835 35.4937C66.812 34.6695 67.2763 33.4553 67.6999 32.2893C68.0384 31.3579 68.1179 29.8998 69.581 29.2585C68.0513 28.7818 65.2472 29.168 63.7083 29.6134C62.826 29.8684 61.94 30.1419 61.1372 30.5854C60.3345 31.0289 59.6094 31.6646 59.208 32.4907C58.8067 33.3167 58.507 34.5845 59.2376 35.1407Z"
        fill="#8F5049"
      />
      <path
        d="M74.8267 44.51C74.823 44.51 74.8193 44.51 74.8156 44.51L72.9936 44.499C72.8401 44.4971 72.7162 44.3733 72.718 44.2199C72.718 44.0665 72.8438 43.9446 72.9955 43.9446H72.9973L74.8193 43.9556C74.8211 43.9556 74.8248 43.9556 74.8267 43.9556C75.2003 43.9556 75.5499 43.7912 75.7866 43.5029C76.0252 43.2127 76.1214 42.8358 76.0474 42.4662L75.3464 38.979C75.3168 38.8293 75.413 38.6833 75.5647 38.6519C75.7145 38.6205 75.8606 38.7184 75.8921 38.8681L76.5931 42.3553C76.7004 42.8875 76.5635 43.4345 76.2176 43.854C75.8717 44.2716 75.3649 44.51 74.8267 44.51Z"
        fill="#1D232A"
      />
      <path d="M83.128 155.222H82.5731V168.376H83.128V155.222Z" fill="#E8E9EA" />
      <path
        d="M136.968 169.956H99.9358C99.0628 169.956 98.3562 169.248 98.3562 168.377C98.3562 167.505 99.0646 166.799 99.9358 166.799H136.968C137.841 166.799 138.548 167.507 138.548 168.377C138.55 169.248 137.841 169.956 136.968 169.956Z"
        fill="#1D232A"
      />
      <path
        d="M109.536 136.562H102.836C101.963 136.562 101.257 135.855 101.257 134.984C101.257 134.112 101.965 133.406 102.836 133.406H109.536C110.409 133.406 111.115 134.114 111.115 134.984C111.115 135.856 110.409 136.562 109.536 136.562Z"
        fill="#1D232A"
      />
      <path d="M46.5767 74.102H36.3073L30.0813 65.5717H40.3507L46.5767 74.102Z" fill="#1D232A" />
      <path
        d="M53.8772 136.468C53.056 136.768 52.1478 136.344 51.8481 135.524L27.6434 69.1845C27.3437 68.364 26.824 65.4497 30.2329 65.4497H47.7345V68.6412H31.8717C31.8458 68.6412 31.8218 68.643 31.7977 68.6449C31.3242 68.6911 31.0098 69.1753 31.1762 69.6336L53.2631 130.168L54.4543 133.432L54.8224 134.441C55.122 135.261 54.6985 136.169 53.8772 136.468Z"
        fill="#00A769"
      />
      <path
        d="M95.3654 120.572C95.3654 120.707 95.3487 120.843 95.3117 120.98L91.4607 135.341L90.4045 139.281L82.4917 168.78C82.266 169.623 81.3985 170.124 80.5551 169.897C79.8485 169.708 79.3842 169.07 79.3842 168.374C79.3842 168.239 79.4009 168.1 79.4379 167.963L87.6023 137.52L88.1868 135.341L92.2579 120.163C92.4836 119.321 93.3511 118.822 94.1927 119.047C94.8992 119.236 95.3654 119.873 95.3654 120.572Z"
        fill="#00734D"
      />
      <path d="M91.4608 135.341L90.4046 139.281L87.6024 137.52L88.1869 135.341H91.4608Z" fill="#1D232A" />
      <path d="M58.7697 135.524L33.1554 65.4497H54.656V68.6412L88.3237 135.524H58.7697Z" fill="#FFC44F" />
      <path
        d="M187.166 117.395C186.878 118.241 186.432 119.195 185.849 120.219C183.859 123.728 180.276 128.075 176.022 131.841C172.047 135.359 167.489 138.375 163.098 139.737C155.198 142.188 153.658 136.313 149.357 132.107C146.988 129.79 138.39 120.38 138.39 120.38L137.841 119.792L137.303 119.217C137.303 119.217 135.821 139.425 138.993 143.439C140.125 144.873 141.744 146.577 143.732 148.22C143.779 148.258 143.825 148.297 143.873 148.336C146.113 150.19 149.82 153.213 154.177 154.983C155.648 155.58 157.192 156.035 158.781 156.253C163.113 156.85 164.11 157.607 164.254 160.281C164.436 163.68 163.77 167.078 160.361 167.104H136.019C125.496 167.104 114.981 167.104 114.981 167.104C114.981 167.104 108.117 167.317 110.866 152.947C113.614 138.579 104.943 121.119 104.943 121.119L102.405 80.7419L129.503 75.61C129.503 75.61 139.417 72.5202 148.088 86.0419C156.76 99.5656 164.162 110.764 164.162 110.764C164.197 111.226 165.142 111.073 166.542 110.557C169.378 109.515 174.078 106.989 176.843 105.1C177.707 104.511 178.384 103.982 178.754 103.581C180.611 101.572 184.244 103.833 183.726 106.867C184.597 107.259 189.43 110.739 187.166 117.395Z"
        fill="#E09661"
      />
      <path
        d="M117.8 113.121C117.696 113.121 117.597 113.065 117.545 112.967C117.469 112.825 117.521 112.646 117.661 112.57L139.944 100.382C140.085 100.305 140.262 100.358 140.338 100.501C140.414 100.643 140.362 100.822 140.222 100.898L117.939 113.085C117.894 113.11 117.848 113.121 117.8 113.121Z"
        fill="#1D232A"
      />
      <path
        d="M119.751 121.9C120.256 127.267 120.436 135.237 116.222 138.606C110.723 134.295 108.92 133.421 103.247 133.421L78.9347 133.53C72.7735 133.53 66.5234 131.83 64.3482 124.347C63.2736 120.644 62.8075 117.005 62.752 113.346C62.6891 109.147 63.1644 104.927 63.8784 100.562C63.9228 100.295 63.9801 100.035 64.0504 99.7818C65.2879 95.3429 70.5391 92.8573 74.8118 90.8245C86.4962 85.2658 99.3273 82.3237 112.264 81.9856C117.018 83.7504 120.48 88.5478 120.654 93.6113C120.798 97.7619 118.947 101.713 118.41 105.832C117.711 111.178 119.246 116.532 119.751 121.9Z"
        fill="#42EC9A"
      />
      <path
        d="M81.1876 104.317L62.752 113.346C62.6891 109.147 63.1644 104.927 63.8784 100.562C63.9228 100.295 63.9801 100.035 64.0504 99.7817L81.1876 104.317Z"
        fill="#1D232A"
      />
      <path
        d="M100.465 77.7554L85.5547 80.5367C85.5547 80.5367 80.4292 64.1265 80.3182 63.7532C79.8392 62.1344 79.5895 58.6675 79.5895 58.6675L84.9942 59.7523C86.0615 59.9389 87.0954 60.4767 87.8427 61.4321L100.465 77.7554Z"
        fill="#E09661"
      />
      <path
        d="M84.9276 66.2757C84.9276 66.3644 95.726 70.8532 95.726 70.8532L95.9646 74.1814C95.9646 74.1814 88.3791 76.6781 88.4827 76.558C88.5863 76.4379 84.9276 66.2757 84.9276 66.2757Z"
        fill="#1D232A"
      />
      <path
        d="M108.663 100.621L64.9254 99.4953L50.6404 51.9704L63.49 54.9512V54.9531L77.8916 58.2943L82.0126 59.1536L108.663 100.621Z"
        fill="#42EC9A"
      />
      <path
        d="M130.396 52.0776L124.696 73.9967L123.081 80.206C122.75 81.4755 121.451 82.2387 120.179 81.9098L103.441 77.5633C102.17 77.2325 101.406 75.9352 101.736 74.6638L104.519 63.9621L109.053 46.5355C109.384 45.2659 110.682 44.5027 111.955 44.8316L128.691 49.1781C129.965 49.5089 130.727 50.808 130.396 52.0776Z"
        fill="#1D232A"
      />
      <path
        d="M124.696 73.9967L123.081 80.206C122.75 81.4755 121.451 82.2388 120.179 81.9098L103.441 77.5633C102.17 77.2325 101.406 75.9353 101.736 74.6638L104.519 63.9621L124.696 73.9967Z"
        fill="#1D232A"
      />
      <path
        d="M130.54 51.5251L130.004 53.5856L129.621 55.0585L129.46 55.6812L129.153 56.8566L129.131 56.9416L128.855 58.0005L128.752 58.4015L128.691 58.6417L128.68 58.6861L128.556 59.1629L128.463 59.514L128.458 59.5343L128.302 60.1312L128.067 61.0386L128.058 61.07L127.87 61.7944L127.825 61.957L127.731 62.3229L127.501 63.2063L127.407 63.5685L127.159 64.5202L127.054 64.9268L126.852 65.7048L126.482 67.1277L126.233 68.085L126.103 68.5876L125.284 71.7348L123.079 80.2078C122.84 81.1281 122.093 81.7805 121.216 81.9468C120.881 82.0096 120.528 82.0022 120.175 81.9117L120.162 81.908L118.499 81.4774L116.675 81.0043L116.1 80.8546L107.632 78.6555C107.083 78.5132 106.626 78.1898 106.313 77.7648C106.306 77.7555 106.301 77.7463 106.293 77.7371C106.212 77.6243 106.141 77.5024 106.082 77.3748C105.849 76.8888 105.781 76.3196 105.927 75.756L106.195 74.7248L106.288 74.3682L107.56 69.4747L108.838 64.5664L109.371 62.517L110.786 57.0765L111.25 55.2913L111.517 54.2638L111.7 53.5597L112.073 52.1257L112.164 51.7746L112.514 50.4311L112.545 50.3147L112.562 50.25L112.652 49.9044L112.845 49.1652L112.956 48.7402L113.059 48.3373L113.333 47.2858L113.388 47.077C113.416 46.9716 113.451 46.8718 113.496 46.7757C113.499 46.7702 113.501 46.7628 113.505 46.7573C113.54 46.6796 113.583 46.6076 113.627 46.5392C113.812 46.2602 114.065 46.0421 114.361 45.9035C114.421 45.8721 114.482 45.848 114.544 45.8259C114.87 45.7113 115.234 45.691 115.593 45.7834L116.455 46.007L120.147 46.9679L121.292 47.2655L122.33 47.5334L123.397 47.8106L127.969 48.997L129.248 49.3297C129.573 49.4147 129.852 49.581 130.072 49.8046C130.509 50.2352 130.709 50.8838 130.54 51.5251Z"
        fill="#00734D"
      />
      <path
        d="M128.855 58.0005L128.752 58.3996L128.691 58.6399L128.68 58.6842L128.556 59.161L128.463 59.5121L112.564 50.2463L112.654 49.9007L112.847 49.1615L112.958 48.7365L128.855 58.0005Z"
        fill="white"
      />
      <path
        d="M107.458 61.1051L107.244 61.0478C106.878 60.9498 106.661 60.5765 106.757 60.2106L108.03 55.4336C108.128 55.0677 108.502 54.8515 108.868 54.9476L109.082 55.0048C109.449 55.1028 109.665 55.4761 109.569 55.842L108.296 60.619C108.198 60.985 107.823 61.2012 107.458 61.1051Z"
        fill="#00734D"
      />
      <path
        d="M109.238 53.9866C108.812 53.8739 108.561 53.4378 108.674 53.0127L109.332 50.5364C109.445 50.1114 109.882 49.86 110.307 49.9728C110.732 50.0855 110.984 50.5216 110.871 50.9467L110.213 53.423C110.1 53.848 109.663 54.1012 109.238 53.9866Z"
        fill="#00734D"
      />
      <path d="M107.099 57.4646L106.959 58.0012L109.216 58.5875L109.356 58.0509L107.099 57.4646Z" fill="#1D232A" />
      <path
        d="M128.067 61.0367L128.058 61.0681L126.689 66.3368L110.788 57.0728L111.252 55.2876L111.518 54.2601L111.702 53.556L112.075 52.122L112.166 51.7709L128.067 61.0367Z"
        fill="white"
      />
      <path
        d="M122.724 52.8944C122.679 53.1384 122.57 53.3731 122.393 53.5726C121.882 54.1492 121 54.201 120.425 53.6909C119.849 53.1809 119.798 52.2994 120.308 51.7247C120.593 51.4031 120.992 51.246 121.39 51.2571C121.706 51.2663 122.021 51.3828 122.276 51.6082C122.654 51.9427 122.807 52.4343 122.724 52.8944Z"
        fill="#1D232A"
      />
      <path
        d="M94.8308 95.0084C99.3829 90.8153 106.277 84.8222 111.261 83.4307C114.556 82.5122 114.702 82.6083 118.113 82.4383C127.05 81.9929 127.511 76.5654 127.008 75.0001C129.479 72.3409 127.786 69.1069 127.786 69.1069C128.5 68.3252 129.231 67.6303 129.695 66.6195C130.172 65.579 130.32 64.3058 129.878 63.2321C129.229 61.6521 127.797 61.3878 126.222 61.4709C124.646 61.5541 122.881 62.4855 121.42 63.1083C116.781 65.0838 110.116 65.3259 106.915 64.3538C107.026 61.1568 106.754 57.6696 105.182 54.8995C104.475 53.6539 103.326 52.4546 101.932 52.5581C100.807 52.6412 99.8009 53.3952 99.3829 54.5095C98.9648 55.6239 99.024 56.899 99.2108 58.1113C99.3958 59.3236 99.6566 63.3762 97.535 67.1702C95.641 70.5594 92.5409 71.67 86.3408 74.3496C73.1213 80.0618 67.4113 82.5621 63.9709 86.4706C59.1211 93.1918 54.9445 106.959 63.0331 108.9C70.8517 110.777 79.4564 108.94 94.8308 95.0084Z"
        fill="#E09661"
      />
      <path
        d="M115.678 73.446C115.442 73.446 115.207 73.4423 114.97 73.433C114.816 73.4275 114.698 73.2981 114.704 73.1466C114.709 72.9932 114.837 72.8749 114.99 72.8786C119.498 73.0413 124.007 71.5998 127.685 68.8204C127.807 68.728 127.98 68.7521 128.073 68.874C128.165 68.996 128.141 69.1697 128.019 69.2621C124.433 71.975 120.075 73.446 115.678 73.446Z"
        fill="#1D232A"
      />
      <path
        d="M117.543 79.938C117.06 79.938 116.577 79.9084 116.096 79.8493C115.945 79.8308 115.836 79.6922 115.856 79.5406C115.874 79.3891 116.013 79.2801 116.165 79.3004C120.19 79.7994 124.339 78.0715 126.993 74.7876C127.089 74.6693 127.265 74.6509 127.383 74.747C127.501 74.843 127.522 75.0168 127.424 75.1369C124.967 78.1731 121.281 79.938 117.543 79.938Z"
        fill="#1D232A"
      />
      <path
        d="M67.5538 109.515C65.508 109.419 55.8694 110.421 47.7087 91.7263C40.0843 74.2609 39.3038 63.3818 40.5745 58.747C41.9932 53.5763 46.3251 49.8212 54.2047 52.839C60.6194 55.2968 74.6491 91.4066 74.4364 91.7263C74.2218 92.0442 69.7049 105.682 67.5538 109.515Z"
        fill="#E09661"
      />
      <path
        d="M73.3006 65.9043C68.4878 63.9066 63.49 54.9531 63.49 54.9531L65.2546 55.3615L65.9501 55.5241L66.1609 55.5722L74.1219 57.4183L74.3864 57.4812L74.9616 57.6142L78.3724 58.4051C78.9403 59.5084 79.4619 60.887 79.6191 62.4245C80.0112 66.2462 78.1135 67.9002 73.3006 65.9043Z"
        fill="#E09661"
      />
      <path
        d="M114.539 82.9446C114.402 82.9446 114.284 82.8449 114.265 82.7044C114.245 82.5529 114.35 82.4124 114.502 82.3921C115.218 82.2942 115.934 82.2609 117.016 82.2128C117.338 82.198 117.696 82.1814 118.098 82.1611C122.313 81.9504 126.83 80.2373 126.865 76.0535C126.867 75.9019 126.991 75.7781 127.143 75.7781H127.145C127.298 75.78 127.42 75.9038 127.42 76.0572C127.394 79.0528 124.938 82.3736 118.126 82.7136C117.722 82.734 117.364 82.7506 117.04 82.7654C115.934 82.8153 115.266 82.8467 114.576 82.9409C114.565 82.9446 114.552 82.9446 114.539 82.9446Z"
        fill="#1D232A"
      />
      <path
        d="M137.303 119.494C137.299 119.494 137.294 119.494 137.288 119.494C137.135 119.487 137.016 119.358 137.025 119.204L137.255 114.555C137.458 109.914 135.498 107.48 134.31 106.444C133.315 105.575 132.431 105.283 132.226 105.305C132.096 105.363 131.941 105.313 131.87 105.187C131.796 105.054 131.845 104.884 131.978 104.81C132.335 104.612 133.149 104.965 133.735 105.329C134.375 105.725 135.586 106.641 136.524 108.351C137.48 110.092 137.911 112.187 137.806 114.58L137.577 119.232C137.571 119.38 137.449 119.494 137.303 119.494Z"
        fill="#1D232A"
      />
      <path
        d="M139.006 101.227L137.547 112.873C136.981 108.855 135.524 105.849 132.113 105.052L139.006 101.227Z"
        fill="#1D232A"
      />
      <path
        d="M142.275 104.514L138.259 120.241L137.71 119.653L139.249 101.127C139.26 100.974 139.397 100.863 139.548 100.874C139.7 100.885 139.813 101.02 139.802 101.173L138.474 117.157L141.738 104.376C141.775 104.228 141.925 104.138 142.075 104.176C142.223 104.215 142.314 104.365 142.275 104.514Z"
        fill="#1D232A"
      />
      <path
        d="M104.804 134.984C104.804 135.42 104.627 135.816 104.34 136.1C104.055 136.385 103.659 136.562 103.223 136.562H53.3963C52.5232 136.562 51.8148 135.856 51.8148 134.984C51.8148 134.548 51.9924 134.153 52.2791 133.868C52.5639 133.582 52.9598 133.404 53.3963 133.404H103.223C104.096 133.404 104.804 134.112 104.804 134.984Z"
        fill="#00A769"
      />
      <path d="M93.2679 133.406L93.9837 136.562L91.4607 136.564L90.6191 133.406H93.2679Z" fill="#1D232A" />
      <path d="M56.5648 133.404L55.4328 136.562H53.5055L54.5043 133.404H56.5648Z" fill="#1D232A" />
      <path
        d="M80.9509 169.956H43.9187C43.0456 169.956 42.3391 169.248 42.3391 168.377C42.3391 167.505 43.0475 166.799 43.9187 166.799H80.9509C81.8239 166.799 82.5305 167.507 82.5305 168.377C82.5305 169.248 81.8221 169.956 80.9509 169.956Z"
        fill="#00734D"
      />
      <path
        d="M58.2443 120.982L45.4242 168.782C45.1986 169.625 44.3311 170.124 43.4895 169.898C42.646 169.673 42.1466 168.806 42.3723 167.965L55.1923 120.165C55.418 119.322 56.2855 118.823 57.1271 119.049C57.9687 119.273 58.47 120.139 58.2443 120.982Z"
        fill="#00A769"
      />
      <path
        d="M85.5787 120.982L98.3988 168.782C98.6244 169.625 99.4919 170.124 100.334 169.898C101.177 169.673 101.676 168.806 101.451 167.965L88.6307 120.165C88.405 119.322 87.5375 118.823 86.6959 119.049C85.8543 119.273 85.3531 120.139 85.5787 120.982Z"
        fill="#00A769"
      />
      <path
        d="M29.1274 67.2622C29.3993 66.8146 29.4146 66.3273 29.1613 66.1737C28.9081 66.0201 28.4823 66.2583 28.2103 66.7059C27.9384 67.1535 27.9231 67.6408 28.1764 67.7944C28.4296 67.948 28.8554 67.7097 29.1274 67.2622Z"
        fill="white"
      />
      <path
        d="M61.988 89.0135C61.951 89.0135 61.914 89.0061 61.877 88.9913C61.7365 88.9303 61.6717 88.7677 61.7328 88.6272C61.8031 88.4628 63.5584 84.5727 70.9904 81.0394C71.1291 80.9728 71.2937 81.032 71.3603 81.1706C71.4269 81.3092 71.3677 81.4736 71.229 81.5402C63.9857 84.9867 62.2599 88.8102 62.2433 88.849C62.1989 88.9506 62.0953 89.0135 61.988 89.0135Z"
        fill="#1D232A"
      />
      <path
        d="M77.5123 166.796C79.3342 166.818 79.8133 166.629 80.3164 164.685L81.1506 167.402H77.6769L77.5123 166.796Z"
        fill="#00734D"
      />
      <path
        d="M162.902 170H111.587C110.446 170 109.521 169.076 109.521 167.936C109.521 166.796 110.446 165.872 111.587 165.872H162.904C164.045 165.872 164.97 166.796 164.97 167.936C164.968 169.076 164.044 170 162.902 170Z"
        fill="#D57228"
      />
      <path
        d="M152.376 165.872C152.424 166.176 152.481 166.485 152.546 166.796L136.019 167.104C135.758 159.335 139.519 152.671 143.732 148.22C143.779 148.258 143.825 148.297 143.873 148.336C146.113 150.189 149.82 153.213 154.177 154.983C153.058 157.027 152.381 159.206 152.189 161.532C152.074 162.926 152.133 164.371 152.376 165.872Z"
        fill="#D57228"
      />
      <path
        d="M152.376 165.872H142.976L152.189 161.533C152.074 162.926 152.134 164.371 152.376 165.872Z"
        fill="#1D232A"
      />
      <path
        d="M152.376 166.149H139.755C139.602 166.149 139.478 166.025 139.478 165.872C139.478 165.718 139.602 165.594 139.755 165.594H152.376C152.529 165.594 152.653 165.718 152.653 165.872C152.653 166.025 152.529 166.149 152.376 166.149Z"
        fill="#1D232A"
      />
      <path
        d="M104.867 121.915H50.7846C49.168 121.915 47.8584 120.607 47.8584 118.992C47.8584 117.376 49.168 116.068 50.7846 116.068H104.867C106.484 116.068 107.793 117.376 107.793 118.992C107.791 120.607 106.482 121.915 104.867 121.915Z"
        fill="#BBBDBF"
      />
      <path
        d="M99.479 121.915H50.7846C49.168 121.915 47.8584 120.607 47.8584 118.992C47.8584 117.376 49.168 116.068 50.7846 116.068H99.479C101.096 116.068 102.405 117.376 102.405 118.992C102.405 120.607 101.096 121.915 99.479 121.915Z"
        fill="white"
      />
      <path
        d="M51.3044 134.931C51.2803 134.931 51.2563 134.927 51.2322 134.921C51.0843 134.883 50.9973 134.729 51.0362 134.581L52.3809 129.564C52.4197 129.416 52.5732 129.329 52.7212 129.368C52.8692 129.407 52.9561 129.56 52.9173 129.708L51.5726 134.725C51.5393 134.849 51.4283 134.931 51.3044 134.931Z"
        fill="#1D232A"
      />
      <path
        d="M71.5379 56.8195C70.9589 57.8175 69.9213 58.5788 68.7079 58.8339C68.3934 58.9004 68.0679 58.9336 67.7387 58.9336C66.5752 58.9336 65.3563 58.5252 64.3353 57.7731C63.3161 57.021 62.456 55.9177 61.8308 54.5668L62.5263 54.7295C63.0904 55.8142 63.8211 56.7031 64.6664 57.3259C65.8483 58.2 67.3169 58.5604 68.5932 58.2924C69.5735 58.0854 70.4429 57.4756 70.9626 56.6865L71.5379 56.8195Z"
        fill="#1D232A"
      />
      <path
        d="M62.7372 54.7775C63.4067 56.3409 64.7034 57.5569 66.1831 57.9856C66.5826 58.102 66.9932 58.1575 67.4002 58.1575C68.6931 58.1575 69.9472 57.5938 70.6981 56.6236L62.7372 54.7775ZM68.3712 56.4887C68.6616 56.4961 69.3664 56.4887 69.3664 56.4887C67.6998 57.2575 66.4291 56.862 65.7725 56.5054C66.6547 56.6698 67.5426 56.6643 68.3712 56.4887ZM66.3366 57.4534C65.8002 57.2982 65.2897 57.0247 64.831 56.6606C65.0973 56.8472 65.3914 57.0117 65.717 57.1429C66.7417 57.555 67.8774 57.5735 69.113 57.2002C68.2825 57.6086 67.2651 57.7232 66.3366 57.4534Z"
        fill="#1D232A"
      />
      <path
        d="M186.269 121.915L180.678 129.254L176.022 131.841C175.628 121.982 173.077 114.277 166.542 110.557C169.378 109.515 174.078 106.989 176.843 105.1C181.499 109.454 184.32 114.569 185.849 120.219C186.003 120.779 186.142 121.344 186.269 121.915Z"
        fill="#D57228"
      />
      <path
        d="M178.626 130.671C178.56 130.671 178.493 130.647 178.441 130.599C178.327 130.497 178.317 130.322 178.421 130.207L186.064 121.729C186.167 121.614 186.341 121.607 186.456 121.708C186.571 121.81 186.58 121.985 186.476 122.1L178.834 130.579C178.778 130.64 178.702 130.671 178.626 130.671Z"
        fill="#1D232A"
      />
      <path
        d="M140.537 13.582L147.649 26.4189C147.674 26.4652 147.732 26.4831 147.78 26.46L176.285 12.5055C176.382 12.4582 176.346 12.313 176.239 12.3172L140.621 13.4336C140.546 13.4368 140.501 13.5168 140.537 13.582Z"
        fill="#E8E9EA"
      />
      <path d="M143.383 18.7203L146.299 23.9829L176.022 12.495L143.383 18.7203Z" fill="#BBBDBF" />
      <path
        d="M134.821 44.1195C134.666 44.1195 134.509 44.1026 134.354 44.0668C132.597 43.6701 131.82 42.1022 131.567 41.4466C131.227 40.5648 131.066 39.5325 131.077 38.4318C130.436 38.496 129.721 38.6402 128.929 38.8927C126.846 39.5567 125.086 41.0257 124.222 42.823C124.184 42.902 124.091 42.9346 124.012 42.8967C123.933 42.8588 123.9 42.7641 123.938 42.6862C124.84 40.8121 126.67 39.2821 128.833 38.5918C129.663 38.3266 130.413 38.1782 131.085 38.114C131.155 36.3283 131.661 34.3847 132.568 32.6105C134.275 29.2727 137.193 26.9134 140.785 25.9674C140.869 25.9453 140.956 25.9959 140.978 26.08C141 26.1642 140.949 26.2505 140.865 26.2726C137.36 27.1955 134.513 29.4968 132.848 32.7537C131.967 34.4773 131.471 36.362 131.399 38.0898C132.976 37.9983 134.103 38.3845 134.845 38.8064C136.058 39.4967 136.668 40.5164 136.821 41.1794C137.034 42.1001 136.834 42.9514 136.271 43.5165C135.883 43.9079 135.363 44.1195 134.821 44.1195ZM131.39 38.4066C131.378 39.4767 131.531 40.4785 131.861 41.333C132.095 41.9412 132.815 43.3955 134.423 43.7585C135.016 43.8932 135.625 43.7196 136.048 43.2945C136.532 42.8083 136.701 42.0633 136.514 41.2509C136.375 40.6501 135.814 39.7209 134.689 39.0811C133.983 38.6802 132.908 38.314 131.39 38.4066Z"
        fill="#1D232A"
      />
      <path
        d="M121.079 43.3839C121.056 43.3839 121.033 43.3786 121.01 43.3681C120.932 43.3302 120.899 43.2355 120.937 43.1577C122.421 40.0976 124.68 37.9478 127.468 36.9407C127.55 36.9113 127.641 36.9534 127.67 37.0354C127.7 37.1175 127.658 37.208 127.576 37.2375C124.866 38.2161 122.669 40.3112 121.221 43.2955C121.194 43.3513 121.137 43.3839 121.079 43.3839Z"
        fill="#1D232A"
      />
      <path
        d="M176.255 12.5181L133.013 26.3042C132.844 26.3579 132.694 26.179 132.777 26.0222L140.721 10.8881C140.755 10.8229 140.821 10.7819 140.894 10.7819L142.574 10.8902L141.852 8.63623C141.829 8.58151 141.832 8.51943 141.859 8.46681L146.261 0.21475C146.309 0.124253 146.417 0.0842661 146.512 0.123201L176.268 12.1435C176.442 12.214 176.432 12.4613 176.255 12.5181Z"
        fill="#E8E9EA"
      />
      <path d="M141.852 8.63617L176.1 12.3118L142.574 10.8902L141.852 8.63617Z" fill="#BBBDBF" />
      <path
        d="M154.672 11.4027L157.53 10.3188L176.281 12.1825C176.368 12.1877 176.385 12.2519 176.395 12.3235L154.672 11.4027Z"
        fill="#1D232A"
      />
      <path
        d="M142.905 15.1952C142.679 15.1952 142.458 15.1247 142.27 14.9858C141.934 14.7395 141.756 14.3218 141.792 13.8682C141.846 13.2011 142.438 12.677 142.98 12.5571C143.066 12.5381 143.15 12.5918 143.169 12.677C143.188 12.7623 143.134 12.8465 143.049 12.8654C142.628 12.9591 142.149 13.3789 142.107 13.8935C142.08 14.2365 142.21 14.5501 142.456 14.7322C142.673 14.8911 142.941 14.9247 143.193 14.8227C143.645 14.6406 143.767 14.3249 143.936 13.8882L143.952 13.8472C144.136 13.3705 144.328 12.9633 144.807 12.8002C145.145 12.6855 145.509 12.7444 145.781 12.9559C146.069 13.18 146.215 13.5378 146.183 13.9377C146.15 14.3533 145.876 14.7543 145.468 14.9858C145.392 15.0289 145.295 15.0026 145.253 14.9268C145.21 14.8511 145.236 14.7543 145.312 14.7122C145.562 14.5712 145.838 14.2871 145.869 13.9135C145.892 13.622 145.79 13.3631 145.588 13.2063C145.397 13.058 145.149 13.019 144.909 13.1001C144.587 13.2095 144.439 13.4652 144.246 13.9619L144.23 14.0019C144.056 14.4544 143.891 14.8816 143.311 15.1152C143.18 15.1689 143.041 15.1952 142.905 15.1952Z"
        fill="#1D232A"
      />
      <path
        d="M146.504 14.0966C146.503 14.0966 146.502 14.0966 146.501 14.0966L141.473 14.0061C141.386 14.004 141.317 13.9324 141.319 13.8451C141.321 13.7577 141.389 13.6851 141.48 13.6904L146.508 13.7809C146.595 13.783 146.664 13.8546 146.662 13.9419C146.66 14.0271 146.59 14.0966 146.504 14.0966Z"
        fill="#1D232A"
      />
      <path
        d="M143.368 16.6979C143.365 16.6979 143.363 16.6979 143.361 16.6979L140.838 16.5979C140.75 16.5948 140.683 16.5211 140.686 16.4338C140.689 16.3464 140.759 16.278 140.85 16.2822L143.374 16.3822C143.461 16.3854 143.529 16.459 143.525 16.5464C143.521 16.6305 143.452 16.6979 143.368 16.6979Z"
        fill="#1D232A"
      />
      <path
        d="M142.94 17.567C142.938 17.567 142.936 17.567 142.934 17.567L140.409 17.4671C140.322 17.4639 140.255 17.3902 140.258 17.3029C140.261 17.2156 140.329 17.1472 140.422 17.1514L142.947 17.2513C143.034 17.2545 143.101 17.3282 143.098 17.4155C143.095 17.5007 143.024 17.567 142.94 17.567Z"
        fill="#1D232A"
      />
      <path
        d="M142.514 18.4373C142.512 18.4373 142.51 18.4373 142.508 18.4373L139.984 18.3373C139.897 18.3341 139.83 18.2605 139.833 18.1731C139.836 18.0858 139.904 18.0174 139.997 18.0216L142.52 18.1216C142.608 18.1247 142.675 18.1984 142.672 18.2857C142.668 18.3699 142.598 18.4373 142.514 18.4373Z"
        fill="#1D232A"
      />
      <path
        d="M142.087 19.3064C142.085 19.3064 142.082 19.3064 142.08 19.3064L139.557 19.2064C139.47 19.2033 139.402 19.1296 139.405 19.0423C139.409 18.957 139.479 18.8907 139.563 18.8907C139.565 18.8907 139.567 18.8907 139.57 18.8907L142.093 18.9907C142.18 18.9939 142.248 19.0675 142.245 19.1549C142.241 19.2401 142.171 19.3064 142.087 19.3064Z"
        fill="#1D232A"
      />
      <path
        d="M141.66 20.1767C141.657 20.1767 141.655 20.1767 141.653 20.1767L139.13 20.0767C139.043 20.0736 138.975 19.9999 138.978 19.9126C138.981 19.8273 139.052 19.761 139.136 19.761C139.138 19.761 139.14 19.761 139.142 19.761L141.666 19.861C141.753 19.8642 141.821 19.9378 141.817 20.0252C141.814 20.1094 141.745 20.1767 141.66 20.1767Z"
        fill="#1D232A"
      />
      <path
        d="M141.233 21.0459C141.231 21.0459 141.229 21.0459 141.227 21.0459L138.704 20.9459C138.616 20.9428 138.549 20.8691 138.552 20.7818C138.555 20.6944 138.627 20.626 138.716 20.6303L141.24 20.7302C141.327 20.7334 141.394 20.807 141.391 20.8944C141.388 20.9796 141.318 21.0459 141.233 21.0459Z"
        fill="#1D232A"
      />
    </svg>
  )
}
