export interface MaskProps {
  boleto: (string | RegExp)[]
  convenio: (string | RegExp)[]
  zipcode: (string | RegExp)[]
  telephone: (string | RegExp)[]
  phone: (string | RegExp)[]
  phoneCountry: (string | RegExp)[]
  cpf: (string | RegExp)[]
  cnpj: (string | RegExp)[]
  date: (string | RegExp)[]
  randomKey: (string | RegExp)[]
  unmask(len: number): RegExp[]
  unmaskEmail(len: number): RegExp[]
}

function generateMask(regex: RegExp, len: number): RegExp[] {
  return Array(len).fill(regex)
}

export const masks: MaskProps = {
  boleto: [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ],
  convenio: [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/
  ],
  zipcode: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
  telephone: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  phone: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  phoneCountry: [
    '+',
    /\d/,
    /\d/,
    ' ',
    '(',
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ],
  cpf: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/],
  cnpj: [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/],
  date: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
  randomKey: [
    /[0-9A-Fa-f]/,
    /[0-9A-Fa-f]/,
    /[0-9A-Fa-f]/,
    /[0-9A-Fa-f]/,
    /[0-9A-Fa-f]/,
    /[0-9A-Fa-f]/,
    /[0-9A-Fa-f]/,
    /[0-9A-Fa-f]/,
    '-',
    /[0-9A-Fa-f]/,
    /[0-9A-Fa-f]/,
    /[0-9A-Fa-f]/,
    /[0-9A-Fa-f]/,
    '-',
    /[0-9A-Fa-f]/,
    /[0-9A-Fa-f]/,
    /[0-9A-Fa-f]/,
    /[0-9A-Fa-f]/,
    '-',
    /[0-9A-Fa-f]/,
    /[0-9A-Fa-f]/,
    /[0-9A-Fa-f]/,
    /[0-9A-Fa-f]/,
    '-',
    /[0-9A-Fa-f]/,
    /[0-9A-Fa-f]/,
    /[0-9A-Fa-f]/,
    /[0-9A-Fa-f]/,
    /[0-9A-Fa-f]/,
    /[0-9A-Fa-f]/,
    /[0-9A-Fa-f]/,
    /[0-9A-Fa-f]/,
    /[0-9A-Fa-f]/,
    /[0-9A-Fa-f]/,
    /[0-9A-Fa-f]/,
    /[0-9A-Fa-f]/
  ],
  unmask: (length: number) => generateMask(/[A-Za-z0-9]/, length),
  unmaskEmail: (length: number) => generateMask(/[A-Za-z0-9-_@\\.]/, length)
}
