export function IdAssessmentIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={316} height={312} viewBox="0 0 316 312" fill="none" {...props}>
      <g clipPath="url(#prefix__id_assessment)">
        <path
          d="M300.991 229.072c30.629-72.913 11.376-126.383-30.629-178.88C195.977-42.165 35.831 5.472 6.952 91.995-21.927 178.52 45.457 256.293 93.588 295.18c48.132 38.887 176.774 6.805 207.403-66.108z"
          fill="#F3FBF6"
        />
        <path
          d="M225.945 111.557a4.108 4.108 0 00-4.141-4.401l-118.663 1.262a4.11 4.11 0 00-4.064 4.018l-2.494 112.829a4.108 4.108 0 004.107 4.2h23.648c.804 0 1.393.757 1.195 1.536l-7.578 29.855c-.367 1.443 1.222 2.591 2.477 1.789l52.682-33.662c.198-.127.428-.194.663-.194h39.965a4.11 4.11 0 004.098-3.816l8.105-113.416z"
          fill="#14AA4B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M125.758 194.992c0-10.992 8.911-19.902 19.902-19.902h28.432c10.991 0 19.902 8.91 19.902 19.902v5.686a2.843 2.843 0 01-5.687 0v-5.686c0-7.851-6.364-14.216-14.215-14.216H145.66c-7.851 0-14.215 6.365-14.215 14.216v5.686a2.843 2.843 0 01-5.687 0v-5.686zM159.876 160.874c6.281 0 11.373-5.092 11.373-11.372 0-6.281-5.092-11.373-11.373-11.373s-11.372 5.092-11.372 11.373c0 6.28 5.091 11.372 11.372 11.372zm0 5.686c9.421 0 17.059-7.637 17.059-17.058 0-9.422-7.638-17.059-17.059-17.059-9.421 0-17.059 7.637-17.059 17.059 0 9.421 7.638 17.058 17.059 17.058z"
          fill="#fff"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M150.84 43.71a4.108 4.108 0 013.96 4.252l-.36 10.142a4.108 4.108 0 11-8.212-.291l.36-10.143a4.109 4.109 0 014.252-3.96zm69.613 16.233a4.109 4.109 0 011.751 5.54l-5.843 11.24a4.109 4.109 0 01-7.291-3.79l5.843-11.24a4.108 4.108 0 015.54-1.75zM86.794 62.995a4.108 4.108 0 015.654 1.34l6.402 10.383a4.108 4.108 0 11-6.995 4.312l-6.401-10.382a4.109 4.109 0 011.34-5.653zM273.856 99.18a4.11 4.11 0 01-1.345 5.653l-12.166 7.49a4.11 4.11 0 01-4.308-6.998l12.166-7.49a4.11 4.11 0 015.653 1.345zM46.86 113.743a4.108 4.108 0 015.456-1.999l16.61 7.699a4.109 4.109 0 01-3.456 7.455l-16.61-7.699a4.11 4.11 0 01-2-5.456z"
          fill="#14AA4B"
        />
      </g>
      <defs>
        <clipPath id="prefix__id_assessment">
          <path fill="#fff" d="M0 0h316v311.842H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
