export function TerminalIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={119} height={135} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M115.139 42.742C110.117 49.46 99.554 59.118 95.284 65.7l-7.307 12.767-.003.005-29.882 52.213c-2.203 3.388-6.678 4.903-10.003 3.378l-19.985-9.165 17.988-38.533 36.407-77.99 27.665 12.688c8.1 3.715 10.33 13.422 4.975 21.68z"
        fill="#BBBDBF"
      />
      <path
        d="M67.434 77.941l6.03 25.875-15.375 26.865c-2.202 3.388-6.677 4.903-10.002 3.378l-19.985-9.165 39.332-46.953z"
        fill="#4A4F55"
      />
      <path
        d="M46.382 124.068l50.262-109.21L66.837 1.191c-7.378-3.383-17.37.73-21.073 8.677l-44.172 96.16c-1.468 3.193-.368 6.54 2.592 7.898l31.648 14.512c3.7 1.7 8.712-.377 10.55-4.37z"
        fill="#E8E9EA"
      />
      <path
        d="M91.702 12.594l19.065 8.742c-7.378-3.382-17.37.73-21.073 8.678L87.4 19.229l4.303-6.635z"
        fill="#E8E9EA"
      />
      <path
        d="M43.177 120.357l7.732-17.198c.675-1.47.073-3.06-1.415-3.745l-29.47-13.512c-1.842-.845-4.25.032-5.085 1.85l-7.732 17.197c-.675 1.47-.073 3.06 1.415 3.745l29.47 13.515c1.842.843 4.25-.035 5.085-1.852z"
        fill="#1D232A"
      />
      <path
        d="M76.894 46.435a.342.342 0 01-.155-.035L41.167 30.087a.378.378 0 01-.185-.497.377.377 0 01.497-.186l35.573 16.313c.187.088.27.31.185.498a.38.38 0 01-.343.22zM38.942 122.34l-30.748-14.1c-1.39-.638-2-2.28-1.362-3.668l8.212-17.91c.638-1.39 2.28-2 3.668-1.362l30.747 14.1c1.39.637 2 2.28 1.363 3.667l-8.213 17.91a2.765 2.765 0 01-3.667 1.363zM50.814 96.45L20.07 82.35c-1.39-.637-2-2.28-1.362-3.667l19.55-42.63c.637-1.39 2.28-2 3.667-1.363l30.748 14.1c1.39.638 2 2.28 1.362 3.668l-19.55 42.63c-.637 1.39-2.28 2-3.67 1.362z"
        fill="#4A4F55"
      />
      <path
        d="M81.921 37.432l5.875-11.6c1.335-2.638-.02-5.743-3.127-7.17l-25.45-11.67c-3.547-1.628-7.925-.433-9.45 2.577"
        fill="#E8E9EA"
      />
      <path
        d="M81.922 37.808a.374.374 0 01-.335-.545l5.875-11.6a4.238 4.238 0 00.222-3.39c-.487-1.393-1.615-2.555-3.172-3.27l-25.45-11.67c-3.348-1.535-7.533-.41-8.96 2.405a.376.376 0 01-.67-.34c1.627-3.215 6.18-4.473 9.94-2.748l25.452 11.673c1.748.8 3.015 2.117 3.57 3.705.46 1.32.368 2.73-.262 3.975l-5.875 11.6a.38.38 0 01-.335.205z"
        fill="#BBBDBF"
      />
      <path
        d="M37.24 102.118l3.602-7.855-.683-.313-3.602 7.855-11.245-5.155 3.602-7.857-.68-.313-3.602 7.858-12.36-5.667-.313.68 12.36 5.667-3.61 7.875-12.36-5.668-.312.683 12.357 5.667-3.835 8.365.68.313 3.835-8.365 11.248 5.155-3.838 8.367.68.313 7.763-16.923 12.407 5.69.313-.682-12.408-5.69zm-4.606 8.245l-11.245-5.158L25 97.331l11.245 5.157-3.61 7.875z"
        fill="#E8E9EA"
      />
      <path
        d="M74.692 102.05c-.18 0-.34-.13-.37-.315l-3.975-24.182a.374.374 0 11.74-.12l3.975 24.182a.374.374 0 01-.37.435z"
        fill="#4A4F55"
      />
    </svg>
  )
}
