export function TimIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M19.0303 4.22375H0.969692C0.700336 4.22375 0.457912 4.11601 0.282828 3.94092C0.107744 3.76584 0 3.52341 0 3.26752V1.61096C0 1.3416 0.107744 1.11265 0.282828 0.937561C0.47138 0.74901 0.700336 0.641266 0.969692 0.641266H19.0303C19.2997 0.641266 19.5421 0.74901 19.7172 0.924094C19.8923 1.09918 20 1.32813 20 1.59749V3.24059C20 3.50995 19.8923 3.7389 19.7172 3.91399C19.5421 4.10254 19.2997 4.22375 19.0303 4.22375ZM7.99999 7.20018H0.969692C0.700336 7.20018 0.457912 7.30792 0.296296 7.48301C0.121212 7.65808 0.013468 7.90051 0.013468 8.15641V9.79947C0.013468 10.0688 0.121212 10.3113 0.296296 10.4729C0.47138 10.648 0.713804 10.7557 0.983164 10.7557H8.01346V7.20018H7.99999ZM19.7172 7.49647C19.5421 7.32138 19.2997 7.21364 19.0303 7.21364H11.9999V10.7826H19.0303C19.2997 10.7826 19.5421 10.6749 19.7172 10.4998C19.8923 10.3247 20 10.0823 20 9.82647V8.16987C20 7.90051 19.8923 7.65808 19.7172 7.49647ZM19.7172 14.0554C19.5421 13.8803 19.2997 13.7725 19.0303 13.7725H11.9999V17.3415H19.0303C19.2997 17.3415 19.5421 17.2338 19.7172 17.0587C19.8923 16.8836 20 16.6412 20 16.3854V14.7422C20 14.4729 19.8923 14.2304 19.7172 14.0554ZM7.99999 13.7725H0.969692C0.700336 13.7725 0.457912 13.8803 0.296296 14.0554C0.121212 14.2304 0.013468 14.4729 0.013468 14.7288V16.3718C0.013468 16.6412 0.121212 16.8836 0.296296 17.0453C0.47138 17.2204 0.713804 17.3281 0.983164 17.3281H8.01346V13.7725H7.99999Z"
        fill="#EB0028"
      />
    </svg>
  )
}
