import { theme as gingaTheme } from '@dlpco/ginga-stone'
import jadeTheme from '@stone-payments/jade-design-tokens/cjs/stone'

import { toRem } from '~/lib/helpers'

import colors from './colors'
import fontSizes from './font-sizes'
import space from './spaces'

export const theme = {
  name: 'Default',
  iconAsset: gingaTheme.light.iconAsset,
  iconSize: gingaTheme.light.iconSize,
  colors,
  space,
  fontSizes,
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
    medium: 600,
    light: 300
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25
  },
  sizes: {
    avatar: 48,
    header: {
      height: toRem(80)
    },
    sidebar: { height: toRem(60) },
    maxContentWidth: 1440
  },
  radii: {
    default: toRem(7),
    medium: toRem(12),
    large: toRem(16),
    xLarge: toRem(24),
    circle: toRem(99999)
  },
  shadows: {
    tiny: '0 1px 1px rgba(0, 0, 0, .16)',
    small: '0 4px 8px rgba(0, 0, 0, .16)',
    medium: '0 8px 14px rgba(0, 0, 0, .16)',
    large: '0 24px 32px rgba(0, 0, 0, .16)',
    card: '0px 4px 4px rgba(46, 55, 66, 0.03)'
  },
  zIndices: {
    flat: 0,
    dropdown: 100,
    sticky: 500,
    loader: 225,
    sideModal: 250,
    fixed: 300,
    tooltip: 325,
    sideBar: 300,
    sideBarMobile: 900,
    menu: 350,
    overlay: 400,
    modal: 1100,
    popover: 600,
    wizard: 700,
    topMenu: 1000,
    toast: 2000,
    alert: 800
  },
  // not using breakpoints cause theme ui uses arrays for it
  windowSizes: {
    large: 1200,
    medium: 1024,
    small: 768,
    isTablet: 600,
    isSmallDesktop: 900,
    isDesktop: 1280,
    isWideScreen: 1440
  },
  // variants
  text: {
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading'
    },
    display: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      fontSize: [5, 6, 7]
    },
    caps: {
      textTransform: 'uppercase',
      letterSpacing: '0.1em'
    }
  },
  variants: {
    avatar: {
      width: 'avatar',
      height: 'avatar',
      borderRadius: 'circle'
    },
    card: {
      bg: 'white',
      boxShadow: 'unset',
      borderRadius: 'default',
      p: 3
    },
    link: {
      color: 'primary'
    },
    nav: {
      fontSize: 1,
      fontWeight: 'bold',
      display: 'inline-block',
      p: 2,
      color: 'inherit',
      textDecoration: 'none',
      ':hover,:focus,.active': {
        color: 'primary'
      }
    }
  },
  styles: {
    root: {
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body'
    }
  },
  jade: jadeTheme
}
