export function OnboardingMoneyYieldsIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="54" height="43" viewBox="0 0 54 43" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_1681_43910)">
        <path
          d="M44.156 24.3224C44.155 25.4456 44.0507 26.5444 43.8576 27.6072C43.7865 28.0157 43.6948 28.4203 43.5954 28.8211C43.4676 29.3378 43.3126 29.8468 43.1361 30.3479C43.0094 30.7233 42.8651 31.0928 42.7052 31.4584C42.6174 31.6837 42.5151 31.9069 42.4098 32.1283C42.2421 32.4802 42.0705 32.8264 41.8774 33.1696C41.7624 33.3792 41.6483 33.5839 41.5215 33.7867C38.3079 39.1336 32.454 42.7109 25.7753 42.709L19.119 42.708L19.0527 21.6938L19.0029 6.70319L19.4358 6.71586C19.3305 6.95864 19.4553 7.31061 19.8005 7.31061C19.9877 7.31061 20.1739 7.30671 20.3601 7.30671C20.1681 7.54461 20.2714 8.00091 20.6663 7.98629L33.2487 7.49879C33.5148 7.61871 33.7781 7.74254 34.0404 7.87611C34.4937 8.10134 34.9364 8.34509 35.3595 8.60931C31.0998 8.81504 26.841 9.02271 22.5792 9.23136C22.0527 9.25671 22.0615 10.0777 22.5929 10.0533C27.228 9.82611 31.869 9.59991 36.5071 9.37176C36.5529 9.40491 36.6017 9.44099 36.6475 9.47511C40.2706 12.1271 42.8739 16.0798 43.7962 20.6457C44.0312 21.8303 44.157 23.0608 44.156 24.3224Z"
          fill="#1D232A"
        />
        <path
          d="M36.3268 30.7798C32.7398 40.2812 22.1406 45.0899 12.6519 41.5224C10.1695 40.5893 8.01185 39.1755 6.23833 37.4274C4.6481 35.8576 3.36988 34.0207 2.45045 32.0181L6.83113 28.7001C6.60298 28.0576 6.42553 27.3966 6.29975 26.7228L1.727 30.187C1.59928 29.8029 1.48325 29.4138 1.38088 29.0219C1.40233 29.0121 1.42085 29.0004 1.44035 28.9858L6.01213 25.5207L6.07648 24.4823C6.07648 24.4823 5.64748 24.8128 5.53925 24.8957L1.181 28.1961C0.9821 27.2757 0.85535 26.3377 0.801725 25.389C0.6623 22.9311 1.01818 20.4 1.93175 17.943C1.94345 17.9147 1.95223 17.8864 1.96393 17.8581C4.5623 10.9756 10.8394 6.55594 17.7092 5.99629C18.1382 5.96119 18.5682 5.94169 19.0001 5.93877L25.7364 5.92999L25.8261 5.92902C27.3783 5.93097 28.8895 6.12402 30.3267 6.48867C30.9799 6.65442 31.6244 6.85527 32.2445 7.08732C32.5828 7.21504 32.9192 7.35349 33.2488 7.49877L27.1014 7.73667C27.8317 8.08474 28.5288 8.47669 29.1918 8.90862C31.2481 8.80819 33.3034 8.70874 35.3596 8.60929C35.3655 8.61027 35.3704 8.61514 35.3733 8.61709C35.7652 8.85402 36.1435 9.10459 36.5072 9.37174C34.4334 9.47412 32.3596 9.57552 30.2857 9.67692C32.3547 11.2496 34.0356 13.2298 35.2534 15.4548C37.7396 19.9924 38.3002 25.5519 36.3268 30.7798Z"
          fill="#42EC9A"
        />
        <path
          d="M10.4453 33.1545L10.4445 33.1537C9.16768 31.8956 8.19031 30.3806 7.56519 28.7235L7.56509 28.7233C7.41728 28.3321 7.28817 27.9341 7.18042 27.5301L25.4877 13.6648C25.8502 13.8782 26.199 14.1094 26.5331 14.3563C27.9836 15.4304 29.1575 16.798 30.0031 18.3407C30.0627 18.4501 30.1211 18.5606 30.1778 18.6718L10.7131 33.4108C10.6226 33.3272 10.5332 33.2415 10.4453 33.1545Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <path
          d="M2.43171 30.6759L2.43147 30.6753C2.39283 30.573 2.35503 30.47 2.31806 30.3664L5.97696 27.5945C6.05391 27.906 6.14175 28.2145 6.24059 28.5194L2.65416 31.2363C2.57662 31.0505 2.50229 30.8635 2.43171 30.6759Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <path
          d="M18.3538 32.8147C17.6762 32.8147 16.9761 32.6928 16.2858 32.4451C13.7216 31.5247 12.3722 28.823 12.5477 26.7433C12.5545 26.6624 12.6267 26.6039 12.7056 26.6098C12.7866 26.6166 12.846 26.6878 12.8392 26.7677C12.6725 28.7372 13.9536 31.2966 16.3853 32.1692C18.1354 32.7981 19.946 32.5778 21.2271 31.5794C22.2012 30.8208 22.6945 29.7122 22.5804 28.5383C22.3669 26.3416 21.0234 25.3861 19.322 24.1771L19.1689 24.0679C17.4695 22.858 16.0587 21.7055 15.9953 19.8511C15.9534 18.6382 16.5023 17.5237 17.5017 16.7954C18.6902 15.9286 20.3418 15.7502 21.9204 16.3167C23.8011 16.9924 24.8249 18.6635 25.1554 20.0022C25.1749 20.0802 25.1271 20.1601 25.0482 20.1796C24.9702 20.2001 24.8902 20.1514 24.8707 20.0724C24.5577 18.8068 23.5925 17.2283 21.8209 16.5926C20.3116 16.0505 18.7994 16.2104 17.6733 17.0323C16.7538 17.7031 16.2488 18.7269 16.2868 19.8413C16.3463 21.5651 17.646 22.624 19.3386 23.83L19.4916 23.9392C21.1833 25.1424 22.6448 26.1817 22.871 28.511C22.9948 29.7873 22.4615 30.9904 21.4065 31.8114C20.5544 32.4734 19.4858 32.8147 18.3538 32.8147Z"
          fill="#1D232A"
        />
        <path
          d="M15.5711 34.2645C15.5545 34.2645 15.538 34.2616 15.5224 34.2557C15.4463 34.2284 15.4063 34.1446 15.4336 34.0685L22.4098 14.5626C22.4371 14.4866 22.5209 14.4466 22.597 14.4739C22.673 14.5012 22.713 14.5851 22.6857 14.6611L15.7096 34.167C15.6871 34.2274 15.6316 34.2645 15.5711 34.2645Z"
          fill="#1D232A"
        />
        <mask id="path-7-inside-1_1681_43910" fill="white">
          <path d="M6.01228 25.5207L1.4405 28.9858C1.42198 29.0005 1.40248 29.0131 1.38103 29.0229C1.30595 28.7499 1.23965 28.4749 1.18115 28.1971L5.5394 24.8967C5.64763 24.8148 6.07663 24.4833 6.07663 24.4833L6.01228 25.5207Z" />
        </mask>
        <path
          d="M6.01228 25.5207L1.4405 28.9858C1.42198 29.0005 1.40248 29.0131 1.38103 29.0229C1.30595 28.7499 1.23965 28.4749 1.18115 28.1971L5.5394 24.8967C5.64763 24.8148 6.07663 24.4833 6.07663 24.4833L6.01228 25.5207Z"
          fill="#FCFCFC"
        />
        <path
          d="M6.01228 25.5207L6.61632 26.3176L6.98196 26.0405L7.01036 25.5826L6.01228 25.5207ZM1.4405 28.9858L0.836458 28.1889L0.828598 28.1948L0.820858 28.2009L1.4405 28.9858ZM1.38103 29.0229L0.416822 29.288L0.727652 30.4183L1.79483 29.9332L1.38103 29.0229ZM1.18115 28.1971L0.577449 27.3998L0.0719827 27.7826L0.202603 28.4031L1.18115 28.1971ZM5.5394 24.8967L4.93596 24.0993L4.9357 24.0995L5.5394 24.8967ZM6.07663 24.4833L7.07471 24.5452L7.21133 22.3427L5.46518 23.692L6.07663 24.4833ZM5.40823 24.7237L0.836458 28.1889L2.04455 29.7828L6.61632 26.3176L5.40823 24.7237ZM0.820858 28.2009C0.849471 28.1784 0.898611 28.1437 0.967224 28.1125L1.79483 29.9332C1.90634 29.8826 1.99448 29.8226 2.06015 29.7707L0.820858 28.2009ZM2.34523 28.7577C2.27578 28.5052 2.21421 28.25 2.1597 27.991L0.202603 28.4031C0.265094 28.6999 0.336129 28.9946 0.416822 29.288L2.34523 28.7577ZM1.78486 28.9943L6.14311 25.6939L4.9357 24.0995L0.577449 27.3998L1.78486 28.9943ZM6.14285 25.6941C6.2014 25.6498 6.33998 25.5431 6.45916 25.4512C6.5198 25.4045 6.577 25.3603 6.61903 25.3279C6.64006 25.3117 6.6573 25.2983 6.66929 25.2891C6.67529 25.2844 6.67997 25.2808 6.68317 25.2784C6.68476 25.2771 6.68598 25.2762 6.68681 25.2755C6.68722 25.2752 6.68754 25.275 6.68775 25.2748C6.68786 25.2747 6.68794 25.2747 6.68799 25.2746C6.68802 25.2746 6.68804 25.2746 6.68805 25.2746C6.68806 25.2746 6.68807 25.2746 6.68807 25.2746C6.68807 25.2746 6.68808 25.2746 6.07663 24.4833C5.46518 23.692 5.46518 23.692 5.46518 23.692C5.46517 23.692 5.46517 23.692 5.46516 23.692C5.46515 23.692 5.46513 23.692 5.46511 23.692C5.46506 23.6921 5.46499 23.6921 5.46488 23.6922C5.46468 23.6924 5.46438 23.6926 5.46398 23.6929C5.46317 23.6935 5.46197 23.6945 5.4604 23.6957C5.45726 23.6981 5.45262 23.7017 5.44668 23.7063C5.43478 23.7155 5.41766 23.7287 5.39678 23.7448C5.35499 23.7771 5.29818 23.8209 5.23804 23.8673C5.11566 23.9616 4.98563 24.0617 4.93596 24.0993L6.14285 25.6941ZM5.07855 24.4214L5.0142 25.4588L7.01036 25.5826L7.07471 24.5452L5.07855 24.4214Z"
          fill="#FCFCFC"
          mask="url(#path-7-inside-1_1681_43910)"
        />
        <path
          d="M10.1239 33.9271C10.0878 33.9271 10.0517 33.9135 10.0235 33.8872C8.53658 32.4724 7.3851 30.696 6.69383 28.7489C5.8095 26.2627 5.69055 23.599 6.34965 21.0464C6.46373 20.5999 6.61095 20.1367 6.78548 19.6697C7.32953 18.2325 8.10173 16.9212 9.0816 15.7716C9.13425 15.7102 9.2259 15.7024 9.28733 15.7551C9.34875 15.8077 9.35655 15.8994 9.3039 15.9608C8.34548 17.085 7.59083 18.3671 7.05848 19.773C6.88785 20.2294 6.74453 20.6827 6.6324 21.1195C5.9889 23.6165 6.10493 26.2207 6.96878 28.6514C7.64543 30.5556 8.77058 32.293 10.2253 33.6756C10.2838 33.7312 10.2857 33.8238 10.2302 33.8823C10.2009 33.9125 10.1629 33.9271 10.1239 33.9271Z"
          fill="#1D232A"
        />
        <path
          d="M46.1801 14.5509L44.3159 9.39122L39.1553 7.53092L44.315 5.66672L46.1753 0.506042L48.0395 5.66575L53.2001 7.52605L48.0404 9.39025L46.1801 14.5509Z"
          fill="#42EC9A"
        />
        <path
          d="M42.0411 21.9736L40.8409 18.6508L37.5181 17.4525L40.8409 16.2523L42.0391 12.9285L43.2394 16.2513L46.5622 17.4496L43.2394 18.6498L42.0411 21.9736Z"
          fill="#42EC9A"
        />
      </g>
      <defs>
        <clipPath id="clip0_1681_43910">
          <rect width="52.4277" height="42.2029" fill="white" transform="translate(0.772461 0.506042)" />
        </clipPath>
      </defs>
    </svg>
  )
}
