export function WarningIllustration({ width = 96, height = 81, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} fill="none" {...props}>
      <g clipPath="url(#prefix__clip0_4489_13385)">
        <path d="M17.265 76.098l-5.617-9.36 1.647-2.987 4.81 12.347 1.556 3.993-2.396-3.993z" fill="#1D232A" />
        <path
          d="M14.424 76.098l1.154 2.707-1.465-2.707-3.777-6.979.628-1.14 3.46 8.119z"
          fill="#1D232A"
          stroke="#00A868"
          strokeWidth={0.171}
          strokeMiterlimit={10}
        />
        <path
          d="M19.75 81.231h-2.6a.26.26 0 00-.021-.1l-2.145-5.034-3.703-8.69.084-.15 5.305 8.84 3.08 5.134zM21.241 76.097l2.732 5.134H20.66l-2-5.133-5.039-12.934.375-.682 7.245 13.615zM26.027 76.098l1.9 5.134h-3.375l-2.732-5.134-7.53-14.153 3.245-5.89.664-1.11 7.828 21.153zM88.586 81.231H28.475l-1.9-5.133-8.036-21.72L39.935 18.55v-.002l2.513-4.204.001-.002 3.267-5.47a7.584 7.584 0 015.74-3.692c2.819-.307 5.78.924 7.435 3.693l36.284 60.75c3.054 5.114-.63 11.608-6.589 11.608zM14.255 81.089a.256.256 0 01-.226-.136l-4.126-7.65 2.362 7.258a.256.256 0 01-.164.324.256.256 0 01-.324-.164l-3.24-9.962a.257.257 0 01.47-.2L13.02 78l-1.976-5.8a.255.255 0 01.16-.326.254.254 0 01.326.16l2.969 8.718a.256.256 0 01-.14.319.373.373 0 01-.105.017z"
          fill="#1D232A"
        />
        <path
          d="M80.29 76.098H7.726c-5.959 0-9.643-6.495-6.589-11.608L37.42 3.739c2.978-4.985 10.2-4.985 13.177 0l.861 1.44L86.88 64.49c3.055 5.114-.631 11.609-6.588 11.609z"
          fill="#F1BD38"
        />
        <path
          d="M43.967 57.145h-.01a4.108 4.108 0 01-4.098-4.115l.076-34.91a4.107 4.107 0 014.107-4.1h.008a4.108 4.108 0 014.099 4.116l-.075 34.91a4.107 4.107 0 01-4.107 4.1zM44.01 69.911a5.154 5.154 0 100-10.308 5.154 5.154 0 000 10.308z"
          fill="#1D232A"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_4489_13385">
          <path fill="#fff" transform="translate(.04)" d="M0 0h96.233v81.232H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
