/* eslint-disable jsx-a11y/anchor-is-valid */
import NextLink from 'next/link'
import { type ButtonProps as JadeButtonProps, Button as JadeButton } from '@stone-payments/jade'

import { experimentAnalytics } from '../analitica'
import { useComponentView } from '../hooks/use-component-view'
import { type AnalyticsEvent, type Navigation } from '../types'

type ButtonProps = {
  analytics?: AnalyticsEvent[]
  navigation?: Navigation
} & JadeButtonProps

export function Button({ analytics = [], navigation, children, onClick, ...rest }: ButtonProps) {
  useComponentView(analytics.find(event => event.type === 'VIEWED'))

  if (!navigation) {
    return (
      <JadeButton
        {...rest}
        style={{ cursor: 'pointer' }}
        onClick={e => {
          const clickEvent = analytics.find(event => event.type === 'CLICKED')
          if (clickEvent != null) {
            experimentAnalytics.events.experiment.click(clickEvent)
          }
          onClick?.(e)
        }}
      >
        {children}
      </JadeButton>
    )
  }

  return (
    <NextLink legacyBehavior passHref scroll={false} href={navigation.uri}>
      <a target={navigation.type === 'LINK_EXTERNAL' ? '_blank' : undefined} style={{ textDecoration: 'none' }}>
        <JadeButton
          {...rest}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            const clickEvent = analytics.find(event => event.type === 'CLICKED')
            if (clickEvent != null) {
              experimentAnalytics.events.experiment.click(clickEvent)
            }
          }}
        >
          {navigation.text}
        </JadeButton>
      </a>
    </NextLink>
  )
}
