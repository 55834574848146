export function CnpjAvatarIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="41" cy="41" r="41" fill="#F2F4F7" />
      <mask id="mask0_11914_43168" x="0" y="0" width="82" height="82">
        <circle cx="41" cy="41" r="41" fill="#ffffff" />
      </mask>
      <g mask="url(#mask0_11914_43168)">
        <g clipPath="url(#clip0_11914_43168)">
          <path d="M106.522 7.198H30.9719V82.1552H106.522V7.198Z" fill="#00A868" />
          <path d="M72.9502 82.1553L106.522 82.1553L106.522 7.1982L72.9502 7.1982L72.9502 82.1553Z" fill="#008E5A" />
          <path d="M36.9131 82.1558L66.1104 82.1558L66.1104 34.9581L36.9131 34.9581L36.9131 82.1558Z" fill="#E8E9EA" />
          <path d="M66.1095 35.3643H36.7778V82.1553H66.1095V35.3643Z" fill="#008E5A" />
          <path d="M66.1079 35.3643H44.9924V82.1553H66.1079V35.3643Z" fill="#00A868" />
          <path d="M66.1078 30.9531H48.4307V62.9897H66.1078V30.9531Z" fill="#42EC9A" />
          <path
            d="M66.0082 31.0543V45.4979L54.2446 62.8374H48.5319V48.0168L60.4478 31.0543H66.0082Z"
            fill="#FCFCFC"
            stroke="#FCFCFC"
            strokeWidth="0.202399"
          />
          <path
            d="M15.1245 23.4132V34.9575H66.1109V13.7683H24.6898C19.4058 13.7683 15.1245 18.0868 15.1245 23.4132Z"
            fill="#42EC9A"
          />
          <path d="M58.0972 22.3528H15.1245V35.3754H58.0972V22.3528Z" fill="#00A868" />
          <path
            d="M53.6008 23.4132V35.3769H66.1094V13.7683H63.1662C57.8821 13.7683 53.6008 18.0868 53.6008 23.4132Z"
            fill="#1D232A"
          />
          <path
            d="M40.8027 22.4103H47.227C47.7252 17.5559 51.7946 13.77 56.7417 13.77H50.3174C47.7584 13.77 45.4335 14.7827 43.7175 16.4332C42.117 17.9721 41.0447 20.0676 40.8027 22.4103Z"
            fill="#00A868"
          />
          <path
            d="M40.7522 23.4132V35.3769H47.1765V23.4132C47.1765 23.0736 47.1939 22.7387 47.2271 22.4102H40.8028C40.7696 22.7387 40.7522 23.0736 40.7522 23.4132Z"
            fill="#008E5A"
          />
          <path
            d="M27.9827 22.4103H34.407C34.9036 17.575 38.9397 13.8003 43.86 13.77H37.4958C34.9368 13.77 32.6119 14.7827 30.8959 16.4332C29.2954 17.9721 28.2231 20.0676 27.9827 22.4103Z"
            fill="#00A868"
          />
          <path
            d="M27.9321 23.4132V35.3769H34.3564V23.4132C34.3564 23.0736 34.3738 22.7387 34.4071 22.4102H27.9827C27.9479 22.7387 27.9321 23.0736 27.9321 23.4132Z"
            fill="#008E5A"
          />
          <path
            d="M15.1611 22.4103H21.5854C22.082 17.575 26.1198 13.7987 31.04 13.77H24.6758C19.7287 13.7684 15.6593 17.5543 15.1611 22.4103Z"
            fill="#00A868"
          />
          <path
            d="M15.1104 23.4132V35.3769H21.5347V23.4132C21.5347 23.0736 21.5521 22.7387 21.5853 22.4102H15.161C15.1451 22.5712 15.1325 22.7323 15.1246 22.895C15.1151 23.0656 15.1104 23.2394 15.1104 23.4132Z"
            fill="#008E5A"
          />
          <path
            d="M72.9497 41.5039V42.1274C74.7859 43.231 76.6237 44.333 78.463 45.4349C81.139 47.0424 83.815 48.6467 86.4895 50.2542C89.1765 51.8665 91.862 53.4788 94.5475 55.091C97.2235 56.6969 99.8995 58.3028 102.576 59.9087C103.89 60.6981 105.206 61.4875 106.522 62.2769V61.6534C104.983 60.7316 103.444 59.8067 101.905 58.8833C99.2273 57.2758 96.5529 55.6715 93.8769 54.064C91.1914 52.4518 88.5044 50.8395 85.8189 49.2272C83.1429 47.6213 80.4669 46.0154 77.7909 44.4095C76.1777 43.4431 74.5645 42.4735 72.9497 41.5039Z"
            fill="#1D232A"
          />
          <path
            d="M86.5573 51.7948C85.2162 50.932 83.8734 50.0693 82.5323 49.2065C83.7058 50.031 84.8841 50.8539 86.0576 51.6784C88.6118 53.4677 91.166 55.257 93.7202 57.0462C96.265 58.8307 98.8097 60.6137 101.356 62.3966C103.077 63.6022 104.801 64.8078 106.521 66.015V66.672C104.997 65.6036 103.472 64.5335 101.944 63.465C99.4012 61.6821 96.8565 59.8992 94.3102 58.1163C91.7559 56.327 89.2017 54.5361 86.6475 52.7468C86.5004 52.6432 86.3533 52.5411 86.2062 52.4375L106.521 67.1202V82.1553H72.9512V42.4082C74.6545 43.5038 76.3563 44.5994 78.0612 45.6934C80.6803 47.379 83.3009 49.063 85.92 50.7471C88.5517 52.4391 91.1803 54.1295 93.812 55.8199C96.431 57.5055 99.0517 59.1912 101.671 60.8752C103.289 61.915 104.905 62.9547 106.521 63.9929V64.6308C105.117 63.7282 103.713 62.824 102.308 61.9213C99.6875 60.2373 97.0684 58.5517 94.4462 56.8676C91.8176 55.1772 89.1875 53.4852 86.5573 51.7948Z"
            fill="#1D232A"
          />
          <path
            d="M65.6333 67.581V76.9644H48.9051V67.581H65.6333ZM66.1078 67.1025H48.4307V77.4428H66.1078V67.1025Z"
            fill="#008E5A"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_11914_43168">
          <rect width="91.4111" height="74.9572" fill="white" transform="translate(15.1104 7.198)" />
        </clipPath>
      </defs>
    </svg>
  )
}
