export const OpenFinanceRedirectIllustration = ({ height = 139, width = 120 }: any) => {
  return (
    <svg width={width} height={height} viewBox="0 0 120 139" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.1412 134.023C8.79132 127.467 -0.980431 99.7788 2.17333 87.8168C2.98159 84.7575 7.89531 83.4127 11.7679 89.1667C13.7185 92.0657 15.3486 94.7166 16.7086 97.0182C20.8765 77.6991 23.9644 74.3716 27.4607 73.9885C29.7877 73.7236 36.3365 75.3385 33.1439 86.5546C33.068 86.8094 32.987 87.071 32.9077 87.3393C32.9077 87.3544 32.8925 87.3781 32.8891 87.3933C32.8857 87.4084 32.9094 87.3814 32.9161 87.3814C33.5084 87.1117 34.1138 86.8718 34.7301 86.6626C43.265 83.6945 45.9513 89.4266 44.3668 93.7497C43.7779 95.3544 42.4719 96.9558 41.1152 98.3057C39.8565 99.5425 38.5067 100.683 37.0773 101.718C37.2848 101.743 37.4924 101.77 37.6965 101.81C45.5379 103.204 51.3848 109.216 52.107 116.811C53.062 126.863 47.4278 134.88 39.3907 137.389C31.5122 139.846 24.4707 138.459 18.1412 134.023Z"
        fill="#FFCBD9"
      />
      <path
        d="M29.1804 90.8998L28.9678 90.4054L29.2614 90.2164C29.2361 90.4374 29.2091 90.6669 29.1804 90.8998Z"
        fill="#1D232A"
      />
      <path
        d="M34.7304 102.195L34.4402 103.208L29.1924 90.9335C29.6716 90.6061 30.139 90.3209 30.5896 90.0746L37.5771 98.7799C36.7157 99.9885 35.7641 101.13 34.7304 102.195Z"
        fill="#1D232A"
      />
      <path
        d="M13.3736 63.8353L1.61631 69.5494C0.723647 69.9833 0.351695 71.0586 0.785533 71.9513L23.1508 117.97C23.5846 118.862 24.6599 119.234 25.5526 118.801L37.3099 113.086C38.2026 112.653 38.5745 111.577 38.1407 110.685L15.7755 64.6661C15.3416 63.7734 14.2663 63.4015 13.3736 63.8353Z"
        fill="#42EC9A"
      />
      <path
        d="M37.3091 113.086L25.5563 118.798C25.1276 119.006 24.6338 119.035 24.1835 118.879C23.7332 118.723 23.3633 118.394 23.1552 117.966L8.04785 86.8854L27.6909 89.1684L38.1528 110.684C38.2557 110.897 38.3154 111.129 38.3286 111.365C38.3419 111.601 38.3083 111.837 38.2299 112.06C38.1515 112.284 38.0298 112.489 37.8718 112.665C37.7138 112.841 37.5226 112.984 37.3091 113.086Z"
        fill="#1D232A"
      />
      <path
        d="M36.6738 52.5135L7.87625 66.5093C6.83355 67.016 6.39909 68.2721 6.90584 69.3148L29.007 114.79C29.5138 115.833 30.7698 116.267 31.8125 115.76L60.6101 101.765C61.6528 101.258 62.0873 100.002 61.5805 98.959L39.4794 53.4839C38.9726 52.4412 37.7165 52.0067 36.6738 52.5135Z"
        fill="#00A868"
      />
      <path d="M37.2677 59.0443L12.0381 71.306L30.6121 109.524L55.8417 97.262L37.2677 59.0443Z" fill="#FCFCFC" />
      <path d="M34.9289 60.1832L12.0381 71.3083L30.6121 109.526L53.5029 98.4009L34.9289 60.1832Z" fill="#42EC9A" />
      <path
        d="M37.7583 91.7501C37.9265 92.0052 38.2809 92.0639 38.5231 91.8756C42.2233 89.0291 43.1291 83.7611 40.4918 79.8382C37.8545 75.9153 32.6401 74.7642 28.6138 77.1247C28.3476 77.2809 28.2702 77.6252 28.4385 77.8803L30.298 80.6493C30.4708 80.907 30.8074 80.9859 31.0846 80.8421C33.0626 79.833 35.522 80.4387 36.7922 82.3265C38.0623 84.2144 37.6908 86.7283 36.0111 88.177C35.7773 88.3823 35.7214 88.7207 35.8942 88.9785L37.7583 91.7501Z"
        fill="white"
      />
      <path
        d="M26.8763 83.8326C26.5761 83.7747 26.2817 83.9693 26.2234 84.2699L25.5342 88.9945C25.4943 89.2632 25.5457 89.542 25.7075 89.7873C25.8718 90.028 26.1096 90.1836 26.374 90.2511L31.0049 91.3935C31.3052 91.4514 31.5996 91.2568 31.6578 90.9562C31.686 90.8023 31.6548 90.6567 31.5726 90.5363L27.2291 84.0727C27.1541 83.9504 27.0274 83.8651 26.8763 83.8326Z"
        fill="white"
      />
      <path
        d="M23.4139 63.7612C24.0411 63.7612 24.5496 63.2528 24.5496 62.6256C24.5496 61.9984 24.0411 61.49 23.4139 61.49C22.7868 61.49 22.2783 61.9984 22.2783 62.6256C22.2783 63.2528 22.7868 63.7612 23.4139 63.7612Z"
        fill="#1D232A"
      />
      <path
        d="M21.9532 129.832C19.9047 126.222 11.223 115.76 16.9298 107.308C19.8068 103.047 23.3638 99.8835 26.0418 98.3311C27.5199 97.5937 30.429 97.7793 31.9764 100.164C33.5051 102.673 33.3043 108.847 29.9211 112.883C35.5975 111.525 41.846 115.128 43.9569 119.191"
        fill="#FFCBD9"
      />
      <path
        d="M21.9535 129.83C21.9196 129.83 21.8861 129.823 21.8548 129.81C21.8235 129.797 21.7951 129.778 21.7713 129.754C20.4349 128.379 16.3632 122.647 15.0639 116.346C14.705 114.594 14.7093 112.786 15.0764 111.036C15.4436 109.285 16.1658 107.628 17.1984 106.168C17.7771 105.33 18.403 104.526 19.0731 103.76C19.1195 103.717 19.1802 103.692 19.2434 103.692C19.3066 103.692 19.3678 103.715 19.4147 103.757C19.4617 103.799 19.4911 103.858 19.4972 103.921C19.5033 103.984 19.4857 104.047 19.4477 104.097C18.791 104.849 18.178 105.637 17.6118 106.459C16.6179 107.863 15.923 109.456 15.57 111.14C15.217 112.823 15.2136 114.562 15.5599 116.246C16.8373 122.437 20.8263 128.058 22.1408 129.408C22.1759 129.444 22.1998 129.489 22.2093 129.537C22.2188 129.586 22.2135 129.637 22.1941 129.683C22.1748 129.729 22.1422 129.768 22.1006 129.795C22.0589 129.823 22.0101 129.837 21.9603 129.837L21.9535 129.83Z"
        fill="#1D232A"
      />
      <path
        d="M83.9189 89.1964C70.1527 80.0413 55.2398 76.153 48.1822 76.8783C45.701 77.1333 42.706 78.6501 42.9232 81.4531C43.1635 84.4995 45.4515 86.1574 47.3283 87.2707C52.0763 90.0904 59.7164 92.533 66.4049 96.9703"
        fill="#FFCBD9"
      />
      <path
        d="M68.244 114.238C72.3122 118.622 81.8801 126.823 96.3566 123.179C102.444 121.649 116.363 114.36 113.147 96.6219C111.916 89.8341 106.295 81.1976 97.4817 77.4215C93.1936 75.5899 88.5116 75.0669 84.696 78.5477C81.4524 77.1674 73.6301 77.4029 70.3263 82.032C69.2868 83.6773 68.2052 85.659 67.113 87.2688C65.4985 89.6459 64.0588 92.2083 63.4633 95.0219C63.0271 97.1447 62.8964 99.3188 63.075 101.478C63.4505 106.28 65.2478 111.016 68.244 114.238Z"
        fill="#FFCBD9"
      />
      <path
        d="M91.8543 82.1386C91.8313 82.1316 91.8095 82.1214 91.7894 82.1083C88.9128 80.2477 85.7202 78.9288 82.3692 78.2167C82.3036 78.2022 82.2463 78.1625 82.2096 78.1062C82.173 78.0499 82.1599 77.9814 82.1732 77.9156C82.1801 77.883 82.1935 77.8522 82.2123 77.8249C82.2312 77.7975 82.2553 77.7741 82.2832 77.7561C82.3112 77.738 82.3424 77.7257 82.3751 77.7197C82.4078 77.7137 82.4413 77.7142 82.4738 77.7212C85.8858 78.4459 89.1363 79.789 92.0647 81.684C92.1156 81.7173 92.1528 81.7677 92.1695 81.8261C92.1863 81.8846 92.1815 81.9471 92.1559 82.0022C92.1304 82.0574 92.0859 82.1015 92.0305 82.1266C91.9751 82.1516 91.9125 82.1559 91.8543 82.1386Z"
        fill="#1D232A"
      />
      <path
        d="M82.0709 88.1464C82.048 88.1394 82.0262 88.1292 82.0061 88.1161C79.1254 86.2552 73.4573 83.5792 70.336 82.139C70.3055 82.125 70.2781 82.1051 70.2553 82.0805C70.2325 82.0559 70.2147 82.0271 70.2031 81.9956C70.1914 81.9641 70.1861 81.9307 70.1874 81.8972C70.1886 81.8636 70.1965 81.8307 70.2105 81.8002C70.2245 81.7697 70.2444 81.7423 70.269 81.7195C70.2936 81.6967 70.3225 81.679 70.3539 81.6673C70.3854 81.6557 70.4188 81.6503 70.4524 81.6516C70.4859 81.6529 70.5188 81.6607 70.5493 81.6748C73.6834 83.1136 79.3755 85.8092 82.2828 87.687C82.3337 87.7202 82.3709 87.7706 82.3877 87.8291C82.4044 87.8875 82.3996 87.95 82.3741 88.0052C82.3485 88.0603 82.304 88.1045 82.2486 88.1295C82.1932 88.1545 82.1307 88.1588 82.0724 88.1415L82.0709 88.1464Z"
        fill="#1D232A"
      />
      <path
        d="M63.073 101.483C64.8262 99.7233 66.9753 99.087 69.4191 99.228C67.5146 97.7355 65.5141 96.3696 63.4307 95.1392C63.0047 97.2254 62.8842 99.3624 63.073 101.483Z"
        fill="#1D232A"
      />
      <path
        d="M63.2814 102.34C63.2405 102.328 63.2034 102.305 63.1736 102.275C63.1438 102.244 63.1223 102.206 63.111 102.165C63.0998 102.124 63.0992 102.08 63.1093 102.038C63.1194 101.997 63.1399 101.959 63.1689 101.927C65.6642 99.2162 69.1913 99.1291 69.3402 99.1267C69.3746 99.124 69.4092 99.1285 69.4418 99.1397C69.4744 99.1509 69.5044 99.1687 69.5299 99.1919C69.5554 99.2151 69.5759 99.2433 69.5901 99.2747C69.6043 99.3062 69.6119 99.3402 69.6125 99.3747C69.6131 99.4091 69.6067 99.4434 69.5936 99.4753C69.5804 99.5072 69.5609 99.5361 69.5363 99.5602C69.5116 99.5843 69.4822 99.6031 69.45 99.6154C69.4178 99.6277 69.3834 99.6334 69.349 99.6319C69.3148 99.6339 65.8839 99.7239 63.5391 102.276C63.5064 102.31 63.465 102.334 63.4195 102.345C63.3739 102.356 63.3261 102.355 63.2814 102.34Z"
        fill="#1D232A"
      />
      <path
        d="M43.9607 119.444C43.9144 119.444 43.8691 119.431 43.8295 119.407C43.79 119.383 43.7578 119.348 43.7363 119.307C42.4927 116.915 39.8148 114.738 36.7471 113.629C36.6885 113.604 36.6419 113.557 36.6165 113.498C36.5911 113.439 36.5889 113.373 36.6103 113.313C36.6316 113.253 36.675 113.203 36.7317 113.173C36.7884 113.143 36.8542 113.136 36.9158 113.153C40.0983 114.304 42.8825 116.572 44.1818 119.074C44.2115 119.134 44.2169 119.202 44.1967 119.265C44.1765 119.328 44.1324 119.381 44.0738 119.412C44.0391 119.431 44.0004 119.442 43.9607 119.444Z"
        fill="#1D232A"
      />
      <path
        d="M60.4142 0.585787C59.6332 -0.195263 58.3668 -0.195263 57.5858 0.585787L44.8579 13.3137C44.0768 14.0948 44.0768 15.3611 44.8579 16.1421C45.6389 16.9232 46.9052 16.9232 47.6863 16.1421L59 4.82843L70.3137 16.1421C71.0948 16.9232 72.3611 16.9232 73.1421 16.1421C73.9232 15.3611 73.9232 14.0948 73.1421 13.3137L60.4142 0.585787ZM61 26L61 2L57 2L57 26L61 26Z"
        fill="#00A868"
      />
      <line
        x1="59"
        y1="21"
        x2="59"
        y2="41"
        stroke="#00A868"
        strokeWidth="4"
        strokeLinecap="round"
        strokeDasharray="10 10"
      />
    </svg>
  )
}
