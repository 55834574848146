export function CoinSuccessIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg height="200" viewBox="0 0 150 151" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_10112_39209)">
        <path
          d="M107.992 80.5326C107.99 82.6926 107.79 84.8057 107.419 86.8494C107.282 87.6351 107.105 88.4132 106.914 89.1838C106.669 90.1776 106.37 91.1563 106.031 92.1201C105.787 92.8419 105.51 93.5526 105.202 94.2557C105.034 94.6888 104.837 95.1182 104.634 95.5438C104.312 96.2207 103.982 96.8863 103.61 97.5463C103.389 97.9494 103.17 98.3432 102.926 98.7332C96.7461 109.016 85.4886 115.895 72.6449 115.891L59.8442 115.889L59.7167 75.4775L59.6211 46.6494L60.4536 46.6738C60.2511 47.1407 60.4911 47.8175 61.1548 47.8175C61.5148 47.8175 61.873 47.81 62.2311 47.81C61.8617 48.2675 62.0605 49.145 62.8198 49.1169L87.0167 48.1794C87.5286 48.41 88.0349 48.6482 88.5392 48.905C89.4111 49.3382 90.2624 49.8069 91.0761 50.315C82.8842 50.7107 74.6942 51.11 66.4986 51.5113C65.4861 51.56 65.503 53.1388 66.5248 53.0919C75.4386 52.655 84.3636 52.22 93.283 51.7813C93.3711 51.845 93.4649 51.9144 93.553 51.98C100.52 57.08 105.527 64.6813 107.3 73.4619C107.752 75.74 107.994 78.1063 107.992 80.5326Z"
          fill="#1D232A"
        />
        <path
          d="M92.9363 92.9511C86.0382 111.223 65.655 120.471 47.4075 113.61C42.6338 111.815 38.4844 109.097 35.0738 105.735C32.0157 102.716 29.5575 99.1836 27.7894 95.3324L36.2138 88.9518C35.775 87.7161 35.4338 86.4449 35.1919 85.1493L26.3982 91.8111C26.1525 91.0724 25.9294 90.3243 25.7325 89.5705C25.7738 89.5518 25.8094 89.5293 25.8469 89.5011L34.6388 82.8374L34.7625 80.8405C34.7625 80.8405 33.9375 81.4761 33.7294 81.6355L25.3482 87.9824C24.9657 86.2124 24.7219 84.4086 24.6188 82.5843C24.3507 77.8574 25.035 72.9899 26.7919 68.2649C26.8144 68.2105 26.8313 68.1561 26.8538 68.1018C31.8507 54.8661 43.9219 46.3668 57.1332 45.2905C57.9582 45.223 58.785 45.1855 59.6157 45.1799L72.57 45.163L72.7425 45.1611C75.7275 45.1649 78.6338 45.5361 81.3975 46.2374C82.6538 46.5561 83.8932 46.9424 85.0857 47.3886C85.7363 47.6343 86.3832 47.9005 87.0169 48.1799L75.195 48.6374C76.5994 49.3068 77.9401 50.0605 79.2151 50.8911C83.1694 50.698 87.1219 50.5068 91.0763 50.3155C91.0876 50.3174 91.0969 50.3268 91.1026 50.3305C91.8563 50.7861 92.5838 51.268 93.2832 51.7818C89.2951 51.9786 85.3069 52.1736 81.3188 52.3686C85.2976 55.393 88.5301 59.2011 90.8719 63.4799C95.6532 72.2061 96.7313 82.8974 92.9363 92.9511Z"
          fill="#42EC9A"
        />
        <path
          d="M42.8386 97.8454L42.8378 97.8446C40.3343 95.3779 38.4182 92.4075 37.1928 89.1593L37.1927 89.1591C36.8667 88.2963 36.5868 87.4155 36.3606 86.5192L72.057 59.484C72.8647 59.9451 73.6385 60.4512 74.3756 60.9958C77.2204 63.1023 79.5223 65.7841 81.1797 68.8086C81.358 69.1356 81.5303 69.4674 81.6951 69.8024L43.652 98.6093C43.3747 98.3632 43.1031 98.1072 42.8386 97.8454Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <path
          d="M26.3984 91.8113L26.7004 92.2098L26.9883 91.9917C26.9492 91.8786 26.9105 91.7654 26.8724 91.6519L26.3984 91.8113ZM27.9895 94.5539L27.5797 94.8644C27.6487 95.0211 27.7187 95.1771 27.7897 95.3326L28.1982 95.0231C28.1276 94.8673 28.058 94.7109 27.9895 94.5539ZM35.741 89.1192L36.2122 88.9519L35.9103 88.5534L35.6208 88.7727C35.66 88.8885 35.7001 89.004 35.741 89.1192ZM34.7476 85.4862C34.7832 85.6674 34.8207 85.8481 34.8601 86.0283L35.3001 85.6949C35.2622 85.5136 35.2262 85.3317 35.1922 85.1494L34.7476 85.4862Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <path
          d="M58.3726 96.8641C57.0695 96.8641 55.7232 96.6297 54.3957 96.1535C49.4645 94.3835 46.8695 89.1879 47.207 85.1885C47.2201 85.0328 47.3589 84.9203 47.5107 84.9316C47.6664 84.9447 47.7807 85.0816 47.7676 85.2353C47.447 89.0229 49.9107 93.9447 54.587 95.6229C57.9526 96.8322 61.4345 96.4085 63.8982 94.4885C65.7714 93.0297 66.7201 90.8979 66.5007 88.6404C66.0901 84.416 63.5064 82.5785 60.2345 80.2535L59.9401 80.0435C56.672 77.7166 53.9589 75.5003 53.837 71.9341C53.7564 69.6016 54.812 67.4585 56.7339 66.0578C59.0195 64.391 62.1957 64.0478 65.2314 65.1372C68.8482 66.4366 70.817 69.6503 71.4526 72.2247C71.4901 72.3747 71.3982 72.5285 71.2464 72.566C71.0964 72.6053 70.9426 72.5116 70.9051 72.3597C70.3032 69.926 68.447 66.8903 65.0401 65.6678C62.1376 64.6253 59.2295 64.9328 57.0639 66.5135C55.2957 67.8035 54.3245 69.7722 54.3976 71.9153C54.512 75.2303 57.0114 77.2666 60.2664 79.586L60.5607 79.796C63.8139 82.1097 66.6245 84.1085 67.0595 88.5879C67.2976 91.0422 66.272 93.356 64.2432 94.9347C62.6045 96.2079 60.5495 96.8641 58.3726 96.8641Z"
          fill="#1D232A"
        />
        <path
          d="M53.0211 99.6524C52.9893 99.6524 52.9574 99.6468 52.9274 99.6356C52.7811 99.5831 52.7043 99.4218 52.7568 99.2756L66.1724 61.7643C66.2249 61.618 66.3861 61.5412 66.5324 61.5937C66.6786 61.6462 66.7555 61.8074 66.703 61.9537L53.2874 99.4649C53.2443 99.5812 53.1374 99.6524 53.0211 99.6524Z"
          fill="#1D232A"
        />
        <path
          d="M34.1534 82.5779L26.0308 88.7344C25.9866 88.5538 25.9443 88.3725 25.9038 88.1907L34.0306 82.0365C34.0685 82.0078 34.126 81.9639 34.1948 81.9111L34.1534 82.5779Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <path
          d="M42.5457 99.0038C42.4764 99.0038 42.407 98.9775 42.3526 98.9269C39.4932 96.2063 37.2789 92.79 35.9495 89.0457C34.2489 84.2644 34.0201 79.1419 35.2876 74.2331C35.507 73.3744 35.7901 72.4838 36.1257 71.5856C37.172 68.8219 38.657 66.3 40.5414 64.0894C40.6426 63.9713 40.8189 63.9562 40.937 64.0575C41.0551 64.1587 41.0701 64.335 40.9689 64.4531C39.1257 66.615 37.6745 69.0806 36.6507 71.7844C36.3226 72.6619 36.047 73.5338 35.8314 74.3738C34.5939 79.1756 34.817 84.1838 36.4782 88.8582C37.7795 92.52 39.9432 95.8613 42.7407 98.52C42.8532 98.6269 42.857 98.805 42.7501 98.9175C42.6939 98.9757 42.6207 99.0038 42.5457 99.0038Z"
          fill="#1D232A"
        />
        <path
          d="M111.884 61.7418L108.299 51.8193L98.375 48.2418L108.297 44.6568L111.875 34.7324L115.46 44.6549L125.384 48.2324L115.462 51.8174L111.884 61.7418Z"
          fill="#42EC9A"
        />
        <path
          d="M103.925 76.0164L101.617 69.6264L95.2266 67.3221L101.617 65.0139L103.921 58.6221L106.229 65.0121L112.619 67.3164L106.229 69.6246L103.925 76.0164Z"
          fill="#42EC9A"
        />
      </g>
      <g filter="url(#filter0_d_10112_39209)">
        <path
          d="M98.25 132.232C109.848 132.232 119.25 122.83 119.25 111.232C119.25 99.6344 109.848 90.2324 98.25 90.2324C86.652 90.2324 77.25 99.6344 77.25 111.232C77.25 122.83 86.652 132.232 98.25 132.232Z"
          fill="#E8E9EA"
        />
      </g>
      <path
        d="M94.7631 121.828C94.2288 121.828 93.6909 121.634 93.2677 121.243L86.2707 114.776C85.3767 113.949 85.322 112.556 86.1472 111.662C86.9743 110.768 88.3673 110.714 89.2614 111.539L94.6396 116.51L107.112 103.013C107.939 102.119 109.332 102.064 110.226 102.889C111.12 103.716 111.175 105.11 110.35 106.004L96.3819 121.119C95.9481 121.59 95.3573 121.828 94.7631 121.828Z"
        fill="#00A868"
      />
      <defs>
        <filter
          id="filter0_d_10112_39209"
          x="74.25"
          y="89.2324"
          width="48"
          height="48"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.290196 0 0 0 0 0.309804 0 0 0 0 0.333333 0 0 0 0.32 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10112_39209" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_10112_39209" result="shape" />
        </filter>
        <clipPath id="clip0_10112_39209">
          <rect width="100.823" height="81.1594" fill="white" transform="translate(24.5625 34.7324)" />
        </clipPath>
      </defs>
    </svg>
  )
}
