export function CoinRefresh(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="136" height="136" viewBox="0 0 136 136" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_701_36480)">
        <g clipPath="url(#clip1_701_36480)">
          <path
            d="M112.025 61.0675C112.022 63.9475 111.755 66.765 111.26 69.49C111.077 70.5375 110.842 71.575 110.587 72.6025C110.26 73.9275 109.862 75.2325 109.41 76.5175C109.085 77.48 108.715 78.4275 108.305 79.365C108.08 79.9425 107.817 80.515 107.547 81.0825C107.117 81.985 106.677 82.8725 106.182 83.7525C105.887 84.29 105.595 84.815 105.27 85.335C97.0296 99.045 82.0196 108.218 64.8946 108.213L47.827 108.21L47.657 54.3275L47.5295 15.89L48.6395 15.9225C48.3695 16.545 48.6895 17.4475 49.5745 17.4475C50.0545 17.4475 50.532 17.4375 51.0095 17.4375C50.517 18.0475 50.782 19.2175 51.7945 19.18L84.0571 17.93C84.7396 18.2375 85.4146 18.555 86.0871 18.8975C87.2496 19.475 88.3846 20.1 89.4696 20.7775C78.5471 21.305 67.6271 21.8375 56.6995 22.3725C55.3495 22.4375 55.372 24.5425 56.7345 24.48C68.6196 23.8975 80.5196 23.3175 92.4121 22.7325C92.5296 22.8175 92.6546 22.91 92.7721 22.9975C102.062 29.7975 108.737 39.9325 111.102 51.64C111.705 54.6775 112.027 57.8325 112.025 61.0675Z"
            fill="#1D232A"
          />
          <path
            d="M91.9497 77.625C82.7522 101.988 55.5747 114.318 31.2447 105.17C24.8797 102.778 19.3472 99.1525 14.7997 94.67C10.7222 90.645 7.44471 85.935 5.0872 80.8L16.3197 72.2925C15.7347 70.645 15.2797 68.95 14.9572 67.2225L3.2322 76.105C2.9047 75.12 2.6072 74.1225 2.3447 73.1175C2.3997 73.0925 2.4472 73.0625 2.4972 73.025L14.2197 64.14L14.3847 61.4775C14.3847 61.4775 13.2847 62.325 13.0072 62.5375L1.8322 71C1.3222 68.64 0.997204 66.235 0.859704 63.8025C0.502204 57.5 1.4147 51.01 3.7572 44.71C3.7872 44.6375 3.8097 44.565 3.8397 44.4925C10.5022 26.845 26.5972 15.5125 44.2122 14.0775C45.3122 13.9875 46.4147 13.9375 47.5222 13.93L64.7947 13.9075L65.0247 13.905C69.0047 13.91 72.8797 14.405 76.5647 15.34C78.2397 15.765 79.8922 16.28 81.4822 16.875C82.3497 17.2025 83.2122 17.5575 84.0572 17.93L68.2947 18.54C70.1672 19.4325 71.9547 20.4375 73.6547 21.545C78.9272 21.2875 84.1972 21.0325 89.4697 20.7775C89.4847 20.78 89.4972 20.7925 89.5047 20.7975C90.5097 21.405 91.4797 22.0475 92.4122 22.7325C87.0947 22.995 81.7772 23.255 76.4597 23.515C81.7647 27.5475 86.0747 32.625 89.1972 38.33C95.5722 49.965 97.0097 64.22 91.9497 77.625Z"
            fill="#42EC9A"
          />
          <path
            d="M25.0364 84.2696L25.0356 84.2688C21.6802 80.9627 19.1123 76.9817 17.47 72.6285L17.4699 72.6283C17.0201 71.438 16.6357 70.2217 16.3279 68.9831L64.0985 32.8035C65.2158 33.4361 66.285 34.1329 67.3021 34.8843C71.1151 37.7077 74.2001 41.3021 76.4212 45.3553C76.6821 45.8335 76.9333 46.3194 77.1723 46.8104L26.2301 85.3848C25.8221 85.0269 25.4234 84.6527 25.0364 84.2696Z"
            fill="#FCFCFC"
            stroke="#FCFCFC"
          />
          <path
            d="M3.23193 76.105C3.42443 76.6775 3.62693 77.2475 3.83943 77.81C4.15918 78.6599 4.50725 79.5027 4.87619 80.3324L5.28563 80.0223M3.23193 76.105L3.53386 76.5036L3.82149 76.2857M3.23193 76.105L14.5121 67.5595C14.5474 67.7412 14.584 67.9225 14.6222 68.1034M3.23193 76.105L3.70586 75.9457C3.74401 76.0592 3.78255 76.1725 3.82149 76.2857M3.82149 76.2857C3.97716 76.7383 4.13905 77.1883 4.30717 77.6333L4.30741 77.634C4.60972 78.4375 4.93792 79.2353 5.28563 80.0223M3.82149 76.2857L14.6222 68.1034M5.28563 80.0223C5.3548 80.1788 5.42475 80.3349 5.49542 80.4906L16.3169 72.2925M5.28563 80.0223L15.725 72.1137M15.725 72.1137C15.7646 72.2293 15.8049 72.3447 15.8458 72.4598L16.3169 72.2925M15.725 72.1137L16.015 71.894L16.3169 72.2925M15.725 72.1137C15.2758 70.8028 14.9086 69.4636 14.6222 68.1034M16.3169 72.2925C15.7938 70.8191 15.3765 69.3077 15.0635 67.7691L14.6222 68.1034"
            fill="#FCFCFC"
            stroke="#FCFCFC"
          />
          <path
            d="M45.865 82.8425C44.1275 82.8425 42.3325 82.53 40.5625 81.895C33.9875 79.535 30.5275 72.6075 30.9775 67.275C30.995 67.0675 31.18 66.9175 31.3825 66.9325C31.59 66.95 31.7425 67.1325 31.725 67.3375C31.2975 72.3875 34.5825 78.95 40.8175 81.1875C45.305 82.8001 49.9475 82.2351 53.2325 79.6751C55.73 77.73 56.995 74.8875 56.7025 71.8775C56.155 66.245 52.71 63.795 48.3475 60.695L47.955 60.415C43.5975 57.3125 39.98 54.3575 39.8175 49.6025C39.71 46.4925 41.1175 43.635 43.68 41.7675C46.7275 39.545 50.9625 39.0875 55.01 40.54C59.8325 42.2725 62.4575 46.5575 63.305 49.99C63.355 50.19 63.2325 50.395 63.03 50.445C62.83 50.4975 62.625 50.3725 62.575 50.17C61.7725 46.925 59.2975 42.8775 54.755 41.2475C50.885 39.8575 47.0075 40.2675 44.12 42.375C41.7625 44.095 40.4675 46.72 40.565 49.5775C40.7175 53.9975 44.05 56.7125 48.39 59.805L48.7825 60.085C53.12 63.17 56.8675 65.835 57.4475 71.8075C57.765 75.08 56.3975 78.165 53.6925 80.27C51.5075 81.9675 48.7675 82.8425 45.865 82.8425Z"
            fill="#1D232A"
          />
          <path
            d="M38.73 86.56C38.6875 86.56 38.645 86.5525 38.605 86.5375C38.41 86.4675 38.3075 86.2525 38.3775 86.0575L56.265 36.0425C56.335 35.8475 56.55 35.745 56.745 35.815C56.94 35.885 57.0425 36.1 56.9725 36.295L39.085 86.31C39.0275 86.465 38.885 86.56 38.73 86.56Z"
            fill="#1D232A"
          />
          <path
            d="M13.7349 63.8802L2.64239 72.2877C2.55207 71.9299 2.4674 71.5704 2.38805 71.2089L13.309 62.9388C13.4067 62.8649 13.6032 62.714 13.8175 62.5491L13.7349 63.8802Z"
            fill="#FCFCFC"
            stroke="#FCFCFC"
          />
          <path
            d="M24.7625 85.6951C24.67 85.6951 24.5775 85.6601 24.505 85.5926C20.6925 81.9651 17.74 77.4101 15.9675 72.4176C13.7 66.0426 13.395 59.2126 15.085 52.6676C15.3775 51.5226 15.755 50.3351 16.2025 49.1376C17.5975 45.4526 19.5775 42.0901 22.09 39.1426C22.225 38.9851 22.46 38.9651 22.6175 39.1001C22.775 39.2351 22.795 39.4701 22.66 39.6276C20.2025 42.5101 18.2675 45.7976 16.9025 49.4026C16.465 50.5726 16.0975 51.7351 15.81 52.8551C14.16 59.2576 14.4575 65.9351 16.6725 72.1676C18.4075 77.0501 21.2925 81.5051 25.0225 85.0501C25.1725 85.1926 25.1775 85.4301 25.035 85.5801C24.96 85.6576 24.8625 85.6951 24.7625 85.6951Z"
            fill="#1D232A"
          />
          <path
            d="M117.215 36.0125L112.435 22.7825L99.2024 18.0125L112.432 13.2325L117.202 0L121.982 13.23L135.215 18L121.985 22.78L117.215 36.0125Z"
            fill="#42EC9A"
          />
          <path
            d="M106.602 55.045L103.525 46.525L95.0046 43.4525L103.525 40.375L106.597 31.8525L109.675 40.3725L118.195 43.445L109.675 46.5225L106.602 55.045Z"
            fill="#42EC9A"
          />
        </g>
        <g filter="url(#filter0_d_701_36480)">
          <path
            d="M99.0349 130C114.499 130 127.035 117.464 127.035 102C127.035 86.536 114.499 74 99.0349 74C83.5709 74 71.0349 86.536 71.0349 102C71.0349 117.464 83.5709 130 99.0349 130Z"
            fill="#E8E9EA"
          />
        </g>
        <path
          d="M116.974 90.4065L115.27 98.3229C115.032 99.4256 114.056 100.18 112.973 100.18C112.81 100.18 112.648 100.164 112.483 100.129L104.56 98.4475C103.29 98.1771 102.479 96.931 102.747 95.659C103.018 94.3894 104.266 93.5782 105.536 93.8486L108.541 94.4858C105.531 90.6886 100.389 88.9135 95.4846 90.4183C92.5103 91.3305 90.0275 93.3243 88.4969 96.0281C87.855 97.159 86.4208 97.5564 85.2923 96.9169C84.1614 96.275 83.764 94.8408 84.4035 93.7122C86.5313 89.9551 89.9758 87.1901 94.1044 85.9228C100.779 83.8773 107.772 86.205 111.98 91.2647L112.378 89.4167C112.65 88.147 113.901 87.3382 115.171 87.6133C116.44 87.8861 117.249 89.1369 116.974 90.4065Z"
          fill="#00A868"
        />
        <path
          d="M113.668 110.29C111.543 114.048 108.096 116.812 103.97 118.08C102.331 118.583 100.676 118.82 99.0466 118.82C94.0316 118.82 89.2705 116.559 86.094 112.74L85.6967 114.586C85.4592 115.689 84.4835 116.443 83.3996 116.443C83.235 116.443 83.0681 116.427 82.9012 116.389C81.6339 116.117 80.8251 114.866 81.0978 113.596L82.8048 105.68C83.0775 104.412 84.3236 103.604 85.5909 103.874L93.5143 105.555C94.7839 105.825 95.5951 107.072 95.3247 108.344C95.0567 109.613 93.8082 110.424 92.5386 110.154L89.5314 109.517C92.5433 113.314 97.6853 115.089 102.59 113.584C105.564 112.672 108.044 110.678 109.577 107.974C110.217 106.844 111.651 106.446 112.782 107.088C113.913 107.728 114.31 109.162 113.668 110.29Z"
          fill="#00A868"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_701_36480"
          x="68.0349"
          y="73"
          width="62"
          height="62"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.290196 0 0 0 0 0.309804 0 0 0 0 0.333333 0 0 0 0.32 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_701_36480" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_701_36480" result="shape" />
        </filter>
        <clipPath id="clip0_701_36480">
          <rect width="134.43" height="130" fill="white" transform="translate(0.784912)" />
        </clipPath>
        <clipPath id="clip1_701_36480">
          <rect width="134.43" height="108.213" fill="white" transform="translate(0.784912)" />
        </clipPath>
      </defs>
    </svg>
  )
}
