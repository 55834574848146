export function CellphoneApprovedIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="10em" height="10em" viewBox="0 0 150 150" fill="none" {...props}>
      <path
        d="M35.501 49.575l3.14 12.853.644 2.625.341 1.399 12.08 49.417c1.148 4.686 5.873 7.555 10.559 6.407l34.38-8.405c4.686-1.142 7.554-5.869 6.407-10.555l-16.206-66.29a8.732 8.732 0 00-10.554-6.41l-17.987 4.398-16.393 4.007c-4.686 1.147-7.554 5.872-6.41 10.554z"
        fill="#1D232A"
      />
      <path
        d="M35.501 49.575l3.14 12.853 17.04-15.439 7.426-6.733c-.89-.611-2.687-2.65-4.8-5.24L41.912 39.02c-4.686 1.147-7.554 5.872-6.41 10.554zM39.285 65.051l.341 1.399 14.742-13.219c.255-.234.168-.701-.066-.905-.289-.24-.645-.167-.906.065-1.629 1.468-3.272 2.937-4.9 4.4l-9.21 8.26z"
        fill="#42EC9A"
      />
      <path
        d="M71.782 119.948l37.236-9.104a7.474 7.474 0 005.486-9.037l-16.802-68.73a7.476 7.476 0 00-9.037-5.486l-37.236 9.103a7.478 7.478 0 00-5.488 9.037l16.802 68.73a7.48 7.48 0 009.04 5.487z"
        fill="#00A868"
      />
      <path d="M67.463 108.147l39.942-9.765-14.44-59.06-39.942 9.765 14.44 59.06z" fill="#42EC9A" />
      <path d="M92.598 39.926L106.8 98.015l-4.272 1.044-14.202-58.088 4.272-1.045z" fill="#FCFCFC" stroke="#FCFCFC" />
      <path d="M71.492 39.877a1.824 1.824 0 100-3.648 1.824 1.824 0 000 3.648z" fill="#1D232A" />
      <g filter="url(#prefix__filter0_d_9949_29747)">
        <path d="M107.25 133.5c11.598 0 21-9.402 21-21s-9.402-21-21-21-21 9.402-21 21 9.402 21 21 21z" fill="#E8E9EA" />
      </g>
      <path
        d="M103.764 123.096a2.199 2.199 0 01-1.495-.585l-6.997-6.467a2.204 2.204 0 012.99-3.237l5.379 4.971 12.472-13.497a2.204 2.204 0 013.238 2.991l-13.968 15.115a2.193 2.193 0 01-1.619.709z"
        fill="#00A868"
      />
      <defs>
        <filter
          id="prefix__filter0_d_9949_29747"
          x={83.25}
          y={90.5}
          width={48}
          height={48}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={1.5} />
          <feColorMatrix values="0 0 0 0 0.290196 0 0 0 0 0.309804 0 0 0 0 0.333333 0 0 0 0.32 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_9949_29747" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_9949_29747" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
