export function KycAssessmentRedirectToMobileIllustration() {
  return (
    <svg width="315" height="269" viewBox="0 0 315 269" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1862_23343)">
        <path
          d="M216.326 268.85H75.5C75.9164 254.013 79.8141 241.215 88.0549 232.175C88.0741 232.153 88.0933 232.131 88.1189 232.108C88.3527 231.849 88.5961 231.59 88.8395 231.332C88.8684 231.303 88.9004 231.271 88.9324 231.242C89.163 231.002 89.3968 230.763 89.637 230.529C95.0657 225.227 102.179 221.401 111.179 219.467L138.739 213.173L139.264 213.055L141.951 212.438L144.11 211.946L145.657 211.594L150.599 210.466C194.432 201.561 215.695 234.742 216.326 268.85Z"
          fill="#008E5A"
        />
        <path
          d="M145.364 212.302C139.135 222.284 127.544 230.969 115.098 234.673C110.835 235.942 106.678 236.569 102.739 236.569C97.2106 236.569 92.1118 235.328 87.7656 232.883C87.7848 232.861 87.8041 232.839 87.8297 232.816C88.0635 232.557 88.3069 232.298 88.5503 232.039C88.5791 232.011 88.6112 231.979 88.6432 231.95C92.5762 233.459 99.6223 233.74 103.767 233.401C116.895 232.318 127.163 225.826 134.408 219.162C132.816 220.411 131.051 221.703 129.129 222.956C121.167 228.159 108.968 233.804 94.8117 232.353C94.7957 232.349 94.7797 232.349 94.7605 232.349C92.8901 232.196 90.6769 231.883 89.3478 231.237C94.7765 225.934 101.89 222.109 110.89 220.175L138.45 213.881C132.073 219.555 121.042 226.535 110.348 230.115C117.574 228.344 123.787 225.116 128.489 222.045C132.877 219.181 136.445 216.147 138.972 213.763L141.659 213.146C134.613 221.591 121.888 233.024 103.856 234.51C102.787 234.596 101.557 234.648 100.244 234.648C98.2451 234.648 96.0576 234.533 93.9822 234.274C100.125 236.044 107.277 235.84 114.784 233.606C126.596 230.09 137.607 222.019 143.821 212.654L145.364 212.302Z"
          fill="#1D232A"
        />
        <path
          d="M75.742 178.779C69.8708 173.03 61.7812 188.018 54.2621 184.744C54.4032 183.232 56.7555 158.034 56.7555 158.034L57.3196 151.987C57.8371 146.443 57.0786 142.832 58.6845 137.372C61.6042 127.445 66.4718 124.523 77.0565 122.032C84.2605 120.34 132.412 113.409 152.823 110.362C175.852 106.921 189.334 121.834 193.062 126.99C201.286 138.364 208.761 141.495 218.411 143.965C217.178 158.16 205.8 170.308 193.035 176.635C193.638 179.475 194.729 185.909 195.264 190.051C196.049 196.111 192.08 202.487 183.699 205.614C176.782 208.195 117.157 219.7 93.1411 224.2C84.7064 225.779 75.2703 220.752 72.9946 212.245C70.803 204.033 81.903 184.807 75.742 178.779Z"
          fill="#FE6A34"
        />
        <path
          d="M194.066 179.234C193.864 179.218 193.69 179.07 193.65 178.854L186.039 138.778C185.991 138.52 186.156 138.262 186.408 138.208C186.661 138.154 186.908 138.318 186.956 138.579L194.567 178.655C194.615 178.913 194.451 179.171 194.198 179.225C194.157 179.235 194.11 179.238 194.066 179.234Z"
          fill="#1D232A"
        />
        <path
          d="M276.406 195.963C268.961 220.02 249.698 221.173 244.749 221.389C224.343 222.28 205.072 214.558 189.249 209.123C187.695 208.592 185.786 207.665 184.145 206.169C181.982 204.202 180.286 201.248 180.488 196.9C181.246 180.319 186.302 165.581 193.557 151.108C193.557 151.108 202.108 153.738 211.657 156.981C216.81 158.73 222.255 160.66 226.801 162.449C233.514 138.995 228.683 139.074 231.517 119.191C232.029 115.589 232.006 91.5768 233.604 88.3851C235.198 85.1944 240.198 80.7472 247.937 82.395C250.969 84.3048 257.224 90.811 259.491 94.1006C280.747 124.983 280.505 182.729 276.406 195.963Z"
          fill="#8F5049"
        />
        <path
          d="M224.963 177.38C224.919 177.395 224.873 177.403 224.824 177.403C224.566 177.405 224.343 177.192 224.326 176.926C224.02 171.985 224.514 167.108 225.796 162.431C225.863 162.177 226.125 162.043 226.372 162.127C226.62 162.214 226.767 162.488 226.7 162.738C225.449 167.316 224.965 172.086 225.264 176.919C225.274 177.132 225.149 177.316 224.963 177.38Z"
          fill="#1D232A"
        />
        <path
          d="M220.731 160.014L185.229 198.731C187.521 182.468 194.036 168.078 202.774 153.982C202.774 153.982 211.273 156.689 220.731 160.014Z"
          fill="#1D232A"
        />
        <path
          d="M56.9688 156.404C56.9688 156.404 52.0226 185.83 56.0562 201.502C60.0899 217.173 71.3374 231.327 90.1178 229.923C116.396 227.955 109.495 152.273 109.495 152.273L56.9688 156.404Z"
          fill="#8F5049"
        />
        <path
          d="M74.5086 176.028C68.2531 170.301 60.6932 184.392 52.8486 180.98C52.9317 179.535 54.2616 156.422 54.2616 156.422L54.6119 150.298C54.9166 145.001 53.9945 141.511 55.4187 136.334C58.0081 126.921 62.3487 124.587 73.102 122.583C80.421 121.222 129.519 116.329 150.327 114.149C173.804 111.688 188.239 126.477 192.272 131.557C201.17 142.763 208.964 146.037 218.96 148.756C218.275 162.323 207.102 173.558 194.269 179.161C195.003 181.906 196.384 188.114 197.103 192.106C198.154 197.945 194.344 203.915 185.876 206.609C178.888 208.832 118.21 217.695 93.7643 221.136C85.1785 222.343 101.057 181.143 98.3766 172.903C95.7942 164.949 81.0728 182.032 74.5086 176.028Z"
          fill="#FE6A34"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53.7874 183.322C59.8432 185.959 73.7361 180.36 76.0902 178.345C79.3033 180.223 84.1567 177.488 88.5823 174.995C93.3797 172.292 97.6744 169.872 98.8322 173.918C99.863 177.512 97.3937 187.377 94.8884 197.385L94.8884 197.385C91.5294 210.804 88.1055 224.482 92.9662 223.653C117.127 219.525 177.11 208.949 184.051 206.494C192.461 203.52 196.376 197.286 195.501 191.304C194.904 187.215 193.715 180.86 193.068 178.054C202.567 173.543 211.249 165.857 215.516 156.475L211.955 157.903C208.243 159.393 203.867 157.355 202.177 153.351L191.452 127.908C186.588 121.993 173.141 109.346 151.592 112.224C147.405 112.783 142.057 113.486 136.135 114.265L136.135 114.265C113 117.307 81.0982 121.501 75.3315 122.756C64.6897 125.075 60.3522 127.581 57.5443 137.327C56.5368 140.824 56.5321 143.559 56.5269 146.552C56.5241 148.147 56.5212 149.816 56.3664 151.713L55.8532 158.022L53.7874 183.322Z"
          fill="#FE6A34"
        />
        <path
          d="M72.4476 186.047C88.003 163.067 116.187 152.417 134.75 131.846C142.498 123.261 148.636 115.166 148.832 103.446C148.877 100.747 148.8 97.9753 149.78 95.4201C150.761 92.8648 153.063 90.9016 156.196 90.5031C162.642 90.0266 164.495 97.0537 164.911 100.891C166.168 116.116 163.265 122.413 157.966 133.994C164.196 133.755 175.847 139.334 172.257 156.736C174.59 160.834 177.088 172.716 168.961 180.239C166.402 182.608 162.598 183.123 159.872 181.064C157.146 179.005 157.144 176.371 156.989 173.71C153.118 190.355 145.457 192.975 135.959 195.195C129.503 196.704 126.1 206.555 122.76 212.399C118.894 219.164 112.745 224.579 105.615 227.498C98.4846 230.417 84.888 231.487 77.6105 229.285"
          fill="#8F5049"
        />
        <path
          d="M172.697 157.679C172.551 157.668 172.411 157.588 172.331 157.451C170.733 154.749 165.745 148.741 154.022 151.295C153.763 151.351 153.51 151.184 153.461 150.917C153.411 150.655 153.58 150.392 153.841 150.337C166.163 147.654 171.451 154.056 173.154 156.93C173.29 157.161 173.217 157.463 172.989 157.607C172.897 157.665 172.796 157.687 172.697 157.679Z"
          fill="#1D232A"
        />
        <path
          d="M142.483 138.1C142.327 138.088 142.182 137.998 142.103 137.848C141.981 137.612 142.069 137.314 142.303 137.181C147.147 134.475 152.824 133.257 158.264 133.73C158.528 133.753 158.717 133.991 158.689 134.261C158.661 134.528 158.428 134.727 158.167 134.707C158.164 134.706 158.164 134.706 158.161 134.706C152.892 134.25 147.427 135.425 142.745 138.039C142.664 138.088 142.572 138.107 142.483 138.1Z"
          fill="#1D232A"
        />
        <path
          d="M71.6774 187.213C71.6143 187.208 71.5524 187.19 71.4917 187.16C71.2617 187.035 71.1776 186.745 71.3045 186.509C77.1045 175.76 85.9344 168.849 95.2319 162.14C95.4469 161.982 95.744 162.034 95.8924 162.249C96.0408 162.464 95.9871 162.767 95.7724 162.921C86.5705 169.561 77.8367 176.394 72.1368 186.957C72.0439 187.134 71.8605 187.227 71.6774 187.213Z"
          fill="#1D232A"
        />
        <path
          d="M177.259 77.7686L178.834 89.2267L179.155 91.5665L179.328 92.8122L185.385 136.868C185.961 141.045 189.783 143.921 193.924 143.289L224.306 138.662C228.447 138.034 231.335 134.137 230.759 129.959L222.633 70.8635C222.06 66.6858 218.238 63.8136 214.097 64.4425L198.203 66.8628L183.715 69.0688C179.574 69.6977 176.686 73.5942 177.259 77.7686Z"
          fill="#42EC9A"
        />
        <path
          d="M235.316 137.053L202.408 142.067C198.867 142.605 195.593 140.146 195.1 136.57L192.956 120.972L186.674 75.2932C186.184 71.7205 188.657 68.3832 192.199 67.8413L225.11 62.8281C228.651 62.2926 231.924 64.7522 232.418 68.3249L234.495 83.43L240.844 129.601C241.337 133.177 238.864 136.511 235.316 137.053Z"
          fill="#00A868"
        />
        <path
          d="M235.386 137.053L202.479 142.066C198.937 142.605 195.664 140.145 195.17 136.569L193.027 120.972L233.249 73.8678L240.913 129.601C241.407 133.177 238.934 136.511 235.386 137.053Z"
          fill="#1D232A"
        />
        <path
          d="M195.543 114.024C195.435 114.022 195.328 113.981 195.242 113.906C195.047 113.731 195.028 113.426 195.203 113.227L226.718 77.3457C226.893 77.147 227.195 77.1244 227.39 77.3019C227.585 77.4762 227.603 77.7817 227.428 77.9804L195.914 113.862C195.818 113.972 195.68 114.027 195.543 114.024Z"
          fill="#1D232A"
        />
        <path
          d="M260.677 91.9349C253.388 81.8062 233.606 66.8141 213.865 72.4458C209.544 73.6782 206.171 77.1821 206.775 82.1115C207.267 86.138 210.867 89.1538 215.313 89.3214C226.885 89.7606 232.102 91.5291 235.255 95.986C227.082 96.6 218.068 101.876 212.729 108.147C209.439 112.012 207.041 117.112 208.029 122.076C209.014 127.04 215.368 129.28 220.074 127.419C217.545 133.965 218.676 139.393 221.74 141.429C226.917 144.869 235.488 143.976 239.964 135.432"
          fill="#8F5049"
        />
        <path
          d="M220.068 127.89C219.988 127.888 219.905 127.867 219.833 127.82C219.607 127.683 219.535 127.389 219.673 127.161C223.775 120.345 228.341 115.712 233.239 113.386C238.033 111.109 241.933 111.675 243.829 112.214C244.083 112.287 244.228 112.551 244.157 112.809C244.082 113.064 243.819 113.212 243.562 113.142C241.793 112.638 238.154 112.114 233.642 114.256C228.919 116.499 224.494 121.007 220.49 127.655C220.399 127.811 220.234 127.893 220.068 127.89Z"
          fill="#1D232A"
        />
        <path
          d="M227.868 143.734C225.605 143.681 223.407 143.012 221.54 141.769C221.198 141.542 220.876 141.271 220.578 140.968C220.395 140.777 220.397 140.472 220.585 140.286C220.776 140.101 221.08 140.102 221.263 140.289C221.511 140.546 221.783 140.771 222.072 140.961C224.671 142.691 227.97 143.212 231.123 142.388C233.621 141.735 237.128 139.948 239.655 135.121C239.78 134.886 240.068 134.793 240.304 134.914C240.537 135.039 240.628 135.327 240.506 135.565C237.813 140.707 234.046 142.624 231.361 143.323C230.2 143.628 229.026 143.761 227.868 143.734Z"
          fill="#1D232A"
        />
        <path
          d="M248.996 97.6014C248.952 97.6004 248.907 97.5928 248.863 97.5788C243.57 95.8731 238.232 95.4156 232.996 96.2194C232.732 96.2587 232.491 96.0774 232.45 95.8097C232.413 95.542 232.593 95.2958 232.857 95.2532C238.238 94.4268 243.723 94.8975 249.158 96.6488C249.409 96.7296 249.549 97.0029 249.467 97.2612C249.4 97.4743 249.205 97.6063 248.996 97.6014Z"
          fill="#1D232A"
        />
        <path
          d="M193.371 178.692C193.418 178.696 193.466 178.693 193.515 178.681C193.77 178.62 193.933 178.361 193.878 178.103C192.855 173.298 191.059 168.75 188.538 164.585C188.404 164.359 188.11 164.294 187.888 164.435C187.666 164.579 187.594 164.877 187.728 165.1C190.191 169.177 191.949 173.625 192.95 178.324C192.997 178.531 193.17 178.677 193.371 178.692Z"
          fill="#1D232A"
        />
        <path
          d="M84.4594 117.322C87.899 117.587 91.0572 113.836 91.5135 108.945C91.9698 104.054 89.5514 99.8752 86.1117 99.6111C82.6721 99.3469 79.5139 103.098 79.0576 107.989C78.6013 112.879 81.0198 117.058 84.4594 117.322Z"
          fill="#F1BD38"
        />
        <path
          d="M128.03 106.368C112.973 111.843 96.9164 106.958 87.4224 95.3419C87.4138 95.3315 87.4081 95.3246 87.4024 95.3177C86.7481 94.5168 86.1225 93.6826 85.5378 92.8191C85.3726 92.5767 85.2105 92.3346 85.052 92.0895C84.8606 91.7868 84.6692 91.4842 84.485 91.1724C84.3605 90.9719 84.2334 90.7648 84.1189 90.5586C83.9322 90.2402 83.752 89.919 83.576 89.5884C82.5852 87.7555 81.7382 85.8139 81.0535 83.7715C79.4933 79.1381 78.8896 74.3873 79.1355 69.7437C79.1775 68.9866 79.238 68.2373 79.3205 67.4898C79.3247 67.4448 79.3321 67.4 79.3363 67.3551C80.9536 53.3785 90.249 40.7383 104.232 35.6536C107.257 34.5494 110.326 33.8691 113.371 33.5785C123.054 32.6452 132.518 35.649 139.8 41.6305C141.684 43.176 143.421 44.9206 144.977 46.8453C147.636 50.1393 149.774 53.9692 151.215 58.2472C157.783 77.7762 147.404 99.3217 128.03 106.368Z"
          fill="#8F5049"
        />
        <path
          d="M96.9889 81.601C97.902 88.9705 93.3401 95.8553 86.517 98.011C86.5069 98.0167 86.4908 98.0187 86.481 98.0212C86.0712 98.1483 85.6565 98.2589 85.2339 98.3526C85.1689 98.3671 85.1007 98.3813 85.036 98.3925C84.7601 98.449 84.4848 98.499 84.2038 98.5422C84.0135 98.5696 83.8199 98.5969 83.6307 98.6115C83.0964 98.674 82.5625 98.6978 82.0405 98.6966C81.5024 98.6973 80.9737 98.6665 80.4508 98.607C80.3781 98.6014 80.3091 98.5896 80.2399 98.5811C79.9187 98.5435 79.599 98.4899 79.2833 98.4268C73.3252 97.2515 68.5516 92.3587 67.7514 85.8941C66.738 77.7242 72.4605 70.1389 80.5365 68.9529C81.7663 68.7723 82.972 68.7483 84.1477 68.874C86.91 69.1571 89.4712 70.2304 91.576 71.8964C94.0572 73.8599 95.904 76.6515 96.6859 79.9373C96.8197 80.4814 96.9176 81.0358 96.9889 81.601Z"
          fill="#8F5049"
        />
        <path
          d="M78.7159 91.1324C78.6345 91.1261 78.5521 91.0972 78.4811 91.0466C78.2714 90.8952 78.227 90.5923 78.3796 90.3753C79.671 88.5554 80.1761 86.1723 79.738 83.9999C79.2967 81.8273 77.9107 79.8814 76.0327 78.7865C75.8083 78.6565 75.7346 78.3641 75.8664 78.1327C75.9983 77.9014 76.2863 77.8205 76.5107 77.9505C78.6165 79.1725 80.1695 81.3568 80.6625 83.7878C81.1554 86.2188 80.5856 88.89 79.1414 90.9301C79.0398 91.0736 78.8755 91.1447 78.7159 91.1324Z"
          fill="#1D232A"
        />
        <path
          d="M144.868 46.9286C140.732 48.0449 134.328 46.0052 133.039 39.7509C130.732 44.6202 125.793 49.0726 121.211 49.8588C119.728 50.1122 112.419 48.9949 114.892 41.6313C113.209 46.7338 98.2971 60.6499 89.3194 56.3672C87.4361 64.7418 81.8442 70.4708 78.7856 69.9334C78.8278 69.1725 78.8885 68.4196 78.9713 67.6683C78.9755 67.6231 78.9829 67.5782 78.9872 67.533C80.6103 53.4872 89.9389 40.7844 103.972 35.6745C107.008 34.5648 110.088 33.8812 113.144 33.5892C122.861 32.6512 132.359 35.6699 139.668 41.681C141.564 43.2411 143.304 44.9941 144.868 46.9286Z"
          fill="#1D232A"
        />
        <path
          d="M89.0275 5.83965C96.5865 12.8164 103.253 33.8873 93.6864 44.5172C84.12 55.1471 61.9438 51.3169 54.3848 44.3402C46.8257 37.3634 48.4517 23.0893 58.0184 12.4562C67.585 1.82309 81.4687 -1.14034 89.0275 5.83965Z"
          fill="#1D232A"
        />
        <path
          d="M125.019 96.7188C130.817 96.7048 133.891 92.4572 135.677 87.1495C131.965 86.3143 129.67 87.069 126.507 90.486C123.459 87.0838 114.575 86.3566 110.833 87.2122C112.44 92.5135 119.221 96.7328 125.019 96.7188Z"
          fill="#804BC3"
        />
        <path
          d="M123.701 93.3863C119.66 93.0661 115.186 91.1421 110.496 87.6119C110.286 87.4514 110.242 87.1472 110.4 86.9308C110.561 86.7147 110.86 86.6665 111.07 86.8237C120.95 94.2604 129.78 94.2964 135.289 86.9218C135.45 86.7057 135.752 86.661 135.962 86.8215C136.172 86.982 136.212 87.2892 136.051 87.5054C132.836 91.8117 128.586 93.7734 123.701 93.3863Z"
          fill="#1D232A"
        />
        <path
          d="M137.705 67.0265C137.468 67.1767 137.231 67.3206 136.992 67.4609L137.929 66.8849C137.854 66.9342 137.779 66.9804 137.705 67.0265ZM138.222 66.1004L138.222 66.1003L138.213 66.1065C138.161 66.1426 138.118 66.1714 138.078 66.1966L138.078 66.1964L138.067 66.2038C137.934 66.2917 137.798 66.3798 137.663 66.466L137.663 66.466L137.657 66.47C137.589 66.5146 137.521 66.5569 137.446 66.6036L137.446 66.6036L137.441 66.6063C137.21 66.7532 136.978 66.894 136.743 67.0316L136.741 67.0329C136.455 67.2026 136.175 67.3629 135.899 67.5063L135.898 67.5062L135.89 67.5109C133.59 68.7616 131.385 69.5217 129.575 69.7534C127.746 69.9876 126.447 69.6703 125.776 68.9478L125.77 68.9407C125.747 68.9135 125.733 68.897 125.724 68.8858C125.711 68.8705 125.706 68.8651 125.7 68.8556L125.682 68.8305L125.662 68.8077C125.63 68.771 125.605 68.7377 125.586 68.7079L125.582 68.7017L125.578 68.6955C124.972 67.7854 125.105 66.3011 126.118 64.4317C127.112 62.5963 128.882 60.543 131.221 58.6497L131.221 58.6497L131.224 58.6473C131.462 58.4517 131.71 58.2587 131.964 58.0673L131.964 58.0673C132.273 57.8336 132.593 57.603 132.923 57.3724L132.923 57.3724L132.925 57.3704C132.97 57.339 133.015 57.3084 133.064 57.2751L133.073 57.2691C133.117 57.2394 133.164 57.2072 133.212 57.1739C133.336 57.0909 133.459 57.0085 133.578 56.9291C134.022 56.6387 134.467 56.3644 134.906 56.1088L134.906 56.1087C137.452 54.6286 139.924 53.7361 141.918 53.4841C143.947 53.2277 145.307 53.6469 145.901 54.5402C146.24 55.0508 146.351 55.7316 146.201 56.5762C146.051 57.4237 145.643 58.4034 144.988 59.4556C143.679 61.5583 141.436 63.87 138.56 65.8714C138.516 65.9008 138.476 65.9287 138.437 65.9556C138.363 66.0063 138.295 66.0533 138.222 66.1004ZM125.663 68.8532C125.66 68.851 125.663 68.8527 125.671 68.8578L125.663 68.8532Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <path
          d="M117.356 70.7674L117.356 70.7674L117.358 70.7788C117.532 71.8509 116.852 73.1222 115.275 74.3876C113.722 75.633 111.416 76.7697 108.665 77.5585L108.665 77.5584L108.657 77.5608C108.575 77.5857 108.497 77.6083 108.419 77.6273L108.419 77.6272L108.407 77.6302C108.377 77.6384 108.346 77.6466 108.316 77.6547C108.183 77.6905 108.052 77.7258 107.918 77.759L107.918 77.759L107.911 77.7607C107.519 77.8638 107.113 77.9569 106.701 78.0401L106.701 78.0401L106.698 78.0408C106.492 78.0837 106.292 78.1255 106.089 78.1638C105.953 78.189 105.822 78.2124 105.701 78.2299L105.701 78.2298L105.69 78.2316C105.478 78.2668 105.269 78.3015 105.065 78.3333C105.029 78.3376 104.993 78.3424 104.964 78.3465L104.961 78.3469C104.926 78.3517 104.898 78.3556 104.871 78.3587L104.871 78.3585L104.857 78.3605C101.551 78.8343 98.5648 78.6539 96.4263 77.9876C96.3957 77.9756 96.3666 77.9677 96.3403 77.9625C94.5751 77.3927 93.5209 76.5328 93.2315 75.562C93.2234 75.5233 93.2139 75.4927 93.2096 75.4786L93.2088 75.4762L93.2069 75.47L93.2058 75.4636L93.2004 75.443C93.1929 75.4142 93.1893 75.3951 93.1837 75.3661C93.18 75.3467 93.1755 75.323 93.1684 75.2891C93.0049 74.246 93.6452 73.0169 95.1394 71.7858C96.6168 70.5686 98.8208 69.4483 101.469 68.6469L101.469 68.6469L101.472 68.6458C101.642 68.593 101.799 68.5443 101.959 68.5024L101.959 68.5025L101.967 68.5004C102.018 68.4862 102.067 68.4724 102.114 68.459C102.204 68.4337 102.288 68.4098 102.374 68.3874L102.375 68.387C102.666 68.3097 102.956 68.236 103.253 68.1665C103.644 68.0772 104.036 67.9929 104.434 67.9222L104.435 67.9221C104.471 67.9156 104.507 67.9091 104.542 67.9027C104.786 67.8589 105.016 67.8174 105.25 67.7835L105.25 67.7835L105.256 67.7826C108.351 67.2973 111.198 67.3755 113.364 67.8926C115.566 68.4184 116.907 69.355 117.277 70.4493C117.315 70.5686 117.343 70.6723 117.356 70.7674ZM96.1567 77.9608C96.1404 77.9636 96.1478 77.9615 96.1679 77.959C96.1628 77.9598 96.1591 77.9604 96.1572 77.9607L96.1567 77.9608Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <path
          d="M117.755 70.3601C117.797 70.4897 117.835 70.6255 117.854 70.7631C118.289 73.4439 114.4 76.4736 108.825 78.0679L105.229 67.3837C111.496 66.3998 116.851 67.679 117.755 70.3601Z"
          fill="#1D232A"
        />
        <path
          d="M138.859 66.3263C138.744 66.4048 138.628 66.4864 138.513 66.5616C138.462 66.5965 138.411 66.6313 138.36 66.663L134.686 55.7467C139.847 52.7455 144.811 52.0629 146.319 54.3314C148.025 56.9027 144.685 62.2706 138.859 66.3263Z"
          fill="#1D232A"
        />
        <path
          d="M120.888 82.3936C120.709 82.3798 120.547 82.261 120.485 82.0759C120.402 81.8246 120.534 81.5514 120.779 81.4607L125.742 79.6464C126.859 79.2365 127.736 78.3506 128.147 77.2163C128.559 76.0819 128.45 74.8399 127.855 73.8147L122.667 64.9207C122.533 64.6946 122.611 64.3978 122.835 64.2605C123.059 64.1199 123.349 64.1938 123.48 64.4197L128.668 73.3137C129.41 74.5853 129.545 76.1257 129.032 77.5357C128.523 78.946 127.433 80.0474 126.046 80.5524L121.083 82.3667C121.018 82.3907 120.951 82.3984 120.888 82.3936Z"
          fill="#1D232A"
        />
        <path
          d="M109.017 61.784C111.572 59.9402 112.872 57.3737 111.921 56.0515C110.969 54.7293 108.126 55.1522 105.571 56.996C103.016 58.8398 101.716 61.4063 102.667 62.7285C103.619 64.0507 106.462 63.6278 109.017 61.784Z"
          fill="#1D232A"
        />
        <path
          d="M132.888 53.1254C135.995 53.3272 138.625 52.1368 138.761 50.4665C138.898 48.7962 136.489 47.2786 133.381 47.0768C130.273 46.875 127.643 48.0655 127.507 49.7357C127.371 51.406 129.78 52.9237 132.888 53.1254Z"
          fill="#1D232A"
        />
        <path
          d="M109.134 81.8856C109.563 81.9159 109.947 81.5815 109.992 81.1386C110.037 80.6957 109.726 80.3121 109.297 80.2817C108.867 80.2514 108.483 80.5858 108.438 81.0287C108.393 81.4716 108.705 81.8552 109.134 81.8856Z"
          fill="#1D232A"
        />
        <path
          d="M114.023 80.8272C114.452 80.8576 114.836 80.5231 114.881 80.0802C114.926 79.6373 114.614 79.2537 114.185 79.2234C113.756 79.1931 113.372 79.5275 113.327 79.9704C113.282 80.4133 113.594 80.7969 114.023 80.8272Z"
          fill="#1D232A"
        />
        <path
          d="M134.254 75.5401C134.683 75.5704 135.068 75.236 135.112 74.7931C135.157 74.3502 134.846 73.9666 134.417 73.9363C133.988 73.9059 133.603 74.2404 133.558 74.6833C133.513 75.1261 133.825 75.5098 134.254 75.5401Z"
          fill="#1D232A"
        />
        <path
          d="M136.406 79.0907C136.835 79.121 137.219 78.7865 137.264 78.3437C137.309 77.9008 136.997 77.5172 136.568 77.4868C136.139 77.4565 135.755 77.7909 135.71 78.2338C135.665 78.6767 135.976 79.0603 136.406 79.0907Z"
          fill="#1D232A"
        />
        <path
          d="M140.475 76.2469C140.904 76.2772 141.288 75.9428 141.333 75.4999C141.378 75.057 141.067 74.6734 140.637 74.6431C140.208 74.6127 139.824 74.9472 139.779 75.3901C139.734 75.8329 140.046 76.2166 140.475 76.2469Z"
          fill="#1D232A"
        />
        <path
          d="M101.896 83.9388C102.325 83.9691 102.709 83.6347 102.754 83.1918C102.799 82.7489 102.487 82.3653 102.058 82.335C101.629 82.3046 101.245 82.6391 101.2 83.0819C101.155 83.5248 101.467 83.9085 101.896 83.9388Z"
          fill="#1D232A"
        />
        <path
          d="M105.514 86.4933C105.943 86.5236 106.327 86.1892 106.372 85.7463C106.417 85.3034 106.106 84.9197 105.676 84.8894C105.247 84.8591 104.863 85.1935 104.818 85.6364C104.773 86.0793 105.085 86.4629 105.514 86.4933Z"
          fill="#1D232A"
        />
        <path
          d="M146.13 69.806C146.559 69.8363 146.944 69.5019 146.988 69.059C147.033 68.6161 146.722 68.2325 146.293 68.2021C145.864 68.1718 145.479 68.5062 145.434 68.9491C145.389 69.392 145.701 69.7756 146.13 69.806Z"
          fill="#1D232A"
        />
      </g>
      <defs>
        <clipPath id="clip0_1862_23343">
          <rect width="314" height="269" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
