export function ErrorCloudIllustration() {
  return (
    <svg width="132" height="132" viewBox="0 0 132 132" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M125.73 96.9642C138.525 66.1007 130.482 43.4674 112.936 21.2457C81.8638 -17.848 14.9674 2.31611 2.90411 38.9408C-9.15917 75.5655 18.9885 108.487 39.094 124.947C59.1994 141.408 112.936 127.828 125.73 96.9642Z"
        fill="#EAF0F8"
      />
      <path
        d="M53.9741 30.8738C44.3071 32.4118 42.4372 39.574 42.7791 43.1767C42.8207 43.6146 42.3135 44.0633 41.882 43.9778C37.5415 43.1179 25.2935 44.3602 22.152 56.422C18.67 69.7912 30.0663 78.1888 39.1876 79.2938C48.3215 80.4003 91.0447 82.0851 99.884 79.2938C108.723 76.5024 114.033 68.7471 109.775 58.4385C105.842 48.9179 94.9031 48.1902 91.3119 48.6642C90.9577 48.7109 90.6298 48.4134 90.661 48.0574C90.9391 44.8767 90.5789 38.6876 83.6787 36.5706C77.2621 34.6019 72.7027 36.9241 70.8234 38.8985C70.5184 39.2189 69.859 39.1382 69.668 38.7393C68.1862 35.6443 63.4662 29.3636 53.9741 30.8738Z"
        fill="#677481"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.7142 52.4732C53.025 52.2956 53.421 52.4036 53.5986 52.7144C53.7954 53.0588 54.0424 53.4832 54.4511 53.8321C54.8425 54.1663 55.4069 54.4503 56.2768 54.4503C57.6407 54.4503 58.133 53.7893 58.6475 53.0983C58.7685 52.9358 58.8907 52.7717 59.0258 52.6142C59.2587 52.3424 59.668 52.3109 59.9398 52.5439C60.2116 52.7768 60.2431 53.1861 60.0101 53.4579C59.9399 53.5397 59.8619 53.6482 59.7717 53.7737C59.2586 54.487 58.3525 55.7467 56.2768 55.7467C55.0843 55.7467 54.2246 55.3433 53.6093 54.8181C53.0197 54.3148 52.6788 53.7179 52.4814 53.3723C52.4785 53.3674 52.4757 53.3625 52.473 53.3576C52.2954 53.0468 52.4033 52.6508 52.7142 52.4732Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.866 52.4732C72.1769 52.2956 72.5728 52.4036 72.7504 52.7144C72.9472 53.0588 73.1943 53.4832 73.6029 53.8321C73.9944 54.1663 74.5588 54.4503 75.4287 54.4503C76.7925 54.4503 77.2848 53.7893 77.7994 53.0983C77.9204 52.9358 78.0426 52.7717 78.1776 52.6142C78.4106 52.3424 78.8198 52.3109 79.0916 52.5439C79.3634 52.7768 79.3949 53.1861 79.1619 53.4579C79.0918 53.5397 79.0137 53.6482 78.9235 53.7737C78.4104 54.487 77.5043 55.7467 75.4287 55.7467C74.2361 55.7467 73.3764 55.3433 72.7612 54.8181C72.1716 54.3148 71.8306 53.7179 71.6332 53.3723C71.6304 53.3674 71.6276 53.3625 71.6248 53.3576C71.4472 53.0468 71.5552 52.6508 71.866 52.4732Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.5894 63.9966C62.1704 63.9966 59.1629 66.6528 58.3761 68.4232C58.2307 68.7503 57.8476 68.8977 57.5205 68.7523C57.1933 68.6069 57.046 68.2238 57.1914 67.8967C58.1974 65.6332 61.6859 62.7002 66.5894 62.7002C71.5402 62.7002 74.4214 65.6778 75.4076 67.8967C75.553 68.2238 75.4057 68.6069 75.0785 68.7523C74.7514 68.8977 74.3683 68.7503 74.2229 68.4232C73.4163 66.6083 70.9611 63.9966 66.5894 63.9966Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.3404 86.4818C44.7643 86.7241 44.9115 87.264 44.6693 87.6879L42.3122 91.8129C42.07 92.2367 41.53 92.384 41.1062 92.1418C40.6823 91.8996 40.5351 91.3596 40.7773 90.9358L43.1344 86.8108C43.3766 86.3869 43.9166 86.2396 44.3404 86.4818Z"
        fill="#677481"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.2601 91.1962C32.684 91.4384 32.8312 91.9784 32.589 92.4022L30.2319 96.5272C29.9897 96.9511 29.4497 97.0983 29.0259 96.8561C28.602 96.6139 28.4547 96.074 28.6969 95.6501L31.0541 91.5251C31.2963 91.1013 31.8362 90.954 32.2601 91.1962Z"
        fill="#677481"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.3493 95.3212C49.7732 95.5634 49.9204 96.1034 49.6782 96.5272L47.3211 100.652C47.0789 101.076 46.5389 101.223 46.1151 100.981C45.6912 100.739 45.544 100.199 45.7862 99.7751L48.1433 95.6501C48.3855 95.2263 48.9255 95.079 49.3493 95.3212Z"
        fill="#677481"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.9565 88.8388C60.3804 89.081 60.5276 89.6209 60.2854 90.0448L57.9283 94.1698C57.6861 94.5937 57.1461 94.7409 56.7223 94.4987C56.2984 94.2565 56.1511 93.7166 56.3933 93.2927L58.7505 89.1677C58.9927 88.7438 59.5327 88.5966 59.9565 88.8388Z"
        fill="#677481"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.2065 95.3212C68.6304 95.5634 68.7776 96.1034 68.5354 96.5272L66.1783 100.652C65.9361 101.076 65.3961 101.223 64.9723 100.981C64.5484 100.739 64.4011 100.199 64.6433 99.7751L67.0005 95.6501C67.2427 95.2263 67.7827 95.079 68.2065 95.3212Z"
        fill="#677481"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M79.4029 88.8388C79.8268 89.081 79.974 89.6209 79.7318 90.0448L77.3747 94.1698C77.1325 94.5937 76.5925 94.7409 76.1687 94.4987C75.7448 94.2565 75.5976 93.7166 75.8398 93.2927L78.1969 89.1677C78.4391 88.7438 78.9791 88.5966 79.4029 88.8388Z"
        fill="#677481"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M88.2423 95.3212C88.6661 95.5634 88.8134 96.1034 88.5712 96.5272L86.214 100.652C85.9718 101.076 85.4319 101.223 85.008 100.981C84.5842 100.739 84.4369 100.199 84.6791 99.7751L87.0363 95.6501C87.2785 95.2263 87.8184 95.079 88.2423 95.3212Z"
        fill="#677481"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98.8493 88.8388C99.2732 89.081 99.4205 89.6209 99.1782 90.0448L96.8211 94.1698C96.5789 94.5937 96.0389 94.7409 95.6151 94.4987C95.1912 94.2565 95.044 93.7166 95.2862 93.2927L97.6433 89.1677C97.8855 88.7438 98.4255 88.5966 98.8493 88.8388Z"
        fill="#677481"
      />
    </svg>
  )
}
