import { type ChangeEvent, useState } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { useCopyToClipboard } from 'react-use'
import { Box as JadeBox, Button, IconButton, Switch, Text, Textarea } from '@stone-payments/jade'
import { rest } from 'msw'
import { useAllExperiment } from 'platform/lib/experiment'

import { authenticator } from '~/domains/platform/core/authenticator/authenticator'
import { Box, Flex } from '~/domains/platform/design-system'
import BlackBird from '~/domains/platform/lib/blackbird'
import { Bucket } from '~/domains/platform/lib/bucket'
import { isomorphicCookies } from '~/lib/helpers/utils/cookies'

function mockFactory(data: any) {
  return [
    rest.get('https://api.lab.amplitude.com/sdk/v2/vardata?v=0', (_, res, ctx) => {
      return res(ctx.status(200), ctx.json(data))
    })
  ]
}

export async function enableExpHttpResponseMock() {
  if (typeof window === 'undefined') {
    const { server } = await import('~/scaffold/mocks/server')
    server.use(...mockFactory(Bucket.local.get('exp::mock::response')))
    server.listen()
  } else {
    const { worker } = await import('~/scaffold/mocks/browser')
    worker.use(...mockFactory(Bucket.local.get('exp::mock::response')))
    worker.start()
  }
}

export function ExpResponseMock() {
  const { token } = isomorphicCookies.getAll()
  const subjectId = authenticator.parseToken({ token })['stone_subject_id']
  const { data: expData } = useAllExperiment(subjectId)

  const [check, setCheck] = useState(Bucket.local.get('exp::mock::enabled'))

  const [, copyToClipboard] = useCopyToClipboard()

  const enableMockResponse = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.currentTarget.checked
    setCheck(!check)
    Bucket.local.set('exp::mock::enabled', checked)
    BlackBird.reload()
  }

  interface PayloadInput {
    payload: string
  }

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<PayloadInput>()

  const onSubmit: SubmitHandler<PayloadInput> = data => {
    Bucket.local.set('exp::mock::response', JSON.parse(data.payload))
    if (check) BlackBird.reload()
  }

  return (
    <JadeBox
      action={
        <Box>
          <Switch name="on-mock" onChange={enableMockResponse} checked={check} />
        </Box>
      }
      leadIcon="action-settings-cog"
      title="Experiment response mock"
    >
      <Flex gap="1rem" p="1rem" as="form" onSubmit={handleSubmit(onSubmit)}>
        <Flex width="50%" flexDirection="column" gap="1rem">
          <Textarea
            value={JSON.stringify(expData, null, 2)}
            label="Original response"
            placeholder="the original response will appear here"
            disabled
          />
          <Box>
            <IconButton
              variant="primary-solid"
              icon="action-copy"
              onClick={() => {
                copyToClipboard(JSON.stringify(expData, null, 4))
              }}
            />
          </Box>
        </Flex>

        <Flex width="50%" flexDirection="column" gap="1rem">
          <Textarea
            label="Fake response"
            placeholder="insert fake response here"
            error={Boolean(errors.payload)}
            {...register('payload', { required: 'This field is required' })}
          />
          {errors.payload && <Text color="negative">{errors.payload.message}</Text>}
          <Box>
            <Button icon="action-export" type="submit" variant="primary-solid">
              inject payload
            </Button>
          </Box>
        </Flex>
      </Flex>
    </JadeBox>
  )
}
