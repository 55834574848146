export function CalendarLoadingIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="127" height="98" viewBox="0 0 127 98" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M84.8254 8.01867C84.7969 7.59566 84.7343 7.18214 84.6414 6.78C83.8257 3.23284 80.6409 0.579102 76.8509 0.579102C72.4425 0.579102 68.8574 4.16421 68.8574 8.57256C68.8574 12.979 72.4425 16.5641 76.8509 16.5641C79.3946 16.5641 81.6652 15.3691 83.1315 13.512C84.2032 12.1501 84.8443 10.4334 84.8443 8.57256C84.8443 8.38666 84.8387 8.20077 84.8254 8.01867ZM76.8509 14.6672C73.4896 14.6672 70.7543 11.9338 70.7543 8.57256C70.7543 5.21128 73.4896 2.47598 76.8509 2.47598C79.829 2.47598 82.3158 4.62325 82.8431 7.4496C82.9114 7.8138 82.9475 8.18938 82.9475 8.57256C82.9475 10.0483 82.422 11.4008 81.5476 12.4574C80.4265 13.806 78.7364 14.6672 76.8509 14.6672Z"
        fill="#42EC9A"
      />
      <path
        d="M84.8437 8.57269C84.8437 10.4335 84.2025 12.1502 83.1308 13.5121H81.5469V12.4575C82.4213 11.4009 82.9468 10.0485 82.9468 8.57269L84.8247 8.0188C84.838 8.2009 84.8437 8.38679 84.8437 8.57269Z"
        fill="#1D232A"
      />
      <path
        d="M56.1692 8.01867C56.1407 7.59566 56.0781 7.18214 55.9852 6.78C55.1695 3.23284 51.9846 0.579102 48.1946 0.579102C43.7863 0.579102 40.2012 4.16421 40.2012 8.57256C40.2012 12.979 43.7863 16.5641 48.1946 16.5641C50.7384 16.5641 53.0089 15.3691 54.4752 13.512C55.547 12.1501 56.1881 10.4334 56.1881 8.57256C56.1881 8.38666 56.1824 8.20077 56.1692 8.01867ZM48.1946 14.6672C44.8334 14.6672 42.0981 11.9338 42.0981 8.57256C42.0981 5.21128 44.8334 2.47598 48.1946 2.47598C51.1728 2.47598 53.6596 4.62325 54.1869 7.4496C54.2552 7.8138 54.2912 8.18938 54.2912 8.57256C54.2912 10.0483 53.7658 11.4008 52.8913 12.4574C51.7703 13.806 50.0802 14.6672 48.1946 14.6672Z"
        fill="#42EC9A"
      />
      <path
        d="M56.1874 8.57269C56.1874 10.4335 55.5462 12.1502 54.4745 13.5121H52.8906V12.4575C53.7651 11.4009 54.2905 10.0485 54.2905 8.57269L56.1684 8.0188C56.1817 8.2009 56.1874 8.38679 56.1874 8.57269Z"
        fill="#1D232A"
      />
      <path d="M83.8879 72.837H10.1885L28.5256 10.1091H102.225L83.8879 72.837Z" fill="#E8E9EA" />
      <path
        d="M33.8244 71.7651C33.7979 71.7651 33.7713 71.7613 33.7448 71.7537C33.5949 71.7101 33.5077 71.5507 33.5513 71.4009L50.3424 13.9634C50.3861 13.8117 50.5454 13.7263 50.6953 13.7699C50.8451 13.8136 50.9324 13.9729 50.8888 14.1228L34.0976 71.5602C34.0615 71.6854 33.9477 71.7651 33.8244 71.7651Z"
        fill="#1D232A"
      />
      <path
        d="M58.3899 71.7651C58.3633 71.7651 58.3368 71.7613 58.3102 71.7537C58.1603 71.7101 58.0731 71.5508 58.1167 71.4009L75.0065 13.6258C75.0502 13.474 75.2095 13.3887 75.3593 13.4323C75.5092 13.4759 75.5965 13.6353 75.5528 13.7851L58.663 71.5602C58.627 71.6854 58.5132 71.7651 58.3899 71.7651Z"
        fill="#1D232A"
      />
      <path d="M102.226 10.1089L99.0051 21.1241H25.3057L28.5266 10.1089H102.226Z" fill="#42EC9A" />
      <path d="M102.254 10.1089L83.8887 72.8368H120.591L102.254 10.1089Z" fill="#00A868" />
      <path d="M102.254 10.1089L83.8887 72.8368H95.5886L102.894 12.2941L102.254 10.1089Z" fill="#1D232A" />
      <path d="M102.226 10.1089L99.0051 21.1241H25.3057L28.5266 10.1089H102.226Z" fill="#00A868" />
      <path
        d="M88.9278 55.8845H16.3646C16.2072 55.8845 16.0801 55.7574 16.0801 55.6C16.0801 55.4425 16.2072 55.3154 16.3646 55.3154H88.9278C89.0853 55.3154 89.2124 55.4425 89.2124 55.6C89.2124 55.7574 89.0834 55.8845 88.9278 55.8845Z"
        fill="#1D232A"
      />
      <path
        d="M93.966 38.6457H21.4046C21.2472 38.6457 21.1201 38.5186 21.1201 38.3612C21.1201 38.2037 21.2472 38.0767 21.4046 38.0767H93.966C94.1234 38.0767 94.2505 38.2037 94.2505 38.3612C94.2505 38.5186 94.1234 38.6457 93.966 38.6457Z"
        fill="#1D232A"
      />
      <path
        d="M47.1652 19.1401C46.3761 19.1401 45.9209 18.5084 46.1485 17.7288L46.6455 16.0292C46.8731 15.2496 47.6982 14.6179 48.4873 14.6179C49.2764 14.6179 49.7317 15.2496 49.504 16.0292L49.0071 17.7288C48.7775 18.5065 47.9543 19.1401 47.1652 19.1401Z"
        fill="#1D232A"
      />
      <path
        d="M47.1652 19.4247C46.7005 19.4247 46.3059 19.2426 46.0593 18.9126C45.807 18.5768 45.7406 18.1159 45.8772 17.6492L46.3742 15.9496C46.6398 15.0429 47.5673 14.3335 48.4892 14.3335C48.954 14.3335 49.3485 14.5156 49.5951 14.8457C49.8474 15.1814 49.9138 15.6423 49.7772 16.109L49.2802 17.8086C49.0147 18.7134 48.0852 19.4247 47.1652 19.4247ZM48.4873 14.8988C47.8291 14.8988 47.1102 15.4527 46.9186 16.1052L46.4216 17.8048C46.3344 18.1026 46.3666 18.372 46.5146 18.5673C46.653 18.7513 46.8845 18.8519 47.1652 18.8519C47.8234 18.8519 48.5423 18.298 48.7339 17.6455L49.2309 15.9458C49.3182 15.648 49.2859 15.3787 49.138 15.1833C48.9995 15.0012 48.7681 14.8988 48.4873 14.8988Z"
        fill="#1D232A"
      />
      <path
        d="M48.1947 16.5509C43.7882 16.5509 40.2012 12.9658 40.2012 8.55747C40.2012 4.15102 43.7863 0.565918 48.1947 0.565918C48.7182 0.565918 49.1431 0.990819 49.1431 1.51436C49.1431 2.0379 48.7182 2.4628 48.1947 2.4628C44.8334 2.4628 42.0981 5.1981 42.0981 8.55937C42.0981 11.9206 44.8334 14.6559 48.1947 14.6559C48.7182 14.6559 49.1431 15.0808 49.1431 15.6044C49.1431 16.1279 48.7182 16.5509 48.1947 16.5509Z"
        fill="#42EC9A"
      />
      <path
        d="M75.8214 19.1404C75.0324 19.1404 74.5771 18.5087 74.8047 17.7291L75.3017 16.0295C75.5293 15.2498 76.3545 14.6182 77.1436 14.6182C77.9326 14.6182 78.3879 15.2498 78.1603 16.0295L77.6633 17.7291C77.4357 18.5068 76.6105 19.1404 75.8214 19.1404Z"
        fill="#1D232A"
      />
      <path
        d="M75.8214 19.4249C75.3567 19.4249 74.9621 19.2428 74.7156 18.9128C74.4633 18.577 74.3969 18.1161 74.5335 17.6494L75.0304 15.9479C75.296 15.0412 76.2236 14.3318 77.1454 14.3318C77.6102 14.3318 78.0047 14.5139 78.2513 14.8439C78.5036 15.1797 78.57 15.6406 78.4334 16.1073L77.9364 17.8088C77.6709 18.7136 76.7433 19.4249 75.8214 19.4249ZM77.1436 14.899C76.4853 14.899 75.7664 15.4528 75.5748 16.1054L75.0779 17.8069C74.9906 18.1028 75.0228 18.374 75.1708 18.5694C75.3093 18.7534 75.5407 18.8539 75.8214 18.8539C76.4796 18.8539 77.1986 18.3001 77.3901 17.6475L77.8871 15.946C77.9744 15.6501 77.9421 15.3789 77.7942 15.1835C77.6576 15.0014 77.4262 14.899 77.1436 14.899Z"
        fill="#1D232A"
      />
      <path
        d="M76.8509 16.5509C72.4444 16.5509 68.8574 12.9658 68.8574 8.55747C68.8574 4.15102 72.4444 0.565918 76.8509 0.565918C77.3744 0.565918 77.7993 0.990819 77.7993 1.51436C77.7993 2.0379 77.3744 2.4628 76.8509 2.4628C73.4896 2.4628 70.7543 5.1981 70.7543 8.55937C70.7543 11.9206 73.4896 14.6559 76.8509 14.6559C77.3744 14.6559 77.7993 15.0808 77.7993 15.6044C77.7993 16.1279 77.3744 16.5509 76.8509 16.5509Z"
        fill="#42EC9A"
      />
      <path
        d="M95.5748 72.8123C95.5615 72.8123 95.5464 72.8123 95.5331 72.8085C95.3776 72.7857 95.2713 72.6397 95.2941 72.4841L103.113 20.3446C103.136 20.1929 103.274 20.0848 103.428 20.1037C103.58 20.1227 103.692 20.2574 103.678 20.411L99.7688 64.6045C99.7556 64.7619 99.6152 64.8776 99.4596 64.8625C99.3022 64.8492 99.1865 64.7107 99.2017 64.5533L102.284 29.7076L95.8556 72.5657C95.8347 72.7099 95.7133 72.8123 95.5748 72.8123Z"
        fill="#1D232A"
      />
      <g filter="url(#filter0_d_2001_224215)">
        <path
          d="M102.566 93.7027C114.3 93.7027 123.811 84.191 123.811 72.4577C123.811 60.7244 114.3 51.2126 102.566 51.2126C90.833 51.2126 81.3213 60.7244 81.3213 72.4577C81.3213 84.191 90.833 93.7027 102.566 93.7027Z"
          fill="#E8E9EA"
        />
      </g>
      <path
        d="M116.178 63.6608L114.885 69.6674C114.705 70.504 113.964 71.0767 113.142 71.0767C113.019 71.0767 112.896 71.0642 112.771 71.0374L106.759 69.7619C105.796 69.5568 105.18 68.6113 105.383 67.6462C105.589 66.6828 106.536 66.0674 107.499 66.2725L109.779 66.756C107.496 63.8749 103.594 62.528 99.8729 63.6697C97.6162 64.3619 95.7323 65.8747 94.571 67.9262C94.084 68.7843 92.9957 69.0858 92.1394 68.6006C91.2814 68.1135 90.9799 67.0253 91.4651 66.169C93.0796 63.3183 95.6931 61.2204 98.8257 60.2588C103.89 58.7068 109.196 60.4729 112.389 64.3119L112.691 62.9098C112.897 61.9464 113.847 61.3327 114.81 61.5415C115.773 61.7484 116.387 62.6975 116.178 63.6608Z"
        fill="#00A868"
      />
      <path
        d="M113.67 78.7478C112.057 81.5985 109.442 83.6965 106.311 84.658C105.068 85.0398 103.812 85.22 102.576 85.22C98.7706 85.22 95.1581 83.5038 92.748 80.6067L92.4465 82.0071C92.2664 82.8437 91.526 83.4164 90.7036 83.4164C90.5787 83.4164 90.4521 83.4039 90.3254 83.3754C89.3639 83.1684 88.7502 82.2194 88.9571 81.256L90.2523 75.2494C90.4592 74.2879 91.4047 73.6742 92.3663 73.8794L98.3782 75.1549C99.3415 75.3601 99.9569 76.3055 99.7518 77.2707C99.5484 78.234 98.6011 78.8495 97.6378 78.6443L95.3562 78.1609C97.6414 81.042 101.543 82.3888 105.264 81.2471C107.521 80.5549 109.403 79.0421 110.566 76.9906C111.051 76.1325 112.14 75.831 112.998 76.318C113.856 76.8033 114.157 77.8915 113.67 78.7478Z"
        fill="#00A868"
      />
      <defs>
        <filter
          id="filter0_d_2001_224215"
          x="79.045"
          y="50.4539"
          width="47.0427"
          height="47.0425"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.5175" />
          <feGaussianBlur stdDeviation="1.13813" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.290196 0 0 0 0 0.309804 0 0 0 0 0.333333 0 0 0 0.32 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2001_224215" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2001_224215" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
