import { type Entity, type Person, type Subject } from '../types'

export const entityFingerprint = Symbol('entityFingerprint')
export const subjectFingerprint = Symbol('subjectFingerprint')
export const personFingerprint = Symbol('personFingerprint')

export const isEntity = (entity: unknown): entity is Entity => {
  return entityFingerprint in (entity as Entity)
}

export const isSubject = (subject: unknown): subject is Subject => {
  return subjectFingerprint in (subject as Subject)
}

export const isPerson = (subject: unknown): subject is Person => {
  return personFingerprint in (subject as Person)
}
