export function HomeTransactionDisputeHelpIllustration() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="200" height="164" viewBox="0 0 200 164" fill="none">
      <g clipPath="url(#clip0_10790_16220)">
        <path
          d="M129.317 140.65L33.8874 147.254C30.9731 147.455 28.4462 145.253 28.2451 142.332L25.9721 109.378C25.7711 106.46 27.97 103.929 30.8863 103.728L110.687 98.2047C113.601 98.0033 116.128 100.205 116.329 103.126L118.068 128.336L130.17 137.886C131.281 138.763 130.731 140.552 129.317 140.65Z"
          fill="#00A868"
        />
        <path
          d="M80.6489 55.7323L175.329 64.2417C178.239 64.5023 180.811 62.3533 181.071 59.437L185.207 13.2853C185.467 10.3712 183.321 7.79611 180.409 7.53543L101.334 0.429133C98.4244 0.168449 95.853 2.3175 95.5927 5.23378L92.1493 43.6518L79.8553 52.9496C78.7251 53.8058 79.2394 55.6052 80.6489 55.7323Z"
          fill="#42EC9A"
        />
        <path
          d="M168.695 110.733C172.532 106.54 172.181 99.9624 167.91 96.0424C163.638 92.1225 157.065 92.3444 153.227 96.538C149.389 100.732 149.74 107.309 154.012 111.229C158.283 115.149 164.857 114.927 168.695 110.733Z"
          fill="#E09661"
        />
        <path
          d="M180.162 101.884C184 97.6907 182.408 89.9748 176.606 84.6503C170.804 79.3259 162.99 78.4093 159.152 82.603C155.314 86.7966 156.906 94.5126 162.708 99.837C168.51 105.161 176.324 106.078 180.162 101.884Z"
          fill="#E09661"
        />
        <path
          d="M186.549 95.6742C188 91.9081 193.355 84.3885 197.516 87.1967C203.579 91.2913 196.965 109.808 194.377 117.601C188.711 112.21 183.71 103.039 186.549 95.6742Z"
          fill="#E09661"
        />
        <path
          d="M184.104 105.993C188.203 105.555 190.85 98.8473 190.016 91.0105C189.182 83.1736 185.182 77.1753 181.083 77.6129C176.983 78.0505 174.336 84.7583 175.171 92.5952C176.005 100.432 180.004 106.43 184.104 105.993Z"
          fill="#E09661"
        />
        <path
          d="M197.64 142.218C195.805 146.671 191.134 149.14 186.567 148.396C186.349 148.362 186.131 148.32 185.915 148.267C185.678 148.216 185.441 148.15 185.209 148.076C184.984 148.008 184.764 147.932 184.542 147.845C184.54 147.845 184.54 147.845 184.538 147.843C184.485 147.822 184.43 147.8 184.377 147.777L184.324 147.758L183.74 147.516L183.698 147.499L183.065 147.239L183.058 147.235L181.816 146.722L181.759 146.698L181.194 146.465L179.35 145.704L182.184 138.831L182.555 137.935L182.656 137.685L183.001 136.847L187.13 126.829L189.966 128.003L190.106 128.063L190.21 128.105L190.906 128.393L191.316 128.565L191.939 128.821L191.951 128.826L192.104 128.889C192.26 128.953 192.415 129.021 192.565 129.095C192.58 129.101 192.595 129.107 192.61 129.116C192.815 129.213 193.014 129.315 193.206 129.427C193.401 129.536 193.592 129.652 193.774 129.773C193.956 129.889 194.131 130.012 194.303 130.144C197.945 132.861 199.45 137.829 197.64 142.218Z"
          fill="#FFCBD9"
        />
        <path
          d="M194.305 130.137C191.23 132.011 188.129 133.834 185.001 135.614C184.756 135.754 184.513 135.891 184.269 136.031C183.937 136.222 183.683 135.688 184.013 135.501C184.447 135.256 184.879 135.01 185.31 134.762C185.88 134.438 186.449 134.109 187.014 133.783C189.283 132.469 191.539 131.131 193.776 129.769C193.958 129.883 194.134 130.008 194.305 130.137Z"
          fill="#1D232A"
        />
        <path d="M189.968 128.001L190.11 128.061C190.058 128.053 190.011 128.033 189.968 128.001Z" fill="#1D232A" />
        <path
          d="M188.555 129.69L189.566 128.79C189.782 128.563 189.996 128.334 190.212 128.103L190.908 128.391L189.951 129.241C189.81 129.389 189.67 129.538 189.528 129.686C189.473 129.741 189.414 129.765 189.357 129.769L188.94 130.14C188.654 130.392 188.271 129.943 188.555 129.69Z"
          fill="#1D232A"
        />
        <path
          d="M193.209 129.421C190.605 131.117 188.002 132.812 185.401 134.508C185.357 134.535 185.31 134.567 185.266 134.594C184.948 134.802 184.694 134.27 185.008 134.065L185.729 133.594L186.123 133.338C186.083 133.238 186.1 133.126 186.22 133.045C187.564 132.127 188.887 131.176 190.18 130.192C189.488 130.707 188.783 131.201 188.062 131.676C187.93 131.765 187.757 131.78 187.647 131.644C187.554 131.532 187.543 131.314 187.678 131.227C188.936 130.398 190.153 129.508 191.319 128.561L191.941 128.817C191.668 129.042 191.393 129.262 191.113 129.48C191.395 129.262 191.674 129.042 191.951 128.821L192.104 128.885C192.26 128.948 192.415 129.016 192.565 129.09C192.449 129.182 192.335 129.273 192.216 129.366C192.347 129.281 192.476 129.196 192.608 129.112C192.815 129.207 193.014 129.311 193.209 129.421Z"
          fill="#1D232A"
        />
        <path
          d="M194.076 118.464C194.006 118.651 193.932 118.835 193.856 119.017L193.573 119.708L193.501 119.884L193.007 121.081L192.936 121.257L192.658 121.933L192.176 123.108L192.121 123.245L192.095 123.307L191.744 124.159L191.556 124.619L191.181 125.532L190.368 127.516L190.275 127.741L190.138 128.073L190.064 128.253L189.456 129.733L189.308 130.097L188.893 131.108L188.87 131.161L188.265 132.636L188.045 133.177L187.676 134.073L182.39 146.961L180.701 151.077C176.548 161.197 165.018 166.258 154.883 162.172C144.669 158.052 139.783 146.4 143.954 136.224L154.155 111.356L155.799 107.35L157.198 103.942C158.121 101.691 159.41 99.7033 160.968 98.0162C162.303 96.5666 163.835 95.3437 165.501 94.3645C166.536 93.7563 167.626 93.2434 168.752 92.8322C170.208 92.3003 171.725 91.9421 173.274 91.764C173.869 91.6962 174.47 91.6538 175.071 91.639C177.287 91.5881 179.541 91.9082 181.744 92.6436C182.057 92.7474 182.373 92.8598 182.684 92.9827C182.807 93.0293 182.927 93.0781 183.05 93.1289C183.158 93.1756 183.268 93.2201 183.374 93.2688C184.582 93.7902 185.714 94.4196 186.762 95.1402C187.89 95.9159 188.919 96.7976 189.841 97.7661C194.146 102.272 196.15 108.651 195.035 114.929C194.978 115.257 194.912 115.584 194.836 115.91C194.648 116.764 194.392 117.618 194.076 118.464Z"
          fill="#FFCBD9"
        />
        <path
          d="M194.076 118.464C194.007 118.65 193.932 118.835 193.856 119.017L193.573 119.708L193.008 121.081L192.658 121.931L192.176 123.105L192.095 123.305L191.744 124.156L191.181 125.53L190.369 127.514C188.817 126.742 187.799 125.498 187.224 123.998C187.008 123.438 186.855 122.842 186.758 122.226C186.703 121.889 186.665 121.545 186.646 121.196C186.614 120.676 186.616 120.145 186.652 119.606C186.673 119.293 186.703 118.975 186.745 118.657C186.811 118.163 186.9 117.665 187.014 117.169C187.397 115.492 188.047 113.837 188.881 112.381C184.582 111.014 177.624 108.6 173.662 104.514C171.816 102.611 170.623 100.347 170.648 97.6579C170.669 95.4685 171.613 93.4848 173.281 91.766C173.876 91.6981 174.779 90.2315 175.38 90.2188C177.596 90.168 179.736 90.2654 181.939 90.9988C182.252 91.1026 183.296 91.5159 183.609 91.6367C183.732 91.6833 186.007 92.5099 186.115 92.5607C187.323 93.0821 187.833 93.4572 188.881 94.1778C190.009 94.9535 191.2 96.1086 192.125 97.0771C196.43 101.583 196.159 108.653 195.044 114.931C194.986 115.259 194.921 115.586 194.845 115.912C194.648 116.764 194.392 117.618 194.076 118.464Z"
          fill="#E09661"
        />
        <path
          d="M160.394 96.6553C160.339 96.6553 160.282 96.6404 160.231 96.6087C158.401 95.5023 157.857 93.5016 157.833 93.4169C157.789 93.2473 157.891 93.0735 158.06 93.029C158.229 92.9845 158.403 93.0862 158.447 93.2558C158.456 93.2833 158.959 95.0975 160.559 96.0661C160.71 96.1572 160.758 96.3522 160.667 96.5027C160.606 96.6002 160.502 96.6553 160.394 96.6553Z"
          fill="#1D232A"
        />
        <path
          d="M192.392 113.318C190.478 113.318 188.849 112.708 188.766 112.678C188.603 112.617 188.521 112.432 188.582 112.269C188.644 112.106 188.828 112.023 188.991 112.085C189.014 112.093 191.255 112.93 193.454 112.61C193.628 112.585 193.789 112.706 193.814 112.88C193.839 113.053 193.719 113.214 193.545 113.24C193.158 113.295 192.768 113.318 192.392 113.318Z"
          fill="#1D232A"
        />
        <path
          d="M188.961 143.907C188.876 143.907 188.794 143.873 188.73 143.807C187.291 142.284 186.849 140.035 187.605 138.077C188.36 136.12 190.197 134.753 192.286 134.597C192.459 134.584 192.614 134.715 192.627 134.889C192.639 135.065 192.508 135.218 192.335 135.23C190.489 135.368 188.866 136.576 188.197 138.305C187.528 140.035 187.92 142.023 189.192 143.369C189.312 143.496 189.306 143.697 189.179 143.818C189.118 143.877 189.039 143.907 188.961 143.907Z"
          fill="#1D232A"
        />
        <path
          d="M165.808 114.047C165.469 114.047 165.126 113.952 164.822 113.751C163.459 112.852 162.199 112.894 161.383 113.087C160.413 113.316 159.446 112.714 159.22 111.748C158.991 110.779 159.59 109.808 160.557 109.582C161.789 109.291 164.238 109.056 166.798 110.741C167.628 111.288 167.859 112.405 167.313 113.235C166.966 113.761 166.392 114.047 165.808 114.047Z"
          fill="#E09661"
        />
        <path
          d="M180.019 120.927C179.376 120.927 178.752 120.579 178.428 119.969C178.034 119.227 177.236 118.25 175.664 117.814C174.707 117.549 174.146 116.555 174.411 115.597C174.675 114.639 175.668 114.077 176.625 114.342C179.579 115.162 181.014 117.161 181.607 118.282C182.072 119.161 181.738 120.251 180.86 120.717C180.591 120.859 180.303 120.927 180.019 120.927Z"
          fill="#E09661"
        />
        <path
          d="M153.908 120.902L153.908 120.901C154.329 119.812 155.245 119.068 156.304 118.822C156.937 118.676 157.622 118.713 158.275 118.966L158.275 118.966L166.15 122.016C166.15 122.016 166.15 122.016 166.15 122.016C167.02 122.354 167.671 123.011 168.021 123.804C168.372 124.596 168.42 125.521 168.082 126.392L168.082 126.392C167.409 128.134 165.455 128.999 163.715 128.325L155.841 125.276C155.84 125.276 155.84 125.276 155.84 125.276C155.188 125.023 154.657 124.588 154.285 124.052C154.16 123.872 154.055 123.685 153.97 123.49L153.969 123.489C153.62 122.695 153.571 121.773 153.908 120.902Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <path
          d="M153.442 120.721C153.055 121.722 153.112 122.781 153.512 123.69C153.611 123.917 153.732 124.131 153.874 124.335C154.238 124.862 154.737 125.301 155.342 125.6C155.446 125.651 155.552 125.698 155.66 125.74L159.131 127.084L161.926 119.844L158.456 118.5C158.348 118.458 158.235 118.422 158.125 118.39C157.478 118.201 156.813 118.191 156.189 118.335C154.98 118.615 153.924 119.471 153.442 120.721Z"
          fill="#1D232A"
        />
        <path
          d="M167.38 125.907L162.724 131.432C161.789 132.541 162.225 134.243 163.575 134.766L168.693 136.748"
          fill="#FFCBD9"
        />
        <path
          d="M168.693 137.066C168.654 137.066 168.616 137.06 168.578 137.045L163.463 135.063C162.722 134.775 162.183 134.169 161.986 133.397C161.789 132.626 161.969 131.835 162.483 131.227L167.139 125.702C167.251 125.568 167.452 125.551 167.586 125.664C167.719 125.776 167.736 125.977 167.624 126.111L162.968 131.636C162.589 132.085 162.456 132.67 162.602 133.238C162.748 133.806 163.146 134.256 163.692 134.468L168.807 136.449C168.97 136.513 169.052 136.697 168.989 136.86C168.94 136.987 168.822 137.066 168.693 137.066Z"
          fill="#1D232A"
        />
        <path
          d="M167.362 126.111L167.362 126.111C167.782 125.022 168.702 124.275 169.756 124.029C170.299 123.904 170.876 123.913 171.439 124.077L171.44 124.078C171.541 124.107 171.638 124.138 171.727 124.173L171.727 124.173L171.729 124.174L179.603 127.223C179.604 127.224 179.604 127.224 179.604 127.224C179.604 127.224 179.604 127.224 179.604 127.224C180.474 127.562 181.125 128.218 181.475 129.011C181.826 129.804 181.874 130.728 181.536 131.6L181.536 131.6C180.863 133.341 178.909 134.207 177.169 133.533L169.296 130.484C169.296 130.484 169.296 130.484 169.295 130.484C169.201 130.447 169.108 130.406 169.017 130.361C168.489 130.1 168.055 129.718 167.739 129.26L167.739 129.26L167.738 129.259C167.614 129.081 167.509 128.896 167.423 128.699L167.423 128.699C167.074 127.905 167.025 126.982 167.362 126.111Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <path
          d="M166.896 125.931C166.508 126.931 166.565 127.991 166.965 128.9C167.065 129.127 167.186 129.341 167.327 129.544C167.691 130.072 168.191 130.511 168.796 130.809C168.9 130.86 169.006 130.907 169.114 130.949L172.584 132.293L175.38 125.053L171.909 123.71C171.801 123.667 171.689 123.631 171.579 123.599C170.932 123.411 170.267 123.4 169.643 123.544C168.434 123.824 167.378 124.68 166.896 125.931Z"
          fill="#1D232A"
        />
        <path
          d="M193.503 119.884L193.01 121.081L192.938 121.257L185.628 118.426C185.245 118.277 185.056 117.847 185.205 117.466C185.353 117.082 185.782 116.893 186.163 117.042L193.503 119.884Z"
          fill="#FFCBD9"
        />
        <path
          d="M192.125 123.245L192.097 123.307L191.748 124.159L191.56 124.619L191.185 125.532L190.373 127.516L190.28 127.741L183.09 124.958L184.948 120.467L192.125 123.245Z"
          fill="#FFCBD9"
        />
        <path
          d="M159.824 145.75C161.319 146.414 166.249 148.178 171.259 145.285C172.305 144.679 172.663 143.34 172.06 142.292C171.455 141.245 170.119 140.887 169.074 141.49L159.824 145.75ZM159.824 145.75L161.901 141.878M159.824 145.75L161.901 141.878M161.901 141.878C163.041 142.334 166.06 143.231 169.074 141.49L161.901 141.878Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <path
          d="M166.162 142.461L167.135 146.745C165.574 146.979 160.657 147.35 156.668 143.673L156.666 143.671C156.53 143.547 156.398 143.421 156.272 143.29L156.272 143.29L156.266 143.285L156.239 143.257C156.197 143.214 156.171 143.185 156.147 143.157L156.142 143.15L156.137 143.144C156.029 143.021 155.938 142.887 155.866 142.747L155.866 142.747L155.863 142.743C155.806 142.634 155.758 142.52 155.72 142.401L155.72 142.401L155.718 142.395C155.656 142.209 155.621 142.018 155.612 141.825L155.612 141.816L155.611 141.807C155.609 141.78 155.608 141.749 155.61 141.708L155.61 141.7L155.61 141.691C155.614 141.134 155.832 140.581 156.26 140.158L166.162 142.461ZM166.162 142.461C165.696 142.513 164.954 142.554 164.078 142.448C162.66 142.277 160.868 141.718 159.35 140.18C159.304 140.133 159.257 140.091 159.208 140.052L159.199 140.045L159.19 140.037C159.108 139.964 159.018 139.9 158.924 139.845L158.909 139.836L158.896 139.827C158.878 139.814 158.856 139.802 158.834 139.791L158.824 139.787L158.814 139.781C157.995 139.345 156.953 139.472 156.26 140.158L166.162 142.461Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <path
          d="M165.742 145.37C162 145.37 158.836 143.691 157.251 142.343C157.118 142.229 157.101 142.03 157.215 141.896C157.33 141.763 157.528 141.746 157.662 141.86C160.032 143.88 166.129 146.673 172.227 142.793C172.375 142.699 172.572 142.742 172.665 142.89C172.758 143.039 172.716 143.236 172.567 143.329C170.231 144.815 167.89 145.37 165.742 145.37Z"
          fill="#1D232A"
        />
        <path
          d="M159.173 139.415C158.767 139.849 158.371 140.29 157.981 140.74C157.209 141.625 156.466 142.537 155.755 143.476C155.622 143.323 155.507 143.156 155.416 142.98C156.364 141.738 157.368 140.538 158.419 139.383C157.313 140.39 156.248 141.45 155.236 142.556C155.16 142.327 155.116 142.09 155.105 141.85C155.385 141.549 155.668 141.25 155.956 140.958C155.67 141.197 155.382 141.441 155.103 141.693C155.107 141.007 155.376 140.326 155.901 139.807C156.754 138.963 158.034 138.809 159.042 139.345C159.088 139.362 159.133 139.385 159.173 139.415Z"
          fill="#1D232A"
        />
        <path
          d="M159.516 139.66C158.411 141.086 157.349 142.551 156.326 144.039C156.185 143.909 156.045 143.776 155.909 143.636C156.582 142.655 157.272 141.69 157.981 140.737C158.331 140.268 158.684 139.802 159.044 139.338C159.088 139.359 159.133 139.385 159.173 139.412C159.294 139.484 159.41 139.567 159.516 139.66Z"
          fill="#1D232A"
        />
        <path
          d="M41.8958 118.831C41.7307 118.831 41.591 118.702 41.5784 118.534C41.5657 118.358 41.699 118.208 41.8725 118.195L87.9249 115.007C88.1006 114.993 88.2509 115.128 88.2636 115.302C88.2763 115.476 88.1429 115.628 87.9694 115.641L41.917 118.829C41.9106 118.831 41.9022 118.831 41.8958 118.831Z"
          fill="#1D232A"
        />
        <path
          d="M42.7635 131.407C42.5985 131.407 42.4588 131.278 42.4461 131.11C42.4334 130.934 42.5667 130.784 42.7403 130.771L63.1273 129.36C63.303 129.345 63.4533 129.48 63.466 129.654C63.4787 129.828 63.3453 129.98 63.1718 129.993L42.7847 131.405C42.7784 131.407 42.7699 131.407 42.7635 131.407Z"
          fill="#1D232A"
        />
        <path
          d="M69.1887 129.578C69.0236 129.578 68.8839 129.449 68.8712 129.281C68.8585 129.106 68.9918 128.955 69.1654 128.942L103.091 126.594C103.264 126.581 103.417 126.715 103.43 126.889C103.442 127.062 103.309 127.215 103.135 127.228L69.2098 129.576C69.2013 129.578 69.195 129.578 69.1887 129.578Z"
          fill="#1D232A"
        />
        <path
          d="M169.594 22.4557C169.586 22.4557 169.575 22.4557 169.564 22.4536L150.86 20.7729C150.684 20.758 150.557 20.6033 150.572 20.4274C150.587 20.2515 150.743 20.1222 150.917 20.1392L169.622 21.8199C169.797 21.8347 169.924 21.9894 169.909 22.1653C169.895 22.3306 169.757 22.4557 169.594 22.4557Z"
          fill="#1D232A"
        />
        <path
          d="M145.541 20.2942C145.533 20.2942 145.522 20.2942 145.512 20.2921L109.313 17.041C109.14 17.0261 109.011 16.8714 109.025 16.6955C109.04 16.5196 109.195 16.3903 109.37 16.4073L145.571 19.6605C145.747 19.6753 145.874 19.8301 145.859 20.006C145.844 20.1713 145.704 20.2942 145.541 20.2942Z"
          fill="#1D232A"
        />
        <path
          d="M160.447 34.6509C160.439 34.6509 160.428 34.6509 160.417 34.6488L108.156 29.9523C107.982 29.9374 107.853 29.7827 107.868 29.6068C107.883 29.4309 108.037 29.3037 108.213 29.3186L160.477 34.0151C160.652 34.0299 160.779 34.1847 160.764 34.3606C160.748 34.5259 160.61 34.6509 160.447 34.6509Z"
          fill="#1D232A"
        />
        <path
          d="M168.529 48.3928C168.521 48.3928 168.51 48.3928 168.5 48.3907L131.501 45.0654C131.326 45.0505 131.199 44.8958 131.213 44.7199C131.228 44.544 131.385 44.4147 131.558 44.4317L168.557 47.757C168.733 47.7718 168.86 47.9265 168.845 48.1024C168.832 48.2699 168.692 48.3928 168.529 48.3928Z"
          fill="#1D232A"
        />
        <path
          d="M126.583 44.6244C126.574 44.6244 126.564 44.6244 126.553 44.6223L106.998 42.8653C106.824 42.8505 106.695 42.6958 106.71 42.5199C106.725 42.344 106.877 42.2168 107.055 42.2316L126.61 43.9886C126.786 44.0035 126.913 44.1582 126.898 44.3341C126.883 44.4994 126.746 44.6244 126.583 44.6244Z"
          fill="#1D232A"
        />
        <path
          d="M24.9606 25.5988C24.9606 25.5988 18.6834 30.6768 7.97456 36.5009C-2.73432 42.325 -1.2973 50.6478 4.33438 55.0858C-3.36289 65.9137 10.1692 81.2262 26.986 75.1012C29.5193 80.0246 34.3256 79.4036 35.7499 76.1122C43.5784 87.7709 57.8788 84.3523 56.7529 75.8112C60.6301 76.8688 64.4226 73.2086 61.4237 68.2111C67.4237 68.7749 70.5622 67.1705 68.8078 60.0769C67.0533 52.9833 58.6132 31.0265 54.063 27.6164C49.5127 24.2063 24.9606 25.5988 24.9606 25.5988Z"
          fill="#8F5049"
        />
        <path
          d="M11.6654 58.0977C7.37342 58.0977 4.26446 55.4421 4.12901 55.3234C3.99568 55.209 3.98298 55.0076 4.09726 54.8762C4.21155 54.7427 4.4126 54.73 4.54593 54.8444C4.5798 54.872 7.88347 57.6865 12.203 57.4492C12.3744 57.4343 12.5268 57.5742 12.5374 57.7501C12.548 57.926 12.4125 58.0744 12.2369 58.085C12.0443 58.0935 11.8538 58.0977 11.6654 58.0977Z"
          fill="#1D232A"
        />
        <path
          d="M59.7475 58.4517C64.3527 60.1535 69.4616 57.7947 71.1611 53.1829L74.3589 44.3196C76.0584 39.7078 73.7029 34.5916 69.0976 32.8898C64.4924 31.1879 59.3835 33.5468 57.684 38.1586L54.4862 47.0218C52.7867 51.6315 55.1422 56.7498 59.7475 58.4517Z"
          fill="#1D232A"
        />
        <path
          d="M68.5643 46.3244C68.34 46.3244 68.1093 46.2884 67.885 46.2121C66.7781 45.8369 66.1855 44.6353 66.558 43.5289C66.5749 43.476 68.2977 38.2326 67.6183 32.1394C66.74 24.2637 62.3782 18.641 54.6491 15.4259C53.5698 14.9766 53.0576 13.7368 53.5063 12.6559C53.955 11.575 55.1931 11.0621 56.2724 11.5114C67.8723 16.3372 71.1421 25.2217 71.8405 31.8257C72.5812 38.8218 70.649 44.6395 70.5664 44.8832C70.268 45.7691 69.4448 46.3244 68.5643 46.3244Z"
          fill="#1D232A"
        />
        <path
          d="M61.6269 66.9757C61.5909 66.9757 61.5528 66.9694 61.5168 66.9566C61.3517 66.8973 61.2671 66.715 61.3284 66.5497C61.3433 66.5073 62.4967 63.11 59.1486 58.6126C59.0428 58.4728 59.0724 58.2735 59.2142 58.1676C59.3539 58.0616 59.5528 58.0913 59.6586 58.2333C63.2311 63.0294 61.9824 66.6175 61.9274 66.768C61.8787 66.8952 61.756 66.9757 61.6269 66.9757Z"
          fill="#1D232A"
        />
        <path
          d="M61.2692 70.8712C62.7817 70.8712 64.0078 69.6433 64.0078 68.1287C64.0078 66.6141 62.7817 65.3862 61.2692 65.3862C59.7567 65.3862 58.5306 66.6141 58.5306 68.1287C58.5306 69.6433 59.7567 70.8712 61.2692 70.8712Z"
          fill="#1D232A"
        />
        <path
          d="M70.7695 44.6367C70.4584 45.9867 70.0394 47.2838 69.5209 48.5258C64.8077 59.7521 51.9105 66.1824 38.8355 63.1728C25.5827 60.123 16.7299 48.4198 17.6611 36.0744C17.7479 34.8981 17.9278 33.7155 18.1987 32.5308C19.0579 28.7816 20.7701 25.4351 23.1044 22.6269C23.1891 22.5252 23.2738 22.4234 23.3584 22.3238C24.7679 20.6877 26.387 19.238 28.1753 18.0045C28.988 17.4429 29.8367 16.9258 30.7149 16.4574C36.3466 13.4521 43.2143 12.4051 50.1306 13.9968C52.3973 14.516 54.5411 15.2938 56.5221 16.2836C61.9908 19.0112 66.2765 23.356 68.8077 28.4849C69.1272 29.1292 69.4193 29.7862 69.6775 30.4538C69.7346 30.5937 69.7897 30.7357 69.8405 30.8734C70.6595 33.0776 71.1674 35.3919 71.324 37.7551C71.4722 40.0186 71.3008 42.3266 70.7695 44.6367Z"
          fill="#E09661"
        />
        <path
          d="M52.3104 19.2155C52.6089 24.018 48.9518 25.0502 46.5095 19.9446C46.1497 23.0346 33.1085 27.674 23.3563 22.3204C24.7658 20.6842 26.3848 19.2346 28.1731 18.0011C28.9858 17.4395 29.8345 16.9223 30.7128 16.454C36.3445 13.4487 43.2121 12.4017 50.1285 13.9933C52.3951 14.5126 54.539 15.2904 56.5199 16.2802C61.9887 19.0078 66.2743 23.3525 68.8055 28.4815C69.1251 29.1258 69.4171 29.7828 69.6753 30.4504C59.0956 28.5238 53.0702 22.3543 52.3104 19.2155Z"
          fill="#8F5049"
        />
        <path
          d="M60.5138 32.0188C60.9522 31.2816 60.1287 29.981 58.6746 29.1137C57.2204 28.2464 55.6861 28.1409 55.2477 28.8781C54.8093 29.6153 55.6328 30.916 57.087 31.7832C58.5411 32.6505 60.0754 32.756 60.5138 32.0188Z"
          fill="#8F5049"
        />
        <path
          d="M45.5739 26.5826C47.2659 26.5434 48.6215 25.8163 48.6017 24.9586C48.5819 24.1009 47.1942 23.4373 45.5023 23.4765C43.8103 23.5156 42.4547 24.2427 42.4745 25.1004C42.4943 25.9581 43.8819 26.6217 45.5739 26.5826Z"
          fill="#8F5049"
        />
        <path
          d="M56.2992 42.5055L56.2967 42.5048C56.0656 42.449 55.8376 42.3825 55.6062 42.3115L55.6057 42.3113C53.8696 41.781 52.4429 40.9457 51.5206 40.0251C50.5878 39.094 50.241 38.1565 50.432 37.3772C50.6369 36.5458 51.4694 35.8413 52.874 35.469C54.2585 35.102 56.0823 35.0986 58.006 35.5751L58.006 35.5751L58.0088 35.5758C58.2398 35.6316 58.4678 35.6982 58.6992 35.7692L58.6998 35.7694C60.4336 36.2986 61.8604 37.1338 62.7827 38.0545C63.7158 38.9859 64.0619 39.9229 63.8694 40.7004L63.8692 40.7011C63.6644 41.5328 62.8323 42.2373 61.4284 42.6098C60.0446 42.977 58.2219 42.9809 56.2992 42.5055Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <path
          d="M56.1793 42.9907C55.9338 42.9314 55.6946 42.8615 55.4597 42.7894L58.8459 35.291C62.4353 36.3867 64.8607 38.7774 64.3549 40.8205C63.8173 43.0035 60.156 43.9741 56.1793 42.9907Z"
          fill="#1D232A"
        />
        <path
          d="M39.9356 37.8005L39.9356 37.8005L39.9328 37.7998C39.7017 37.7439 39.4737 37.6774 39.2423 37.6064L39.2418 37.6063C37.5058 37.0759 36.079 36.2407 35.1567 35.32C34.2239 34.389 33.8771 33.4515 34.0681 32.6722C34.273 31.8407 35.1055 31.1362 36.5101 30.7639C37.8946 30.397 39.7184 30.3936 41.6421 30.8701L41.6421 30.8701L41.6449 30.8707C41.8759 30.9266 42.104 30.9931 42.3353 31.0641L42.3359 31.0643C44.0698 31.5936 45.4965 32.4287 46.4189 33.3494C47.3519 34.2808 47.698 35.2178 47.5055 35.9954L47.5053 35.9961C47.3004 36.8289 46.4682 37.5339 45.0645 37.9064C43.6808 38.2735 41.8583 38.277 39.9356 37.8005Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <path
          d="M39.8155 38.2857C39.57 38.2263 39.3308 38.1564 39.0959 38.0843L42.4821 30.5859C46.0715 31.6817 48.4968 34.0723 47.991 36.1154C47.4535 38.3005 43.7921 39.2712 39.8155 38.2857Z"
          fill="#1D232A"
        />
        <path
          d="M51.0175 34.8711L50.6683 41.456C50.5413 43.8721 47.2016 44.8979 45.3053 43.3974C44.7551 42.9629 44.2175 42.3991 43.735 41.6701"
          fill="#E09661"
        />
        <path
          d="M47.4302 44.4148C46.5815 44.4148 45.7476 44.152 45.1085 43.6476C44.4884 43.158 43.9381 42.5519 43.4704 41.8461C43.3731 41.6999 43.4133 41.5028 43.5593 41.4053C43.7053 41.3078 43.9021 41.3481 43.9995 41.4943C44.4312 42.1428 44.9349 42.7002 45.5021 43.1495C46.3889 43.851 47.7307 43.9825 48.8439 43.478C49.7539 43.0648 50.3042 42.323 50.3508 41.4392L50.7 34.8543C50.7084 34.6784 50.8587 34.5427 51.0343 34.5533C51.21 34.5618 51.3433 34.7123 51.3349 34.8882L50.9857 41.4731C50.9264 42.6006 50.2407 43.5437 49.1063 44.0566C48.5772 44.2982 47.9995 44.4148 47.4302 44.4148Z"
          fill="#1D232A"
        />
        <path
          d="M32.554 48.9093C33.1868 49.9817 33.9191 50.9715 34.7466 51.8701C36.427 53.7012 38.4862 55.1509 40.827 56.1343C42.9687 57.0329 45.2354 57.4907 47.5062 57.5013C47.9105 57.5034 48.3168 57.4907 48.7232 57.4652C50.3951 57.3572 52.0607 57.0032 53.667 56.4077C56.4585 55.3692 57.885 52.26 56.8458 49.4646C56.5855 48.7652 56.1961 48.1506 55.7157 47.6398C54.2744 46.1096 52.0057 45.5013 49.9105 46.2791C48.3168 46.8704 46.575 46.8344 45.0047 46.1753C43.6714 45.6158 42.5772 44.6599 41.8449 43.418C40.7106 41.4957 38.5751 40.5356 36.4968 40.809C35.7963 40.9001 35.1021 41.1312 34.4545 41.5148C31.8895 43.0301 31.0387 46.3406 32.554 48.9093Z"
          fill="#1D232A"
        />
        <path
          d="M32.554 48.9093C33.1868 49.9817 33.9191 50.9715 34.7466 51.8701C36.427 53.7012 38.4862 55.1509 40.827 56.1343C42.9687 57.0329 45.2354 57.4907 47.5062 57.5013C47.9105 57.5034 48.3168 57.4907 48.7232 57.4652C50.3951 57.3572 52.0607 57.0032 53.667 56.4077C56.4585 55.3692 57.885 52.26 56.8458 49.4646C56.5855 48.7652 56.1961 48.1506 55.7157 47.6398C54.2744 46.1096 52.0057 45.5013 49.9105 46.2791C48.3168 46.8704 46.575 46.8344 45.0047 46.1753C43.6714 45.6158 42.5772 44.6599 41.8449 43.418C40.7106 41.4957 38.5751 40.5356 36.4968 40.809C35.7963 40.9001 35.1021 41.1312 34.4545 41.5148C31.8895 43.0301 31.0387 46.3406 32.554 48.9093Z"
          fill="#1D232A"
        />
        <path
          d="M53.6973 48.3636L53.6974 48.3635C54.1268 48.1332 54.5426 47.8758 54.9416 47.592C54.2732 47.0298 53.465 46.6535 52.6058 46.508L52.6049 46.5079C51.7855 46.3676 50.9186 46.4348 50.0856 46.7462L50.0844 46.7466C48.3718 47.3821 46.4987 47.3433 44.8113 46.635L53.6973 48.3636ZM53.6973 48.3636C50.7372 49.9524 47.1153 50.2344 43.7805 48.8328C40.393 47.4086 38.0399 44.5532 37.1262 41.2603M53.6973 48.3636L37.1262 41.2603M37.1262 41.2603C38.825 41.2355 40.4876 42.0986 41.4142 43.6706L41.4143 43.6706C42.2013 45.0054 43.3786 46.0338 44.8112 46.635L37.1262 41.2603Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <path
          d="M34.7466 51.8686C36.427 53.6997 38.4862 55.1494 40.8269 56.1328C42.9687 57.0314 45.2354 57.4892 47.5062 57.4998C46.8565 51.1162 38.027 48.1639 34.7466 51.8686Z"
          fill="#D0572B"
        />
        <path
          d="M52.6893 46.0151L53.9338 48.8043C54.5581 48.4694 55.1549 48.0794 55.7157 47.6365C54.9073 46.7802 53.8406 46.2101 52.6893 46.0151Z"
          fill="#1D232A"
        />
        <path
          d="M24.2537 24.8951C23.9321 24.8951 23.6082 24.821 23.3014 24.6662C22.2622 24.1385 21.8411 22.8732 22.3638 21.8284C22.5268 21.5041 26.4527 13.8425 35.8367 10.6635C41.8599 8.62251 49.2799 8.91922 56.1941 11.4794C57.2904 11.8864 57.8513 13.105 57.4449 14.2028C57.0386 15.3007 55.8217 15.8623 54.7254 15.4554C48.7064 13.2258 42.3149 12.9418 37.1933 14.6776C29.5087 17.2823 26.1754 23.6701 26.1437 23.7358C25.7691 24.4713 25.0262 24.8951 24.2537 24.8951Z"
          fill="#1D232A"
        />
        <path
          d="M14.7618 44.0268C19.1871 46.1504 24.495 44.279 26.6156 39.8474L30.624 31.3211C32.7447 26.8895 30.8759 21.574 26.4505 19.4504C22.0252 17.3268 16.7173 19.1982 14.5967 23.6298L10.5883 32.1561C8.46766 36.5899 10.3364 41.9032 14.7618 44.0268Z"
          fill="#1D232A"
        />
      </g>
      <defs>
        <clipPath id="clip0_10790_16220">
          <rect width="200" height="163.184" fill="white" transform="translate(0 0.408203)" />
        </clipPath>
      </defs>
    </svg>
  )
}
