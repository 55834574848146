import { type NextPageContext } from 'next'
import Router, { type SingletonRouter } from 'next/router'
import { type PartialDeep } from 'type-fest'

export const isomorphicPush = (
  url: string,
  context?: PartialDeep<NextPageContext>,
  router: PartialDeep<SingletonRouter> = Router
) => {
  const ctx = context as NextPageContext
  const rtr = router as SingletonRouter

  if (ctx?.res && ctx.req) {
    ctx.res.writeHead(302, { Location: url })
    ctx.res.end()
  } else {
    rtr.push(url)
  }
}
