export function TeamListSalesmanDuoIllustration() {
  return (
    <svg
      width="283"
      height="145"
      viewBox="0 0 283 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="salesman-duo"
      role="figure"
    >
      <path
        d="M275.614 45.1837L275.352 46.9935L274.937 49.882L273.353 60.8899L270.62 79.9065L270.257 82.4362C269.921 84.7741 267.917 86.5095 265.556 86.5095H209.98L211.334 77.0925L212.916 66.0846L213.331 63.1961L213.823 59.7796L216.116 43.832C216.452 41.4941 218.456 39.7565 220.816 39.7565H270.913C273.276 39.7565 275.188 41.4647 275.585 43.6537C275.675 44.1434 275.689 44.6579 275.614 45.1837Z"
        fill="#00A769"
      />
      <path
        d="M272.064 45.7862L267.066 80.1909H214.315L214.994 75.4678L216.393 65.7394L216.759 63.1871L217.192 60.1678L219.306 45.4635C219.385 44.9084 219.862 44.4954 220.424 44.4954H270.945C271.636 44.4954 272.164 45.1047 272.064 45.7862Z"
        fill="white"
      />
      <path
        d="M232.521 83.208C232.521 84.1197 232.066 84.9456 231.328 85.5436C230.59 86.1394 229.57 86.5095 228.441 86.5095H219.496C219.496 86.5095 189.672 86.5095 181.418 86.5095C178.477 86.5095 176.04 84.7606 175.614 82.4769C175.568 82.2444 175.546 82.003 175.546 81.7593V80.6309C175.546 80.2315 175.945 79.9066 176.439 79.9066H228.441C230.696 79.9066 232.521 81.3847 232.521 83.208Z"
        fill="#00A769"
      />
      <path
        d="M228.441 85.3405V86.5095C228.441 86.5095 189.672 86.5095 181.418 86.5095C178.477 86.5095 176.04 84.7606 175.614 82.4769L228.441 85.3405Z"
        fill="#1D232A"
      />
      <path
        d="M270.62 79.9066L270.257 82.4362C269.921 84.7741 267.917 86.5095 265.556 86.5095H230.484C226.837 86.5095 223.881 83.5533 223.881 79.9066H270.62Z"
        fill="#42EC9A"
      />
      <path
        d="M203.984 42.7556C203.598 44.87 200.879 47.7946 196.305 49.0313C194.59 51.6918 191.803 53.1654 190.835 56.257C189.44 60.7048 186.872 68.3165 185.793 72.0016L164.19 56.2051C171.996 47.657 176.873 33.8441 175.372 22.7279C175.207 21.5003 174.982 20.2479 175.255 19.0383C175.528 17.8265 176.437 16.6418 177.748 16.4093C178.85 16.2108 179.989 16.7366 180.745 17.5061C181.499 18.2778 181.934 19.2662 182.322 20.2434C183.218 22.5113 184.252 25.0297 184.764 27.4014C185.556 27.011 191.864 24.4926 196.718 25.7563C198.554 26.334 199.644 28.3086 199.502 30.1184C199.45 30.8089 198.985 31.7612 198.087 32.6774C201.867 32.6436 203.131 35.1462 202.551 37.123C202.37 37.7391 201.982 38.4025 201.409 39.0569C203.447 39.5737 204.255 41.2549 203.984 42.7556Z"
        fill="#E09661"
      />
      <path
        d="M187.951 36.6085C186.134 36.6085 184.599 35.7533 183.965 34.3541C183.388 33.0791 183.394 31.1678 185.141 29.622C185.888 28.9608 187.799 27.9002 189.237 27.4692C189.415 27.415 189.605 27.5165 189.659 27.6971C189.713 27.8776 189.611 28.0649 189.431 28.1191C188.095 28.5185 186.269 29.5272 185.588 30.1297C184.117 31.4318 184.103 33.0227 184.579 34.0766C185.146 35.3268 186.621 36.0489 188.343 35.918C192.376 35.6133 195.576 34.4376 197.859 32.427C197.999 32.3029 198.214 32.3164 198.338 32.4563C198.462 32.5962 198.448 32.8106 198.308 32.9347C195.914 35.0447 192.579 36.2768 188.397 36.5927C188.246 36.6018 188.097 36.6085 187.951 36.6085Z"
        fill="#1D232A"
      />
      <path
        d="M191.333 43.2994C190.805 43.2994 190.261 43.2769 189.704 43.2295C187.42 43.0354 185.829 42.0244 185.337 40.4561C184.841 38.8764 185.522 37.3735 187.368 35.9857C187.517 35.8728 187.729 35.9022 187.842 36.0534C187.955 36.2023 187.926 36.4144 187.775 36.5273C186.184 37.7233 185.581 38.978 185.983 40.253C186.558 42.0899 188.817 42.4757 189.76 42.5547C195.33 43.0264 199.297 40.9435 201.152 38.8335C201.274 38.6936 201.49 38.6801 201.63 38.8019C201.77 38.9261 201.784 39.1404 201.662 39.2804C199.888 41.3 196.307 43.2994 191.333 43.2994Z"
        fill="#1D232A"
      />
      <path
        d="M282.662 86.848H188.154C187.966 86.848 187.815 86.6968 187.815 86.5095C187.815 86.3222 187.966 86.171 188.154 86.171H282.662C282.849 86.171 283 86.3222 283 86.5095C283 86.6968 282.849 86.848 282.662 86.848Z"
        fill="#1D232A"
      />
      <path
        d="M191.963 49.952C189.824 49.952 188.248 49.1486 187.623 47.7314C187.156 46.6731 187.079 44.9941 189.363 43.0083C189.505 42.8864 189.717 42.9 189.842 43.0421C189.963 43.1821 189.95 43.3964 189.808 43.5206C188.224 44.8971 187.698 46.224 188.244 47.4606C188.993 49.1599 191.223 49.2953 192.143 49.275C193.561 49.2434 194.933 49.0516 196.219 48.704C196.397 48.6567 196.584 48.7627 196.634 48.9433C196.684 49.1238 196.575 49.3088 196.395 49.3585C195.057 49.7195 193.63 49.9204 192.159 49.952C192.089 49.952 192.026 49.952 191.963 49.952Z"
        fill="#1D232A"
      />
      <path
        d="M203.228 107.074C203.228 107.074 128.326 106.334 127.586 107.074C126.845 107.814 118.214 43.7665 118.214 43.7665L135.382 47.4719L136.104 47.6276L136.366 47.6841L137.833 48.0022L137.883 48.0135L146.031 49.7714L146.537 49.8798L147.367 50.0603L171.258 55.2167C176.789 56.4105 181.442 60.1294 183.825 65.2633L203.228 107.074Z"
        fill="#00734D"
      />
      <path
        d="M10.9897 44.8475C10.8836 44.8475 10.7775 44.7978 10.7121 44.703C10.6173 44.5676 8.35165 41.3091 7.1263 36.3806C7.08117 36.2 7.19174 36.015 7.37227 35.9699C7.5528 35.9247 7.73785 36.0353 7.78298 36.2158C8.97449 41.0157 11.2424 44.2788 11.265 44.3127C11.3733 44.4661 11.3349 44.676 11.1815 44.7843C11.1251 44.8294 11.0574 44.8475 10.9897 44.8475Z"
        fill="#1D232A"
      />
      <path
        d="M75.798 89.1972L56.9889 110.048C48.7229 118.791 33.5447 119.924 25.6262 107.106C19.6303 97.3978 18.5133 85.151 12.7047 73.5226C7.09019 62.28 0.575284 57.8232 0.0675412 41.8778C0.0156385 40.2914 0.16232 37.3938 0.789665 36.094C2.18652 33.1965 5.86033 33.1762 7.45577 36.3016C7.17143 34.2187 7.02475 31.7319 7.66112 29.7845C8.99931 25.7135 13.7969 26.0226 14.7831 29.1187C15.5661 31.5762 16.0942 33.2078 17.0171 34.652C16.7757 32.9889 16.8817 28.8592 16.8998 26.8508C16.9133 25.3253 17.1142 23.4185 18.1251 22.3376C19.9914 20.3382 23.4395 20.3269 25.2493 24.8785C27.0366 29.3692 28.3906 40.0048 32.5721 43.5048C36.0744 46.4384 39.0532 53.7341 40.9375 62.7517C40.5245 65.1053 40.5561 67.4274 41.3504 70.194L45.1822 81.0259L54.8496 70.3046C60.067 64.5209 68.9875 64.0605 74.7712 69.2778C80.555 74.4952 81.0153 83.4134 75.798 89.1972Z"
        fill="#E09661"
      />
      <path
        d="M32.4954 60.2693C30.8481 60.6303 29.1827 60.9914 27.4947 60.9688C25.8067 60.9463 24.0737 60.5017 22.7851 59.4118C21.4966 58.3241 20.7474 56.5142 21.2077 54.8917"
        fill="#E09661"
      />
      <path
        d="M27.6527 61.3096C27.5985 61.3096 27.5444 61.3096 27.4902 61.3096C26.1046 61.2893 24.1256 60.9914 22.5662 59.6735C21.0859 58.4234 20.4111 56.4669 20.8828 54.8015C20.9347 54.6209 21.1197 54.5171 21.3002 54.569C21.4808 54.6209 21.5846 54.8082 21.5327 54.9865C21.131 56.3992 21.7222 58.0758 23.0018 59.1568C24.4076 60.346 26.2242 60.6168 27.4992 60.6326C29.1579 60.6574 30.8165 60.2919 32.4232 59.9421C32.606 59.9015 32.7865 60.0188 32.8271 60.2016C32.8678 60.3844 32.7504 60.5649 32.5676 60.6055C30.9744 60.9485 29.3294 61.3096 27.6527 61.3096Z"
        fill="#1D232A"
      />
      <path
        d="M53.9064 32.7157C51.582 35.5027 46.6671 39.3909 44.697 42.144L27.5827 69.5057C26.5672 70.9228 24.4686 71.4306 22.8912 70.6408L13.4133 65.8838L38.304 16.3845L51.4241 22.9693C55.2671 24.8987 56.3774 29.2631 53.9064 32.7157Z"
        fill="#00A769"
      />
      <path
        d="M41.6641 25.4787L13.4156 65.886L22.8912 70.6408C24.4663 71.4328 26.5672 70.9251 27.5827 69.5057L44.697 42.144L41.6641 25.4787Z"
        fill="#1D232A"
      />
      <path
        d="M45.0784 39.0231C44.9114 39.0231 44.7647 38.8989 44.7422 38.7274L43.1061 25.9097C43.0835 25.7247 43.2144 25.5555 43.3995 25.5306C43.5845 25.5058 43.7538 25.6389 43.7786 25.824L45.4146 38.6417C45.4372 38.8267 45.3063 38.996 45.1213 39.0208C45.1055 39.0231 45.0919 39.0231 45.0784 39.0231Z"
        fill="#1D232A"
      />
      <path
        d="M22.0246 66.1704L45.013 19.7536L30.8774 12.661C27.3796 10.9054 22.6971 12.3654 21.0024 15.7413L0.800963 56.6135C0.130742 57.9697 0.670078 59.4885 2.07371 60.1925L17.0803 67.7229C18.8337 68.6053 21.1829 67.8674 22.0246 66.1704Z"
        fill="#42EC9A"
      />
      <path
        d="M26.1475 52.9488L34.8672 35.2883C35.1831 34.6677 34.892 33.9479 34.1856 33.5936L20.1742 26.5777C19.2986 26.1399 18.159 26.4333 17.7686 27.2005L9.04673 44.8632C8.7308 45.4838 9.0219 46.2037 9.72823 46.558L23.7397 53.5738C24.6175 54.0116 25.7571 53.716 26.1475 52.9488Z"
        fill="white"
      />
      <path
        d="M42.6706 18.5779L51.7107 23.1138C48.2129 21.3581 43.5304 22.8182 41.8356 26.1941L40.6848 21.3513L42.6706 18.5779Z"
        fill="#42EC9A"
      />
      <path
        d="M3.3803 55.7966L7.1218 48.5844C7.43773 47.9638 8.19371 47.7653 8.90003 48.1173L22.8867 55.1061C23.76 55.5439 24.1955 56.6271 23.8074 57.3966L20.0636 64.6088C19.7477 65.2294 18.9917 65.4279 18.2854 65.0759L4.29875 58.0871C3.42543 57.6493 2.9899 56.5639 3.3803 55.7966Z"
        fill="#1D232A"
      />
      <path d="M5.33631 49.787L5.03363 50.3926L23.8042 59.7747L24.1069 59.1692L5.33631 49.787Z" fill="#42EC9A" />
      <path d="M4.09327 53.6983L3.79059 54.3038L15.9847 60.3989L16.2874 59.7933L4.09327 53.6983Z" fill="#42EC9A" />
      <path d="M19.4424 52.8604L13.8235 63.5442L14.4227 63.8593L20.0416 53.1755L19.4424 52.8604Z" fill="#42EC9A" />
      <path d="M12.8205 49.5506L7.20157 60.2344L7.80077 60.5496L13.4197 49.8658L12.8205 49.5506Z" fill="#42EC9A" />
      <path
        d="M38.2182 29.1931L40.9127 24.2827C41.5242 23.1657 40.8675 21.7462 39.3917 21.0061L27.3232 14.9492C25.642 14.1053 23.5862 14.4776 22.8867 15.7503"
        fill="#42EC9A"
      />
      <path
        d="M38.2183 29.5316C38.1641 29.5316 38.1077 29.5181 38.0558 29.4887C37.891 29.3985 37.8324 29.1931 37.9226 29.0284L40.6171 24.118C40.8224 23.7456 40.8585 23.3214 40.7254 22.8903C40.529 22.2585 39.9875 21.683 39.2428 21.3084L27.1743 15.2516C25.6601 14.4911 23.7961 14.8003 23.1868 15.9128C23.0965 16.0776 22.8912 16.1362 22.7264 16.046C22.5617 15.9557 22.503 15.7503 22.5933 15.5856C23.3831 14.1436 25.6217 13.7126 27.4789 14.6446L39.5474 20.7014C40.4568 21.1572 41.1225 21.8816 41.373 22.6872C41.5626 23.2988 41.5084 23.9036 41.2128 24.4429L38.5184 29.3533C38.4529 29.4684 38.3379 29.5316 38.2183 29.5316Z"
        fill="#00A769"
      />
      <path d="M46.176 10.0217L46.1684 10.0191V10.014L46.176 10.0217Z" fill="#E8E9EA" />
      <path
        d="M53.8658 16.574C53.8658 16.574 39.254 12.7535 35.4336 32.5465L35.4268 32.5803L20.8151 25.2485L20.1133 24.8965C21.2822 8.9669 33.732 4.27761 41.8266 7.23155L41.8401 7.24058L41.61 8.58327L46.1661 10.0162L45.945 11.3747L50.456 12.7648L50.465 12.7693L50.2574 14.2181L53.8658 16.574Z"
        fill="#E8E9EA"
      />
      <path
        d="M35.4268 32.9211C35.3749 32.9211 35.323 32.9098 35.2756 32.885L19.9621 25.2011C19.7951 25.1177 19.7274 24.9146 19.8109 24.7476C19.8944 24.5806 20.0975 24.5129 20.2645 24.5964L35.5757 32.2802C35.7427 32.3637 35.8104 32.5668 35.7269 32.7338C35.6705 32.8534 35.5509 32.9211 35.4268 32.9211Z"
        fill="#1D232A"
      />
      <path d="M46.1751 10.0208L46.1684 10.0185V10.0095H46.1706L46.1751 10.0208Z" fill="#1D232A" />
      <path
        d="M21.3612 46.9371C24.9692 46.9371 27.8941 44.0122 27.8941 40.4041C27.8941 36.7961 24.9692 33.8712 21.3612 33.8712C17.7531 33.8712 14.8282 36.7961 14.8282 40.4041C14.8282 44.0122 17.7531 46.9371 21.3612 46.9371Z"
        fill="#00A769"
      />
      <path d="M46.1751 10.0208L46.1684 10.0185V10.0095H46.1706L46.1751 10.0208Z" fill="#1D232A" />
      <path
        d="M19.0233 42.6179C18.8811 42.6179 18.7502 42.5276 18.7029 42.3854L17.7032 39.3909C17.6445 39.2126 17.7393 39.0208 17.9176 38.9621C18.0958 38.9034 18.2876 38.9982 18.3463 39.1765L19.2377 41.8506L25.387 39.7993C25.5653 39.7407 25.7571 39.8354 25.8158 40.0137C25.8744 40.192 25.7797 40.3838 25.6014 40.4425L19.1294 42.5998C19.0955 42.6134 19.0594 42.6179 19.0233 42.6179Z"
        fill="#1D232A"
      />
      <path
        d="M23.5275 72.6199L29.8484 74.0777L41.2579 70.3948C39.0442 61.779 43.6906 54.0139 47.4659 49.2592C49.3728 46.8559 48.4882 43.4799 45.8502 42.9541C43.7335 42.5321 41.2782 43.6244 37.5886 49.3088C36.9906 50.2318 35.8668 52.7051 33.7411 53.6709C24.0804 58.0623 22.5346 67.9238 23.5275 72.6199Z"
        fill="#E09661"
      />
      <path
        d="M74.9924 25.9753C74.1394 24.1248 73.2751 22.2383 72.9907 20.2208C72.7064 18.2012 73.0787 15.9942 74.4485 14.4822C75.552 13.2636 77.1948 12.625 78.8332 12.5077C80.4715 12.3903 82.1121 12.7582 83.6601 13.3043C82.7529 5.92506 88.0831 4.89603 90.1773 5.35187C91.9781 5.74453 93.9549 7.23616 95.09 8.68944C96.2251 10.1427 97.0104 11.8307 97.7844 13.5028C97.8047 11.7562 98.6983 10.4338 100.206 10.4225C102.943 10.4 104.861 14.5725 104.884 17.5084C106.204 16.52 109.494 16.6463 108.133 22.5655C108.133 22.5655 126.092 27.3157 116.997 38.7162C116.997 38.7162 127.231 41.449 124.365 52.3644C121.499 63.2797 77.0707 59.5134 74.8186 59.1027C72.5665 58.6897 70.8988 56.6114 72.9027 51.2428C72.9027 51.2428 61.9558 50.3221 64.2079 39.6527C66.0313 30.9985 74.9924 25.9753 74.9924 25.9753Z"
        fill="#1D232A"
      />
      <path d="M148.868 81.9195L158.529 109.969H124.871V93.135L148.868 81.9195Z" fill="#E09661" />
      <path
        d="M150.463 83.481L124.449 96.2604L66.6947 99.2865L46.0104 78.0065L49.1832 74.3123L50.1942 75.1631L62.6012 60.2445C63.6844 58.965 65.2008 58.1345 66.8617 57.9111C72.835 57.1077 89.9742 55.4288 106.364 53.9552C108.244 53.786 110.106 53.619 111.931 53.4565C117.09 52.9984 121.951 52.5697 126.062 52.2108C133.311 51.5767 140.051 55.9749 142.391 62.8622L147.812 78.803L148.708 78.4488L150.463 83.481Z"
        fill="#E8E9EA"
      />
      <path d="M65.5438 83.7609L61.7076 104.818L73.916 91.2823L65.5438 83.7609Z" fill="#1D232A" />
      <path
        d="M64.7314 72.7756L67.0129 103.561C67.1867 105.917 69.1499 107.74 71.5149 107.74H121.982C124.878 107.74 127.024 105.052 126.383 102.229L115.526 65.3671L64.7314 72.7756Z"
        fill="#00734D"
      />
      <path d="M72.3385 57.2522L71.991 71.7172L77.8628 70.8597V56.6813L72.3385 57.2522Z" fill="#00734D" />
      <path d="M112.439 53.4836V65.9347L106.572 66.7978L106.881 53.9833L112.439 53.4836Z" fill="#00734D" />
      <path
        d="M94.0474 107.532H79.1536C73.6655 107.532 68.9739 111.481 68.0352 116.888L64.2057 138.988H142.825L121.454 107.532H94.0474Z"
        fill="#00734D"
      />
      <path
        d="M96.9697 103.091C94.6048 104.337 91.538 103.962 89.4145 102.338C87.291 100.713 86.1356 97.9755 86.244 95.3036C86.3523 92.634 87.6544 90.0727 89.6063 88.2449C89.0625 86.2297 89.3942 84.2754 90.5677 82.5491C91.7411 80.8228 93.6886 79.6448 95.7624 79.4101C97.0487 76.6142 98.748 75.1676 101.783 74.9014C104.818 74.6351 108.481 75.7115 111.299 77.9185C111.665 75.8153 112.683 73.3465 113.682 71.4577C114.68 69.5712 116.42 67.9464 118.532 67.6463C120.646 67.3461 123.023 68.7408 123.318 70.8552C123.594 72.8117 122.185 74.5471 121.416 76.3659C119.906 79.9314 120.904 84.1603 123.023 87.4009C125.142 90.6414 128.242 93.0921 131.286 95.4819C135.797 99.0225 140.308 102.563 144.819 106.106C149.136 105.806 156.226 103.782 158.529 110.001C161.529 122.501 160.529 130.501 157.029 134.501C153.529 138.501 138.529 142.501 130.014 134.364C130.014 134.364 117.893 123.469 109.207 110.694C101.436 110.448 98.3373 105.167 96.9697 103.091Z"
        fill="#E09661"
      />
      <path
        d="M96.9698 103.43C96.8637 103.43 96.7599 103.38 96.6945 103.288C95.0155 100.943 93.9707 96.8629 93.9256 96.6892C93.8804 96.5086 93.9888 96.3236 94.1715 96.2785C94.3521 96.2333 94.5371 96.3417 94.5822 96.5244C94.5935 96.5651 95.6384 100.654 97.2451 102.895C97.3534 103.046 97.3196 103.258 97.1661 103.367C97.1074 103.409 97.0397 103.43 96.9698 103.43Z"
        fill="#1D232A"
      />
      <path
        d="M89.419 88.7639C89.331 88.7639 89.243 88.73 89.1753 88.6601C89.0444 88.5247 89.0489 88.3126 89.1821 88.1817C89.3062 88.0621 92.2692 85.2864 97.9401 88.1027C98.1071 88.1862 98.1748 88.3893 98.0935 88.5563C98.01 88.7233 97.807 88.791 97.64 88.7097C92.391 86.1033 89.6808 88.642 89.6537 88.6669C89.5883 88.7323 89.5048 88.7639 89.419 88.7639Z"
        fill="#1D232A"
      />
      <path
        d="M105.538 82.5333C105.482 82.5333 105.423 82.5198 105.369 82.4882C99.4656 79.0874 95.864 79.7351 95.8279 79.7419C95.6451 79.778 95.4668 79.6584 95.4307 79.4756C95.3946 79.2928 95.512 79.1145 95.6947 79.0784C95.8482 79.0468 99.5558 78.3585 105.705 81.9015C105.868 81.994 105.922 82.2016 105.829 82.3641C105.768 82.4724 105.656 82.5333 105.538 82.5333Z"
        fill="#1D232A"
      />
      <path
        d="M110.252 34.8799C109.688 38.583 106.226 41.1308 102.523 40.5666C98.8202 40.0024 96.2724 36.5408 96.8366 32.8376C97.315 29.6964 99.8763 27.3901 102.902 27.1035H102.905C103.446 27.0516 104.004 27.0674 104.566 27.1532C104.665 27.169 104.762 27.1847 104.859 27.2051C108.402 27.9024 110.801 31.276 110.252 34.8799Z"
        fill="#E09661"
      />
      <path
        d="M103.433 34.5955C102.016 43.9064 93.3162 50.3085 84.0031 48.8891C74.6922 47.4719 68.2902 38.7726 69.7096 29.4594C70.1 26.8891 71.0478 24.54 72.4085 22.518C75.9763 17.2104 82.3964 14.1391 89.1392 15.1637C90.5947 15.3848 91.9781 15.7842 93.2734 16.3371C97.385 18.0883 100.589 21.3784 102.286 25.3885C103.473 28.198 103.925 31.3573 103.433 34.5955Z"
        fill="#E09661"
      />
      <path
        d="M92.2692 31.0459C92.206 31.0526 92.1428 31.0594 92.0796 31.0639C92.0525 31.0662 92.0232 31.0684 91.9961 31.0707C91.9216 31.0775 91.8472 31.082 91.7749 31.0887C91.7343 31.0932 91.6937 31.0932 91.6553 31.0955C91.529 31.1045 91.4026 31.1113 91.2762 31.1136C91.1228 31.1181 90.9716 31.1226 90.8249 31.1203C88.4035 31.1181 86.5057 30.3982 86.2101 29.3015C86.2078 29.2992 86.2078 29.2947 86.2078 29.2925C86.2011 29.2676 86.1965 29.2451 86.1943 29.2225C86.1875 29.1909 86.1808 29.1593 86.1785 29.13C86.0386 27.8663 88.0154 26.5867 90.7369 26.0813C90.8791 26.0542 91.0212 26.0294 91.1657 26.009C91.3417 25.982 91.5222 25.9594 91.705 25.9391C91.7592 25.9323 91.8133 25.9278 91.8652 25.9233C91.9329 25.9165 92.0006 25.912 92.0683 25.9052C92.312 25.8849 92.5535 25.8714 92.7882 25.8646C95.5052 25.7902 97.6603 26.6071 97.7979 27.8527C97.9536 29.2631 95.4781 30.6938 92.2692 31.0459Z"
        fill="white"
      />
      <path
        d="M85.3526 29.2044C85.3436 29.2654 85.33 29.3286 85.3097 29.3895C84.9261 30.5629 82.7236 30.9285 80.1375 30.3463C80.0969 30.3373 80.0563 30.3282 80.0156 30.3192C79.9412 30.3012 79.8644 30.2831 79.7877 30.2628C79.6027 30.2177 79.4154 30.1635 79.2281 30.1071C79.1356 30.08 79.0453 30.0507 78.9528 30.0213C78.8918 30.001 78.8309 29.9807 78.7722 29.9604C78.6775 29.9288 78.5849 29.895 78.4947 29.8611C78.4653 29.8498 78.436 29.8408 78.4067 29.8273C76.9466 29.2834 75.7438 28.5365 74.9924 27.767C74.9924 27.7647 74.9901 27.7647 74.9879 27.7647C74.3628 27.1238 74.0468 26.4649 74.1574 25.8962C74.1619 25.8782 74.1642 25.8624 74.1687 25.8466C74.1755 25.8172 74.1845 25.7902 74.1935 25.7631C74.5659 24.619 76.6713 24.2421 79.1762 24.7679C79.2552 24.7837 79.3341 24.7995 79.4131 24.8198C79.4763 24.8356 79.5417 24.8491 79.6027 24.8649C79.7381 24.8988 79.8712 24.9326 80.0089 24.971C80.1872 25.0206 80.3654 25.0748 80.546 25.1357C80.6678 25.1763 80.7897 25.217 80.9093 25.2621C83.6488 26.237 85.5467 27.9272 85.3526 29.2044Z"
        fill="white"
      />
      <path
        d="M88.0876 35.4192C88.0696 35.4192 88.0538 35.4169 88.0357 35.4147L85.6279 35.0491C84.9193 34.9408 84.3032 34.5256 83.9399 33.9072C83.5766 33.2889 83.5111 32.5487 83.7594 31.8763L85.4157 27.4172C85.4812 27.2411 85.6753 27.1531 85.8513 27.2186C86.0273 27.284 86.1153 27.4781 86.0499 27.6541L84.3935 32.1132C84.2152 32.5939 84.2626 33.1219 84.5221 33.5642C84.7816 34.0065 85.2217 34.3044 85.7294 34.3811L88.1373 34.7467C88.3223 34.7738 88.4487 34.9476 88.4216 35.1326C88.3968 35.2996 88.2523 35.4192 88.0876 35.4192Z"
        fill="#1D232A"
      />
      <path
        d="M85.3526 29.2044C85.3435 29.2654 85.33 29.3285 85.3097 29.3895C84.9261 30.5629 82.7236 30.9285 80.1375 30.3463L80.9115 25.2576C83.6488 26.2369 85.5466 27.9272 85.3526 29.2044Z"
        fill="#1D232A"
      />
      <path
        d="M92.2692 31.0459C92.206 31.0526 92.1428 31.0594 92.0796 31.0639C92.0525 31.0662 92.0232 31.0684 91.9961 31.0707L92.7905 25.8601C95.5074 25.7856 97.6625 26.6025 97.8002 27.8482C97.9536 29.2631 95.4781 30.6938 92.2692 31.0459Z"
        fill="#1D232A"
      />
      <path
        d="M104.232 37.2403C104.123 37.2403 104.019 37.1884 103.954 37.0936C103.288 36.1323 103.049 34.8776 103.315 33.738C103.582 32.5984 104.351 31.5806 105.376 31.0142C105.538 30.924 105.746 30.9826 105.836 31.1474C105.926 31.3099 105.868 31.5175 105.703 31.6077C104.843 32.0839 104.198 32.9391 103.974 33.8937C103.751 34.8505 103.952 35.9021 104.511 36.71C104.617 36.8634 104.579 37.0756 104.426 37.1816C104.367 37.22 104.299 37.2403 104.232 37.2403Z"
        fill="#1D232A"
      />
      <path
        d="M91.6218 21.7505C92.0917 21.0922 91.4185 19.8059 90.118 18.8776C88.8176 17.9493 87.3825 17.7305 86.9125 18.3888C86.4426 19.0471 87.1158 20.3333 88.4162 21.2616C89.7167 22.1899 91.1518 22.4088 91.6218 21.7505Z"
        fill="#1D232A"
      />
      <path
        d="M80.4817 21.4919C82.0349 21.117 83.1402 20.1758 82.9504 19.3895C82.7606 18.6032 81.3477 18.2696 79.7945 18.6445C78.2413 19.0193 77.1361 19.9606 77.3258 20.7469C77.5156 21.5332 78.9285 21.8667 80.4817 21.4919Z"
        fill="#1D232A"
      />
      <path
        d="M85.6926 42.4588C86.8841 42.4588 88.2652 41.9488 89.3439 40.2789C89.4116 40.1751 89.3822 40.0352 89.2762 39.9675C89.1724 39.8998 89.0325 39.9291 88.9648 40.0352C86.9135 43.217 83.9889 41.6148 83.8648 41.5471C83.7565 41.4862 83.6188 41.5246 83.5579 41.6329C83.4969 41.7412 83.5353 41.8789 83.6436 41.9398C84.122 42.2106 84.8622 42.4588 85.6926 42.4588Z"
        fill="#1D232A"
      />
      <path
        d="M90.3865 40.8183C90.4858 40.8183 90.5761 40.7529 90.6031 40.6536C90.637 40.534 90.567 40.4098 90.4474 40.376C89.0415 39.9788 88.7098 39.1777 88.6963 39.1439C88.6511 39.0288 88.5203 38.9701 88.4052 39.0152C88.2901 39.0604 88.2314 39.189 88.2743 39.3041C88.2901 39.347 88.6827 40.3467 90.3256 40.8115C90.3436 40.8138 90.3662 40.8183 90.3865 40.8183Z"
        fill="#1D232A"
      />
      <path
        d="M91.371 15.6579C91.371 15.6579 90.0667 21.5161 97.8047 23.696C97.8047 23.696 98.0552 27.2818 102.902 27.1013C108.932 25.5894 103.78 15.4255 100.44 14.173C97.0984 12.9206 91.371 15.6579 91.371 15.6579Z"
        fill="#1D232A"
      />
      <path
        d="M82.103 56.248C82.103 56.248 81.8864 61.5263 88.0876 61.2262C90.7753 61.0975 96.3334 58.8883 99.7928 54.5578L82.103 56.248Z"
        fill="#E09661"
      />
      <path
        d="M74.004 109.48C73.8302 109.48 73.6813 109.347 73.6677 109.168C73.6519 108.981 73.7918 108.819 73.9769 108.803L86.5102 107.792L76.062 108.078C75.8815 108.09 75.719 107.936 75.7145 107.749C75.71 107.562 75.8567 107.406 76.044 107.401L99.2174 106.765C99.2196 106.765 99.2241 106.765 99.2264 106.765C99.4047 106.765 99.5536 106.905 99.5649 107.085C99.5739 107.268 99.4362 107.426 99.2535 107.442L74.031 109.48C74.022 109.48 74.013 109.48 74.004 109.48Z"
        fill="#1D232A"
      />
      <path d="M129.233 94.0579L135.703 98.9481L134.615 91.2823L129.233 94.0579Z" fill="#1D232A" />
      <path
        d="M126.496 88.0824C126.362 88.0824 126.238 88.0034 126.184 87.8747C126.112 87.7032 126.193 87.5047 126.365 87.4324L147.679 78.4962C147.85 78.424 148.049 78.5052 148.121 78.6767C148.193 78.8482 148.112 79.0468 147.94 79.119L126.626 88.0553C126.584 88.0756 126.538 88.0824 126.496 88.0824Z"
        fill="#1D232A"
      />
      <path
        d="M65.2098 88.0327C65.1376 88.0327 65.0632 88.0101 65.0022 87.9628L50.4311 76.657C50.2845 76.5419 50.2574 76.3298 50.3702 76.1809C50.4853 76.0342 50.6974 76.0071 50.8441 76.1199L65.4152 87.4257C65.5619 87.5408 65.589 87.7529 65.4761 87.9018C65.4107 87.9876 65.3091 88.0327 65.2098 88.0327Z"
        fill="#1D232A"
      />
      <path
        d="M66.2141 72.5364C66.038 72.5364 65.8914 72.401 65.8778 72.225L65.5168 67.635C65.5032 67.4477 65.6409 67.2852 65.8282 67.2717C66.0155 67.2581 66.1779 67.3958 66.1915 67.5831L66.5526 72.1731C66.5661 72.3604 66.4284 72.5229 66.2411 72.5364C66.2321 72.5364 66.2231 72.5364 66.2141 72.5364Z"
        fill="#1D232A"
      />
      <path
        d="M146.537 49.8798C145.921 50.3807 145.246 50.7824 144.535 51.0667C142.879 51.7279 141.051 51.7437 139.388 51.1096C138.273 50.6854 137.373 50.0061 136.779 49.1463C136.463 48.6883 136.235 48.1647 136.104 47.6276L135.382 47.4719C135.511 48.2008 135.797 48.9162 136.222 49.5322C136.892 50.5048 137.905 51.2698 139.149 51.7437C140.038 52.0822 140.972 52.2515 141.904 52.2515C142.881 52.2515 143.858 52.0664 144.786 51.6941C145.718 51.3217 146.593 50.7666 147.367 50.0603L146.537 49.8798ZM139.771 50.6199C141.423 51.2202 143.24 51.0261 144.772 50.1144C145.025 50.0671 145.273 50.0106 145.519 49.9384H145.607C145.697 49.9362 145.779 49.8978 145.839 49.8391C145.905 49.8166 145.968 49.794 146.031 49.7714L137.883 48.0135C138.18 48.2843 138.492 48.5348 138.815 48.7627C138.467 48.5506 138.138 48.2933 137.833 48.0022L136.366 47.684C137.264 49.0561 138.512 50.1618 139.771 50.6199ZM141.504 50.207C141.741 50.207 141.978 50.1867 142.215 50.1573C142.276 50.1663 142.339 50.1776 142.4 50.1867C142.475 50.1957 142.549 50.2002 142.624 50.207C141.845 50.3446 141.044 50.304 140.277 50.0716C140.681 50.1596 141.091 50.207 141.504 50.207Z"
        fill="#1D232A"
      />
      <path
        d="M232.487 53.9147L234.101 67.2694H249.965C251.145 67.2694 252.177 66.4706 252.472 65.3265L254.797 56.2999C254.918 55.8283 254.58 55.3634 254.095 55.3318L232.487 53.9147Z"
        fill="#00A769"
      />
      <path
        d="M249.965 67.6079H234.101C233.93 67.6079 233.785 67.4793 233.765 67.3101L232.151 53.9553C232.14 53.856 232.172 53.7544 232.242 53.6822C232.312 53.61 232.409 53.5694 232.51 53.5762L254.117 54.9933C254.449 55.0159 254.751 55.1829 254.948 55.4514C255.144 55.7222 255.207 56.0607 255.124 56.3834L252.8 65.41C252.468 66.7053 251.301 67.6079 249.965 67.6079ZM234.403 66.9309H249.967C250.994 66.9309 251.89 66.2359 252.147 65.2407L254.472 56.2142C254.503 56.0878 254.478 55.9547 254.402 55.8486C254.325 55.7425 254.205 55.6771 254.075 55.6681L232.876 54.278L234.403 66.9309Z"
        fill="#00A769"
      />
      <path
        d="M249.383 71.284H235.769C234.94 71.284 234.243 70.6634 234.146 69.842L233.767 67.3191C233.74 67.1341 233.866 66.9626 234.051 66.9332C234.234 66.9061 234.408 67.0325 234.437 67.2175L234.819 69.7518C234.877 70.2437 235.286 70.607 235.769 70.607H249.383C249.57 70.607 249.721 70.7582 249.721 70.9455C249.721 71.1328 249.57 71.284 249.383 71.284Z"
        fill="#00A769"
      />
      <path
        d="M232.487 54.2532C232.323 54.2532 232.178 54.1336 232.154 53.9666L231.833 51.8859L228.491 51.297C228.306 51.2654 228.184 51.0894 228.216 50.9043C228.247 50.7193 228.426 50.5974 228.608 50.629L232.19 51.2609C232.332 51.2857 232.442 51.3985 232.465 51.5429L232.824 53.8628C232.853 54.0478 232.727 54.2193 232.542 54.2486C232.521 54.2532 232.506 54.2532 232.487 54.2532Z"
        fill="#00A769"
      />
      <path
        d="M237.867 74.0732C236.913 74.0732 236.134 73.2969 236.134 72.3401C236.134 71.3833 236.91 70.607 237.867 70.607C238.824 70.607 239.6 71.3833 239.6 72.3401C239.6 73.2969 238.822 74.0732 237.867 74.0732ZM237.867 71.284C237.285 71.284 236.811 71.7579 236.811 72.3401C236.811 72.9223 237.285 73.3962 237.867 73.3962C238.45 73.3962 238.923 72.9223 238.923 72.3401C238.923 71.7579 238.45 71.284 237.867 71.284Z"
        fill="#00A769"
      />
      <path
        d="M246.488 74.0732C245.533 74.0732 244.755 73.2969 244.755 72.3401C244.755 71.3833 245.531 70.607 246.488 70.607C247.444 70.607 248.221 71.3833 248.221 72.3401C248.221 73.2969 247.442 74.0732 246.488 74.0732ZM246.488 71.284C245.905 71.284 245.432 71.7579 245.432 72.3401C245.432 72.9223 245.905 73.3962 246.488 73.3962C247.07 73.3962 247.544 72.9223 247.544 72.3401C247.544 71.7579 247.07 71.284 246.488 71.284Z"
        fill="#00A769"
      />
      <path
        d="M241 64.5141C240.99 64.5141 240.979 64.5141 240.97 64.5118C240.88 64.5028 240.799 64.4599 240.74 64.3922L238.154 61.2961C238.034 61.1517 238.052 60.9396 238.197 60.82C238.341 60.7004 238.553 60.7184 238.673 60.8628L241.042 63.6994L247.472 58.3286C247.616 58.209 247.828 58.2271 247.948 58.3715C248.067 58.5159 248.049 58.7281 247.905 58.8477L241.216 64.4351C241.155 64.487 241.078 64.5141 241 64.5141Z"
        fill="#1D232A"
      />
      <path
        d="M243.699 42.8572C244.119 42.8572 244.459 42.5167 244.459 42.0967C244.459 41.6767 244.119 41.3362 243.699 41.3362C243.279 41.3362 242.938 41.6767 242.938 42.0967C242.938 42.5167 243.279 42.8572 243.699 42.8572Z"
        fill="#1D232A"
      />
      <path
        d="M23.4463 72.9629C23.2861 72.9629 23.1416 72.8478 23.1146 72.683C23.0852 72.5138 22.3902 68.5173 23.347 64.1169C23.3876 63.9341 23.5682 63.819 23.7487 63.8573C23.9315 63.898 24.0466 64.0762 24.0082 64.259C23.083 68.5241 23.7735 72.5228 23.7803 72.5634C23.8141 72.7485 23.69 72.9223 23.5072 72.9561C23.4869 72.9606 23.4666 72.9629 23.4463 72.9629Z"
        fill="#1D232A"
      />
      <path
        d="M47.5968 86.8931C47.4659 86.8931 47.3418 86.8164 47.2854 86.69L44.8708 81.159C44.7963 80.9875 44.8753 80.7889 45.0446 80.7122C45.2161 80.6377 45.4146 80.7167 45.4891 80.8859L47.9037 86.4169C47.9782 86.5884 47.8992 86.787 47.73 86.8638C47.6871 86.8841 47.6419 86.8931 47.5968 86.8931Z"
        fill="#1D232A"
      />
      <path
        d="M156.303 12.0066C156.303 12.0066 157.54 10.5782 159.948 10.6007C161.898 10.6188 162.891 11.9863 162.096 14.0376C161.313 16.0573 158.998 17.3007 158.998 17.3007L156.303 12.0066Z"
        fill="#1D232A"
      />
      <path
        d="M129.107 20.5841C128.107 14.6266 129.677 8.73002 131.74 7.19325C134.173 5.38117 136.052 7.81833 136.052 7.81833C136.052 7.81833 138.343 2.70254 142.811 0.621923C147.279 -1.4587 148.793 2.179 147.758 4.24157C147.758 4.24157 153.557 -0.754625 158.407 1.71188C163.24 4.17161 156.942 13.7555 156.942 13.7555L129.107 20.5841Z"
        fill="#1D232A"
      />
      <path
        d="M150.784 40.8284C144.968 42.7985 138.83 41.4535 134.423 37.8113C132.067 35.8616 130.208 33.2574 129.154 30.15C128.312 27.6655 128.075 25.1222 128.364 22.6806H128.366C128.436 22.0735 128.54 21.4755 128.675 20.8843C128.739 20.6067 128.809 20.3314 128.885 20.0606C129.989 16.1499 132.471 12.6791 135.969 10.3954C137.147 9.62592 138.44 8.9918 139.835 8.52017C141.031 8.11623 142.238 7.84995 143.443 7.7168C148.748 7.12782 153.972 9.08433 157.592 12.8371C159.287 14.5905 160.627 16.7388 161.462 19.2008C164.486 28.1213 159.704 37.8045 150.784 40.8284Z"
        fill="#E09661"
      />
      <path
        d="M146.835 19.9545C146.841 20.0155 146.853 20.0786 146.868 20.1396C147.2 21.3288 149.385 21.7914 151.993 21.3243C152.034 21.3175 152.075 21.3108 152.117 21.3017C152.192 21.2859 152.269 21.2724 152.348 21.2543C152.535 21.216 152.724 21.1709 152.914 21.1235C153.007 21.1009 153.099 21.0761 153.192 21.049C153.253 21.0309 153.316 21.0129 153.374 20.9948C153.469 20.9678 153.564 20.9384 153.656 20.9091C153.686 20.9001 153.715 20.891 153.747 20.8797C155.229 20.3991 156.464 19.7063 157.249 18.9729C157.249 18.9706 157.251 18.9706 157.254 18.9706C157.906 18.3568 158.253 17.7137 158.165 17.1405C158.161 17.1224 158.161 17.1067 158.156 17.0886C158.149 17.0593 158.143 17.0322 158.136 17.0028C157.813 15.8429 155.728 15.3758 153.201 15.7888C153.122 15.8023 153.043 15.8136 152.961 15.8294C152.898 15.8407 152.833 15.852 152.77 15.8655C152.634 15.8926 152.497 15.9219 152.359 15.9535C152.178 15.9964 151.998 16.0415 151.815 16.0934C151.691 16.1295 151.567 16.1634 151.447 16.2017C148.667 17.0638 146.695 18.6682 146.835 19.9545Z"
        fill="white"
      />
      <path
        d="M159.932 16.3688L164.418 19.9049C165.635 20.864 165.662 22.6986 164.477 23.6938L162.238 25.5736"
        fill="#E09661"
      />
      <path
        d="M146.835 19.9545C146.841 20.0154 146.853 20.0786 146.868 20.1396C147.2 21.3288 149.385 21.7914 151.993 21.3243L151.445 16.2062C148.667 17.0638 146.695 18.6682 146.835 19.9545Z"
        fill="#1D232A"
      />
      <path
        d="M151.329 13.8976C152.615 12.9499 153.269 11.6538 152.789 11.0026C152.31 10.3514 150.878 10.5917 149.591 11.5393C148.305 12.487 147.651 13.7831 148.131 14.4343C148.611 15.0855 150.042 14.8452 151.329 13.8976Z"
        fill="#1D232A"
      />
      <path
        d="M159.084 27.9092C157.892 27.9092 156.511 27.3992 155.432 25.7293C155.365 25.6255 155.394 25.4856 155.5 25.4179C155.604 25.3502 155.744 25.3795 155.812 25.4856C157.863 28.6674 160.787 27.0652 160.912 26.9975C161.02 26.9366 161.158 26.9749 161.218 27.0833C161.279 27.1916 161.241 27.3292 161.133 27.3902C160.654 27.661 159.914 27.9092 159.084 27.9092Z"
        fill="#1D232A"
      />
      <path
        d="M154.39 26.2686C154.291 26.2686 154.2 26.2032 154.173 26.1039C154.139 25.9843 154.209 25.8602 154.329 25.8263C155.735 25.4291 156.067 24.628 156.08 24.5942C156.125 24.4791 156.256 24.4204 156.371 24.4656C156.486 24.5107 156.545 24.6393 156.502 24.7544C156.486 24.7973 156.094 25.797 154.451 26.2618C154.433 26.2641 154.41 26.2686 154.39 26.2686Z"
        fill="#1D232A"
      />
      <path
        d="M130.727 18.5193C130.727 18.5193 129.576 16.5696 127.588 16.38C125.979 16.2243 125.284 17.8942 126.125 20.6044C126.956 23.274 128.287 25.8511 128.287 25.8511L130.727 18.5193Z"
        fill="#1D232A"
      />
      <path
        d="M139.728 16.2085C139.532 19.7605 136.303 20.7714 136.303 20.7714L128.366 22.6805C128.436 22.0735 128.54 21.4755 128.675 20.8843C128.739 20.6067 128.903 20.0064 128.98 19.7356C130.084 15.8249 131.058 12.0879 134.556 9.80417C135.734 9.03466 138.063 7.52949 139.458 7.05785C140.654 6.65391 142.236 7.84993 143.441 7.71679C144.027 11.8487 142.321 15.3262 139.728 16.2085Z"
        fill="#1D232A"
      />
      <path
        d="M134.423 37.8113C132.067 35.8616 130.208 33.2574 129.154 30.15C128.102 27.0494 127.996 23.8563 128.675 20.8843C128.739 20.6067 134.482 18.0003 134.559 17.7295C137.966 21.5387 136.206 29.7461 136.56 34.8438C136.517 35.6923 136.086 37.2291 134.423 37.8113Z"
        fill="#1D232A"
      />
      <path
        d="M128.283 30.836C129.657 34.3203 133.597 36.033 137.084 34.6588C140.568 33.2845 142.281 29.3444 140.906 25.8601C139.74 22.9039 136.729 21.225 133.715 21.6199C133.175 21.6899 132.636 21.8275 132.106 22.0374C128.621 23.4117 126.909 27.3518 128.283 30.836Z"
        fill="#E09661"
      />
      <path d="M139.539 19.7646L132.626 21.8609L136.564 34.8487L143.477 32.7525L139.539 19.7646Z" fill="#E09661" />
      <path
        d="M135.222 31.4295C135.161 31.4295 135.1 31.4137 135.044 31.3799C134.883 31.2806 134.836 31.073 134.933 30.915C135.45 30.08 135.594 29.0172 135.319 28.0762C135.046 27.1329 134.355 26.3137 133.473 25.885C133.304 25.8037 133.236 25.6006 133.317 25.4336C133.399 25.2644 133.602 25.1944 133.769 25.2779C134.82 25.7902 135.644 26.7651 135.969 27.8889C136.294 29.0127 136.122 30.2764 135.509 31.2738C135.448 31.3708 135.337 31.4295 135.222 31.4295Z"
        fill="#1D232A"
      />
    </svg>
  )
}
