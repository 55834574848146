import styled from 'styled-components'

export const LogWrapper = styled.div`
  display: flex;
  gap: 2rem;
  width: 100%;

  div {
    flex: 1;
  }
`
