import { type AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'

type ResponseTransform = Record<string, any>

export const keysToSnakecase = (data: ResponseTransform) => data && snakecaseKeys(data)

export const transformResponse = (data: ResponseTransform) => {
  if (Object.keys(data).length) return camelcaseKeys(data, { deep: true })

  return data
}

export const responseSuccessInterceptor = (response: AxiosResponse) => {
  if (response.status === 204) {
    return { ...response, data: {} }
  }

  return response
}
