// TODO: [Web Platform Refactor] Fix this circular dependencie
// eslint-disable-next-line @nx/enforce-module-boundaries
import { analiticaInstance } from '~/domains/platform/lib/analitica/analitica.config'

import { type AnalyticsEvent } from './types'

export const experimentAnalytics = analiticaInstance.eventFactory('Experiment', {
  experiment: {
    /**
     * @analitica When the user clicks on the button or link
     */
    click: (event: AnalyticsEvent) => ({
      label: event?.event_name,
      metadata: {
        type: event?.type,
        ...event?.properties
      }
    }),

    /**
     * @analitica when the user views the component
     */

    view: (event: AnalyticsEvent) => ({
      label: event?.event_name,
      metadata: {
        type: event?.type,
        ...event?.properties
      }
    })
  }
})
