import { type ExperimentValue } from '@stone-payments/experiment/src/lib/types'

// TODO: [Web Platform Refactor] Fix this circular dependencie
// eslint-disable-next-line @nx/enforce-module-boundaries
import { experimentInstance } from '~/domains/platform/lib/analitica/analitica.config'
// TODO: [Web Platform Refactor] Fix this circular dependencie
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useQueryRequest } from '~/ui/hooks/utils/service/use-query-request'

export async function getAllExperiment(subjectId: string) {
  try {
    return await experimentInstance.fetch({ user_id: subjectId })
  } catch (error) {
    console.error(error)
  }
}

export const useAllExperiment = (subjectId: string) => {
  const {
    data: response,
    isLoading,
    ...restQuery
  } = useQueryRequest<ExperimentValue, unknown, true>(['useAllExperiment'], () => getAllExperiment(subjectId))

  return { data: response, isLoading, ...restQuery }
}
