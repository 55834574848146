import { APP_ENV, BANKING_API_ENDPOINT, DEPLOY_TYPE } from '~/lib/constants'

const PROD = 'prod'
const SDB = 'sdb'
const HML = 'hml'
const CY = 'cy'
const PREVIEW = 'preview'

/**
 * @disclaimer The calls to process env are inside the functions to improve testability only
 */

function isPoitingToHomolog(endpoint = BANKING_API_ENDPOINT()) {
  return endpoint?.includes('homolog')
}

function isProduction(environment = APP_ENV()) {
  return environment === PROD
}

function isLocal(environment = APP_ENV()) {
  return ![PROD, SDB, HML].includes(String(environment))
}

function isSandbox(environment = APP_ENV()) {
  return environment === SDB
}

function isHomolog(environment = APP_ENV()) {
  return environment === HML
}

function isCypress(environment = APP_ENV()) {
  return environment === CY
}

function isPreview(deployType = DEPLOY_TYPE()) {
  return deployType === PREVIEW
}

function getEnv() {
  return APP_ENV()
}

export const appEnv = { isLocal, isProduction, isSandbox, isHomolog, isPoitingToHomolog, isCypress, isPreview, getEnv }
