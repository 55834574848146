export function CoinNegativeError(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="136" height="136" viewBox="0 0 136 136" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_701_35186)">
        <g clipPath="url(#clip1_701_35186)">
          <path
            d="M112.025 61.0675C112.023 63.9475 111.755 66.765 111.26 69.49C111.078 70.5375 110.843 71.575 110.588 72.6025C110.26 73.9275 109.863 75.2325 109.41 76.5175C109.085 77.48 108.715 78.4275 108.305 79.365C108.08 79.9425 107.818 80.515 107.548 81.0825C107.118 81.985 106.678 82.8725 106.183 83.7525C105.888 84.29 105.595 84.815 105.27 85.335C97.0303 99.045 82.0203 108.218 64.8953 108.213L47.8278 108.21L47.6578 54.3275L47.5303 15.89L48.6403 15.9225C48.3703 16.545 48.6903 17.4475 49.5753 17.4475C50.0553 17.4475 50.5328 17.4375 51.0103 17.4375C50.5178 18.0475 50.7828 19.2175 51.7953 19.18L84.0578 17.93C84.7403 18.2375 85.4153 18.555 86.0878 18.8975C87.2503 19.475 88.3853 20.1 89.4703 20.7775C78.5478 21.305 67.6278 21.8375 56.7003 22.3725C55.3503 22.4375 55.3728 24.5425 56.7353 24.48C68.6203 23.8975 80.5203 23.3175 92.4128 22.7325C92.5303 22.8175 92.6553 22.91 92.7728 22.9975C102.063 29.7975 108.738 39.9325 111.103 51.64C111.705 54.6775 112.028 57.8325 112.025 61.0675Z"
            fill="#1D232A"
          />
          <path
            d="M91.9502 77.625C82.7527 101.988 55.5752 114.318 31.2452 105.17C24.8802 102.778 19.3477 99.1525 14.8002 94.67C10.7227 90.645 7.44519 85.935 5.08769 80.8L16.3202 72.2925C15.7352 70.645 15.2802 68.95 14.9577 67.2225L3.23269 76.105C2.90519 75.12 2.60769 74.1225 2.34519 73.1175C2.40019 73.0925 2.44769 73.0625 2.49769 73.025L14.2202 64.14L14.3852 61.4775C14.3852 61.4775 13.2852 62.325 13.0077 62.5375L1.83269 71C1.32269 68.64 0.997692 66.235 0.860192 63.8025C0.502692 57.5 1.41519 51.01 3.75769 44.71C3.78769 44.6375 3.81019 44.565 3.84019 44.4925C10.5027 26.845 26.5977 15.5125 44.2127 14.0775C45.3127 13.9875 46.4152 13.9375 47.5227 13.93L64.7952 13.9075L65.0252 13.905C69.0052 13.91 72.8802 14.405 76.5652 15.34C78.2402 15.765 79.8927 16.28 81.4827 16.875C82.3502 17.2025 83.2127 17.5575 84.0577 17.93L68.2952 18.54C70.1677 19.4325 71.9552 20.4375 73.6552 21.545C78.9277 21.2875 84.1977 21.0325 89.4702 20.7775C89.4852 20.78 89.4977 20.7925 89.5052 20.7975C90.5102 21.405 91.4802 22.0475 92.4127 22.7325C87.0952 22.995 81.7777 23.255 76.4602 23.515C81.7652 27.5475 86.0752 32.625 89.1977 38.33C95.5727 49.965 97.0102 64.22 91.9502 77.625Z"
            fill="#E8E9EA"
          />
          <path
            d="M25.0369 84.2696L25.0361 84.2688C21.6807 80.9627 19.1128 76.9817 17.4705 72.6285L17.4704 72.6283C17.0206 71.438 16.6362 70.2217 16.3284 68.9831L64.0989 32.8035C65.2163 33.4361 66.2855 34.1329 67.3026 34.8843C71.1156 37.7077 74.2006 41.3021 76.4217 45.3553C76.6825 45.8335 76.9337 46.3194 77.1728 46.8104L26.2306 85.3848C25.8226 85.0269 25.4239 84.6527 25.0369 84.2696Z"
            fill="#FCFCFC"
            stroke="#FCFCFC"
          />
          <path
            d="M3.23242 76.105C3.42492 76.6775 3.62742 77.2475 3.83992 77.81C4.15967 78.6599 4.50774 79.5027 4.87668 80.3324L5.28611 80.0223M3.23242 76.105L3.53435 76.5036L3.82198 76.2857M3.23242 76.105L14.5126 67.5595C14.5479 67.7412 14.5845 67.9225 14.6226 68.1034M3.23242 76.105L3.70635 75.9457C3.7445 76.0592 3.78304 76.1725 3.82198 76.2857M3.82198 76.2857C3.97764 76.7383 4.13954 77.1883 4.30766 77.6333L4.3079 77.634C4.61021 78.4375 4.93841 79.2353 5.28611 80.0223M3.82198 76.2857L14.6226 68.1034M5.28611 80.0223C5.35529 80.1788 5.42524 80.3349 5.49591 80.4906L16.3174 72.2925M5.28611 80.0223L15.7255 72.1137M15.7255 72.1137C15.7651 72.2293 15.8054 72.3447 15.8463 72.4598L16.3174 72.2925M15.7255 72.1137L16.0155 71.894L16.3174 72.2925M15.7255 72.1137C15.2763 70.8028 14.9091 69.4636 14.6226 68.1034M16.3174 72.2925C15.7942 70.8191 15.377 69.3077 15.064 67.7691L14.6226 68.1034"
            fill="#FCFCFC"
            stroke="#FCFCFC"
          />
          <path
            d="M45.8655 82.8425C44.128 82.8425 42.333 82.53 40.563 81.895C33.988 79.535 30.528 72.6075 30.978 67.275C30.9955 67.0675 31.1805 66.9175 31.383 66.9325C31.5905 66.95 31.743 67.1325 31.7255 67.3375C31.298 72.3875 34.583 78.95 40.818 81.1875C45.3055 82.8001 49.948 82.2351 53.233 79.6751C55.7305 77.73 56.9955 74.8875 56.703 71.8775C56.1555 66.245 52.7105 63.795 48.348 60.695L47.9555 60.415C43.598 57.3125 39.9805 54.3575 39.818 49.6025C39.7105 46.4925 41.118 43.635 43.6805 41.7675C46.728 39.545 50.963 39.0875 55.0105 40.54C59.833 42.2725 62.458 46.5575 63.3055 49.99C63.3555 50.19 63.233 50.395 63.0305 50.445C62.8305 50.4975 62.6255 50.3725 62.5755 50.17C61.773 46.925 59.298 42.8775 54.7555 41.2475C50.8855 39.8575 47.008 40.2675 44.1205 42.375C41.763 44.095 40.468 46.72 40.5655 49.5775C40.718 53.9975 44.0505 56.7125 48.3905 59.805L48.783 60.085C53.1205 63.17 56.868 65.835 57.448 71.8075C57.7655 75.08 56.398 78.165 53.693 80.27C51.508 81.9675 48.768 82.8425 45.8655 82.8425Z"
            fill="#1D232A"
          />
          <path
            d="M38.7305 86.56C38.688 86.56 38.6455 86.5525 38.6055 86.5375C38.4105 86.4675 38.308 86.2525 38.378 86.0575L56.2655 36.0425C56.3355 35.8475 56.5505 35.745 56.7455 35.815C56.9405 35.885 57.043 36.1 56.973 36.295L39.0855 86.31C39.028 86.465 38.8855 86.56 38.7305 86.56Z"
            fill="#1D232A"
          />
          <path
            d="M13.7357 63.8802L2.64313 72.2877C2.55281 71.9299 2.46814 71.5704 2.38879 71.2089L13.3097 62.9388C13.4075 62.8649 13.604 62.714 13.8182 62.5491L13.7357 63.8802Z"
            fill="#FCFCFC"
            stroke="#FCFCFC"
          />
          <path
            d="M24.763 85.6951C24.6705 85.6951 24.578 85.6601 24.5055 85.5926C20.693 81.9651 17.7405 77.4101 15.968 72.4176C13.7005 66.0426 13.3955 59.2126 15.0855 52.6676C15.378 51.5226 15.7555 50.3351 16.203 49.1376C17.598 45.4526 19.578 42.0901 22.0905 39.1426C22.2255 38.9851 22.4605 38.9651 22.618 39.1001C22.7755 39.2351 22.7955 39.4701 22.6605 39.6276C20.203 42.5101 18.268 45.7976 16.903 49.4026C16.4655 50.5726 16.098 51.7351 15.8105 52.8551C14.1605 59.2576 14.458 65.9351 16.673 72.1676C18.408 77.0501 21.293 81.5051 25.023 85.0501C25.173 85.1926 25.178 85.4301 25.0355 85.5801C24.9605 85.6576 24.863 85.6951 24.763 85.6951Z"
            fill="#1D232A"
          />
          <path
            d="M117.215 36.0125L112.435 22.7825L99.2026 18.0125L112.433 13.2325L117.203 0L121.983 13.23L135.215 18L121.985 22.78L117.215 36.0125Z"
            fill="#E8E9EA"
          />
          <path
            d="M106.602 55.045L103.525 46.525L95.0049 43.4525L103.525 40.375L106.597 31.8525L109.675 40.3725L118.195 43.445L109.675 46.5225L106.602 55.045Z"
            fill="#BBBDBF"
          />
        </g>
        <g filter="url(#filter0_d_701_35186)">
          <path
            d="M99.0352 130C114.499 130 127.035 117.464 127.035 102C127.035 86.536 114.499 74 99.0352 74C83.5712 74 71.0352 86.536 71.0352 102C71.0352 117.464 83.5712 130 99.0352 130Z"
            fill="#4A4F55"
          />
        </g>
        <path
          d="M112.47 111.278C113.617 112.427 113.617 114.287 112.47 115.435C111.896 116.008 111.144 116.295 110.391 116.295C109.639 116.295 108.887 116.008 108.313 115.435L99.0352 106.157L89.7552 115.435C89.1815 116.008 88.4291 116.295 87.6767 116.295C86.9244 116.295 86.172 116.008 85.5983 115.435C84.451 114.287 84.451 112.427 85.5983 111.278L94.8784 102L85.5983 92.72C84.451 91.5726 84.451 89.7105 85.5983 88.5632C86.7457 87.4158 88.6055 87.4158 89.7552 88.5632L99.0352 97.8432L108.313 88.5632C109.46 87.4158 111.322 87.4158 112.47 88.5632C113.617 89.7105 113.617 91.5726 112.47 92.72L103.192 102L112.47 111.278Z"
          fill="#FCFCFC"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_701_35186"
          x="68.0352"
          y="73"
          width="62"
          height="62"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.290196 0 0 0 0 0.309804 0 0 0 0 0.333333 0 0 0 0.32 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_701_35186" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_701_35186" result="shape" />
        </filter>
        <clipPath id="clip0_701_35186">
          <rect width="134.43" height="130" fill="white" transform="translate(0.785156)" />
        </clipPath>
        <clipPath id="clip1_701_35186">
          <rect width="134.43" height="108.213" fill="white" transform="translate(0.785156)" />
        </clipPath>
      </defs>
    </svg>
  )
}
