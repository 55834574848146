export function OnboardingIncomeMoneyIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="327" height="230" viewBox="0 0 327 230" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M40.3087 119.859C39.3023 114.149 45.0457 112.158 48.6091 115.143C49.8712 116.201 52.1955 118.667 55.8605 120.558C61.7908 123.62 71.137 122.871 72.5696 128.965C72.4614 128.771 62.6038 130.671 62.0891 130.681C60.3255 130.712 52.4741 131.532 50.8088 130.949C42.905 128.202 40.6824 121.974 40.3087 119.859Z"
        fill="#8F5049"
      />
      <path
        d="M22.0428 101.791V112.574H19.0268V132.443H74.8745V83.3181H46.9539V93.07H19.0268V101.791H22.0428Z"
        fill="#42EC9A"
      />
      <path d="M46.9505 112.057H49.9697V132.337H46.9505V112.057Z" fill="#1D232A" />
      <path d="M62.2072 112.573H77.8967V131.568H62.2072V112.573Z" fill="#42EC9A" />
      <path d="M62.2072 93.0651H77.8967V101.79H62.2072V93.0651Z" fill="#42EC9A" />
      <path d="M71.1162 83.8181H67.7922V131.34H71.1162V83.8181Z" fill="#FCFCFC" stroke="#FCFCFC" />
      <path d="M65.3169 83.8181H64.7303V131.34H65.3169V83.8181Z" fill="#FCFCFC" stroke="#FCFCFC" />
      <path d="M46.9501 92.0377H74.8772V93.0684H46.9501V92.0377Z" fill="#1D232A" />
      <path d="M22.0389 101.789H74.8772V102.82H22.0389V101.789Z" fill="#1D232A" />
      <path d="M22.0389 111.546H74.8772V112.576H22.0389V111.546Z" fill="#1D232A" />
      <path d="M19.0265 121.295H77.8967V122.326H19.0265V121.295Z" fill="#1D232A" />
      <path d="M47.9919 93.0696L48.0312 101.791H46.9494V135.876H40.9569L46.8675 93.0696H47.9919Z" fill="#1D232A" />
      <path
        d="M26.6685 87.2549C20.0956 89.0018 14.3457 93.9782 12.8114 100.082C10.9199 93.9581 6.51072 88.9985 0.000193227 87.2549C6.33042 85.5046 11.2281 80.2739 12.8114 73.3063C14.0604 80.3308 18.8729 85.5816 26.6685 87.2549Z"
        fill="#F1BD38"
      />
      <path
        d="M40.9583 80.3125C36.7884 81.4202 33.1627 84.5559 32.189 88.428C30.9892 84.5459 28.1732 81.4001 24.046 80.2924C28.0618 79.1813 31.1662 75.8648 32.1694 71.4473C33.438 75.8581 36.7753 79.1813 40.9583 80.3125Z"
        fill="#F1BD38"
      />
      <path d="M46.9505 93.0651H49.9697V101.79H46.9505V93.0651Z" fill="#1D232A" />
      <path
        d="M100.162 215.667C87.9215 230.676 73.4318 258.142 46.9176 246.154C31.8706 239.351 31.3493 222.173 34.1915 207.953C38.2336 187.723 47.4814 147.988 47.4814 147.988C38.5876 148.122 23.2226 144.009 14.8533 138.772C3.196 131.48 8.628 123.776 12.2898 123.04C16.4072 122.21 22.5965 125.011 26.0452 126.467C34.1784 129.903 41.8298 129.897 49.3926 129.442C56.4408 129.017 62.4301 129.308 70.3207 127.022C74.9693 125.677 89.8917 132.226 86.5774 147.604L82.696 168.697L123.293 185.403L100.162 215.667Z"
        fill="#8F5049"
      />
      <path
        d="M38.3202 144.157C28.4004 142.048 26.2662 141.49 22.8667 137.373C20.7457 138.822 17.8379 139.057 14.4089 138.491C14.4089 138.491 21.7456 143.37 29.8264 144.746C32.7768 145.248 35.2485 144.799 38.3202 144.157Z"
        fill="#1D232A"
      />
      <path
        d="M36.9325 145.2C25.9636 144.906 18.6892 137.671 16.4502 132.393C14.9061 128.755 14.1325 123.454 17.3812 121.286C23.1345 117.447 26.8847 124.291 31.943 128.728C35.2966 131.67 42.83 132.999 47.7047 132.477"
        fill="#8F5049"
      />
      <path
        d="M103.658 211.096L78.2417 190.558L83.7622 162.922L95.6523 147.768C100.599 141.58 108.139 134.251 130.303 132.819L146.92 155.522L103.658 211.096Z"
        fill="#00A868"
      />
      <path
        d="M332.624 51.6367V62.4194H335.64V82.2882H279.792V33.1635H307.713V42.9154H335.64V51.6367H332.624Z"
        fill="#42EC9A"
      />
      <path d="M307.716 61.9044H304.697V82.1847H307.716V61.9044Z" fill="#1D232A" />
      <path d="M292.459 62.4199H276.77V81.4151H292.459V62.4199Z" fill="#42EC9A" />
      <path d="M292.459 42.9122H276.77V51.6368H292.459V42.9122Z" fill="#42EC9A" />
      <path d="M283.551 33.6635H286.874V81.1858H283.551V33.6635Z" fill="#FCFCFC" stroke="#FCFCFC" />
      <path d="M289.35 33.6635H289.936V81.1858H289.35V33.6635Z" fill="#FCFCFC" stroke="#FCFCFC" />
      <path d="M307.716 41.8856H279.789V42.9163H307.716V41.8856Z" fill="#1D232A" />
      <path d="M332.628 51.6371H279.789V52.6679H332.628V51.6371Z" fill="#1D232A" />
      <path d="M332.628 61.3935H279.789V62.4243H332.628V61.3935Z" fill="#1D232A" />
      <path d="M335.64 71.14H276.77V72.1707H335.64V71.14Z" fill="#1D232A" />
      <path d="M306.675 42.9149L306.635 51.6361H307.717V85.7212H313.71L307.799 42.9149H306.675Z" fill="#1D232A" />
      <path
        d="M313.708 30.158C317.878 31.2658 321.504 34.4015 322.477 38.2735C323.677 34.3915 326.493 31.2457 330.621 30.138C326.605 29.0269 323.5 25.7104 322.497 21.2929C321.228 25.7037 317.891 29.0269 313.708 30.158Z"
        fill="#F1BD38"
      />
      <path d="M307.716 42.9122H304.697V51.6368H307.716V42.9122Z" fill="#1D232A" />
      <path
        d="M61.1321 130.427C60.9682 130.427 60.8076 130.343 60.7158 130.193C60.5715 129.958 60.6404 129.647 60.8699 129.5C61.0207 129.403 64.666 127.1 70.2718 126.525C70.5406 126.498 70.7832 126.699 70.8094 126.973C70.8356 127.248 70.6389 127.495 70.3701 127.522C65.007 128.071 61.4272 130.326 61.3911 130.346C61.3124 130.403 61.2206 130.427 61.1321 130.427Z"
        fill="#1D232A"
      />
      <path
        d="M75.5296 204.367C75.4968 204.367 75.464 204.363 75.4279 204.357C75.1624 204.3 74.9919 204.035 75.0477 203.764L77.762 190.458C77.8178 190.187 78.0767 190.013 78.3423 190.07C78.6078 190.127 78.7783 190.391 78.7226 190.662L76.0082 203.969C75.9623 204.206 75.7558 204.367 75.5296 204.367Z"
        fill="#1D232A"
      />
      <path
        d="M314.299 198.497C329.517 188.417 331.438 171.131 324.629 152.049C317.705 132.649 304.707 100.12 304.707 100.12C313.598 100.261 328.169 94.7153 336.919 90.1706C349.104 83.8422 344.285 75.7234 340.692 74.6926C336.65 73.5347 330.264 75.8238 326.714 76.9984C310.539 82.3463 295.875 82.0786 279.012 79.8497C272.24 78.9528 263.244 83.8054 266.798 99.1027L279.399 143.284L248.915 177.042C276.963 201.78 295.367 211.04 314.299 198.497Z"
        fill="#8F5049"
      />
      <path
        d="M313.342 94.8687C323.964 92.0576 329.451 83.333 330.468 77.6773C331.169 73.7785 330.438 69.1066 327.104 67.0719C324.682 65.5893 321.013 65.5258 318.784 68.8322C316.683 71.9479 315.837 73.668 313.339 76.4624C308.831 81.5091 299.587 82.3424 292.391 79.7689"
        fill="#8F5049"
      />
      <path
        d="M313.341 95.375C313.121 95.375 312.921 95.2244 312.865 95.0002C312.796 94.7325 312.954 94.458 313.219 94.3877C319.018 92.8517 328.273 87.1122 329.987 77.5912C330.037 77.3201 330.296 77.1394 330.561 77.1896C330.827 77.2398 331.004 77.5008 330.955 77.7752C329.971 83.2436 324.5 92.4434 313.468 95.3616C313.423 95.3683 313.383 95.375 313.341 95.375Z"
        fill="#1D232A"
      />
      <path
        d="M254.517 182.316L280.258 146.236L279.399 143.288L253.18 126.354C235.382 116.12 220.538 123.837 204.947 125.655L205.767 145.477L252.236 185.452L254.517 182.316Z"
        fill="#00A868"
      />
      <path
        d="M287.028 170.686C286.812 170.686 286.615 170.539 286.556 170.318L279.144 143.019C279.072 142.752 279.226 142.477 279.485 142.4C279.747 142.327 280.016 142.484 280.092 142.748L287.504 170.047C287.576 170.314 287.422 170.589 287.163 170.666C287.117 170.679 287.074 170.686 287.028 170.686Z"
        fill="#1D232A"
      />
      <path
        d="M266.886 99.443C264.43 91.7961 266.112 83.9617 272.423 79.8387C276.029 77.4827 282.602 76.7765 287.762 78.3394C292.921 79.8989 300.268 81.8332 308.89 79.6379L307.28 91.7023L266.886 99.443Z"
        fill="#8F5049"
      />
      <path
        d="M171.697 90.1148C198.89 89.251 220.333 68.8547 219.593 44.5583C218.852 20.262 196.208 1.26614 169.015 2.12991C141.822 2.99368 120.378 23.39 121.119 47.6863C121.859 71.9827 144.504 90.9785 171.697 90.1148Z"
        fill="#1D232A"
      />
      <path
        d="M199.08 87.7632C200.132 87.9841 201.181 88.0778 202.21 88.0544C202.578 88.0443 202.938 88.0209 203.296 87.9808C203.394 87.9741 203.492 87.9607 203.591 87.9473C203.971 87.8971 204.348 87.8335 204.722 87.7498C204.833 87.7264 204.941 87.703 205.053 87.6729C205.308 87.6126 205.564 87.5424 205.82 87.462C210.495 86.023 214.271 82.0807 215.33 76.8533C216.867 69.2599 212.081 61.8338 204.643 60.2642C197.205 58.698 189.934 63.5807 188.393 71.1741C186.859 78.7676 191.642 86.197 199.08 87.7632Z"
        fill="#8F5049"
      />
      <path
        d="M200.607 77.875C201.332 80.0536 202.056 82.2323 202.784 84.4076C202.332 83.4069 201.896 82.3996 201.483 81.3822C201.296 80.9171 200.561 81.0944 200.725 81.5931C201.165 82.9217 201.561 84.267 201.919 85.6224C201.833 85.4651 201.745 85.3044 201.656 85.1505C201.401 84.7054 200.722 85.107 200.978 85.5521C201.44 86.362 201.853 87.1953 202.214 88.0554C202.581 88.0453 202.941 88.0219 203.299 87.9817C203.21 87.5734 203.112 87.1685 203.017 86.7636C203.207 87.1585 203.397 87.5534 203.594 87.9483C203.974 87.8981 204.351 87.8345 204.725 87.7508C204.387 86.7435 204.053 85.7362 203.718 84.7288C204.164 85.7127 204.607 86.6899 205.056 87.6738C205.311 87.6136 205.567 87.5433 205.823 87.463C204.325 84.1633 202.827 80.8669 201.328 77.5671C201.135 77.1555 200.466 77.45 200.607 77.875Z"
        fill="#1D232A"
      />
      <path
        d="M204.475 78.8425C204.455 78.8425 204.436 78.8425 204.416 78.8392C204.147 78.8057 203.954 78.5581 203.987 78.2803C204.331 75.3353 205.475 72.5041 207.301 70.1012C207.468 69.8803 207.776 69.8435 207.989 70.0108C208.202 70.1815 208.242 70.4961 208.078 70.7136C206.363 72.9759 205.285 75.6331 204.963 78.3974C204.934 78.6551 204.721 78.8425 204.475 78.8425Z"
        fill="#1D232A"
      />
      <path
        d="M143.19 92.5855C150.785 92.5855 156.942 86.3 156.942 78.5465C156.942 70.793 150.785 64.5075 143.19 64.5075C135.595 64.5075 129.438 70.793 129.438 78.5465C129.438 86.3 135.595 92.5855 143.19 92.5855Z"
        fill="#8F5049"
      />
      <path
        d="M140.638 82.8402C140.389 82.8402 140.176 82.6495 140.15 82.3918C139.822 79.3096 138.406 76.4449 136.367 74.7247C136.157 74.5474 136.127 74.2328 136.301 74.0186C136.475 73.8044 136.783 73.7743 136.993 73.9517C139.225 75.8358 140.769 78.9515 141.127 82.2847C141.156 82.5591 140.963 82.8068 140.691 82.8369C140.674 82.8402 140.655 82.8402 140.638 82.8402Z"
        fill="#1D232A"
      />
      <path d="M135.678 59.0961L144.228 76.3211L143.963 59.4107L135.678 59.0961Z" fill="#1D232A" />
      <path d="M207.862 56.6402L200.371 74.3705L199.614 57.4768L207.862 56.6402Z" fill="#1D232A" />
      <path
        d="M174.254 118.586C190.271 117.836 202.663 103.968 201.928 87.6162L201.234 62.4398C200.499 46.0883 186.914 33.4382 170.897 34.1878C154.88 34.9374 142.488 48.8057 143.222 65.1572L143.917 90.3337C144.651 106.688 158.233 119.335 174.254 118.586Z"
        fill="#8F5049"
      />
      <path
        d="M174.201 108.781C173.929 108.781 173.709 108.557 173.709 108.279C173.709 106.943 172.644 105.856 171.336 105.856C171.064 105.856 170.844 105.632 170.844 105.354C170.844 105.076 171.064 104.852 171.336 104.852C173.188 104.852 174.693 106.388 174.693 108.279C174.693 108.557 174.473 108.781 174.201 108.781Z"
        fill="#1D232A"
      />
      <path
        d="M193.418 69.8615L193.418 69.8609C194.322 68.9414 194.878 67.6741 194.878 66.2658C194.878 64.507 194.008 62.9653 192.687 62.0486L193.418 69.8615ZM193.418 69.8615C193.196 70.0878 192.952 70.2923 192.686 70.4807C191.89 71.0273 190.934 71.3479 189.907 71.3479H177.994C175.257 71.3479 173.022 69.0814 173.022 66.2658C173.022 64.8571 173.578 63.5866 174.482 62.6673L174.482 62.6667M193.418 69.8615L174.482 62.6667M174.482 62.6667C175.384 61.7468 176.624 61.1803 177.994 61.1803M174.482 62.6667L177.994 61.1803M177.994 61.1803H189.907M177.994 61.1803H189.907M189.907 61.1803C190.936 61.1803 191.893 61.4988 192.687 62.0485L189.907 61.1803Z"
        fill="#FCFCFC"
        stroke="#FCFCFC"
      />
      <path
        d="M195.378 66.2652C195.378 67.808 194.768 69.2002 193.775 70.2109C193.529 70.4619 193.26 70.6861 192.972 70.8869C192.224 71.4056 191.346 71.7369 190.395 71.8239C190.234 71.8373 190.07 71.8474 189.906 71.8474H184.658V60.6831H189.906C190.07 60.6831 190.234 60.6931 190.395 60.7065C191.342 60.7902 192.224 61.1215 192.972 61.6402C194.424 62.6409 195.378 64.3376 195.378 66.2652Z"
        fill="#1D232A"
      />
      <path d="M174.4 65.9804L177.721 75.8094C178.387 77.7838 176.951 79.8387 174.905 79.8387H167.165" fill="#8F5049" />
      <path
        d="M174.905 80.344H167.165C166.893 80.344 166.674 80.1198 166.674 79.842C166.674 79.5642 166.893 79.34 167.165 79.34H174.905C175.718 79.34 176.456 78.9518 176.928 78.2758C177.4 77.5998 177.518 76.7598 177.252 75.9767L173.932 66.1478C173.843 65.8867 173.981 65.5989 174.236 65.5085C174.492 65.4182 174.774 65.5588 174.863 65.8198L178.183 75.6487C178.554 76.7464 178.387 77.9144 177.728 78.8581C177.069 79.8019 176.039 80.344 174.905 80.344Z"
        fill="#1D232A"
      />
      <path
        d="M173.068 69.8609L173.067 69.8615C172.845 70.0885 172.6 70.2928 172.336 70.477C171.658 70.947 170.863 71.2471 170.001 71.3264C169.848 71.3391 169.7 71.3479 169.556 71.3479H157.643C154.906 71.3479 152.672 69.0814 152.672 66.2658C152.672 64.8571 153.228 63.5866 154.131 62.6673C155.036 61.7464 156.274 61.1803 157.643 61.1803H169.556C169.7 61.1803 169.848 61.1891 170.002 61.2019C170.86 61.2779 171.658 61.578 172.336 62.0482L172.337 62.0487C173.657 62.9616 174.527 64.5063 174.527 66.2658C174.527 67.6741 173.971 68.9414 173.068 69.8609Z"
        fill="#FCFCFC"
        stroke="#FCFCFC"
      />
      <path
        d="M175.027 66.2652C175.027 67.808 174.418 69.2002 173.424 70.2109C173.178 70.4619 172.91 70.6861 172.621 70.8869C171.874 71.4056 170.995 71.7369 170.045 71.8239C169.884 71.8373 169.72 71.8474 169.556 71.8474H164.308V60.6831H169.556C169.72 60.6831 169.884 60.6931 170.045 60.7065C170.992 60.7902 171.874 61.1215 172.621 61.6402C174.073 62.6409 175.027 64.3376 175.027 66.2652Z"
        fill="#1D232A"
      />
      <path
        d="M177.289 50.9245C176.719 50.9245 176.142 50.7472 175.647 50.3757C174.404 49.4487 174.132 47.6683 175.04 46.3999C177.453 43.0299 181.734 43.5219 183.885 44.3451C185.327 44.8973 186.055 46.5371 185.514 48.0063C184.976 49.4721 183.38 50.2151 181.944 49.6796C181.459 49.5056 179.941 49.191 179.534 49.7566C178.997 50.5196 178.148 50.9245 177.289 50.9245Z"
        fill="#1D232A"
      />
      <path
        d="M162.55 61.4038C161.39 61.4038 159.957 61.0759 158.397 59.9849C157.128 59.098 156.8 57.3277 157.672 56.0292C158.541 54.7341 160.275 54.3994 161.547 55.2896C161.878 55.5205 162.347 55.7816 162.694 55.6979C163.202 55.5774 163.711 54.8679 163.76 54.7776C164.517 53.4088 166.215 52.9236 167.556 53.6966C168.897 54.4663 169.372 56.2032 168.615 57.572C168.448 57.8732 166.904 60.5404 163.956 61.2399C163.553 61.3369 163.081 61.4038 162.55 61.4038Z"
        fill="#1D232A"
      />
      <path
        d="M134.078 55.0902L134.078 55.0899C134.08 55.0908 134.08 55.0911 134.08 55.0911C134.08 55.0911 134.08 55.0909 134.079 55.0906L134.075 55.0973L134.475 55.371M134.078 55.0902L134.059 55.0926C134.06 55.0923 134.06 55.0917 134.061 55.091L134.068 55.0958L134.072 55.0984L134.068 55.1039L133.79 55.4982M134.078 55.0902L134.074 55.0967L134.078 55.0902ZM134.475 55.371L134.073 55.0991L134.068 55.1059L133.79 55.4982M134.475 55.371C134.377 55.5149 134.226 55.5885 134.068 55.5885C133.97 55.5885 133.875 55.5584 133.79 55.4982M134.475 55.371C134.629 55.1434 134.573 54.8289 134.35 54.6716L133.79 55.4982"
        fill="#FCFCFC"
        stroke="#FCFCFC"
      />
      <mask id="path-60-inside-1_1681_43712" fill="white">
        <path d="M133.276 47.8399C133.112 47.8399 132.951 47.7562 132.856 47.6023C130.545 43.7938 132.761 40.0523 132.856 39.8951C133 39.6608 133.302 39.5872 133.531 39.7311C133.761 39.8783 133.833 40.1862 133.692 40.4205C133.61 40.5577 131.709 43.7972 133.692 47.0702C133.836 47.3044 133.764 47.6157 133.531 47.7596C133.453 47.8165 133.364 47.8399 133.276 47.8399Z" />
      </mask>
      <path
        d="M133.276 47.8399C133.112 47.8399 132.951 47.7562 132.856 47.6023C130.545 43.7938 132.761 40.0523 132.856 39.8951C133 39.6608 133.302 39.5872 133.531 39.7311C133.761 39.8783 133.833 40.1862 133.692 40.4205C133.61 40.5577 131.709 43.7972 133.692 47.0702C133.836 47.3044 133.764 47.6157 133.531 47.7596C133.453 47.8165 133.364 47.8399 133.276 47.8399Z"
        fill="#FCFCFC"
      />
      <path
        d="M132.856 47.6023L132.001 48.1211L132.005 48.1277L132.856 47.6023ZM132.856 39.8951L132.004 39.3707L132 39.3778L132.856 39.8951ZM133.531 39.7311L134.071 38.8894L134.063 38.8839L133.531 39.7311ZM133.692 40.4205L132.835 39.9049L132.833 39.9077L133.692 40.4205ZM133.692 47.0702L132.837 47.5884L132.84 47.5945L133.692 47.0702ZM133.531 47.7596L133.005 46.909L132.975 46.928L132.945 46.9492L133.531 47.7596ZM133.276 46.8399C133.454 46.8399 133.617 46.9318 133.707 47.0768L132.005 48.1277C132.285 48.5807 132.77 48.8399 133.276 48.8399V46.8399ZM133.711 47.0835C131.723 43.8073 133.631 40.5462 133.712 40.4123L132 39.3778C131.891 39.5585 129.367 43.7804 132.001 48.1211L133.711 47.0835ZM133.708 40.4194C133.57 40.6432 133.256 40.7387 133 40.5783L134.063 38.8839C133.348 38.4356 132.431 38.6784 132.004 39.3707L133.708 40.4194ZM132.991 40.5727C132.765 40.4277 132.697 40.1342 132.835 39.9049L134.549 40.9361C134.969 40.2382 134.756 39.3289 134.071 38.8894L132.991 40.5727ZM132.833 39.9077C132.739 40.065 130.532 43.7846 132.837 47.5884L134.547 46.5519C132.886 43.8098 134.481 41.0504 134.55 40.9333L132.833 39.9077ZM132.84 47.5945C132.699 47.3656 132.763 47.0591 133.005 46.909L134.057 48.6101C134.766 48.1722 134.973 47.2433 134.543 46.5459L132.84 47.5945ZM132.945 46.9492C133.06 46.8667 133.183 46.8399 133.276 46.8399V48.8399C133.545 48.8399 133.846 48.7663 134.117 48.5699L132.945 46.9492Z"
        fill="#FCFCFC"
        mask="url(#path-60-inside-1_1681_43712)"
      />
      <path
        d="M170.113 87.7409C167.53 87.7409 165.153 86.6934 163.012 84.6051C162.816 84.4143 162.809 84.0964 162.996 83.8956C163.183 83.6948 163.494 83.6881 163.691 83.8789C166.517 86.6331 169.795 87.3962 173.434 86.1412C173.693 86.0542 173.971 86.1947 174.057 86.4558C174.142 86.7168 174.004 87.0046 173.748 87.0916C172.496 87.5267 171.283 87.7409 170.113 87.7409Z"
        fill="#1D232A"
      />
      <path
        d="M254.809 230.871L105.693 236.871L104.388 209.817L102.241 155.807C103.457 137.772 113.938 134.251 130.306 132.819C134.778 132.427 139.213 132.25 143.593 131.939C161.911 130.633 179.342 129.024 195.431 126.959C205.807 125.624 215.625 124.098 224.774 122.341C234.235 122.341 246.027 141.537 246.027 151.192L254.809 230.871Z"
        fill="#00A868"
      />
      <path
        d="M195.426 126.959C194.246 133.09 190.001 149.95 172.521 152.902C152.193 156.332 145.345 138.609 143.588 131.939C145.922 131.772 148.236 131.601 150.541 131.424C150.925 131.397 151.308 131.363 151.692 131.333C152.252 131.29 152.816 131.25 153.377 131.199C162.769 130.46 171.892 129.633 180.691 128.686C180.95 128.663 181.209 128.633 181.468 128.602C181.907 128.556 182.346 128.509 182.782 128.458C187.08 127.987 191.299 127.485 195.426 126.959Z"
        fill="#8F5049"
      />
      <path
        d="M104.571 214.118C104.309 214.118 104.089 213.907 104.079 213.636L102.965 185.294C102.955 185.016 103.165 184.782 103.437 184.772C103.709 184.765 103.938 184.976 103.948 185.253L105.063 213.596C105.073 213.873 104.863 214.108 104.591 214.118C104.584 214.118 104.578 214.118 104.571 214.118Z"
        fill="#1D232A"
      />
      <path d="M246.513 158.642L249.545 183.139L252.236 185.451L256.406 179.719L246.513 158.642Z" fill="#1D232A" />
      <path
        d="M256.721 177.064C256.551 177.064 256.387 176.974 256.295 176.813L246.09 158.892C245.952 158.651 246.034 158.346 246.267 158.206C246.503 158.065 246.801 158.149 246.939 158.387L257.144 176.308C257.282 176.549 257.2 176.853 256.967 176.994C256.888 177.041 256.803 177.064 256.721 177.064Z"
        fill="#1D232A"
      />
      <path
        d="M181.469 128.602C180.905 130.804 179.21 132.832 176.794 134.341C179.092 132.501 180.187 130.221 180.689 128.685C171.89 129.632 162.767 130.462 153.375 131.199C156.614 137.982 162.282 140.372 167.337 140.372C168.248 140.372 169.136 140.295 169.985 140.151C171.513 139.896 172.965 139.438 174.306 138.816C172.572 139.82 170.615 140.536 168.523 140.877C165.77 141.325 156.607 141.908 151.69 131.333C151.306 131.363 150.923 131.396 150.539 131.423C154.093 139.384 160.426 142.186 166.022 142.186C166.937 142.186 167.835 142.112 168.694 141.971C176.086 140.767 181.908 135.141 182.784 128.458C182.348 128.508 181.908 128.555 181.469 128.602ZM169.815 139.063C164.514 139.953 159.82 138.186 156.584 134.251C157.925 135.442 159.482 136.379 161.233 137.025C163.101 137.714 165.18 138.063 167.31 138.063C168.576 138.063 169.858 137.939 171.123 137.691C173.923 137.142 176.388 136.075 178.322 134.662C176.191 136.878 173.198 138.494 169.815 139.063Z"
        fill="#1D232A"
      />
      <path
        d="M58.8432 150.305C43.534 159.619 31.0833 149.693 28.7984 146.684C26.6839 143.9 25.3661 138.519 27.6117 135.477C29.3163 133.168 34.4205 130.889 40.2656 136.728C42.1735 138.706 45.4321 140.232 50.3822 137.351"
        fill="#8F5049"
      />
      <path
        d="M27.0583 143.736C26.855 143.736 26.6616 143.605 26.5928 143.398C25.5109 140.175 25.7404 137.176 27.2189 135.175C28.0647 134.03 29.8218 132.739 32.2149 132.555C34.1556 132.407 37.1814 132.946 40.6071 136.37C42.3282 138.153 45.3704 139.689 50.1369 136.915C50.3729 136.778 50.6745 136.862 50.8089 137.103C50.9433 137.344 50.8614 137.651 50.6254 137.789C45.3146 140.878 41.8692 139.111 39.9121 137.082C36.7552 133.927 34.0245 133.425 32.287 133.555C30.2217 133.712 28.717 134.807 28.0024 135.777C26.7239 137.511 26.5469 140.168 27.5205 143.073C27.609 143.334 27.4713 143.622 27.2156 143.712C27.1664 143.725 27.1107 143.736 27.0583 143.736Z"
        fill="#1D232A"
      />
      <path
        d="M25.7838 143.26C25.7018 143.26 25.6166 143.24 25.5379 143.193C16.4802 137.872 15.1066 129.864 15.0509 129.526C15.0083 129.251 15.1886 128.994 15.4574 128.95C15.7262 128.907 15.9786 129.091 16.0213 129.365C16.0344 129.442 17.3916 137.25 26.0264 142.323C26.2624 142.46 26.3444 142.768 26.2067 143.009C26.1182 143.17 25.9543 143.26 25.7838 143.26Z"
        fill="#1D232A"
      />
      <path
        d="M47.8718 154.723C47.026 154.723 46.5474 154.699 46.5244 154.699C46.2524 154.686 46.0458 154.448 46.0589 154.171C46.0721 153.893 46.2983 153.682 46.5769 153.695C46.6949 153.702 58.4998 154.268 70.144 149.482C70.3964 149.378 70.6816 149.502 70.7832 149.76C70.8849 150.017 70.7636 150.309 70.5111 150.412C60.9781 154.331 51.3434 154.723 47.8718 154.723Z"
        fill="#1D232A"
      />
      <path
        d="M45.1124 133.448C36.648 133.448 31.8389 129.298 31.6258 129.111C31.4193 128.93 31.3963 128.612 31.5733 128.405C31.7504 128.194 32.0618 128.171 32.265 128.351C32.3175 128.398 37.7364 133.05 46.9678 132.377C47.2399 132.357 47.4727 132.565 47.4923 132.842C47.512 133.12 47.3088 133.358 47.0367 133.378C46.3778 133.425 45.7352 133.448 45.1124 133.448Z"
        fill="#1D232A"
      />
    </svg>
  )
}
