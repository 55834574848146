export const TopupPollingIllustration = () => {
  return (
    <svg width="136" height="135" viewBox="0 0 136 135" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3299_8656)">
        <path
          d="M112.025 61.0675C112.023 63.9475 111.755 66.765 111.26 69.49C111.078 70.5375 110.843 71.575 110.588 72.6025C110.26 73.9275 109.863 75.2325 109.41 76.5175C109.085 77.48 108.715 78.4275 108.305 79.365C108.08 79.9425 107.818 80.515 107.548 81.0825C107.118 81.985 106.678 82.8725 106.183 83.7525C105.888 84.29 105.595 84.815 105.27 85.335C97.0303 99.045 82.0203 108.218 64.8953 108.213L47.8278 108.21L47.6578 54.3275L47.5303 15.89L48.6403 15.9225C48.3703 16.545 48.6903 17.4475 49.5753 17.4475C50.0553 17.4475 50.5328 17.4375 51.0103 17.4375C50.5178 18.0475 50.7828 19.2175 51.7953 19.18L84.0578 17.93C84.7403 18.2375 85.4153 18.555 86.0878 18.8975C87.2503 19.475 88.3853 20.1 89.4703 20.7775C78.5478 21.305 67.6278 21.8375 56.7003 22.3725C55.3503 22.4375 55.3728 24.5425 56.7353 24.48C68.6203 23.8975 80.5203 23.3175 92.4128 22.7325C92.5303 22.8175 92.6553 22.91 92.7728 22.9975C102.063 29.7975 108.738 39.9325 111.103 51.64C111.705 54.6775 112.028 57.8325 112.025 61.0675Z"
          fill="#1D232A"
        />
        <path
          d="M91.9502 77.625C82.7527 101.988 55.5752 114.318 31.2452 105.17C24.8802 102.778 19.3477 99.1525 14.8002 94.67C10.7227 90.645 7.44519 85.935 5.08769 80.8L16.3202 72.2925C15.7352 70.645 15.2802 68.95 14.9577 67.2225L3.23269 76.105C2.90519 75.12 2.60769 74.1225 2.34519 73.1175C2.40019 73.0925 2.44769 73.0625 2.49769 73.025L14.2202 64.14L14.3852 61.4775C14.3852 61.4775 13.2852 62.325 13.0077 62.5375L1.83269 71C1.32269 68.64 0.997692 66.235 0.860192 63.8025C0.502692 57.5 1.41519 51.01 3.75769 44.71C3.78769 44.6375 3.81019 44.565 3.84019 44.4925C10.5027 26.845 26.5977 15.5125 44.2127 14.0775C45.3127 13.9875 46.4152 13.9375 47.5227 13.93L64.7952 13.9075L65.0252 13.905C69.0052 13.91 72.8802 14.405 76.5652 15.34C78.2402 15.765 79.8927 16.28 81.4827 16.875C82.3502 17.2025 83.2127 17.5575 84.0577 17.93L68.2952 18.54C70.1677 19.4325 71.9552 20.4375 73.6552 21.545C78.9277 21.2875 84.1977 21.0325 89.4702 20.7775C89.4852 20.78 89.4977 20.7925 89.5052 20.7975C90.5102 21.405 91.4802 22.0475 92.4127 22.7325C87.0952 22.995 81.7777 23.255 76.4602 23.515C81.7652 27.5475 86.0752 32.625 89.1977 38.33C95.5727 49.965 97.0102 64.22 91.9502 77.625Z"
          fill="#42EC9A"
        />
        <path
          d="M25.0374 84.2696L25.0366 84.2688C21.6812 80.9627 19.1132 76.9817 17.4709 72.6285L17.4708 72.6283C17.0211 71.438 16.6367 70.2217 16.3289 68.9831L64.0994 32.8035C65.2168 33.4361 66.286 34.1329 67.3031 34.8843C71.116 37.7077 74.201 41.3021 76.4222 45.3553C76.683 45.8335 76.9342 46.3194 77.1733 46.8104L26.2311 85.3848C25.8231 85.0269 25.4244 84.6527 25.0374 84.2696Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <path
          d="M3.23242 76.105C3.42492 76.6775 3.62742 77.2475 3.83992 77.81C4.15967 78.6599 4.50774 79.5027 4.87668 80.3324L5.28611 80.0223M3.23242 76.105L3.53435 76.5036L3.82198 76.2857M3.23242 76.105L14.5126 67.5595C14.5479 67.7412 14.5845 67.9225 14.6226 68.1034M3.23242 76.105L3.70635 75.9457C3.7445 76.0592 3.78304 76.1725 3.82198 76.2857M3.82198 76.2857C3.97764 76.7383 4.13954 77.1883 4.30766 77.6333L4.3079 77.634C4.61021 78.4375 4.93841 79.2353 5.28611 80.0223M3.82198 76.2857L14.6226 68.1034M5.28611 80.0223C5.35529 80.1788 5.42524 80.3349 5.49591 80.4906L16.3174 72.2925M5.28611 80.0223L15.7255 72.1137M15.7255 72.1137C15.7651 72.2293 15.8054 72.3447 15.8463 72.4598L16.3174 72.2925M15.7255 72.1137L16.0155 71.894L16.3174 72.2925M15.7255 72.1137C15.2763 70.8028 14.9091 69.4636 14.6226 68.1034M16.3174 72.2925C15.7942 70.8191 15.377 69.3077 15.064 67.7691L14.6226 68.1034"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <path
          d="M45.865 82.8425C44.1275 82.8425 42.3325 82.53 40.5625 81.895C33.9875 79.535 30.5275 72.6075 30.9775 67.275C30.995 67.0675 31.18 66.9175 31.3825 66.9325C31.59 66.95 31.7425 67.1325 31.725 67.3375C31.2975 72.3875 34.5825 78.95 40.8175 81.1875C45.305 82.8 49.9475 82.235 53.2325 79.675C55.73 77.73 56.995 74.8875 56.7025 71.8775C56.155 66.245 52.71 63.795 48.3475 60.695L47.955 60.415C43.5975 57.3125 39.98 54.3575 39.8175 49.6025C39.71 46.4925 41.1175 43.635 43.68 41.7675C46.7275 39.545 50.9625 39.0875 55.01 40.54C59.8325 42.2725 62.4575 46.5575 63.305 49.99C63.355 50.19 63.2325 50.395 63.03 50.445C62.83 50.4975 62.625 50.3725 62.575 50.17C61.7725 46.925 59.2975 42.8775 54.755 41.2475C50.885 39.8575 47.0075 40.2675 44.12 42.375C41.7625 44.095 40.4675 46.72 40.565 49.5775C40.7175 53.9975 44.05 56.7125 48.39 59.805L48.7825 60.085C53.12 63.17 56.8675 65.835 57.4475 71.8075C57.765 75.08 56.3975 78.165 53.6925 80.27C51.5075 81.9675 48.7675 82.8425 45.865 82.8425Z"
          fill="#1D232A"
        />
        <path
          d="M38.73 86.56C38.6875 86.56 38.645 86.5525 38.605 86.5375C38.41 86.4675 38.3075 86.2525 38.3775 86.0575L56.265 36.0425C56.335 35.8475 56.55 35.745 56.745 35.815C56.94 35.885 57.0425 36.1 56.9725 36.295L39.085 86.31C39.0275 86.465 38.885 86.56 38.73 86.56Z"
          fill="#1D232A"
        />
        <path
          d="M13.7357 63.8802L2.64313 72.2877C2.55281 71.9299 2.46814 71.5704 2.38879 71.2089L13.3097 62.9388C13.4075 62.8649 13.604 62.714 13.8182 62.5491L13.7357 63.8802Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <path
          d="M24.7625 85.6951C24.67 85.6951 24.5775 85.6601 24.505 85.5926C20.6925 81.9651 17.74 77.4101 15.9675 72.4176C13.7 66.0426 13.395 59.2126 15.085 52.6676C15.3775 51.5226 15.755 50.3351 16.2025 49.1376C17.5975 45.4526 19.5775 42.0901 22.09 39.1426C22.225 38.9851 22.46 38.9651 22.6175 39.1001C22.775 39.2351 22.795 39.4701 22.66 39.6276C20.2025 42.5101 18.2675 45.7976 16.9025 49.4026C16.465 50.5726 16.0975 51.7351 15.81 52.8551C14.16 59.2576 14.4575 65.9351 16.6725 72.1676C18.4075 77.0501 21.2925 81.5051 25.0225 85.0501C25.1725 85.1926 25.1775 85.4301 25.035 85.5801C24.96 85.6576 24.8625 85.6951 24.7625 85.6951Z"
          fill="#1D232A"
        />
        <path
          d="M117.216 36.0125L112.436 22.7825L99.2031 18.0125L112.433 13.2325L117.203 0L121.983 13.23L135.216 18L121.986 22.78L117.216 36.0125Z"
          fill="#42EC9A"
        />
        <path
          d="M106.602 55.045L103.525 46.525L95.0049 43.4525L103.525 40.375L106.597 31.8525L109.675 40.3725L118.195 43.445L109.675 46.5225L106.602 55.045Z"
          fill="#42EC9A"
        />
      </g>
      <g filter="url(#filter0_d_3299_8656)">
        <path
          d="M99.0352 130C114.499 130 127.035 117.464 127.035 102C127.035 86.536 114.499 74 99.0352 74C83.5712 74 71.0352 86.536 71.0352 102C71.0352 117.464 83.5712 130 99.0352 130Z"
          fill="#E8E9EA"
        />
      </g>
      <path
        d="M116.974 90.4065L115.269 98.3229C115.032 99.4256 114.056 100.18 112.972 100.18C112.81 100.18 112.648 100.164 112.483 100.129L104.56 98.4475C103.29 98.1771 102.479 96.931 102.747 95.659C103.017 94.3894 104.266 93.5782 105.535 93.8486L108.54 94.4858C105.531 90.6886 100.389 88.9135 95.4841 90.4183C92.5099 91.3305 90.027 93.3243 88.4964 96.0281C87.8546 97.159 86.4203 97.5564 85.2918 96.9169C84.1609 96.275 83.7635 94.8408 84.403 93.7122C86.5308 89.9551 89.9753 87.1901 94.1039 85.9228C100.779 83.8773 107.771 86.205 111.98 91.2647L112.377 89.4167C112.65 88.147 113.901 87.3382 115.17 87.6133C116.44 87.8861 117.249 89.1369 116.974 90.4065Z"
        fill="#00A868"
      />
      <path
        d="M113.668 110.29C111.543 114.048 108.096 116.812 103.97 118.08C102.331 118.583 100.676 118.82 99.0466 118.82C94.0316 118.82 89.2705 116.559 86.094 112.74L85.6967 114.586C85.4592 115.689 84.4835 116.443 83.3996 116.443C83.235 116.443 83.0681 116.427 82.9012 116.389C81.6339 116.117 80.8251 114.866 81.0978 113.596L82.8048 105.68C83.0775 104.412 84.3236 103.604 85.5909 103.874L93.5143 105.555C94.7839 105.825 95.5951 107.072 95.3247 108.344C95.0567 109.613 93.8082 110.424 92.5386 110.154L89.5314 109.517C92.5433 113.314 97.6853 115.089 102.59 113.584C105.564 112.672 108.044 110.678 109.577 107.974C110.217 106.844 111.651 106.446 112.782 107.088C113.913 107.728 114.31 109.162 113.668 110.29Z"
        fill="#00A868"
      />
      <defs>
        <filter
          id="filter0_d_3299_8656"
          x="68.0352"
          y="73"
          width="62"
          height="62"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.290196 0 0 0 0 0.309804 0 0 0 0 0.333333 0 0 0 0.32 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3299_8656" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3299_8656" result="shape" />
        </filter>
        <clipPath id="clip0_3299_8656">
          <rect width="134.43" height="108.213" fill="white" transform="translate(0.785156)" />
        </clipPath>
      </defs>
    </svg>
  )
}
