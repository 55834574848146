export function SercomtelLandlineIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="32" height="32" fill="white" />
      <g clipPath="url(#clip0_2841_10521)">
        <path
          d="M10.3212 14.5708C10.1925 14.2872 10.0983 13.9886 10.0406 13.6818C9.2388 12.8518 8.9281 11.8855 9.04837 10.5814C9.16263 9.32825 9.64972 8.34361 10.4736 7.6804C11.4558 6.89106 12.9552 6.49029 14.9316 6.49029C16.5352 6.49029 18.7402 6.74662 19.5761 6.96837C19.7023 7.00092 19.6983 7.04567 19.6923 7.1067L19.6262 7.87773C19.7845 7.87773 19.9469 7.89401 20.1093 7.90418L20.1774 7.15146C20.1966 7.00034 20.1575 6.84754 20.0682 6.72505C19.9789 6.60257 19.8463 6.5199 19.6983 6.49435C18.8344 6.2665 16.5673 6 14.9316 6C10.9827 6 8.84191 7.52579 8.56729 10.5367C8.46169 11.3517 8.58539 12.1804 8.92409 12.9271C9.25377 13.5822 9.73529 14.1463 10.3272 14.5708"
          fill="#FBB03E"
        />
        <path
          d="M14.228 24.7733C13.5469 24.7707 12.8664 24.7313 12.1894 24.6553C11.9369 24.6268 11.6823 24.6024 11.4317 24.5617C10.9676 24.491 10.5074 24.3966 10.0526 24.2789H10.0426C9.67377 24.167 9.64169 23.9616 9.64169 23.8497C9.70383 23.1681 9.76397 22.4866 9.82611 21.8153C9.82048 21.6565 9.87337 21.5013 9.97444 21.3799C10.028 21.3389 10.0891 21.3092 10.1542 21.2924C10.2192 21.2756 10.2869 21.2722 10.3533 21.2823C11.0609 21.3372 14.0496 21.6444 14.8234 21.5712C15.4949 21.5081 16.2265 21.3352 16.5532 20.6699C16.6893 20.4133 16.7467 20.1213 16.7182 19.8313C16.6896 19.5413 16.5763 19.2666 16.3929 19.0424C16.0961 18.7446 15.7586 18.4917 15.3906 18.2917L14.2641 17.5919C13.6126 17.1891 12.9692 16.8066 12.3438 16.3937C11.6582 15.942 11.0188 15.4029 10.6239 14.6624C10.1909 13.8486 10.1228 12.9189 10.233 12.0177C10.3299 11.1013 10.7126 10.2403 11.3255 9.56017C11.9371 8.94762 12.698 8.51094 13.5304 8.29478C14.5381 8.02812 15.5788 7.91297 16.6194 7.95301C17.6253 7.97195 18.6293 8.05075 19.6262 8.189C20.0984 8.24443 20.567 8.32867 21.0293 8.44126C21.0736 8.4462 21.1164 8.46022 21.1552 8.48247C21.194 8.50472 21.2279 8.53474 21.2549 8.5707C21.2819 8.60666 21.3014 8.64781 21.3122 8.69165C21.3231 8.7355 21.325 8.78112 21.318 8.82576L21.1175 11.0636C21.0874 11.4013 20.7647 11.4481 20.4901 11.4318C19.4297 11.3626 18.3713 11.2711 17.3109 11.2182C16.7958 11.1917 16.2786 11.1856 15.7615 11.1958C15.338 11.1759 14.9174 11.2759 14.5467 11.4847C14.4137 11.5788 14.3022 11.701 14.2199 11.8428C14.1375 11.9847 14.0863 12.1428 14.0697 12.3066C14.0478 12.4876 14.0711 12.6712 14.1372 12.8408C14.2034 13.0103 14.3104 13.1603 14.4485 13.277C14.754 13.527 15.0853 13.7426 15.4367 13.9198L16.5332 14.5302C17.1105 14.8536 17.6958 15.169 18.2671 15.5026C19.0689 15.9685 19.8306 16.5564 20.2997 17.3763C20.7511 18.2396 20.9056 19.2313 20.7387 20.1939C20.6087 21.1211 20.223 21.9921 19.6262 22.7063C19.0448 23.3763 18.3114 23.8923 17.4894 24.2097C16.4461 24.6012 15.3402 24.7923 14.228 24.7733Z"
          fill="#24408F"
        />
        <path
          d="M22.6129 9.16956C22.3583 9.10243 21.9875 9.03326 21.5305 8.96613C21.5305 9.07598 21.5044 9.25094 21.4864 9.45438C21.8237 9.50456 22.1582 9.57246 22.4886 9.65782C22.6149 9.68833 22.6109 9.73309 22.6049 9.79412L22.4044 12.0319C22.3924 12.1499 22.3543 12.1845 22.228 12.1845H22.196L21.9955 12.1703C21.1396 12.1072 18.8645 11.9424 17.4693 11.9424C16.1103 11.9424 15.4648 12.3717 15.3766 13.336C15.3676 13.4341 15.3676 13.5328 15.3766 13.631L15.4849 13.692L15.555 13.7306L16.0902 14.0317C15.9977 13.9547 15.9274 13.8537 15.8869 13.7395C15.8463 13.6252 15.8369 13.502 15.8597 13.3828C15.9018 12.923 16.0601 12.4307 17.4633 12.4307C18.8404 12.4307 21.1035 12.5955 21.9554 12.6585L22.1779 12.6748H22.232C22.3144 12.6824 22.3975 12.6726 22.476 12.6461C22.5545 12.6196 22.6268 12.577 22.6883 12.5208C22.7498 12.4647 22.7993 12.3963 22.8335 12.3198C22.8678 12.2434 22.8862 12.1606 22.8875 12.0767L23.088 9.83888C23.1072 9.68752 23.0681 9.53446 22.9789 9.41165C22.8896 9.28883 22.7571 9.20575 22.6089 9.17974"
          fill="#57C5CC"
        />
        <path
          d="M23.0118 17.5227C22.5748 16.709 21.8251 16.0051 20.7166 15.3866L17.9905 13.8588C16.8078 13.1997 16.7156 12.9881 16.7557 12.5487C16.7557 12.5222 16.7557 12.4958 16.7557 12.4714C16.583 12.4989 16.4158 12.5546 16.2606 12.6361C16.2545 12.7848 16.2773 12.9332 16.3279 13.0729C16.3785 13.2125 16.4557 13.3407 16.5553 13.4499C16.7557 13.6859 17.1005 13.9198 17.758 14.282L20.4841 15.8098C22.4024 16.8819 23.1461 18.1554 22.9737 20.0637C22.9096 20.7965 22.6798 21.5046 22.3022 22.1326C22.0893 22.7895 21.7484 23.3962 21.2999 23.9168C21.4483 23.809 21.5926 23.6971 21.7269 23.5791C22.7237 22.6972 23.3452 21.4576 23.4608 20.1206C23.5749 19.2313 23.4212 18.3278 23.0198 17.5288"
          fill="#F26443"
        />
        <path
          d="M23.5169 8.35175C22.667 8.1239 20.444 7.8635 18.8123 7.85536C19.0869 7.88588 19.3676 7.91843 19.6542 7.95708C20.1336 8.01383 20.6094 8.09805 21.0794 8.20934C21.1641 8.22819 21.2438 8.2654 21.313 8.31842C21.3822 8.37144 21.4393 8.43903 21.4803 8.51654C22.1236 8.57832 22.7623 8.6816 23.3926 8.82576C23.5189 8.85831 23.5149 8.90103 23.5089 8.9641L23.3084 11.2019C23.2964 11.3219 23.2583 11.3565 23.134 11.3565H23.102L22.9496 11.3443L22.9055 11.8326L23.0799 11.8448H23.134C23.2163 11.8521 23.2991 11.8421 23.3774 11.8154C23.4556 11.7888 23.5277 11.7461 23.589 11.69C23.6503 11.6339 23.6995 11.5656 23.7337 11.4893C23.7679 11.413 23.7862 11.3304 23.7875 11.2467L23.988 9.00886C24.0077 8.85765 23.9687 8.70459 23.8794 8.58198C23.79 8.45937 23.6571 8.37682 23.5089 8.35175"
          fill="#F26443"
        />
        <path
          d="M22.1057 18.3426C21.6708 17.5288 20.9211 16.8249 19.8126 16.2065L19.2894 15.9135C19.7584 16.2644 20.157 16.7028 20.464 17.2054C21.7269 18.1615 22.214 19.3028 22.0676 20.8896C21.9643 22.1027 21.4017 23.2278 20.4981 24.0287C19.3796 25.0133 17.784 25.5118 15.7554 25.5118C14.3679 25.5305 12.9837 25.3698 11.6362 25.0337C11.544 25.0072 11.3936 24.9442 11.4016 24.8485V24.7936C11.2433 24.7692 11.0869 24.7407 10.9326 24.7122V24.8038C10.9186 24.9584 10.9486 25.3327 11.5219 25.5056C12.9106 25.853 14.3373 26.0191 15.7675 26C17.9143 26 19.6161 25.4609 20.8269 24.3989C21.823 23.5165 22.4444 22.2773 22.5608 20.9405C22.6753 20.051 22.5208 19.1471 22.1178 18.3487"
          fill="#FBB03E"
        />
        <path
          d="M9.53345 22.5416C9.25683 22.4887 8.98221 22.4276 8.71762 22.3585C8.62541 22.33 8.47307 22.2689 8.48309 22.1733L8.68354 20.0067C8.69757 19.8582 8.74969 19.8033 8.88399 19.8033H8.94012C9.88424 19.8948 12.3157 20.0698 13.1797 20.0698C14.6069 20.0698 15.3064 19.6446 15.5069 18.6254L15.3946 18.5562L15.2664 18.4769L15.0659 18.3507C14.9697 19.1482 14.6149 19.5714 13.1817 19.5714C12.3237 19.5714 9.90629 19.3984 8.97219 19.3069H8.88199C8.79427 19.3013 8.70636 19.3141 8.62372 19.3444C8.54107 19.3748 8.46544 19.422 8.40154 19.4833C8.33763 19.5445 8.28681 19.6184 8.25222 19.7004C8.21763 19.7824 8.20001 19.8707 8.20045 19.9599L8 22.1286C7.98597 22.2812 8.01403 22.6575 8.58933 22.8304C8.88199 22.9057 9.19068 22.9728 9.48735 23.0339C9.50138 22.8732 9.51541 22.7104 9.53145 22.5436"
          fill="#57C5CC"
        />
      </g>
      <defs>
        <clipPath id="clip0_2841_10521">
          <rect width="16" height="20" fill="white" transform="translate(8 6)" />
        </clipPath>
      </defs>
    </svg>
  )
}
