import { useEffectOnce } from 'react-use'

import { appEnv } from '~/lib/helpers'

import { savePubSubLog } from './logging'
import { type PubSubKeyMappings } from './types'

function useSubscribeEvent<K extends keyof PubSubKeyMappings>(
  eventName: K,
  callback: (e: CustomEvent<PubSubKeyMappings[K]>) => void
) {
  useEffectOnce(() => {
    globalThis.document.addEventListener(eventName, callback as EventListener)

    return () => {
      globalThis.document.removeEventListener(eventName, callback as EventListener)
    }
  })

  if (!appEnv.isProduction()) {
    savePubSubLog('SUB', eventName)
  }
}

function publish<K extends keyof PubSubKeyMappings>(eventName: K, data?: PubSubKeyMappings[K]): boolean {
  const event = new CustomEvent(eventName, { detail: data })

  if (!appEnv.isProduction()) {
    savePubSubLog('PUB', eventName)
  }

  return globalThis.document.dispatchEvent(event)
}

export { publish, useSubscribeEvent }
