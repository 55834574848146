import styled from 'styled-components'
import { ifProp, prop, theme } from 'styled-tools'

export const Wrapper = styled.div<{ rows: string; size?: string }>`
  display: grid;
  grid-template-rows: ${ifProp('rows')};
  position: fixed;
  width: ${ifProp('size', prop('size'))};
  min-height: 460px;
  min-width: 460px;
  max-height: 680px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;

  background-color: ${theme('colors.white')};
  border-radius: ${theme('radii.default')};

  z-index: ${theme('zIndices.alert')};

  &.visible {
    animation: modal-in 0.15s ease-in;
  }
  @keyframes modal-in {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
`

export const Content = styled.div`
  padding: ${theme('space.medium')};
`

export const Header = styled.div`
  padding: ${theme('space.medium')} ${theme('space.medium')} 0;
`

export const Footer = styled.div`
  border-top: 1px solid ${theme('colors.mediumGray4')};
  padding: ${theme('space.medium')};
`

export const ModalTitle = styled.div`
  padding: ${theme('space.small')};
  font-size: ${theme('fontSizes.medium')};
  font-weight: ${theme('fontWeights.medium')};
  color: ${theme('colors.darkGray')};
`
