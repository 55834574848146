import { memo } from 'react'

function CoinsStackNeutralSvg() {
  return (
    <svg width="90" height="89" viewBox="0 0 90 89" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_13072_1475)">
        <path
          d="M54.4231 23.5736C54.4231 24.9748 54.2932 26.3486 54.0511 27.6731C53.9625 28.1848 53.8484 28.6886 53.7244 29.1905C53.565 29.834 53.3702 30.4697 53.1517 31.0955C52.9923 31.5639 52.8132 32.0245 52.6124 32.483C52.5042 32.7625 52.3763 33.042 52.2444 33.3195C52.0358 33.7564 51.8213 34.1894 51.5792 34.6184C51.4355 34.8801 51.2938 35.136 51.1344 35.3879C47.1235 42.0636 39.816 46.5292 31.4773 46.5272L23.1681 46.5252L23.0854 20.2928L23.0225 3.18041L25.0988 3.17845L40.8079 2.57031C41.1405 2.71989 41.4672 2.87536 41.7959 3.04068C42.3607 3.32212 42.9138 3.62717 43.4432 3.9578C38.1255 4.21365 32.8077 4.47344 27.488 4.73322C26.8307 4.76471 26.8425 5.79008 27.5038 5.76056C33.2899 5.47519 39.0859 5.19375 44.8759 4.90838C44.933 4.95168 44.994 4.99498 45.0511 5.03827C49.5737 8.34857 52.823 13.2825 53.9743 18.984C54.2676 20.4621 54.425 21.9991 54.4231 23.5736Z"
          fill="#1D232A"
        />
        <path
          d="M44.6496 31.635C40.1722 43.4966 26.9409 49.4992 15.0951 45.0455C11.9954 43.8823 9.30106 42.117 7.08698 39.9344C5.1012 37.9742 3.50706 35.6814 2.35967 33.1819L7.82698 29.0391C7.54161 28.2362 7.32118 27.4115 7.16374 26.5692L1.4583 30.8931C1.29691 30.4129 1.15128 29.9287 1.02335 29.4406C1.04894 29.4288 1.07255 29.4131 1.09814 29.3954L6.80555 25.0695L6.8823 23.7883C6.8823 23.7883 6.43162 24.1249 6.21513 24.2882L0.773406 28.4094C0.525429 27.26 0.367983 26.089 0.301069 24.9062C0.12591 21.836 0.570695 18.6772 1.71021 15.609C1.72595 15.5736 1.73579 15.5382 1.75154 15.5027C4.99492 6.9121 12.8318 1.39363 21.4086 0.694964C21.942 0.651666 22.4793 0.626081 23.0185 0.622145L31.4301 0.612305H31.5403C33.4788 0.614273 35.3643 0.854378 37.1591 1.309C37.9759 1.51762 38.7789 1.76756 39.5543 2.05687C39.9754 2.21628 40.3946 2.38947 40.8079 2.57054L33.1344 2.86772C34.0456 3.30266 34.9155 3.79271 35.7421 4.32999C38.3085 4.206 40.8768 4.08202 43.4432 3.95803C43.4511 3.95803 43.455 3.96393 43.4609 3.9659C43.949 4.26308 44.4213 4.576 44.8759 4.90861C42.286 5.03653 39.694 5.16249 37.1021 5.28844C39.6901 7.25061 41.79 9.72448 43.3094 12.5034C46.413 18.1675 47.1136 25.1089 44.6496 31.635Z"
          fill="#BBBDBF"
        />
        <path
          d="M12.1778 34.764L12.177 34.7631C10.559 33.1682 9.32073 31.2486 8.52772 29.1501C8.32283 28.6059 8.14486 28.0515 8.00111 27.4881L31.1017 9.99295C31.609 10.2841 32.0949 10.604 32.5594 10.9477C34.3981 12.3104 35.886 14.0419 36.9574 15.9967C37.064 16.1916 37.167 16.3892 37.2659 16.5881L12.66 35.2195C12.4959 35.0724 12.335 34.9198 12.1778 34.764Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
          strokeWidth="0.787229"
        />
        <path
          d="M2.11997 31.5867C2.05179 31.4031 1.98548 31.2198 1.92101 31.0355L6.90405 27.2604C7.02883 27.8138 7.18104 28.3603 7.36133 28.8968L2.51723 32.5674C2.37683 32.2431 2.24382 31.916 2.11997 31.5867Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
          strokeWidth="0.787229"
        />
        <mask id="path-5-inside-1_13072_1475" fill="white">
          <path d="M1.45802 30.8948C1.46196 30.9085 1.4659 30.9204 1.46983 30.9341C1.46393 30.9204 1.45999 30.9085 1.45605 30.8948H1.45802Z" />
        </mask>
        <path
          d="M1.45802 30.8948C1.46196 30.9085 1.4659 30.9204 1.46983 30.9341C1.46393 30.9204 1.45999 30.9085 1.45605 30.8948H1.45802Z"
          fill="#FCFCFC"
        />
        <path
          d="M1.45802 30.8948L2.21495 30.6785L2.0518 30.1075H1.45802V30.8948ZM1.46983 30.9341L0.746276 31.2443L2.22676 30.7178L1.46983 30.9341ZM1.45605 30.8948V30.1075H0.412346L0.699126 31.1111L1.45605 30.8948ZM0.701094 31.1111C0.705569 31.1267 0.709818 31.1405 0.711516 31.146C0.713754 31.1533 0.713442 31.1523 0.712903 31.1504L2.22676 30.7178C2.22229 30.7022 2.21804 30.6884 2.21634 30.6829C2.2141 30.6756 2.21441 30.6766 2.21495 30.6785L0.701094 31.1111ZM2.19339 30.624C2.19785 30.6344 2.20218 30.6455 2.20612 30.6569C2.20988 30.6678 2.21222 30.6758 2.21298 30.6785L0.699126 31.1111C0.709611 31.1478 0.724033 31.1924 0.746276 31.2443L2.19339 30.624ZM1.45605 31.682H1.45802V30.1075H1.45605V31.682Z"
          fill="#FCFCFC"
          mask="url(#path-5-inside-1_13072_1475)"
        />
        <path
          d="M22.2119 34.2894C21.3538 34.2894 20.4662 34.1339 19.5924 33.821C16.3411 32.6539 14.6289 29.2255 14.8513 26.5883C14.8651 26.4249 15.0068 26.3088 15.1701 26.3187C15.3335 26.3324 15.4535 26.4761 15.4397 26.6375C15.2351 29.0543 16.8075 32.1934 19.7911 33.266C21.9403 34.0374 24.1603 33.7678 25.7288 32.5476C26.9136 31.6246 27.5138 30.2784 27.3761 28.8516C27.1143 26.1612 25.4572 24.9804 23.3573 23.4886L23.1644 23.3528C21.0192 21.8236 19.2361 20.3672 19.1555 18.0016C19.1023 16.4488 19.8049 15.0239 21.0822 14.093C22.5956 12.9889 24.6975 12.7606 26.703 13.4809C29.0922 14.339 30.3931 16.4606 30.8123 18.161C30.8517 18.3185 30.7553 18.4798 30.5958 18.5192C30.4364 18.5586 30.277 18.4621 30.2377 18.3027C29.8539 16.7519 28.6711 14.8173 26.5003 14.0379C24.6523 13.3747 22.8023 13.5695 21.4266 14.5713C20.3107 15.386 19.6967 16.6299 19.7419 17.9819C19.8148 20.0819 21.4168 21.3867 23.5049 22.8726L23.6958 23.0084C25.8311 24.5258 27.6733 25.8365 27.9606 28.7965C28.12 30.428 27.4371 31.967 26.089 33.016C25.0085 33.8544 23.6505 34.2894 22.2119 34.2894Z"
          fill="#1D232A"
        />
        <path
          d="M18.7382 36.0978C18.7048 36.0978 18.6713 36.0919 18.6378 36.08C18.4843 36.0249 18.4036 35.8557 18.4587 35.7022L27.1675 11.3512C27.2226 11.1977 27.3918 11.117 27.5453 11.1721C27.6989 11.2272 27.7795 11.3965 27.7244 11.55L19.0157 35.9029C18.9724 36.023 18.8602 36.0978 18.7382 36.0978Z"
          fill="#1D232A"
        />
        <path
          d="M1.21169 28.5707L6.43863 24.6122L6.4235 24.8647L1.2601 28.7783C1.24353 28.7091 1.22738 28.64 1.21169 28.5707Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
          strokeWidth="0.787229"
        />
        <path
          d="M11.9381 35.6771C11.8653 35.6771 11.7925 35.6495 11.7354 35.5964C9.86574 33.8192 8.4192 31.5854 7.54932 29.1372C6.43932 26.0119 6.28778 22.6642 7.11634 19.4542C7.26001 18.8933 7.44501 18.3108 7.66346 17.7243C8.34639 15.9176 9.31861 14.2684 10.5506 12.8238C10.6569 12.6998 10.8419 12.6841 10.9659 12.7903C11.0899 12.8966 11.1056 13.0816 10.9994 13.2056C9.8126 14.599 8.8758 16.1892 8.21649 17.9309C8.00394 18.4977 7.82681 19.0586 7.68905 19.5999C6.89001 22.6957 7.03565 25.9233 8.10628 28.9364C8.94468 31.2961 10.34 33.4512 12.1428 35.1654C12.2609 35.2776 12.2648 35.4645 12.1526 35.5826C12.0936 35.6456 12.0168 35.6771 11.9381 35.6771Z"
          fill="#1D232A"
        />
        <path
          d="M69.7859 32.2844V88.4237H4.45572V74.1571H0V60.4889H4.46162V46.5274H46.4642V32.2844H69.7859Z"
          fill="#BBBDBF"
        />
        <path
          d="M74.7159 88.0303V32.6783H80.3209V88.0303H74.7159Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
          strokeWidth="0.787229"
        />
        <path
          d="M70.1797 88.0303V32.6783H71.7404V88.0303H70.1797Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
          strokeWidth="0.787229"
        />
        <path
          d="M85.5346 45.9529V60.493H90.0002V74.1633H85.5346V88.4239H80.7148V32.2847H90.0002V45.9529H85.5346Z"
          fill="#BBBDBF"
        />
        <path d="M74.3223 32.2847H72.1357V88.4239H74.3223V32.2847Z" fill="#BBBDBF" />
        <path d="M46.4642 59.9121H44.2324V74.5388H46.4642V59.9121Z" fill="#1D232A" />
        <path
          d="M36.356 88.4239H44.2342V46.5276H42.1185L40.3374 59.4775L40.1268 61.0047L38.3457 73.9507L38.1351 75.4779L36.356 88.4239Z"
          fill="#1D232A"
        />
        <path
          d="M85.5344 59.9006V60.4911H4.46158C4.45567 60.4911 4.4478 60.4911 4.44189 60.4891H4.46158V59.9006H85.5344Z"
          fill="#1D232A"
        />
        <path d="M85.5346 45.9368H46.4644V46.5272H85.5346V45.9368Z" fill="#1D232A" />
        <path d="M85.5343 74.1616H4.45557V74.7521H85.5343V74.1616Z" fill="#1D232A" />
      </g>
      <defs>
        <clipPath id="clip0_13072_1475">
          <rect width="90" height="87.8114" fill="white" transform="translate(0 0.612305)" />
        </clipPath>
      </defs>
    </svg>
  )
}
export const CoinsStackNeutralIllustration = memo(CoinsStackNeutralSvg)
