import { type Identity, type Organization, type User } from '~/lib/types'

import { stringFormat } from '../utils/string-format'

/** @disclaimer this factory is only kept for use in the identity-selector*/
export function identitiesListFactory({
  organizations,
  subject,
  users
}: {
  organizations: Organization[]
  subject: User
  users: User[]
}): Identity[] {
  const list = [...organizations, ...users, subject].filter(identity => {
    if (stringFormat.documentType(identity.document) === 'CPF')
      return identity.metadata.roles.banking || identity.metadata.roles.acquirer

    return true
  })

  return list
}
