import { ReactQueryDevtoolsPanel } from '@tanstack/react-query-devtools'
export const ReactQueryDevtools = () => {
  /**
   * @disclaimer This is a workaround for what I believe to be a mistake in react-query typings definitions.
   * For some reason the props `setIsOpen` and `handleDragStarts` are required but it's not even documented
   * in [ReactQueryDevtoolsPanel Options](https://github.com/tannerlinsley/react-query/blob/128e578c08c19f50306db4de34321d6d34016cdb/docs/src/pages/devtools.md?plain=1#L71-L78)
   */
  const noop = () => {}

  return <ReactQueryDevtoolsPanel onDragStart={noop} style={{ height: '18rem' }} setIsOpen={noop} />
}
