export function VolumeExemptionIllustration({ width = 55, height = 65, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox="0 0 55 65" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M53.6305 20.8549C51.2717 24.0097 46.3111 28.5453 44.3057 31.6368L40.8737 37.633L40.8726 37.6353L26.8384 62.1567C25.804 63.7476 23.7023 64.4591 22.1408 63.7429L12.7549 59.4386L21.2026 41.342L38.3013 4.71436L51.294 10.673C55.0981 12.4177 56.1455 16.9768 53.6305 20.8549Z"
        fill="#00A868"
      />
      <path
        d="M31.2257 37.3872L34.0577 49.5393L26.8369 62.1563C25.8025 63.7473 23.7009 64.4588 22.1393 63.7426L12.7534 59.4383L31.2257 37.3872Z"
        fill="#1D232A"
      />
      <path
        d="M21.3391 59.0469L44.9447 7.75697L30.9457 1.33809C27.4809 -0.250484 22.788 1.68093 21.0491 5.41344L0.303699 50.5745C-0.385506 52.0739 0.131104 53.646 1.52125 54.2835L16.3844 61.0993C18.122 61.8977 20.4761 60.922 21.3391 59.0469Z"
        fill="#42EC9A"
      />
      <path
        d="M42.6239 6.69434L51.5777 10.8002C48.1129 9.21164 43.42 11.1431 41.6811 14.8756L40.6033 9.81044L42.6239 6.69434Z"
        fill="#42EC9A"
      />
      <path
        d="M19.8349 57.3082L23.4664 49.2315C23.7835 48.5411 23.5005 47.7943 22.8019 47.4726L8.96143 41.1266C8.09611 40.7297 6.96544 41.1418 6.57329 41.9954L2.94175 50.0721C2.62474 50.7625 2.9077 51.5092 3.6063 51.8309L17.4468 58.1782C18.3121 58.5739 19.4427 58.1618 19.8349 57.3082Z"
        fill="#1D232A"
      />
      <path
        d="M35.6692 22.5856C35.6445 22.5856 35.6199 22.5809 35.5964 22.5691L18.8899 14.908C18.8019 14.8669 18.7631 14.7624 18.8031 14.6744C18.843 14.5863 18.9487 14.5476 19.0367 14.5875L35.7432 22.2486C35.8312 22.2897 35.87 22.3942 35.8301 22.4822C35.7995 22.5468 35.7361 22.5856 35.6692 22.5856Z"
        fill="#1D232A"
      />
      <path
        d="M17.845 58.2366L3.40459 51.6146C2.75178 51.3152 2.4653 50.5438 2.7647 49.8921L6.62166 41.4808C6.92106 40.828 7.69245 40.5415 8.34409 40.8409L22.7845 47.4629C23.4373 47.7623 23.7238 48.5337 23.4244 49.1853L19.5674 57.5967C19.2692 58.2495 18.4978 58.536 17.845 58.2366Z"
        fill="#1D232A"
      />
      <path
        d="M23.4208 46.0761L8.9815 39.4541C8.32869 39.1547 8.0422 38.3834 8.3416 37.7317L17.5232 17.7107C17.8226 17.0579 18.594 16.7715 19.2456 17.0709L33.686 23.6928C34.3389 23.9922 34.6253 24.7636 34.3259 25.4153L25.1444 45.4362C24.845 46.0891 24.0736 46.3755 23.4208 46.0761Z"
        fill="#1D232A"
      />
      <path
        d="M38.0306 18.3615L40.7897 12.9136C41.4167 11.675 40.7803 10.2167 39.3209 9.54628L27.3684 4.06552C25.7024 3.30118 23.6465 3.8624 22.9303 5.27604"
        fill="#42EC9A"
      />
      <path
        d="M38.0307 18.538C38.0037 18.538 37.9767 18.5321 37.9508 18.5192C37.8639 18.4758 37.8287 18.3689 37.8733 18.2821L40.6325 12.8342C40.8884 12.3293 40.9248 11.7786 40.737 11.2421C40.508 10.5881 39.9785 10.0421 39.247 9.70634L27.2946 4.22558C25.7225 3.50467 23.757 4.03302 23.0866 5.35507C23.0431 5.44196 22.9363 5.47601 22.8494 5.43257C22.7625 5.38912 22.7273 5.28228 22.7719 5.19539C23.5363 3.68549 25.6743 3.09491 27.4402 3.90505L39.3938 9.38698C40.2145 9.76269 40.8098 10.3815 41.0704 11.127C41.2865 11.7469 41.243 12.4091 40.9471 12.9939L38.188 18.4417C38.1563 18.5028 38.0941 18.538 38.0307 18.538Z"
        fill="#00A868"
      />
      <path
        d="M17.0451 48.7378L18.737 45.0487L18.4165 44.902L16.7246 48.591L11.4434 46.17L13.1353 42.4798L12.816 42.333L11.1241 46.0232L5.31927 43.3615L5.17251 43.6809L10.9773 46.3426L9.2819 50.0411L3.47709 47.3794L3.33032 47.6999L9.13396 50.3616L7.33287 54.2902L7.65223 54.4369L9.45332 50.5084L14.7357 52.9294L12.9334 56.8591L13.2528 57.0059L16.8984 49.0583L22.7255 51.7306L22.8723 51.4101L17.0451 48.7378ZM14.8824 52.61L9.60126 50.1878L11.2967 46.4894L16.5778 48.9116L14.8824 52.61Z"
        fill="#42EC9A"
      />
      <path
        d="M34.6348 48.7086C34.5502 48.7086 34.4751 48.6476 34.461 48.5607L32.5942 37.2035C32.5789 37.1072 32.6435 37.0168 32.7397 37.0015C32.8348 36.9851 32.9264 37.0509 32.9417 37.1471L34.8086 48.5043C34.8238 48.6006 34.7592 48.691 34.663 48.7063C34.6547 48.7074 34.6442 48.7086 34.6348 48.7086Z"
        fill="#1D232A"
      />
    </svg>
  )
}

export function VolumeExemptionTwoIllustration({ width = 55, height = 65, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox="0 0 55 60" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M39.0041 14.6229C37.2886 16.9173 33.6809 20.2159 32.2224 22.4642L29.7264 26.8251L29.7256 26.8268L19.5189 44.6605C18.7666 45.8176 17.2381 46.335 16.1025 45.8141L9.27637 42.6837L15.4202 29.5226L27.8556 2.88428L37.3048 7.21782C40.0715 8.48672 40.8331 11.8024 39.0041 14.6229Z"
        fill="#00A868"
      />
      <path
        d="M22.7096 26.646L24.7692 35.4839L19.5177 44.6599C18.7654 45.8169 17.2369 46.3344 16.1012 45.8135L9.27515 42.6831L22.7096 26.646Z"
        fill="#1D232A"
      />
      <path
        d="M15.5194 42.3988L32.687 5.097L22.506 0.428724C19.9861 -0.726604 16.5731 0.678062 15.3084 3.39261L0.220872 36.237C-0.280368 37.3275 0.0953484 38.4708 1.10637 38.9345L11.9159 43.8914C13.1797 44.4721 14.8917 43.7625 15.5194 42.3988Z"
        fill="#42EC9A"
      />
      <path
        d="M30.9992 4.32422L37.511 7.31032C34.9912 6.15499 31.5782 7.55966 30.3135 10.2742L29.5297 6.59048L30.9992 4.32422Z"
        fill="#42EC9A"
      />
      <path
        d="M14.4253 41.1338L17.0665 35.2599C17.297 34.7578 17.0912 34.2147 16.5831 33.9807L6.51736 29.3654C5.88804 29.0768 5.06573 29.3765 4.78053 29.9973L2.13941 35.8713C1.90886 36.3734 2.11465 36.9164 2.62272 37.1504L12.6885 41.7666C13.3178 42.0544 14.1401 41.7546 14.4253 41.1338Z"
        fill="#1D232A"
      />
      <path
        d="M25.9413 15.8814C25.9234 15.8814 25.9054 15.878 25.8883 15.8695L13.7382 10.2978C13.6741 10.2679 13.646 10.1919 13.675 10.1278C13.704 10.0638 13.7809 10.0356 13.8449 10.0646L25.9951 15.6364C26.0591 15.6662 26.0873 15.7422 26.0583 15.8063C26.0361 15.8532 25.99 15.8814 25.9413 15.8814Z"
        fill="#1D232A"
      />
      <path
        d="M12.9781 41.8095L2.47602 36.9935C2.00125 36.7758 1.7929 36.2148 2.01065 35.7408L4.81571 29.6235C5.03345 29.1487 5.59447 28.9404 6.06838 29.1581L16.5705 33.9741C17.0453 34.1919 17.2536 34.7529 17.0359 35.2268L14.2308 41.3442C14.0139 41.8189 13.4529 42.0273 12.9781 41.8095Z"
        fill="#1D232A"
      />
      <path
        d="M17.0332 32.9653L6.53193 28.1493C6.05716 27.9315 5.84881 27.3705 6.06655 26.8966L12.7441 12.3359C12.9618 11.8611 13.5228 11.6528 13.9967 11.8705L24.4989 16.6865C24.9736 16.9043 25.182 17.4653 24.9643 17.9392L18.2867 32.4999C18.069 32.9747 17.508 33.183 17.0332 32.9653Z"
        fill="#1D232A"
      />
      <path
        d="M27.6588 12.8095L29.6654 8.84744C30.1214 7.94657 29.6586 6.88603 28.5972 6.39845L19.9045 2.41244C18.6928 1.85656 17.1976 2.26472 16.6768 3.29282"
        fill="#42EC9A"
      />
      <path
        d="M27.6587 12.9377C27.6391 12.9377 27.6194 12.9334 27.6006 12.924C27.5375 12.8924 27.5118 12.8147 27.5443 12.7515L29.5509 8.78942C29.7371 8.42224 29.7636 8.02176 29.6269 7.63153C29.4604 7.15591 29.0753 6.75885 28.5433 6.51463L19.8507 2.52863C18.7073 2.00433 17.2779 2.38859 16.7903 3.35008C16.7587 3.41327 16.681 3.43803 16.6178 3.40643C16.5546 3.37484 16.529 3.29714 16.5614 3.23395C17.1173 2.13583 18.6723 1.70632 19.9565 2.29551L28.6501 6.28237C29.247 6.55562 29.6799 7.00562 29.8694 7.54785C30.0266 7.99871 29.995 8.48031 29.7798 8.90555L27.7731 12.8676C27.7501 12.9121 27.7048 12.9377 27.6587 12.9377Z"
        fill="#00A868"
      />
      <path
        d="M12.3965 34.9012L13.627 32.2182L13.3939 32.1115L12.1634 34.7945L8.32257 33.0337L9.55304 30.3499L9.32078 30.2432L8.09031 32.927L3.86863 30.9912L3.76189 31.2234L7.98357 33.1592L6.75054 35.849L2.52886 33.9132L2.42212 34.1463L6.64295 36.0821L5.33307 38.9393L5.56533 39.046L6.87521 36.1889L10.7169 37.9496L9.40617 40.8076L9.63843 40.9144L12.2898 35.1343L16.5277 37.0778L16.6344 36.8447L12.3965 34.9012ZM10.8236 37.7174L6.9828 35.9558L8.21583 33.266L12.0567 35.0276L10.8236 37.7174Z"
        fill="#42EC9A"
      />
      <path
        d="M25.1888 34.8798C25.1274 34.8798 25.0727 34.8354 25.0625 34.7722L23.7047 26.5125C23.6936 26.4425 23.7406 26.3767 23.8106 26.3656C23.8798 26.3536 23.9464 26.4015 23.9575 26.4715L25.3152 34.7313C25.3263 34.8013 25.2794 34.867 25.2093 34.8781C25.2034 34.879 25.1957 34.8798 25.1888 34.8798Z"
        fill="#1D232A"
      />
      <path
        d="M54.0041 27.6229C52.2886 29.9173 48.6809 33.2159 47.2224 35.4642L44.7264 39.8251L44.7256 39.8268L34.5189 57.6605C33.7666 58.8176 32.2381 59.335 31.1025 58.8141L24.2764 55.6837L30.4202 42.5226L42.8556 15.8843L52.3048 20.2178C55.0715 21.4867 55.8331 24.8024 54.0041 27.6229Z"
        fill="#00A868"
      />
      <path
        d="M37.7096 39.646L39.7692 48.4839L34.5177 57.6599C33.7654 58.8169 32.2369 59.3344 31.1012 58.8135L24.2751 55.6831L37.7096 39.646Z"
        fill="#1D232A"
      />
      <path
        d="M30.5194 55.3988L47.687 18.097L37.506 13.4287C34.9861 12.2734 31.5731 13.6781 30.3084 16.3926L15.2209 49.237C14.7196 50.3275 15.0953 51.4708 16.1064 51.9345L26.9159 56.8914C28.1797 57.4721 29.8917 56.7625 30.5194 55.3988Z"
        fill="#42EC9A"
      />
      <path
        d="M45.9992 17.3242L52.511 20.3103C49.9912 19.155 46.5782 20.5597 45.3135 23.2742L44.5297 19.5905L45.9992 17.3242Z"
        fill="#42EC9A"
      />
      <path
        d="M29.4253 54.1338L32.0665 48.2599C32.297 47.7578 32.0912 47.2147 31.5831 46.9807L21.5174 42.3654C20.888 42.0768 20.0657 42.3765 19.7805 42.9973L17.1394 48.8713C16.9089 49.3734 17.1146 49.9164 17.6227 50.1504L27.6885 54.7666C28.3178 55.0544 29.1401 54.7546 29.4253 54.1338Z"
        fill="#1D232A"
      />
      <path
        d="M40.9413 28.8814C40.9234 28.8814 40.9054 28.878 40.8883 28.8695L28.7382 23.2978C28.6741 23.2679 28.646 23.1919 28.675 23.1278C28.704 23.0638 28.7809 23.0356 28.8449 23.0646L40.9951 28.6364C41.0591 28.6662 41.0873 28.7422 41.0583 28.8063C41.0361 28.8532 40.99 28.8814 40.9413 28.8814Z"
        fill="#1D232A"
      />
      <path
        d="M27.9781 54.8095L17.476 49.9935C17.0013 49.7758 16.7929 49.2148 17.0106 48.7408L19.8157 42.6235C20.0335 42.1487 20.5945 41.9404 21.0684 42.1581L31.5705 46.9741C32.0453 47.1919 32.2536 47.7529 32.0359 48.2268L29.2308 54.3442C29.0139 54.8189 28.4529 55.0273 27.9781 54.8095Z"
        fill="#1D232A"
      />
      <path
        d="M32.0332 45.9653L21.5319 41.1493C21.0572 40.9315 20.8488 40.3705 21.0666 39.8966L27.7441 25.3359C27.9618 24.8611 28.5228 24.6528 28.9967 24.8705L39.4989 29.6865C39.9736 29.9043 40.182 30.4653 39.9643 30.9392L33.2867 45.4999C33.069 45.9747 32.508 46.183 32.0332 45.9653Z"
        fill="#1D232A"
      />
      <path
        d="M42.6588 25.8095L44.6654 21.8474C45.1214 20.9466 44.6586 19.886 43.5972 19.3985L34.9045 15.4124C33.6928 14.8566 32.1976 15.2647 31.6768 16.2928"
        fill="#42EC9A"
      />
      <path
        d="M42.6587 25.9377C42.6391 25.9377 42.6194 25.9334 42.6006 25.924C42.5375 25.8924 42.5118 25.8147 42.5443 25.7515L44.5509 21.7894C44.7371 21.4222 44.7636 21.0218 44.6269 20.6315C44.4604 20.1559 44.0753 19.7588 43.5433 19.5146L34.8507 15.5286C33.7073 15.0043 32.2779 15.3886 31.7903 16.3501C31.7587 16.4133 31.681 16.438 31.6178 16.4064C31.5546 16.3748 31.529 16.2971 31.5614 16.2339C32.1173 15.1358 33.6723 14.7063 34.9565 15.2955L43.6501 19.2824C44.247 19.5556 44.6799 20.0056 44.8694 20.5479C45.0266 20.9987 44.995 21.4803 44.7798 21.9056L42.7731 25.8676C42.7501 25.9121 42.7048 25.9377 42.6587 25.9377Z"
        fill="#00A868"
      />
      <path
        d="M27.3965 47.9012L28.627 45.2182L28.3939 45.1115L27.1634 47.7945L23.3226 46.0337L24.553 43.3499L24.3208 43.2432L23.0903 45.927L18.8686 43.9912L18.7619 44.2234L22.9836 46.1592L21.7505 48.849L17.5289 46.9132L17.4221 47.1463L21.6429 49.0821L20.3331 51.9393L20.5653 52.046L21.8752 49.1889L25.7169 50.9496L24.4062 53.8076L24.6384 53.9144L27.2898 48.1343L31.5277 50.0778L31.6344 49.8447L27.3965 47.9012ZM25.8236 50.7174L21.9828 48.9558L23.2158 46.266L27.0567 48.0276L25.8236 50.7174Z"
        fill="#42EC9A"
      />
      <path
        d="M40.1888 47.8798C40.1274 47.8798 40.0727 47.8354 40.0625 47.7722L38.7047 39.5125C38.6936 39.4425 38.7406 39.3767 38.8106 39.3656C38.8798 39.3536 38.9464 39.4015 38.9575 39.4715L40.3152 47.7313C40.3263 47.8013 40.2794 47.867 40.2093 47.8781C40.2034 47.879 40.1957 47.8798 40.1888 47.8798Z"
        fill="#1D232A"
      />
    </svg>
  )
}

export function VolumeExemptionFourIllustration({ width = 55, height = 80, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox="0 0 55 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M37.8161 12.5693C36.3418 14.5411 33.2414 17.3758 31.988 19.308L29.8431 23.0556L29.8424 23.0571L21.071 38.3829C20.4245 39.3772 19.1109 39.8219 18.135 39.3743L12.2688 36.6841L17.5486 25.3737L28.2353 2.48145L36.3558 6.20559C38.7333 7.29605 39.3879 10.1455 37.8161 12.5693Z"
        fill="#00A868"
      />
      <path
        d="M23.813 22.9014L25.583 30.4964L21.07 38.3821C20.4235 39.3764 19.11 39.8211 18.134 39.3735L12.2678 36.6833L23.813 22.9014Z"
        fill="#1D232A"
      />
      <path
        d="M17.6338 36.4391L32.3873 4.38283L23.6379 0.371036C21.4724 -0.621823 18.5394 0.585311 17.4526 2.91813L4.48669 31.1438C4.05593 32.0809 4.37881 33.0635 5.24766 33.4619L14.5371 37.7218C15.6232 38.2208 17.0945 37.611 17.6338 36.4391Z"
        fill="#42EC9A"
      />
      <path
        d="M30.9367 3.71875L36.5328 6.28493C34.3673 5.29207 31.4343 6.4992 30.3475 8.83203L29.6738 5.66632L30.9367 3.71875Z"
        fill="#42EC9A"
      />
      <path
        d="M16.6937 35.352L18.9634 30.3041C19.1615 29.8726 18.9847 29.4059 18.5481 29.2048L9.89777 25.2385C9.35694 24.9905 8.65027 25.2481 8.40518 25.7815L6.13547 30.8295C5.93734 31.261 6.11419 31.7277 6.55081 31.9288L15.2011 35.8958C15.7419 36.1431 16.4486 35.8855 16.6937 35.352Z"
        fill="#1D232A"
      />
      <path
        d="M26.5902 13.6507C26.5748 13.6507 26.5594 13.6478 26.5447 13.6404L16.1032 8.85224C16.0481 8.82656 16.0239 8.76125 16.0489 8.70621C16.0738 8.65118 16.1399 8.62696 16.1949 8.65191L26.6364 13.4401C26.6915 13.4658 26.7157 13.5311 26.6908 13.5861C26.6717 13.6265 26.632 13.6507 26.5902 13.6507Z"
        fill="#1D232A"
      />
      <path
        d="M15.4501 35.9323L6.42481 31.7936C6.0168 31.6065 5.83775 31.1243 6.02487 30.7171L8.43547 25.46C8.6226 25.052 9.10472 24.8729 9.51199 25.06L18.5373 29.1988C18.9453 29.3859 19.1243 29.868 18.9372 30.2753L16.5266 35.5324C16.3402 35.9404 15.8581 36.1195 15.4501 35.9323Z"
        fill="#1D232A"
      />
      <path
        d="M18.9347 28.3324L9.91016 24.1936C9.50215 24.0065 9.3231 23.5244 9.51022 23.1171L15.2487 10.604C15.4358 10.196 15.918 10.0169 16.3252 10.2041L25.3505 14.3428C25.7585 14.5299 25.9376 15.0121 25.7504 15.4193L20.0119 27.9324C19.8248 28.3404 19.3427 28.5195 18.9347 28.3324Z"
        fill="#1D232A"
      />
      <path
        d="M28.0661 11.0111L29.7906 7.60618C30.1824 6.832 29.7847 5.9206 28.8726 5.50158L21.4023 2.07611C20.361 1.59839 19.076 1.94916 18.6284 2.83268"
        fill="#42EC9A"
      />
      <path
        d="M28.0661 11.1212C28.0493 11.1212 28.0324 11.1175 28.0162 11.1094C27.9619 11.0823 27.9399 11.0155 27.9678 10.9612L29.6923 7.55627C29.8523 7.24073 29.875 6.89656 29.7576 6.56121C29.6145 6.15247 29.2835 5.81124 28.8264 5.60137L21.3561 2.1759C20.3735 1.72533 19.1451 2.05555 18.7261 2.88183C18.6989 2.93614 18.6322 2.95742 18.5779 2.93027C18.5236 2.90311 18.5016 2.83634 18.5294 2.78203C19.0072 1.83834 20.3434 1.46923 21.4471 1.97557L28.9181 5.40177C29.431 5.6366 29.8031 6.02332 29.966 6.4893C30.101 6.87675 30.0739 7.29063 29.8889 7.65607L28.1645 11.061C28.1447 11.0992 28.1058 11.1212 28.0661 11.1212Z"
        fill="#00A868"
      />
      <path
        d="M14.9502 29.9957L16.0076 27.69L15.8073 27.5983L14.7498 29.9039L11.4491 28.3908L12.5066 26.0844L12.307 25.9927L11.2495 28.2991L7.62151 26.6355L7.52978 26.8351L11.1578 28.4987L10.0982 30.8102L6.47015 29.1466L6.37842 29.347L10.0057 31.0105L8.88001 33.4659L9.07961 33.5576L10.2053 31.1023L13.5068 32.6154L12.3803 35.0715L12.5799 35.1632L14.8584 30.196L18.5004 31.8662L18.5921 31.6658L14.9502 29.9957ZM13.5985 32.4158L10.2978 30.9019L11.3574 28.5904L14.6581 30.1043L13.5985 32.4158Z"
        fill="#42EC9A"
      />
      <path
        d="M25.9435 29.9777C25.8907 29.9777 25.8437 29.9395 25.8349 29.8852L24.6681 22.787C24.6586 22.7268 24.699 22.6703 24.7591 22.6608C24.8186 22.6505 24.8758 22.6916 24.8854 22.7517L26.0521 29.85C26.0617 29.9102 26.0213 29.9667 25.9612 29.9762C25.956 29.9769 25.9494 29.9777 25.9435 29.9777Z"
        fill="#1D232A"
      />
      <path
        d="M54.1442 22.8818C52.6699 24.8536 49.5695 27.6883 48.3162 29.6205L46.1712 33.3681L46.1705 33.3696L37.3991 48.6954C36.7526 49.6897 35.4391 50.1344 34.4631 49.6868L28.5969 46.9966L33.8768 35.6862L44.5634 12.7939L52.6839 16.5181C55.0615 17.6085 55.716 20.458 54.1442 22.8818Z"
        fill="#00A868"
      />
      <path
        d="M40.1412 33.2139L41.9111 40.8089L37.3981 48.6946C36.7516 49.6889 35.4381 50.1336 34.4621 49.686L28.5959 46.9958L40.1412 33.2139Z"
        fill="#1D232A"
      />
      <path
        d="M33.9619 46.7516L48.7154 14.6953L39.9661 10.6835C37.8006 9.69068 34.8675 10.8978 33.7807 13.2306L20.8148 41.4563C20.3841 42.3934 20.7069 43.376 21.5758 43.7744L30.8652 48.0343C31.9513 48.5333 33.4226 47.9235 33.9619 46.7516Z"
        fill="#42EC9A"
      />
      <path
        d="M47.2649 14.0312L52.861 16.5974C50.6955 15.6046 47.7624 16.8117 46.6756 19.1445L46.002 15.9788L47.2649 14.0312Z"
        fill="#42EC9A"
      />
      <path
        d="M33.0218 45.6645L35.2915 40.6166C35.4897 40.1851 35.3128 39.7184 34.8762 39.5173L26.2259 35.551C25.6851 35.303 24.9784 35.5606 24.7333 36.094L22.4636 41.142C22.2655 41.5735 22.4423 42.0402 22.8789 42.2413L31.5292 46.2083C32.07 46.4556 32.7767 46.198 33.0218 45.6645Z"
        fill="#1D232A"
      />
      <path
        d="M42.9183 23.9632C42.9029 23.9632 42.8875 23.9603 42.8728 23.9529L32.4313 19.1647C32.3763 19.1391 32.3521 19.0737 32.377 19.0187C32.402 18.9637 32.468 18.9395 32.523 18.9644L42.9646 23.7526C43.0196 23.7783 43.0438 23.8436 43.0189 23.8986C42.9998 23.939 42.9602 23.9632 42.9183 23.9632Z"
        fill="#1D232A"
      />
      <path
        d="M31.7782 46.2448L22.7529 42.1061C22.3449 41.919 22.1659 41.4368 22.353 41.0296L24.7636 35.7725C24.9507 35.3645 25.4328 35.1854 25.8401 35.3725L34.8654 39.5113C35.2734 39.6984 35.4524 40.1805 35.2653 40.5878L32.8547 45.8449C32.6683 46.2529 32.1862 46.432 31.7782 46.2448Z"
        fill="#1D232A"
      />
      <path
        d="M35.2628 38.6449L26.2383 34.5061C25.8303 34.319 25.6512 33.8369 25.8383 33.4296L31.5768 20.9165C31.764 20.5085 32.2461 20.3294 32.6534 20.5166L41.6786 24.6553C42.0866 24.8424 42.2657 25.3246 42.0786 25.7318L36.3401 38.2449C36.1529 38.6529 35.6708 38.832 35.2628 38.6449Z"
        fill="#1D232A"
      />
      <path
        d="M44.3942 21.3236L46.1187 17.9187C46.5106 17.1445 46.1128 16.2331 45.2007 15.8141L37.7304 12.3886C36.6891 11.9109 35.4042 12.2617 34.9565 13.1452"
        fill="#42EC9A"
      />
      <path
        d="M44.3943 21.4337C44.3774 21.4337 44.3605 21.43 44.3444 21.4219C44.2901 21.3948 44.2681 21.328 44.2959 21.2737L46.0204 17.8688C46.1804 17.5532 46.2031 17.2091 46.0857 16.8737C45.9426 16.465 45.6117 16.1237 45.1545 15.9139L37.6842 12.4884C36.7016 12.0378 35.4732 12.3681 35.0542 13.1943C35.0271 13.2486 34.9603 13.2699 34.906 13.2428C34.8517 13.2156 34.8297 13.1488 34.8576 13.0945C35.3353 12.1508 36.6716 11.7817 37.7752 12.2881L45.2462 15.7143C45.7592 15.9491 46.1312 16.3358 46.2941 16.8018C46.4291 17.1893 46.402 17.6031 46.2171 17.9686L44.4926 21.3735C44.4728 21.4117 44.4339 21.4337 44.3943 21.4337Z"
        fill="#00A868"
      />
      <path
        d="M31.2783 40.3082L32.3357 38.0025L32.1354 37.9108L31.078 40.2164L27.7772 38.7033L28.8347 36.3969L28.6351 36.3052L27.5776 38.6116L23.9496 36.948L23.8579 37.1476L27.4859 38.8112L26.4263 41.1227L22.7983 39.4591L22.7065 39.6595L26.3338 41.323L25.2081 43.7784L25.4077 43.8701L26.5334 41.4148L29.8349 42.9279L28.7085 45.384L28.9081 45.4757L31.1866 40.5085L34.8285 42.1787L34.9203 41.9783L31.2783 40.3082ZM29.9266 42.7283L26.6259 41.2144L27.6855 38.9029L30.9862 40.4168L29.9266 42.7283Z"
        fill="#42EC9A"
      />
      <path
        d="M42.2717 40.2902C42.2188 40.2902 42.1719 40.252 42.1631 40.1977L40.9963 33.0995C40.9867 33.0393 41.0271 32.9828 41.0873 32.9733C41.1467 32.963 41.2039 33.0041 41.2135 33.0642L42.3803 40.1625C42.3898 40.2227 42.3494 40.2792 42.2893 40.2887C42.2841 40.2894 42.2775 40.2902 42.2717 40.2902Z"
        fill="#1D232A"
      />
      <path
        d="M33.5192 41.788C32.0449 43.7598 28.9445 46.5946 27.6912 48.5267L25.5462 52.2743L25.5455 52.2758L16.7741 67.6017C16.1276 68.596 14.8141 69.0407 13.8381 68.5931L7.97192 65.9029L13.2518 54.5925L23.9384 31.7002L32.0589 35.4243C34.4365 36.5148 35.091 39.3642 33.5192 41.788Z"
        fill="#00A868"
      />
      <path
        d="M19.5162 52.1201L21.2861 59.7152L16.7731 67.6008C16.1266 68.5951 14.8131 69.0398 13.8371 68.5922L7.97095 65.902L19.5162 52.1201Z"
        fill="#1D232A"
      />
      <path
        d="M13.3369 65.6578L28.0904 33.6016L19.3411 29.5898C17.1756 28.5969 14.2425 29.8041 13.1557 32.1369L0.189812 60.3626C-0.240941 61.2996 0.08194 62.2822 0.950784 62.6807L10.2402 66.9405C11.3263 67.4395 12.7976 66.8297 13.3369 65.6578Z"
        fill="#42EC9A"
      />
      <path
        d="M26.6399 32.9375L32.236 35.5037C30.0705 34.5108 27.1374 35.718 26.0506 38.0508L25.377 34.8851L26.6399 32.9375Z"
        fill="#42EC9A"
      />
      <path
        d="M12.3968 64.5708L14.6665 59.5228C14.8647 59.0914 14.6878 58.6246 14.2512 58.4236L5.6009 54.4573C5.06007 54.2092 4.3534 54.4668 4.1083 55.0003L1.83859 60.0483C1.64046 60.4797 1.81731 60.9464 2.25394 61.1475L10.9042 65.1146C11.445 65.3618 12.1517 65.1043 12.3968 64.5708Z"
        fill="#1D232A"
      />
      <path
        d="M22.2933 42.8695C22.2779 42.8695 22.2625 42.8665 22.2478 42.8592L11.8063 38.071C11.7513 38.0453 11.7271 37.98 11.752 37.925C11.777 37.8699 11.843 37.8457 11.898 37.8707L22.3396 42.6588C22.3946 42.6845 22.4188 42.7498 22.3939 42.8049C22.3748 42.8452 22.3352 42.8695 22.2933 42.8695Z"
        fill="#1D232A"
      />
      <path
        d="M11.1532 65.1511L2.12793 61.0123C1.71993 60.8252 1.54087 60.3431 1.728 59.9358L4.1386 54.6787C4.32572 54.2707 4.80784 54.0917 5.21511 54.2788L14.2404 58.4175C14.6484 58.6047 14.8274 59.0868 14.6403 59.4941L12.2297 64.7512C12.0433 65.1592 11.5612 65.3382 11.1532 65.1511Z"
        fill="#1D232A"
      />
      <path
        d="M14.6378 57.5511L5.61328 53.4124C5.20528 53.2253 5.02623 52.7431 5.21335 52.3359L10.9518 39.8228C11.139 39.4148 11.6211 39.2357 12.0284 39.4228L21.0536 43.5616C21.4616 43.7487 21.6407 44.2308 21.4536 44.6381L15.7151 57.1512C15.5279 57.5592 15.0458 57.7382 14.6378 57.5511Z"
        fill="#1D232A"
      />
      <path
        d="M23.7692 40.2299L25.4937 36.8249C25.8856 36.0508 25.4878 35.1393 24.5757 34.7203L17.1054 31.2949C16.0641 30.8171 14.7792 31.1679 14.3315 32.0514"
        fill="#42EC9A"
      />
      <path
        d="M23.7693 40.3399C23.7524 40.3399 23.7355 40.3363 23.7194 40.3282C23.6651 40.301 23.6431 40.2342 23.6709 40.1799L25.3954 36.775C25.5554 36.4595 25.5781 36.1153 25.4607 35.78C25.3176 35.3712 24.9867 35.03 24.5295 34.8201L17.0592 31.3946C16.0766 30.9441 14.8482 31.2743 14.4292 32.1006C14.4021 32.1549 14.3353 32.1762 14.281 32.149C14.2267 32.1219 14.2047 32.0551 14.2326 32.0008C14.7103 31.0571 16.0466 30.688 17.1502 31.1943L24.6212 34.6205C25.1342 34.8553 25.5062 35.2421 25.6691 35.708C25.8041 36.0955 25.777 36.5094 25.5921 36.8748L23.8676 40.2797C23.8478 40.3179 23.8089 40.3399 23.7693 40.3399Z"
        fill="#00A868"
      />
      <path
        d="M10.6533 59.2144L11.7107 56.9088L11.5104 56.817L10.453 59.1227L7.15224 57.6096L8.20968 55.3032L8.01008 55.2114L6.95264 57.5178L3.32464 55.8543L3.23291 56.0539L6.86092 57.7174L5.80128 60.029L2.17327 58.3654L2.08154 58.5657L5.70882 60.2293L4.58314 62.6847L4.78274 62.7764L5.90842 60.321L9.20988 61.8342L8.08346 64.2903L8.28306 64.382L10.5616 59.4148L14.2035 61.0849L14.2953 60.8846L10.6533 59.2144ZM9.30161 61.6346L6.00088 60.1207L7.06052 57.8092L10.3612 59.323L9.30161 61.6346Z"
        fill="#42EC9A"
      />
      <path
        d="M21.6467 59.1964C21.5938 59.1964 21.5469 59.1583 21.5381 59.104L20.3713 52.0057C20.3617 51.9455 20.4021 51.889 20.4623 51.8795C20.5217 51.8692 20.5789 51.9103 20.5885 51.9705L21.7553 59.0687C21.7648 59.1289 21.7244 59.1854 21.6643 59.195C21.6591 59.1957 21.6525 59.1964 21.6467 59.1964Z"
        fill="#1D232A"
      />
      <path
        d="M49.8473 52.9599C48.3731 54.9317 45.2727 57.7664 44.0193 59.6986L41.8743 63.4462L41.8736 63.4477L33.1022 78.7735C32.4557 79.7679 31.1422 80.2126 30.1662 79.7649L24.3 77.0747L29.5799 65.7644L40.2665 42.8721L48.387 46.5962C50.7646 47.6867 51.4192 50.5361 49.8473 52.9599Z"
        fill="#00A868"
      />
      <path
        d="M35.8443 63.292L37.6143 70.887L33.1013 78.7727C32.4548 79.767 31.1412 80.2117 30.1652 79.7641L24.2991 77.0739L35.8443 63.292Z"
        fill="#1D232A"
      />
      <path
        d="M29.6651 76.8297L44.4185 44.7735L35.6692 40.7617C33.5037 39.7688 30.5706 40.9759 29.4838 43.3088L16.5179 71.5344C16.0872 72.4715 16.4101 73.4541 17.2789 73.8526L26.5683 78.1124C27.6544 78.6114 29.1257 78.0016 29.6651 76.8297Z"
        fill="#42EC9A"
      />
      <path
        d="M42.968 44.1094L48.5641 46.6756C46.3986 45.6827 43.4655 46.8898 42.3787 49.2227L41.7051 46.0569L42.968 44.1094Z"
        fill="#42EC9A"
      />
      <path
        d="M28.7249 75.7427L30.9946 70.6947C31.1928 70.2632 31.0159 69.7965 30.5793 69.5954L21.929 65.6291C21.3882 65.3811 20.6815 65.6387 20.4364 66.1722L18.1667 71.2201C17.9686 71.6516 18.1454 72.1183 18.5821 72.3194L27.2323 76.2864C27.7732 76.5337 28.4798 76.2762 28.7249 75.7427Z"
        fill="#1D232A"
      />
      <path
        d="M38.6215 54.0413C38.6061 54.0413 38.5906 54.0384 38.576 54.0311L28.1344 49.2429C28.0794 49.2172 28.0552 49.1519 28.0801 49.0968C28.1051 49.0418 28.1711 49.0176 28.2262 49.0425L38.6677 53.8307C38.7227 53.8564 38.747 53.9217 38.722 53.9767C38.7029 54.0171 38.6633 54.0413 38.6215 54.0413Z"
        fill="#1D232A"
      />
      <path
        d="M27.4813 76.323L18.4561 72.1842C18.0481 71.9971 17.869 71.515 18.0561 71.1077L20.4667 65.8506C20.6538 65.4426 21.136 65.2635 21.5432 65.4507L30.5685 69.5894C30.9765 69.7765 31.1556 70.2587 30.9684 70.6659L28.5578 75.923C28.3714 76.331 27.8893 76.5101 27.4813 76.323Z"
        fill="#1D232A"
      />
      <path
        d="M30.9659 68.723L21.9414 64.5843C21.5334 64.3971 21.3544 63.915 21.5415 63.5077L27.28 50.9946C27.4671 50.5866 27.9492 50.4076 28.3565 50.5947L37.3818 54.7334C37.7898 54.9206 37.9688 55.4027 37.7817 55.81L32.0432 68.3231C31.8561 68.7311 31.374 68.9101 30.9659 68.723Z"
        fill="#1D232A"
      />
      <path
        d="M40.0973 51.4017L41.8218 47.9968C42.2137 47.2226 41.8159 46.3112 40.9038 45.8922L33.4335 42.4667C32.3922 41.989 31.1073 42.3398 30.6597 43.2233"
        fill="#42EC9A"
      />
      <path
        d="M40.0974 51.5118C40.0805 51.5118 40.0636 51.5081 40.0475 51.5001C39.9932 51.4729 39.9712 51.4061 39.9991 51.3518L41.7235 47.9469C41.8835 47.6314 41.9063 47.2872 41.7888 46.9518C41.6458 46.5431 41.3148 46.2019 40.8576 45.992L33.3874 42.5665C32.4048 42.116 31.1764 42.4462 30.7574 43.2725C30.7302 43.3268 30.6634 43.348 30.6091 43.3209C30.5548 43.2937 30.5328 43.227 30.5607 43.1727C31.0384 42.229 32.3747 41.8599 33.4784 42.3662L40.9494 45.7924C41.4623 46.0272 41.8343 46.4139 41.9972 46.8799C42.1323 47.2674 42.1051 47.6813 41.9202 48.0467L40.1957 51.4516C40.1759 51.4898 40.137 51.5118 40.0974 51.5118Z"
        fill="#00A868"
      />
      <path
        d="M26.9814 70.3863L28.0389 68.0806L27.8385 67.9889L26.7811 70.2946L23.4804 68.7814L24.5378 66.475L24.3382 66.3833L23.2808 68.6897L19.6528 67.0261L19.561 67.2257L23.189 68.8893L22.1294 71.2008L18.5014 69.5373L18.4097 69.7376L22.0369 71.4012L20.9113 73.8565L21.1109 73.9483L22.2365 71.4929L25.538 73.006L24.4116 75.4621L24.6112 75.5539L26.8897 70.5866L30.5317 72.2568L30.6234 72.0565L26.9814 70.3863ZM25.6297 72.8064L22.329 71.2926L23.3886 68.981L26.6894 70.4949L25.6297 72.8064Z"
        fill="#42EC9A"
      />
      <path
        d="M37.9748 70.3683C37.922 70.3683 37.875 70.3301 37.8662 70.2758L36.6994 63.1776C36.6899 63.1174 36.7302 63.0609 36.7904 63.0514C36.8498 63.0411 36.9071 63.0822 36.9166 63.1424L38.0834 70.2406C38.0929 70.3008 38.0526 70.3573 37.9924 70.3668C37.9873 70.3676 37.9807 70.3683 37.9748 70.3683Z"
        fill="#1D232A"
      />
    </svg>
  )
}

export function VolumeExemptionPlusIllustration({ width = 56, height = 64, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox="0 0 56 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M37.6355 15.0278C35.9802 17.2418 32.4991 20.4246 31.0918 22.5941L28.6834 26.8019L28.6826 26.8036L18.8341 44.0115C18.1082 45.1279 16.6333 45.6272 15.5375 45.1246L8.95093 42.1041L14.8792 29.4047L26.8782 3.70117L35.9959 7.88266C38.6654 9.10703 39.4004 12.3064 37.6355 15.0278Z"
        fill="#00A868"
      />
      <path
        d="M21.9127 26.6294L23.9001 35.1572L18.8328 44.0112C18.1069 45.1276 16.6321 45.6269 15.5363 45.1243L8.94971 42.1038L21.9127 26.6294Z"
        fill="#1D232A"
      />
      <path
        d="M14.9748 41.8293L31.54 5.83639L21.7162 1.33193C19.2848 0.217144 15.9915 1.57252 14.7713 4.19182L0.213121 35.8837C-0.27053 36.9359 0.0920026 38.0391 1.06754 38.4865L11.4978 43.2695C12.7172 43.8298 14.3692 43.1451 14.9748 41.8293Z"
        fill="#42EC9A"
      />
      <path
        d="M29.9114 5.09082L36.1947 7.97214C33.7633 6.85735 30.47 8.21273 29.2498 10.832L28.4934 7.27756L29.9114 5.09082Z"
        fill="#42EC9A"
      />
      <path
        d="M13.9192 40.6091L16.4676 34.9412C16.6901 34.4568 16.4915 33.9327 16.0012 33.707L6.28866 29.2536C5.68142 28.9751 4.88797 29.2643 4.61277 29.8633L2.06433 35.5312C1.84187 36.0157 2.04044 36.5397 2.53068 36.7654L12.2433 41.2196C12.8505 41.4973 13.644 41.2081 13.9192 40.6091Z"
        fill="#1D232A"
      />
      <path
        d="M25.031 16.2423C25.0137 16.2423 24.9964 16.239 24.9799 16.2308L13.2561 10.8546C13.1943 10.8258 13.1671 10.7524 13.1951 10.6906C13.2231 10.6289 13.2973 10.6017 13.3591 10.6297L25.0829 16.0059C25.1447 16.0347 25.1719 16.108 25.1438 16.1698C25.1224 16.2152 25.0779 16.2423 25.031 16.2423Z"
        fill="#1D232A"
      />
      <path
        d="M12.5226 41.2605L2.38904 36.6135C1.93093 36.4034 1.72989 35.8621 1.93999 35.4048L4.64662 29.5021C4.85673 29.044 5.39805 28.8429 5.85534 29.053L15.9889 33.7C16.4471 33.9101 16.6481 34.4515 16.438 34.9088L13.7314 40.8115C13.5221 41.2696 12.9808 41.4706 12.5226 41.2605Z"
        fill="#1D232A"
      />
      <path
        d="M16.4355 32.7269L6.30273 28.0799C5.84462 27.8698 5.64358 27.3285 5.85369 26.8712L12.2969 12.8214C12.507 12.3633 13.0483 12.1623 13.5056 12.3724L23.6392 17.0194C24.0973 17.2295 24.2984 17.7708 24.0883 18.2281L17.6451 32.2779C17.435 32.736 16.8936 32.937 16.4355 32.7269Z"
        fill="#1D232A"
      />
      <path
        d="M26.6882 13.2782L28.6245 9.45517C29.0644 8.58592 28.6179 7.56259 27.5937 7.09212L19.206 3.24598C18.0369 2.7096 16.5942 3.10344 16.0916 4.09546"
        fill="#42EC9A"
      />
      <path
        d="M26.6881 13.4021C26.6692 13.4021 26.6502 13.398 26.6321 13.3889C26.5711 13.3584 26.5464 13.2835 26.5777 13.2225L28.514 9.39943C28.6936 9.04513 28.7191 8.65871 28.5873 8.28217C28.4266 7.82324 28.055 7.4401 27.5417 7.20446L19.1541 3.35832C18.0508 2.85243 16.6715 3.2232 16.2011 4.15095C16.1706 4.21192 16.0956 4.23582 16.0346 4.20533C15.9737 4.17484 15.949 4.09987 15.9803 4.03889C16.5166 2.97931 18.017 2.56487 19.2562 3.13339L27.6447 6.98035C28.2206 7.24401 28.6384 7.67822 28.8213 8.20142C28.9729 8.63646 28.9424 9.10116 28.7348 9.51148L26.7985 13.3345C26.7763 13.3774 26.7326 13.4021 26.6881 13.4021Z"
        fill="#00A868"
      />
      <path
        d="M11.9616 34.5947L13.1489 32.0059L12.9239 31.9029L11.7366 34.4917L8.03056 32.7927L9.21786 30.2031L8.99375 30.1001L7.80645 32.6897L3.73291 30.8219L3.62992 31.046L7.70346 32.9138L6.5137 35.5092L2.44015 33.6414L2.33716 33.8663L6.40988 35.7342L5.14596 38.4911L5.37007 38.5941L6.63399 35.8372L10.3409 37.5361L9.07614 40.2939L9.30025 40.3968L11.8586 34.8196L15.9478 36.6949L16.0508 36.47L11.9616 34.5947ZM10.4439 37.312L6.73781 35.6122L7.92757 33.0168L11.6336 34.7166L10.4439 37.312Z"
        fill="#42EC9A"
      />
      <path
        d="M24.3049 34.5742C24.2456 34.5742 24.1929 34.5313 24.183 34.4704L22.8729 26.5004C22.8622 26.4329 22.9075 26.3694 22.9751 26.3587C23.0418 26.3472 23.1061 26.3933 23.1168 26.4609L24.4269 34.4308C24.4376 34.4984 24.3923 34.5618 24.3247 34.5725C24.3189 34.5733 24.3115 34.5742 24.3049 34.5742Z"
        fill="#1D232A"
      />
      <path
        d="M54.0389 28.5415C52.3837 30.7554 48.9025 33.9383 47.4952 36.1077L45.0869 40.3156L45.086 40.3172L35.2375 57.5252C34.5116 58.6416 33.0368 59.1409 31.9409 58.6383L25.3544 55.6177L31.2826 42.9184L43.2816 17.2148L52.3993 21.3963C55.0689 22.6207 55.8038 25.82 54.0389 28.5415Z"
        fill="#00A868"
      />
      <path
        d="M38.3163 40.1431L40.3036 48.6708L35.2364 57.5249C34.5105 58.6413 33.0357 59.1406 31.9398 58.638L25.3533 55.6174L38.3163 40.1431Z"
        fill="#1D232A"
      />
      <path
        d="M31.3782 55.3429L47.9435 19.3501L38.1197 14.8456C35.6882 13.7308 32.395 15.0862 31.1747 17.7055L16.6166 49.3974C16.1329 50.4496 16.4954 51.5528 17.471 52.0002L27.9012 56.7832C29.1206 57.3435 30.7726 56.6588 31.3782 55.3429Z"
        fill="#42EC9A"
      />
      <path
        d="M46.3148 18.6045L52.5982 21.4858C50.1667 20.371 46.8735 21.7264 45.6532 24.3457L44.8969 20.7912L46.3148 18.6045Z"
        fill="#42EC9A"
      />
      <path
        d="M30.3228 54.1228L32.8713 48.4549C33.0937 47.9704 32.8952 47.4464 32.4049 47.2207L22.6923 42.7673C22.0851 42.4888 21.2917 42.778 21.0165 43.377L18.468 49.0449C18.2456 49.5293 18.4441 50.0534 18.9344 50.2791L28.6469 54.7333C29.2542 55.011 30.0476 54.7218 30.3228 54.1228Z"
        fill="#1D232A"
      />
      <path
        d="M41.4344 29.7555C41.4171 29.7555 41.3998 29.7522 41.3833 29.744L29.6595 24.3678C29.5977 24.339 29.5705 24.2656 29.5985 24.2038C29.6266 24.142 29.7007 24.1148 29.7625 24.1429L41.4863 29.5191C41.5481 29.5479 41.5753 29.6212 41.5473 29.683C41.5259 29.7283 41.4814 29.7555 41.4344 29.7555Z"
        fill="#1D232A"
      />
      <path
        d="M28.9263 54.7742L18.7927 50.1272C18.3346 49.9171 18.1336 49.3757 18.3437 48.9184L21.0503 43.0157C21.2604 42.5576 21.8017 42.3566 22.259 42.5667L32.3926 47.2137C32.8507 47.4238 33.0518 47.9651 32.8417 48.4224L30.135 54.3251C29.9258 54.7832 29.3844 54.9843 28.9263 54.7742Z"
        fill="#1D232A"
      />
      <path
        d="M32.8391 46.2406L22.7063 41.5936C22.2482 41.3835 22.0471 40.8421 22.2573 40.3849L28.7004 26.3351C28.9106 25.877 29.4519 25.6759 29.9092 25.886L40.0428 30.533C40.5009 30.7431 40.7019 31.2845 40.4918 31.7418L34.0486 45.7915C33.8385 46.2496 33.2972 46.4507 32.8391 46.2406Z"
        fill="#1D232A"
      />
      <path
        d="M43.0918 26.7919L45.028 22.9688C45.468 22.0996 45.0214 21.0763 43.9973 20.6058L35.6096 16.7597C34.4404 16.2233 32.9977 16.6171 32.4951 17.6091"
        fill="#42EC9A"
      />
      <path
        d="M43.0917 26.9158C43.0727 26.9158 43.0538 26.9117 43.0357 26.9026C42.9747 26.8721 42.95 26.7971 42.9813 26.7362L44.9175 22.9131C45.0971 22.5588 45.1227 22.1724 44.9909 21.7958C44.8302 21.3369 44.4586 20.9538 43.9453 20.7181L35.5576 16.872C34.4544 16.3661 33.0751 16.7369 32.6046 17.6646C32.5742 17.7256 32.4992 17.7495 32.4382 17.719C32.3772 17.6885 32.3525 17.6135 32.3838 17.5526C32.9202 16.493 34.4206 16.0785 35.6598 16.6471L44.0483 20.494C44.6242 20.7577 45.0419 21.1919 45.2248 21.7151C45.3765 22.1501 45.346 22.6148 45.1383 23.0252L43.2021 26.8482C43.1798 26.8911 43.1362 26.9158 43.0917 26.9158Z"
        fill="#00A868"
      />
      <path
        d="M28.365 48.1079L29.5523 45.519L29.3274 45.4161L28.1401 48.0049L24.434 46.3059L25.6213 43.7163L25.3972 43.6133L24.2099 46.2029L20.1364 44.3351L20.0334 44.5592L24.1069 46.427L22.9171 49.0224L18.8436 47.1546L18.7406 47.3795L22.8133 49.2474L21.5494 52.0043L21.7735 52.1073L23.0374 49.3504L26.7443 51.0493L25.4796 53.807L25.7037 53.91L28.262 48.3328L32.3512 50.2081L32.4542 49.9831L28.365 48.1079ZM26.8473 50.8252L23.1412 49.1254L24.331 46.53L28.0371 48.2298L26.8473 50.8252Z"
        fill="#42EC9A"
      />
      <path
        d="M40.7084 48.0878C40.649 48.0878 40.5963 48.045 40.5864 47.984L39.2763 40.0141C39.2656 39.9465 39.311 39.8831 39.3785 39.8724C39.4453 39.8608 39.5095 39.907 39.5202 39.9745L40.8303 47.9445C40.841 48.012 40.7957 48.0755 40.7281 48.0862C40.7224 48.087 40.715 48.0878 40.7084 48.0878Z"
        fill="#1D232A"
      />
      <g filter="url(#filter0_d_803_30880)">
        <path
          d="M44.9027 61.2212C49.9653 61.2212 54.0694 57.254 54.0694 52.3601C54.0694 47.4663 49.9653 43.499 44.9027 43.499C39.8401 43.499 35.7361 47.4663 35.7361 52.3601C35.7361 57.254 39.8401 61.2212 44.9027 61.2212Z"
          fill="#E8E9EA"
        />
      </g>
      <path
        d="M41.2927 51.8749V49.3162H40.0387V51.8749H37.4167V53.1289H40.0387V55.8142H41.2927V53.1289H43.902V51.8749H41.2927ZM49.0186 53.4582C50.1459 52.2802 50.7159 51.3682 50.7159 50.1649C50.7159 48.6195 49.3099 47.6822 47.3972 47.6822C46.6246 47.6822 45.7379 47.8342 45.3579 47.9862L45.1426 49.6075C45.5352 49.4302 46.2699 49.2782 46.9159 49.2782C48.1066 49.2782 48.7779 49.6455 48.7779 50.4689C48.7779 51.1909 48.3092 51.8749 47.4352 52.8249C46.5106 53.8002 45.7126 54.6235 44.7626 55.5482L45.1426 56.7769H50.7539L50.9819 55.1682C49.8672 55.1935 48.5246 55.2062 47.3086 55.2062L49.0186 53.4582Z"
        fill="#00A868"
      />
      <defs>
        <filter
          id="filter0_d_803_30880"
          x="34.4525"
          y="43.0712"
          width="20.9004"
          height="20.2893"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.855709" />
          <feGaussianBlur stdDeviation="0.641781" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.290196 0 0 0 0 0.309804 0 0 0 0 0.333333 0 0 0 0.32 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_803_30880" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_803_30880" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
