export function NpsFeedbackIllustration({ width = 186, height = 200, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox="0 0 171 130" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M170.309 76.257c-.516 2.422-1.933 4.542-8.628 9.83-4.85 3.831-8.231 8.94-10.51 15.22-3.872 10.677-6.249 26.287-25.552 28.08-8.189.762-15.016-2.512-18.407-4.74-4.904-3.219-8.964-7.852-10.605-13.072a33.565 33.565 0 01-1.407-7.363 42.198 42.198 0 010-6.855c.48-6.02 2.323-13.103 1.378-22.945-.516-5.342-2.367-7.803-2.702-12.088-.15-1.887.759-3.695 1.706-4.504 2.429-2.094 6.628-1.987 9.057-.786 2.428 1.202 4.632 3.633 6.137 6.782 1.18 2.466 2.23 6.518 2.857 12.148 3.446-10.116 9.896-20.18 18.921-25.73 2.682-1.646 5.614-3.036 8.742-3.259 3.128-.222 5.727.081 7.568 2.652 1.84 2.57 1.512 6.381-.589 8.933 2.839-2.308 6.995-3.37 10.471-2.183a7.415 7.415 0 013.454 2.595 7.584 7.584 0 011.45 4.104c.098 2.957-1.807 6.132-3.766 7.24 2.295-1.118 5.663-1.833 7.826-.605 2.163 1.228 3.26 3.458 2.599 6.546z"
        fill="#E09661"
      />
      <path
        d="M90.342 53.066c4.835 1.06 9.731 3.115 10.874 8.994 1.143 5.88-2.031 13.535-4.315 16.229L80.573 62.432s-8.073-2.256-12.022-3.649l-41.63-13.108c-2.182-2.995-3.844-6.455-4.316-10.148-.473-3.694.064-8.405 2.392-11.285 3.714-4.588 9.573-3.808 11.498-3.722.326-4.135 1.529-9.81 4.925-13.86 5.975-7.124 16.518-7.985 23.133-3.017a21.919 21.919 0 015.764 6.615 22.231 22.231 0 012.738 8.38c2.348.105 4.65.692 6.768 1.727a17.369 17.369 0 015.548 4.294c3.141 3.628 7.289 13.577 4.971 28.407z"
        fill="#1D232A"
      />
      <path
        d="M78.605 49.23l-.138.028.023-.076.115.047zM103.291 100.064l3.931 24.583c-4.904-3.219-8.964-7.852-10.605-13.072a33.57 33.57 0 01-1.407-7.363l8.081-4.148z"
        fill="#1D232A"
      />
      <path
        d="M97.218 82.707a44.112 44.112 0 01-6.272 19c-11.614 18.998-36.497 26.961-59.044 17.75C9.046 110.125-3.498 86.382 1.644 64.275c2.06-8.85 6.795-16.826 13.545-22.812a45.255 45.255 0 0110.383-6.937 46.302 46.302 0 014.979-2.062c10.925-3.835 23.51-3.767 35.436 1.105a51.775 51.775 0 0110.794 5.978c9.026 6.51 15.455 15.616 18.552 25.636.47 1.52.861 3.063 1.172 4.625.443 2.21.72 4.452.83 6.706a43.934 43.934 0 01-.118 6.193z"
        fill="#E09661"
      />
      <path
        d="M87.521 115.011l-11.997-4.876 14.634-37.033 11.998 4.877c4.842 1.968 8.715 5.806 10.767 10.67a20.21 20.21 0 01.174 15.267c-1.941 4.911-5.726 8.839-10.522 10.919a19.416 19.416 0 01-15.054.176z"
        fill="#E09661"
      />
      <path
        d="M92.663 107.183a.384.384 0 01-.271-.112.395.395 0 01-.116-.272 18.422 18.422 0 013.284-10.802 18.01 18.01 0 018.929-6.764.38.38 0 01.437.1.407.407 0 01.097.298.398.398 0 01-.292.345 17.24 17.24 0 00-8.535 6.47 17.633 17.633 0 00-3.146 10.324.4.4 0 01-.106.28.38.38 0 01-.271.123l-.01.01zM97.334 76.422c-8-2.491-17.95-9.174-23.789-16.61-3.959-5.041-8.499-14.807-7.549-26.243 1.564-6.466 9.206-5.555 12.471-3.188 2.84 2.039 7.103 9.04 6.014 15.998 5.03 5.473 9.328 10.064 11.447 16.945.472 1.521 1.742 4.974 2.064 6.523.44 2.21-.766 4.322-.658 6.575z"
        fill="#1D232A"
      />
      <path
        d="M98.873 116.392a.395.395 0 01-.428-.351.394.394 0 01.346-.435c8.423-1.863 11.786-7.043 13.967-11.865a19.83 19.83 0 00-.186-14.954c-2.012-4.763-5.803-8.523-10.543-10.456l-5.823-2.382a.398.398 0 010-.715.382.382 0 01.29-.013l5.822 2.382c4.931 2.012 8.874 5.925 10.964 10.88a20.627 20.627 0 01.189 15.557c-2.269 5.036-5.748 10.407-14.524 12.344l-.075.008zM129.738 81.526a.386.386 0 01-.358-.495c1.737-6.514 9.418-15.885 18.681-22.787a.383.383 0 01.472.13.393.393 0 01.071.253.389.389 0 01-.102.242c-9.134 6.806-16.696 16.001-18.392 22.36a.382.382 0 01-.372.298zM141.892 91.439a.39.39 0 01-.316-.166.4.4 0 01-.053-.357c2.129-6.79 9.402-15.182 18.529-21.375a.382.382 0 01.526.115.4.4 0 01-.095.537c-8.99 6.101-16.144 14.33-18.222 20.96a.396.396 0 01-.369.286z"
        fill="#1D232A"
      />
      <path
        d="M108.564 123.296a.392.392 0 01-.382-.32l-1.92-10.325a.397.397 0 01.315-.466.395.395 0 01.459.32l1.92 10.325a.396.396 0 01-.307.46l-.085.006zM41.641 56.834c1.85-.662 2.5-3.64 1.453-6.653-1.047-3.013-3.396-4.919-5.245-4.257-1.85.66-2.5 3.64-1.453 6.652 1.047 3.013 3.395 4.92 5.245 4.258zM72.166 65.768c1.364-1.403.58-4.428-1.749-6.757-2.33-2.33-5.324-3.08-6.688-1.677-1.364 1.403-.58 4.429 1.749 6.758 2.33 2.329 5.324 3.08 6.688 1.676zM40.155 86.82c-.866 0-1.73-.08-2.58-.242-2.497-.474-4.7-2.562-5.475-5.198-.663-2.243-.186-4.371 1.311-5.842l9.1-8.94a.389.389 0 01.282-.13.383.383 0 01.286.119.393.393 0 01.068.449.392.392 0 01-.099.126L33.951 76.1c-1.29 1.262-1.686 3.102-1.108 5.052.7 2.356 2.656 4.233 4.873 4.657 1.442.265 2.916.297 4.367.097a.386.386 0 01.288.08.397.397 0 01.07.555.389.389 0 01-.26.15c-.671.088-1.348.13-2.026.128z"
        fill="#1D232A"
      />
      <path
        d="M55.215 83.073l-.41-.157-.18-.07c-.16-.063-.32-.131-.478-.197l-.258-.11a20.276 20.276 0 01-.8-.358 18.616 18.616 0 01-.942-.458c-5.002-2.531-8.187-6.02-7.666-8.638a.04.04 0 010-.023c.012-.055.026-.11.044-.163.015-.072.036-.143.062-.212 1.012-2.793 6.414-3.416 12.562-1.636.32.092.642.189.965.296.392.128.79.262 1.187.416.121.042.235.09.349.131l.438.175c.527.212 1.038.434 1.533.668 5.694 2.68 9.307 6.638 8.306 9.397-1.125 3.13-7.715 3.546-14.712.94zM38.674 67.3a2.67 2.67 0 01-.28.34c-2 2.06-6.929.524-11.675-3.389-.075-.06-.15-.12-.225-.186-.134-.115-.273-.233-.41-.356a22.905 22.905 0 01-.997-.91c-.16-.155-.322-.31-.48-.467l-.31-.316-.472-.498c-.051-.057-.1-.107-.147-.165-2.457-2.664-4.176-5.483-4.942-7.878-.633-1.998-.612-3.71.206-4.785.029-.029.05-.063.078-.092.028-.029.09-.102.134-.151 1.951-2.01 6.684-.603 11.325 3.114.145.115.292.23.436.354.145.123.235.193.346.293.258.21.488.424.733.646.315.29.632.59.942.903.212.215.42.427.622.644 4.646 4.9 6.832 10.423 5.116 12.9z"
        fill="#fff"
      />
      <path
        d="M38.674 67.3a2.654 2.654 0 01-.279.34c-2 2.06-6.93.524-11.676-3.39l6.834-9.857c4.651 4.908 6.837 10.431 5.12 12.907zM55.215 83.073l-.41-.157-.18-.07 7.001-10.104c5.694 2.68 9.307 6.638 8.306 9.397-1.13 3.125-7.72 3.541-14.717.934zM54.516 100.602a.393.393 0 01-.253.16.388.388 0 01-.29-.068 11.97 11.97 0 01-2.554-2.458c-2.948 2.277-5.678 3.07-8.056 3.07-5.401 0-8.956-4.098-9.005-4.158a.396.396 0 01.043-.554.384.384 0 01.546.043 12.708 12.708 0 004.597 3.165c3.776 1.486 7.621.74 11.433-2.21-1.032-1.646-1.066-2.905-1.068-2.986a.397.397 0 01.234-.366.382.382 0 01.148-.032c.101.001.199.041.272.113.073.072.116.17.12.272 0 .034.111 2.83 3.742 5.462a.394.394 0 01.09.547zM84.612 69.654c-1.06 6.24.22 11.949 5.382 14l4.535-8.619s-6.538-3.405-9.916-5.381z"
        fill="#1D232A"
      />
    </svg>
  )
}
