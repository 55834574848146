export function CreditCardIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="95" height="70" viewBox="0 0 95 70" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M74.5078 43.1814L63.4041 48.7896L32.9747 64.1535L30.1463 65.5822L25.9651 67.693C24.3945 68.4847 22.6708 68.6524 21.0825 68.288C20.9438 68.2574 20.8067 68.2219 20.6697 68.1832C18.8056 67.6382 17.1657 66.3562 16.224 64.4873L1.5164 35.3624C-0.287984 31.7891 1.14875 27.4289 4.72205 25.6229L53.2647 1.10965C56.838 -0.693126 61.1982 0.741998 63.0058 4.31691L76.6863 31.4085L77.7134 33.4419C79.5194 37.0152 78.0827 41.3754 74.5078 43.1814Z"
        fill="#42EC9A"
      />
      <path
        d="M25.8454 36.6943L30.1459 65.5839L25.9647 67.6946C24.3941 68.4864 22.6703 68.6541 21.082 68.2897L25.8454 36.6943Z"
        fill="#1D232A"
      />
      <path
        d="M20.3242 67.164C20.3097 67.164 20.2952 67.1624 20.2807 67.1608C20.1501 67.1366 20.063 67.0109 20.0856 66.8786L25.6068 36.6523C25.631 36.5201 25.7568 36.4346 25.889 36.4572C26.0196 36.4814 26.1067 36.6072 26.0841 36.7394L20.5629 66.9657C20.5403 67.0818 20.4387 67.164 20.3242 67.164Z"
        fill="#1D232A"
      />
      <path
        d="M88.0833 61.551L34.2984 69.5861C30.3381 70.1779 26.6471 67.4463 26.0553 63.486L21.2339 31.2151C20.6421 27.2548 23.3737 23.5638 27.334 22.972L81.1189 14.9369C85.0792 14.3451 88.7702 17.0767 89.362 21.037L94.1834 53.3079C94.7736 57.2682 92.0436 60.9592 88.0833 61.551Z"
        fill="#00A868"
      />
      <path
        d="M31.6026 52.1598C31.4849 52.1598 31.3817 52.0743 31.3639 51.9534C31.3446 51.8212 31.4349 51.6986 31.5671 51.6776L69.0835 46.0839C69.2157 46.063 69.3383 46.1549 69.3576 46.2871C69.377 46.4193 69.2867 46.5419 69.1544 46.5628L31.6381 52.1566C31.6252 52.1582 31.6139 52.1598 31.6026 52.1598Z"
        fill="#1D232A"
      />
      <path
        d="M47.1485 54.0996C47.0308 54.0996 46.9276 54.0142 46.9098 53.8932C46.8905 53.761 46.9808 53.6385 47.113 53.6175L63.2816 51.2068C63.4138 51.1875 63.5363 51.2778 63.5557 51.41C63.575 51.5422 63.4847 51.6648 63.3525 51.6857L47.184 54.0964C47.1727 54.098 47.1614 54.0996 47.1485 54.0996Z"
        fill="#1D232A"
      />
      <path
        d="M32.2666 56.3184C32.1489 56.3184 32.0457 56.233 32.028 56.112C32.0087 55.9798 32.0989 55.8573 32.2312 55.8363L44.2781 54.04C44.4104 54.019 44.5329 54.1109 44.5523 54.2431C44.5716 54.3754 44.4813 54.4979 44.3491 54.5189L32.3021 56.3152C32.2908 56.3168 32.2779 56.3184 32.2666 56.3184Z"
        fill="#1D232A"
      />
      <path d="M89.5892 22.5836L21.4609 32.7719L22.791 41.6658L90.9192 31.4776L89.5892 22.5836Z" fill="#1D232A" />
      <path
        d="M83.6722 23.9595L81.1952 32.4238L73.3919 33.5912L75.8689 25.1268L83.6722 23.9595Z"
        fill="#FCFCFC"
        stroke="#FCFCFC"
      />
      <path
        d="M88.1287 23.2933L85.6503 31.7562L84.2259 31.9691L86.7029 23.5062L88.1287 23.2933Z"
        fill="#FCFCFC"
        stroke="#FCFCFC"
      />
    </svg>
  )
}
