export function PsnStoreIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={30} height={35} viewBox="0 0 30 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.9293 6.04538C18.1238 6.02106 18.3183 6.04538 18.5127 6.14262C17.2243 7.91733 16.9811 11.2479 16.9568 12.5121H17.8563C17.905 10.859 18.221 8.01457 19.2421 6.67746C20.4333 7.91733 21.187 10.6402 21.4301 12.5121H22.3296C22.0865 10.47 21.2599 7.52835 19.8985 6.06969C20.093 5.97244 20.3118 5.89951 20.5306 5.89951C20.9438 5.89951 21.3328 6.094 21.6975 6.50729C23.0103 7.94164 23.5938 11.2723 23.7153 12.5121H24.6148C24.5176 11.1264 23.9341 7.99026 22.6456 6.26418C22.0378 5.4376 21.3085 5 20.5062 5C19.9714 5 19.5338 5.17018 19.1448 5.46191C18.7072 5.2188 18.2696 5.09724 17.8077 5.14587C15.4252 5.38898 14.7931 9.10857 14.7202 12.4878H15.6197C15.717 8.5251 16.5435 6.19124 17.9293 6.04538Z"
        fill="#F58357"
      />
      <path
        d="M30.5469 12.4636C30.5469 12.342 30.4496 12.2691 30.3038 12.2691L27.9213 11.9287C27.897 11.9287 27.8727 11.9287 27.8484 11.9287C26.9245 11.9287 23.5939 11.9287 18.367 11.9287C13.1402 11.9287 9.78524 12.0016 8.86142 12.0016C8.71555 12.0016 8.594 12.0989 8.594 12.2448C8.54538 13.1443 8.32658 16.6937 8.03484 22.1394C7.7188 27.9011 7.54862 31.0372 7.5 31.8881C7.5 32.0096 7.59724 32.1069 7.7188 32.1312L17.1758 33.5656L26.5113 34.9999C26.5842 34.9999 26.6571 34.9999 26.7301 34.9513L31.3492 31.9853C31.4707 31.9124 31.5436 31.7665 31.5436 31.6207L30.5469 12.4636Z"
        fill="url(#paint0_linear_2626_1617)"
      />
      <path d="M21.0415 27.9248H20.7012V27.8032H21.4791V27.9248H21.1631V28.8243H21.0415V27.9248Z" fill="white" />
      <path
        d="M22.0384 28.8482L21.771 28.0216V28.8482H21.6494V27.8271H21.8439L22.1356 28.7023L22.4274 27.8271H22.5975V28.8482H22.476V28.0216L22.2086 28.8482H22.0384Z"
        fill="white"
      />
      <path
        d="M15.6685 16.377V27.4142L18.1482 28.2164V18.9539C18.1482 18.5163 18.3427 18.2246 18.6587 18.3218C19.072 18.4434 19.1449 18.8324 19.1449 19.27V22.9653C20.7008 23.7189 21.9164 22.9653 21.9164 20.9717C21.9164 18.9296 21.2114 18.0301 19.0963 17.3008C18.2454 17.0577 16.7138 16.5714 15.6685 16.377Z"
        fill="white"
      />
      <path
        d="M18.6104 26.6121L22.5974 25.1777C23.0593 25.0076 23.1079 24.7888 22.7432 24.6672C22.3786 24.5457 21.7222 24.57 21.2603 24.7401L18.6104 25.6883V24.181L18.7562 24.1324C18.7562 24.1324 19.5342 23.8649 20.6039 23.7434C21.6735 23.6218 23.0107 23.7677 24.056 24.1567C25.223 24.5213 25.3688 25.0805 25.0528 25.4695C24.7611 25.8341 24.0317 26.1259 24.0317 26.1259L18.6104 28.0951V26.6121Z"
        fill="white"
      />
      <path
        d="M11.1224 26.4416C9.93111 26.1013 9.71231 25.3963 10.2715 24.983C10.782 24.594 11.6329 24.3266 11.6329 24.3266L15.1823 23.0381V24.4967L12.6296 25.4449C12.1677 25.6151 12.1191 25.8339 12.4838 25.9554C12.8484 26.077 13.5048 26.0527 13.9667 25.8825L15.2066 25.4449V26.7334C15.1337 26.7577 15.0364 26.7577 14.9635 26.782C13.6993 26.9765 12.4108 26.8792 11.1224 26.4416Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2626_1617"
          x1={2.771}
          y1={6.133}
          x2={20.979}
          y2={29.7}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00C0F3" />
          <stop offset="1" stopColor="#007DC5" />
        </linearGradient>
      </defs>
    </svg>
  )
}
