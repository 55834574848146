import { type EntityResource } from '~/domains/platform/infra/deus-ex-machina/entities'
import { type Resource } from '~/lib/types'

const SalesRoleVisionType = 'paymentaccount:paymentlinks:read'

export function findSalesResource(account: EntityResource): boolean {
  for (const resource of account.resources) {
    for (const data of resource.data.data) {
      const hasRole = data.scopes.find(scope => scope.name === SalesRoleVisionType)

      if (hasRole) return Boolean(hasRole)
    }
  }

  return false
}

export function findResourceById(resources: Resource[], id: string) {
  return resources.find(x => x.resourceId === id)
}
