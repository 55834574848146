import { EMAIL_REGEX, PHONE_REGEX } from '~/lib/constants'

export const isNullOrWhiteSpace = (value?: string | null): boolean => {
  return !value || !value.trim()
}

export const emailValidator = (email?: string): boolean => {
  if (!isNullOrWhiteSpace(email)) {
    if (email?.match(EMAIL_REGEX)) {
      return true
    }
  }
  return false
}

export const telValidator = (telefone?: string): boolean => {
  if (telefone) {
    if (telefone.match(PHONE_REGEX)) {
      return true
    }
  }
  return false
}
