export function SkyTvIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={25} height={10} viewBox="0 0 25 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.29011 6.45162C1.75267 7.06698 1.21523 7.68234 0.679199 8.2977C2.6611 9.9146 5.30598 10.3291 7.51498 8.8494C7.81685 8.64711 8.27812 8.18311 8.61666 7.59463L8.27671 9.43646H11.0598L11.7073 5.92538L13.5383 9.43646H16.8278H16.9364H19.6109L20.2682 5.87587H20.2852L24.6792 0.279648H21.5448L19.4402 3.23337L17.9633 0.279648H17.5768H15.176H15.1393L12.0148 4.26462L12.7511 0.279648H9.96802L9.82414 1.06052C8.03408 -0.171614 5.01821 -0.453122 3.22957 0.876617C1.66521 2.04085 1.46914 4.04677 2.94886 5.11905C3.51874 5.53212 4.63876 5.80231 5.45973 6.02158C6.76877 6.37099 6.09027 7.30888 5.29892 7.43478C4.0717 7.63141 3.15763 7.09103 2.29011 6.45162ZM8.98201 5.617C8.89878 5.30154 8.73092 4.98608 8.45444 4.68759C7.44445 3.59692 6.28352 3.78648 5.19031 3.35503C4.42999 3.05371 4.80803 2.34357 5.44563 2.22333C6.56988 2.01114 7.62642 2.39309 8.61525 3.05796L9.70706 1.68861L8.98201 5.617ZM16.8546 9.29359L14.4523 5.0752L16.2903 2.76796L17.543 5.56749L16.8546 9.29359Z"
        fill="#FF0000"
      />
    </svg>
  )
}
