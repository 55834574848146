export function ReceivablesSettlementEveryDayBannerIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="70" height="57" viewBox="0 0 70 57" fill="none" {...props}>
      <path
        d="M57.9245 32.1024C57.9232 33.5929 57.7839 35.0509 57.5261 36.4611C57.4311 37.0032 57.3087 37.5401 57.176 38.0718C57.0054 38.7575 56.7984 39.4328 56.5628 40.0978C56.3936 40.5959 56.2009 41.0863 55.9874 41.5714C55.8703 41.8703 55.7336 42.1665 55.593 42.4602C55.3691 42.9273 55.14 43.3865 54.8822 43.8419C54.7286 44.1201 54.5763 44.3918 54.4071 44.6609C50.1163 51.7558 42.3004 56.5026 33.3831 56.5L24.4958 56.4987L24.3409 8.72308L24.9189 8.7399C24.7783 9.06205 24.9449 9.52909 25.4057 9.52909C25.6557 9.52909 25.9043 9.52391 26.153 9.52391C25.8965 9.83959 26.0345 10.4451 26.5617 10.4257L43.3613 9.77878C43.7167 9.93791 44.0682 10.1022 44.4184 10.2795C45.0237 10.5783 45.6147 10.9018 46.1797 11.2524C40.4922 11.5253 34.806 11.8009 29.1158 12.0778C28.4129 12.1114 28.4246 13.2008 29.1341 13.1684C35.3228 12.867 41.5193 12.5668 47.7119 12.2641C47.7731 12.3081 47.8382 12.3559 47.8994 12.4012C52.7369 15.9202 56.2126 21.1651 57.4441 27.2237C57.7579 28.7956 57.9258 30.4283 57.9245 32.1024Z"
        fill="#1D232A"
      />
      <path
        d="M47.4711 40.6709C42.6818 53.2785 28.5301 59.6593 15.861 54.9255C12.5467 53.6874 9.66582 51.8114 7.29786 49.4917C5.17464 47.4088 3.46799 44.9714 2.2404 42.314L8.08935 37.9114C7.78473 37.0588 7.5478 36.1816 7.37987 35.2877L1.27447 39.8843C1.10394 39.3746 0.949024 38.8584 0.812336 38.3383C0.840976 38.3254 0.86571 38.3099 0.891746 38.2904L6.99584 33.6925L7.08176 32.3146C7.08176 32.3146 6.50897 32.7532 6.36448 32.8632L0.545469 37.2425C0.279904 36.0212 0.110671 34.7766 0.0390724 33.5178C-0.147084 30.2563 0.32807 26.8977 1.54785 23.6374C1.56347 23.5999 1.57519 23.5624 1.59081 23.5249C5.06008 14.3923 13.441 8.52772 22.6134 7.78511C23.1862 7.73854 23.7603 7.71266 24.337 7.70878L33.3311 7.69714L33.4508 7.69584C35.5233 7.69843 37.5411 7.95459 39.4599 8.43845C40.3321 8.65839 41.1926 8.9249 42.0205 9.23282C42.4723 9.4023 42.9213 9.58601 43.3613 9.77878L35.1536 10.0945C36.1286 10.5563 37.0594 11.0764 37.9446 11.6495C40.6901 11.5163 43.4342 11.3843 46.1797 11.2524C46.1875 11.2537 46.1941 11.2601 46.198 11.2627C46.7213 11.5771 47.2264 11.9096 47.7119 12.2641C44.943 12.3999 42.1741 12.5345 39.4052 12.669C42.1676 14.7558 44.4119 17.3834 46.0379 20.3358C49.3574 26.3569 50.106 33.7339 47.4711 40.6709Z"
        fill="#42EC9A"
      />
      <path
        d="M32.9471 17.1674C33.5928 17.5219 34.2085 17.9165 34.7917 18.3447C36.8082 19.8286 38.4393 21.7175 39.6136 23.847C39.7854 24.1601 39.9494 24.4797 40.103 24.8031L13.2393 45.0192C12.9659 44.7889 12.7003 44.5444 12.4452 44.2934C10.6708 42.5559 9.31306 40.4639 8.44476 38.1766C8.18701 37.4987 7.96961 36.8039 7.80037 36.095L32.9471 17.1674Z"
        fill="#FCFCFC"
      />
      <path
        d="M1.27447 39.8843C1.37471 40.1806 1.48003 40.4756 1.59068 40.7667C1.78855 41.2894 2.00738 41.8069 2.2404 42.314L8.08792 37.9114C7.7833 37.0588 7.5478 36.1816 7.37987 35.2877L1.27447 39.8843Z"
        fill="#FCFCFC"
      />
      <path
        d="M23.474 43.371C22.5693 43.371 21.6346 43.2093 20.7129 42.8807C17.2892 41.6594 15.4876 38.0744 15.7219 35.3148C15.731 35.2074 15.8273 35.1298 15.9328 35.1376C16.0408 35.1466 16.1202 35.2411 16.1111 35.3472C15.8885 37.9606 17.5991 41.3566 20.8457 42.5146C23.1824 43.349 25.5999 43.0566 27.3104 41.7318C28.6109 40.7253 29.2696 39.2543 29.1173 37.6966C28.8322 34.7818 27.0383 33.5139 24.7667 31.9097L24.5623 31.7648C22.2933 30.1592 20.4096 28.63 20.325 26.1693C20.269 24.5599 21.0019 23.0811 22.3363 22.1147C23.9232 20.9645 26.1284 20.7278 28.236 21.4795C30.7471 22.376 32.114 24.5935 32.5553 26.3698C32.5814 26.4733 32.5176 26.5794 32.4121 26.6053C32.308 26.6325 32.2012 26.5678 32.1752 26.463C31.7573 24.7837 30.4686 22.6891 28.1032 21.8456C26.088 21.1263 24.069 21.3384 22.5654 22.4291C21.3378 23.3192 20.6635 24.6776 20.7142 26.1564C20.7937 28.4437 22.5289 29.8487 24.7889 31.4491L24.9932 31.594C27.2518 33.1905 29.2032 34.5696 29.5052 37.6604C29.6706 39.3539 28.9585 40.9504 27.5499 42.0397C26.4122 42.9182 24.9854 43.371 23.474 43.371Z"
        fill="#1D232A"
      />
      <path
        d="M19.7587 45.2948C19.7366 45.2948 19.7145 45.291 19.6936 45.2832C19.5921 45.247 19.5387 45.1357 19.5752 45.0348L28.8895 19.152C28.9259 19.0511 29.0379 18.998 29.1394 19.0343C29.241 19.0705 29.2943 19.1818 29.2579 19.2827L19.9436 45.1655C19.9136 45.2457 19.8394 45.2948 19.7587 45.2948Z"
        fill="#1D232A"
      />
      <path
        d="M6.99584 33.6925L0.891746 38.2904C0.867012 38.3099 0.841063 38.3267 0.812424 38.3396C0.712186 37.9774 0.623664 37.6125 0.545557 37.2438L6.36456 32.8645C6.50906 32.7558 7.08185 32.3159 7.08185 32.3159L6.99584 33.6925Z"
        fill="#FCFCFC"
      />
      <path
        d="M12.4856 44.8472C12.4374 44.8472 12.3893 44.8291 12.3515 44.7942C10.3663 42.9169 8.82888 40.5597 7.90591 37.9761C6.72519 34.677 6.56637 31.1425 7.44638 27.7554C7.59869 27.1629 7.79526 26.5484 8.02828 25.9287C8.75468 24.0217 9.7857 22.2816 11.094 20.7563C11.1643 20.6747 11.2867 20.6644 11.3687 20.7343C11.4507 20.8041 11.4611 20.9257 11.3908 21.0072C10.1111 22.4989 9.10356 24.2002 8.39278 26.0658C8.16497 26.6713 7.9736 27.2729 7.8239 27.8525C6.96471 31.1658 7.11963 34.6214 8.27301 37.8467C9.17646 40.3734 10.6787 42.6789 12.621 44.5134C12.6991 44.5872 12.7017 44.7101 12.6275 44.7877C12.5885 44.8278 12.5377 44.8472 12.4856 44.8472Z"
        fill="#1D232A"
      />
      <path
        d="M60.6271 19.1365L58.1381 12.29L51.2477 9.82148L58.1368 7.34782L60.6206 0.5L63.1096 7.34653L70 9.815L63.1109 12.2887L60.6271 19.1365Z"
        fill="#42EC9A"
      />
      <path
        d="M55.1008 28.9858L53.4983 24.5767L49.0618 22.9867L53.4983 21.3941L55.0982 16.9837L56.7008 21.3928L61.1373 22.9828L56.7008 24.5754L55.1008 28.9858Z"
        fill="#42EC9A"
      />
    </svg>
  )
}
