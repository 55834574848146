import { type Roles } from '~/lib/types'

const permissions: Record<string, Partial<Roles>> = {
  '/banking/statement': { banking: true },
  '/banking/': { banking: true },
  '/banking/finance-calendar': { banking: true },
  '/banking/limits': { banking: true },
  '/settings/connected-apps': { banking: true, acquirer: true },
  '/banking/contacts': { banking: true },
  '/acquirer/sales': { acquirer: true },
  '/acquirer/receivables': { acquirer: true },
  '/sva/topups': { banking: true },
  '/acquirer/rav': { acquirer: true },
  '/smb/': { banking: true }
}

function roleControl(roles: Roles, expected: Partial<Roles>) {
  for (const key in expected) {
    if (roles[key as keyof Roles] !== expected[key as keyof Roles]) {
      return false
    }
  }

  return true
}

/**
 * Role Based Access Control
 * */
function rbac(resource: keyof typeof permissions, roles: Roles) {
  function defaultControl(expected: Partial<Roles>) {
    return roleControl(roles, expected)
  }

  if (permissions[resource]) return defaultControl(permissions[resource])

  return true
}

export const access = { rbac, roleControl }
