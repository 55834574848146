export function SecurityOtpLockIllustration() {
  return (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="50" cy="50" r="50" fill="#DCF2E4" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.8567 31.1463C62.7627 28.7055 62.2088 25.8688 60.0212 23.3312C57.8737 20.8401 55.2356 19.2332 49.4647 19.4555C43.6904 19.6779 40.9169 21.478 38.8444 24.1469C36.739 26.8583 36.2055 29.7545 36.2985 32.1694L36.572 39.2693C36.6519 39.2644 36.7323 39.2595 36.8134 39.2546C44.2169 38.8051 56.696 38.0474 63.1234 38.0703L62.8567 31.1463ZM66.9321 38.2879L66.6513 31.0001C66.5472 28.2959 65.7937 24.1355 63.2646 21.2017C60.6955 18.2215 55.7184 15.692 49.3292 15.9382C42.9433 16.1842 38.3692 19.0968 35.9166 22.2552C33.497 25.3712 32.4919 29.5817 32.5971 32.3119L32.8736 39.4898C32.1966 39.5282 31.6724 39.5555 31.3335 39.5686C27.5884 39.7128 25.1874 41.2195 25.2545 42.9612C25.2545 42.9612 25.5496 65.3719 26.3068 70.2777C27.064 75.1836 28.6793 78.2798 31.6435 78.363C34.6077 78.4462 69.2885 76.8448 71.846 76.617C74.4034 76.3891 75.7172 74.6232 75.5983 71.5374C75.5715 70.8423 75.5366 69.1549 75.4903 66.9204C75.3311 59.2351 75.0378 45.0777 74.4821 42.5625C73.7901 39.43 71.5455 38.93 66.9321 38.2879Z"
        fill="#14AA4B"
      />
      <path
        d="M41.5993 57.8819L49.2655 65.3471L60.2383 50.5959"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
