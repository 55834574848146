export function SpotifyIllustration() {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3460_16570)">
        <path
          d="M21.1648 20.4853C25.851 15.799 25.851 8.20102 21.1648 3.51475C16.4785 -1.17153 8.88052 -1.17153 4.19424 3.51475C-0.492036 8.20102 -0.492036 15.799 4.19424 20.4853C8.88052 25.1715 16.4785 25.1715 21.1648 20.4853Z"
          fill="#1ED761"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.4492 9.65033C21.4457 10.5192 20.5347 11.0513 19.7716 10.6196C19.2541 10.3268 18.7245 10.0614 18.1727 9.84013C17.0765 9.40061 15.9405 9.1049 14.7793 8.90004C13.971 8.75752 13.1566 8.66311 12.3378 8.61388C11.4864 8.5627 10.6344 8.55671 9.78178 8.60092C8.80492 8.65145 7.83566 8.75882 6.87921 8.9653C6.52632 9.04158 6.17749 9.13761 5.82849 9.23089C5.6144 9.28806 5.40355 9.29535 5.19026 9.233C4.72369 9.09648 4.4019 8.67833 4.38814 8.18877C4.3747 7.71313 4.66814 7.27118 5.12905 7.11912C5.46654 7.00786 5.81295 6.92041 6.15951 6.84024C7.01751 6.64186 7.88732 6.51943 8.76427 6.43927C9.6953 6.35425 10.6281 6.32688 11.5614 6.3536C13.8609 6.41951 16.1158 6.75571 18.2979 7.50908C19.1877 7.81629 20.044 8.19751 20.8578 8.67234C21.148 8.84174 21.3428 9.08028 21.4169 9.41033C21.4347 9.48887 21.4389 9.57033 21.4492 9.65033Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.587 12.334C9.21647 12.3396 7.81239 12.5097 6.44103 12.9166C6.21754 12.9829 5.99324 12.9874 5.77429 12.8987C5.41801 12.7544 5.1861 12.3973 5.19873 12.0195C5.21185 11.6253 5.45218 11.2821 5.83211 11.166C6.21867 11.048 6.61123 10.9456 7.0059 10.858C7.97758 10.6423 8.96221 10.5281 9.95721 10.4904C12.0978 10.4091 14.1889 10.686 16.2289 11.3399C17.2605 11.6706 18.2473 12.1035 19.1794 12.6578C19.2257 12.6854 19.2719 12.7136 19.3179 12.7417C19.8039 13.0387 19.862 13.6366 19.6301 14.0175C19.365 14.4532 18.8139 14.5886 18.3558 14.3303C18.0973 14.1845 17.8418 14.0324 17.5776 13.8979C16.3664 13.2805 15.082 12.8802 13.7494 12.6281C12.7234 12.4334 11.6876 12.3385 10.587 12.334Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.8014 14.3594C12.3792 14.3761 13.9226 14.5892 15.4206 15.0944C16.2469 15.3732 17.0359 15.7335 17.7852 16.18C17.85 16.2185 17.9159 16.2567 17.9758 16.3022C18.2782 16.5319 18.3567 16.9476 18.1621 17.2749C17.9732 17.5923 17.5729 17.7201 17.2304 17.5688C17.1615 17.5384 17.0973 17.4977 17.0323 17.4592C15.7623 16.7063 14.3919 16.2401 12.9352 16.0171C12.0413 15.8802 11.1425 15.8328 10.2399 15.8682C9.07229 15.9142 7.91939 16.0762 6.7788 16.3286C6.66365 16.3542 6.54511 16.3774 6.42786 16.3785C6.07465 16.3824 5.77651 16.1319 5.71464 15.7923C5.64905 15.4326 5.8272 15.0956 6.15708 14.9629C6.22672 14.9349 6.30105 14.9171 6.37458 14.9006C7.30755 14.6912 8.24928 14.5328 9.20217 14.4522C9.73384 14.407 10.2681 14.3893 10.8014 14.3594Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_3460_16570">
          <rect width="24" height="24" fill="white" transform="translate(0.679688)" />
        </clipPath>
      </defs>
    </svg>
  )
}
