export function DocumentsNeutralErrorIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="88" height="87" viewBox="0 0 88 87" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_2024_46035)">
        <path
          d="M83.1279 11.04L75.8751 72.063L36.162 67.3424L30.1879 66.6331L28.84 66.4723L28.7471 66.462L28.9712 64.5788L29.1659 62.9477L32.7524 32.7569L35.8125 7.00807L35.9983 5.44043L48.2032 6.89009L83.1279 11.04Z"
          fill="#BBBDBF"
        />
        <path
          d="M63.1504 56.1728C63.0546 56.1728 62.9661 56.1094 62.9381 56.0121L49.1375 7.28243C49.1036 7.16445 49.1729 7.04205 49.2894 7.0096C49.4074 6.97568 49.5298 7.045 49.5623 7.1615L63.3643 55.8911C63.3982 56.0091 63.3289 56.1315 63.2124 56.164C63.1902 56.1699 63.1711 56.1728 63.1504 56.1728Z"
          fill="#1D232A"
        />
        <path
          d="M63.4767 61.2697L33.5661 66.8132L30.1875 66.4077L30.332 64.7058L32.7521 32.4342L34.7061 6.386L35.9006 5.8374L49.3517 7.22218L63.4767 61.2697Z"
          fill="#1D232A"
          stroke="#1D232A"
          strokeWidth="1.76968"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M46.9682 -8.22361e-06L0.642578 10.3225L14.0084 70.3059L60.334 59.9834L46.9682 -8.22361e-06Z"
          fill="#E8E9EA"
        />
        <path
          d="M9.98822 20.1201C9.88646 20.1201 9.79503 20.0494 9.77291 19.9476C9.74636 19.8282 9.82157 19.7102 9.94103 19.6836L42.5223 12.422C42.6417 12.394 42.7597 12.4707 42.7863 12.5902C42.8128 12.7096 42.7376 12.8276 42.6181 12.8541L10.0369 20.1157C10.0207 20.1187 10.0044 20.1201 9.98822 20.1201Z"
          fill="#1D232A"
        />
        <path
          d="M12.1064 29.6219C12.0046 29.6219 11.9132 29.5511 11.8911 29.4493C11.8645 29.3299 11.9397 29.2119 12.0592 29.1854L44.6405 21.9238C44.7584 21.8957 44.8779 21.9724 44.9044 22.0919C44.931 22.2113 44.8558 22.3293 44.7363 22.3558L12.155 29.616C12.1388 29.6189 12.1226 29.6219 12.1064 29.6219Z"
          fill="#1D232A"
        />
        <path
          d="M14.2236 39.1222C14.1218 39.1222 14.0304 39.0514 14.0083 38.9496C13.9817 38.8302 14.0569 38.7122 14.1764 38.6857L46.7577 31.424C46.8771 31.3975 46.9951 31.4727 47.0217 31.5922C47.0482 31.7116 46.973 31.8296 46.8535 31.8561L14.2722 39.1163C14.256 39.1192 14.2398 39.1222 14.2236 39.1222Z"
          fill="#1D232A"
        />
        <path
          d="M16.3417 48.6224C16.24 48.6224 16.1485 48.5531 16.1264 48.4499C16.0999 48.3304 16.1751 48.2124 16.2945 48.1859L48.8759 40.9243C48.9953 40.8977 49.1133 40.9729 49.1398 41.0924C49.1664 41.2118 49.0912 41.3298 48.9717 41.3564L16.3904 48.618C16.3727 48.6209 16.3565 48.6224 16.3417 48.6224Z"
          fill="#1D232A"
        />
        <path
          d="M18.457 58.1225C18.3552 58.1225 18.2638 58.0532 18.2417 57.95C18.2151 57.8305 18.2903 57.7125 18.4098 57.686L41.4009 52.5612C41.5189 52.5347 41.6383 52.6099 41.6649 52.7294C41.6914 52.8488 41.6162 52.9668 41.4967 52.9933L18.5056 58.1181C18.4894 58.121 18.4732 58.1225 18.457 58.1225Z"
          fill="#1D232A"
        />
        <g filter="url(#filter0_d_2024_46035)">
          <path
            d="M70.8403 86.7144C79.9624 86.7144 87.3573 79.3195 87.3573 70.1974C87.3573 61.0753 79.9624 53.6804 70.8403 53.6804C61.7182 53.6804 54.3232 61.0753 54.3232 70.1974C54.3232 79.3195 61.7182 86.7144 70.8403 86.7144Z"
            fill="#4A4F55"
          />
        </g>
        <path
          d="M78.7642 75.6702C79.441 76.3484 79.441 77.4455 78.7642 78.1223C78.4258 78.4607 77.982 78.6299 77.5381 78.6299C77.0943 78.6299 76.6505 78.4607 76.3121 78.1223L70.8392 72.6495L65.365 78.1223C65.0266 78.4607 64.5828 78.6299 64.1389 78.6299C63.6951 78.6299 63.2513 78.4607 62.9129 78.1223C62.2361 77.4455 62.2361 76.3484 62.9129 75.6702L68.3871 70.1974L62.9129 64.7231C62.2361 64.0463 62.2361 62.9479 62.9129 62.271C63.5897 61.5942 64.6868 61.5942 65.365 62.271L70.8392 67.7453L76.3121 62.271C76.9889 61.5942 78.0874 61.5942 78.7642 62.271C79.441 62.9479 79.441 64.0463 78.7642 64.7231L73.2913 70.1974L78.7642 75.6702Z"
          fill="#FCFCFC"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_2024_46035"
          x="52.5536"
          y="53.0905"
          width="36.5735"
          height="36.5733"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.17979" />
          <feGaussianBlur stdDeviation="0.88484" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.290196 0 0 0 0 0.309804 0 0 0 0 0.333333 0 0 0 0.32 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2024_46035" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2024_46035" result="shape" />
        </filter>
        <clipPath id="clip0_2024_46035">
          <rect width="86.7143" height="86.7143" fill="white" transform="translate(0.642578)" />
        </clipPath>
      </defs>
    </svg>
  )
}
