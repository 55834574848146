export function PiggyBankIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="106" height="87" viewBox="0 0 106 87" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_1562_47030)">
        <path
          d="M30.9555 14.8398L43.05 14.396L43.4578 14.3808C43.4446 14.3296 43.4313 14.2784 43.4199 14.229C42.9077 12.1918 42.0352 10.0673 40.9672 8.1135C40.7529 7.71706 40.529 7.33009 40.2976 6.95072C38.4785 3.96503 36.2459 1.54651 34.2561 0.734647C33.2261 0.317333 31.8869 0.654978 31.7825 1.96193L31.0579 13.2635L31.0276 13.7548L30.9555 14.8398Z"
          fill="#BBBDBF"
        />
        <path
          d="M30.9555 14.8399L43.05 14.396L43.4578 14.3808C43.4446 14.3296 43.4313 14.2784 43.4199 14.2291C42.9077 12.1918 42.0352 10.0673 40.9672 8.11353L31.0276 13.7548L30.9555 14.8399Z"
          fill="#1D232A"
        />
        <path
          d="M31.3027 14.1552C31.2097 14.1552 31.1187 14.1096 31.0637 14.0262C30.9783 13.8934 31.0163 13.7189 31.1471 13.6316L38.0707 9.13416L31.6915 11.5413C31.555 11.5925 31.4013 11.5318 31.3368 11.4028C31.2723 11.2719 31.316 11.1126 31.4393 11.0348C31.481 11.0083 35.7053 8.34506 38.9414 7.2316C39.0894 7.18228 39.2525 7.26005 39.3037 7.40801C39.3549 7.55596 39.2753 7.71909 39.1273 7.77031C37.7084 8.2578 36.0904 9.05449 34.7303 9.78668L40.3375 7.66977C40.4722 7.61856 40.6258 7.67736 40.6922 7.80824C40.7586 7.93913 40.715 8.09657 40.5936 8.17624L31.4601 14.1096C31.4089 14.14 31.3558 14.1552 31.3027 14.1552Z"
          fill="#1D232A"
        />
        <path
          d="M111.123 40.3451C110.803 40.3451 110.488 40.2521 110.226 40.0757C108.511 38.9111 108.54 36.4451 109.551 34.6658C108.786 34.3908 107.944 34.3358 107.189 34.5482C105.831 34.9333 104.905 36.2649 105.211 37.4011C105.251 37.5529 105.161 37.7084 105.01 37.7502C104.858 37.79 104.702 37.7008 104.661 37.5491C104.272 36.0999 105.359 34.4742 107.034 34.0019C107.94 33.7458 108.957 33.8255 109.864 34.1802C110.313 33.5675 110.848 33.1559 111.459 32.9567C112.11 32.7443 112.906 32.7708 113.699 33.0345C114.469 33.2887 115.406 33.9905 115.706 34.8366C115.759 34.9845 115.682 35.1476 115.534 35.2008C115.386 35.2539 115.222 35.1761 115.169 35.0281C114.942 34.3851 114.151 33.7838 113.519 33.5751C112.84 33.3513 112.17 33.3228 111.634 33.4992C111.169 33.651 110.752 33.9621 110.389 34.4268C110.797 34.6488 111.171 34.9295 111.486 35.2633C112.671 36.5248 113.088 38.1656 112.546 39.4403C112.373 39.8443 112.022 40.1516 111.582 40.2825C111.434 40.3223 111.277 40.3451 111.123 40.3451ZM110.074 34.8954C109.164 36.4508 109.09 38.6132 110.545 39.6034C110.793 39.7722 111.121 39.8197 111.425 39.7324C111.706 39.6489 111.92 39.4649 112.026 39.2146C112.576 37.9171 111.857 36.483 111.074 35.6484C110.789 35.3449 110.448 35.0926 110.074 34.8954Z"
          fill="#1D232A"
        />
        <path
          d="M72.7323 84.7552L79.5858 86.0697C80.4735 86.2405 81.3593 85.7605 81.6989 84.9221L90.1495 64.1797L73.7453 61.0347L71.2034 82.6724C71.0896 83.6588 71.7554 84.5693 72.7323 84.7552Z"
          fill="#4A4F55"
        />
        <path
          d="M80.0525 81.9763C80.024 81.9763 79.9956 81.9725 79.9671 81.963L72.0003 79.48C71.8561 79.4345 71.7726 79.2846 71.8087 79.1385C71.8466 78.9925 71.9927 78.8995 72.1387 78.9299L75.4772 79.5843L71.9339 78.6017C71.7878 78.5619 71.6987 78.4139 71.7328 78.266C71.7651 78.118 71.9111 78.0232 72.0591 78.0478L83.1956 79.958C83.3512 79.9846 83.4536 80.1325 83.427 80.2862C83.4005 80.4398 83.2544 80.546 83.0989 80.5176L77.5278 79.5616L82.7423 81.0089C82.8902 81.0487 82.9775 81.2005 82.9433 81.3484C82.9092 81.4964 82.7631 81.5931 82.6114 81.5628L77.1598 80.4948L80.1341 81.4224C80.2839 81.4698 80.3674 81.6292 80.3219 81.779C80.2858 81.8966 80.1739 81.9763 80.0525 81.9763Z"
          fill="#1D232A"
        />
        <path d="M84.0321 79.201L86.847 72.2888L72.5446 71.5889L71.7138 78.3057L84.0321 79.201Z" fill="#1D232A" />
        <path
          d="M16.7991 71.7878L21.0405 77.1066C21.6039 77.8141 22.5884 78.0247 23.3927 77.6093L43.3023 67.3472L32.8865 54.2891L16.9831 69.2213C16.2623 69.8985 16.1826 71.0138 16.7991 71.7878Z"
          fill="#4A4F55"
        />
        <path
          d="M27.7023 74.876C27.6226 74.876 27.5449 74.8437 27.488 74.7792C27.3817 74.6578 27.2774 74.5345 27.1712 74.4074C26.3384 73.4381 25.3293 72.3209 24.3486 71.2377C23.237 70.0085 22.0894 68.7395 21.1979 67.6811C21.0974 67.5616 21.1125 67.3813 21.232 67.2808C21.3515 67.1784 21.5317 67.1955 21.6323 67.315C22.3645 68.1837 23.275 69.2005 24.195 70.2191C24.1058 70.0844 24.0167 69.9497 23.9294 69.8132C23.017 68.4227 22.1539 67.1101 21.3155 66.0364C21.2225 65.9169 21.2396 65.7462 21.3534 65.6457C21.4672 65.5471 21.6399 65.5546 21.7442 65.6628C22.4631 66.4082 23.3812 67.4439 24.3221 68.5404C23.3793 67.222 22.4669 65.9625 21.8068 65.0823C21.7119 64.9571 21.7385 64.7788 21.8637 64.684C21.9889 64.5891 22.1672 64.6157 22.262 64.7409C24.3714 67.5559 29.0567 74.2424 29.1022 74.3088C29.1895 74.434 29.1629 74.6085 29.0396 74.6996C28.9163 74.7925 28.7437 74.7697 28.647 74.6502C28.6147 74.6104 26.5509 72.0401 24.4397 69.5533C25.4811 71.1391 26.5547 72.7685 27.6056 74.0375C27.7137 74.1627 27.818 74.286 27.9185 74.4055C28.0191 74.525 28.0058 74.7034 27.8863 74.8039C27.8351 74.8532 27.7687 74.876 27.7023 74.876Z"
          fill="#1D232A"
        />
        <path d="M22.2014 64.322L29.0225 74.7074L35.305 71.4694L25.0353 61.6606L22.2014 64.322Z" fill="#1D232A" />
        <path
          d="M55.5409 77.5393H74.2973C92.4618 77.5393 107.185 62.8139 107.185 44.6513C107.185 26.4868 92.4599 11.7632 74.2973 11.7632H55.5409C37.3764 11.7632 22.6528 26.4886 22.6528 44.6513C22.6528 62.8139 37.3764 77.5393 55.5409 77.5393Z"
          fill="#BBBDBF"
        />
        <path
          d="M51.3869 77.2793L71.0973 11.7611C63.8114 11.7611 57.988 10.8411 52.4738 10.0539C34.4705 7.64295 17.9202 20.2819 15.5093 38.2851C13.0984 56.2884 25.0297 75.17 51.3869 77.2793Z"
          fill="#BBBDBF"
        />
        <path
          d="M41.0678 51.6017C41.0014 51.6017 40.935 51.5789 40.8819 51.5315C38.3647 49.3501 38.7631 46.2449 38.7802 46.1121C38.801 45.9565 38.9452 45.8484 39.1007 45.8693C39.2563 45.8901 39.3644 46.0343 39.3435 46.1899C39.3378 46.2259 38.9793 49.1281 41.2537 51.1009C41.3732 51.2033 41.3846 51.3835 41.2821 51.503C41.2271 51.5675 41.1475 51.6017 41.0678 51.6017Z"
          fill="#1D232A"
        />
        <path
          d="M33.8369 51.7797C32.4674 51.7797 31.2534 51.3871 30.5231 50.8446C30.396 50.7516 30.3713 50.5733 30.4643 50.4462C30.5572 50.3191 30.7355 50.2945 30.8626 50.3874C32.4484 51.5635 36.5608 51.9391 39.1102 48.5001C39.2051 48.3749 39.3834 48.3483 39.5086 48.4413C39.6357 48.5342 39.6603 48.7125 39.5674 48.8396C37.9512 51.0172 35.7452 51.7797 33.8369 51.7797Z"
          fill="#1D232A"
        />
        <path
          d="M27.2547 28.7707C28.472 28.7707 29.4589 27.7838 29.4589 26.5665C29.4589 25.3492 28.472 24.3623 27.2547 24.3623C26.0374 24.3623 25.0505 25.3492 25.0505 26.5665C25.0505 27.7838 26.0374 28.7707 27.2547 28.7707Z"
          fill="#1D232A"
        />
        <path
          d="M40.4342 34.6017C41.6515 34.6017 42.6383 33.6148 42.6383 32.3975C42.6383 31.1802 41.6515 30.1934 40.4342 30.1934C39.2168 30.1934 38.23 31.1802 38.23 32.3975C38.23 33.6148 39.2168 34.6017 40.4342 34.6017Z"
          fill="#1D232A"
        />
        <path
          d="M15.4542 44.304L22.4214 47.026C26.6059 48.6611 31.3216 46.5935 32.9567 42.409C34.5918 38.2245 32.5242 33.5089 28.3397 31.8738L21.3725 29.1518C17.1879 27.5166 12.4723 29.5842 10.8372 33.7688C9.20207 37.9514 11.2697 42.6689 15.4542 44.304Z"
          fill="#BBBDBF"
        />
        <path
          d="M25.371 47.8665C24.3542 47.8665 23.3204 47.6806 22.3189 47.2899L15.3517 44.5678C15.2056 44.5109 15.1335 44.3459 15.1904 44.1999C15.2473 44.0538 15.4124 43.9817 15.5584 44.0386L22.5257 46.7606C26.5565 48.335 31.1185 46.3357 32.6929 42.3049C33.575 40.0495 33.3739 37.5551 32.1447 35.4648C32.0651 35.3301 32.1106 35.1556 32.2453 35.0759C32.3799 34.9962 32.5544 35.0418 32.6341 35.1764C33.9524 37.4185 34.1668 40.0931 33.2221 42.5116C31.9247 45.8331 28.7379 47.8665 25.371 47.8665Z"
          fill="#1D232A"
        />
        <path
          d="M24.4358 42.4642C25.1718 42.7525 26.0027 42.3883 26.291 41.6504L27.5429 38.4466C27.8312 37.7106 27.467 36.8798 26.7292 36.5915C25.9932 36.3031 25.1623 36.6673 24.874 37.4052L23.6221 40.609C23.3356 41.345 23.6998 42.1758 24.4358 42.4642Z"
          fill="#4A4F55"
        />
        <path
          d="M17.0627 39.5848C17.7987 39.8731 18.6295 39.5089 18.9179 38.771L20.1698 35.5672C20.4581 34.8312 20.0939 34.0004 19.3561 33.7121C18.6201 33.4237 17.7892 33.7879 17.5009 34.5258L16.249 37.7297C15.9625 38.4656 16.3267 39.2965 17.0627 39.5848Z"
          fill="#4A4F55"
        />
        <path
          d="M54.695 20.2896C56.0057 14.5345 55.0592 6.82555 52.5458 3.97264C51.8117 3.13801 50.4611 2.84399 49.7858 3.96695L43.3023 15.1263"
          fill="#BBBDBF"
        />
        <path
          d="M44.3399 13.6219C44.2906 13.6219 44.2412 13.6087 44.1976 13.584C44.061 13.5043 44.0155 13.3317 44.0952 13.1952L46.5384 8.98978C46.6181 8.8532 46.7907 8.80768 46.9273 8.88735C47.0638 8.96702 47.1094 9.13963 47.0297 9.27621L44.5865 13.4816C44.5334 13.5707 44.4385 13.6219 44.3399 13.6219Z"
          fill="#1D232A"
        />
        <path
          d="M54.695 20.5739C54.6741 20.5739 54.6532 20.572 54.6324 20.5663C54.4787 20.5322 54.3839 20.3785 54.418 20.2249C54.9548 17.8633 55.4044 15.0824 54.4977 9.3994C54.473 9.24386 54.5793 9.0978 54.7348 9.07314C54.8903 9.04848 55.0364 9.1547 55.0611 9.31025C55.9849 15.0938 55.5239 17.9353 54.9738 20.352C54.9435 20.4847 54.8259 20.5739 54.695 20.5739Z"
          fill="#1D232A"
        />
        <path
          d="M92.9474 86.4015L99.0497 85.6086C99.8615 85.5023 100.486 84.8403 100.541 84.0247L99.4822 64.7676H84.3735L91.0467 85.0566C91.2345 85.931 92.0596 86.5153 92.9474 86.4015Z"
          fill="#BBBDBF"
        />
        <path
          d="M54.4426 86.0086H47.464C46.5592 86.0086 45.7814 85.3713 45.6031 84.4835L39.3131 62.5195H56.0151L56.3319 84.2483C56.2617 85.2423 55.4365 86.0086 54.4426 86.0086Z"
          fill="#BBBDBF"
        />
        <path
          d="M59.2341 16.9854C63.4585 17.9073 69.1491 18.1122 72.8044 17.674C72.9713 17.655 73.0965 17.4615 73.0927 17.2377L73.0548 14.6598C73.051 14.4208 72.9012 14.2311 72.7248 14.2368C68.9917 14.3639 63.3523 14.1742 59.3195 13.3548C59.0786 13.3055 58.8604 13.351 58.8661 13.6469L58.9079 16.5226C58.9117 16.7502 59.0482 16.9456 59.2341 16.9854Z"
          fill="#4A4F55"
        />
        <path
          d="M59.2341 16.9857C63.4585 17.9075 69.1491 18.1124 72.8044 17.6742C72.9713 17.6553 73.0965 17.4618 73.0927 17.2379L73.0719 15.8153C73.0681 15.5763 72.9182 15.3885 72.7418 15.3961C69.0107 15.5592 63.3693 15.4264 59.3385 14.6487C59.0976 14.6013 58.8794 14.6487 58.8851 14.9446L58.9079 16.5228C58.9117 16.7505 59.0482 16.9458 59.2341 16.9857Z"
          fill="#1D232A"
        />
        <path
          d="M43.1694 76.2722C43.0461 76.2722 42.9323 76.1906 42.8963 76.0654L40.7566 68.5955C40.713 68.4438 40.8002 68.2863 40.952 68.2427C41.1018 68.1991 41.2612 68.2863 41.3048 68.4381L43.4445 75.908C43.4881 76.0597 43.4008 76.2172 43.2491 76.2608C43.2225 76.2684 43.196 76.2722 43.1694 76.2722Z"
          fill="#1D232A"
        />
        <path
          d="M87.9547 75.9836C87.8333 75.9836 87.7214 75.9058 87.6834 75.7844L85.6026 69.1814C85.5552 69.0316 85.6386 68.8722 85.7885 68.8248C85.9383 68.7774 86.0977 68.8609 86.1451 69.0107L88.2259 75.6137C88.2734 75.7636 88.1899 75.9229 88.04 75.9703C88.0116 75.9779 87.9831 75.9836 87.9547 75.9836Z"
          fill="#1D232A"
        />
      </g>
      <defs>
        <clipPath id="clip0_1562_47030">
          <rect width="105.446" height="85.8497" fill="white" transform="translate(0.277222 0.565918)" />
        </clipPath>
      </defs>
    </svg>
  )
}
