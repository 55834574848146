export function LeagueOfLegendsIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={29} height={30} viewBox="0 0 33 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.688 0c-.004.032-.01.064-.012.095V23.93c0 .055-.001.11.001.165.004.067.023.086.094.091.045.004.092.003.137.003h11.565l.151.001c.079.004.094.027.072.107l-.1.357-.842 2.942c-.212.743-.423 1.487-.636 2.23-.049.17-.016.174-.231.174H1.922c-.059 0-.118-.002-.171-.003-.036-.048-.002-.078.012-.107l.492-.999c.295-.604.588-1.21.887-1.813a.712.712 0 00.075-.328L3.216 9.056c0-1.927-.001-3.855.001-5.783a.747.747 0 00-.078-.342C2.757 2.161 2.379 1.387 2 .614L1.76.12C1.74.08 1.716.04 1.694 0h7.994z"
        fill="#C28F2C"
      />
      <path
        d="M28.34 15.827c-.03.045-.03.095-.032.145-.003.152-.002.302-.009.454-.007.187-.013.375-.03.562a13.45 13.45 0 01-.302 1.853 13.692 13.692 0 01-2.693 5.485 13.843 13.843 0 01-1.584 1.656c-.405.36-.827.694-1.27 1.003l-.104.068c-.004.002-.009 0-.015 0-.018-.032.004-.06.012-.09l.744-2.612c.08-.286.164-.571.242-.858a.775.775 0 01.166-.31 11.642 11.642 0 002.083-3.575c.229-.63.397-1.275.515-1.934.04-.224.064-.452.093-.68.069-.532.09-1.066.075-1.602-.007-.233-.028-.466-.046-.698-.014-.177-.037-.355-.056-.532-.038-.332-.1-.66-.173-.986a11.558 11.558 0 00-2.04-4.413 11.953 11.953 0 00-6.151-4.274 10.79 10.79 0 00-1.402-.326c-.225-.036-.45-.072-.676-.103-.136-.017-.274-.024-.41-.033-.223-.016-.447-.033-.67-.042a5.144 5.144 0 00-.81.014 5.656 5.656 0 00-.766.053c-.24.032-.481.053-.72.092-.36.059-.718.134-1.072.227-.049.013-.097.028-.146.038-.048.01-.077-.015-.084-.066-.003-.032-.003-.064-.003-.097V2.475c0-.168.003-.172.169-.208.304-.065.608-.127.915-.17.249-.033.497-.071.746-.1.15-.018.301-.021.452-.035.219-.02.438-.03.658-.029.344.002.686-.003 1.03.006.192.004.383.028.574.044.11.01.218.02.327.032.173.02.346.034.517.062.28.047.557.1.835.154.597.117 1.18.286 1.756.487 1.024.356 1.993.828 2.908 1.408a13.993 13.993 0 012.851 2.395 13.75 13.75 0 012.912 4.991c.196.6.352 1.212.457 1.835.047.285.088.57.128.854.018.127.018.256.034.382.035.288.02.576.03.864.003.064 0 .13.035.187v.193z"
        fill="#C28F2C"
      />
      <path
        d="M11.006 14.323V6.027c0-.05.002-.1 0-.151 0-.068.025-.115.095-.13.022-.005.044-.014.066-.02.434-.13.874-.236 1.32-.305.23-.036.461-.073.692-.1.163-.02.327-.023.492-.033.146-.009.292-.021.438-.024.215-.005.43.003.645.016.255.015.51.031.765.059a10.617 10.617 0 015.337 2.157 10.501 10.501 0 012.745 3.162 10.177 10.177 0 011.246 3.824c.024.222.049.445.055.67.003.136.017.273.02.41.007.362-.017.723-.048 1.083a9.546 9.546 0 01-.201 1.3 10.368 10.368 0 01-2.556 4.802c-.145.154-.119.135-.307.135H11.207c-.036 0-.073 0-.11-.002-.067-.005-.086-.023-.09-.094-.003-.046-.002-.092-.002-.138v-3.24-5.085zM1.881 22.145c-2.165-3.883-2.179-8.915-.018-12.83.005 0 .01 0 .013.003a.02.02 0 01.005.012v12.815z"
        fill="#C28F2C"
      />
    </svg>
  )
}
