export function ResetPinIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="141" height="203" viewBox="0 0 141 203" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M97.4127 38.027C96.2731 17.1175 78.8886 0.44446 57.6889 0.433843C35.746 0.423225 17.8909 18.2749 17.8802 40.2213L17.8555 101.17L97.4516 101.201L97.4764 40.2532C97.4764 39.5099 97.4552 38.7632 97.4127 38.027ZM34.5639 84.4752L34.5816 40.2284C34.5887 27.4909 44.9444 17.1282 57.6819 17.1352C70.4194 17.1423 80.7821 27.5121 80.775 40.2461L80.7573 84.4929L34.5639 84.4752Z"
        fill="#00A868"
      />
      <path
        d="M97.4764 38.0164L97.4516 101.201L17.8555 101.17L17.8767 52.5128L34.5816 40.2283L34.5639 84.4752L80.7573 84.4929L80.7715 52.7641L97.4127 38.027L97.4764 38.0164Z"
        fill="#1D232A"
      />
      <path
        d="M111.308 57.6836L111.269 154.905C111.269 156.129 110.267 157.131 109.042 157.131L2.38923 157.089C1.16467 157.089 0.163086 156.087 0.163086 154.862L0.202017 57.6412C0.202017 56.406 1.20361 55.415 2.42816 55.415L109.081 55.461C110.309 55.4575 111.311 56.4485 111.308 57.6836Z"
        fill="#00A868"
      />
      <path
        d="M111.311 55.4576L111.268 154.905C111.268 156.13 110.267 157.131 109.042 157.131L84.8271 157.121L84.8696 55.447L111.311 55.4576Z"
        fill="#00A868"
      />
      <path
        d="M111.297 88.1348L111.268 154.905C111.268 156.129 110.267 157.131 109.042 157.131L84.8271 157.12L84.8448 115.726L111.297 88.1348Z"
        fill="#1D232A"
      />
      <path d="M84.8452 109.097L111.297 83.5232L111.301 75.645L84.8488 99.998L84.8452 109.097Z" fill="#1D232A" />
      <path
        d="M55.119 126.924L30.2457 126.914C29.4777 126.914 28.9432 126.146 29.1981 125.42L33.7778 112.686L35.919 106.74C32.3231 104.457 29.9413 100.426 29.9448 95.8502V95.7511C30.005 88.6692 35.7526 82.9605 42.8416 82.964C43.4078 82.964 43.967 82.9994 44.5121 83.0772C45.0783 83.1551 45.6375 83.2684 46.1719 83.3993C47.6761 83.8134 49.0776 84.4681 50.3128 85.3494C52.9742 87.2429 54.8854 90.1273 55.5083 93.4577C55.6534 94.2363 55.7313 95.0397 55.7313 95.8643C55.7277 100.249 53.544 104.114 50.2031 106.45L56.1737 125.48C56.4002 126.192 55.8657 126.924 55.119 126.924Z"
        fill="#1D232A"
      />
      <path
        d="M84.8234 155.446C84.5296 155.446 84.2925 155.209 84.2925 154.915L84.3279 66.8995C84.3279 66.6058 84.565 66.3687 84.8587 66.3687C85.1525 66.3687 85.3896 66.6058 85.3896 66.8995L85.3542 154.919C85.3542 155.209 85.1171 155.446 84.8234 155.446Z"
        fill="#1D232A"
      />
      <g filter="url(#filter0_d_3276_7210)">
        <path
          d="M98.1984 197.566C120.09 197.566 137.837 179.819 137.837 157.927C137.837 136.035 120.09 118.288 98.1984 118.288C76.3065 118.288 58.5596 136.035 58.5596 157.927C58.5596 179.819 76.3065 197.566 98.1984 197.566Z"
          fill="#E8E9EA"
        />
      </g>
      <path
        d="M123.594 141.515L121.181 152.722C120.845 154.283 119.464 155.351 117.929 155.351C117.7 155.351 117.47 155.328 117.237 155.278L106.02 152.898C104.223 152.515 103.074 150.751 103.454 148.95C103.837 147.153 105.604 146.005 107.401 146.388L111.655 147.29C107.395 141.914 100.115 139.401 93.1722 141.531C88.9616 142.823 85.4468 145.645 83.2799 149.473C82.3713 151.074 80.3409 151.637 78.7432 150.731C77.1422 149.823 76.5797 147.792 77.4851 146.194C80.4973 140.876 85.3735 136.961 91.2184 135.167C100.668 132.271 110.567 135.567 116.525 142.73L117.087 140.113C117.473 138.316 119.244 137.171 121.042 137.56C122.839 137.947 123.984 139.717 123.594 141.515Z"
        fill="#00A868"
      />
      <path
        d="M118.915 169.664C115.906 174.983 111.026 178.897 105.185 180.691C102.865 181.403 100.522 181.739 98.2149 181.739C91.1153 181.739 84.3751 178.537 79.8784 173.132L79.3159 175.745C78.9797 177.306 77.5984 178.374 76.0639 178.374C75.8309 178.374 75.5946 178.351 75.3583 178.298C73.5643 177.912 72.4193 176.141 72.8054 174.344L75.2218 163.136C75.6079 161.342 77.372 160.197 79.1661 160.58L90.383 162.96C92.1804 163.343 93.3287 165.107 92.9459 166.908C92.5665 168.705 90.7991 169.853 89.0017 169.471L84.7446 168.569C89.0084 173.944 96.2877 176.457 103.231 174.327C107.441 173.035 110.953 170.213 113.123 166.385C114.028 164.784 116.059 164.222 117.66 165.13C119.261 166.036 119.823 168.066 118.915 169.664Z"
        fill="#00A868"
      />
      <defs>
        <filter
          id="filter0_d_3276_7210"
          x="55.5596"
          y="117.288"
          width="85.2778"
          height="85.2776"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.290196 0 0 0 0 0.309804 0 0 0 0 0.333333 0 0 0 0.32 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3276_7210" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3276_7210" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
