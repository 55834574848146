export function ThumbsUpIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="140" height="144" viewBox="0 0 140 144" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_701_37440)">
        <path
          d="M97.6274 122.558C95.9474 126.51 93.5974 129.558 90.3949 131.218C88.9824 130.275 87.3949 129.478 85.6574 128.798C82.4124 127.525 78.6499 126.65 74.5199 125.96C73.9349 125.865 73.3449 125.77 72.7449 125.68C72.1849 125.595 71.6174 125.513 71.0449 125.433C66.5449 124.805 61.6949 124.32 56.6749 123.73C45.0049 122.363 33.1449 121.438 21.7224 116.328C9.40991 110.82 8.36492 99.0077 8.38242 96.1202C8.39742 93.2327 9.32242 90.1327 11.6099 88.3727C3.65242 77.7977 10.9374 70.6302 14.2824 68.8027C9.62742 59.7677 13.8699 49.5527 28.0299 47.2377C29.8499 46.9402 32.2299 46.8427 34.9774 46.8677C37.7449 46.8952 40.8899 47.0477 44.2174 47.2477C45.2649 47.3127 49.0649 47.5052 49.2324 47.5127C48.9524 46.9052 49.0874 40.5977 49.1349 39.8652C49.3324 36.8202 49.7324 33.7777 50.5524 30.8327C51.9524 25.8177 54.6974 21.0177 58.9824 18.0627C62.1274 15.8952 65.9749 15.2952 69.6749 16.4302C73.6049 17.6352 74.9399 20.6102 74.7749 23.9802C74.6474 26.5877 73.7699 29.0877 73.0174 31.5877C71.9649 35.0727 71.4474 38.7002 71.6474 42.3377C72.1799 52.0102 77.6174 59.3277 92.9999 63.4927C93.4624 63.6177 97.7824 64.3177 97.8199 64.5077C100.717 78.9852 104.077 107.413 97.6274 122.558Z"
          fill="#FFCBD9"
        />
        <path
          d="M25.5546 75.7502C25.5371 75.7502 25.5221 75.7502 25.5046 75.7477C20.2471 75.0352 16.3121 72.6577 13.8121 68.6827C13.7021 68.5077 13.7546 68.2752 13.9296 68.1652C14.1046 68.0552 14.3371 68.1077 14.4471 68.2827C16.8221 72.0602 20.5771 74.3227 25.6046 75.0027C25.8096 75.0302 25.9546 75.2202 25.9271 75.4252C25.8996 75.6127 25.7371 75.7502 25.5546 75.7502Z"
          fill="#1D232A"
        />
        <path
          d="M21.7247 93.6703C21.7197 93.6703 21.7147 93.6703 21.7097 93.6703C17.4247 93.5053 13.8322 91.7553 11.3197 88.6053C11.1897 88.4428 11.2172 88.2078 11.3797 88.0778C11.5422 87.9478 11.7772 87.9753 11.9072 88.1378C14.2797 91.1103 17.6797 92.7628 21.7422 92.9203C21.9497 92.9278 22.1097 93.1028 22.1022 93.3103C22.0897 93.5128 21.9247 93.6703 21.7247 93.6703Z"
          fill="#1D232A"
        />
        <path
          d="M50.0148 54.0602C49.9798 54.2377 49.8248 54.3627 49.6473 54.3627C49.6248 54.3627 49.5998 54.3602 49.5748 54.3552C44.7573 53.4152 38.6398 53.0302 38.5798 53.0252C38.3723 53.0127 38.2148 52.8352 38.2273 52.6277C38.2398 52.4227 38.4198 52.2677 38.6248 52.2777C38.6848 52.2802 44.1923 52.6252 48.9073 53.4677L44.2173 47.2477C45.2648 47.3127 49.0648 47.5052 49.2298 47.5152C49.5198 50.2152 49.8573 52.7602 50.0123 53.9002V53.9077C50.0248 53.9552 50.0248 54.0077 50.0148 54.0602Z"
          fill="#1D232A"
        />
        <path
          d="M98.2697 95.3502L97.6272 122.558C95.9472 126.51 93.5972 129.558 90.3947 131.218C88.9822 130.275 87.3947 129.478 85.6572 128.798C82.4122 127.525 78.6497 126.65 74.5197 125.96C80.5672 120.143 91.2397 108.815 98.2697 95.3502Z"
          fill="#1D232A"
        />
        <path
          d="M94.5773 101.51C87.9448 110.873 77.8873 120.82 72.7448 125.68C72.1848 125.595 71.6173 125.513 71.0448 125.433C75.8448 120.935 86.5598 110.503 93.4798 100.733C93.6948 100.43 94.1148 100.358 94.4173 100.573C94.7223 100.788 94.7923 101.208 94.5773 101.51Z"
          fill="#1D232A"
        />
        <path
          d="M96.8349 62.0452C94.3274 85.7252 90.8049 109.355 84.5374 132.858C84.1499 134.315 85.1349 135.778 86.6274 135.975L125.167 143.698C126.382 143.748 127.307 142.873 127.715 141.725C134.782 121.788 138.76 91.7502 139.212 67.1727C139.235 65.8927 138.32 64.7902 137.052 64.6177L99.6574 59.8277C98.2624 59.6352 96.9849 60.6402 96.8349 62.0452Z"
          fill="#00A868"
        />
        <path
          d="M17.9047 34.5452L22.4497 21.9652L35.0322 17.4277L22.4522 12.8852L17.9147 0.300232L13.3722 12.8802L0.787231 17.4177L13.3672 21.9627L17.9047 34.5452Z"
          fill="#42EC9A"
        />
        <path
          d="M89.0121 45.8128L92.3196 36.6578L101.475 33.3578L92.3221 30.0503L89.0196 20.8953L85.7121 30.0478L76.5571 33.3503L85.7121 36.6578L89.0121 45.8128Z"
          fill="#42EC9A"
        />
        <path
          d="M105.005 29.5227L106.817 24.5052L111.835 22.6977L106.82 20.8852L105.01 15.8677L103.197 20.8827L98.1797 22.6927L103.197 24.5052L105.005 29.5227Z"
          fill="#42EC9A"
        />
      </g>
      <defs>
        <clipPath id="clip0_701_37440">
          <rect width="138.426" height="143.4" fill="white" transform="translate(0.787231 0.300232)" />
        </clipPath>
      </defs>
    </svg>
  )
}
