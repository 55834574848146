export function CellphoneStone(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={112} height={120} fill="none" {...props}>
      <path
        d="M109.488 44.241l-8.053 13.525-1.644 2.76-.876 1.472-30.977 51.999c-2.938 4.931-9.34 6.559-14.3 3.639L17.234 96.219c-4.96-2.914-6.599-9.275-3.66-14.206l41.549-69.746c2.933-4.93 9.335-6.558 14.296-3.643L88.463 19.83l17.358 10.21c4.962 2.914 6.6 9.275 3.667 14.2z"
        fill="#1D232A"
      />
      <path
        d="M109.488 44.24l-8.052 13.525L87.31 34.34 81.16 24.123c1.233-.386 3.992-2.083 7.31-4.297l17.358 10.209c4.956 2.92 6.594 9.28 3.661 14.206zM99.786 60.525l-.877 1.473c-4.093-6.703-8.177-13.405-12.27-20.107-.21-.355.048-.857.39-1.007.415-.172.794.033 1.014.388 1.352 2.232 2.725 4.464 4.078 6.692l7.665 12.561z"
        fill="#42EC9A"
      />
      <path
        d="M42.27 110.946L5.43 89.273c-4.96-2.92-6.599-9.28-3.665-14.206l41.55-69.75c2.938-4.931 9.339-6.56 14.295-3.645l36.838 21.673c4.962 2.92 6.6 9.281 3.667 14.206l-41.55 69.751c-2.933 4.931-9.334 6.559-14.296 3.644z"
        fill="#00A868"
      />
      <path d="M52.62 99.761L10.326 74.886l37.018-62.147 42.292 24.876L52.619 99.76z" fill="#42EC9A" />
      <path
        d="M11.406 74.61l4.197 2.47L51.817 16.28l-4.197-2.469-36.214 60.799z"
        fill="#FCFCFC"
        stroke="#FCFCFC"
        strokeWidth={1.569}
      />
      <path
        d="M72.336 20.894a2.172 2.172 0 01-2.187-2.157 2.172 2.172 0 012.17-2.174 2.172 2.172 0 012.188 2.157 2.172 2.172 0 01-2.17 2.174zM44.36 66.083a.652.652 0 00.254.92c5.108 2.68 11.486.978 14.529-3.992 3.042-4.97 1.633-11.4-3.097-14.696a.658.658 0 00-.937.194l-2.145 3.508a.746.746 0 00.19.98 5.48 5.48 0 011.29 7.155c-1.464 2.395-4.47 3.27-6.972 2.136a.76.76 0 00-.964.285l-2.149 3.51zM47.614 49.825a.689.689 0 00-.83-.504l-5.66 1.657a1.37 1.37 0 00-.972 1.584l1.114 5.772a.689.689 0 001.256.19l5.01-8.19a.634.634 0 00.082-.509z"
        fill="#1D232A"
      />
    </svg>
  )
}
