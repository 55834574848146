import { createGlobalStyle } from 'styled-components'

import { APP_VERSION, DEPLOY_TYPE } from '~/lib/constants'

import colors from './colors'

const fonts = DEPLOY_TYPE() === 'preview' ? `/${APP_VERSION()}/fonts` : '/fonts'

export const GlobalStyle = createGlobalStyle`
 @font-face {
    font-family: "Sharon Sans";
    font-weight: 400;
    font-style: normal;
    src: url("${fonts}/SharonSans-Regular.woff2") format("woff2"),
      url("${fonts}/SharonSans-Regular.woff") format("woff");
  }

  @font-face {
    font-family: "Sharon Sans";
    font-weight: 600;
    font-style: normal;
    src: url("${fonts}/SharonSans-Medium.woff2") format("woff2"),
      url("${fonts}/SharonSans-Medium.woff") format("woff");
  }

  @font-face {
    font-family: "Sharon Sans";
    font-weight: 700;
    font-style: normal;
    src: url("${fonts}/SharonSans-Bold.woff2") format("woff2"),
      url("${fonts}/SharonSans-Bold.woff") format("woff");
  }

  * {
    font-family: "Sharon Sans", Helvetica, Arial, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    text-size-adjust: none;
    -webkit-font-smoothing: antialiased;
  }


  :root{
    line-height: 1.5;
    color: ${colors.darkGray};
  }

  body {
    background:  ${colors.lightGray};
  }

  .debug > * {
    border: 2px solid ${colors.danger};
  }

  @media print {
    .hide-when-print {
      display: none;
    }
  }

  .grecaptcha-badge {
    visibility: hidden;
  }
`
