import { type PropsWithChildren, type ReactElement } from 'react'

import { For } from '~/domains/platform/lib/utilities-components'

export interface ListProps<T> {
  items: T[]
  render: (item: T, index: number) => ReactElement
}

export function List<T>(props: PropsWithChildren<ListProps<T>>): ReactElement {
  const { items, render } = props

  return <For of={items} render={render} />
}
