import { type PropsWithChildren, useState } from 'react'

import { FullScreensStateContext, FullScreensUpdateContext, initialState } from './context'

export function FullScreensProvider(props: PropsWithChildren<Record<string, unknown>>) {
  const { children } = props
  const [fullScreens, setFullScreens] = useState(initialState)

  function toggleFullScreen(key: keyof typeof fullScreens): void {
    setFullScreens({ ...fullScreens, [key]: !fullScreens[key] })
  }

  return (
    <FullScreensStateContext.Provider value={fullScreens}>
      <FullScreensUpdateContext.Provider value={toggleFullScreen}>{children}</FullScreensUpdateContext.Provider>
    </FullScreensStateContext.Provider>
  )
}
