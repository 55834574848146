export function PixKeyNoKeysIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={107} height={139} fill="none" {...props}>
      <path
        d="M86.55 77.756a15.207 15.207 0 012.017 8.421c-.298 5.918-3.878 11.012-9.342 13.293l-24.994 11.063c-5.387 2.249-11.575-.299-13.822-5.691-2.248-5.392.299-11.586 5.686-13.835l13.555-6.289-14.173-8.49c-5.135-2.78-7.046-9.198-4.269-14.335 2.776-5.14 9.19-7.052 14.323-4.272L80.605 72.01a15.238 15.238 0 015.946 5.746z"
        fill="#E8E9EA"
      />
      <path d="M54.657 91.215c-1.901 5.708-.93 11.414 2.916 17.122l-16.828-8.378 13.912-8.744z" fill="#4A4F55" />
      <path
        d="M67.32 101.892c2.689 3.362.553 8.799 2.496 11.418 1.029 1.387 2.377 2.513 3.382 3.916 1.006 1.404 1.644 3.261.993 4.859-.662 1.627-2.502 2.521-4.255 2.587-1.754.067-3.456-.535-5.107-1.129-2.864-1.028-5.23-3.323-6.795-5.934-1.564-2.612-3.377-8.462-3.377-8.462l12.662-7.255z"
        fill="#E8E9EA"
      />
      <path
        d="M68.458 104.238c-.176-.581-1.019-2.753-3.03-3.925-.592-.344-.218-.394-.019-.33l8.167 1.987-5.118 2.268z"
        fill="#4A4F55"
      />
      <path
        d="M71.837 121.097c1.119.155 2.294.039 3.305-.464 1.01-.502 1.84-1.419 2.11-2.515.272-1.097-.076-2.358-.957-3.065-.847-.682-2.001-.78-3.067-1a12.003 12.003 0 01-6.957-4.31"
        fill="#E8E9EA"
      />
      <path
        d="M72.54 121.452c-2.287 0-5.254-.892-6.15-3.01a.251.251 0 01.133-.328.248.248 0 01.328.133c1.057 2.501 5.63 3.158 7.516 2.438a.25.25 0 11.178.467c-.516.195-1.22.3-2.005.3z"
        fill="#4A4F55"
      />
      <path
        d="M70.009 125.825L3.623 138.301a33.692 33.692 0 014.475-24.025l.046-.074c.112-.18.225-.358.341-.536.13-.201.263-.401.399-.599.02-.032.041-.062.063-.092l.023-.035c.067-.099.135-.198.205-.296l.045-.063.036-.051a33.624 33.624 0 0120.269-13.473l.046-.01c.334-.073.673-.143 1.013-.206 18.332-3.445 35.982 8.636 39.425 26.984z"
        fill="#BBBDBF"
      />
      <path d="M27.53 106.442a1.684 1.684 0 01-.043.098l-.06.077.103-.175z" fill="#4A4F55" />
      <path
        d="M69.158 12.647c-.01.629-4.467 26.262-30.977 23.699-1.587-.153-3.164-.61-4.677-1.093-3.663-1.171-7.2-2.843-10.382-5.014a8.487 8.487 0 01-.216-.147c-1.845-1.28-3.605-2.713-5.078-4.413a41.432 41.432 0 01-1.109-1.336c-1.846-2.328-2.972-4.281-3.14-4.775 1.32-.315 2.522-1.115 3.169-2.36.432-.833.61-1.784 1.075-2.6.507-.885 1.328-1.558 2.224-2.046.901-.489 2.176-.644 2.93-1.302.762-.666 1.187-1.566 1.483-2.518.304-.983.507-2.01 1.008-2.908a4.96 4.96 0 013.72-2.475c1.537-.173 3.309.45 4.343 1.604l.914-1.614c.46-.642.99-1.238 1.622-1.714C37.18.797 38.665.356 39.995.77c1.258.39 2.204 1.467 3.44 1.923 2.487.915 5.394-.884 7.848.115 1.24.505 2.08 1.613 2.855 2.653.383.514.805 1.005 1.149 1.539.357.556.946.932 1.562 1.143.177.06.356.108.539.141.966.182 1.958.055 2.935-.023.98-.078 1.998-.101 2.906.275 1.933.796 2.34 2.568 4.139 3.635 0 0 .718.449 1.79.477z"
        fill="#BBBDBF"
      />
      <path
        d="M26.839 17.12l-3.717 13.09a8.313 8.313 0 01-.216-.146c-1.845-1.28-3.605-2.714-5.078-4.414-.394-.454-1.772-2.126-2.14-2.695 5.087-.494 8.862-2.377 11.15-5.834z"
        fill="#4A4F55"
      />
      <path
        d="M54.903 34.424a6.323 6.323 0 01-7.817 4.344 6.33 6.33 0 01-4.34-7.826 6.32 6.32 0 0110.975-2.26 5.399 5.399 0 01.344.466 6.31 6.31 0 01.838 5.276z"
        fill="#E8E9EA"
      />
      <path
        d="M53.721 28.681l-4.258 5.033a.242.242 0 01-.177.088.248.248 0 01-.203-.41l.159-.186 1.41-1.668 2.737-3.236c.116.121.227.248.332.38z"
        fill="#4A4F55"
      />
      <path
        d="M53.043 27.968l-2.392 3.57-1.069 1.597a.25.25 0 01-.34.072l-.005-.004a.252.252 0 01-.069-.347l1.588-2.37-1.461 1.69a.249.249 0 01-.397-.3l1.595-2.443-1.46 1.662a.249.249 0 11-.374-.33l3.196-3.636c.182.098.359.207.528.323l-1.04 1.592L52.59 27.6c.156.115.307.238.452.368z"
        fill="#4A4F55"
      />
      <path
        d="M20.178 29.343a6.344 6.344 0 00.607.794h.002a5.527 5.527 0 00.508.506h.002a5.862 5.862 0 00.66.51c.116.079.236.154.359.223h.002a6.323 6.323 0 009.243-3.74 6.33 6.33 0 00-4.342-7.825 6.32 6.32 0 00-7.817 4.343 6.324 6.324 0 00.776 5.19z"
        fill="#E8E9EA"
      />
      <path
        d="M22.31 27.745a.25.25 0 01-.181-.423c1.036-1.088 1.317-2.573.754-3.976a.249.249 0 11.462-.186c.638 1.588.319 3.274-.858 4.506a.235.235 0 01-.178.079zM51.48 36.324c-.13 0-.239-.1-.247-.231a5.576 5.576 0 011.512-4.2.248.248 0 01.353-.007c.1.094.103.253.007.352a5.071 5.071 0 00-1.375 3.82.25.25 0 01-.231.268c-.005-.002-.012-.002-.019-.002z"
        fill="#4A4F55"
      />
      <path
        d="M32.347 46.963c7.09 2.03 14.48-2.076 16.507-9.172L53.16 22.73c2.028-7.096-2.074-14.492-9.163-16.522-7.09-2.03-14.48 2.076-16.507 9.172l-4.305 15.063c-2.028 7.093 2.074 14.492 9.163 16.521z"
        fill="#E8E9EA"
      />
      <path
        d="M27.287 25.568h0l-.003-.005c-.031-.04-.059-.081-.091-.13a.284.284 0 00-.03-.046c-.412-.624-.514-1.134-.385-1.478a.539.539 0 00.014-.032l.001-.003v-.001h0l.028-.053c.107-.194.31-.35.625-.447.318-.098.734-.13 1.226-.084h0c.69.064 1.506.276 2.36.638h0l.01.003a1.5 1.5 0 01.066.029l.05.021.05.022c.035.016.068.03.102.047h0l.004.002c.25.113.503.24.758.38h.002c.088.048.178.099.264.15l.058.035c.076.046.15.09.222.135h0l.001.001c.3.184.58.38.843.581h0c.796.61 1.392 1.256 1.73 1.84.343.595.383 1.058.216 1.364h0c-.163.297-.555.508-1.208.547-.642.04-1.468-.095-2.373-.408l-.045-.016h-.001c-.253-.09-.513-.191-.772-.307h0-.002l-.069-.032a3.373 3.373 0 00-.042-.02h0l-.007-.002a1.098 1.098 0 01-.056-.026h0l-.009-.005a7.988 7.988 0 01-.169-.08c-.167-.08-.33-.165-.499-.257l-.047-.027a6.317 6.317 0 01-.094-.053l-.001-.001a9.519 9.519 0 01-.218-.13h0l-.004-.002c-1.107-.657-1.977-1.43-2.505-2.15zm4.447 3.092c-.001 0 0 0 0 0h0z"
        fill="#FCFCFC"
        stroke="#FCFCFC"
        strokeWidth={0.572}
      />
      <path
        d="M29.738 103.394a.255.255 0 00-.301.193c-.311 1.434-1.014 2.87-2.011 4.194.527-.961.952-1.994 1.275-3.099.006-.02.008-.042.01-.062.398-.783.693-1.595.874-2.418.227-1.042.267-2.132-.007-3.155l-.051.01A33.624 33.624 0 009.51 112.18c.487.22.979.386 1.398.486 1.688.401 3.535.319 5.284.032 2.97-.491 5.766-1.583 8.015-3.115-.396.335-.798.639-1.192.907-2.337 1.585-7.18 3.909-13.795 2.091-.014.022-.03.042-.044.063-.07.098-.138.197-.205.296l-.023.035c-.022.03-.043.06-.063.092a15.286 15.286 0 006.284 1.369c2.226 0 4.457-.497 6.593-1.496-2.801 1.712-5.512 2.218-6.28 2.331-1.957.291-5.464.235-7.383-.995-.089.142-.175.283-.26.426 1.423.909 3.577 1.218 5.467 1.218.828 0 1.606-.06 2.249-.155.798-.119 3.629-.647 6.53-2.437a15.594 15.594 0 003.279-2.675c2.34-1.948 4.022-4.452 4.566-6.958a.256.256 0 00-.193-.301zm-4.714 6.89c-.002.001-.004.001-.005.005a16.163 16.163 0 01-3.25 2.091c-3.13 1.517-6.48 1.921-9.71 1.211a17.76 17.76 0 001.933.108c4.3 0 7.496-1.625 9.276-2.835 1.779-1.209 3.247-2.733 4.16-4.247.034-.058.07-.117.102-.175a13.969 13.969 0 01-2.506 3.842zM31.145 23.666l-.367 4.896.117.053a10.875 10.875 0 00.84.33c1.856.643 3.46.58 3.925-.27.48-.875-.39-2.321-2.023-3.569-.27-.206-.558-.407-.866-.597-.093-.058-.188-.115-.284-.173a11.285 11.285 0 00-1.054-.546c-.053-.025-.106-.048-.158-.071-.043-.017-.086-.037-.13-.053z"
        fill="#4A4F55"
      />
      <path
        d="M43.668 31.292a7.853 7.853 0 01-.206-.007l.206.007zm0 0c.085.003.17.005.256.005m-.256-.005l.256.005m-.448-.298h0c.063.004.129.005.2.007h.003a6.182 6.182 0 00.316.005c.064 0 .121 0 .18-.002h.005c.085-.002.167-.003.248-.006h.003l.16-.008h0c.089-.006.174-.01.255-.017h.002c.45-.032.883-.09 1.288-.168.828-.16 1.538-.411 2.071-.71a.28.28 0 01.038-.022c.648-.374.967-.785 1.004-1.148v-.023-.007 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0c-.011-.295-.208-.61-.644-.908-.433-.296-1.063-.545-1.834-.708h0a9.766 9.766 0 00-1.466-.189h-.014s0 0 0 0h-.026v-.001h-.003a3.381 3.381 0 01-.131-.007c-.196-.008-.394-.011-.597-.008-.083.002-.163.003-.244.007h-.001c-1.308.047-2.481.307-3.348.688-.496.218-.879.47-1.13.732-.25.26-.356.513-.348.748l4.013 1.815zm0 0a9.478 9.478 0 01-1.768-.245m1.768.245l-1.768-.245m0 0c-.704-.171-1.275-.417-1.665-.702m1.665.702l-1.665-.702m0 0c-.393-.287-.57-.587-.58-.868l.58.868zm3.881 1.245h.065m-.065 0h.065m0 0c.066 0 .131 0 .196-.002l-.196.002z"
        fill="#FCFCFC"
        stroke="#FCFCFC"
        strokeWidth={0.572}
      />
      <path
        d="M34.882 40.76c2.708.58 5.4-.754 6.714-3.017-1.658-.76-4.332-1.03-6.117.223-1.117-1.873-3.665-2.723-5.489-2.71.271 2.604 2.183 4.923 4.892 5.504z"
        fill="#BBBDBF"
      />
      <path
        d="M36.431 39.562c-.673 0-1.33-.085-1.958-.257-1.968-.535-3.594-1.893-4.702-3.93a.25.25 0 11.439-.239c1.042 1.913 2.561 3.19 4.394 3.687 2.093.569 4.531.11 6.864-1.294a.249.249 0 11.257.427c-1.76 1.06-3.584 1.606-5.294 1.606zM44.528 26.723l-.343 4.572a12.693 12.693 0 00.422-.015 11.317 11.317 0 001.582-.19c.86-.166 1.608-.429 2.177-.752l.003-.001c.69-.395 1.12-.879 1.165-1.391l.002-.045v-.077c-.03-.85-1.119-1.555-2.706-1.89a10.016 10.016 0 00-1.51-.194c-.014-.002-.03-.002-.044-.002-.044-.002-.087-.003-.128-.006a10.945 10.945 0 00-.62-.009z"
        fill="#4A4F55"
      />
      <path
        d="M86.463 61c11.157 0 20.202-9.053 20.202-20.22 0-11.168-9.045-20.22-20.202-20.22-11.158 0-20.203 9.052-20.203 20.22C66.26 51.947 75.305 61 86.463 61z"
        fill="#42EC9A"
      />
      <path
        d="M61.066 77.74h0c.063-1.435.007-2.89-.172-4.36m.172 4.36l.112-4.394m-.111 4.394c-.367 8.526-4.864 16.2-11.736 20.765M61.066 77.74L49.331 98.505M60.894 73.38l.284-.034m-.284.034s0 0 0 0l.284-.034m-.284.034a26.282 26.282 0 00-1.745-6.734h0c-3.002-7.386-9.214-12.966-16.722-15.247m18.75 21.947a26.575 26.575 0 00-1.763-6.808C56.38 59.07 50.1 53.432 42.51 51.126m-.083.273s0 0 0 0l.083-.273m-.083.273s0 0 0 0l.083-.273m-.083.273c-.17-.05-.339-.1-.51-.148h-.001a16.02 16.02 0 00-.484-.13l-.004-.002h0c-.108-.03-.217-.057-.327-.083h-.004c-.157-.04-.317-.078-.477-.114h-.003a6.946 6.946 0 00-.265-.058l-.005-.001h0a26.035 26.035 0 00-6.713-.56h0l-.014-.285m8.89 1.108a25.67 25.67 0 00-1.343-.368 22.717 22.717 0 00-.76-.175 26.32 26.32 0 00-6.786-.565m0 0l.013.285-1.568-.169m1.554-.116h-.018l-.402.021m0 0a29.21 29.21 0 00-1.069.088l-.032.004a.73.73 0 01-.033.003m1.134-.095l.017.286s0 0 0 0h.002l-.02-.286zm-1.134.095l.022.285h.009l.007-.001-.038-.284zm17.265 48.37a26.165 26.165 0 01-11.322 4.179m11.322-4.178l-11.322 4.178m0 0c-14.404 1.766-27.51-8.49-29.277-22.906a26.22 26.22 0 014.164-17.705m25.113 40.611L31.12 50.54c-7.726 1.095-14.214 5.467-18.224 11.532m0 0l-.239-.158.239.158zM31.95 50.435h.002l-.034-.284.029.284h.003z"
        fill="#FCFCFC"
        stroke="#FCFCFC"
        strokeWidth={0.572}
      />
      <path
        d="M32.934 53.17c.773 6.312-3.712 12.055-10.018 12.829a11.479 11.479 0 01-10.259-4.086c4.142-6.262 10.896-10.74 18.926-11.724.183-.023.365-.043.548-.062.397.952.672 1.972.803 3.043zM24.74 94c6.683 0 12.102-5.424 12.102-12.113 0-6.69-5.419-12.114-12.102-12.114-6.684 0-12.103 5.424-12.103 12.114S18.056 94 24.74 94zM61.352 77.752c-.369 8.622-4.917 16.377-11.863 20.993a12.102 12.102 0 01-7.08-9.591c-.813-6.64 3.904-12.682 10.54-13.496 3.084-.38 6.041.44 8.403 2.094zM59.414 66.538a11.45 11.45 0 01-6.206 2.79C46.9 70.1 41.165 65.613 40.392 59.3c-.369-3.015.46-5.9 2.118-8.174 7.588 2.303 13.869 7.944 16.904 15.41z"
        fill="#BBBDBF"
      />
      <path
        d="M17.072 109.249c-3.165 0-6.326-.912-9.125-2.732-5.466-3.555-8.335-9.78-7.484-16.249l4.12-31.373c.839-6.377 6.681-10.867 13.054-10.028 6.371.838 10.857 6.688 10.02 13.064l-2.342 17.833 16.743-10.255c5.481-3.357 12.644-1.632 15.998 3.855 3.354 5.485 1.63 12.655-3.851 16.013l-28.383 17.381a16.717 16.717 0 01-8.75 2.491z"
        fill="#E8E9EA"
      />
      <path
        d="M33.735 79.626c8.105-3.469 16.029-6.81 22.828-12.421.308-3.427.015-8.603.208-12.038-3.765-.167-7.312 1.943-9.938 4.648-2.626 2.705-4.54 6.006-6.755 9.057-2.216 3.051-4.882 5.962-8.379 7.37.666 1.105 1.373 2.277 2.036 3.384z"
        fill="#E8E9EA"
      />
      <path
        d="M81.81 55.31l-5.056 25.32-1.433 7.173a2.707 2.707 0 01-3.186 2.126l-19.302-3.862a2.71 2.71 0 01-2.124-3.189l2.469-12.362 4.02-20.132a2.707 2.707 0 013.187-2.126l19.298 3.86a2.715 2.715 0 012.127 3.192z"
        fill="#4A4F55"
      />
      <path
        d="M81.936 54.673l-.227 1.141-.148.739-.1.499-.339 1.7-.143.72-.272 1.358-.02.096-.245 1.225-.091.462-.053.268-.002.01-.01.052-.11.55-.081.405-.005.023-.136.69-.21 1.048-.008.037-.166.837-.04.188-.083.422-.203 1.02-.085.42-.22 1.1-.092.468-.18.9-.327 1.642-.222 1.107-.114.58-.727 3.635-1.954 9.788a2.709 2.709 0 01-3.188 2.127l-.017-.003-1.916-.382-2.105-.421-.663-.133-9.765-1.955a2.7 2.7 0 01-1.556-.928c-.01-.01-.017-.02-.025-.03a2.703 2.703 0 01-.545-2.23l.238-1.193.081-.412 1.129-5.653 1.133-5.67.474-2.367.508-2.544.747-3.74.41-2.063.105-.527.131-.66.015-.077.148-.736.266-1.332.065-.325.08-.404.31-1.552.029-.135.015-.075.081-.399.172-.854.096-.492.093-.466.243-1.214.048-.243c.025-.12.06-.238.101-.35.004-.006.007-.015.01-.023.035-.09.078-.175.127-.254.19-.33.463-.596.79-.772a2.064 2.064 0 011.391-.216l.995.197 4.257.856 1.32.262 1.198.24 1.23.246 5.273 1.053 1.472.295c.376.075.705.248.97.487a2.049 2.049 0 01.642 1.937z"
        fill="#BBBDBF"
      />
      <path
        d="M81.656 54.616h0l-.188.944-20.593 3.04.068-.337h0l.226-1.136 20.515-3.026c.015.168.007.341-.028.515zM81.18 56.996h0l-.338 1.7h0l-.143.72h0l-.272 1.356v.002l-.02.094v.002l-.245 1.225h0l-.092.463h0l-.014.07-20.59 3.039.667-3.342h0l.41-2.063h0l.066-.33 20.592-3.037-.02.1z"
        fill="#FCFCFC"
        stroke="#FCFCFC"
        strokeWidth={0.572}
      />
      <path
        d="M75.215 66.714a2.218 2.218 0 01-1.736-2.612l1.739-8.65a2.216 2.216 0 114.345.874l-1.739 8.651a2.216 2.216 0 01-2.61 1.737z"
        fill="#BBBDBF"
      />
      <path
        d="M75.647 66.8a2.218 2.218 0 01-1.736-2.611l1.74-8.651a2.216 2.216 0 114.344.875l-1.739 8.65a2.216 2.216 0 01-2.61 1.738z"
        fill="#E8E9EA"
      />
      <path
        d="M79.397 56.175a1.612 1.612 0 01-.331.794c-.544.69-1.541.806-2.23.262a1.59 1.59 0 01.938-2.833c.361-.01.725.102 1.03.343.451.354.656.905.593 1.434zM78.579 60.33a1.6 1.6 0 01-.33.793c-.544.69-1.542.806-2.23.263a1.59 1.59 0 01.938-2.834 1.592 1.592 0 011.622 1.779zM77.701 64.708a1.61 1.61 0 01-.335.802 1.604 1.604 0 11-2.517-1.992 1.6 1.6 0 012.254-.264c.454.363.661.92.598 1.454z"
        fill="#4A4F55"
      />
      <path
        d="M47.8 85.07c5.936 3.797 13.727 4.52 20.259 1.878 1.247-.504 2.475-1.145 3.373-2.147.897-1.001 1.539-3.478.359-5.01.999-.423 1.853-1.078 2.461-1.975.61-.897.875-2.056.569-3.098-.306-1.041-1.23-1.91-2.31-2.013 1.424-.57 2.32-2.16 2.13-3.683-.188-1.524-1.487-2.842-3.008-3.048-3.717.472-7.455.941-11.192.68-3.736-.263-7.498-1.262-10.553-3.432"
        fill="#E8E9EA"
      />
      <path
        d="M63.428 81.41c-4.491 0-8.13-.91-8.188-.924a.249.249 0 01.123-.483c.092.024 9.234 2.306 16.336-.444a.251.251 0 01.323.143.25.25 0 01-.143.323c-2.726 1.055-5.743 1.386-8.45 1.386zM64.068 74.354c-4.491 0-8.13-.91-8.188-.925a.249.249 0 01.123-.483c.092.024 9.234 2.306 16.336-.444a.251.251 0 01.323.143.25.25 0 01-.143.323c-2.726 1.055-5.745 1.386-8.45 1.386z"
        fill="#4A4F55"
      />
      <path
        d="M44.02 22.57c1.03-.328 1.708-1.09 1.513-1.703-.195-.613-1.188-.844-2.218-.516-1.031.328-1.709 1.09-1.514 1.704.195.613 1.188.844 2.219.515zM36.728 20.902c.467-.44.245-1.437-.496-2.225-.742-.787-1.722-1.069-2.19-.627-.468.44-.246 1.437.496 2.225.742.787 1.722 1.068 2.19.627z"
        fill="#BBBDBF"
      />
      <path d="M39.484 27.345l-.2 4.998a2.139 2.139 0 01-2.654 1.99l-2.481-.62" fill="#E8E9EA" />
      <path
        d="M37.15 34.645c-.194 0-.388-.023-.58-.071l-2.483-.62a.25.25 0 01.122-.485l2.481.62a1.879 1.879 0 001.59-.32c.457-.343.73-.865.754-1.436l.201-4.998a.25.25 0 11.499.02l-.201 4.998a2.384 2.384 0 01-.954 1.815 2.37 2.37 0 01-1.428.477z"
        fill="#4A4F55"
      />
      <path
        d="M31.55 11.764l-.433 4.758 22.622 5.373s2.821-4.49-.347-10.499c-3.906-7.41-14.215-7.385-14.215-7.385-5.267-.373-9.81 2.915-10.57 5.382l-2.33 10.225s.88.075 1.217-.022c.748-.215 1.097-.567 1.66-1.444.964-1.497 1.543-3.588 2.395-6.388z"
        fill="#BBBDBF"
      />
      <path
        d="M53.555 21.093a.252.252 0 01-.243-.313 11.765 11.765 0 00-1.325-9.105.767.767 0 01-.08-.144.25.25 0 01.464-.185l.04.065a12.281 12.281 0 011.385 9.494.25.25 0 01-.241.188zM66.209 20.895a.252.252 0 01-.234-.162c-.742-2.014-2.314-3.34-4.43-3.737-.354-.067-.761-.11-1.194-.156-1.788-.19-4.014-.428-4.895-2.526a.25.25 0 01.459-.193c.768 1.827 2.744 2.036 4.488 2.223.442.046.86.091 1.233.161 2.296.431 4.003 1.872 4.806 4.055a.25.25 0 01-.233.335zM62.772 25.85a.25.25 0 01-.236-.17c-.444-1.322-1.189-3.538-3.712-3.845a.25.25 0 01-.218-.277.251.251 0 01.278-.218c2.836.344 3.673 2.841 4.124 4.182a.25.25 0 01-.157.316.273.273 0 01-.08.012zM14.921 21.96a.25.25 0 01-.03-.497c1.548-.187 2.67-1.677 3.2-2.549a.249.249 0 11.425.26c-.997 1.638-2.264 2.628-3.565 2.785l-.03.001zM26.317 19.728a.25.25 0 01-.243-.304l1.137-5.027a.25.25 0 01.485.11l-1.137 5.026a.25.25 0 01-.242.195zM56.058 65.814h-.011c-2.2-.098-4.741-1.84-4.85-1.913a.25.25 0 01.284-.41c.026.016 2.533 1.733 4.587 1.826a.25.25 0 01-.01.497z"
        fill="#4A4F55"
      />
      <path
        d="M87.792 41.824h-.286v8.681a1.044 1.044 0 11-2.087 0v-8.681h-8.673a1.045 1.045 0 010-2.09h8.673v-8.682a1.045 1.045 0 012.087 0v8.682h8.676a1.044 1.044 0 010 2.09h-8.39z"
        fill="#FCFCFC"
        stroke="#FCFCFC"
        strokeWidth={0.572}
      />
      <path
        d="M11.512 89.358a.25.25 0 01-.231-.34c.924-2.377 3.075-4.293 6.577-5.859.753-.336 1.534-.65 2.289-.953 1.936-.777 3.938-1.582 5.616-2.836a.25.25 0 11.3.399c-1.73 1.294-3.764 2.111-5.73 2.9-.75.3-1.526.612-2.27.945-3.376 1.507-5.442 3.334-6.317 5.584-.04.1-.134.16-.234.16zM42.995 118.529a.25.25 0 01-.237-.173l-4.8-14.78a.25.25 0 11.474-.155l4.8 14.78a.248.248 0 01-.16.314.222.222 0 01-.077.014zM42.995 107.63a1.916 1.916 0 010-3.83 1.916 1.916 0 010 3.83zm0-3.329c-.78 0-1.414.636-1.414 1.416 0 .78.634 1.416 1.414 1.416.78 0 1.415-.636 1.415-1.416 0-.78-.635-1.416-1.415-1.416zM41.995 50.976a4.545 4.545 0 01-.75 1.526c-.723.936-1.789 1.407-3.171 1.407-.114 0-.228-.002-.345-.01-2.172-.115-4.358-1.311-6.648-3.642.166-.023.334-.046.502-.066.111-.015.224-.027.335-.04.318.27.632.512.941.73a11.6 11.6 0 01-.793-.747l.065-.007c.358-.036.713-.064 1.069-.088a8.151 8.151 0 002.217 1.47c-.63-.355-1.328-.992-1.797-1.491a26.32 26.32 0 016.787.565 2.864 2.864 0 01-.324.954c-.218.386-.527.68-.914.893.867-.368 1.3-1.09 1.514-1.787.161.037.324.075.484.115-.245.875-.773 1.815-1.89 2.263-.446.178-.968.266-1.536.266-.076 0-.155-.005-.231-.007.08.007.161.018.241.022 1.395.075 2.448-.331 3.125-1.205.297-.382.495-.822.627-1.254.164.041.329.086.492.133z"
        fill="#4A4F55"
      />
    </svg>
  )
}
