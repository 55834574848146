export function SalesHomePixPosTerminalInfoIllustration() {
  return (
    <svg width="46" height="61" viewBox="0 0 46 61" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M43.1287 15.4652C41.5256 18.5887 37.8409 23.3136 36.5391 26.3286L34.4163 32.109L34.4156 32.1115L25.74 55.7514C25.0677 57.3039 23.3267 58.2421 21.8446 57.845L12.9424 55.4596L17.6661 38.2988L27.2266 3.56493L39.5513 6.86731C43.1587 7.83124 44.7615 11.6832 43.1287 15.4652Z"
        fill="#00A868"
      />
      <path
        d="M25.8938 33.328L31.8723 39.0428L25.7413 55.7466C25.069 57.299 23.328 58.2372 21.8459 57.8401L12.9437 55.4547L25.8938 33.328Z"
        fill="#1D232A"
      />
      <path
        d="M20.4382 53.8379L33.529 5.25123L20.2504 1.69323C16.9625 0.812245 13.1247 3.20906 12.1527 6.74694L0.661662 49.5224C0.281163 50.9425 0.970217 52.2466 2.28787 52.5997L16.386 56.3772C18.0342 56.8215 19.9599 55.6129 20.4382 53.8379Z"
        fill="#42EC9A"
      />
      <path
        d="M31.3248 4.66034L39.8184 6.93621C36.5306 6.05523 32.6928 8.45205 31.7208 11.9899L30.0138 7.69959L31.3248 4.66034Z"
        fill="#42EC9A"
      />
      <path
        d="M18.8511 52.5275L20.8362 44.8897C21.0115 44.2357 20.6501 43.6217 19.9875 43.4442L6.85834 39.9262C6.03885 39.7066 5.10383 40.2371 4.88725 41.0453L2.9021 48.6832C2.72685 49.3372 3.08827 49.9512 3.75083 50.1287L16.88 53.6467C17.702 53.8669 18.6339 53.3383 18.8511 52.5275Z"
        fill="#1D232A"
      />
      <path
        d="M27.4597 19.8815L11.613 15.6354C11.4063 15.58 11.2841 15.3687 11.3394 15.1623C11.3947 14.9559 11.6062 14.834 11.813 14.8894L27.6596 19.1355C27.8664 19.1909 27.9886 19.4022 27.9333 19.6086C27.878 19.815 27.6664 19.9369 27.4597 19.8815Z"
        fill="#1D232A"
      />
      <path
        d="M17.2416 53.6423L3.54446 49.9721C2.92673 49.8066 2.55753 49.1719 2.72345 48.5527L4.85785 40.587C5.02311 39.9703 5.65837 39.602 6.27859 39.7682L19.9757 43.4384C20.5934 43.6039 20.9626 44.2386 20.7967 44.8578L18.6623 52.8235C18.4964 53.4427 17.8593 53.8078 17.2416 53.6423Z"
        fill="#1D232A"
      />
      <path
        d="M20.424 41.7653L6.72688 38.0951C6.30819 37.9829 6.05907 37.5531 6.17109 37.1351L11.2515 18.1746C11.3632 17.758 11.7927 17.5085 12.2129 17.6211L25.91 21.2912C26.3287 21.4034 26.5778 21.8332 26.4658 22.2513L21.3853 41.2117C21.2733 41.6298 20.8427 41.8774 20.424 41.7653Z"
        fill="#FCFCFC"
        stroke="#FCFCFC"
        strokeWidth="0.750167"
      />
      <path
        d="M29.0294 15.5974L30.6411 10.3997C31.0082 9.21865 30.2291 8.03163 28.8442 7.66055L17.5084 4.62314C15.9292 4.2 14.2017 4.99789 13.7842 6.34672"
        fill="#42EC9A"
      />
      <path
        d="M28.9294 15.9704C28.9244 15.9691 28.9194 15.9678 28.9145 15.9664C28.7097 15.9036 28.5969 15.6868 28.6597 15.4824L30.2713 10.2847C30.4027 9.86406 30.3572 9.43606 30.1435 9.04826C29.8793 8.56964 29.37 8.19862 28.7448 8.0311L17.409 4.99369C16.0316 4.62461 14.5105 5.30988 14.1545 6.45927C14.0917 6.66368 13.8797 6.77746 13.6699 6.71327C13.4652 6.65041 13.3524 6.43361 13.4152 6.2292C13.8986 4.67414 15.818 3.76774 17.6089 4.24761L28.9447 7.28502C29.7841 7.50994 30.4502 8.00295 30.8201 8.67513C31.1329 9.24139 31.201 9.89403 31.0088 10.5116L29.3972 15.7093C29.3382 15.9094 29.1312 16.0245 28.9294 15.9704Z"
        fill="#00A868"
      />
      <path
        d="M19.5962 29.2631L17.9423 28.8199C17.8177 28.7866 17.7448 28.6604 17.7781 28.536L18.2205 26.8847C18.2539 26.7604 18.3802 26.6876 18.5047 26.721L20.1586 27.1641C20.2831 27.1975 20.3561 27.3237 20.3228 27.448L19.8803 29.0993C19.8477 29.2212 19.7207 29.2965 19.5962 29.2631Z"
        fill="#1D232A"
      />
      <path
        d="M17.5637 32.2955L17.0132 32.148C16.9709 32.1367 16.9471 32.0957 16.9585 32.0534L17.1057 31.5038C17.1171 31.4615 17.1581 31.4378 17.2005 31.4492L17.7509 31.5967C17.7933 31.608 17.817 31.649 17.8057 31.6913L17.6584 32.2409C17.6471 32.2832 17.606 32.3069 17.5637 32.2955Z"
        fill="#1D232A"
      />
      <path
        d="M17.1884 33.6955L16.638 33.548C16.5956 33.5367 16.5719 33.4957 16.5832 33.4534L16.7305 32.9038C16.7418 32.8615 16.7829 32.8378 16.8252 32.8492L17.3757 32.9967C17.418 33.008 17.4418 33.049 17.4304 33.0913L17.2832 33.6409C17.2718 33.6832 17.2308 33.7069 17.1884 33.6955Z"
        fill="#1D232A"
      />
      <path
        d="M18.9658 32.6712L18.4153 32.5237C18.373 32.5123 18.3492 32.4713 18.3606 32.429L18.5078 31.8794C18.5192 31.8371 18.5602 31.8135 18.6026 31.8248L19.153 31.9723C19.1954 31.9837 19.2191 32.0247 19.2078 32.067L19.0605 32.6166C19.0492 32.6589 19.0081 32.6825 18.9658 32.6712Z"
        fill="#1D232A"
      />
      <path
        d="M18.0782 33.185L17.5277 33.0375C17.4854 33.0261 17.4617 32.9851 17.473 32.9428L17.6203 32.3932C17.6316 32.3509 17.6726 32.3273 17.715 32.3386L18.2654 32.4861C18.3078 32.4975 18.3315 32.5385 18.3202 32.5807L18.1729 33.1304C18.1622 33.1702 18.118 33.1956 18.0782 33.185Z"
        fill="#1D232A"
      />
      <path
        d="M13.4971 32.7065L11.8432 32.2634C11.7186 32.23 11.6456 32.1038 11.6789 31.9795L12.1214 30.3281C12.1547 30.2038 12.281 30.131 12.4056 30.1644L14.0595 30.6075C14.184 30.6409 14.257 30.7671 14.2237 30.8914L13.7812 32.5428C13.7479 32.6671 13.6216 32.7399 13.4971 32.7065Z"
        fill="#1D232A"
      />
      <path
        d="M14.7665 27.969L13.1126 27.5258C12.988 27.4924 12.915 27.3663 12.9484 27.2419L13.3908 25.5906C13.4242 25.4662 13.5504 25.3935 13.675 25.4268L15.3289 25.87C15.4534 25.9034 15.5264 26.0295 15.4931 26.1539L15.0506 27.8052C15.018 27.9271 14.891 28.0023 14.7665 27.969Z"
        fill="#1D232A"
      />
      <path
        d="M20.6898 31.3685L11.1973 28.825C10.9905 28.7696 10.8683 28.5582 10.9236 28.3518C10.9789 28.1454 11.1904 28.0235 11.3972 28.0789L20.8898 30.6224C21.0965 30.6778 21.2187 30.8891 21.1634 31.0956C21.1081 31.302 20.8941 31.4232 20.6898 31.3685Z"
        fill="#1D232A"
      />
      <path
        d="M14.673 34.8341C14.4662 34.7787 14.344 34.5674 14.3993 34.361L16.9389 24.8832C16.9942 24.6768 17.2057 24.5549 17.4124 24.6103C17.6192 24.6657 17.7414 24.877 17.6861 25.0835L15.1465 34.5612C15.0912 34.7676 14.8797 34.8895 14.673 34.8341Z"
        fill="#1D232A"
      />
      <path
        d="M18.9688 35.9879L10.3754 33.6853C9.92209 33.5638 9.64958 33.0963 9.77152 32.6412L12.0712 24.0588C12.1925 23.6061 12.6604 23.3344 13.1162 23.4565L21.7096 25.7591C22.1629 25.8806 22.4354 26.3481 22.3135 26.8032L20.0138 35.3856C19.8919 35.8407 19.4221 36.1093 18.9688 35.9879ZM12.9156 24.2051C12.8733 24.1937 12.8291 24.2192 12.8178 24.2615L10.5181 32.8439C10.5068 32.8862 10.5323 32.9304 10.5747 32.9417L19.168 35.2443C19.2104 35.2556 19.2546 35.2302 19.2659 35.1879L21.5656 26.6055C21.5769 26.5632 21.5514 26.519 21.509 26.5077L12.9156 24.2051Z"
        fill="#1D232A"
      />
      <path d="M20.7017 46.6768L4.05298 42.2158L3.85306 42.9619L20.5018 47.4229L20.7017 46.6768Z" fill="#42EC9A" />
      <path d="M10.9603 40.5589L10.213 40.3587L7.17901 51.6817L7.92627 51.882L10.9603 40.5589Z" fill="#42EC9A" />
      <path d="M16.2731 41.9825L15.5259 41.7823L12.4919 53.1053L13.2391 53.3055L16.2731 41.9825Z" fill="#42EC9A" />
      <path d="M14.0031 48.9604L3.03345 46.0211L2.83354 46.7671L13.8032 49.7064L14.0031 48.9604Z" fill="#42EC9A" />
    </svg>
  )
}
