export function EmailIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={'128' || props.width} height="129" viewBox="0 0 128 129" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M100.914 53.6744L65.9569 26.1464C64.7953 25.2312 63.1585 25.2312 61.9969 26.1464L27.04 53.6744V80.116H100.914V53.6744Z"
        fill="#008E5A"
      />
      <path d="M100.914 64.2572L27.04 64.7708L37.6144 53.6748H92.8129L100.914 64.2572Z" fill="#1D232A" />
      <path
        d="M97.3728 57.5306C97.32 57.5306 97.2672 57.513 97.224 57.4794L92.7296 53.9179H37.6896L33.2112 56.977C33.1024 57.0522 32.952 57.0234 32.8784 56.9146C32.8032 56.8058 32.832 56.6554 32.9408 56.5818L37.48 53.4811C37.52 53.4539 37.5664 53.4395 37.616 53.4395H92.8144C92.8688 53.4395 92.9216 53.4571 92.9632 53.4907L97.5232 57.105C97.6272 57.1866 97.6448 57.3386 97.5616 57.4426C97.5136 57.5002 97.4432 57.5306 97.3728 57.5306Z"
        fill="#1D232A"
      />
      <path d="M92.8129 28.9229H37.6145V100.552H92.8129V28.9229Z" fill="#E8E9EA" />
      <path
        d="M92.8126 96.3942L68.2046 74.7366C65.787 72.6086 62.1646 72.6086 59.7486 74.7366L35.1406 96.3942"
        fill="#00A868"
      />
      <path d="M100.914 103.524H27.04V53.6748L63.4225 80.1164L100.914 53.6748V103.524Z" fill="#00A868" />
      <path
        d="M35.1408 96.6341C35.0752 96.6341 35.0096 96.6069 34.9616 96.5541C34.8736 96.4549 34.8816 96.3029 34.9808 96.2149L58.5968 75.0805C58.696 74.9925 58.848 75.0005 58.936 75.0997C59.024 75.1989 59.016 75.3509 58.9168 75.4389L35.3008 96.5733C35.2544 96.6133 35.1984 96.6341 35.1408 96.6341Z"
        fill="#1D232A"
      />
      <path
        d="M92.8128 96.6345C92.7552 96.6345 92.6992 96.6137 92.6528 96.5737L69.3712 75.7641C69.272 75.6761 69.264 75.5241 69.352 75.4249C69.44 75.3257 69.592 75.3177 69.6912 75.4057L92.9728 96.2153C93.072 96.3033 93.08 96.4553 92.992 96.5545C92.944 96.6073 92.8784 96.6345 92.8128 96.6345Z"
        fill="#1D232A"
      />
      <path
        d="M46.189 42.8233C46.0562 42.8233 45.949 42.7161 45.949 42.5833C45.949 42.4505 46.0562 42.3433 46.189 42.3433L84.237 42.3369C84.3698 42.3369 84.477 42.4441 84.477 42.5769C84.477 42.7097 84.3698 42.8169 84.237 42.8169L46.189 42.8233Z"
        fill="#1D232A"
      />
      <path
        d="M46.1904 53.9161C46.0576 53.9161 45.9504 53.8089 45.9504 53.6761C45.9504 53.5433 46.0576 53.4361 46.1904 53.4361L84.2384 53.4297C84.3712 53.4297 84.4784 53.5369 84.4784 53.6697C84.4784 53.8025 84.3712 53.9097 84.2384 53.9097L46.1904 53.9161Z"
        fill="#1D232A"
      />
      <path
        d="M46.1921 65.0108C46.0593 65.0108 45.9521 64.9036 45.9521 64.7708C45.9521 64.638 46.0593 64.5308 46.1921 64.5308L84.2401 64.5244C84.3729 64.5244 84.4801 64.6316 84.4801 64.7644C84.4801 64.8972 84.3729 65.0044 84.2401 65.0044L46.1921 65.0108Z"
        fill="#1D232A"
      />
    </svg>
  )
}
