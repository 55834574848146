export function PhonePaperAirplaneIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={108} height={112} fill="none" {...props}>
      <path
        d="M18.435 106.501C9.146 99.989-.561 72.482 2.572 60.598c.803-3.039 5.684-4.375 9.531 1.342a156.162 156.162 0 014.909 7.8c4.14-19.193 7.208-22.498 10.681-22.879 2.312-.263 8.817 1.341 5.646 12.484-.075.253-.156.513-.235.78 0 .014-.015.038-.018.053-.003.015.02-.012.027-.012a19.35 19.35 0 011.802-.714c8.479-2.949 11.147 2.746 9.573 7.04-.585 1.595-1.882 3.185-3.23 4.526a35.02 35.02 0 01-4.012 3.39c.207.025.413.052.616.092 7.79 1.385 13.598 7.358 14.316 14.903.948 9.986-4.649 17.95-12.633 20.443-7.827 2.44-14.822 1.062-21.11-3.345z"
        fill="#FFCBD9"
      />
      <path
        d="M29.402 63.661l-.212-.49.292-.189-.08.68zM34.915 74.882l-.288 1.006-5.214-12.193c.476-.326.94-.609 1.388-.854l6.942 8.649a29.227 29.227 0 01-2.828 3.392z"
        fill="#1D232A"
      />
      <path
        d="M13.698 36.775L2.018 42.45a1.785 1.785 0 00-.825 2.386l22.218 45.716a1.785 1.785 0 002.386.826l11.68-5.677c.887-.43 1.257-1.5.826-2.386L16.085 37.6a1.785 1.785 0 00-2.387-.825z"
        fill="#42EC9A"
      />
      <path
        d="M37.476 85.701l-11.675 5.675a1.786 1.786 0 01-2.386-.827L8.407 59.673l19.514 2.268 10.394 21.375a1.784 1.784 0 01-.839 2.385z"
        fill="#1D232A"
      />
      <path
        d="M36.846 25.527L8.238 39.43a2.085 2.085 0 00-.964 2.787L29.23 87.394a2.085 2.085 0 002.787.964l28.608-13.904a2.085 2.085 0 00.965-2.787L39.633 26.491a2.085 2.085 0 00-2.787-.964z"
        fill="#00A868"
      />
      <path d="M37.435 32.015L12.372 44.196l18.452 37.967 25.064-12.181-18.453-37.967z" fill="#FCFCFC" />
      <path d="M35.112 33.146l-22.74 11.052 18.452 37.967 22.74-11.052-18.452-37.967z" fill="#42EC9A" />
      <path d="M23.674 36.7a1.128 1.128 0 100-2.256 1.128 1.128 0 000 2.257z" fill="#1D232A" />
      <path
        d="M22.222 102.337c-2.035-3.586-10.66-13.979-4.99-22.376 2.858-4.232 6.392-7.376 9.052-8.918 1.468-.732 4.358-.548 5.895 1.82 1.52 2.493 1.32 8.627-2.041 12.637 5.639-1.35 11.846 2.23 13.943 6.266"
        fill="#FFCBD9"
      />
      <path
        d="M22.222 102.335a.254.254 0 01-.181-.075c-1.328-1.366-5.373-7.06-6.664-13.32a12.998 12.998 0 012.121-10.112 26.657 26.657 0 011.862-2.392.252.252 0 01.373.336 25.879 25.879 0 00-1.824 2.346 12.478 12.478 0 00-2.039 9.723c1.27 6.15 5.232 11.734 6.538 13.075a.253.253 0 01.053.273.248.248 0 01-.232.153l-.007-.007z"
        fill="#1D232A"
      />
      <path
        d="M74.755 60.532c-15.73-4.727-31.027-4.117-37.526-1.39-2.285.959-4.694 3.265-3.678 5.867 1.108 2.826 3.761 3.742 5.867 4.258 5.327 1.31 13.295 1.425 20.934 3.711"
        fill="#FFCBD9"
      />
      <path
        d="M67.086 88.861c5.133 2.992 16.595 8.025 29.303.38 5.345-3.211 16.47-14.16 8.292-30.091-3.13-6.097-10.967-12.683-20.435-13.727-4.605-.503-9.206.352-11.828 4.762-3.482-.376-10.85 2.107-12.653 7.461-.513 1.864-.969 4.06-1.542 5.906-.848 2.726-1.477 5.577-1.23 8.424a22.612 22.612 0 001.495 6.249c1.743 4.456 4.82 8.439 8.598 10.636z"
        fill="#FFCBD9"
      />
      <path
        d="M80.26 51.531a.25.25 0 01-.07-.01 27.915 27.915 0 00-10.078-.979.255.255 0 01-.274-.23.252.252 0 01.23-.27c3.452-.297 6.93.04 10.26.996a.251.251 0 01-.068.493zM72.695 60.067a.258.258 0 01-.07-.01c-3.276-.937-9.437-1.844-12.82-2.312a.254.254 0 11.07-.503c3.394.463 9.583 1.381 12.888 2.327a.251.251 0 01-.068.493v.005zM58.487 78.23c1.159-2.18 3.018-3.405 5.381-3.976a54.94 54.94 0 00-6.872-2.158c.197 2.106.7 4.172 1.491 6.134z"
        fill="#1D232A"
      />
      <path
        d="M58.933 78.984a.25.25 0 01-.226-.36c1.59-3.298 4.917-4.399 5.057-4.444a.251.251 0 11.155.477c-.032.012-3.267 1.088-4.76 4.191a.25.25 0 01-.226.136zM44.085 92.018a.253.253 0 01-.223-.136c-1.236-2.377-3.896-4.54-6.944-5.64a.252.252 0 01.168-.473c3.162 1.143 5.928 3.396 7.218 5.882a.25.25 0 01-.107.335.258.258 0 01-.112.032zM63.738 35.477L94.852.958 64.505 24.74l-.767 10.737z"
        fill="#1D232A"
      />
      <path d="M48.451 15.54L94.851.958l-11.355 36.3L48.451 15.54z" fill="#E8E9EA" />
      <path d="M61.273 23.486l2.465 11.991 2.39-8.982L94.797 1.01 61.273 23.486z" fill="#BBBDBF" />
      <path
        d="M29.884 58.26a.25.25 0 01-.252-.242.251.251 0 01.016-.097c.116-.316.24-.633.369-.945a.252.252 0 01.417-.093.253.253 0 01.047.284c-.126.305-.246.618-.362.929a.248.248 0 01-.235.164zM31.452 54.788a.251.251 0 01-.221-.372c.335-.617.697-1.232 1.08-1.83a.252.252 0 01.423.273c-.377.586-.732 1.19-1.06 1.797a.251.251 0 01-.222.132zm2.7-4.097a.248.248 0 01-.25-.222.254.254 0 01.053-.185c.435-.552.898-1.095 1.376-1.616a.252.252 0 11.37.335 29.555 29.555 0 00-1.35 1.59.252.252 0 01-.199.098zm3.353-3.582a.25.25 0 01-.237-.168.25.25 0 01.068-.272 30.234 30.234 0 011.62-1.37.251.251 0 11.31.395c-.544.43-1.08.883-1.593 1.35a.246.246 0 01-.168.065zm3.89-2.994a.252.252 0 01-.138-.463 30.813 30.813 0 011.82-1.093.252.252 0 01.243.441c-.605.335-1.208.697-1.79 1.075a.25.25 0 01-.136.04zm4.319-2.329a.252.252 0 01-.222-.366.251.251 0 01.121-.115 29.61 29.61 0 011.973-.78.251.251 0 11.168.474c-.652.23-1.304.487-1.941.767a.27.27 0 01-.099.02zm4.643-1.582a.251.251 0 01-.06-.495 29.044 29.044 0 012.08-.432.256.256 0 01.29.309.253.253 0 01-.206.194c-.68.114-1.368.256-2.042.424a.268.268 0 01-.062 0zM55.209 39.453a.252.252 0 01-.017-.503c.267-.017.535-.03.801-.038a7.44 7.44 0 01.168-.803.253.253 0 01.206-.198.25.25 0 01.258.123.252.252 0 01.024.196 8.279 8.279 0 00-.18.958.25.25 0 01-.243.22c-.335.008-.67.023-.995.044l-.022.001zM57.11 36.521a.252.252 0 01-.225-.365c.236-.466.512-.91.823-1.33a.252.252 0 01.451.21.25.25 0 01-.047.089 8.837 8.837 0 00-.778 1.259.255.255 0 01-.225.137zM59.292 33.742a.251.251 0 01-.167-.442c.263-.224.538-.433.824-.626a.252.252 0 01.28.42c-.27.18-.53.376-.777.588a.252.252 0 01-.16.06z"
        fill="#1D232A"
      />
    </svg>
  )
}
