import { Icon } from '@dlpco/ginga-stone'

import { type CaptureMethod } from '~/lib/types'

export const paymentCaptureIconList: Record<CaptureMethod, React.ReactNode> = {
  Ecommerce: <Icon use="copy-outline" color="primary" size="large" />,
  Pos: <Icon use="device-card-machine-outline" color="primary" size="large" />,
  'Micro Pos': <Icon use="device-card-machine-outline" color="primary" size="large" />
}

export const paymentCaptureTranslate: Record<CaptureMethod, string> = {
  Ecommerce: 'Ecommerce',
  Pos: 'Maquininha',
  'Micro Pos': 'Maquininha'
}
