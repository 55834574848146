export function RequestedLoanStatusIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="150" height="18" fill="none" viewBox="0 0 150 18" {...props}>
      <mask
        id="mask0_6886_41230"
        style={{ maskType: 'alpha' }}
        width="18"
        height="18"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#000"
          fillRule="evenodd"
          d="M1.5 9.188a7.688 7.688 0 1115.375 0 7.688 7.688 0 01-15.375 0zm10.995-.75a.75.75 0 10-.99-1.127l-2.907 2.55-1.333-1.258a.75.75 0 00-1.03 1.09l1.83 1.728a.75.75 0 001.01.018l3.42-3z"
          clipRule="evenodd"
        />
      </mask>
      <g mask="url(#mask0_6886_41230)">
        <path fill="#767F8D" d="M0 0H18V18H0z" />
      </g>
      <path stroke="#303742" strokeOpacity="0.2" strokeWidth="1.5" d="M18 9h26" />
      <mask
        id="mask1_6886_41230"
        style={{ maskType: 'alpha' }}
        width="18"
        height="18"
        x="44"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#000"
          fillRule="evenodd"
          d="M45.5 9.188a7.688 7.688 0 1115.375 0 7.688 7.688 0 01-15.375 0zm10.995-.75a.75.75 0 10-.99-1.127l-2.907 2.55-1.333-1.258a.75.75 0 10-1.03 1.09l1.83 1.728a.75.75 0 001.01.018l3.42-3z"
          clipRule="evenodd"
        />
      </mask>
      <g mask="url(#mask1_6886_41230)">
        <path fill="#303742" d="M44 0H62V18H44z" />
      </g>
      <path stroke="#303742" strokeOpacity="0.2" strokeWidth="1.5" d="M62 9h26" />
      <rect width="13" height="13" x="90.5" y="2.5" stroke="#767F8D" strokeWidth="2" rx="6.5" />
      <path stroke="#303742" strokeOpacity="0.2" strokeWidth="1.5" d="M106 9h26" />
      <rect width="13" height="13" x="134.5" y="2.5" stroke="#767F8D" strokeWidth="2" rx="6.5" />
    </svg>
  )
}

export function LoanStepThreeDeniedIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="150" height="18" fill="none" viewBox="0 0 150 18" {...props}>
      <mask
        id="mask0_6886_41477"
        style={{ maskType: 'alpha' }}
        width="18"
        height="18"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#000"
          fillRule="evenodd"
          d="M1.5 9.188a7.688 7.688 0 1115.375 0 7.688 7.688 0 01-15.375 0zm10.995-.75a.75.75 0 10-.99-1.127l-2.907 2.55-1.333-1.258a.75.75 0 00-1.03 1.09l1.83 1.728a.75.75 0 001.01.018l3.42-3z"
          clipRule="evenodd"
        />
      </mask>
      <g mask="url(#mask0_6886_41477)">
        <path fill="#767F8D" d="M0 0H18V18H0z" />
      </g>
      <path stroke="#303742" strokeOpacity="0.2" strokeWidth="1.5" d="M18 9h26" />
      <mask
        id="mask1_6886_41477"
        style={{ maskType: 'alpha' }}
        width="18"
        height="18"
        x="44"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#000"
          fillRule="evenodd"
          d="M45.5 9.188a7.688 7.688 0 1115.375 0 7.688 7.688 0 01-15.375 0zm10.995-.75a.75.75 0 10-.99-1.127l-2.907 2.55-1.333-1.258a.75.75 0 10-1.03 1.09l1.83 1.728a.75.75 0 001.01.018l3.42-3z"
          clipRule="evenodd"
        />
      </mask>
      <g mask="url(#mask1_6886_41477)">
        <path fill="#767F8D" d="M44 0H62V18H44z" />
      </g>
      <path stroke="#303742" strokeOpacity="0.2" strokeWidth="1.5" d="M62 9h26" />
      <mask
        id="mask2_6886_41477"
        style={{ maskType: 'alpha' }}
        width="18"
        height="18"
        x="88"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#000"
          fillRule="evenodd"
          d="M97 1.5a7.5 7.5 0 100 15 7.5 7.5 0 000-15zm-2.78 4.72a.75.75 0 011.06 0l1.722 1.721 1.72-1.719a.75.75 0 011.06 1.06l-1.72 1.72 1.716 1.715a.75.75 0 11-1.06 1.06l-1.716-1.714-1.717 1.717a.75.75 0 11-1.06-1.06l1.717-1.718L94.22 7.28a.75.75 0 010-1.06z"
          clipRule="evenodd"
        />
      </mask>
      <g mask="url(#mask2_6886_41477)">
        <path fill="#F73E41" d="M88 0H106V18H88z" />
      </g>
      <path stroke="#303742" strokeOpacity="0.2" strokeWidth="1.5" d="M106 9h26" />
      <rect width="13" height="13" x="134.5" y="2.5" stroke="#767F8D" strokeWidth="2" rx="6.5" />
    </svg>
  )
}

export function LoanStepThreeApprovedIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="150" height="18" fill="none" viewBox="0 0 150 18" {...props}>
      <mask
        id="mask0_6886_40657"
        style={{ maskType: 'alpha' }}
        width="18"
        height="18"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#000"
          fillRule="evenodd"
          d="M1.5 9.188a7.688 7.688 0 1115.375 0 7.688 7.688 0 01-15.375 0zm10.995-.75a.75.75 0 10-.99-1.127l-2.907 2.55-1.333-1.258a.75.75 0 00-1.03 1.09l1.83 1.728a.75.75 0 001.01.018l3.42-3z"
          clipRule="evenodd"
        />
      </mask>
      <g mask="url(#mask0_6886_40657)">
        <path fill="#767F8D" d="M0 0H18V18H0z" />
      </g>
      <path stroke="#303742" strokeOpacity="0.2" strokeWidth="1.5" d="M18 9h26" />
      <mask
        id="mask1_6886_40657"
        style={{ maskType: 'alpha' }}
        width="18"
        height="18"
        x="44"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#000"
          fillRule="evenodd"
          d="M45.5 9.188a7.688 7.688 0 1115.375 0 7.688 7.688 0 01-15.375 0zm10.995-.75a.75.75 0 10-.99-1.127l-2.907 2.55-1.333-1.258a.75.75 0 10-1.03 1.09l1.83 1.728a.75.75 0 001.01.018l3.42-3z"
          clipRule="evenodd"
        />
      </mask>
      <g mask="url(#mask1_6886_40657)">
        <path fill="#767F8D" d="M44 0H62V18H44z" />
      </g>
      <path stroke="#303742" strokeOpacity="0.2" strokeWidth="1.5" d="M62 9h26" />
      <mask
        id="mask2_6886_40657"
        style={{ maskType: 'alpha' }}
        width="18"
        height="18"
        x="88"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#000"
          fillRule="evenodd"
          d="M89.5 9.188a7.687 7.687 0 1115.375 0 7.687 7.687 0 01-15.375 0zm10.995-.75a.75.75 0 00-.99-1.127l-2.907 2.55-1.333-1.258a.75.75 0 10-1.03 1.09l1.83 1.728a.75.75 0 001.01.018l3.42-3z"
          clipRule="evenodd"
        />
      </mask>
      <g mask="url(#mask2_6886_40657)">
        <path fill="#00A868" d="M88 0H106V18H88z" />
      </g>
      <path stroke="#303742" strokeOpacity="0.2" strokeWidth="1.5" d="M106 9h26" />
      <rect width="13" height="13" x="134.5" y="2.5" stroke="#767F8D" strokeWidth="2" rx="6.5" />
    </svg>
  )
}
