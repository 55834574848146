export function RavDailyReceivableBannerFollowUpIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="61" height="40" viewBox="0 0 81 60" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_8047_3292)">
        <path
          d="M26.799 3.63677C26.799 3.63677 26.5205 7.14867 30.8688 8.694C33.3851 9.58811 35.399 8.53422 35.399 8.53422C35.399 8.53422 36.8077 3.00653 35.3845 1.47331C33.8616 -0.16644 31.8718 0.357278 31.1328 0.981866C30.1001 1.85581 29.2541 3.37854 26.799 3.63677Z"
          fill="#E09661"
        />
        <path
          d="M12.2448 29.989C13.3129 28.7898 14.1171 25.286 15.0838 21.3674C15.9998 17.6578 18.1756 17.5109 19.5826 17.3076C20.646 17.1535 26.3015 17.3342 29.2597 17.3342C30.4687 17.3342 31.941 18.2864 31.3638 20.5338C30.8478 22.544 29.3989 22.7368 29.3989 22.7368H20.7901L16.2188 36.393L5.60718 33.4202C6.30587 30.6039 11.1766 31.1881 12.2448 29.989Z"
          fill="#FFCBD9"
        />
        <path
          d="M12.2448 29.9891C12.6408 29.5445 13.8797 30.0343 13.6132 31.5982C15.0871 32.4713 17.4278 32.7812 17.4278 32.7812L16.2188 36.3932L5.56135 33.4365C5.47442 33.4123 5.4237 33.3211 5.44946 33.2347C6.22623 30.6258 11.1976 31.1649 12.2448 29.9891Z"
          fill="#00A868"
        />
        <path
          d="M14.8955 35.3569C14.8842 35.3569 14.8729 35.3553 14.8617 35.3521L5.90505 32.7424C5.84066 32.7238 5.80444 32.6568 5.82295 32.5923C5.84146 32.5277 5.90827 32.4914 5.97267 32.51L14.9293 35.1197C14.9937 35.1382 15.0299 35.2052 15.0114 35.2698C14.9961 35.3222 14.9478 35.3569 14.8955 35.3569Z"
          fill="#1D232A"
        />
        <path
          d="M12.3776 27.2743C13.6856 25.5336 14.2209 24.2441 14.9598 21.2842C15.8847 17.577 17.9558 17.3301 19.6865 17.1493C20.7554 17.0372 26.3925 17.1243 29.3506 17.1243C30.5597 17.1243 32.0697 18.3735 31.4926 20.6209C30.9766 22.631 29.3981 22.7375 29.3981 22.7375H20.79L18.5507 29.4272L12.3776 27.2743Z"
          fill="#008E5A"
        />
        <path d="M18.5781 22.6086L15.1 28.2235L17.58 29.0401L18.5781 22.6086Z" fill="#1D232A" />
        <path
          d="M17.8165 19.99C17.8165 19.99 16.585 15.9624 16.2356 14.5212C15.7905 12.6837 17.5235 9.99251 20.7184 11.8324C25.6334 14.6624 30.0171 17.5005 30.0171 17.5005C30.0171 17.5005 21.9024 20.7703 21.7809 20.7703C21.6594 20.7703 17.8165 19.99 17.8165 19.99Z"
          fill="#008E5A"
        />
        <path d="M22.3855 17.0921L29.5181 17.1655L22.6125 12.8604L22.3855 17.0921Z" fill="#1D232A" />
        <path
          d="M22.1465 16.8314C22.1416 16.8314 22.1368 16.8314 22.132 16.8306C22.066 16.8226 22.0185 16.762 22.0265 16.6959L22.4926 12.8459C22.5006 12.7797 22.561 12.7321 22.627 12.7401C22.693 12.7482 22.7405 12.8087 22.7325 12.8749L22.2664 16.7249C22.2583 16.7863 22.2068 16.8314 22.1465 16.8314Z"
          fill="#1D232A"
        />
        <path
          d="M16.9288 18.0767C16.8901 18.0767 16.8523 18.0582 16.829 18.0243C16.7911 17.9694 16.8048 17.8935 16.8604 17.8556C17.7724 17.227 18.8268 17.1173 19.6744 17.0285C20.2162 16.972 27.0485 16.9639 28.8259 16.9704C28.8927 16.9704 28.9466 17.0253 28.9458 17.0922C28.9458 17.1592 28.8911 17.2125 28.8242 17.2125C26.7588 17.2044 20.1993 17.2165 19.6994 17.269C18.8365 17.3593 17.8585 17.4618 16.9972 18.0549C16.9763 18.0695 16.9529 18.0767 16.9288 18.0767Z"
          fill="#1D232A"
        />
        <path
          d="M44.9932 22.736H37.5483C36.8013 22.736 36.592 21.0914 36.2917 20.1836C36.1702 19.8172 35.8225 18.7908 35.424 17.6174C34.9129 16.1108 34.318 14.3638 34.0137 13.4656C33.9043 13.142 33.8326 12.929 33.8157 12.8757C33.7578 12.6942 33.703 12.5142 33.6765 12.3246C33.5879 11.7113 33.6072 11.0786 33.8705 10.5081C34.1812 9.83348 34.8348 9.3017 35.5729 9.23795C36.534 9.15483 37.4718 9.51473 37.867 10.7187C38.1689 11.6378 40.4919 20.2005 41.6744 20.7008C42.2121 20.9284 44.2108 21.1576 44.7308 21.4239C45.3723 21.7523 44.9932 22.736 44.9932 22.736Z"
          fill="#FFCBD9"
        />
        <path
          d="M37.033 13.2043L35.4239 17.6176C34.9128 16.111 34.3179 14.3639 34.0137 13.4658L33.6764 12.3247L37.033 13.2043Z"
          fill="#1D232A"
        />
        <path
          d="M51.9447 21.3666C51.9278 21.1156 51.8908 20.8695 51.8361 20.6306C51.3523 18.5228 49.4647 16.946 47.2189 16.946C44.6061 16.946 42.4818 19.0764 42.4818 21.695C42.4818 24.3128 44.6069 26.4432 47.2189 26.4432C48.7266 26.4432 50.0724 25.733 50.941 24.6299C51.5761 23.8205 51.956 22.8005 51.956 21.695C51.956 21.5853 51.9528 21.4747 51.9447 21.3666ZM47.2189 25.3175C45.2267 25.3175 43.6055 23.693 43.6055 21.6966C43.6055 19.6994 45.2267 18.0742 47.2189 18.0742C48.9841 18.0742 50.458 19.35 50.7703 21.0293C50.8106 21.2455 50.8323 21.4691 50.8323 21.6966C50.8323 22.5738 50.5208 23.3767 50.0024 24.0045C49.3383 24.805 48.3362 25.3175 47.2189 25.3175Z"
          fill="#42EC9A"
        />
        <path
          d="M51.9559 21.6959C51.9559 22.8015 51.576 23.8215 50.9409 24.6309H50.0023V24.0038C50.5207 23.376 50.8322 22.5723 50.8322 21.6959L51.9455 21.3667C51.9527 21.4748 51.9559 21.5854 51.9559 21.6959Z"
          fill="#1D232A"
        />
        <path
          d="M34.9611 21.3666C34.9442 21.1156 34.9072 20.8695 34.8524 20.6306C34.3687 18.5228 32.4811 16.946 30.2353 16.946C27.6224 16.946 25.4982 19.0764 25.4982 21.695C25.4982 24.3128 27.6232 26.4432 30.2353 26.4432C31.7429 26.4432 33.0888 25.733 33.9573 24.6299C34.5924 23.8205 34.9724 22.8005 34.9724 21.695C34.9724 21.5853 34.9692 21.4747 34.9611 21.3666ZM30.2345 25.3175C28.2422 25.3175 26.6211 23.693 26.6211 21.6966C26.6211 19.6994 28.2422 18.0742 30.2345 18.0742C31.9997 18.0742 33.4736 19.35 33.7859 21.0293C33.8261 21.2455 33.8479 21.4691 33.8479 21.6966C33.8479 22.5738 33.5364 23.3767 33.018 24.0045C32.3539 24.805 31.3525 25.3175 30.2345 25.3175Z"
          fill="#42EC9A"
        />
        <path
          d="M34.9724 21.6959C34.9724 22.8015 34.5925 23.8215 33.9574 24.6309H33.0188V24.0038C33.5372 23.376 33.8487 22.5723 33.8487 21.6959L34.9619 21.3667C34.9692 21.4748 34.9724 21.5854 34.9724 21.6959Z"
          fill="#1D232A"
        />
        <path
          d="M62.2576 22.6086L57.3966 39.2797L57.3273 39.5161L54.4094 49.5216L54.341 49.758L51.3892 59.879H7.70972L10.6614 49.758L10.7315 49.516L13.6478 39.5161L13.7186 39.274L18.5781 22.6086H62.2576Z"
          fill="#E8E9EA"
        />
        <path d="M62.2568 22.6086L60.3482 29.1539H16.6687L18.578 22.6086H62.2568Z" fill="#42EC9A" />
        <path d="M62.2738 22.6086L51.3893 59.879H73.1413L62.2738 22.6086Z" fill="#00A868" />
        <path
          d="M57.3966 39.2795C57.3853 39.2754 57.3741 39.2738 57.362 39.2738H42.1767L46.4035 24.78C46.422 24.7154 46.3858 24.6485 46.3214 24.6299C46.2578 24.6105 46.1902 24.6476 46.1716 24.7122L41.9256 39.2738H27.6169L31.7849 24.9801C31.8042 24.9156 31.7672 24.8486 31.7036 24.83C31.6392 24.8107 31.5724 24.8478 31.5538 24.9123L27.3657 39.2738H13.7187L13.6478 39.5159H27.2949L24.3786 49.5158H10.7315L10.6615 49.7579H24.3085L21.3673 59.8449C21.364 59.8562 21.3624 59.8675 21.3624 59.8788C21.3616 59.9312 21.3962 59.9796 21.4486 59.995C21.4606 59.9982 21.4719 59.9998 21.4832 59.9998C21.5355 59.9998 21.583 59.9659 21.5983 59.9127L21.6079 59.8788L24.5597 49.7579H38.8684L35.9271 59.8449C35.9239 59.8562 35.9223 59.8675 35.9223 59.8788C35.9215 59.9312 35.9561 59.9796 36.0084 59.995C36.0205 59.9982 36.0318 59.9998 36.043 59.9998C36.0953 59.9998 36.1428 59.9659 36.1589 59.9127L36.1686 59.8788L39.1195 49.7579H54.3756C54.4425 49.7579 54.4964 49.7038 54.4964 49.6368C54.4964 49.5819 54.4594 49.5351 54.4094 49.5214C54.399 49.5174 54.3877 49.5158 54.3756 49.5158H39.1904L42.1067 39.5159H57.362C57.4288 39.5159 57.4827 39.4618 57.4827 39.3949C57.4827 39.34 57.4465 39.294 57.3966 39.2795ZM38.9384 49.5158H24.6305L27.5468 39.5159H41.8547L38.9384 49.5158Z"
          fill="#1D232A"
        />
        <path d="M62.2737 22.6086L51.3893 59.879H58.3239L62.6521 23.9062L62.2737 22.6086Z" fill="#1D232A" />
        <path d="M62.2568 22.6086L60.3482 29.1539H16.6687L18.578 22.6086H62.2568Z" fill="#00A868" />
        <path
          d="M29.6252 27.974C29.1575 27.974 28.8878 27.5979 29.0231 27.1347L29.3177 26.1244C29.4529 25.6612 29.9415 25.2852 30.4092 25.2852C30.8768 25.2852 31.1465 25.6612 31.0113 26.1244L30.7167 27.1347C30.5814 27.5987 30.0928 27.974 29.6252 27.974Z"
          fill="#1D232A"
        />
        <path
          d="M29.6252 28.0949C29.3644 28.0949 29.1454 27.9933 29.0078 27.8093C28.8653 27.6188 28.8291 27.3671 28.9071 27.1008L29.2018 26.0905C29.3531 25.5708 29.8835 25.1641 30.4092 25.1641C30.67 25.1641 30.8889 25.2657 31.0266 25.4497C31.169 25.6402 31.2053 25.8919 31.1272 26.1582L30.8326 27.1686C30.6812 27.6882 30.1508 28.0949 29.6252 28.0949ZM30.4084 25.407C29.9914 25.407 29.5535 25.7443 29.4328 26.159L29.139 27.1694C29.0834 27.3606 29.1052 27.5365 29.201 27.6648C29.2919 27.7867 29.4424 27.8537 29.6252 27.8537C30.0421 27.8537 30.48 27.5164 30.6008 27.1016L30.8954 26.0913C30.9509 25.9 30.9292 25.7241 30.8334 25.5958C30.7416 25.4739 30.5911 25.407 30.4084 25.407Z"
          fill="#1D232A"
        />
        <path
          d="M30.2345 26.4367C27.6225 26.4367 25.4974 24.3063 25.4974 21.6877C25.4974 19.0691 27.6225 16.9387 30.2345 16.9387C30.5452 16.9387 30.7964 17.1913 30.7964 17.502C30.7964 17.8135 30.5444 18.0652 30.2345 18.0652C28.2423 18.0652 26.6219 19.6905 26.6219 21.6869C26.6219 23.6841 28.2431 25.3085 30.2345 25.3085C30.5452 25.3085 30.7964 25.5611 30.7964 25.8718C30.7964 26.1825 30.5452 26.4367 30.2345 26.4367Z"
          fill="#42EC9A"
        />
        <path
          d="M46.6095 27.974C46.1419 27.974 45.8722 27.5979 46.0074 27.1347L46.302 26.1244C46.4373 25.6612 46.9259 25.2852 47.3936 25.2852C47.8612 25.2852 48.1309 25.6612 47.9957 26.1244L47.701 27.1347C47.5658 27.5987 47.0772 27.974 46.6095 27.974Z"
          fill="#1D232A"
        />
        <path
          d="M46.6095 28.0949C46.3487 28.0949 46.1298 27.9933 45.9921 27.8093C45.8497 27.6188 45.8134 27.3671 45.8915 27.1008L46.1861 26.0905C46.3375 25.5708 46.8679 25.1641 47.3935 25.1641C47.6544 25.1641 47.8733 25.2657 48.0109 25.4497C48.1534 25.6402 48.1896 25.8919 48.1116 26.1582L47.8169 27.1686C47.6648 27.6882 47.1344 28.0949 46.6095 28.0949ZM47.3927 25.407C46.9758 25.407 46.5379 25.7443 46.4172 26.159L46.1225 27.1694C46.067 27.3606 46.0887 27.5365 46.1845 27.6648C46.2755 27.7867 46.426 27.8537 46.6087 27.8537C47.0257 27.8537 47.4636 27.5164 47.5843 27.1016L47.8789 26.0913C47.9345 25.9 47.9127 25.7241 47.8169 25.5958C47.726 25.4739 47.5755 25.407 47.3927 25.407Z"
          fill="#1D232A"
        />
        <path
          d="M47.2189 26.4367C44.6069 26.4367 42.4818 24.3063 42.4818 21.6877C42.4818 19.0691 44.6069 16.9387 47.2189 16.9387C47.5296 16.9387 47.7808 17.1913 47.7808 17.502C47.7808 17.8135 47.5288 18.0652 47.2189 18.0652C45.2267 18.0652 43.6063 19.6905 43.6063 21.6869C43.6063 23.6841 45.2275 25.3085 47.2189 25.3085C47.5296 25.3085 47.7808 25.5611 47.7808 25.8718C47.7808 26.1825 47.5288 26.4367 47.2189 26.4367Z"
          fill="#42EC9A"
        />
        <path
          d="M58.3159 59.815C58.3102 59.815 58.3038 59.8142 58.2982 59.8134C58.2322 59.8037 58.1871 59.7416 58.1967 59.6754L62.8308 28.697C62.8404 28.6324 62.9 28.5873 62.9644 28.5945C63.0288 28.6026 63.0763 28.6599 63.0707 28.7252L60.7532 54.983C60.7476 55.0499 60.6888 55.0984 60.622 55.0927C60.5552 55.0871 60.5061 55.0282 60.5126 54.9612L62.4782 32.6818L58.4342 59.7118C58.4261 59.7723 58.3746 59.815 58.3159 59.815Z"
          fill="#1D232A"
        />
        <path
          d="M36.6588 14.1329C36.3577 14.6671 35.9102 15.1376 35.3419 15.3667C34.7809 15.5927 34.153 15.5693 33.5501 15.5128C32.2533 15.3918 30.9686 15.1359 29.7242 14.7502C29.3177 14.6243 28.9023 14.4767 28.59 14.1862C28.1304 13.7577 27.975 13.0911 27.9501 12.4617C27.9332 12.0324 27.9654 11.6031 28.0153 11.1762C28.0644 10.7453 28.1312 10.3152 28.1819 9.88424C28.2318 9.45736 28.2439 9.03693 28.2528 8.60843C28.2552 8.4656 28.3526 8.36635 28.4508 8.27112C28.5683 8.15573 28.7084 8.06373 28.8573 7.99434C29.164 7.85312 29.4787 7.75305 29.9842 7.78614C30.4398 7.8168 31.1828 8.21706 31.7655 8.47206C31.7752 8.47609 31.784 8.48013 31.7937 8.48416C31.8396 8.50434 31.8863 8.52451 31.9322 8.54388C31.978 8.56324 32.0239 8.58261 32.0698 8.60117C32.0795 8.60521 32.0891 8.60924 32.098 8.61328C32.102 8.61408 32.1052 8.6157 32.1084 8.61731C32.5866 8.8126 33.0744 8.98206 33.5694 9.1257C33.6411 9.14668 33.7127 9.16766 33.7851 9.18703C33.8882 9.21527 33.9912 9.24271 34.095 9.26853C34.1208 9.27498 34.1466 9.28144 34.1723 9.2879C34.2737 9.31291 34.376 9.33631 34.4782 9.35891C34.5072 9.36536 34.5362 9.37182 34.5651 9.37828C35.2526 9.52756 35.9955 9.64941 36.5026 10.1368C36.9381 10.5556 37.12 11.1778 37.1635 11.7806C37.2231 12.5924 37.058 13.4244 36.6588 14.1329Z"
          fill="#00A868"
        />
        <path
          d="M36.3247 5.51803C36.1741 5.83517 35.9077 6.05789 35.6002 6.16118C35.5809 6.16763 35.5616 6.17328 35.5431 6.17893C35.5173 6.18619 35.4924 6.19184 35.4674 6.19749C35.4602 6.19911 35.4521 6.20072 35.4441 6.20233C35.4376 6.20314 35.4312 6.20475 35.4247 6.20556C35.4006 6.2104 35.3764 6.21363 35.3515 6.21686H35.3507C35.3378 6.21847 35.3249 6.22009 35.3121 6.22089C35.2976 6.2217 35.2839 6.22251 35.2702 6.22332H35.2629C35.246 6.22493 35.2283 6.22493 35.2114 6.22493H35.2106C35.1897 6.22493 35.1696 6.22412 35.1486 6.22332C35.1406 6.22332 35.1317 6.22251 35.1237 6.2217C35.1221 6.22251 35.1197 6.2217 35.1181 6.22089C35.1052 6.22089 35.0923 6.22009 35.0794 6.21847C35.069 6.21686 35.0593 6.21605 35.048 6.21444C35.0207 6.21121 34.9941 6.20718 34.9667 6.20153V6.20072C34.9539 6.1983 34.9402 6.19507 34.9265 6.19184C34.9168 6.19023 34.9072 6.187 34.8983 6.18458C34.8573 6.17409 34.8162 6.16118 34.7752 6.14504C34.7735 6.14504 34.7719 6.14423 34.7703 6.14343C34.7518 6.13697 34.7333 6.1289 34.7148 6.12083C34.7035 6.11599 34.6914 6.11034 34.6802 6.1055C34.6496 6.09097 34.6198 6.07483 34.5908 6.05869C34.0402 5.73914 33.8173 5.04434 34.095 4.45768C34.3864 3.84036 35.1229 3.57729 35.7387 3.87022C36.3553 4.16314 36.6169 4.9007 36.3247 5.51803Z"
          fill="#FFCBD9"
        />
        <path
          d="M35.4441 6.20222C35.4377 6.20303 35.4313 6.20465 35.4248 6.20545C35.4007 6.21029 35.3765 6.21352 35.3516 6.21675H35.3508C35.3379 6.21836 35.325 6.21998 35.3121 6.22078C35.2977 6.22159 35.284 6.2224 35.2703 6.22321H35.263C35.1423 5.81327 35.032 5.39849 34.9322 4.98291C34.9048 4.86913 35.0803 4.82555 35.1069 4.93852C35.2091 5.36299 35.321 5.78341 35.4441 6.20222Z"
          fill="#1D232A"
        />
        <path
          d="M35.1488 6.22306C35.1407 6.22306 35.1319 6.22225 35.1238 6.22144C35.1222 6.22225 35.1198 6.22144 35.1182 6.22064C35.1053 6.22064 35.0924 6.21983 35.0795 6.21822C35.0691 6.2166 35.0594 6.21579 35.0482 6.21418C35.0208 6.21095 34.9942 6.20692 34.9669 6.20127V6.20046C34.954 6.19804 34.9403 6.19481 34.9266 6.19159C34.9169 6.18997 34.9073 6.18674 34.8984 6.18432C34.8574 6.17383 34.8163 6.16092 34.7753 6.14478C34.7737 6.13429 34.7721 6.1238 34.7704 6.11331V6.14317C34.7519 6.13671 34.7334 6.12864 34.7149 6.12057C34.7036 6.11573 34.6916 6.11008 34.6803 6.10524C34.6497 6.09072 34.6199 6.07458 34.5909 6.05844C34.5942 5.81796 34.603 5.57749 34.6167 5.33701C34.6191 5.31038 34.6304 5.29021 34.6457 5.27488C34.6433 5.25793 34.6417 5.24099 34.6384 5.22323C34.6272 5.14657 34.7028 5.10058 34.7592 5.11429C34.7922 5.04409 34.9121 5.03118 34.9266 5.12478C34.9813 5.49518 35.0546 5.86154 35.1488 6.22306Z"
          fill="#1D232A"
        />
        <path
          d="M35.3249 4.0257L35.3153 4.05636L35.2936 4.1298C35.2936 4.1298 35.2936 4.1298 35.2927 4.1298L34.8154 5.70983C34.3985 7.09054 32.9439 7.87168 31.5675 7.45367C30.1902 7.03566 29.411 5.57748 29.828 4.19677L30.3367 2.51184C30.5251 1.88564 30.9267 1.38371 31.4387 1.06092C31.4628 1.04559 31.487 1.03106 31.5119 1.01654C31.788 0.853532 32.0931 0.742172 32.4135 0.689719C32.4215 0.688105 32.4304 0.687298 32.4384 0.686491C32.4432 0.685684 32.4489 0.684877 32.4553 0.68407C32.6219 0.659862 32.7926 0.650985 32.9657 0.659862C33.1717 0.669545 33.3802 0.705858 33.5863 0.768801C33.7609 0.821254 33.9243 0.890653 34.0781 0.974577C34.6206 1.26992 35.0295 1.74361 35.2509 2.29638C35.3588 2.56671 35.4223 2.85641 35.4352 3.15337C35.4481 3.44065 35.4135 3.73519 35.3249 4.0257Z"
          fill="#FFCBD9"
        />
        <path
          d="M33.786 3.76431C33.9896 3.81354 34.1949 3.68846 34.244 3.4843C34.269 3.38181 34.2496 3.27933 34.1989 3.19621M33.786 3.76431L32.8989 3.54966C32.7975 3.52545 32.7154 3.4617 32.6647 3.37858C32.614 3.29547 32.5947 3.19379 32.6196 3.09131C32.6695 2.88715 32.874 2.76126 33.0768 2.80968L33.9639 3.02433C34.0661 3.04934 34.1482 3.11309 34.1989 3.19621M33.786 3.76431L33.8751 3.39558L33.8754 3.39454L33.8756 3.39346M33.786 3.76431L33.8752 3.3956L33.8755 3.39456L33.8757 3.39339M34.1989 3.19621L33.8757 3.39339M34.1989 3.19621L33.8757 3.39339M33.8756 3.39346L33.8744 3.39423L33.8756 3.39346ZM33.8756 3.39346L33.9035 3.27831"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <path
          d="M33.2692 2.85742L33.3432 3.65793L33.7867 3.76525C33.9904 3.81448 34.1957 3.6894 34.2448 3.48524C34.2697 3.38275 34.2504 3.28027 34.1997 3.19715C34.149 3.11404 34.0669 3.05029 33.9646 3.02527L33.2692 2.85742Z"
          fill="#1D232A"
        />
        <path d="M31.0362 2.6592L31.0363 2.65917L31.0362 2.6592Z" fill="#FCFCFC" stroke="#FCFCFC" />
        <path
          d="M31.3187 2.33447L31.3928 3.13498L31.8363 3.2423C32.04 3.29153 32.2452 3.16645 32.2943 2.96229C32.3193 2.8598 32.3 2.75732 32.2492 2.6742C32.1985 2.59109 32.1164 2.52734 32.0142 2.50232L31.3187 2.33447Z"
          fill="#1D232A"
        />
        <path
          d="M32.1518 4.22986C31.9756 4.22986 31.8049 4.12657 31.7139 3.96356C31.6335 3.81912 31.6359 3.65611 31.7196 3.5278L32.1124 2.93307C32.1494 2.87739 32.2243 2.86206 32.2798 2.89918C32.3354 2.9363 32.3507 3.01135 32.3136 3.06703L31.9216 3.66176C31.8749 3.73277 31.9039 3.80782 31.9248 3.84575C31.9659 3.91918 32.0528 3.99181 32.1583 3.98777C32.2275 3.98535 32.2975 3.97163 32.3684 3.94581C32.4312 3.92322 32.5004 3.95549 32.5229 4.01844C32.5455 4.08138 32.5133 4.15078 32.4505 4.17337C32.3563 4.20807 32.2605 4.22663 32.1655 4.22986C32.1615 4.22986 32.1567 4.22986 32.1518 4.22986Z"
          fill="#1D232A"
        />
        <path
          d="M31.4942 6.01493C31.6311 6.08756 31.772 6.14486 31.9168 6.1852C32.2115 6.26832 32.5189 6.28365 32.824 6.22878C33.1532 6.16987 33.4583 6.03269 33.7191 5.82933C33.8825 5.70264 34.029 5.54932 34.1522 5.37421C34.3671 5.06918 34.3043 4.63342 34.0105 4.40182C33.9373 4.34372 33.856 4.30418 33.7722 4.28158C33.5219 4.2146 33.2498 4.30498 33.088 4.53335C32.9649 4.70766 32.7846 4.82386 32.5801 4.86017C32.4062 4.89084 32.2308 4.86259 32.0722 4.77867C31.8275 4.64794 31.545 4.69152 31.351 4.8634C31.2858 4.9215 31.2302 4.99413 31.19 5.08047C31.0314 5.42262 31.1674 5.84063 31.4942 6.01493Z"
          fill="#1D232A"
        />
        <path
          d="M31.4942 6.01493C31.6311 6.08756 31.772 6.14486 31.9168 6.1852C32.2115 6.26832 32.5189 6.28365 32.824 6.22878C33.1532 6.16987 33.4583 6.03269 33.7191 5.82933C33.8825 5.70264 34.029 5.54932 34.1522 5.37421C34.3671 5.06918 34.3043 4.63342 34.0105 4.40182C33.9373 4.34372 33.856 4.30418 33.7722 4.28158C33.5219 4.2146 33.2498 4.30498 33.088 4.53335C32.9649 4.70766 32.7846 4.82386 32.5801 4.86017C32.4062 4.89084 32.2308 4.86259 32.0722 4.77867C31.8275 4.64794 31.545 4.69152 31.351 4.8634C31.2858 4.9215 31.2302 4.99413 31.19 5.08047C31.0314 5.42262 31.1674 5.84063 31.4942 6.01493Z"
          fill="#1D232A"
        />
        <mask id="path-42-inside-1_8047_3292" fill="white">
          <path d="M31.3525 4.86348C31.6842 5.20563 32.1599 5.38316 32.6469 5.29601C33.1918 5.19836 33.6104 4.79569 33.7738 4.28085C33.5235 4.21387 33.2514 4.30425 33.0896 4.53262C32.9665 4.70692 32.7861 4.82313 32.5817 4.85944C32.4078 4.89011 32.2323 4.86186 32.0738 4.77794C31.8291 4.64882 31.5465 4.69159 31.3525 4.86348Z" />
        </mask>
        <path
          d="M31.3525 4.86348C31.6842 5.20563 32.1599 5.38316 32.6469 5.29601C33.1918 5.19836 33.6104 4.79569 33.7738 4.28085C33.5235 4.21387 33.2514 4.30425 33.0896 4.53262C32.9665 4.70692 32.7861 4.82313 32.5817 4.85944C32.4078 4.89011 32.2323 4.86186 32.0738 4.77794C31.8291 4.64882 31.5465 4.69159 31.3525 4.86348Z"
          fill="#FCFCFC"
        />
        <path
          d="M31.3525 4.86348L30.6894 4.11501L29.9066 4.80854L30.6345 5.55946L31.3525 4.86348ZM32.6469 5.29601L32.8231 6.28037L32.8233 6.28033L32.6469 5.29601ZM33.7738 4.28085L34.727 4.58336L35.0437 3.58543L34.0323 3.31483L33.7738 4.28085ZM33.0896 4.53262L32.2736 3.95453L32.2729 3.95557L33.0896 4.53262ZM32.5817 4.85944L32.7554 5.84424L32.7566 5.84403L32.5817 4.85944ZM32.0738 4.77794L32.5415 3.89409L32.5404 3.8935L32.0738 4.77794ZM30.6345 5.55946C31.1763 6.11847 31.9795 6.43133 32.8231 6.28037L32.4707 4.31165C32.3403 4.33498 32.192 4.29279 32.0706 4.16749L30.6345 5.55946ZM32.8233 6.28033C33.7667 6.11129 34.4618 5.41884 34.727 4.58336L32.8207 3.97833C32.759 4.17254 32.617 4.28544 32.4705 4.31168L32.8233 6.28033ZM34.0323 3.31483C33.3824 3.14097 32.6799 3.38107 32.2736 3.95453L33.9056 5.11071C33.8229 5.22743 33.6645 5.28678 33.5154 5.24687L34.0323 3.31483ZM32.2729 3.95557C32.2965 3.9222 32.3433 3.88614 32.4068 3.87485L32.7566 5.84403C33.229 5.76012 33.6364 5.49165 33.9063 5.10968L32.2729 3.95557ZM32.408 3.87464C32.4303 3.87071 32.4552 3.87024 32.4806 3.87431C32.5058 3.87836 32.5265 3.88611 32.5415 3.89409L31.606 5.66179C31.9573 5.8477 32.3581 5.91431 32.7554 5.84424L32.408 3.87464ZM32.5404 3.8935C31.917 3.56457 31.186 3.67501 30.6894 4.11501L32.0157 5.61195C31.9071 5.70818 31.7411 5.73308 31.6071 5.66237L32.5404 3.8935Z"
          fill="#FCFCFC"
          mask="url(#path-42-inside-1_8047_3292)"
        />
        <path
          d="M31.9169 6.18522C32.2115 6.26834 32.519 6.28367 32.824 6.22879C33.1533 6.16989 33.4583 6.0327 33.7191 5.82935C33.4165 5.57758 33.0293 5.56305 32.8514 5.79707C32.824 5.83338 32.8031 5.87292 32.7878 5.9165C32.7645 5.89148 32.7387 5.8697 32.7097 5.85194C32.465 5.69459 32.1101 5.84387 31.9169 6.18522Z"
          fill="#D0572B"
        />
        <path
          d="M32.4511 2.25396C32.5247 2.19936 32.497 2.03659 32.3891 1.89041C32.2812 1.74423 32.134 1.67 32.0604 1.72461C31.9868 1.77922 32.0146 1.94199 32.1225 2.08816C32.2304 2.23434 32.3775 2.30857 32.4511 2.25396Z"
          fill="#E09661"
        />
        <path
          d="M35.4344 3.15354C35.2492 3.17856 35.0593 3.18017 34.8661 3.15515C33.6611 3.00264 32.807 1.89871 32.96 0.689886C32.9616 0.679396 32.9624 0.669712 32.9648 0.659222C33.1709 0.668905 33.6402 0.242023 35.0359 1.45731C35.7966 2.12063 35.4215 2.85658 35.4344 3.15354Z"
          fill="#E09661"
        />
        <path
          d="M34.9789 7.63774C35.2189 7.63774 35.4135 7.35304 35.4135 7.00185C35.4135 6.65066 35.2189 6.36597 34.9789 6.36597C34.7388 6.36597 34.5442 6.65066 34.5442 7.00185C34.5442 7.35304 34.7388 7.63774 34.9789 7.63774Z"
          fill="#42EC9A"
        />
        <path
          d="M31.1441 11.9461C30.2691 13.6802 28.9297 15.3894 27.3882 16.6224C25.5755 18.0733 23.0761 17.1171 22.4611 15.6669C21.8124 14.1378 21.5234 12.6965 20.6726 9.57843C20.6387 9.45658 19.0522 5.09011 19.6527 3.82399C19.8064 3.50039 20.3804 3.45359 20.6903 4.17582C20.8464 4.53976 20.972 4.869 21.075 5.15386C21.9419 3.08965 22.3541 2.78381 22.7509 2.81448C23.0149 2.83384 23.7088 3.15098 23.1196 4.33318C23.1059 4.35981 23.0914 4.38725 23.0769 4.41549C23.0761 4.4171 23.0745 4.41952 23.0737 4.42114C23.0745 4.42114 23.0761 4.42033 23.0769 4.42033C23.1526 4.40177 23.225 4.38805 23.2935 4.37837C24.3037 4.22666 24.4824 4.92145 24.2159 5.36932C24.1169 5.53555 23.9382 5.68726 23.7595 5.8083C23.51 5.97938 23.2629 6.09155 23.2395 6.10365C23.2621 6.11091 23.2846 6.11818 23.3063 6.12705C24.1483 6.44661 24.3262 7.74098 24.5129 8.20901C25.2551 10.0658 25.9305 11.5095 25.9305 11.5095C25.9305 11.5095 27.1427 9.5986 28.2905 8.36072C28.7325 7.883 29.4706 7.69579 30.1113 7.80392C30.9195 7.9403 31.491 8.7408 31.6013 9.55503C31.7132 10.3692 31.5144 11.2133 31.1441 11.9461Z"
          fill="#FFCBD9"
        />
        <path
          d="M23.0778 4.41556L23.0746 4.34294L23.121 4.33325C23.1072 4.35988 23.0925 4.38732 23.0778 4.41556Z"
          fill="#1D232A"
        />
        <path
          d="M23.2404 6.1037L23.1558 6.21748L23.0778 4.42038C23.1534 4.40182 23.2259 4.3881 23.2943 4.37842L23.7604 5.80835C23.5108 5.97943 23.2637 6.0916 23.2404 6.1037Z"
          fill="#1D232A"
        />
        <path
          d="M23.0487 7.59994L21.2988 7.76133C21.166 7.77343 21.0485 7.67579 21.0364 7.54264L20.4053 0.676208C20.3932 0.54306 20.4906 0.425243 20.6234 0.413139L22.3734 0.251747C22.5062 0.239642 22.6237 0.337284 22.6358 0.470433L23.2669 7.33687C23.2798 7.47002 23.1816 7.58783 23.0487 7.59994Z"
          fill="#42EC9A"
        />
        <path
          d="M23.0487 7.59993L21.2988 7.76132C21.166 7.77343 21.0484 7.67579 21.0364 7.54264L20.6097 2.90503L22.9723 4.12677L23.2677 7.33686C23.2797 7.47001 23.1815 7.58783 23.0487 7.59993Z"
          fill="#1D232A"
        />
        <path
          d="M25.759 7.34969L22.6045 7.64101C22.4716 7.65311 22.3541 7.55547 22.342 7.42232L21.711 0.555885C21.6989 0.422736 21.7963 0.30492 21.9291 0.292815L25.0837 0.00150238C25.2165 -0.010602 25.334 0.0870403 25.3461 0.220189L25.9772 7.08662C25.9901 7.21977 25.8919 7.33759 25.759 7.34969Z"
          fill="#00A868"
        />
        <path
          d="M23.1167 6.26052L22.6839 1.55419L24.504 1.38598L24.9368 6.09231L23.1167 6.26052Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <path d="M24.4758 0.886184L22.14 1.10205L22.6644 6.8042L25.0001 6.58833L24.4758 0.886184Z" fill="#42EC9A" />
        <path
          d="M23.6935 0.729253C23.7779 0.729253 23.8464 0.660608 23.8464 0.57593C23.8464 0.491252 23.7779 0.422607 23.6935 0.422607C23.609 0.422607 23.5405 0.491252 23.5405 0.57593C23.5405 0.660608 23.609 0.729253 23.6935 0.729253Z"
          fill="#1D232A"
        />
        <path
          d="M20.8778 8.11477C20.8609 7.64431 20.6822 6.21195 21.5668 5.77377C22.0876 5.51555 22.3484 5.4518 22.7155 5.30009C22.8813 5.2315 23.0415 5.13385 23.2202 5.13627C23.3989 5.1387 23.6685 5.30251 23.6983 5.60915C23.7144 5.77539 23.6702 5.94727 23.5655 6.07719C23.3112 6.39191 23.0334 6.58316 22.7863 6.67192C23.4206 6.67192 23.898 7.42724 23.898 7.94611"
          fill="#FFCBD9"
        />
        <path d="M25.0321 12.4336C25.6946 11.922 25.7783 11.7332 26.2822 10.9771L25.0321 12.4336Z" fill="#FFCBD9" />
        <path
          d="M25.0321 12.5546C24.9959 12.5546 24.9605 12.5385 24.9363 12.5078C24.8953 12.4546 24.9057 12.3787 24.958 12.3384C25.5304 11.8962 25.6559 11.7065 26.027 11.1433C26.0745 11.0714 26.1252 10.9948 26.1816 10.9101C26.2186 10.8544 26.2934 10.8399 26.349 10.877C26.4045 10.9141 26.419 10.9891 26.382 11.0448C26.3256 11.1287 26.2749 11.2062 26.2282 11.2772C25.8507 11.8494 25.7099 12.0632 25.1054 12.5304C25.0836 12.5466 25.0579 12.5546 25.0321 12.5546Z"
          fill="#1D232A"
        />
        <path
          d="M40.0331 32.3509C39.9904 32.2993 39.9139 32.2928 39.8632 32.3356L34.2954 36.9764L32.1937 34.4426C32.1511 34.3909 32.0746 34.3845 32.0239 34.4272C31.9732 34.47 31.9659 34.5467 32.0086 34.5975L34.1876 37.2242C34.2117 37.2532 34.2455 37.2677 34.2801 37.2677C34.3075 37.2677 34.3349 37.2589 34.3574 37.2395L40.0178 32.5212C40.0685 32.4792 40.0757 32.4026 40.0331 32.3509Z"
          fill="#1D232A"
        />
        <path
          d="M25.9884 32.3358L20.4206 36.9766L18.3189 34.4428C18.2762 34.3911 18.1997 34.3847 18.149 34.4275C18.0975 34.4702 18.0911 34.5469 18.1337 34.5977L20.3127 37.2244C20.3328 37.2494 20.3626 37.2647 20.3948 37.2671C20.3988 37.2671 20.4021 37.268 20.4061 37.268C20.4343 37.268 20.4616 37.2583 20.4834 37.2397L26.1437 32.5214C26.1953 32.4786 26.2017 32.402 26.159 32.3511C26.1156 32.3003 26.0399 32.293 25.9884 32.3358Z"
          fill="#1D232A"
        />
        <path
          d="M45.5485 51.6689L45.687 51.1016C45.7031 51.037 45.6636 50.9708 45.5984 50.9555C45.5341 50.9394 45.468 50.9789 45.4528 51.0443L45.3046 51.6479C44.6913 51.6342 44.098 51.8771 43.7028 52.3088C43.3792 52.663 43.2295 53.1036 43.2923 53.5176C43.3897 54.1535 43.9177 54.4287 44.5641 54.674L43.8421 57.6259C43.0371 57.5056 42.5461 56.8318 42.5316 56.2088C42.53 56.1419 42.4736 56.0894 42.4084 56.091C42.3416 56.0926 42.2893 56.1483 42.2909 56.2145C42.3078 56.9351 42.8665 57.7146 43.7849 57.8623L43.6456 58.4304C43.6296 58.495 43.669 58.5611 43.7342 58.5765C43.7439 58.5789 43.7535 58.5797 43.7632 58.5797C43.8171 58.5797 43.867 58.5426 43.8807 58.4877L44.028 57.8841C44.0602 57.8857 44.0924 57.8873 44.1246 57.8873C44.8201 57.8873 45.501 57.5879 45.9301 57.0836C46.2657 56.6906 46.397 56.2379 46.3004 55.8078C46.1184 54.9976 45.5324 54.7724 44.8507 54.5207L45.4914 51.9021C46.0734 51.9812 46.4348 52.379 46.5821 52.7389C46.607 52.801 46.6779 52.8301 46.7399 52.8051C46.8018 52.7801 46.8308 52.709 46.8059 52.6469C46.5885 52.1216 46.1152 51.7568 45.5485 51.6689ZM43.5313 53.4821C43.479 53.1408 43.607 52.7728 43.8807 52.4734C44.218 52.1046 44.7203 51.8924 45.2459 51.89L44.6229 54.4375C43.9556 54.1801 43.6022 53.9461 43.5313 53.4821ZM46.0645 55.8635C46.1442 56.2169 46.0307 56.5946 45.7466 56.9286C45.3546 57.3886 44.7259 57.6581 44.086 57.6468L44.7919 54.7603C45.4206 54.9919 45.9132 55.1896 46.0645 55.8635Z"
          fill="#1D232A"
        />
        <path
          d="M33.8592 41.1873L34.0113 40.5627C34.0274 40.4981 33.9872 40.432 33.9228 40.4166C33.8584 40.4005 33.7924 40.4409 33.7771 40.5054L33.6153 41.1671C32.9512 41.1502 32.3089 41.4124 31.8807 41.8805C31.5321 42.2614 31.3711 42.7359 31.4395 43.1813C31.545 43.8672 32.1181 44.1626 32.8192 44.428L32.0352 47.6414C31.153 47.5147 30.6145 46.7787 30.5984 46.0977C30.5968 46.0307 30.5404 45.9774 30.4752 45.9798C30.4084 45.9814 30.3561 46.0371 30.3577 46.1041C30.3762 46.8828 30.9823 47.7253 31.9781 47.8794L31.8251 48.5056C31.809 48.5702 31.8493 48.6364 31.9137 48.6517C31.9233 48.6541 31.933 48.6549 31.9426 48.6549C31.9966 48.6549 32.0465 48.6178 32.0602 48.5629L32.222 47.902C32.259 47.9036 32.296 47.9061 32.333 47.9052C33.084 47.9052 33.8182 47.5817 34.2818 47.0378C34.6424 46.6141 34.7841 46.1267 34.6794 45.6651C34.4822 44.7904 33.8463 44.5491 33.1066 44.2763L33.8029 41.4237C34.442 41.5069 34.8388 41.9418 35.0006 42.3364C35.0256 42.3985 35.0964 42.4276 35.1584 42.4026C35.2204 42.3776 35.2493 42.3065 35.2244 42.2444C34.9885 41.6723 34.4742 41.2785 33.8592 41.1873ZM31.6786 43.1442C31.6215 42.7714 31.7599 42.3703 32.0585 42.0435C32.428 41.6392 32.9802 41.4076 33.5565 41.4076L32.8772 44.19C32.2429 43.9495 31.7631 43.6978 31.6786 43.1442ZM34.442 45.7176C34.5289 46.1033 34.4058 46.5157 34.0959 46.8796C33.6668 47.3831 32.9778 47.6769 32.2783 47.6607L33.047 44.5128C33.7352 44.7662 34.2754 44.98 34.442 45.7176Z"
          fill="#1D232A"
        />
        <path
          d="M52.6596 31.9952C52.4334 31.4763 51.956 31.1253 51.3918 31.051L51.5157 30.487C51.5302 30.4216 51.4892 30.357 51.424 30.3425C51.3588 30.328 51.2944 30.3691 51.2799 30.4345L51.1471 31.0357C50.5385 31.0365 49.9558 31.2907 49.5742 31.7289C49.2611 32.0872 49.1226 32.5278 49.1951 32.9377C49.3062 33.5663 49.8358 33.827 50.4822 34.0553L49.8342 36.9967C49.0341 36.8958 48.5326 36.2398 48.5044 35.6233C48.5012 35.5563 48.4449 35.5046 48.3781 35.5079C48.3113 35.5111 48.2597 35.5676 48.263 35.6346C48.296 36.3487 48.8675 37.1089 49.7819 37.2348L49.6571 37.8004C49.6426 37.8658 49.6837 37.9304 49.7489 37.9449C49.7577 37.9465 49.7666 37.9481 49.7746 37.9481C49.8302 37.9481 49.8801 37.9094 49.8922 37.8529L50.0242 37.2525C50.0371 37.2525 50.0507 37.2533 50.0636 37.2533C50.7736 37.2533 51.4666 36.937 51.8925 36.4141C52.216 36.0163 52.336 35.5636 52.2305 35.1399C52.0309 34.341 51.4449 34.1304 50.7631 33.8972L51.3379 31.2883C51.915 31.3536 52.2821 31.7386 52.4366 32.092C52.4632 32.1533 52.5348 32.1816 52.596 32.1541C52.6588 32.1283 52.6861 32.0565 52.6596 31.9952ZM49.4333 32.8957C49.3738 32.5584 49.4913 32.1913 49.7561 31.8887C50.0813 31.5158 50.574 31.2947 51.094 31.2794L50.5353 33.8173C49.8704 33.5776 49.5146 33.3541 49.4333 32.8957ZM51.9979 35.1988C52.0848 35.5466 51.9818 35.9243 51.7073 36.2616C51.329 36.7264 50.7132 37.0072 50.0797 37.0112L50.7132 34.1352C51.3403 34.3507 51.8329 34.5355 51.9979 35.1988Z"
          fill="#1D232A"
        />
        <path d="M13.6655 35.6772L13.8345 35.0542L12.7648 35.4246L13.6655 35.6772Z" fill="#1D232A" />
        <path
          d="M11.2354 31.9225C11.2233 31.9225 11.2112 31.9209 11.1992 31.9168L9.48462 31.3826C9.42103 31.3625 9.38561 31.2947 9.40493 31.2309C9.42505 31.1672 9.49267 31.1317 9.55626 31.151L11.2708 31.6852C11.3344 31.7054 11.3698 31.7732 11.3505 31.837C11.3344 31.8894 11.2869 31.9225 11.2354 31.9225Z"
          fill="#1D232A"
        />
        <path
          d="M12.3986 31.5675C12.3865 31.5675 12.3744 31.5659 12.3624 31.5619L10.6478 31.0277C10.5842 31.0075 10.5488 30.9397 10.5681 30.8759C10.5875 30.8122 10.6559 30.7767 10.7195 30.7961L12.434 31.3303C12.4976 31.3504 12.533 31.4182 12.5137 31.482C12.4976 31.5344 12.4501 31.5675 12.3986 31.5675Z"
          fill="#1D232A"
        />
        <path
          d="M26.8924 5.47685C26.8312 5.47685 26.7789 5.43085 26.7724 5.36871C26.7652 5.30254 26.8135 5.24283 26.8803 5.23556C27.5275 5.16778 28.2866 4.87566 28.7269 4.52544C28.7792 4.48428 28.8548 4.49235 28.8967 4.54481C28.9378 4.59726 28.9297 4.67311 28.8774 4.71507C28.4041 5.09112 27.5935 5.40422 26.9053 5.47604C26.9012 5.47604 26.8964 5.47685 26.8924 5.47685Z"
          fill="#1D232A"
        />
        <path
          d="M34.0951 9.26874C33.9502 9.3914 33.7739 9.47774 33.5831 9.52213C33.4793 9.54795 33.3714 9.56086 33.262 9.56086C33.1291 9.56086 32.9939 9.5423 32.8619 9.50276C32.461 9.38252 32.0988 9.08475 31.8541 8.67643C31.8348 8.64577 31.8171 8.6151 31.8002 8.58282C31.7833 8.55135 31.7817 8.51504 31.7937 8.48438C31.8396 8.50455 31.8863 8.52472 31.9322 8.54409C31.9781 8.56346 32.024 8.58282 32.0698 8.60138C32.0795 8.60542 32.0892 8.60945 32.098 8.61349C32.102 8.6143 32.1053 8.61591 32.1085 8.61752C32.5866 8.81281 33.0744 8.98227 33.5695 9.12591C33.3425 9.22597 33.0849 9.25422 32.8434 9.19854C32.7476 9.17675 32.655 9.14205 32.5681 9.09605C32.6824 9.17433 32.8048 9.23404 32.9311 9.27116C33.2209 9.35751 33.5381 9.322 33.7852 9.18724C33.8882 9.21548 33.9912 9.24292 34.0951 9.26874Z"
          fill="#1D232A"
        />
        <path d="M20.8778 8.11454C20.8617 7.61099 20.6766 6.07615 21.6256 5.60327L20.8778 8.11454Z" fill="#FFCBD9" />
        <path
          d="M20.8779 8.23565C20.8127 8.23565 20.7595 8.184 20.7571 8.11864C20.7555 8.07184 20.7523 8.01535 20.7491 7.9516C20.7145 7.30442 20.642 5.95841 21.5717 5.49521C21.6313 5.46535 21.7037 5.48956 21.7335 5.54928C21.7633 5.60899 21.7392 5.68162 21.6796 5.71148C20.8916 6.10447 20.9584 7.34315 20.9906 7.93869C20.9938 8.00405 20.997 8.06215 20.9986 8.11138C21.001 8.17835 20.9487 8.23404 20.8819 8.23646C20.8803 8.23565 20.8787 8.23565 20.8779 8.23565Z"
          fill="#1D232A"
        />
        <g filter="url(#filter0_d_8047_3292)">
          <path
            d="M66 31C73.732 31 80 24.732 80 17C80 9.26801 73.732 3 66 3C58.268 3 52 9.26801 52 17C52 24.732 58.268 31 66 31Z"
            fill="#E8E9EA"
          />
        </g>
        <path
          d="M74.9698 11.2032L74.1175 15.1614C73.9987 15.7127 73.5109 16.0901 72.9689 16.0901C72.8878 16.0901 72.8067 16.0819 72.7244 16.0642L68.7627 15.2237C68.1279 15.0885 67.7223 14.4654 67.8563 13.8294C67.9915 13.1946 68.6157 12.7891 69.2506 12.9242L70.753 13.2428C69.2482 11.3443 66.6772 10.4567 64.2249 11.2091C62.7378 11.6652 61.4964 12.6621 60.7311 14.014C60.4102 14.5795 59.6931 14.7781 59.1288 14.4584C58.5633 14.1374 58.3647 13.4203 58.6844 12.8561C59.7483 10.9775 61.4706 9.59499 63.5349 8.96135C66.8724 7.9386 70.3685 9.10242 72.4728 11.6323L72.6715 10.7083C72.8079 10.0735 73.4333 9.66905 74.0681 9.8066C74.7029 9.94297 75.1073 10.5684 74.9698 11.2032Z"
          fill="#00A868"
        />
        <path
          d="M73.3169 21.1451C72.2542 23.0237 70.5308 24.4062 68.4676 25.0398C67.6482 25.2914 66.8206 25.4101 66.006 25.4101C63.4984 25.4101 61.1179 24.2792 59.5297 22.3701L59.331 23.2929C59.2123 23.8443 58.7244 24.2216 58.1825 24.2216C58.1002 24.2216 58.0167 24.2134 57.9332 24.1946C57.2996 24.0582 56.8952 23.4328 57.0316 22.798L57.885 18.8398C58.0214 18.2061 58.6445 17.8017 59.2781 17.9369L63.2398 18.7775C63.8746 18.9127 64.2802 19.5357 64.145 20.1717C64.011 20.8065 63.3868 21.2121 62.752 21.0769L61.2484 20.7583C62.7543 22.6569 65.3253 23.5445 67.7776 22.7921C69.2647 22.336 70.5049 21.3391 71.2714 19.9872C71.5911 19.4217 72.3082 19.223 72.8737 19.544C73.4392 19.8637 73.6378 20.5808 73.3169 21.1451Z"
          fill="#00A868"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_8047_3292"
          x="49"
          y="2"
          width="34"
          height="34"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.290196 0 0 0 0 0.309804 0 0 0 0 0.333333 0 0 0 0.32 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8047_3292" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8047_3292" result="shape" />
        </filter>
        <clipPath id="clip0_8047_3292">
          <rect width="81" height="60" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
