export function TopupSuccessIllustration() {
  return (
    <svg width="160" height="163" viewBox="0 0 160 163" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3299_12962)">
        <path
          d="M83.6496 22.3503C83.6321 22.3503 83.6121 22.3478 83.5946 22.3453C83.4421 22.3153 83.3446 22.1678 83.3746 22.0153L87.6996 0.320298C87.7296 0.167798 87.8771 0.0702977 88.0296 0.100298C88.1821 0.130298 88.2796 0.277798 88.2496 0.430298L83.9246 22.1253C83.8971 22.2603 83.7796 22.3503 83.6496 22.3503Z"
          fill="#1D232A"
        />
        <path
          d="M57.8468 31.5677C57.7918 31.5677 57.7368 31.5502 57.6868 31.5177L39.5618 18.8327C39.4343 18.7452 39.4043 18.5702 39.4943 18.4427C39.5818 18.3177 39.7568 18.2852 39.8843 18.3752L58.0093 31.0602C58.1368 31.1477 58.1668 31.3227 58.0768 31.4502C58.0218 31.5277 57.9368 31.5677 57.8468 31.5677Z"
          fill="#1D232A"
        />
        <path
          d="M69.8814 25.2953C69.7664 25.2953 69.6614 25.2253 69.6189 25.1128L62.0089 4.34029C61.9564 4.19529 62.0314 4.03529 62.1764 3.98279C62.3214 3.93029 62.4814 4.00279 62.5364 4.15029L70.1464 24.9228C70.1989 25.0678 70.1239 25.2278 69.9789 25.2803C69.9464 25.2903 69.9139 25.2953 69.8814 25.2953Z"
          fill="#1D232A"
        />
        <path
          d="M83.649 22.4453C83.624 22.4453 83.599 22.4428 83.5765 22.4378C83.374 22.3978 83.2415 22.2003 83.2815 21.9978L87.6065 0.302832C87.6465 0.100332 87.844 -0.0346679 88.0465 0.00783214C88.249 0.0478321 88.3815 0.245332 88.3415 0.447832L84.0165 22.1428C83.9815 22.3228 83.824 22.4453 83.649 22.4453Z"
          fill="#1D232A"
        />
        <path
          d="M57.8464 31.6629C57.7714 31.6629 57.6964 31.6404 57.6314 31.5954L39.5064 18.9104C39.3364 18.7929 39.2964 18.5579 39.4139 18.3879C39.5339 18.2179 39.7664 18.1779 39.9364 18.2954L58.0614 30.9804C58.2314 31.0979 58.2714 31.3329 58.1539 31.5029C58.0814 31.6079 57.9664 31.6629 57.8464 31.6629Z"
          fill="#1D232A"
        />
        <path
          d="M69.8816 25.3929C69.7291 25.3929 69.5841 25.2979 69.5291 25.1479L61.9191 4.3754C61.8491 4.1804 61.9466 3.9654 62.1416 3.8954C62.3366 3.8254 62.5516 3.9229 62.6216 4.1179L70.2316 24.8904C70.3016 25.0854 70.2041 25.3004 70.0091 25.3704C69.9691 25.3854 69.9266 25.3929 69.8816 25.3929Z"
          fill="#1D232A"
        />
        <path
          d="M127.667 144.163C127.607 144.163 127.549 144.145 127.497 144.105L109.909 130.685C109.787 130.593 109.762 130.415 109.857 130.293C109.952 130.17 110.127 130.148 110.249 130.24L127.837 143.66C127.959 143.753 127.984 143.93 127.889 144.053C127.834 144.125 127.752 144.163 127.667 144.163Z"
          fill="#1D232A"
        />
        <path
          d="M79.4141 162.385C79.3916 162.385 79.3691 162.383 79.3491 162.378C79.1991 162.34 79.1066 162.19 79.1441 162.04L84.3566 140.54C84.3941 140.39 84.5441 140.298 84.6941 140.335C84.8441 140.373 84.9366 140.523 84.8991 140.673L79.6866 162.173C79.6541 162.3 79.5416 162.385 79.4141 162.385Z"
          fill="#1D232A"
        />
        <path
          d="M105.827 158.25C105.714 158.25 105.609 158.183 105.567 158.073L97.5441 137.458C97.4891 137.313 97.5591 137.153 97.7041 137.095C97.8491 137.04 98.0091 137.11 98.0666 137.255L106.089 157.87C106.144 158.015 106.074 158.175 105.929 158.233C105.897 158.245 105.862 158.25 105.827 158.25Z"
          fill="#1D232A"
        />
        <path
          d="M127.666 144.258C127.586 144.258 127.506 144.233 127.439 144.18L109.851 130.76C109.686 130.635 109.654 130.4 109.781 130.235C109.906 130.07 110.141 130.04 110.306 130.165L127.894 143.585C128.059 143.71 128.091 143.945 127.964 144.11C127.891 144.208 127.779 144.258 127.666 144.258Z"
          fill="#1D232A"
        />
        <path
          d="M79.4137 162.48C79.3837 162.48 79.3537 162.478 79.3262 162.47C79.1237 162.42 79.0012 162.218 79.0512 162.018L84.2637 140.518C84.3137 140.315 84.5162 140.195 84.7162 140.243C84.9187 140.293 85.0412 140.495 84.9912 140.695L79.7787 162.195C79.7362 162.365 79.5837 162.48 79.4137 162.48Z"
          fill="#1D232A"
        />
        <path
          d="M105.826 158.345C105.676 158.345 105.533 158.255 105.476 158.105L97.4534 137.49C97.3784 137.298 97.4734 137.08 97.6659 137.005C97.8584 136.93 98.0759 137.025 98.1509 137.218L106.173 157.833C106.248 158.025 106.153 158.243 105.961 158.318C105.918 158.338 105.873 158.345 105.826 158.345Z"
          fill="#1D232A"
        />
        <path
          d="M92.7336 117.003C90.9686 122.845 87.3786 127.26 83.0211 130.485C80.9011 132.06 78.5936 133.353 76.2311 134.388C71.3736 136.52 66.2636 137.573 61.9511 137.798C47.2586 138.56 35.8111 128.295 29.6311 121.2C25.6836 116.668 17.4111 112.25 11.0011 111.045C2.60611 109.468 -0.93389 104.043 1.31611 98.9628C4.09611 92.6853 19.1711 90.6453 28.0761 95.0153C17.6761 79.8853 7.23611 63.5903 16.6036 58.2003C28.0436 51.6228 42.2411 78.1878 42.8086 79.2578C42.3036 78.0628 29.1336 46.7278 41.4111 44.0678C53.7311 41.3953 64.5111 73.4153 64.8386 74.4003C64.6711 73.5853 59.9111 50.2878 71.0936 48.2828C77.3236 47.1678 80.2461 52.7678 82.0611 58.6953C82.8361 61.2253 84.4836 69.8303 86.0861 77.1003C86.0936 77.1228 86.0961 77.1503 86.1036 77.1728C86.3161 78.1478 86.5311 79.1003 86.7386 80.0078C88.1036 85.8953 97.2186 102.145 92.7336 117.003Z"
          fill="#8F5049"
        />
        <path
          d="M71.5016 112.303C71.3391 112.303 71.1891 112.195 71.1416 112.033C69.3341 105.805 64.0366 100.573 57.3116 98.3803C51.2091 96.3903 44.1566 96.8203 37.4466 99.5928C37.2566 99.6703 37.0366 99.5803 36.9566 99.3903C36.8766 99.2003 36.9691 98.9803 37.1591 98.9003C44.0316 96.0603 51.2691 95.6228 57.5416 97.6678C64.4991 99.9378 69.9841 105.36 71.8591 111.823C71.9166 112.023 71.8016 112.23 71.6041 112.288C71.5716 112.298 71.5366 112.303 71.5016 112.303Z"
          fill="#1D232A"
        />
        <path
          d="M92.7336 117.003C91.7861 120.138 90.3136 122.863 88.4786 125.213C78.8511 121.223 72.7311 108.698 75.4086 94.0279C76.3386 88.9329 84.3136 83.1679 86.1036 77.1729C86.3161 78.1479 86.5311 79.1004 86.7386 80.0079C88.1036 85.8954 97.2186 102.145 92.7336 117.003Z"
          fill="#1D232A"
        />
        <path
          d="M150.751 82.8904C151.516 82.2454 152.291 81.6229 153.071 81.0204C157.726 77.4279 161.546 73.2529 157.884 67.3604C154.469 61.8679 144.579 63.3579 137.011 68.9704C141.221 52.7979 143.319 29.8179 134.081 27.1704C121.131 23.4579 119.016 56.6054 119.016 56.6054C119.016 56.6054 120.209 46.0804 119.224 36.5379C118.401 28.5679 117.636 22.0479 111.179 21.5254C104.451 20.9804 100.059 26.9404 98.8636 34.7504C97.2886 45.0504 97.0811 55.7304 97.0811 55.7304C97.0811 55.7304 97.4986 31.1179 85.9361 31.0779C79.6061 31.0554 77.6936 37.0779 76.9336 43.2279C76.5636 46.2179 77.2461 55.3604 76.0111 65.0329C75.2461 71.0279 70.4811 88.2029 76.5061 102.505C83.2036 118.398 96.0261 123.04 112.929 119.898C130.139 116.7 137.046 100.665 139.766 95.7429C142.486 90.8204 146.446 86.5229 150.751 82.8904Z"
          fill="#E09661"
        />
        <path
          d="M96.8261 65.4479C96.8086 65.4479 96.7935 65.4479 96.776 65.4454C96.571 65.4179 96.426 65.2279 96.4535 65.0229C96.771 62.6829 97.1211 55.8304 96.496 50.5929C96.471 50.3879 96.6185 50.2004 96.8235 50.1754C97.0311 50.1479 97.2161 50.2979 97.2411 50.5029C97.8736 55.8054 97.5186 62.7504 97.1961 65.1229C97.1736 65.3104 97.0111 65.4479 96.8261 65.4479Z"
          fill="#1D232A"
        />
        <path
          d="M117.886 66.9129C117.864 66.9129 117.841 66.9104 117.819 66.9079C117.616 66.8704 117.481 66.6754 117.516 66.4729C118.184 62.8104 118.866 58.7154 119.234 52.4879C119.246 52.2804 119.424 52.1204 119.631 52.1354C119.839 52.1479 119.996 52.3254 119.984 52.5329C119.611 58.8004 118.926 62.9229 118.254 66.6079C118.221 66.7879 118.064 66.9129 117.886 66.9129Z"
          fill="#1D232A"
        />
        <path
          d="M137.016 68.9254C136.244 73.1179 135.629 76.5929 134.644 81.8904L143.374 65.4203C141.679 66.0728 139.111 67.3454 137.016 68.9254Z"
          fill="#1D232A"
        />
      </g>
      <defs>
        <clipPath id="clip0_3299_12962">
          <rect width="158.693" height="162.48" fill="white" transform="translate(0.65332)" />
        </clipPath>
      </defs>
    </svg>
  )
}
