import { theme } from '@dlpco/ginga-stone'
import styled from 'styled-components'

export const TableContent = styled.div`
  max-height: 300px;
  overflow-x: auto;
  margin-top: 0;
  line-height: 0;
`

export const Input = styled.input`
  padding: 6px 12px;
  border-radius: 5px;
  outline: 0;
  width: 150px;
  background: ${theme.dark.color.neutralOverlayHover};
  color: #333;
  border-color: ${theme.dark.color.neutralLow};
  border-width: 1px;
  border-style: solid;
  font-weight: 600;
`
