export function OnboardingCoinsIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_2001_82246)">
        <g clipPath="url(#clip1_2001_82246)">
          <path
            d="M38.7004 17.0176C38.7004 18.014 38.608 18.9909 38.4359 19.9328C38.3729 20.2966 38.2918 20.6549 38.2036 21.0118C38.0902 21.4694 37.9517 21.9215 37.7963 22.3665C37.683 22.6996 37.5556 23.0271 37.4129 23.3532C37.3359 23.5519 37.2449 23.7506 37.1511 23.948C37.0028 24.2587 36.8503 24.5666 36.6781 24.8717C36.5759 25.0578 36.4752 25.2397 36.3618 25.4189C33.5096 30.166 28.3132 33.3415 22.3834 33.3401L16.4747 33.3387L16.4159 14.6846L16.3711 2.51576L17.8476 2.51436L29.0185 2.08191C29.2551 2.18827 29.4874 2.29883 29.7211 2.41639C30.1228 2.61652 30.516 2.83345 30.8925 3.06857C27.111 3.25051 23.3295 3.43524 19.5466 3.61998C19.0792 3.64237 19.0876 4.37152 19.5578 4.35053C23.6724 4.1476 27.794 3.94747 31.9114 3.74454C31.9519 3.77533 31.9953 3.80611 32.0359 3.8369C35.252 6.19089 37.5626 9.69948 38.3813 13.7539C38.5899 14.8049 38.7018 15.8979 38.7004 17.0176Z"
            fill="#1D232A"
          />
          <path
            d="M31.7505 22.7499C28.5666 31.1848 19.1576 35.4534 10.7339 32.2862C8.52968 31.4591 6.61374 30.2038 5.03929 28.6517C3.62717 27.2578 2.49356 25.6273 1.67764 23.85L5.5655 20.904C5.36257 20.333 5.20583 19.7466 5.09387 19.1476L1.03666 22.2223C0.921902 21.8808 0.818338 21.5366 0.727369 21.1895C0.745563 21.1811 0.762357 21.1699 0.780551 21.1573L4.83915 18.0811L4.89374 17.1701C4.89374 17.1701 4.57325 17.4094 4.4193 17.5255L0.549631 20.4561C0.373291 19.6388 0.26133 18.8061 0.213746 17.965C0.0891891 15.7817 0.40548 13.5355 1.2158 11.3537C1.227 11.3285 1.234 11.3033 1.24519 11.2781C3.5516 5.16919 9.12448 1.24494 15.2236 0.748111C15.6029 0.717321 15.9849 0.699128 16.3684 0.696329L22.3499 0.689331H22.4283C23.8068 0.690731 25.1476 0.861472 26.4239 1.18476C27.0047 1.33311 27.5757 1.51085 28.1271 1.71658C28.4266 1.82994 28.7247 1.9531 29.0186 2.08185L23.5619 2.29318C24.2099 2.60247 24.8285 2.95095 25.4163 3.33302C27.2412 3.24485 29.0676 3.15668 30.8926 3.06851C30.8982 3.06851 30.901 3.07271 30.9052 3.07411C31.2523 3.28544 31.5882 3.50796 31.9114 3.74448C30.0697 3.83545 28.2265 3.92502 26.3833 4.01459C28.2237 5.4099 29.717 7.1691 30.7974 9.14522C33.0045 13.173 33.5027 18.1091 31.7505 22.7499Z"
            fill="#42EC9A"
          />
          <path
            d="M8.65926 24.9745L8.65986 24.9751C8.77161 25.0859 8.88604 25.1945 9.00274 25.299L26.5003 12.0501C26.4299 11.9085 26.3566 11.7679 26.2808 11.6292C25.5189 10.2394 24.4609 9.00821 23.1535 8.03923L8.65926 24.9745ZM8.65926 24.9745C7.50861 23.8403 6.62802 22.4751 6.06411 20.9827M8.65926 24.9745L8.46276 25.1739C7.28297 24.0109 6.38028 22.6113 5.80228 21.0817M5.80228 21.0817L6.06422 20.983C6.06419 20.9829 6.06415 20.9828 6.06411 20.9827M5.80228 21.0817C5.63153 20.6282 5.48598 20.1636 5.37402 19.6891L5.80228 21.0817ZM6.06411 20.9827C5.91845 20.5959 5.79194 20.2017 5.68974 19.8012M6.06411 20.9827L5.68974 19.8012M5.68974 19.8012L22.1168 7.36019C22.4776 7.5672 22.8231 7.79468 23.1534 8.03911L5.68974 19.8012Z"
            fill="#FCFCFC"
            stroke="#FCFCFC"
            strokeWidth="0.559807"
          />
          <path
            d="M1.50728 22.7163C1.45879 22.5858 1.41164 22.4554 1.36579 22.3244L4.90929 19.6399C4.99802 20.0334 5.10626 20.4221 5.23446 20.8036L1.78977 23.4137C1.68993 23.1831 1.59534 22.9505 1.50728 22.7163Z"
            fill="#FCFCFC"
            stroke="#FCFCFC"
            strokeWidth="0.559807"
          />
          <mask id="path-5-inside-1_2001_82246" fill="white">
            <path d="M1.03656 22.2238C1.03935 22.2336 1.04215 22.2419 1.04495 22.2517C1.04075 22.2419 1.03796 22.2336 1.03516 22.2238H1.03656Z" />
          </mask>
          <path
            d="M1.03656 22.2238C1.03935 22.2336 1.04215 22.2419 1.04495 22.2517C1.04075 22.2419 1.03796 22.2336 1.03516 22.2238H1.03656Z"
            fill="#FCFCFC"
          />
          <path
            d="M1.03656 22.2238L1.57482 22.0699L1.4588 21.6639H1.03656V22.2238ZM1.04495 22.2517L0.530424 22.4723L1.58321 22.0979L1.04495 22.2517ZM1.03516 22.2238V21.6639H0.292963L0.496896 22.3776L1.03516 22.2238ZM0.498295 22.3776C0.501478 22.3887 0.504499 22.3985 0.505707 22.4024C0.507298 22.4076 0.507076 22.4069 0.506693 22.4056L1.58321 22.0979C1.58003 22.0868 1.57701 22.077 1.5758 22.0731C1.57421 22.0679 1.57443 22.0686 1.57482 22.0699L0.498295 22.3776ZM1.55948 22.0312C1.56266 22.0386 1.56573 22.0465 1.56853 22.0546C1.57121 22.0623 1.57287 22.068 1.57342 22.0699L0.496896 22.3776C0.504352 22.4037 0.514608 22.4354 0.530424 22.4723L1.55948 22.0312ZM1.03516 22.7836H1.03656V21.6639H1.03516V22.7836Z"
            fill="#FCFCFC"
            mask="url(#path-5-inside-1_2001_82246)"
          />
          <path
            d="M15.7948 24.6365C15.1846 24.6365 14.5534 24.5259 13.932 24.3034C11.62 23.4735 10.4024 21.0355 10.5606 19.1602C10.5704 19.044 10.6711 18.9615 10.7873 18.9685C10.9034 18.9783 10.9888 19.0804 10.979 19.1952C10.8335 20.9138 11.9517 23.146 14.0734 23.9088C15.6016 24.4574 17.1803 24.2656 18.2957 23.3979C19.1382 22.7416 19.5651 21.7843 19.4671 20.7696C19.281 18.8565 18.1026 18.0168 16.6093 16.956L16.4721 16.8594C14.9466 15.772 13.6787 14.7363 13.6213 13.0541C13.5835 11.9499 14.0831 10.9366 14.9914 10.2747C16.0677 9.48953 17.5623 9.32719 18.9885 9.83941C20.6875 10.4496 21.6125 11.9583 21.9106 13.1675C21.9386 13.2794 21.8701 13.3942 21.7567 13.4222C21.6433 13.4502 21.53 13.3816 21.502 13.2682C21.2291 12.1654 20.388 10.7897 18.8443 10.2355C17.5302 9.76384 16.2146 9.90239 15.2364 10.6147C14.4428 11.1941 14.0062 12.0786 14.0384 13.0401C14.0901 14.5334 15.2294 15.4613 16.7142 16.5179L16.85 16.6145C18.3685 17.6935 19.6784 18.6256 19.8827 20.7304C19.9961 21.8906 19.5105 22.9851 18.5518 23.731C17.7835 24.3272 16.8178 24.6365 15.7948 24.6365Z"
            fill="#1D232A"
          />
          <path
            d="M13.3243 25.9227C13.3006 25.9227 13.2768 25.9185 13.253 25.9101C13.1438 25.8709 13.0864 25.7505 13.1256 25.6413L19.3185 8.32511C19.3577 8.21595 19.478 8.15857 19.5872 8.19775C19.6964 8.23694 19.7537 8.3573 19.7145 8.46646L13.5217 25.7841C13.4909 25.8695 13.4111 25.9227 13.3243 25.9227Z"
            fill="#1D232A"
          />
          <path
            d="M0.861453 20.5712L4.57839 17.7563L4.56763 17.9358L0.895877 20.7188C0.884092 20.6696 0.87261 20.6204 0.861453 20.5712Z"
            fill="#FCFCFC"
            stroke="#FCFCFC"
            strokeWidth="0.559807"
          />
          <path
            d="M8.48911 25.6245C8.43733 25.6245 8.38555 25.6049 8.34496 25.5672C7.01542 24.3034 5.98677 22.7149 5.36818 20.9739C4.57886 18.7515 4.47109 16.3709 5.06029 14.0883C5.16246 13.6894 5.29401 13.2752 5.44936 12.8581C5.93499 11.5734 6.62635 10.4006 7.50245 9.37332C7.57803 9.28515 7.70958 9.27395 7.79775 9.34952C7.88592 9.4251 7.89712 9.55665 7.82154 9.64482C6.97763 10.6357 6.31146 11.7665 5.84262 13.0051C5.69147 13.4081 5.56552 13.807 5.46755 14.1919C4.89935 16.3933 5.00291 18.6885 5.76425 20.8312C6.36044 22.5092 7.3527 24.0417 8.63466 25.2607C8.71864 25.3404 8.72143 25.4734 8.64166 25.5574C8.59968 25.6021 8.54509 25.6245 8.48911 25.6245Z"
            fill="#1D232A"
          />
          <path
            d="M49.6255 23.2117V63.133H3.16851V52.9879H0V43.2682H3.17271V33.34H33.0412V23.2117H49.6255Z"
            fill="#42EC9A"
          />
          <path
            d="M53.1315 62.8531V23.4916H57.1173V62.8531H53.1315Z"
            fill="#FCFCFC"
            stroke="#FCFCFC"
            strokeWidth="0.559807"
          />
          <path
            d="M49.9059 62.8531V23.4916H51.0157V62.8531H49.9059Z"
            fill="#FCFCFC"
            stroke="#FCFCFC"
            strokeWidth="0.559807"
          />
          <path
            d="M60.8249 32.9313V43.271H64.0004V52.992H60.8249V63.1329H57.3975V23.2117H64.0004V32.9313H60.8249Z"
            fill="#42EC9A"
          />
          <path d="M52.8518 23.2117H51.2969V63.133H52.8518V23.2117Z" fill="#42EC9A" />
          <path d="M33.0411 42.8582H31.4541V53.2594H33.0411V42.8582Z" fill="#1D232A" />
          <path
            d="M25.8535 63.133H31.4558V33.3401H29.9513L28.6847 42.5489L28.535 43.6349L27.2684 52.841L27.1187 53.927L25.8535 63.133Z"
            fill="#1D232A"
          />
          <path
            d="M60.824 42.8499V43.2697H3.1722C3.168 43.2697 3.1624 43.2697 3.1582 43.2683H3.1722V42.8499H60.824Z"
            fill="#1D232A"
          />
          <path d="M60.8243 32.9202H33.041V33.3401H60.8243V32.9202Z" fill="#1D232A" />
          <path d="M60.8239 52.9907H3.16797V53.4106H60.8239V52.9907Z" fill="#1D232A" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2001_82246">
          <rect width="64" height="62.69" fill="white" transform="translate(0 0.566162)" />
        </clipPath>
        <clipPath id="clip1_2001_82246">
          <rect width="64" height="62.4437" fill="white" transform="translate(0 0.689331)" />
        </clipPath>
      </defs>
    </svg>
  )
}
