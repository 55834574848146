export function DocumentsGreyAndGreenIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={100} height={88} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M100 13.383l-8.793 73.981-48.146-5.723-7.243-.86-1.634-.195-.112-.012.271-2.283.236-1.977 4.349-36.602 3.71-31.217.225-1.9 14.796 1.757L100 13.383z"
        fill="#008738"
      />
      <path
        d="M75.781 68.1a.269.269 0 01-.257-.195L58.793 8.828a.267.267 0 01.184-.331.267.267 0 01.33.184L76.04 67.758a.267.267 0 01-.259.342z"
        fill="#1D232A"
      />
      <path
        d="M76.176 74.28L39.914 81l-4.096-.492.176-2.063 2.934-39.124 2.369-31.58 1.448-.665 16.307 1.679 17.124 65.524z"
        fill="#1D232A"
        stroke="#1D232A"
        strokeWidth={3}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M56.163 0L0 12.515l16.204 72.72 56.163-12.514L56.163 0z" fill="#E8E9EA" />
      <path
        d="M11.33 24.393a.267.267 0 01-.058-.53l39.5-8.803a.268.268 0 11.116.524l-39.5 8.804a.328.328 0 01-.058.005zM13.897 35.912a.267.267 0 01-.057-.53l39.5-8.803a.268.268 0 11.116.524l-39.5 8.801c-.02.004-.04.008-.059.008zM16.465 47.429a.267.267 0 01-.058-.53l39.5-8.803a.268.268 0 11.116.524l-39.5 8.802a.33.33 0 01-.058.007zM19.032 58.946a.268.268 0 01-.057-.53l39.5-8.803a.268.268 0 11.116.524l-39.5 8.804a.36.36 0 01-.06.005zM21.598 70.465a.268.268 0 01-.057-.53l27.873-6.213a.269.269 0 01.116.524L21.657 70.46a.328.328 0 01-.06.005z"
        fill="#1D232A"
      />
    </svg>
  )
}
