export const __LOG_KEY = '__pub_sub_log'

export interface __PubSubLog {
  type: 'PUB' | 'SUB'
  eventName: string
  at: string
  ['stack trace']?: string
}

function parseStackTrace(stack: string, lineIndex = 3) {
  const stackTrace = stack.split('\n')[lineIndex]

  return stackTrace
    .replace('webpack-internal:///', '')
    .replace(/:\d+:\d+/gm, '')
    .trim()
}

export function savePubSubLog(type: __PubSubLog['type'], eventName: string) {
  const stack = new Error().stack

  if (!stack) return

  const log: __PubSubLog = {
    type,
    eventName,
    at: new Date().toLocaleTimeString('pt-BR'),
    ['stack trace']: parseStackTrace(stack)
  }

  const previousLog = sessionStorage.getItem(__LOG_KEY)
  const parsedPreviousLog = JSON.parse(previousLog || '[]') as __PubSubLog[]

  const shouldNotLog = type === 'SUB' && parsedPreviousLog.some(l => l['stack trace'] === log['stack trace'])

  if (!shouldNotLog && log['stack trace']) {
    sessionStorage.setItem(__LOG_KEY, JSON.stringify([log, ...parsedPreviousLog]))

    /**
     * @see https://stackoverflow.com/a/71725843
     */
    window.dispatchEvent(new Event('storage'))
  }
}
