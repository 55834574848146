import { toRem } from '~/lib/helpers'

export default {
  tiny: toRem(12),
  small: toRem(14),
  normal: toRem(16),
  medium: toRem(20),
  large: toRem(24),
  xLarge: toRem(32),
  xxLarge: toRem(48),
  xxxLarge: toRem(64),
  huge: toRem(96),
  [0]: 12,
  [1]: 14,
  [2]: 16,
  [3]: 20,
  [4]: 24,
  [5]: 32,
  [6]: 48,
  [7]: 64,
  [8]: 96
}
