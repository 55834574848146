export function WarningDialogIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" {...props}>
      <path
        d="M76.2 58.766c7.754-18.705 2.88-32.422-7.754-45.89C49.614-10.817 9.071 1.404 1.76 23.601c-7.311 22.196 9.748 42.148 21.933 52.124 12.185 9.977 44.753 1.746 52.507-16.959z"
        fill="#FFF5D5"
      />
      <path
        d="M57.361 26.424a2.964 2.964 0 00-2.988-3.176l-26.665.284a2.964 2.964 0 00-2.932 2.898l-.56 25.283a2.964 2.964 0 002.964 3.03h3.41a.89.89 0 01.863 1.108l-1.298 5.114c-.265 1.042.882 1.87 1.787 1.291l11.812-7.547a.889.889 0 01.479-.14h8.356a2.964 2.964 0 002.957-2.753l1.815-25.392z"
        fill="#F9BF09"
      />
      <g clipPath="url(#general_svg__clip0)">
        <path fill="#F9BF09" d="M32.143 30.474l16.027-1.189 1.215 16.384-16.027 1.189z" />
        <path
          d="M39.21 32.6l-4.94 10.072a1.392 1.392 0 00.096 1.359c.131.198.31.358.52.465.21.105.444.154.677.139l11.313-.839c.233-.02.457-.102.649-.238.192-.135.345-.32.446-.536a1.393 1.393 0 00-.105-1.359l-6.372-9.233a1.353 1.353 0 00-.523-.445 1.317 1.317 0 00-1.31.097c-.192.13-.347.308-.451.517v0zM40.612 36.023l.203 2.731M41.017 41.484h.006"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="general_svg__clip0">
          <path fill="#fff" transform="rotate(-4.241 427.598 -418.832)" d="M0 0h16.071v16.429H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
