export function CalendarIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="200" height="200" viewBox="0 0 42 28" fill="none" {...props}>
      <g clipPath="url(#prefix__clip0_5041_31422)">
        <path
          d="M28.395 3.089A3.047 3.047 0 0025.36.259a3.044 3.044 0 00-3.04 3.04 3.04 3.04 0 106.075-.21zM25.36 5.618a2.322 2.322 0 01-2.32-2.319c0-1.278 1.041-2.32 2.32-2.32a2.323 2.323 0 012.32 2.32 2.32 2.32 0 01-2.32 2.319z"
          fill="#42EC9A"
        />
        <path
          d="M28.4 3.3a3.03 3.03 0 01-.651 1.878h-.602v-.401a2.31 2.31 0 00.532-1.478l.714-.21c.005.069.008.14.008.21z"
          fill="#1D232A"
        />
        <path
          d="M17.49 3.089a3.047 3.047 0 00-3.034-2.83 3.044 3.044 0 00-3.04 3.04 3.04 3.04 0 106.075-.21zm-3.033 2.529a2.322 2.322 0 01-2.32-2.319c0-1.278 1.041-2.32 2.32-2.32a2.323 2.323 0 012.32 2.32 2.32 2.32 0 01-2.32 2.319z"
          fill="#42EC9A"
        />
        <path
          d="M17.5 3.3a3.03 3.03 0 01-.651 1.878h-.603v-.401a2.31 2.31 0 00.533-1.478l.714-.21c.005.069.007.14.007.21z"
          fill="#1D232A"
        />
        <path d="M28.037 27.747H0L6.976 3.883h28.037l-6.976 23.864z" fill="#E8E9EA" />
        <path
          d="M8.991 27.34a.109.109 0 01-.104-.139L15.275 5.35a.108.108 0 11.208.06L9.095 27.262a.108.108 0 01-.104.078zM18.337 27.339a.109.109 0 01-.104-.139l6.425-21.979a.108.108 0 11.208.06l-6.425 21.98a.108.108 0 01-.104.078z"
          fill="#1D232A"
        />
        <path d="M35.013 3.884l-1.226 4.19H5.75l1.225-4.19h28.038z" fill="#42EC9A" />
        <path d="M35.024 3.883l-6.987 23.864H42L35.024 3.883z" fill="#00A868" />
        <path d="M35.024 3.883l-6.987 23.864h4.451l2.78-23.032-.244-.832z" fill="#1D232A" />
        <path d="M35.013 3.884l-1.226 4.19H5.75l1.225-4.19h28.038z" fill="#00A868" />
        <path
          d="M29.953 21.298H2.348a.108.108 0 110-.217h27.605a.108.108 0 110 .217zM31.873 14.74H4.268a.108.108 0 110-.217h27.605a.108.108 0 110 .216zM14.067 7.319c-.3 0-.473-.24-.386-.537l.189-.646c.086-.297.4-.537.7-.537.3 0 .474.24.387.537l-.189.646c-.087.296-.4.537-.7.537z"
          fill="#1D232A"
        />
        <path
          d="M14.067 7.427a.509.509 0 01-.42-.195.54.54 0 01-.07-.48l.19-.647c.1-.345.453-.615.804-.615.177 0 .327.07.42.195a.54.54 0 01.07.48l-.19.647c-.1.344-.454.615-.804.615zm.503-1.722c-.25 0-.524.211-.597.46l-.189.646c-.033.113-.02.216.036.29.052.07.14.108.247.108.25 0 .524-.21.597-.459l.19-.646c.032-.114.02-.216-.036-.29a.3.3 0 00-.248-.109z"
          fill="#1D232A"
        />
        <path
          d="M14.457 6.334a3.044 3.044 0 01-3.041-3.04A3.044 3.044 0 0114.457.252a.361.361 0 010 .722 2.322 2.322 0 00-2.32 2.319c0 1.279 1.041 2.32 2.32 2.32a.361.361 0 010 .72z"
          fill="#42EC9A"
        />
        <path
          d="M24.968 7.319c-.3 0-.474-.24-.387-.537l.189-.646c.087-.297.4-.537.7-.537.3 0 .474.24.387.537l-.189.646c-.086.296-.4.537-.7.537z"
          fill="#1D232A"
        />
        <path
          d="M24.968 7.427a.509.509 0 01-.421-.195.54.54 0 01-.07-.48l.19-.647c.1-.345.454-.615.804-.615.177 0 .327.069.421.195a.54.54 0 01.07.48l-.19.647c-.1.345-.454.615-.804.615zm.503-1.721c-.25 0-.524.21-.597.458l-.19.648c-.032.112-.02.216.036.29.053.07.14.108.248.108.25 0 .523-.21.596-.459l.19-.647c.033-.113.02-.216-.036-.29-.052-.07-.14-.108-.247-.108z"
          fill="#1D232A"
        />
        <path
          d="M25.361 6.334a3.044 3.044 0 01-3.04-3.04A3.044 3.044 0 0125.36.252a.36.36 0 010 .722 2.322 2.322 0 00-2.32 2.319c0 1.279 1.041 2.32 2.32 2.32a.36.36 0 010 .72z"
          fill="#42EC9A"
        />
        <path
          d="M32.483 27.737l-.016-.001a.108.108 0 01-.09-.124L35.35 7.777a.108.108 0 01.12-.092c.057.008.1.059.095.117l-1.488 16.813a.108.108 0 01-.117.098.107.107 0 01-.098-.118l1.172-13.256-2.445 16.304a.109.109 0 01-.107.094z"
          fill="#1D232A"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_5041_31422">
          <path fill="#fff" transform="translate(0 .253)" d="M0 0h42v27.494H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
