export function OnboardingSafeMoneyIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="32" height="44" viewBox="0 0 32 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M27.4834 10.809C27.1695 5.04867 22.3803 0.455441 16.54 0.452516C10.495 0.449591 5.57616 5.36749 5.57323 11.4135L5.56641 28.2039L27.4942 28.2127L27.501 11.4222C27.501 11.2175 27.4951 11.0118 27.4834 10.809ZM10.1694 23.6049L10.1743 11.4154C10.1762 7.90639 13.0291 5.05159 16.5381 5.05354C20.0471 5.05549 22.9019 7.91224 22.9 11.4203L22.8951 23.6097L10.1694 23.6049Z"
        fill="#00A868"
      />
      <path
        d="M27.501 10.806L27.4942 28.2127L5.56641 28.2039L5.57226 14.7996L10.1743 11.4154L10.1694 23.6049L22.8951 23.6097L22.899 14.8689L27.4834 10.809L27.501 10.806Z"
        fill="#1D232A"
      />
      <path
        d="M31.3113 16.2241L31.3006 43.0074C31.3006 43.3447 31.0247 43.6206 30.6873 43.6206L1.30566 43.6089C0.968308 43.6089 0.692383 43.333 0.692383 42.9957L0.703108 16.2124C0.703108 15.8721 0.979033 15.5991 1.31638 15.5991L30.698 15.6118C31.0364 15.6108 31.3123 15.8838 31.3113 16.2241Z"
        fill="#00A868"
      />
      <path
        d="M31.3122 15.6109L31.3005 43.0074C31.3005 43.3448 31.0246 43.6207 30.6873 43.6207L24.0163 43.6178L24.028 15.608L31.3122 15.6109Z"
        fill="#00A868"
      />
      <path
        d="M31.3083 24.613L31.3005 43.0074C31.3005 43.3447 31.0246 43.6206 30.6872 43.6206L24.0163 43.6177L24.0212 32.2141L31.3083 24.613Z"
        fill="#1D232A"
      />
      <path d="M24.0213 30.3879L31.3084 23.3426L31.3094 21.1722L24.0223 27.8812L24.0213 30.3879Z" fill="#1D232A" />
      <path
        d="M15.8321 35.299L8.97977 35.2961C8.76819 35.2961 8.62097 35.0845 8.69117 34.8847L9.95282 31.3766L10.5427 29.7386C9.55209 29.1097 8.89592 27.9992 8.89689 26.7385V26.7112C8.91347 24.7602 10.4969 23.1876 12.4498 23.1885C12.6058 23.1885 12.7598 23.1983 12.91 23.2197C13.066 23.2412 13.22 23.2724 13.3673 23.3085C13.7816 23.4225 14.1677 23.6029 14.508 23.8457C15.2412 24.3673 15.7677 25.1619 15.9393 26.0794C15.9793 26.2939 16.0007 26.5152 16.0007 26.7424C15.9998 27.9504 15.3982 29.0151 14.4778 29.6586L16.1226 34.9012C16.185 35.0972 16.0378 35.299 15.8321 35.299Z"
        fill="#1D232A"
      />
      <path
        d="M24.0153 43.1565C23.9343 43.1565 23.869 43.0912 23.869 43.0103L23.8788 18.763C23.8788 18.6821 23.9441 18.6168 24.025 18.6168C24.1059 18.6168 24.1713 18.6821 24.1713 18.763L24.1615 43.0112C24.1615 43.0912 24.0962 43.1565 24.0153 43.1565Z"
        fill="#1D232A"
      />
    </svg>
  )
}
