export const UNAUTHENTICATED_ERROR = 'srn:error:unauthenticated'
export const UNAUTHORIZED_ERROR = 'srn:error:unauthorized'
export const VALIDATION_ERROR = 'srn:error:validation'
export const UNPROCESSABLE_ENTITY = 'srn:error:unprocessable_entity'
export const MISSING_TOTP_ERROR = 'srn:error:missing_totp'
export const VALIDATION_ERROR_REASON_TAKEN = 'has already been taken'
export const PAYMENT_ACCOUNT_SRN = 'srn:resource:paymentaccount'
export const MAINTENANCE_ERROR = 'srn:error:in_maintenance'
export const INVALID_PARAMS_ERROR = 'srn:error:invalid_params'

export const SIGN_IN_CHALLENGE_REQUIRED = 'srn:error:sign_in_challenge_required'
export const SIGN_IN_CHALLENGE_DENIED = 'srn:error:challenge_denied'
export const SIGN_IN_CHALLENGE_EXPIRED = 'srn:error:challenge_expired'
export const SIGN_IN_CHALLENGE_PENDING_APPROVAL = 'srn:error:pending_device_approval'

export const CHALLENGE_REQUIRED_ERROR = 'srn:error:challenge_required'
export const BAD_CHALLENGE_SOLUTION_ERROR = 'srn:error:bad_challenge_solution'
export const CHALLENGE_LOCKED = 'srn:error:challenge_locked'

export const NEED_VERIFICATION_ERROR = 'srn:error:needs_verification'
export const INVALID_VERIFICATION_CODE = 'srn:error:invalid_verification_code'

export const KYC_PENDING_REQUEST = 'srn:error:kyc_pending_request'

export const USER_IS_BLOCKED = 'srn:error:user_is_blocked'

export const REASON_CONSENT_ALREADY_RESPONDED = 'consent_already_responded'

export enum ERROR_MAPPER_ENUM {
  NO_ERROR = 'NO_ERROR',
  GENERAL = 'GENERAL',
  CREDIT_LOCK = 'CREDIT_LOCK',
  INVALID_CNPJ = 'INVALID_CNPJ',
  INVALID_BANK_ACCOUNT = 'INVALID_BANK_ACCOUNT',
  INVALID_BANK_ACCOUNT_SAVING = 'INVALID_BANK_ACCOUNT_SAVING',
  CREDIT_LINE_IS_NOT_ACTIVE = 'CREDIT_LINE_IS_NOT_ACTIVE',
  CREDIT_LINE_ALREADY_HAS_LOAN = 'CREDIT_LINE_ALREADY_HAS_LOAN',
  BORROWER_ALREADY_HAS_LOAN = 'BORROWER_ALREADY_HAS_LOAN'
}

export const API_ERROR_RESPONSE_MAPPER_ENUM: {
  [type: string]: string
} = {
  [ERROR_MAPPER_ENUM.INVALID_CNPJ]:
    'Seu CNPJ não está apto na Receita Federal. Você precisa regularizar a situação antes de contratar o empréstimo.',
  [ERROR_MAPPER_ENUM.INVALID_BANK_ACCOUNT]:
    'Identificamos que a conta bancária cadastrada é inválida. Nesse caso, não é possível concluir a contratação. Para prosseguir, é necessário atualizar a conta bancária.',
  [ERROR_MAPPER_ENUM.CREDIT_LINE_IS_NOT_ACTIVE]: 'Credit line is not active',
  [ERROR_MAPPER_ENUM.CREDIT_LINE_ALREADY_HAS_LOAN]: 'Credit line already has a loan',
  [ERROR_MAPPER_ENUM.BORROWER_ALREADY_HAS_LOAN]: 'Borrower already has an ongoing loan',
  [ERROR_MAPPER_ENUM.CREDIT_LOCK]:
    'Você tem outra operação de crédito e em garantia os seus recebimentos de cartão. Essa operação precisa ser encerrada antes de contratar um novo empréstimo.',
  [ERROR_MAPPER_ENUM.INVALID_BANK_ACCOUNT_SAVING]:
    'O domicílio bancário configurado é Conta Poupança e por isso não é possível seguir com a contratação'
}
