import { type SVGProps } from 'react'

export function RavSpotRedirectIllustration(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width={98} height={98} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <ellipse cx={45.106} cy={46.925} rx={44.567} ry={46.675} fill="#ECFDF2" />
      <path fill="#fff" d="M18.367 46.924h45.557v43.564H18.367z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.567 49.082c-1.783 0-3.228 1.514-3.228 3.38v32.582c0 1.867 1.445 3.38 3.228 3.38H59.71c1.783 0 3.227-1.513 3.227-3.38V52.462c0-1.867-1.444-3.38-3.227-3.38H23.567zm-7.1 3.38c0-4.106 3.178-7.436 7.1-7.436H59.71c3.922 0 7.1 3.33 7.1 7.436v32.582c0 4.107-3.179 7.436-7.1 7.436H23.567c-3.922 0-7.1-3.33-7.1-7.436V52.462z"
        fill="#2D3844"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.708 39.754c1.043 0 1.888.885 1.888 1.977v10.545c0 1.092-.845 1.977-1.888 1.977s-1.888-.885-1.888-1.977V41.731c0-1.092.845-1.977 1.888-1.977zM31.57 39.754c1.043 0 1.888.885 1.888 1.977v10.545c0 1.092-.845 1.977-1.888 1.977s-1.888-.885-1.888-1.977V41.731c0-1.092.845-1.977 1.888-1.977zM17.096 63.48c0-1.091.845-1.977 1.888-1.977h45.31c1.043 0 1.888.886 1.888 1.977 0 1.093-.845 1.978-1.888 1.978h-45.31c-1.043 0-1.888-.885-1.888-1.977z"
        fill="#2D3844"
      />
      <ellipse cx={73.827} cy={36.553} rx={18.817} ry={19.707} fill="#fff" />
      <path d="M74.36 24.107l-.003 13.05-6.943-.001" stroke="#2D3844" strokeWidth={3.447} strokeLinecap="round" />
      <g clipPath="url(#prefix__clip0)" fillRule="evenodd" clipRule="evenodd" fill="#000">
        <path d="M95.615 17.883c-1.094 0-1.98.929-1.98 2.075V30.33H83.73c-1.094 0-1.98.929-1.98 2.075 0 1.145.886 2.074 1.98 2.074h11.885c1.094 0 1.98-.929 1.98-2.075V19.959c0-1.146-.886-2.075-1.98-2.075z" />
        <path d="M76.589 16.03c-4.252-.626-8.584.212-12.343 2.388-3.758 2.175-6.74 5.572-8.494 9.677a21.65 21.65 0 00-1.236 13.08c.952 4.386 3.24 8.329 6.519 11.234 3.278 2.905 7.369 4.616 11.656 4.874 4.287.258 8.539-.95 12.114-3.441 3.575-2.493 6.28-6.134 7.706-10.376.364-1.08-.178-2.265-1.21-2.646-1.031-.38-2.163.187-2.526 1.267-1.142 3.394-3.305 6.307-6.165 8.3-2.86 1.995-6.261 2.96-9.691 2.754-3.43-.207-6.703-1.575-9.325-3.9-2.623-2.324-4.453-5.477-5.216-8.986a17.32 17.32 0 01.99-10.465c1.403-3.284 3.788-6 6.795-7.741a15.238 15.238 0 019.874-1.91c3.402.501 6.556 2.147 8.988 4.689l.043.044 9.19 9.044c.798.785 2.051.744 2.8-.091.75-.835.71-2.148-.087-2.932l-9.17-9.024c-3.035-3.165-6.97-5.213-11.212-5.838z" />
      </g>
      <path
        fill="#14AA4B"
        d="M25.299 70.781h3.962v4.149h-3.962zM25.299 78.041h3.962v4.149h-3.962zM32.23 70.781h3.962v4.149H32.23zM32.23 78.041h3.962v4.149H32.23zM39.164 70.781h3.962v4.149h-3.962zM39.164 78.041h3.962v4.149h-3.962zM46.096 70.781h3.962v4.149h-3.962zM46.096 78.041h3.962v4.149h-3.962zM53.029 70.781h3.962v4.149h-3.962zM53.029 78.041h3.962v4.149h-3.962z"
      />
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" transform="matrix(-1 0 0 1 97.596 11.658)" d="M0 0h47.538v49.787H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
