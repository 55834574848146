export function CoinPaymentWaiting(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="136" height="136" viewBox="0 0 136 136" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_4907_50933)">
        <path
          d="M112.025 61.2416C112.022 64.1216 111.755 66.9391 111.26 69.6641C111.077 70.7116 110.842 71.7491 110.587 72.7766C110.26 74.1016 109.862 75.4066 109.41 76.6916C109.085 77.6541 108.715 78.6016 108.305 79.5391C108.08 80.1166 107.817 80.6891 107.547 81.2566C107.117 82.1591 106.677 83.0466 106.182 83.9266C105.887 84.4641 105.595 84.9891 105.27 85.5091C97.0298 99.2191 82.0198 108.392 64.8948 108.387L47.8273 108.384L47.6573 54.5016L47.5298 16.0641L48.6398 16.0966C48.3698 16.7191 48.6898 17.6216 49.5748 17.6216C50.0548 17.6216 50.5323 17.6116 51.0098 17.6116C50.5173 18.2216 50.7823 19.3916 51.7948 19.3541L84.0573 18.1041C84.7398 18.4116 85.4148 18.7291 86.0873 19.0716C87.2498 19.6491 88.3848 20.2741 89.4698 20.9516C78.5473 21.4791 67.6273 22.0116 56.6998 22.5466C55.3498 22.6116 55.3723 24.7166 56.7348 24.6541C68.6198 24.0716 80.5198 23.4916 92.4123 22.9066C92.5298 22.9916 92.6548 23.0841 92.7723 23.1716C102.062 29.9716 108.737 40.1066 111.102 51.8141C111.705 54.8516 112.027 58.0066 112.025 61.2416Z"
          fill="#1D232A"
        />
        <path
          d="M91.9499 77.7991C82.7524 102.162 55.5749 114.492 31.2449 105.344C24.8799 102.952 19.3474 99.3266 14.7999 94.8441C10.7224 90.8191 7.44486 86.1091 5.08736 80.9741L16.3199 72.4666C15.7349 70.8191 15.2799 69.1241 14.9574 67.3966L3.23236 76.2791C2.90486 75.2941 2.60736 74.2966 2.34486 73.2916C2.39986 73.2666 2.44736 73.2366 2.49736 73.1991L14.2199 64.3141L14.3849 61.6516C14.3849 61.6516 13.2849 62.4991 13.0074 62.7116L1.83236 71.1741C1.32236 68.8141 0.997356 66.4091 0.859856 63.9766C0.502356 57.6741 1.41486 51.1841 3.75736 44.8841C3.78736 44.8116 3.80986 44.7391 3.83986 44.6666C10.5024 27.0191 26.5974 15.6866 44.2124 14.2516C45.3124 14.1616 46.4149 14.1116 47.5224 14.1041L64.7949 14.0816L65.0249 14.0791C69.0049 14.0841 72.8799 14.5791 76.5649 15.5141C78.2399 15.9391 79.8924 16.4541 81.4824 17.0491C82.3499 17.3766 83.2124 17.7316 84.0574 18.1041L68.2949 18.7141C70.1674 19.6066 71.9549 20.6116 73.6549 21.7191C78.9274 21.4616 84.1974 21.2066 89.4699 20.9516C89.4849 20.9541 89.4974 20.9666 89.5049 20.9716C90.5099 21.5791 91.4799 22.2216 92.4124 22.9066C87.0949 23.1691 81.7774 23.4291 76.4599 23.6891C81.7649 27.7216 86.0749 32.7991 89.1974 38.5041C95.5724 50.1391 97.0099 64.3941 91.9499 77.7991Z"
          fill="#42EC9A"
        />
        <path
          d="M25.0366 84.4437L25.0358 84.4429C21.6805 81.1368 19.1125 77.1558 17.4702 72.8026L17.4701 72.8023C17.0204 71.6121 16.636 70.3958 16.3281 69.1572L64.0987 32.9775C65.2161 33.6102 66.2853 34.307 67.3024 35.0584C71.1153 37.8818 74.2003 41.4762 76.4215 45.5294C76.6823 46.0076 76.9335 46.4935 77.1725 46.9845L26.2303 85.5589C25.8224 85.201 25.4236 84.8267 25.0366 84.4437Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <path
          d="M3.23212 76.2791C3.42462 76.8516 3.62712 77.4216 3.83962 77.9841C4.15937 78.834 4.50743 79.6768 4.87638 80.5065L5.28581 80.1963M3.23212 76.2791L3.53404 76.6777L3.82167 76.4598M3.23212 76.2791L14.5123 67.7336C14.5476 67.9153 14.5842 68.0966 14.6223 68.2775M3.23212 76.2791L3.70604 76.1198C3.7442 76.2332 3.78274 76.3466 3.82167 76.4598M3.82167 76.4598C3.97734 76.9124 4.13924 77.3624 4.30735 77.8074L4.30759 77.808C4.60991 78.6116 4.9381 79.4094 5.28581 80.1963M3.82167 76.4598L14.6223 68.2775M5.28581 80.1963C5.35499 80.3529 5.42493 80.509 5.4956 80.6647L16.3171 72.4666M5.28581 80.1963L15.7252 72.2878M15.7252 72.2878C15.7648 72.4034 15.8051 72.5188 15.8459 72.6339L16.3171 72.4666M15.7252 72.2878L16.0152 72.0681L16.3171 72.4666M15.7252 72.2878C15.276 70.9769 14.9088 69.6377 14.6223 68.2775M16.3171 72.4666C15.7939 70.9932 15.3767 69.4818 15.0637 67.9431L14.6223 68.2775"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <path
          d="M45.8651 83.0166C44.1276 83.0166 42.3326 82.7041 40.5626 82.0691C33.9876 79.7091 30.5276 72.7816 30.9776 67.4491C30.9951 67.2416 31.1801 67.0916 31.3826 67.1066C31.5901 67.1241 31.7426 67.3066 31.7251 67.5116C31.2976 72.5616 34.5826 79.1241 40.8176 81.3616C45.3051 82.9741 49.9476 82.4091 53.2326 79.8491C55.7301 77.9041 56.9951 75.0616 56.7026 72.0516C56.1551 66.4191 52.7101 63.9691 48.3476 60.8691L47.9551 60.5891C43.5976 57.4866 39.9801 54.5316 39.8176 49.7766C39.7101 46.6666 41.1176 43.8091 43.6801 41.9416C46.7276 39.7191 50.9626 39.2616 55.0101 40.7141C59.8326 42.4466 62.4576 46.7316 63.3051 50.1641C63.3551 50.3641 63.2326 50.5691 63.0301 50.6191C62.8301 50.6716 62.6251 50.5466 62.5751 50.3441C61.7726 47.0991 59.2976 43.0516 54.7551 41.4216C50.8851 40.0316 47.0076 40.4416 44.1201 42.5491C41.7626 44.2691 40.4676 46.8941 40.5651 49.7516C40.7176 54.1716 44.0501 56.8866 48.3901 59.9791L48.7826 60.2591C53.1201 63.3441 56.8676 66.0091 57.4476 71.9816C57.7651 75.2541 56.3976 78.3391 53.6926 80.4441C51.5076 82.1416 48.7676 83.0166 45.8651 83.0166Z"
          fill="#1D232A"
        />
        <path
          d="M38.7301 86.7341C38.6876 86.7341 38.6451 86.7266 38.6051 86.7116C38.4101 86.6416 38.3076 86.4266 38.3776 86.2316L56.2651 36.2166C56.3351 36.0216 56.5501 35.9191 56.7451 35.9891C56.9401 36.0591 57.0426 36.2741 56.9726 36.4691L39.0851 86.4841C39.0276 86.6391 38.8851 86.7341 38.7301 86.7341Z"
          fill="#1D232A"
        />
        <path
          d="M13.7352 64.0543L2.64264 72.4618C2.55232 72.104 2.46765 71.7445 2.3883 71.383L13.3092 63.1129C13.407 63.0389 13.6035 62.8881 13.8178 62.7232L13.7352 64.0543Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <path
          d="M24.7626 85.8692C24.6701 85.8692 24.5776 85.8342 24.5051 85.7667C20.6926 82.1392 17.7401 77.5842 15.9676 72.5917C13.7001 66.2167 13.3951 59.3867 15.0851 52.8416C15.3776 51.6966 15.7551 50.5091 16.2026 49.3116C17.5976 45.6266 19.5776 42.2641 22.0901 39.3166C22.2251 39.1591 22.4601 39.1391 22.6176 39.2741C22.7751 39.4091 22.7951 39.6441 22.6601 39.8016C20.2026 42.6841 18.2676 45.9716 16.9026 49.5766C16.4651 50.7466 16.0976 51.9091 15.8101 53.0291C14.1601 59.4317 14.4576 66.1092 16.6726 72.3417C18.4076 77.2242 21.2926 81.6792 25.0226 85.2242C25.1726 85.3667 25.1776 85.6042 25.0351 85.7542C24.9601 85.8317 24.8626 85.8692 24.7626 85.8692Z"
          fill="#1D232A"
        />
        <path
          d="M117.215 36.1866L112.435 22.9566L99.2026 18.1866L112.433 13.4066L117.203 0.174072L121.983 13.4041L135.215 18.1741L121.985 22.9541L117.215 36.1866Z"
          fill="#42EC9A"
        />
        <path
          d="M106.602 55.2191L103.525 46.6991L95.0048 43.6266L103.525 40.5491L106.597 32.0266L109.675 40.5466L118.195 43.6191L109.675 46.6966L106.602 55.2191Z"
          fill="#42EC9A"
        />
      </g>
      <g filter="url(#filter0_d_4907_50933)">
        <path
          d="M99.035 130.174C114.499 130.174 127.035 117.638 127.035 102.174C127.035 86.7101 114.499 74.1741 99.035 74.1741C83.571 74.1741 71.035 86.7101 71.035 102.174C71.035 117.638 83.571 130.174 99.035 130.174Z"
          fill="#E8E9EA"
        />
      </g>
      <path
        d="M116.974 90.5806L115.27 98.4969C115.032 99.5996 114.057 100.354 112.973 100.354C112.81 100.354 112.648 100.338 112.484 100.303L104.56 98.6216C103.291 98.3512 102.479 97.1051 102.747 95.8331C103.018 94.5635 104.266 93.7523 105.536 94.0227L108.541 94.6598C105.531 90.8627 100.389 89.0876 95.4847 90.5923C92.5105 91.5046 90.0277 93.4984 88.4971 96.2022C87.8552 97.3331 86.421 97.7305 85.2925 97.0909C84.1615 96.4491 83.7642 95.0149 84.4037 93.8863C86.5315 90.1292 89.976 87.3642 94.1046 86.0969C100.78 84.0514 107.772 86.379 111.981 91.4388L112.378 89.5907C112.651 88.3211 113.901 87.5123 115.171 87.7874C116.441 88.0601 117.249 89.3109 116.974 90.5806Z"
        fill="#00A868"
      />
      <path
        d="M113.669 110.464C111.543 114.222 108.096 116.987 103.97 118.254C102.331 118.757 100.676 118.994 99.0468 118.994C94.0317 118.994 89.2706 116.733 86.0942 112.914L85.6969 114.76C85.4594 115.863 84.4837 116.617 83.3998 116.617C83.2352 116.617 83.0683 116.601 82.9013 116.563C81.6341 116.291 80.8253 115.04 81.098 113.77L82.8049 105.854C83.0777 104.586 84.3238 103.778 85.5911 104.048L93.5145 105.729C94.7841 106 95.5953 107.246 95.3249 108.518C95.0568 109.787 93.8084 110.598 92.5388 110.328L89.5316 109.691C92.5435 113.488 97.6854 115.263 102.59 113.758C105.564 112.846 108.045 110.852 109.578 108.149C110.217 107.018 111.651 106.62 112.782 107.262C113.913 107.902 114.311 109.336 113.669 110.464Z"
        fill="#00A868"
      />
      <g filter="url(#filter1_d_4907_50933)">
        <path
          d="M98.785 130.29C114.249 130.29 126.785 117.754 126.785 102.29C126.785 86.8261 114.249 74.2901 98.785 74.2901C83.321 74.2901 70.785 86.8261 70.785 102.29C70.785 117.754 83.321 130.29 98.785 130.29Z"
          fill="#E8E9EA"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98.285 90.1235C91.8417 90.1235 86.6183 95.3468 86.6183 101.79C86.6183 108.233 91.8417 113.457 98.285 113.457C104.728 113.457 109.952 108.233 109.952 101.79C109.952 95.3468 104.728 90.1235 98.285 90.1235ZM83.7016 101.79C83.7016 93.736 90.2308 87.2068 98.285 87.2068C106.339 87.2068 112.868 93.736 112.868 101.79C112.868 109.844 106.339 116.373 98.285 116.373C90.2308 116.373 83.7016 109.844 83.7016 101.79Z"
        fill="#00A868"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98.285 94.4985C99.0904 94.4985 99.7433 95.1514 99.7433 95.9568V101.132L103.441 103.475C104.121 103.906 104.323 104.807 103.892 105.487C103.461 106.168 102.56 106.37 101.879 105.939L97.5043 103.166C97.0824 102.898 96.8266 102.434 96.8266 101.934V95.9568C96.8266 95.1514 97.4796 94.4985 98.285 94.4985Z"
        fill="#00A868"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98.285 90.1235C91.8417 90.1235 86.6183 95.3468 86.6183 101.79C86.6183 108.233 91.8417 113.457 98.285 113.457C104.728 113.457 109.952 108.233 109.952 101.79C109.952 95.3468 104.728 90.1235 98.285 90.1235ZM83.7016 101.79C83.7016 93.736 90.2308 87.2068 98.285 87.2068C106.339 87.2068 112.868 93.736 112.868 101.79C112.868 109.844 106.339 116.373 98.285 116.373C90.2308 116.373 83.7016 109.844 83.7016 101.79Z"
        stroke="#00A868"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98.285 94.4985C99.0904 94.4985 99.7433 95.1514 99.7433 95.9568V101.132L103.441 103.475C104.121 103.906 104.323 104.807 103.892 105.487C103.461 106.168 102.56 106.37 101.879 105.939L97.5043 103.166C97.0824 102.898 96.8266 102.434 96.8266 101.934V95.9568C96.8266 95.1514 97.4796 94.4985 98.285 94.4985Z"
        stroke="#00A868"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_d_4907_50933"
          x="68.035"
          y="73.1741"
          width="62"
          height="62"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.290196 0 0 0 0 0.309804 0 0 0 0 0.333333 0 0 0 0.32 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4907_50933" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4907_50933" result="shape" />
        </filter>
        <filter
          id="filter1_d_4907_50933"
          x="67.785"
          y="73.2901"
          width="62"
          height="62"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.290196 0 0 0 0 0.309804 0 0 0 0 0.333333 0 0 0 0.32 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4907_50933" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4907_50933" result="shape" />
        </filter>
        <clipPath id="clip0_4907_50933">
          <rect width="134.43" height="108.213" fill="white" transform="translate(0.784973 0.174072)" />
        </clipPath>
      </defs>
    </svg>
  )
}
