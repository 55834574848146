import { createCookiesStorage } from './cookies'
import { createStorage } from './storage'

function createBucket() {
  const local = createStorage('localStorage')
  const session = createStorage('sessionStorage')
  const cookies = createCookiesStorage()

  return { local, session, cookies }
}

export const Bucket = createBucket()
