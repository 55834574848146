export function DocumentCheckedIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={110} height={111} fill="none" {...props}>
      <path
        d="M93.406 31.976l-6.762 56.896-37.027-4.401-5.57-.662-1.257-.15-.087-.01.21-1.755.18-1.521 3.345-28.149 2.853-24.007.173-1.462 11.38 1.352 32.562 3.869z"
        fill="#00A868"
      />
      <path
        d="M74.78 74.056a.207.207 0 01-.198-.15L61.715 28.472a.205.205 0 11.396-.112L74.98 73.794a.205.205 0 01-.2.262z"
        fill="#1D232A"
      />
      <path
        d="M75.085 78.808l-27.888 5.169-3.15-.379.134-1.586 2.257-30.09 1.822-24.286 1.113-.511 12.542 1.29 13.17 50.393z"
        fill="#1D232A"
        stroke="#1D232A"
        strokeWidth={3}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M59.693 21.682L16.5 31.307l12.462 55.927 43.193-9.625-12.462-55.927z" fill="#E8E9EA" />
      <path
        d="M25.213 40.442a.206.206 0 01-.044-.407l30.378-6.77a.206.206 0 11.09.403l-30.378 6.77a.25.25 0 01-.046.004zM27.188 49.3a.206.206 0 01-.044-.406l30.378-6.77a.206.206 0 11.09.403l-30.379 6.768c-.015.003-.03.006-.045.006zM29.162 58.159a.206.206 0 01-.044-.407l30.378-6.771a.206.206 0 11.09.403l-30.378 6.77c-.015.002-.03.005-.046.005zM31.137 67.016a.206.206 0 01-.044-.407l30.378-6.77a.206.206 0 11.089.402l-30.378 6.77a.283.283 0 01-.045.005zM33.11 75.874a.206.206 0 01-.044-.407l21.436-4.778a.206.206 0 11.09.403L33.154 75.87a.248.248 0 01-.045.004z"
        fill="#1D232A"
      />
      <g filter="url(#prefix__filter0_d_10150_17880)">
        <path
          d="M81.95 102.532c8.505 0 15.4-6.895 15.4-15.4s-6.895-15.4-15.4-15.4-15.4 6.895-15.4 15.4 6.895 15.4 15.4 15.4z"
          fill="#E8E9EA"
        />
      </g>
      <path
        d="M79.394 94.903c-.392 0-.787-.143-1.097-.43l-5.131-4.742a1.617 1.617 0 012.193-2.374l3.944 3.645 9.146-9.897a1.617 1.617 0 012.375 2.193L80.58 94.383c-.318.345-.752.52-1.187.52z"
        fill="#00A868"
      />
      <defs>
        <filter
          id="prefix__filter0_d_10150_17880"
          x={63.55}
          y={70.732}
          width={36.8}
          height={36.8}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={1.5} />
          <feColorMatrix values="0 0 0 0 0.290196 0 0 0 0 0.309804 0 0 0 0 0.333333 0 0 0 0.32 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_10150_17880" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_10150_17880" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
