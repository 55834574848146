export function ErrorInComunicationIllustration({
  width = 186,
  height = 200,
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox="0 0 110 92" fill="none" {...props}>
      <path
        d="M35.217 37.333a3.871 3.871 0 003.58 4.138l20.622 1.496 48.703 3.53c1.036.075 1.617-1.16.903-1.912l-7.779-8.149 2.037-28.09a3.868 3.868 0 00-3.58-4.139L41.803.01a3.867 3.867 0 00-4.137 3.58l-2.304 31.754-.145 1.989z"
        fill="#4A4F55"
      />
      <path
        d="M90.454 34.054h-.023l-43.017-3.537a.293.293 0 01-.268-.317.293.293 0 01.317-.268l43.015 3.537a.293.293 0 01-.024.585zM86.768 24.147h-.025l-13.567-1.123a.293.293 0 01-.269-.318.295.295 0 01.317-.268l13.567 1.124a.293.293 0 01-.023.585z"
        fill="#E8E9EA"
      />
      <path d="M77.606 44.288l-5.721-15.473-.756 14.988 6.477.485z" fill="#1D232A" />
      <path
        d="M74.51 63.468a3.871 3.871 0 01-3.58 4.138L50.308 69.1 1.605 72.632c-1.035.075-1.617-1.16-.902-1.912l7.779-8.149-2.038-28.09a3.868 3.868 0 013.58-4.137l57.9-4.199a3.867 3.867 0 014.137 3.58l2.304 31.754.145 1.989z"
        fill="#BBBDBF"
      />
      <path
        d="M19.21 60.07a.292.292 0 01-.021-.585l26.498-2.033a.289.289 0 01.315.27.292.292 0 01-.27.315l-26.5 2.034h-.022zM18.671 50.417a.293.293 0 01-.021-.586l38.256-2.938a.29.29 0 01.315.27.292.292 0 01-.27.316l-38.256 2.936c-.008.002-.016.002-.024.002zM17.773 41.542a.292.292 0 01-.022-.586l27.082-2.078a.29.29 0 01.315.27.292.292 0 01-.27.315L17.794 41.54c-.007.002-.013.002-.021.002zM48.48 39.182a.292.292 0 01-.022-.585l14.315-1.1a.29.29 0 01.315.27.292.292 0 01-.27.315l-14.315 1.1h-.023z"
        fill="#1D232A"
      />
      <path
        d="M91.992 15.533h-.024l-45-3.7a.293.293 0 01-.268-.317.291.291 0 01.317-.268l45 3.7a.293.293 0 01-.025.585zM70.243 22.779h-.024l-24.098-1.995a.293.293 0 01-.268-.317.295.295 0 01.317-.268l24.098 1.995a.293.293 0 01-.025.585z"
        fill="#E8E9EA"
      />
      <g filter="url(#prefix__filter0_d_4354_24079)">
        <path
          d="M87.689 92c12.108 0 21.923-9.815 21.923-21.923 0-12.108-9.815-21.924-21.923-21.924-12.108 0-21.923 9.816-21.923 21.924C65.766 82.185 75.58 92 87.689 92z"
          fill="#4A4F55"
        />
      </g>
      <path
        d="M98.21 77.342a2.302 2.302 0 01-3.255 3.254l-7.264-7.264-7.266 7.264c-.45.45-1.039.674-1.628.674s-1.178-.224-1.627-.674a2.302 2.302 0 010-3.254l7.266-7.265-7.266-7.266a2.302 2.302 0 013.255-3.254l7.266 7.266 7.264-7.266a2.302 2.302 0 013.255 3.254l-7.265 7.266 7.265 7.265z"
        fill="#FCFCFC"
      />
    </svg>
  )
}
