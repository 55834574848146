import { type PropsWithChildren, type ReactNode } from 'react'
import FocusLock from 'react-focus-lock'
import { useKey, useLockBodyScroll } from 'react-use'
import { IconButton } from '@dlpco/ginga-stone'

import { Flex } from '~/domains/platform/design-system'
import { Overlay } from '~/domains/platform/design-system/overlay/overlay'
import { Choose, If } from '~/domains/platform/lib/utilities-components'
import { useClickAway } from '~/ui/hooks/utils/ui/use-click-away'

import { ClientPortal } from '../../utils/client-portal'

import * as S from './styles'

interface AlertProps {
  toolbar?: ReactNode
  isOpen: boolean
  style?: React.CSSProperties
  onClose?(): void
  title?: string
  size?: string
  disablePortal?: boolean
}

/**
 * @deprecated This component should not be used. Try `<Modal />` instead
 */

export function Alert({ isOpen, disablePortal = false, style, ...restProps }: PropsWithChildren<AlertProps>) {
  useLockBodyScroll(isOpen)

  return (
    <If
      condition={isOpen}
      render={() => {
        const visibleClassName = isOpen ? 'visible' : ''

        return (
          <FocusLock disabled={!isOpen}>
            <Choose>
              <Choose.When condition={disablePortal}>
                <Content {...restProps} className={visibleClassName} />
              </Choose.When>
              <Choose.Otherwise>
                <ClientPortal selector="#modal">
                  <Content {...restProps} className={visibleClassName} style={style} />
                </ClientPortal>
              </Choose.Otherwise>
            </Choose>
          </FocusLock>
        )
      }}
    />
  )
}

interface ContentProps {
  toolbar?: ReactNode
  onClose?(): void
  title?: string
  size?: string
  className: string
  style?: React.CSSProperties
}

function Content({ toolbar, children, onClose, title, size, className, style }: PropsWithChildren<ContentProps>) {
  useKey('Escape', onClose)

  const noop = () => {}

  const clickAwayRef = useClickAway(onClose ? onClose : noop)

  const templateRows = (() => {
    if (toolbar && (title || onClose)) {
      return '70px 1fr 100px'
    } else if (toolbar) {
      return '1fr 100px'
    } else if (title) {
      return '70px 1fr'
    } else {
      return '1fr'
    }
  })()

  return (
    <>
      <Overlay data-testid="overlay" zIndex={751} />
      <S.Wrapper ref={clickAwayRef} className={className} data-testid="modal" rows={templateRows} size={size}>
        <If condition={Boolean(title || onClose)}>
          <S.Header>
            <Flex justifyContent="space-between">
              <S.ModalTitle data-testid="title">{title}</S.ModalTitle>
              <IconButton
                icon="close-outline"
                color="neutral"
                variant="contentOnly"
                aria-label="Fechar"
                onClick={onClose}
              />
            </Flex>
          </S.Header>
        </If>
        <S.Content style={style}>{children}</S.Content>
        <If condition={Boolean(toolbar)}>
          <S.Footer>{toolbar}</S.Footer>
        </If>
      </S.Wrapper>
    </>
  )
}
