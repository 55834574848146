export function HomePixScanQrCodeIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={77} height={100} fill="none" {...props}>
      <path
        d="M64.204 24.794L62.79 36.14l-.292 2.318-.154 1.234-5.446 43.629a7.555 7.555 0 01-8.44 6.553l-30.399-3.792c-4.142-.515-7.08-4.285-6.562-8.424l7.307-58.52c.514-4.134 4.289-7.068 8.435-6.553l15.904 1.986 14.496 1.806a7.546 7.546 0 016.566 8.416z"
        fill="#1D232A"
      />
      <path
        d="M64.205 24.794l-1.416 11.348-16.195-11.56-7.055-5.043c.707-.613 2.044-2.543 3.604-4.974L57.64 16.37c4.14.52 7.08 4.294 6.566 8.424zM62.502 38.46l-.154 1.235c-4.665-3.293-9.33-6.59-13.995-9.882-.245-.174-.215-.586-.033-.781.225-.235.538-.207.782-.033 1.547 1.1 3.106 2.196 4.653 3.29 2.914 2.054 5.829 4.108 8.747 6.171z"
        fill="#42EC9A"
      />
      <path
        d="M38.97 88.686L8.202 84.848a7.553 7.553 0 01-6.566-8.424L8.94 17.9c.514-4.136 4.293-7.068 8.435-6.554l30.767 3.839a7.553 7.553 0 016.566 8.424l-7.302 58.524c-.515 4.14-4.294 7.072-8.435 6.553z"
        fill="#00A868"
      />
      <path
        d="M7.926 73.353l6.362-50.992 34.17 4.261-6.362 50.992-34.17-4.26z"
        fill="#FCFCFC"
        stroke="#FCFCFC"
        strokeWidth={1.16}
      />
      <path d="M18.423 22.292l-4.638-.579L7.28 73.856l4.638.579 6.506-52.143z" fill="#42EC9A" />
      <path
        d="M31.945 20.058a1.58 1.58 0 001.735-1.408 1.58 1.58 0 00-1.411-1.732 1.58 1.58 0 10-.324 3.14z"
        fill="#1D232A"
      />
      <path
        d="M38.239 62.085h0L20.17 59.894s0 0 0 0a2.031 2.031 0 01-1.774-2.261h0l2.19-18.04s0 0 0 0a2.032 2.032 0 012.264-1.769h0l18.069 2.187s0 0 0 0a2.031 2.031 0 011.773 2.261h0l-2.185 18.039s0 0 0 0a2.039 2.039 0 01-2.27 1.774z"
        fill="#FCFCFC"
        stroke="#FCFCFC"
        strokeWidth={1.16}
      />
      <path
        d="M37.528 60.684c-.009 0-.022-.002-.035-.004l-16.518-2.062a1.522 1.522 0 01-1.322-1.702l2.06-16.496c.104-.833.86-1.41 1.7-1.32l16.518 2.063c.407.05.767.253 1.018.577.247.318.36.719.309 1.12l-2.06 16.497a1.524 1.524 0 01-1.67 1.327zM23.253 40.395a.22.22 0 00-.238.19L20.954 57.08a.215.215 0 00.19.243l16.517 2.062a.224.224 0 00.243-.189L39.965 42.7a.219.219 0 00-.045-.162.2.2 0 00-.144-.08l-16.519-2.062h-.004z"
        fill="#1D232A"
      />
      <path
        d="M36.927 48.128l-3.181-.398a.437.437 0 01-.38-.485l.398-3.177a.437.437 0 01.486-.378l3.182.398a.437.437 0 01.379.485l-.398 3.176a.437.437 0 01-.486.379zM33.937 54.271l-1.06-.13a.149.149 0 01-.127-.163l.131-1.057a.149.149 0 01.162-.128l1.06.131a.149.149 0 01.127.162l-.131 1.058a.149.149 0 01-.162.127zM33.6 56.96l-1.06-.13a.149.149 0 01-.128-.162l.131-1.058a.149.149 0 01.162-.127l1.06.13a.149.149 0 01.127.163l-.13 1.057a.14.14 0 01-.163.128zM36.635 54.607l-1.06-.13a.149.149 0 01-.127-.163l.131-1.057a.149.149 0 01.162-.128l1.06.131a.149.149 0 01.127.162l-.131 1.058a.149.149 0 01-.162.127zM35.117 55.783l-1.059-.131a.149.149 0 01-.128-.162l.131-1.057a.149.149 0 01.162-.128l1.06.131a.149.149 0 01.127.162l-.13 1.058a.143.143 0 01-.163.127zM26.5 56.075l-3.181-.398a.436.436 0 01-.38-.485l.398-3.177a.437.437 0 01.486-.378l3.182.398a.437.437 0 01.379.485l-.398 3.177a.434.434 0 01-.486.378zM27.642 46.965l-3.182-.399a.437.437 0 01-.379-.485l.398-3.176a.437.437 0 01.486-.379l3.181.399a.437.437 0 01.38.485l-.398 3.176a.434.434 0 01-.486.379zM39.516 51.683l-.013-.002-18.247-2.28a.652.652 0 01-.57-.727c.047-.358.376-.626.73-.568l18.247 2.28a.652.652 0 01-.147 1.297z"
        fill="#1D232A"
      />
      <path
        d="M29.253 59.651l-.012-.001a.652.652 0 01-.57-.728l2.279-18.218a.644.644 0 01.728-.568c.359.046.614.37.569.728l-2.278 18.218a.655.655 0 01-.716.57z"
        fill="#1D232A"
      />
      <path
        d="M62.261 31.284l-4.198-8.261-9.076-2.076 8.311-4.158 2.077-9.012 4.198 8.261 9.076 2.076-8.31 4.158-2.078 9.012zM45.233 18.094l-1.203-4.126-3.963-1.72 4.152-1.189 1.726-3.934 1.203 4.126 3.963 1.72-4.152 1.188-1.726 3.935zM49.318 97.36l-1.45-5.77-5.394-2.583 5.809-1.431 2.593-5.355 1.45 5.77 5.393 2.583-5.808 1.431-2.593 5.355z"
        fill="#F1BD38"
      />
    </svg>
  )
}
