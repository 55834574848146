export function VerifyEmailIdentityRecoveryIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="120" height="138" viewBox="0 0 143 160" fill="none" {...props}>
      <g clipPath="url(#clip0_4130:4993)">
        <path
          d="M116.553 45.5851L61.9325 2.57252C60.1175 1.14252 57.56 1.14252 55.745 2.57252L1.125 45.5851V86.9001H116.553V45.5851Z"
          fill="#008E5A"
        />
        <path d="M116.553 62.12L1.125 62.9225L17.6475 45.5851H103.895L116.553 62.12Z" fill="#1D232A" />
        <path
          d="M111.02 51.61C110.937 51.61 110.855 51.5825 110.787 51.53L103.765 45.965H17.7648L10.7673 50.745C10.5973 50.8625 10.3623 50.8175 10.2473 50.6475C10.1298 50.4775 10.1748 50.2425 10.3448 50.1275L17.4373 45.2825C17.4998 45.24 17.5723 45.2175 17.6498 45.2175H103.897C103.982 45.2175 104.065 45.245 104.13 45.2975L111.255 50.945C111.417 51.0725 111.445 51.31 111.315 51.4725C111.24 51.5625 111.13 51.61 111.02 51.61Z"
          fill="#1D232A"
        />
        <path d="M103.895 6.91H17.6476V118.83H103.895V6.91Z" fill="#E8E9EA" />
        <path
          d="M103.895 112.335L65.4449 78.495C61.6674 75.17 56.0074 75.17 52.2324 78.495L13.7823 112.335"
          fill="#00A868"
        />
        <path d="M116.553 123.475H1.125V45.5851L57.9725 86.9001L116.553 45.5851V123.475Z" fill="#00A868" />
        <path
          d="M13.7825 112.71C13.68 112.71 13.5775 112.667 13.5025 112.585C13.365 112.43 13.3775 112.192 13.5325 112.055L50.4325 79.0324C50.5875 78.8949 50.825 78.9074 50.9625 79.0624C51.1 79.2174 51.0875 79.4549 50.9325 79.5924L14.0325 112.615C13.96 112.677 13.8725 112.71 13.7825 112.71Z"
          fill="#1D232A"
        />
        <path
          d="M103.895 112.71C103.805 112.71 103.718 112.677 103.645 112.615L67.2676 80.0999C67.1126 79.9624 67.1001 79.7249 67.2376 79.5699C67.3751 79.4149 67.6126 79.4024 67.7676 79.5399L104.145 112.055C104.3 112.192 104.313 112.43 104.175 112.585C104.1 112.667 103.998 112.71 103.895 112.71Z"
          fill="#1D232A"
        />
        <path
          d="M31.0452 28.63C30.8377 28.63 30.6702 28.4625 30.6702 28.255C30.6702 28.0475 30.8377 27.88 31.0452 27.88L90.4952 27.87C90.7027 27.87 90.8702 28.0375 90.8702 28.245C90.8702 28.4525 90.7027 28.62 90.4952 28.62L31.0452 28.63Z"
          fill="#1D232A"
        />
        <path
          d="M31.0476 45.9625C30.8401 45.9625 30.6726 45.795 30.6726 45.5875C30.6726 45.38 30.8401 45.2125 31.0476 45.2125L90.4976 45.2025C90.7051 45.2025 90.8726 45.37 90.8726 45.5775C90.8726 45.785 90.7051 45.9525 90.4976 45.9525L31.0476 45.9625Z"
          fill="#1D232A"
        />
        <path
          d="M31.0502 63.2975C30.8427 63.2975 30.6752 63.13 30.6752 62.9225C30.6752 62.715 30.8427 62.5475 31.0502 62.5475L90.5002 62.5375C90.7077 62.5375 90.8752 62.705 90.8752 62.9125C90.8752 63.12 90.7077 63.2875 90.5002 63.2875L31.0502 63.2975Z"
          fill="#1D232A"
        />
        <g filter="url(#filter0_d_4130:4993)">
          <path
            d="M103.875 148.5C119.339 148.5 131.875 135.964 131.875 120.5C131.875 105.036 119.339 92.5 103.875 92.5C88.411 92.5 75.875 105.036 75.875 120.5C75.875 135.964 88.411 148.5 103.875 148.5Z"
            fill="#E8E9EA"
          />
        </g>
        <path
          d="M99.2268 134.628C98.5144 134.628 97.7973 134.369 97.233 133.847L87.9036 125.226C86.7116 124.123 86.6387 122.266 87.739 121.074C88.8417 119.882 90.6991 119.809 91.8912 120.909L99.0622 127.537L115.692 109.541C116.795 108.349 118.652 108.276 119.844 109.377C121.036 110.479 121.109 112.337 120.009 113.529L101.385 133.683C100.807 134.311 100.019 134.628 99.2268 134.628Z"
          fill="#00A868"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_4130:4993"
          x="72.875"
          y="91.5"
          width="62"
          height="62"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.290196 0 0 0 0 0.309804 0 0 0 0 0.333333 0 0 0 0.32 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4130:4993" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4130:4993" result="shape" />
        </filter>
        <clipPath id="clip0_4130:4993">
          <rect width="140" height="160" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
