export function KycRejectedIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="329px" viewBox="0 0 290 329" fill="none" aria-label="conta rejeitada" {...props}>
      <path
        d="M213.027 18.732C145.221-12.812 95.497 7.016 46.677 50.277c-85.889 76.608-41.589 241.54 38.875 271.282 80.463 29.742 152.79-39.656 188.953-89.226 36.163-49.57 6.329-182.056-61.478-213.6z"
        fill="#FEF2F4"
      />
      <g opacity={0.7} fillRule="evenodd" clipRule="evenodd">
        <path
          d="M153.401 187.545c6.768-.351 15.138 3.6 15.861 10.957.524 5.345.362 10.675 1.173 16.012.649 4.266 2.022 8.361 3.894 12.246 1.801 3.737 5.535 6.917 6.507 10.909.619 2.543.272 6.121-.582 8.609-1.038 3.025-.486 2.786 1.583 5.324 4.054 4.973 7.704 10.074 10.285 16.014 3.718 8.557 6.156 18.558 4.776 27.924-.175 1.19-.384 5.458-2.021 5.756-1.83.334-.974-2.107-.877-2.896.623-5.031 1.176-9.429.425-14.539-2.897-19.716-17.041-32.705-26.25-49.438-.506-.863-1.575-2.918-2.318-4.274-1.173-2.14-2.353-6.212-3.941-7.799-2.991-2.99-.241 3.02.341 4.168.737 1.451 1.548 2.994 2.303 4.265 3.456 5.895 7.312 12.331 10.881 18.407-15.434 3.517-26.303-15.038-30.445-27.237-3.413-10.05-7.496-33.548 8.405-34.408z"
          fill="#FDDBE1"
        />
        <path
          d="M244.112 168.882c-11.314.898-18.577 8.364-21.958 18.806-1.822 5.627-2.031 11.408-3.044 17.191-1.523 8.7-4.801 15.294-11.207 21.492-1.984 1.92-3.446 3.891-4.485 6-2.064 2.105-3.86 4.488-5.179 7.401-1.686 3.72-2.163 8.183-.216 11.892.523.997 1.828 2.999 3.181 3.908-5.065 10.787-7.85 22.601-7.536 34.401.19 7.122.537 14.248.943 21.362.109 1.91.731 5.064 1.666 1.861.855-2.931-.218-7.53-.317-10.592-.483-14.912-.895-28.47 5.243-42.402a108.394 108.394 0 012.037-4.346c.042-.024.081-.054.12-.082.027.021.051.043.079.064.045-.081.107-.177.18-.285.204-.21.382-.476.551-.773.544-.755 1.16-1.641 1.164-2.126l-.002-.014c3.8-6.203 8.152-12.064 12.598-17.811-.033.042 4.823-6.57 4.072-2.913-.362 1.762-3.934 5.117-5.376 6.983-3.191 4.152-6.938 9.413-10.052 14.5-.419.241-1.011 1.424-1.439 2.304-.237.446-.457.896-.674 1.288 11.033 3.645 19.616-3.698 25.469-12.238l.02-.031c1.781-2.36 3.605-4.703 5.884-6.568 3.803-3.113 7.875-3.663 12.359-5.195 12.226-4.177 17.425-20.023 19.076-31.78 1.862-13.265-7.249-33.605-23.157-32.297z"
          fill="#FCD5DB"
        />
        <path
          d="M221.764 131.737c-11.612 12.085-17.222 28.848-21.665 44.606-4.961 17.601-8.238 35.474-8.364 53.773-.082 12.023-.409 24.121.293 36.13.248 4.265.674 8.537 1.35 12.756.055.347 1.568 9.767 3.015 7.572.823-1.248-1.55-9.772-1.73-11.192-1.009-7.997-1.243-16.052-1.194-24.106.081-13.261-.31-26.455 1.034-39.667.201-1.973.359-4.045.828-5.974.497-2.052-.136-1.157 1.484-1.969 1.575-.789 3.521-.677 5.154-1.667 6.481-3.93 9.796-14.024 12.376-20.647 2.951-7.574 4.745-15.65 6.177-23.62a166.637 166.637 0 002.23-17.608c.134-1.869.142-3.824.509-5.662.099-.496.596-1.373.578-1.812-.038-.929-.599-1.476-.645-2.257-.486.438-.963.886-1.43 1.344z"
          fill="#FDE3E7"
        />
        <path
          d="M158.447 292.757c0 19.986 16.202 36.188 36.188 36.188 19.987 0 36.189-16.202 36.189-36.188"
          fill="#FCD7DD"
        />
        <path
          d="M219.436 261.071c8.956-.66 18.958 6.566 23.279 14.085 3.482 6.063 2.896 14.024.745 20.465-1.441 4.312-4.585 10.46-9.828 10.52-6.559.075-6.876-8.033-7.003-12.772-.168-6.301-.964-11.79-5.929-16.085-.613-.53-1.257-1.026-1.9-1.523a47.894 47.894 0 01-1.956-1.567c-1.755-1.476-3.599-3.026-3.824-5.458-.325-3.525 1.664-4.159 4.702-3.504.098.021.365.122.727.259 1.551.588 4.862 1.843 4.235.343-.235-.565-1.097-1.013-2.184-1.349-2.813-.306-6.167-.661-7.132-.449-10.408 2.284-14.461 16.701-15.537 25.861-.116.989-.21 1.98-.292 2.974h-1.912c.132-2.399.308-4.794.709-7.166 1.534-9.073 6.33-22.4 16.85-23.872.388-.055 1.815-.064 3.545.037a6.391 6.391 0 012.705-.799z"
          fill="#FDE5E9"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.462 151.677l26.885 94.197 19.616 69.428h14.307l-20.845-163.625h-39.963z"
        fill="#191847"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.272 151.677c-.622 48.55-2.743 75.031-3.396 79.442-.653 4.411-10.525 32.472-29.617 84.183h14.82c24.307-49.608 37.875-77.669 40.705-84.183 2.831-6.514 11.566-32.995 24.722-79.442H95.272z"
        fill="#2F3676"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.934 328.629l.742-15.548h15.572c11.077 7.774 28.921 12.587 28.921 12.587v2.961H77.99l-9.64-1.481v1.481h-7.416zM146.956 328.629l.741-15.548h15.573c11.076 7.774 28.921 12.587 28.921 12.587v2.961h-28.179l-9.641-1.481v1.481h-7.415z"
        fill="#191847"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M107.149 67.904c5.533 1.549 10.57 1.639 12.597.465 5.08-2.942-.02-23.208-6.466-29.962-6.446-6.754-29.189-4.148-25.91 11.878 1.138 5.562 4.774 9.796 9.25 12.83l1.835 29.97 17.547-4.886-8.853-20.295z"
        fill="#D4A181"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M115.928 41.509c-.46-.98-1.203-2.004-1.816-2.882-.792-1.13-1.775-2.075-2.808-2.936a27.02 27.02 0 00-6.365-3.963c-4.117-1.814-8.825-2.21-12.877-.527a12.557 12.557 0 00-5.251 4.073c-1.242 1.66-2.263 3.882-4.275 4.581-2.189.761-4.782-.646-6.909-1.369-2.397-.815-4.82-1.22-7.284-.954-4.126.446-7.46 2.817-9.273 6.826-1.93 4.266-2.62 9.926.557 13.983.59.753 1.245 1.346 2.063 1.76.75.38 1.825.706 2.31 1.51.516.857.08 2.268.015 3.188-.094 1.318-.094 2.674.394 3.943.8 2.083 2.708 3.754 4.514 4.767 1.837 1.03 3.836 1.359 5.833 1.21 1.333-.098 2.662-.319 3.92-.77.623-.225 1.136-.56 1.714-.873.562-.304.942-.238 1.543-.049 2.794.878 5.504.764 8.172-.225 2.252-.835 4.753-2.136 6.192-4.247 1.598-2.345.88-4.764-.335-7.224.593.114 2.078-.126.93-.92-.455-.314-1.146-.302-1.65-.42-.588-.139-1.207-.357-1.73-.704-2.111-1.402-1.544-5.608.292-6.787 2.727-1.75 4.575 2.303 7.01 2.806 1.4.29 2.441-.929 3.113-2.064.901-1.521 1.304-3.316 1.585-5.106.231-1.475.416-2.956.66-4.427.114-.687.617-2.68-.067-3.23 2.017-1.023 4.646-.793 6.812-.136.895.272 1.624.644 2.267 1.385.138.158.479.65.722.606.454-.082.138-.579.022-.825z"
        fill="#050C12"
      />
      <g filter="url(#Erro_01_svg__filter0_d)">
        <circle cx={206.83} cy={49.553} r={23.699} fill="#E74C3C" />
        <path
          d="M206.83 36.626v15.081"
          stroke="#fff"
          strokeWidth={3.088}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx={206.83} cy={61.403} r={2.154} fill="#fff" />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.415 191.84c1.512-4.517 4.572-60.447 4.572-60.447l15.578-2.04s-10.002 60.157-10.731 63.03c-.948 3.731 2.165 8.546 4.276 11.812.327.505.63.973.89 1.394-2.14 1.329-3.4.242-4.729-.904-1.5-1.295-3.088-2.664-6.129-.714-1.174.754-2.267 1.604-3.335 2.436-3.689 2.872-7.078 5.51-12.497 3.09-.86-.383-1.977-2.113-.072-3.839 4.747-4.301 11.437-11.609 12.177-13.818zm128.262-38.921l-22.796-16.979-5.126 12.503 22.613 9.554c6.369 7.583 9.937 10.75 10.702 9.5.645-1.054.15-2.047-.305-2.962-.355-.712-.687-1.377-.437-1.986.57-1.392 4.875-1.219 8.953-.881 4.078.339 2.899-1.487 1.92-2.391-4.151-2.524-9.326-4.643-15.524-6.358z"
        fill="#D4A181"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M107.462 80.73l6.006-1.885c14.311 33.009 67.226 49.692 72.01 62.108 1.755 4.553-1.303 7.386.635 8.584l-7.201 6.94c-1.566-1.4-4.064 1.884-10.368.672-6.304-1.212-55.605-35.787-61.082-76.418z"
        fill="#C92312"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.78 75.028a3.087 3.087 0 00-2.883 3.279l.257 4.002c-10.367 10.484-35.45 41.018-36.23 91.67-.083 5.321 1.713 7.923 3.148 10.002.982 1.422 1.795 2.6 1.722 4.235l10.849 1.484c-.16-1.179 1.038-2.254 2.582-3.64 2.247-2.016 5.227-4.691 5.84-9.305.582-4.373 1.555-9.87 2.777-16.002 1.237 7.737 3.668 14.678 7.665 20.16 9.971 13.677 53.921-8.72 56.712-24.42 1.489-8.377-3.069-14.627-9.097-22.894-7.652-10.493-17.674-24.235-20.703-49.701l-.463-7.197a3.09 3.09 0 00-3.28-2.884l-18.896 1.21z"
        fill="#E73B29"
      />
      <defs>
        <filter
          id="Erro_01_svg__filter0_d"
          x={176.955}
          y={19.678}
          width={59.75}
          height={59.75}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset />
          <feGaussianBlur stdDeviation={3.088} />
          <feColorMatrix values="0 0 0 0 0.905882 0 0 0 0 0.298039 0 0 0 0 0.235294 0 0 0 0.5 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
