export function NpsTokenExpiredIllustration({ width = 186, height = 200, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox="0 0 272 217" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.8418 132.734V195.133C9.8418 198.379 12.4691 201.014 15.7136 201.014L133.296 179.703C136.754 179.703 140.006 174.84 139.686 171.396L139.342 167.687L138.993 163.933L138.586 159.51L138.503 158.634L135.44 125.592L133.901 108.993C133.624 105.969 129.252 100.484 126.221 100.484L15.7136 126.856C12.0892 127.696 9.8418 129.488 9.8418 132.734Z"
        fill="#FE6A34"
      />
      <path d="M80.7681 173.957H9.69336V202.691H80.7681V173.957Z" fill="#1D232A" />
      <path
        d="M158.278 165.792L160.323 178.459C161.756 187.444 158.29 202.109 135.823 202.109H65.7217C56.4432 202.109 49.871 193.036 52.7436 184.198L57.4442 169.764H118.643C118.643 169.764 123.735 159.95 158.278 165.792Z"
        fill="#E09661"
      />
      <path
        d="M57.77 145.238C64.9911 149.499 71.7729 155.384 73.7394 163.668C75.5397 171.25 73.0272 179.577 70.1665 187.329C70.0715 187.373 70.0992 187.246 70.0003 187.246H50.3828C50.9407 185.577 55.6492 145.421 55.6492 145.421C56.1557 145.425 57.2596 145.329 57.77 145.238Z"
        fill="#E09661"
      />
      <path
        d="M158.277 165.792C158.277 165.792 154.506 136.828 149.928 116.758C147.606 106.576 139.72 98.9624 128.052 100.203C118.263 101.245 111.694 109.354 112.482 121.157L118.642 169.764L158.277 165.792Z"
        fill="#FE6A34"
      />
      <path
        d="M128.956 160.616C128.972 160.616 128.987 160.616 129.007 160.612L155.834 157.536C156.075 157.509 156.249 157.291 156.221 157.049C156.194 156.807 155.98 156.629 155.735 156.66L128.908 159.736C128.667 159.764 128.493 159.982 128.52 160.223C128.544 160.449 128.734 160.616 128.956 160.616Z"
        fill="#1D232A"
      />
      <path
        d="M117.299 157.493C106.877 158.92 96.2811 159.566 92.8229 169.514L82.3613 169.767C88.0353 179.68 89.0363 192.053 84.8778 202.691C85.0836 202.691 84.6483 202.683 84.8541 202.683H147.363C148.095 202.639 148.759 202.512 149.325 202.247C153.428 200.979 162.366 196.163 160.321 178.459C157.686 155.65 151.766 125.473 151.766 125.473"
        fill="#FE6A34"
      />
      <path
        d="M100.918 202.703C100.891 202.703 100.859 202.703 100.831 202.695C100.507 202.647 100.285 202.342 100.333 202.017C101.472 194.404 101.979 183.706 97.927 175.998C95.5252 171.428 92.6091 170.065 92.5814 170.049C92.2847 169.914 92.1501 169.561 92.2847 169.264C92.4192 168.967 92.7674 168.832 93.0681 168.963C93.5825 169.193 105.591 174.876 101.508 202.188C101.46 202.493 101.207 202.703 100.918 202.703Z"
        fill="#1D232A"
      />
      <path
        d="M93.2314 108.363L61.1582 116.008C61.1582 116.008 63.7499 130.276 77.1553 128.017C93.9476 125.187 93.2314 108.363 93.2314 108.363Z"
        fill="#E09661"
      />
      <path
        d="M56.9942 119.263C57.9438 120.214 58.9923 121.05 60.124 121.756C61.2714 122.473 62.5059 123.056 63.7919 123.488C68.718 125.148 74.3009 124.415 78.6454 121.561C82.5745 118.981 85.2532 114.859 86.1316 110.258C86.1434 110.191 86.1553 110.115 86.1672 110.044L84.6161 110.413C84.6043 110.484 84.5884 110.559 84.5687 110.631C84.0978 112.719 83.1798 114.701 81.9295 116.437C79.1875 120.25 74.6966 122.663 70.0118 122.893C68.259 122.98 66.5141 122.758 64.8443 122.271C68.6468 123.107 72.7736 122.727 76.2833 121.05C80.3231 119.12 83.1284 115.085 84.0503 110.757C84.0582 110.714 84.0662 110.674 84.0741 110.634C84.082 110.603 84.0859 110.571 84.0899 110.543L82.5032 110.92C82.4874 110.995 82.4676 111.063 82.4439 111.138C81.981 112.858 81.2173 114.511 80.1529 115.941C81.0867 114.475 81.7871 112.854 82.2302 111.189C82.2302 111.181 82.2342 111.177 82.2381 111.17C82.254 111.102 82.2658 111.039 82.2658 110.975L60.7016 116.112L57.0496 116.984C56.9942 117.051 56.9546 117.134 56.9309 117.218C56.824 117.531 56.8992 117.895 57.2316 118.054C57.2514 118.062 57.2712 118.07 57.2909 118.082C56.8597 118.252 56.5669 118.831 56.9942 119.263Z"
        fill="#1D232A"
      />
      <path
        d="M26.907 81.0913C5.35076 69.4589 1.79365 51.0214 4.6702 39.4167C8.60715 23.5277 21.7356 17.5391 25.6132 32.2629C28.5135 7.72186 43.6163 -0.29599 51.514 0.984171C60.4839 2.44268 66.2528 14.5467 61.7698 26.409C71.994 17.5272 84.5289 34.7677 73.9091 56.8872C48.7917 71.9281 26.907 81.0913 26.907 81.0913Z"
        fill="#1D232A"
      />
      <path
        d="M37.8754 95.1258L40.198 94.0161L31.323 75.3923L29.0004 76.5021C23.8646 78.9554 21.6923 85.1144 24.1415 90.2588C26.5908 95.4033 32.7395 97.5791 37.8754 95.1258Z"
        fill="#E09661"
      />
      <path
        d="M83.4354 61.9087C84.662 64.4809 84.7292 67.3028 83.8508 69.7958C83.6846 70.2634 83.4828 70.7232 83.2534 71.1671C83.1426 71.3771 83.0278 71.5832 82.9012 71.7854C82.7785 71.9835 82.648 72.1817 82.5095 72.3719C82.3671 72.5741 82.2167 72.7683 82.0584 72.9625C82.0545 72.9625 82.0545 72.9625 82.0545 72.9625C81.8843 73.1686 81.7063 73.3667 81.5163 73.5609C81.3423 73.7353 81.1682 73.9097 80.9782 74.0722C80.8279 74.2069 80.6736 74.3377 80.5113 74.4646C79.9257 74.9283 79.2768 75.3325 78.5765 75.6694L76.2499 76.7792L67.375 58.1554L69.7016 57.0457C74.8374 54.5884 80.9861 56.7682 83.4354 61.9087Z"
        fill="#E09661"
      />
      <path
        d="M32.8125 96.0406C33.0578 96.0565 33.3031 96.0644 33.5523 96.0605C33.5563 96.0605 33.5563 96.0605 33.5563 96.0605C34.2645 94.7368 34.9766 93.4132 35.6848 92.0856C35.2456 93.3974 34.8025 94.7091 34.3594 96.0169C34.6047 95.9931 34.85 95.9654 35.0953 95.9218C35.6452 94.297 36.1952 92.6682 36.7411 91.0394C36.4405 92.6404 36.0923 94.2296 35.6927 95.8068C36.4207 95.6444 37.1447 95.3986 37.845 95.0618L38.2802 94.8557C38.2722 94.8478 38.2643 94.8438 38.2564 94.8399C38.2881 93.6668 38.292 92.4978 38.2683 91.3247C38.2643 91.0077 37.9043 90.8849 37.7184 91.0355C37.6709 90.9205 37.568 90.8532 37.4572 90.8373C37.5008 90.6114 37.5443 90.3816 37.5838 90.1517C37.6313 89.8743 37.3662 89.7554 37.1526 89.8228C37.1645 89.7871 37.1763 89.7515 37.1882 89.7198C37.2317 89.589 37.2001 89.478 37.1249 89.3988C37.3148 89.0262 36.7728 88.6577 36.571 89.0342C35.3169 91.3683 34.0627 93.7065 32.8125 96.0406Z"
        fill="#1D232A"
      />
      <path
        d="M31.4141 95.858C31.6475 95.9056 31.877 95.9452 32.1143 95.973C33.7008 93.1949 35.2913 90.4169 36.8778 87.6389C37.0914 87.2664 36.5415 86.8859 36.3278 87.2624C34.6899 90.1276 33.052 92.9928 31.4141 95.858Z"
        fill="#1D232A"
      />
      <path
        d="M63.4138 44.783L80.0796 79.7556C85.342 90.7975 80.8788 104.166 69.891 109.512C58.82 114.899 45.5096 110.21 40.2194 99.1126L23.4389 63.8943C18.1686 52.8365 22.8494 39.595 33.8887 34.3158C44.924 29.0406 58.1434 33.7253 63.4138 44.783Z"
        fill="#E09661"
      />
      <path
        d="M44.7434 82.0108C58.4055 82.0108 69.4809 70.9169 69.4809 57.232C69.4809 43.547 58.4055 32.4531 44.7434 32.4531C31.0812 32.4531 20.0059 43.547 20.0059 57.232C20.0059 70.9169 31.0812 82.0108 44.7434 82.0108Z"
        fill="#E09661"
      />
      <path
        d="M75.9964 71.1869L80.1945 80.0014C85.4609 91.0551 80.788 104.305 69.7526 109.58C58.7094 114.859 45.486 110.166 40.2196 99.1126L36.0215 90.2981C40.2512 84.0519 46.1468 78.8163 53.4351 75.3325C60.7273 71.8488 68.4904 70.5527 75.9964 71.1869Z"
        fill="#1D232A"
      />
      <path
        d="M64.4274 81.1608L64.4277 81.1611C64.4803 81.222 64.5157 81.3075 64.5231 81.3934C64.5308 81.4818 64.5067 81.5285 64.4973 81.5406L64.4973 81.5406L64.4954 81.543C63.2097 83.2128 60.91 84.5971 58.1464 85.2933L58.1458 85.2935C56.9808 85.5887 55.8125 85.7404 54.6721 85.7492C54.6708 85.7489 54.6408 85.7446 54.5952 85.6965C54.5454 85.6438 54.5029 85.5627 54.4898 85.4697C54.4791 85.3807 54.5002 85.3284 54.5119 85.3093C55.6531 85.2985 56.8214 85.1453 57.9845 84.8515C60.6513 84.1798 63.0424 82.7963 64.3542 81.1015C64.3679 81.1074 64.3947 81.1229 64.4274 81.1608Z"
        fill="#FCFCFC"
        stroke="#FCFCFC"
      />
      <path
        d="M22.8086 39.2979C28.3362 41.7076 34.6867 41.7274 40.5545 40.3442C43.4786 39.6546 46.4976 38.5052 48.29 36.0915C50.0824 33.6739 50.0191 29.7026 47.4788 28.0935"
        fill="#1D232A"
      />
      <path
        d="M67.6229 57.3955L67.6232 57.3961C68.6612 59.5216 67.7755 62.0879 65.6532 63.1243L55.3225 68.1655C53.1965 69.2019 50.6358 68.3179 49.6053 66.1931L49.6047 66.1919C48.5667 64.0664 49.4524 61.5002 51.5746 60.4638C51.5746 60.4638 51.5747 60.4638 51.5747 60.4638L61.9056 55.4224L61.9063 55.4221C64.0267 54.3832 66.588 55.269 67.6229 57.3955Z"
        fill="#FCFCFC"
        stroke="#FCFCFC"
      />
      <path
        d="M50.5283 65.1826L61.2392 72.0828C63.269 73.3788 63.0989 76.4029 60.9345 77.4611L54.8768 80.4177C54.034 80.8299 53.6739 81.1193 52.7362 81.0875C51.8973 81.0598 51.1139 80.5683 50.6154 79.8906C50.1168 79.2129 49.8873 78.3687 49.8398 77.5285"
        fill="#E09661"
      />
      <path
        d="M52.8755 81.6858C52.8241 81.6858 52.7687 81.6858 52.7133 81.6819C51.736 81.6462 50.7705 81.1111 50.1374 80.2432C49.6112 79.5298 49.3065 78.6023 49.2472 77.56C49.2274 77.231 49.4806 76.9496 49.809 76.9338C50.1374 76.9258 50.4144 77.1676 50.4342 77.4966C50.4659 78.0594 50.6043 78.8718 51.095 79.5416C51.5104 80.1084 52.1475 80.477 52.7568 80.4968C53.378 80.5246 53.655 80.3779 54.205 80.0925C54.3316 80.0291 54.4661 79.9578 54.6125 79.8865L60.6703 76.9298C61.4933 76.5295 62.0116 75.7448 62.063 74.8332C62.1145 73.9216 61.6871 73.0814 60.9156 72.5899L50.2008 65.6858C49.9238 65.5074 49.8446 65.1388 50.0227 64.8654C50.2008 64.5879 50.5687 64.5087 50.8417 64.687L61.5526 71.5872C62.6882 72.3125 63.3213 73.5491 63.2461 74.9006C63.1709 76.2481 62.3994 77.4094 61.1886 77.9999L55.1308 80.9566C54.9884 81.0239 54.8618 81.0913 54.7431 81.1508C54.1694 81.444 53.7064 81.6858 52.8755 81.6858Z"
        fill="#1D232A"
      />
      <path
        d="M49.3216 71.0941L49.3215 71.0942L46.259 72.5883L46.259 72.5883L46.2543 72.5907L46.2012 72.6173L39.3109 75.9811L39.2833 75.9934L39.2756 75.9968L39.2679 76.0005L38.9909 76.1353L38.99 76.1358C37.9301 76.6541 36.7592 76.6962 35.7267 76.3349L35.7238 76.3339C34.691 75.9796 33.7951 75.2249 33.2771 74.1626C32.2424 72.0326 33.1247 69.4713 35.2448 68.4347L35.4902 68.3177L35.5478 68.2907L35.5478 68.2908L35.5556 68.287L42.4481 64.9181L42.4756 64.9059L42.4836 64.9023L42.4915 64.8985L45.5775 63.3925C45.5776 63.3925 45.5777 63.3924 45.5777 63.3924C46.6421 62.8736 47.8092 62.8319 48.8414 63.1931L48.8429 63.1936C49.8794 63.5527 50.7815 64.3051 51.2943 65.3679L51.295 65.3694C52.3297 67.4955 51.4473 70.0579 49.3216 71.0941Z"
        fill="#FCFCFC"
        stroke="#FCFCFC"
      />
      <path
        d="M34.4647 56.4553C32.6052 58.1395 32.4588 61.0166 34.1402 62.8792L40.8779 56.7842C39.1964 54.9177 36.3241 54.771 34.4647 56.4553Z"
        fill="#1D232A"
      />
      <path
        d="M49.354 45.9642C47.091 47.0461 46.1336 49.7607 47.2136 52.0275L55.4072 48.1082C54.3272 45.8453 51.6171 44.8823 49.354 45.9642Z"
        fill="#1D232A"
      />
      <path
        d="M78.0406 69.6047C77.9061 69.6047 77.7716 69.5611 77.6608 69.4699C74.84 67.1278 73.9617 64.0169 75.0497 60.2244C75.1407 59.9074 75.469 59.7251 75.7855 59.8162C76.102 59.9073 76.284 60.2363 76.193 60.5533C75.2317 63.898 75.9596 66.5136 78.4204 68.5545C78.6737 68.7645 78.7093 69.137 78.4996 69.3907C78.3809 69.5294 78.2108 69.6047 78.0406 69.6047Z"
        fill="#1D232A"
      />
      <path
        d="M47.7209 70.1009C47.7209 71.0362 47.5112 71.924 47.1353 72.7167L40.2347 76.0855C37.5046 75.4672 35.4629 73.0219 35.4629 70.1009C35.4629 69.1655 35.6726 68.2777 36.0485 67.489L42.949 64.1162C45.6792 64.7385 47.7209 67.1838 47.7209 70.1009Z"
        fill="#1D232A"
      />
      <path
        d="M64.7423 61.7937C64.7423 62.7291 64.5326 63.6169 64.1567 64.4095L57.2562 67.7784C54.5261 67.1601 52.4844 64.7147 52.4844 61.7937C52.4844 60.8584 52.6941 59.9706 53.07 59.1819L59.9705 55.8091C62.7007 56.4274 64.7423 58.8727 64.7423 61.7937Z"
        fill="#1D232A"
      />
      <path
        d="M271.702 130.578L271.251 133.713L270.535 138.71L267.797 157.758L262.443 195.045C261.861 199.092 258.403 202.096 254.324 202.096H170.002L172.34 185.799L175.074 166.747L175.791 161.749L176.641 155.836L180.606 128.239C181.184 124.193 184.646 121.188 188.725 121.188H263.579C267.662 121.188 270.966 124.145 271.65 127.93C271.809 128.778 271.832 129.666 271.702 130.578Z"
        fill="#00A868"
      />
      <path
        d="M173.846 202.092V200.071L262.449 195.042C262.366 195.767 261.218 202.092 254.326 202.092C247.437 202.092 173.846 202.092 173.846 202.092Z"
        fill="#1D232A"
      />
      <path
        d="M173.133 202.092H107.411C102.881 202.092 99.2051 198.414 99.2051 193.872V191.918C99.2051 191.229 99.763 190.666 100.455 190.666H173.129C176.278 190.666 178.83 193.222 178.83 196.377C178.834 199.536 176.282 202.092 173.133 202.092Z"
        fill="#42EC9A"
      />
      <path
        d="M260.206 191.261H178.836C178.507 191.261 178.242 190.995 178.242 190.666C178.242 190.337 178.507 190.072 178.836 190.072H260.206C260.534 190.072 260.8 190.337 260.8 190.666C260.8 190.995 260.53 191.261 260.206 191.261Z"
        fill="#1D232A"
      />
      <path
        d="M3.04133 195.989C3.04133 195.989 -1.00245 166.51 0.580243 145.98C1.38346 135.568 8.06638 126.872 19.7862 126.385C29.6227 125.977 37.3066 133.027 38.2642 144.818L44.6939 193.369C44.6939 193.369 47.7524 216.111 26.758 216.111C5.76356 216.111 3.04133 195.989 3.04133 195.989Z"
        fill="#FE6A34"
      />
      <path
        d="M9.84424 82.7045C10.2122 79.6606 11.5852 77.9247 13.5913 77.1082C14.9761 76.5455 17.184 76.8269 18.3829 78.1863C19.5461 79.51 20.1001 81.0993 20.9983 83.8103C21.932 82.106 23.582 80.3344 25.0579 79.7795C26.3596 79.2881 28.9038 79.5457 30.3164 81.4521C31.5113 83.0652 31.7883 85.1301 31.725 87.084C31.7131 87.4605 31.6498 88.5821 31.634 88.9587C31.634 88.9587 33.1969 87.8608 36.1961 87.2386C36.8291 87.1078 37.4781 87.0523 38.123 87.0483C40.3625 87.0364 42.7168 87.9163 43.9631 89.7791C45.4667 92.0184 45.0671 94.979 43.7297 97.1786C43.4369 97.6622 43.0254 98.0625 42.7247 98.5341C42.7247 98.5341 43.8049 98.4152 45.6368 98.6332C79.0158 102.644 73.2113 121.272 73.2113 121.272L62.6429 158.995L24.1399 147.628L35.9943 117.974C30.8307 116.686 22.5018 113.345 16.5232 105.827C10.7068 98.5064 8.86692 90.7897 9.84424 82.7045Z"
        fill="#E09661"
      />
      <path
        d="M19.7881 215.271C33.8622 219.476 48.9374 217.423 59.209 193.016C70.2048 166.878 62.6435 160.172 62.6435 160.172L65.983 147.073L29.0429 134.918L24.1405 147.628C24.1405 147.628 13.8372 150.763 11.748 166.45"
        fill="#FE6A34"
      />
      <path
        d="M62.6428 160.763C62.5835 160.763 62.5202 160.755 62.4608 160.735L24.0212 148.187C23.7086 148.084 23.5385 147.751 23.6413 147.438C23.7442 147.125 24.0766 146.954 24.3892 147.057L62.8288 159.605C63.1414 159.708 63.3115 160.041 63.2087 160.354C63.1256 160.604 62.8921 160.763 62.6428 160.763Z"
        fill="#1D232A"
      />
      <path
        d="M23.8995 105.953C23.757 105.953 23.6106 105.902 23.4998 105.799C21.5729 104.031 20.1089 101.641 19.2701 98.8828C18.5341 96.4691 18.2611 93.7701 18.4669 90.861C18.6766 87.9202 19.3453 85.3797 20.465 83.3068C20.6193 83.0175 20.9794 82.9105 21.2682 83.0651C21.5571 83.2197 21.6639 83.5803 21.5096 83.8696C20.4729 85.7919 19.8478 88.1738 19.6499 90.9403C19.456 93.7027 19.7093 96.2551 20.4017 98.53C21.1812 101.082 22.5304 103.294 24.2991 104.915C24.5404 105.137 24.5563 105.513 24.3347 105.755C24.22 105.89 24.0617 105.953 23.8995 105.953Z"
        fill="#1D232A"
      />
      <path
        d="M33.2822 109.338C33.1754 109.338 33.0646 109.31 32.9696 109.247C27.8496 106.049 25.0799 100.5 26.073 95.4348C26.3698 93.9287 26.9514 92.5891 27.814 91.4517C28.7161 90.2547 30.002 89.1846 31.5333 88.3523C31.8182 88.1977 32.1822 88.3048 32.3365 88.5901C32.4948 88.8794 32.384 89.2401 32.0991 89.3947C30.7182 90.1437 29.5629 91.1029 28.7636 92.165C28.0158 93.1559 27.5014 94.333 27.2402 95.6607C26.3421 100.242 28.8981 105.296 33.6027 108.236C33.8796 108.411 33.9667 108.775 33.7926 109.057C33.6739 109.239 33.48 109.338 33.2822 109.338Z"
        fill="#1D232A"
      />
      <path
        d="M10.7257 174.551C10.698 174.551 10.6743 174.551 10.6466 174.547C10.3221 174.504 10.0926 174.206 10.1362 173.881C13.0285 151.809 21.8718 147.747 23.6959 147.137L28.3055 135.187C28.4242 134.882 28.7684 134.727 29.0731 134.846C29.3777 134.965 29.5321 135.31 29.4134 135.615L24.5861 148.135L24.2775 148.207C24.1826 148.231 14.358 150.834 11.3153 174.036C11.2717 174.333 11.0185 174.551 10.7257 174.551Z"
        fill="#1D232A"
      />
      <path
        d="M31.3577 89.7316C31.2153 89.7316 31.0729 89.6801 30.9621 89.581C30.7603 89.3987 30.7049 89.1054 30.8315 88.8636C30.9581 88.6219 31.1203 87.5201 31.1362 87.0643C31.2074 84.7972 30.788 83.0772 29.8423 81.8049C28.6316 80.176 26.4118 79.9025 25.2723 80.3345C24.9676 80.4494 24.6234 80.2949 24.5086 79.9897C24.3939 79.6845 24.5482 79.3397 24.8529 79.2248C26.2852 78.6818 29.1657 78.9037 30.7959 81.0994C31.9038 82.5936 32.4023 84.5594 32.3232 87.1079C32.3192 87.2426 32.2915 87.5716 32.2401 87.9521C33.8703 87.1079 36.9526 85.8356 40.3593 86.0615C40.6877 86.0853 40.933 86.3667 40.9133 86.6957C40.8895 87.0246 40.6007 87.2704 40.2802 87.2505C35.7774 86.9414 31.7297 89.6167 31.6901 89.6444C31.5872 89.6999 31.4725 89.7316 31.3577 89.7316Z"
        fill="#1D232A"
      />
      <path
        d="M82.3632 170.362C82.0427 170.362 81.7776 170.104 81.7697 169.783C81.7618 169.454 82.019 169.181 82.3474 169.173L92.4648 168.927C94.6607 164.876 96.9279 162.502 100.224 160.774C103.896 158.852 109.146 157.655 117.246 156.898C117.574 156.863 117.863 157.108 117.891 157.433C117.918 157.758 117.681 158.052 117.357 158.079C101.237 159.585 97.0783 162.764 93.3511 169.787L93.1888 170.092L82.379 170.354C82.3751 170.362 82.3672 170.362 82.3632 170.362Z"
        fill="#1D232A"
      />
      <path
        d="M39.3257 100.876C39.112 100.876 38.9063 100.761 38.7995 100.559C38.6491 100.269 38.7599 99.9087 39.0487 99.7581C40.4414 99.0289 41.8775 98.276 43.0209 97.206C44.2315 96.0726 44.9358 94.6737 44.9476 93.3619C44.9516 93.037 45.2167 92.7754 45.5411 92.7754C45.5411 92.7754 45.545 92.7754 45.549 92.7754C45.8774 92.7794 46.1385 93.0488 46.1345 93.3778C46.1147 95.0343 45.2958 96.7027 43.828 98.0778C42.5699 99.2548 41.0585 100.047 39.5947 100.812C39.5116 100.852 39.4206 100.876 39.3257 100.876Z"
        fill="#1D232A"
      />
      <path
        d="M114.132 158.42C113.835 158.42 113.578 158.198 113.542 157.893L111.287 138.342C111.251 138.017 111.485 137.72 111.809 137.684C112.137 137.648 112.43 137.882 112.466 138.207L114.721 157.758C114.757 158.083 114.523 158.381 114.199 158.416C114.175 158.42 114.155 158.42 114.132 158.42Z"
        fill="#1D232A"
      />
    </svg>
  )
}
