import { useState } from 'react'
import { useDeepCompareEffect } from 'react-use'
import { type IconId, Button, NotificationDot, ToggleChip, Tooltip } from '@dlpco/ginga-stone'

import { Box, Flex } from '~/domains/platform/design-system'
import BlackBird from '~/domains/platform/lib/blackbird'
import { Bucket } from '~/domains/platform/lib/bucket'
import { clientSideOnly } from '~/domains/platform/lib/client-side-only'

interface Settings {
  disableReCAPTCHA: boolean
  forceDarkGinga: boolean
  roleBadgeEnable: boolean
}

const ToggleButton = ({
  icon,
  action,
  checked,
  label,
  reloadNeeded
}: {
  icon: IconId
  action: () => void
  checked: boolean
  label: string
  reloadNeeded: boolean
}) => {
  return (
    <Flex gap=".2rem">
      <ToggleChip icon={icon} onChange={action} checked={checked}>
        {label}
      </ToggleChip>
      {reloadNeeded && (
        <Tooltip content="Need reload to work">
          <NotificationDot color="warning" />
        </Tooltip>
      )}
    </Flex>
  )
}

export function GeneralSettings() {
  const [settings, setSettingState] = useState<Settings>(
    clientSideOnly(
      () => {
        return {
          disableReCAPTCHA: Bucket.local.get('recaptcha::disabled') || false,
          forceDarkGinga: Bucket.local.get('force::dark::mode') || false,
          roleBadgeEnable: Bucket.local.get('role::badge::enable') || false
        }
      },
      { disableReCAPTCHA: false, forceDarkGinga: false, roleBadgeEnable: false }
    )
  )

  function toggle(name: keyof Settings) {
    setSettingState(state => ({ ...state, [name]: !state[name] }))
  }

  useDeepCompareEffect(() => {
    clientSideOnly(() => {
      Bucket.local.set('recaptcha::disabled', settings.disableReCAPTCHA)
      Bucket.local.set('force::dark::mode', settings.forceDarkGinga)
      Bucket.local.set('role::badge::enable', settings.roleBadgeEnable)
    })
  }, [settings])

  return (
    <Flex justifyContent="space-between">
      <Flex gap="1rem" style={{ minHeight: '10rem' }}>
        <ToggleButton
          action={() => toggle('disableReCAPTCHA')}
          icon={settings.disableReCAPTCHA ? 'unlock-solid' : 'lock-solid'}
          checked={settings.disableReCAPTCHA}
          label="Disable reCAPTCHA"
          reloadNeeded
        />
        <ToggleButton
          action={() => toggle('forceDarkGinga')}
          icon={settings.forceDarkGinga ? 'lamp-solid' : 'lamp-outline'}
          checked={settings.forceDarkGinga}
          label="Force Dark Ginga"
          reloadNeeded
        />
        <ToggleButton
          action={() => toggle('roleBadgeEnable')}
          icon={settings.roleBadgeEnable ? 'tag-solid' : 'tag-outline'}
          checked={settings.roleBadgeEnable}
          label="Enable Role Badge"
          reloadNeeded
        />
      </Flex>
      <Box>
        <Button onClick={() => BlackBird.reload()}>Atualizar página</Button>
      </Box>
    </Flex>
  )
}
