export function AppleStoreIllustration({ width = '111' }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height="36" viewBox="0 0 111 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.74141 35.5H107.913C110.455 35.5 112.5 33.345 112.5 30.75V5.25C112.5 2.65498 110.455 0.5 107.913 0.5H5.74141C3.19922 0.5 1.15454 2.65498 1.15454 5.25V30.75C1.15454 33.345 3.19923 35.5 5.74141 35.5Z"
        fill="white"
        stroke="#E8E8E8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.5503 18.2659C23.5703 16.758 24.3848 15.3652 25.7084 14.5754C24.8696 13.4127 23.5151 12.7019 22.0534 12.6575C20.5156 12.5009 19.0247 13.5507 18.2411 13.5507C17.4423 13.5507 16.2357 12.6731 14.9365 12.699C13.227 12.7526 11.672 13.6731 10.8403 15.1237C9.0692 18.0999 10.3903 22.4739 12.0869 24.8797C12.9357 26.0577 13.9277 27.3736 15.2258 27.3269C16.496 27.2758 16.9705 26.5408 18.5037 26.5408C20.0228 26.5408 20.4679 27.3269 21.7923 27.2973C23.1553 27.2758 24.0141 26.114 24.8331 24.9248C25.443 24.0854 25.9123 23.1577 26.2237 22.1761C24.6048 21.5115 23.5522 19.9719 23.5503 18.2659Z"
        fill="#2D3844"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.0489 11.0753C21.7921 10.2094 22.1582 9.09636 22.0696 7.97266C20.9342 8.0884 19.8854 8.61509 19.1322 9.44779C18.3884 10.2694 18.0116 11.3442 18.0848 12.4355C19.2354 12.447 20.3279 11.9457 21.0489 11.0753Z"
        fill="#2D3844"
      />
      <path
        d="M39.6077 24.3434H35.273L34.232 27.3267H32.396L36.5018 16.2891H38.4094L42.5152 27.3267H40.6478L39.6077 24.3434ZM35.7219 22.9667H39.1579L37.4641 18.1249H37.4167L35.7219 22.9667Z"
        fill="#2D3844"
      />
      <path
        d="M51.383 23.3051C51.383 25.8058 50.004 27.4125 47.9229 27.4125C46.8472 27.4671 45.834 26.9203 45.3142 26.0046H45.2748V29.9905H43.5729V19.281H45.2203V20.6195H45.2516C45.7945 19.7095 46.8088 19.1629 47.8916 19.1968C49.9959 19.1968 51.383 20.8113 51.383 23.3051ZM49.6337 23.3051C49.6337 21.6759 48.7662 20.6047 47.4427 20.6047C46.1423 20.6047 45.2677 21.6984 45.2677 23.3051C45.2677 24.9265 46.1423 26.0124 47.4427 26.0124C48.7662 26.0124 49.6337 24.9491 49.6337 23.3051Z"
        fill="#2D3844"
      />
      <path
        d="M60.5086 23.3051C60.5086 25.8058 59.1295 27.4125 57.0485 27.4125C55.9728 27.4671 54.9595 26.9203 54.4397 26.0046H54.4004V29.9905H52.6985V19.281H54.3458V20.6195H54.3771C54.92 19.7095 55.9343 19.1629 57.0171 19.1968C59.1215 19.1968 60.5086 20.8113 60.5086 23.3051ZM58.7593 23.3051C58.7593 21.6758 57.8918 20.6047 56.5682 20.6047C55.2679 20.6047 54.3932 21.6984 54.3932 23.3051C54.3932 24.9265 55.2679 26.0124 56.5682 26.0124C57.8918 26.0124 58.7593 24.9491 58.7593 23.3051H58.7593Z"
        fill="#2D3844"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.5395 24.2528C66.6656 25.3473 67.7612 26.066 69.2582 26.066C70.6927 26.066 71.7247 25.3473 71.7247 24.3604C71.7247 23.5036 71.1023 22.9907 69.6285 22.6391L68.1547 22.2945C66.0664 21.8049 65.097 20.8571 65.097 19.319C65.097 17.4145 66.8069 16.1064 69.235 16.1064C71.638 16.1064 73.2853 17.4145 73.3408 19.319H71.6228C71.5199 18.2174 70.5818 17.5526 69.2108 17.5526C67.8398 17.5526 66.9017 18.2253 66.9017 19.2044C66.9017 19.9847 67.5009 20.4439 68.9667 20.7954L70.2196 21.094C72.5529 21.6296 73.5223 22.5393 73.5223 24.1537C73.5223 26.2187 71.8276 27.5121 69.1321 27.5121C66.6101 27.5121 64.9074 26.2491 64.7974 24.2527L66.5395 24.2528Z"
        fill="#2D3844"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.1945 17.376V19.2804H78.7712V20.5885H77.1945V25.0249C77.1945 25.7141 77.5102 26.0352 78.2033 26.0352C78.3905 26.0321 78.5773 26.0193 78.7631 25.997V27.2973C78.4515 27.3538 78.1348 27.3794 77.8178 27.3737C76.1392 27.3737 75.4846 26.7617 75.4846 25.2011V20.5885H74.2791V19.2804H75.4846V17.376H77.1945Z"
        fill="#2D3844"
      />
      <path
        d="M79.6851 23.3047C79.6851 20.7727 81.2215 19.1816 83.6174 19.1816C86.0213 19.1816 87.5505 20.7727 87.5505 23.3047C87.5505 25.8436 86.0293 27.4277 83.6174 27.4277C81.2062 27.4277 79.6851 25.8436 79.6851 23.3047ZM85.8165 23.3047C85.8165 21.5678 84.9964 20.5427 83.6173 20.5427C82.2383 20.5427 81.4191 21.5756 81.4191 23.3047C81.4191 25.0485 82.2383 26.0658 83.6173 26.0658C84.9964 26.0658 85.8164 25.0485 85.8164 23.3047H85.8165Z"
        fill="#2D3844"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M88.9533 19.2809H90.5764V20.6506H90.6158C90.8422 19.7689 91.6746 19.1621 92.6101 19.1967C92.8063 19.1961 93.0018 19.2167 93.1932 19.2584V20.8034C92.9456 20.73 92.6873 20.6963 92.4285 20.7036C91.9282 20.6839 91.444 20.8774 91.103 21.2334C90.7621 21.5893 90.5985 22.0722 90.6551 22.5551V27.3282H88.9532L88.9533 19.2809Z"
        fill="#2D3844"
      />
      <path
        d="M101.041 24.9643C100.812 26.4252 99.3461 27.4277 97.4707 27.4277C95.0587 27.4277 93.5616 25.8592 93.5616 23.3429C93.5616 20.8187 95.0668 19.1816 97.3991 19.1816C99.6931 19.1816 101.136 20.7111 101.136 23.1511V23.717H95.2796V23.8168C95.225 24.4159 95.4381 25.009 95.8648 25.4451C96.2916 25.8812 96.8908 26.1183 97.51 26.0962C98.3359 26.1713 99.1146 25.711 99.4248 24.9643L101.041 24.9643ZM95.2877 22.5625L99.4328 22.5625C99.4637 22.0242 99.2618 21.4978 98.8758 21.1101C98.4897 20.7224 97.9545 20.5085 97.3991 20.5201C96.8394 20.5169 96.3015 20.7308 95.905 21.1143C95.5085 21.4979 95.2862 22.0192 95.2877 22.5625Z"
        fill="#2D3844"
      />
      <path
        d="M33.6476 14.4237V8.81152H35.8469C36.8749 8.81152 37.4928 9.34794 37.4928 10.2114C37.4932 10.7961 37.0923 11.3004 36.5337 11.4174V11.4835C37.2326 11.5174 37.7785 12.1153 37.7659 12.8328C37.7659 13.8207 37.0647 14.4237 35.8961 14.4237H33.6476ZM34.4974 11.1877H35.5057C36.257 11.1877 36.6474 10.9039 36.6474 10.3711C36.6474 9.85356 36.2794 9.55044 35.6499 9.55044H34.4974V11.1877ZM35.726 13.6847C36.4917 13.6847 36.8973 13.3655 36.8973 12.7708C36.8973 12.1834 36.4764 11.8762 35.6768 11.8762H34.4974V13.6847H35.726Z"
        fill="#2D3844"
      />
      <path
        d="M38.6393 13.2258C38.6393 12.4634 39.1927 12.0239 40.1751 11.9614L41.2935 11.8953V11.5297C41.2935 11.0824 41.0052 10.8298 40.4482 10.8298C39.9933 10.8298 39.6781 11.0011 39.5876 11.3005H38.7987C38.882 10.5731 39.5491 10.1064 40.4858 10.1064C41.5209 10.1064 42.1048 10.635 42.1048 11.5297V14.4235H41.3204V13.8283H41.2559C40.9895 14.263 40.5157 14.517 40.0157 14.4933C39.6652 14.5307 39.3156 14.4143 39.0533 14.1728C38.7911 13.9313 38.6407 13.5872 38.6393 13.2258ZM41.2935 12.8639V12.5098L40.2852 12.5759C39.7166 12.6149 39.4587 12.8133 39.4587 13.1867C39.4587 13.5679 39.7811 13.7897 40.2243 13.7897C40.4868 13.817 40.7487 13.7338 40.9503 13.5593C41.1518 13.3848 41.2757 13.1338 41.2935 12.8639Z"
        fill="#2D3844"
      />
      <path
        d="M43.274 8.93503C43.2602 8.71352 43.389 8.50637 43.5989 8.41235C43.8089 8.31832 44.0575 8.35646 44.2263 8.50857C44.395 8.66068 44.4498 8.89597 44.3644 9.10229C44.2791 9.3086 44.0708 9.44416 43.8391 9.44435C43.6928 9.4515 43.5497 9.40119 43.4431 9.30505C43.3364 9.20891 43.2753 9.07528 43.274 8.93503ZM43.4035 10.1874H44.2737V14.4228H43.4034L43.4035 10.1874Z"
        fill="#2D3844"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.0032 12.9225L46.1005 14.4238H45.206L46.5751 12.3158L45.1943 10.1885H46.1462L47.0336 11.682H47.0981L47.9774 10.1885H48.8836L47.5226 12.2809L48.8989 14.4238H47.9631L47.064 12.9225H47.0032Z"
        fill="#2D3844"
      />
      <path
        d="M49.6183 13.2258C49.6183 12.4634 50.1717 12.0239 51.1541 11.9614L52.2725 11.8953V11.5297C52.2725 11.0824 51.9842 10.8298 51.4272 10.8298C50.9723 10.8298 50.6571 11.0011 50.5666 11.3005H49.7777C49.861 10.5731 50.5281 10.1064 51.4648 10.1064C52.4999 10.1064 53.0838 10.635 53.0838 11.5297V14.4235H52.2994V13.8283H52.2349C51.9685 14.263 51.4947 14.517 50.9947 14.4933C50.6442 14.5307 50.2946 14.4143 50.0323 14.1728C49.7701 13.9313 49.6197 13.5872 49.6183 13.2258ZM52.2725 12.8639V12.5098L51.2642 12.5759C50.6956 12.6149 50.4377 12.8133 50.4377 13.1867C50.4377 13.5679 50.7601 13.7897 51.2033 13.7897C51.4658 13.817 51.7277 13.7338 51.9293 13.5593C52.1308 13.3848 52.2547 13.1338 52.2725 12.8639Z"
        fill="#2D3844"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.3597 10.1881H55.1441V10.8375H55.205C55.3852 10.375 55.8354 10.0844 56.3198 10.1178C56.4419 10.1162 56.5638 10.1279 56.6834 10.1528V10.9776C56.5339 10.9449 56.3814 10.9279 56.2285 10.9271C55.9567 10.9021 55.6873 10.9959 55.4866 11.1856C55.2859 11.3752 55.1726 11.6429 55.1746 11.9228V14.4235H54.3597V10.1881Z"
        fill="#2D3844"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.8249 10.1885H60.6098V10.8613H60.6707C60.8825 10.3659 61.3773 10.0629 61.9028 10.1067C62.3098 10.0753 62.7086 10.2355 62.9866 10.5419C63.2646 10.8484 63.3917 11.2679 63.332 11.682V14.4238H62.5171V11.8919C62.5171 11.2113 62.2288 10.8728 61.6261 10.8728C61.3501 10.8596 61.0822 10.9707 60.8927 11.177C60.7032 11.3833 60.611 11.6642 60.6402 11.9461V14.4238H59.8249L59.8249 10.1885Z"
        fill="#2D3844"
      />
      <path
        d="M64.3557 13.2258C64.3557 12.4634 64.9091 12.0239 65.8914 11.9614L67.0098 11.8953V11.5297C67.0098 11.0824 66.7215 10.8298 66.1645 10.8298C65.7096 10.8298 65.3944 11.0011 65.3039 11.3005H64.515C64.5983 10.5731 65.2654 10.1064 66.2021 10.1064C67.2372 10.1064 67.8211 10.635 67.8211 11.5297V14.4235H67.0367V13.8283H66.9722C66.7058 14.263 66.232 14.517 65.732 14.4933C65.3815 14.5307 65.0319 14.4143 64.7697 14.1728C64.5074 13.9313 64.357 13.5872 64.3557 13.2258ZM67.0098 12.8639L67.0098 12.5098L66.0015 12.5759C65.4329 12.6149 65.175 12.8133 65.175 13.1867C65.175 13.5679 65.4974 13.7897 65.9407 13.7897C66.2031 13.817 66.465 13.7338 66.6666 13.5593C66.8681 13.3848 66.992 13.1338 67.0098 12.8639H67.0098Z"
        fill="#2D3844"
      />
    </svg>
  )
}
