export function RefundDialogIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={297} height={294} viewBox="0 0 297 294" fill="none" {...props}>
      <path
        d="M282.893 215.752c28.787-68.529 10.693-118.784-28.787-168.125C184.194-39.177 33.676 5.596 6.534 86.917c-27.142 81.321 36.19 154.419 81.427 190.968 45.238 36.549 166.145 6.396 194.932-62.133z"
        fill="#F3FBF6"
      />
      <path
        d="M212.43 88.335a2.965 2.965 0 00-2.989-3.176L96.072 86.365a2.964 2.964 0 00-2.932 2.899l-2.385 107.83a2.964 2.964 0 002.964 3.03h23.49a.89.89 0 01.862 1.108l-7.431 29.275c-.265 1.041.882 1.869 1.787 1.291l50.345-32.169a.892.892 0 01.478-.14h38.476a2.963 2.963 0 002.956-2.753l7.748-108.401z"
        fill="#14AA4B"
      />
      <path
        d="M144.628 118.574a2.502 2.502 0 000-3.537 2.502 2.502 0 00-3.537 0l-11.138 11.138a2.502 2.502 0 000 3.537l11.138 11.137a2.5 2.5 0 103.537-3.537l-6.868-6.868h15.233c8.562 0 15.502 6.941 15.502 15.502s-6.94 15.502-15.502 15.502h-21.271a2.5 2.5 0 100 5.002h21.271c11.324 0 20.504-9.18 20.504-20.504 0-11.324-9.18-20.504-20.504-20.504H137.76l6.868-6.868z"
        fill="#fff"
      />
    </svg>
  )
}
