export const NpsModalIllustration = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={215} height={165} viewBox="0 0 215 165" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#prefix__nps_modal)">
      <path
        d="M83.337 3.468c.35-1.004.913-1.958 1.74-2.621.828-.666 1.945-1.012 2.977-.772.91.212 1.678.85 2.21 1.622.53.771.844 1.669 1.098 2.57.528-.78 1.623-1.046 2.514-.75.89.294 1.573 1.062 1.944 1.926.371.865.467 1.824.479 2.764a4.754 4.754 0 012.42 2.71 4.768 4.768 0 01-.235 3.63l-5.131-1.434-5.132-1.434c-.804-.226-1.614-.436-2.413-.678-.477-.144-.958-.311-1.386-.574-.74-.454-1.09-1.18-1.274-2.006-.323-1.435-.37-2.933.008-4.361.053-.2.111-.397.18-.592z"
        fill="#8F5049"
      />
      <path
        d="M103.184 115.111l-1.208 38.741H33.782l7.345-22.817a11.204 11.204 0 018.507-7.613l53.55-8.311z"
        fill="#1D232A"
      />
      <path
        d="M94.114 67.477l-5.193 49.927-45.482-24.75c-5.104-2.797-6.234-9.654-2.301-13.952L57.45 60.485c2.831-3.136 4.893-3.923 7.407-4.013l1.903.006 15.503.059 8.427.03c3.54.55 3.795 7.334 3.424 10.91z"
        fill="#008E5A"
      />
      <path
        d="M74.325 91.707l-8.553 12.173s10.888 6.17 10.797 5.865c-.091-.305.548-22.354.548-22.354l-2.792 4.316z"
        fill="#1D232A"
      />
      <path
        d="M52.288 33.678c-.235 1.276-.822 2.453-1.366 3.629a44.597 44.597 0 00-1.632 4.064 50.82 50.82 0 00-1.614 5.718l-.06.256c-1.852 8.488-1.82 17.47-1.36 25.736l-27.074 2.306 11.278-30.89c4.318-2.219 6.696-6.96 9.799-10.735.473-.58.962-1.134 1.48-1.654 1.103-1.107 2.325-2.056 3.757-2.725 1.975-.92 4.639-1.065 6.033.61.83.995.994 2.412.759 3.686z"
        fill="#E09661"
      />
      <path
        d="M49.29 41.373a50.862 50.862 0 00-1.614 5.718l-.06.256-7.355-13.583c.473-.58.962-1.134 1.48-1.655l7.55 9.264z"
        fill="#1D232A"
      />
      <path
        d="M88.933 137.685c-1.715.02-3.44-.261-5.095-.859L39.606 120.89c-7.563-2.724-11.492-11.08-8.775-18.664 2.717-7.584 11.051-11.524 18.612-8.8l25.355 9.136.927-31.835c.235-8.055 6.938-14.393 14.967-14.16 8.032.237 14.353 6.956 14.119 15.01l-1.513 51.945a14.6 14.6 0 01-6.348 11.629 14.504 14.504 0 01-8.017 2.534z"
        fill="#E09661"
      />
      <path
        d="M76.33 66.96l-.394 1.669c.097-.57.231-1.126.394-1.669zM34.57 124.063c-7.616.093-13.674-2.548-16.344-14.294-2.67-11.745-1.913-46.79-1.913-46.79-.605-8.035 5.4-15.042 13.413-15.648 8.013-.61 14.997 5.415 15.604 13.451l3.583 47.591c.605 8.036-5.4 15.041-13.412 15.649-.31.025-.623.037-.932.041z"
        fill="#E09661"
      />
      <path
        d="M18.226 109.769l30.688-1.396c.604 8.036-5.4 15.041-13.413 15.649-.31.025-17.275-14.253-17.275-14.253z"
        fill="#1D232A"
      />
      <path
        d="M18.51 21.141c.68 1.298 1.587 2.735 2.764 4.335L7.25 30.48l.002-.009-1.92-.742-.902 1.754-1.52-.83-1.305 1.84-.006.001c-4.671-12.598 2.374-15.755 2.41-15.767L18.832 10.4a12.189 12.189 0 00-.794 1.109 6.077 6.077 0 00-.227.382 7.967 7.967 0 00-.552 1.197 6.924 6.924 0 00-.402 1.982c-.06 1.056.104 2.226.554 3.557a13.508 13.508 0 00.38.995 13.18 13.18 0 00.333.743c.032.069.064.14.101.211.063.133.132.265.199.401.028.053.057.106.083.163l.004.002z"
        fill="#BBBDBF"
      />
      <path
        d="M18.51 21.141C19.19 22.44.49 21.27.49 21.27c1.063-3.445 3.498-4.536 3.52-4.542L18.832 10.4a12.187 12.187 0 00-.794 1.109 6.124 6.124 0 00-.227.382 7.967 7.967 0 00-.552 1.197 6.935 6.935 0 00-.402 1.982c-.06 1.056.104 2.226.554 3.557a13.666 13.666 0 00.38.995 13.18 13.18 0 00.332.743c.033.069.066.14.102.211.063.133.132.265.199.401.028.053.057.106.083.163l.004.002z"
        fill="#1D232A"
      />
      <path
        d="M56.462 96.062c-5.442-2.047-7.928-4.878-8.301-7.729-.473-3.602.069-5.688.186-9.407.039-1.238.051-2.503-.34-3.675-.392-1.173-1.27-2.253-2.466-2.563-1.739-.45-3.441.812-4.655 2.141-2.656 2.908-3.689 5.969-4.444 9.837-2.914-1.62-6.325-2.383-9.653-2.248-1.48.06-2.974.307-4.326.92-1.351.612-4.675 2.791-3.41 7.221-1.647.517-5.51 3.055-2.917 8.464-1.17.58-1.854 1.876-2.116 3.158-.264 1.282-.761 6.038 4 9.52 5.837 4.269 12.542 6.371 22.263 9.458a524.02 524.02 0 0110.157 3.309"
        fill="#E09661"
      />
      <path
        d="M29.271 118.198a.339.339 0 01-.116-.022c-4.527-1.858-9.119-4.752-10.418-5.594a.304.304 0 11.329-.513c1.289.834 5.84 3.703 10.319 5.541.156.063.229.242.166.399a.296.296 0 01-.28.189zM16.343 92.773a.312.312 0 01-.189-.065.307.307 0 01-.05-.43 6.296 6.296 0 012.599-1.922c-.39-2.287.004-4.194 1.169-5.67 2.195-2.781 6.41-3.162 7.65-3.213 1.548-.063 3.441.269 5.334.932 1.38.485 2.616 1.105 3.385 1.687.71-3.644 1.414-7.083 4.01-9.924 1.355-1.484 3.612-2.23 5.365-1.779a.306.306 0 01-.152.593c-1.548-.401-3.553.272-4.765 1.599-2.524 2.763-3.161 6.036-3.898 9.823l-.17.879-.404-.401c-1.228-1.219-5.416-2.928-8.681-2.798-.836.034-5.111.338-7.197 2.981-1.094 1.384-1.429 3.213-1 5.435l.05.269-.26.081c-.73.228-1.785.825-2.559 1.807a.296.296 0 01-.237.116z"
        fill="#1D232A"
      />
      <path
        d="M24.084 55.709c1.417.31 2.848.623 4.296.602 1.45-.02 2.94-.403 4.046-1.34 1.106-.939 1.75-2.498 1.354-3.896"
        fill="#E09661"
      />
      <path
        d="M28.244 56.617c-1.442 0-2.857-.31-4.224-.611a.306.306 0 01.13-.598c1.38.303 2.81.616 4.226.594 1.094-.016 2.648-.248 3.854-1.27 1.095-.928 1.6-2.367 1.258-3.577a.304.304 0 11.584-.167c.408 1.438-.177 3.132-1.45 4.21-1.344 1.138-3.047 1.396-4.24 1.412-.044.007-.091.007-.138.007z"
        fill="#1D232A"
      />
      <path
        d="M5.692 31.971c1.997 2.401 6.22 5.75 7.912 8.122l14.701 23.572c.87 1.221 2.677 1.659 4.03.977l8.141-4.096-21.381-42.642-11.27 5.67c-3.302 1.661-4.256 5.422-2.133 8.397z"
        fill="#00A868"
      />
      <path
        d="M13.176 38.467l15.127 25.199c.87 1.22 2.677 1.66 4.03.976l8.141-4.096-24.267-34.81-3.031 12.73z"
        fill="#1D232A"
      />
      <path
        d="M13.275 37.419c-.012 0-.026 0-.039-.002a.306.306 0 01-.263-.342l1.404-11.042a.305.305 0 11.604.077l-1.404 11.042a.305.305 0 01-.302.267z"
        fill="#1D232A"
      />
      <path
        d="M33.08 60.79L13.332 20.803l12.142-6.11c3.006-1.513 7.027-.255 8.484 2.653l17.356 35.213c.576 1.17.114 2.476-1.092 3.083l-12.891 6.487c-1.512.76-3.529.124-4.251-1.339z"
        fill="#42EC9A"
      />
      <path
        d="M29.539 49.4l-7.492-15.213c-.272-.533-.022-1.154.586-1.459l12.037-6.044c.753-.378 1.731-.124 2.066.538l7.492 15.215c.272.533.022 1.154-.587 1.459L31.605 49.94c-.752.376-1.73.122-2.065-.54z"
        fill="#fff"
      />
      <path d="M15.345 19.79L7.579 23.7c3.005-1.513 7.027-.255 8.484 2.653l.988-4.172-1.706-2.391z" fill="#42EC9A" />
      <path
        d="M34.397 59.28l-3.036-6.302c-.266-.537-.012-1.162.595-1.467l12.004-6.042c.751-.379 1.721-.118 2.05.547l3.035 6.303c.266.537.012 1.162-.594 1.467l-12.005 6.042c-.75.378-1.72.118-2.05-.548z"
        fill="#1D232A"
      />
      <path
        d="M47.373 46.71l-16.11 8.107.272.546 16.111-8.107-.273-.546zM43.782 52.427l-10.468 5.268.273.546 10.469-5.268-.273-.546z"
        fill="#42EC9A"
      />
      <path
        d="M41.18 46.43l-.549.263 4.458 9.389.55-.263-4.458-9.389zM35.495 49.292l-.55.262 4.458 9.389.55-.262-4.458-9.39zM19.168 28.935l-2.316-4.23c-.525-.963.039-2.186 1.305-2.823l10.367-5.218c1.445-.726 3.21-.407 3.81.69"
        fill="#42EC9A"
      />
      <path
        d="M19.168 29.24a.306.306 0 01-.268-.159l-2.316-4.23a1.915 1.915 0 01-.138-1.522c.217-.698.792-1.325 1.575-1.72l10.367-5.218c1.601-.805 3.53-.43 4.215.817a.305.305 0 11-.534.295c-.521-.953-2.114-1.217-3.409-.564l-10.367 5.218c-.637.321-1.1.814-1.266 1.355a1.32 1.32 0 00.091 1.048l2.315 4.23a.307.307 0 01-.12.416.341.341 0 01-.145.034z"
        fill="#00A868"
      />
      <path
        d="M16.215 74.866c-.23-11.2-5.142-13.942-7.382-25.341-1.437-7.31-.39-14.589-.026-15.558 1.337-3.56 5.706-3.82 7.747.707.964 2.138 1.678 6.829 1.928 8.846 2.175.256 4.342.915 6.264 1.971 1.921 1.056 3.573 2.717 4.302 4.79.728 2.072.414 3.425-1.299 4.471 2.218 1.124 3.659 1.854 4.967 3.33.62.698 1.6 2.002 1.898 2.967.314-.072.635-.108.957-.114a5.247 5.247 0 011.898.33 5.32 5.32 0 011.808 1.114c1.284 1.211 1.934 3.037 1.824 4.8"
        fill="#E09661"
      />
      <path d="M15.903 56.842c.01-.103 2.534-7.696 11.846-2.092l-11.846 2.092z" fill="#E09661" />
      <path
        d="M15.903 57.148c-.01 0-.023 0-.033-.002a.305.305 0 01-.27-.336c.002-.023.753-2.558 3.23-3.793 2.407-1.199 5.46-.705 9.073 1.47a.306.306 0 01-.314.524c-3.225-1.942-5.952-2.487-8.107-1.62-2.415.97-3.253 3.415-3.285 3.519a.3.3 0 01-.294.238z"
        fill="#1D232A"
      />
      <path d="M10.296 49.901c-.03-.138-.347-7.478 8.184-6.382l-8.184 6.382z" fill="#E09661" />
      <path
        d="M10.296 50.206a.303.303 0 01-.296-.24c-.006-.024-.205-2.853 1.658-4.896 1.483-1.626 3.792-2.248 6.862-1.856a.308.308 0 01.264.342.308.308 0 01-.34.265c-2.732-.352-4.8.142-6.15 1.469-1.834 1.8-1.702 4.469-1.696 4.564a.307.307 0 01-.302.352zM35.16 42.121a.256.256 0 00.357.114 4.333 4.333 0 001.783-5.642 4.306 4.306 0 00-5.5-2.143.258.258 0 00-.137.35l.698 1.46a.294.294 0 00.364.15 2.156 2.156 0 012.631 1.115 2.163 2.163 0 01-.783 2.755.299.299 0 00-.111.378l.698 1.463zM30.499 37.551a.269.269 0 00-.343.165l-.662 2.22a.54.54 0 00.315.661l2.139.875a.27.27 0 00.33-.372l-1.63-3.413a.25.25 0 00-.15-.136z"
        fill="#1D232A"
      />
      <path
        d="M3.959 16.744a6.71 6.71 0 01.426-.184l-.426.184zM34.721 25.235l-.6.302-12.555 6.318-.006-.028c-.386-2.04-1.05-4.013-1.853-5.79v-.002c-1.065-2.36-2.372-4.37-3.583-5.714-5.004-5.543-9.681-4.58-11.713-3.77l16.086-6.932c5.89-1.174 12.828 3.165 14.224 15.616z"
        fill="#E8E9EA"
      />
      <path
        d="M21.566 32.16a.305.305 0 01-.136-.578l13.155-6.62a.305.305 0 01.272.545l-13.155 6.62a.292.292 0 01-.136.033z"
        fill="#1D232A"
      />
      <path
        d="M23.61 65.66c1.306.572 2.628 1.15 4.044 1.428 1.416.28 2.956.238 4.239-.384 2.118-1.03 3.074-3.466 2.826-5.653"
        fill="#E09661"
      />
      <path
        d="M29.301 67.562a8.953 8.953 0 01-1.706-.172c-1.457-.287-2.804-.88-4.107-1.45a.305.305 0 01-.156-.402.303.303 0 01.401-.157c1.275.558 2.592 1.135 3.982 1.41 1.515.3 2.954.171 4.048-.36 2.124-1.034 2.867-3.494 2.658-5.344a.306.306 0 01.268-.338.303.303 0 01.337.269c.24 2.124-.593 4.792-2.997 5.962-.903.436-1.857.582-2.728.582zM23.463 94.784a.338.338 0 01-.1-.016c-2.306-.798-3.869-2.23-4.64-4.257a.307.307 0 01.177-.395.304.304 0 01.393.177c.704 1.852 2.141 3.162 4.27 3.899a.304.304 0 01-.1.592zM20.18 102.413a.287.287 0 01-.113-.022c-1.622-.648-2.614-1.116-4.179-3.185a.306.306 0 01.06-.427.303.303 0 01.425.059c1.537 2.035 2.484 2.413 3.919 2.987.156.063.231.24.17.397a.303.303 0 01-.282.191zM36.19 87.696a.304.304 0 01-.304-.305c0-.092.006-2.29.759-5.228a.305.305 0 11.59.153c-.732 2.859-.74 5.055-.74 5.075a.304.304 0 01-.305.305z"
        fill="#1D232A"
      />
      <path
        d="M104.811 71.58l-1.01 27.82-33.727-5.855 5.665-25 .394-1.668c1.842-6.17 7.835-10.505 14.557-10.31 8.032.237 14.355 6.958 14.121 15.012z"
        fill="#008E5A"
      />
      <path
        d="M74.688 94.65a.505.505 0 01-.053-.004l-4.614-.802a.298.298 0 01-.201-.132.298.298 0 01-.043-.239L74.75 71.59a.305.305 0 11.594.136l-4.9 21.57 4.298.747a.304.304 0 01.247.352.304.304 0 01-.3.255z"
        fill="#1D232A"
      />
      <path
        d="M83.07 56.541c-1.262 1.467-6.933 4.573-11.052 4.573-5.855 0-4.625-4.17-3.554-4.642l14.606.07z"
        fill="#E09661"
      />
      <path
        d="M49.149 90.25a.306.306 0 01-.288-.409l7.313-20.484a.305.305 0 11.574.206l-7.313 20.484a.3.3 0 01-.286.203zM41.097 67.483h-.02a.304.304 0 01-.284-.324c.11-1.731-.538-3.435-1.73-4.558a5.035 5.035 0 00-1.704-1.05.305.305 0 01.211-.574c.71.263 1.372.67 1.912 1.179 1.32 1.245 2.04 3.131 1.92 5.042a.305.305 0 01-.305.285z"
        fill="#1D232A"
      />
      <path
        d="M103.563 95.985s23.835-2.815 35.154-12.092c7.908-6.481 12.818-15.96 11.034-25.502a14.08 14.08 0 01-10.945 1.59 14.14 14.14 0 01-8.796-6.86 14.182 14.182 0 01-1.587-4.57c-3.941-1.391-8.49-1.391-12.81.325a18.7 18.7 0 00-3.913 2.114c-3.273 2.307-5.619 5.551-6.753 9.124a16.399 16.399 0 00-.432 1.65 15.93 15.93 0 00-.282 4.61c.363 2.263-.67 29.61-.67 29.61z"
        fill="#FE6A34"
      />
      <path
        d="M127.765 79.642c-8.167 3.244-17.15.35-21.306-6.463a15.85 15.85 0 01-1.191-2.397 15.495 15.495 0 01-1.057-6.783c.046-.749.15-1.495.302-2.23.116-.558.26-1.11.432-1.652 1.134-3.574 3.48-6.816 6.753-9.125a19.012 19.012 0 013.912-2.114c4.323-1.718 8.872-1.716 12.813-.324.613.216 1.215.466 1.798.753a16.318 16.318 0 013.735 2.497 15.519 15.519 0 014.152 5.936c.294.74.526 1.487.698 2.244 1.818 7.906-2.762 16.37-11.041 19.658z"
        fill="#FFCBD9"
      />
      <path
        d="M106.794 62.957l-4.347 1.72c-3.638 1.439-5.44 5.6-4.005 9.249 1.434 3.648 5.584 5.455 9.222 4.017l4.347-1.72-5.217-13.266z"
        fill="#FFCBD9"
      />
      <path
        d="M105.826 75.3h-.008a.303.303 0 01-.296-.314 5.975 5.975 0 00-1.086-3.598 5.945 5.945 0 00-2.991-2.269.304.304 0 01-.191-.386.304.304 0 01.386-.192 6.543 6.543 0 013.295 2.5 6.573 6.573 0 011.195 3.963.306.306 0 01-.304.295zM143.429 75.434a6.152 6.152 0 01-2.115-.385.305.305 0 01.207-.574c2.165.79 4.302.253 6.348-1.6a.305.305 0 11.407.454c-1.538 1.395-3.185 2.105-4.847 2.105zM143.778 78.765c-.69 0-1.678-.16-2.874-.798a.305.305 0 01.286-.54c2.084 1.112 3.442.611 3.456.605a.305.305 0 01.223.568c-.032.014-.422.165-1.091.165z"
        fill="#1D232A"
      />
      <path
        d="M130.221 49.305a14.72 14.72 0 01-1.362 1.105c-1.479 1.064-3.318 1.99-5.383 2.653-4.197 1.347-8.247 1.25-10.69-.016-.14 1.606-.311 5.944-4.391 9.487-1.321 1.146-2.837 1.394-4.184 1.465.046-.749.15-1.495.302-2.23.116-.558.26-1.11.432-1.652 1.134-3.574 3.48-6.816 6.753-9.125a18.994 18.994 0 013.913-2.114c4.322-1.718 8.871-1.716 12.812-.324.613.214 1.215.466 1.798.75z"
        fill="#FE6A34"
      />
      <path
        d="M117.998 57.682c.856-.706 1.26-1.63.904-2.065-.357-.435-1.34-.215-2.196.49-.856.707-1.261 1.632-.904 2.066.357.435 1.34.216 2.196-.49zM127.119 56.555c.766-.803 1.058-1.77.652-2.16-.405-.388-1.355-.052-2.121.751s-1.058 1.77-.653 2.16c.406.388 1.356.053 2.122-.75z"
        fill="#FE6A34"
      />
      <path
        d="M169.735 98.221c3.63-8.783 8.261-22.533 12.772-25.162 4.677-2.725 8.456-12.806 10.96-16.907 2.536-4.155 5.904-3.56 7.392-1.286.805 1.231.682 3.136.436 4.634-.321 1.972-1.577 5.922-2.092 7.513 1.147-1.26 1.938-2.77 3.117-5.047 1.487-2.869 6.227-2.36 6.85 1.856.293 2.019-.27 4.43-.899 6.424 2.086-2.791 5.674-2.152 6.551.922.393 1.382.048 4.245-.268 5.792-3.3 16.1-11.1 19.098-18.472 30.015"
        fill="#FFCBD9"
      />
      <path
        d="M203.436 78a.304.304 0 01-.215-.521c.028-.029 2.798-2.85 4.774-7.357a.305.305 0 11.558.247c-2.025 4.619-4.784 7.425-4.902 7.541a.306.306 0 01-.215.09z"
        fill="#1D232A"
      />
      <path
        d="M197.728 104.491l-24.809 37.49a13.983 13.983 0 01-12.488 7.165 13.977 13.977 0 01-11.546-6.537l-16.333-32.07c-4.125-6.553-2.171-15.218 4.363-19.353 6.534-4.137 15.174-2.177 19.297 4.375l7.644 12.145 5.571-9.97 3.894-6.963c3.776-6.758 12.302-9.165 19.041-5.378 6.743 3.787 9.144 12.338 5.366 19.096z"
        fill="#FFCBD9"
      />
      <path
        d="M151.783 128.355a.308.308 0 01-.305-.289l-.888-16.229a.305.305 0 01.288-.322.31.31 0 01.32.289l.889 16.229a.307.307 0 01-.288.322h-.016z"
        fill="#1D232A"
      />
      <path d="M150.896 110.492l-.84 18.87h-4.426l-5.062-8.437 10.328-10.433z" fill="#1D232A" />
      <path d="M141.954 121.004L162.438 165h-61.074l1.313-47.107 39.277 3.111z" fill="#008E5A" />
      <path
        d="M167.138 65.507l3.169 5.326.648 1.086.345.58 12.193 20.476a4.076 4.076 0 005.599 1.414l14.204-8.508a4.097 4.097 0 001.41-5.612l-16.355-27.464a4.076 4.076 0 00-5.597-1.416l-7.43 4.452-6.774 4.056a4.097 4.097 0 00-1.412 5.61z"
        fill="#1D232A"
      />
      <path
        d="M167.138 65.507l3.169 5.326 5.489-9.268 2.391-4.041c-.483-.15-1.565-.816-2.865-1.685l-6.774 4.056a4.101 4.101 0 00-1.41 5.612zM170.957 71.92l.345.58c1.591-2.652 3.177-5.304 4.768-7.955.081-.14-.02-.338-.154-.397-.162-.067-.31.014-.396.154-.525.884-1.059 1.767-1.584 2.648-.993 1.654-1.985 3.31-2.979 4.97z"
        fill="#42EC9A"
      />
      <path
        d="M193.527 91.731l14.375-8.61a4.101 4.101 0 001.413-5.612l-16.356-27.465a4.075 4.075 0 00-5.596-1.417l-14.375 8.61a4.1 4.1 0 00-1.412 5.612l16.355 27.466a4.075 4.075 0 005.596 1.416z"
        fill="#00A868"
      />
      <path d="M189.463 87.333l16.503-9.882-14.572-24.472-16.502 9.883 14.571 24.471z" fill="#42EC9A" />
      <path d="M203.8 78.748l2.165-1.297-14.571-24.471-2.166 1.297L203.8 78.748z" fill="#fff" />
      <path
        d="M181.632 56.236a.854.854 0 000-1.71.854.854 0 000 1.71zM192.087 73.85c.085.13.262.161.385.068a4.513 4.513 0 001.055-6.073 4.484 4.484 0 00-5.975-1.442.27.27 0 00-.092.38l.923 1.41a.307.307 0 00.396.104 2.24 2.24 0 012.871.784c.63.962.431 2.228-.424 2.952a.31.31 0 00-.063.405l.924 1.412zM186.64 69.785a.281.281 0 00-.332.217l-.374 2.385a.572.572 0 00.083.401.563.563 0 00.333.236l2.33.605a.281.281 0 00.292-.43l-2.157-3.292a.261.261 0 00-.175-.122z"
        fill="#1D232A"
      />
      <path
        d="M187.285 98.79l-7.268 4.699-10.532-5.539c3.616-8.07.384-16.469-2.504-21.767-1.459-2.679-.024-5.839 2.642-5.908 2.141-.057 4.357 1.43 7.003 7.625.43 1.007.946 3.738 2.537 4.666.16.094.32.187.489.26 7.887 3.389 8.321 13.35 7.633 15.965z"
        fill="#FFCBD9"
      />
      <path
        d="M154.699 101.483l-1.417 11.196c-.793 6.26-6.495 10.688-12.735 9.894l-29.683-3.779 8.982-36.193 12.288 3.005 6.881 1.683 5.82 1.423c.676.085 1.329.23 1.958.427a11.288 11.288 0 014.846 3.02 11.409 11.409 0 013.06 9.324z"
        fill="#00A868"
      />
      <path
        d="M138.329 96.78a5.027 5.027 0 01.246 7.092 4.993 4.993 0 01-7.072.246l6.826-7.338zM124.653 97.94a5.019 5.019 0 013.21-6.322 5 5 0 016.305 3.219l-9.515 3.103zM129.882 101.969a5.003 5.003 0 01-6.11 3.58l1.295-4.947 4.815 1.367zM112.482 106.017l9.097.863c-.262 2.759-2.703 4.784-5.453 4.521a4.995 4.995 0 01-3.983-2.708l.339-2.676zM120.576 102.387l-7.023-4.817v-.002l.29-2.3a4.991 4.991 0 015.428.145 5.026 5.026 0 011.305 6.974zM134.634 121.82l-6.925-.881 6.931-2.369a4.99 4.99 0 01-.006 3.25zM129.772 109.405a4.997 4.997 0 017.028.834 5.03 5.03 0 01-.832 7.047l-6.196-7.881zM127.81 115.717c-2.749.267-5.195-1.754-5.46-4.511l5.077-.49.383 5.001zM154.788 100.095a4.987 4.987 0 01-3.476 2.503 5.003 5.003 0 01-5.817-4.04l8.869-1.617a11.32 11.32 0 01.424 3.154zM151.639 92.159a4.993 4.993 0 01-3.456 2.914l-1.39-5.934a11.288 11.288 0 014.846 3.02zM137.061 93.492l-4.927-7.886 6.881 1.683a5.023 5.023 0 01-1.954 6.203zM145.472 95.865a4.993 4.993 0 01-7.031-.79l3.985-3.19 3.046 3.98zM148.388 120.685a11.36 11.36 0 01-4.192 1.762 5.026 5.026 0 01-1.855-8.528l6.047 6.766zM153.816 108.467v.002l-.534 4.21a11.425 11.425 0 01-1.294 4.025 4.985 4.985 0 01-1.958.302l.458-10.025a4.972 4.972 0 013.328 1.486zM144.618 112.49a5.02 5.02 0 01-3.009-6.422l4.796 1.734-1.787 4.688z"
        fill="#42EC9A"
      />
      <path
        d="M138.329 96.78a5.027 5.027 0 01.246 7.092 4.993 4.993 0 01-7.072.246l6.826-7.338zM124.653 97.94a5.019 5.019 0 013.21-6.322 5 5 0 016.305 3.219l-9.515 3.103zM129.882 101.969a5.003 5.003 0 01-6.11 3.58l1.295-4.947 4.815 1.367zM112.482 106.017l9.097.863c-.262 2.759-2.703 4.784-5.453 4.521a4.995 4.995 0 01-3.983-2.708l.339-2.676zM120.576 102.387l-7.023-4.817v-.002l.29-2.3a4.991 4.991 0 015.428.145 5.026 5.026 0 011.305 6.974zM134.634 121.82l-6.925-.881 6.931-2.369a4.99 4.99 0 01-.006 3.25zM129.772 109.405a4.997 4.997 0 017.028.834 5.03 5.03 0 01-.832 7.047l-6.196-7.881zM127.81 115.717c-2.749.267-5.195-1.754-5.46-4.511l5.077-.49.383 5.001zM154.788 100.095a4.987 4.987 0 01-3.476 2.503 5.003 5.003 0 01-5.817-4.04l8.869-1.617a11.32 11.32 0 01.424 3.154zM151.639 92.159a4.993 4.993 0 01-3.456 2.914l-1.39-5.934a11.288 11.288 0 014.846 3.02zM137.061 93.492l-4.927-7.886 6.881 1.683a5.023 5.023 0 01-1.954 6.203zM145.472 95.865a4.993 4.993 0 01-7.031-.79l3.985-3.19 3.046 3.98zM148.388 120.685a11.36 11.36 0 01-4.192 1.762 5.026 5.026 0 01-1.855-8.528l6.047 6.766zM153.816 108.467v.002l-.534 4.21a11.425 11.425 0 01-1.294 4.025 4.985 4.985 0 01-1.958.302l.458-10.025a4.972 4.972 0 013.328 1.486zM144.618 112.49a5.02 5.02 0 01-3.009-6.422l4.796 1.734-1.787 4.688z"
        fill="#42EC9A"
      />
      <path
        d="M195.542 133.678c-.895.936-2.329 1.553-3.583 1.86-5.242 1.29-10.479 2.131-15.718 3.421 1.513 1.479 1.556 3.498.83 5.488-1.056 2.883-3.953 4.353-6.094 4.927-4.872 1.3-7.78 1.514-13.046 2.385l-13.139 2.104-26.939 4.316a13.982 13.982 0 01-11.242-3.488 14.054 14.054 0 01-4.612-10.857l1.562-47.851c.244-7.746 6.701-13.826 14.424-13.584 7.721.245 13.786 6.718 13.543 14.465l-1.047 31.293c4.214-.604 9.587-.985 14.005-1.552 4.158-.534 7.463-1.234 8.141-2.444.465-1.138 1.431-3.065 2.252-3.977 1.561-1.725 3.817-2.867 6.139-2.832 2.321.036 4.314 2.216 5.324 4.314 5.349-2.477 13.722-6.294 19.395-7.886 1.003-.279 2.033-.563 3.068-.441 2.864.34 3.318 4.09 1.948 5.561-.641.686-1.394 1.25-2.189 1.75-3.789 2.393-13.427 8.72-13.427 8.72s11.268-1.49 17.134-1.595c.948-.017 1.946.091 2.77.551 1.615.897 2.045 3.736.501 5.352z"
        fill="#FFCBD9"
      />
      <path
        d="M187.003 99.295a.307.307 0 01-.292-.393c1.857-6.255-1.98-11.991-2.019-12.048a.305.305 0 01.079-.424.303.303 0 01.422.08c.041.059 4.043 6.027 2.101 12.568a.304.304 0 01-.291.217zM175.137 129.879a.3.3 0 01-.286-.204.305.305 0 01.123-.36c.081-.053 8.216-5.232 11.871-7.727.434-.297.814-.553 1.149-.779 1.442-.977 2.039-1.38 2.538-1.915.651-.7.84-1.982.451-3.05-.216-.59-.792-1.604-2.239-1.777-.949-.112-3.005.253-4.062.548-5.089 1.428-11.459 4.391-16.577 6.772-.562.26-1.107.515-1.635.759a.295.295 0 01-.233.008.303.303 0 01-.171-.159c-.008-.02-.919-2.004-2.1-3.26-.938-.997-2.875-1.9-4.911-1.522-1.438.267-3.437 1.272-4.789 4.559-.9 2.185-2.406 3.551-4.478 4.059a.303.303 0 01-.367-.224.306.306 0 01.223-.368c1.899-.466 3.226-1.677 4.06-3.699 1.457-3.541 3.655-4.634 5.242-4.927 2.272-.421 4.383.553 5.464 1.703.993 1.054 1.778 2.538 2.08 3.148l1.362-.633c5.138-2.391 11.532-5.364 16.668-6.807.943-.262 3.171-.7 4.297-.565 1.293.152 2.289.946 2.738 2.173.473 1.292.235 2.806-.577 3.677-.547.584-1.16.999-2.642 2.003-.334.226-.712.482-1.146.779-2.843 1.941-8.387 5.501-10.763 7.021 1.81-.214 5.122-.601 7.679-.873a.304.304 0 01.065.606c-3.685.395-8.941 1.024-8.994 1.03-.016.004-.028.004-.04.004zM121.761 71.425a.304.304 0 01-.193-.541c1.258-1.038 1.1-2.605 1.098-2.62a.307.307 0 01.27-.337.306.306 0 01.337.268c.008.078.195 1.915-1.317 3.159a.304.304 0 01-.195.07z"
        fill="#1D232A"
      />
      <path
        d="M125.858 71.525c-1.562 0-2.638-.85-3.32-1.681a.305.305 0 01.471-.387c1.142 1.398 2.618 1.793 4.381 1.174a.77.77 0 01.207-.057.308.308 0 01.327.281.304.304 0 01-.25.323 5.249 5.249 0 01-1.816.346zm1.808-.344c-.008.002-.016.002-.024.002.006 0 .014 0 .024-.002z"
        fill="#1D232A"
      />
      <path
        d="M124.33 61.299a2.182 2.182 0 01-1.177 2.849l-4.833 2.008a2.173 2.173 0 01-2.843-1.18 2.183 2.183 0 011.177-2.85l4.833-2.01a2.175 2.175 0 012.843 1.183z"
        fill="#fff"
      />
      <path
        d="M120.444 60.552l.292 4.601-2.416 1.005a2.173 2.173 0 01-2.843-1.18 2.183 2.183 0 011.177-2.85l3.79-1.576z"
        fill="#1D232A"
      />
      <path
        d="M134.916 56.596c.23.556.213 1.154 0 1.669-.213.517-.621.95-1.177 1.18l-4.833 2.01a2.174 2.174 0 01-2.843-1.18 2.187 2.187 0 011.177-2.85l4.833-2.01a2.173 2.173 0 012.843 1.181z"
        fill="#fff"
      />
      <path
        d="M131.028 55.85l.293 4.6-2.415 1.005a2.173 2.173 0 01-2.843-1.18 2.187 2.187 0 011.177-2.85l3.788-1.576z"
        fill="#1D232A"
      />
      <path
        d="M127.29 66.993c-.318 0-.641-.033-.969-.096a.305.305 0 01.115-.6c.463.09.915.108 1.35.053.655-.082 1.262-.59 1.509-1.266.199-.543.12-1.069-.221-1.443l-2.749-3.022a.304.304 0 01.02-.431.302.302 0 01.43.02l2.75 3.022c.491.541.621 1.313.343 2.066-.325.883-1.131 1.55-2.007 1.66a4.072 4.072 0 01-.571.037zM70.98 56.488c.114 1.069 1.153 2.066 2.144 2.631.483.275 1.008.47 1.56.589.325.069.66.107.998.122.165.046.341.091.53.132 1.061.224 2.476.287 3.748-.44.868-.496 1.573-1.467 1.761-2.509.138-.134.264-.29.361-.464l-11.101-.06zm2.483 1.193c.213.083.432.154.653.215 1.203.595 2.61.788 3.92.503.084-.018.167-.039.248-.061.067-.006.132-.012.199-.02a4.444 4.444 0 01-1.382.342c-1.22.103-2.54-.21-4.036-.965-.053-.026-.108-.053-.162-.084a9.021 9.021 0 01-.14-.142c.233.081.464.15.7.212zm-.102 1.023a4.347 4.347 0 01-1.122-.918c.128.078.256.151.381.216.315.312.638.584.97.82a3.983 3.983 0 01-.229-.118zm2.388.643a5.96 5.96 0 01-1.368-.58c.979.324 1.892.446 2.765.37.265-.022.525-.066.779-.127-.28.1-.572.18-.87.238a5.915 5.915 0 01-1.306.1zm3.978-.244c-.62.356-1.374.53-2.194.521a6.19 6.19 0 002.064-.954c.428-.312.81-.68 1.134-1.091.136-.043.268-.096.396-.159-.26.706-.783 1.333-1.4 1.683z"
        fill="#1D232A"
      />
      <path
        d="M100.424 21.027a12.54 12.54 0 01-.152 1.612c-.02.122-.043.238-.065.356l-.511 2.645-.627 3.252-2.851 14.782c-1.26 6.528-7.514 10.928-14.032 9.709-6.567-1.227-10.87-7.582-9.602-14.147l3.595-18.622.422-2.198c.67-3.467 2.758-6.3 5.546-8.005a11.949 11.949 0 018.539-1.543c.069.015.142.027.211.045a11.92 11.92 0 015.032 2.389 12.084 12.084 0 014.495 9.725z"
        fill="#E09661"
      />
      <path
        d="M97.139 28.392l3.204.626c3.34.654 5.538 3.93 4.886 7.28-.651 3.349-3.918 5.553-7.258 4.9l-3.204-.627 2.372-12.18z"
        fill="#E09661"
      />
      <path
        d="M100.238 38.208a.308.308 0 01-.175-.055 3.454 3.454 0 01-1.4-3.388 3.444 3.444 0 012.449-2.723.305.305 0 11.164.588 2.835 2.835 0 00-2.013 2.239 2.84 2.84 0 001.151 2.786.304.304 0 01-.176.553z"
        fill="#1D232A"
      />
      <path
        d="M84.061 26.894c-.122 0-.246-.022-.365-.07-1.601-.618-2.624-1.631-2.666-1.674a1.018 1.018 0 010-1.439 1.013 1.013 0 011.432-.004c.022.02.791.765 1.964 1.22a1.018 1.018 0 01-.365 1.967zM91.425 26.172a1.02 1.02 0 01-.958-.68c-.01-.027-.38-1.034-1.24-1.956a1.019 1.019 0 01.049-1.438 1.012 1.012 0 011.435.048c1.172 1.256 1.651 2.615 1.672 2.672a1.018 1.018 0 01-.958 1.354z"
        fill="#8F5049"
      />
      <path
        d="M85.445 30.889a2.364 2.364 0 01-2.693 1.73 2.509 2.509 0 01-.207-.045l-4.992-1.335a2.376 2.376 0 01-1.68-2.91 2.362 2.362 0 011.104-1.445 2.36 2.36 0 011.8-.24l4.992 1.335a2.351 2.351 0 011.179.737c.495.573.708 1.379.497 2.173z"
        fill="#fff"
      />
      <path
        d="M85.445 30.889a2.364 2.364 0 01-2.693 1.73 2.508 2.508 0 01-.207-.045l-2.2-.589 1.222-4.595 2.2.588a2.352 2.352 0 011.179.737c.497.574.71 1.38.499 2.174z"
        fill="#1D232A"
      />
      <path
        d="M93.941 33.16a2.364 2.364 0 01-1.514 1.634c-.432.15-.913.177-1.388.05l-4.992-1.334a2.376 2.376 0 01-1.68-2.91 2.362 2.362 0 011.104-1.445 2.36 2.36 0 011.8-.24l4.992 1.335c.475.126.878.39 1.179.736.497.576.71 1.38.499 2.174z"
        fill="#fff"
      />
      <path
        d="M93.941 33.16a2.364 2.364 0 01-2.693 1.73 2.508 2.508 0 01-.207-.046l-2.2-.588 1.222-4.595 2.2.588c.069.019.136.041.203.066.388.14.72.376.976.671.497.574.71 1.38.5 2.174z"
        fill="#1D232A"
      />
      <path
        d="M85.098 37.663c2.591-.07 4.779.342 6.729 1.789 1.426 1.058.493 3.09-3.324 2.336.513.588.241 1.793-1.388 1.727-.688-.028-1.664-.39-2.161-.812-.254.373-.54.741-.934.994-.393.252-.925.372-1.363.205-.438-.167-.607-.55-.72-1.262-.729.41-1.583.692-2.413.656-.53-.025-1.244-.735-.784-1.79.837-1.918 3.572-3.768 6.358-3.843z"
        fill="#8F5049"
      />
      <path d="M83.894 32.293l-1.47 2.216c-.618.928-.202 2.21.878 2.706l1.5.69" fill="#E09661" />
      <path
        d="M84.802 38.212a.276.276 0 01-.126-.028l-1.5-.69a2.294 2.294 0 01-1.26-1.453 2.051 2.051 0 01.256-1.7l1.47-2.215a.304.304 0 01.423-.086c.14.094.178.283.085.424L82.68 34.68a1.428 1.428 0 00-.179 1.194c.132.466.471.853.93 1.064l1.5.69c.152.072.218.253.15.405a.305.305 0 01-.278.18zM86.778 45.976a.305.305 0 01-.004-.61c.122-.003 2.954-.072 3.326-2.842a.305.305 0 11.605.081c-.441 3.301-3.888 3.37-3.923 3.37h-.004z"
        fill="#1D232A"
      />
      <path
        d="M100.424 21.027a12.54 12.54 0 01-.152 1.612c-.02.122-.043.238-.065.356l-.511 2.645-.605 3.134-.39-.077c-3.088-.61-2.402-3.913-1.791-6.776-4.556-6.451-.986-10.621-.986-10.621l.003.002a12.08 12.08 0 014.497 9.725z"
        fill="#8F5049"
      />
    </g>
    <defs>
      <clipPath id="prefix__nps_modal">
        <path fill="#fff" d="M0 0h215v165H0z" />
      </clipPath>
    </defs>
  </svg>
)
