import { Fragment } from 'react'
import { Overline } from '@dlpco/ginga-stone'
import styled from 'styled-components'
import { theme } from 'styled-tools'

import * as Subject from '~/domains/platform/design-system/illustrations'

const StyledBox = styled.div`
  object-fit: contain;

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
`

const IllustrationDisplay = styled.div`
  display: flex;
  margin: 2rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  > * {
    margin: 0.5rem;
  }
`

const IllustrationGrid = styled.div`
  height: 50rem;
  overflow-y: scroll;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: ${theme('space.3')};
`

export function Illustrations() {
  return (
    <Fragment>
      <IllustrationGrid>
        {Object.keys(Subject as any)
          .sort()
          .map(key => {
            const Illustration = (Subject as any)[key]

            return (
              <IllustrationDisplay key={key}>
                <StyledBox>
                  <Illustration />
                </StyledBox>
                <Overline color="onColor">{key}</Overline>
              </IllustrationDisplay>
            )
          })}
      </IllustrationGrid>
    </Fragment>
  )
}
