export function UserPfIllustration() {
  return (
    <svg width="91" height="102" viewBox="0 0 91 102" fill="none">
      <g clipPath="url(#clip0)">
        <mask id="mask0" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="-1" y="0" width="92" height="103">
          <path
            d="M24.1275 4.8448C45.3821 -5.04178 60.9688 1.17264 76.2721 14.7314C103.195 38.7416 89.3083 90.4343 64.0862 99.7559C38.864 109.078 16.1924 87.3271 4.85661 71.791C-6.47917 56.255 2.87285 14.7314 24.1275 4.8448Z"
            fill="#F3FBF6"
          />
        </mask>
        <g mask="url(#mask0)">
          <path
            d="M24.1285 4.8448C45.3831 -5.04178 60.9698 1.17264 76.2731 14.7314C103.196 38.7416 89.3093 90.4343 64.0871 99.7559C38.865 109.078 16.1934 87.3271 4.85759 71.791C-6.4782 56.255 2.87383 14.7314 24.1285 4.8448Z"
            fill="#F3FBF6"
          />
          <g opacity="0.5">
            <path
              opacity="0.5"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M81.2978 1.85449C82.5104 0.125557 84.7605 -4.26534 86.4671 -4.88314C87.5663 -5.28105 87.2458 -1.1649 87.2594 -0.380038C87.3123 2.65647 87.0167 5.67372 86.6516 8.68473C85.9939 14.1151 83.8433 18.9512 80.8936 23.573C79.3758 25.9512 77.5954 28.3582 75.1296 29.8421C73.9946 30.5254 72.6289 30.6398 71.508 31.2286C70.2025 31.914 70.9135 31.3599 70.3251 32.7203C69.5081 34.609 69.2468 37.0455 68.8688 39.0608C67.1331 48.312 62.931 97.2164 63.6595 106.627C63.7242 107.461 62.5612 107.66 62.5031 106.642C62.1945 101.243 64.8649 63.8549 66.2121 51.628C67.5593 39.401 68.9226 28.5991 70.2896 24.369C71.948 19.2369 74.9431 13.0652 77.4364 8.27931C78.5945 6.0559 79.8568 3.90909 81.2978 1.85449Z"
              fill="#CCE4D5"
            />
            <path
              opacity="0.5"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M90.2985 61.8232C93.4537 59.5068 96.8689 63.8786 94.0138 66.1953C92.4355 67.4759 90.7854 67.0204 89.8564 65.9532C89.2534 66.6883 88.6889 67.4569 88.1466 68.1061C86.4942 70.0843 84.9325 72.1097 83.4399 74.1824C80.3393 78.4883 77.6821 83.0374 74.9039 87.5033C72.1621 91.911 69.4423 96.3412 66.9572 100.86C64.852 104.689 62.046 109.194 61.3252 113.391C62.4188 104.489 66.5685 95.9321 71.51 88.0839C76.4114 80.3002 82.0362 71.7995 89.6253 65.6567C88.8529 64.5209 88.8315 62.8995 90.2985 61.8232Z"
              fill="#EBF8F0"
            />
            <path
              opacity="0.5"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M30.4261 40.1114C32.1917 40.4567 35.0767 44.4509 36.5321 45.9727C38.2618 47.7811 39.831 49.7087 41.3102 51.7282C44.4946 56.0753 47.3424 60.6972 49.7641 65.517C53.712 73.3742 56.8748 81.4399 59.1702 89.9959C60.5753 95.2334 61.485 100.662 62.0242 106.052C62.126 107.069 63.1919 114.907 61.6561 115.118C60.2747 115.308 60.7014 109.167 60.6347 108.331C59.8815 98.906 57.6248 89.7354 54.4859 80.8517C53.8022 78.9164 53.167 76.5461 52.0736 74.8046C51.2862 73.5502 52.068 73.9877 50.6842 73.5129C49.496 73.1051 48.1423 73.2047 46.9256 72.7055C44.2824 71.6215 42.1659 69.5172 40.3107 67.4005C36.7054 63.287 33.8484 58.8368 32.3591 53.5658C31.533 50.6433 30.7738 47.7037 30.3525 44.6908C30.2435 43.9121 29.2888 39.889 30.4261 40.1114Z"
              fill="#F3FBF6"
            />
            <path
              opacity="0.5"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M37.3942 -26.3442C44.0449 -14.81 49.5103 -2.14006 53.0393 10.6927C55.1775 18.4679 56.6727 26.4464 58.1584 34.3673C60.4587 46.6318 62.6346 58.8954 63.2535 71.3777C63.8761 83.9324 65.1074 98.4128 60.9964 110.505C63.0499 98.2281 61.0949 85.1604 60.7487 72.8074C60.5833 66.9102 60.1039 61.0707 59.4751 55.2081C58.8649 49.521 57.868 43.8609 56.9396 38.2185C56.4874 35.4706 56.0125 32.7268 55.5289 29.9844C55.3846 29.1663 55.3236 26.7385 54.8046 26.1795C54.2822 25.6169 51.6171 25.3876 50.7803 24.9748C49.0954 24.1433 47.5105 22.9623 46.1079 21.7144C43.419 19.3222 41.0797 16.1965 39.2334 13.1153C34.9616 5.9859 33.2537 -2.7146 33.34 -10.9714C33.3566 -12.5745 35.9347 -29.8372 34.8993 -30.0749C34.9617 -30.148 35.0206 -30.2233 35.0762 -30.3018C35.8601 -28.9893 36.6294 -27.6679 37.3942 -26.3442Z"
              fill="#DAEDE1"
            />
            <path
              opacity="0.5"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M109.178 15.2544C109.28 15.1064 109.948 14.077 109.612 14.1428C110.297 14.0089 108.67 15.2781 110.376 14.4993C109.566 15.2644 110.034 16.2583 110.044 17.2881C110.062 18.96 109.782 20.6181 109.468 22.2541C108.97 24.8401 108.229 27.3545 107.153 29.7598C104.808 35.0015 100.826 40.0476 96.0989 43.3247C93.7652 44.9427 91.4781 45.4936 88.8166 46.2385C87.1354 46.7091 87.0021 47.5787 86.1097 49.2101C84.2519 52.6079 82.4272 55.9934 80.7887 59.5059C78.8323 63.7001 76.9332 67.8989 75.1417 72.167C69.1065 86.5437 64.1944 101.438 61.6147 116.852C61.5515 107.769 63.6529 98.4135 66.3035 89.7553C68.2343 83.4471 70.7479 77.3662 73.4289 71.3469C74.0786 69.8887 75.0631 68.0129 75.3389 66.4029C75.5321 65.2749 74.9415 64.19 74.6711 63.0017C73.6169 58.3743 73.6924 53.1901 74.6181 48.538C75.6388 43.4081 78.5906 41.0271 83.5841 40.3005C85.4103 40.0347 87.7314 40.1321 89.4059 39.3535C91.2303 38.5056 91.9829 35.7184 93.0203 33.9293C95.8067 29.1234 99.1174 24.5142 102.919 20.4647C103.972 19.3434 105.104 18.3089 106.305 17.35C107.062 16.7451 108.662 15.9997 109.178 15.2544Z"
              fill="#CCE4D5"
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M47.4617 40.1585C50.8233 41.6728 54.0103 42.2132 55.4078 41.6623C58.9122 40.2809 57.6147 26.9217 54.1716 22.0139C50.7285 17.1061 36.0522 16.5798 36.6 27.0716C36.7901 30.713 38.6917 33.7507 41.2403 36.1074L39.539 55.3165L51.137 53.8968L47.4617 40.1585Z"
            fill="#B28B67"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M55.5549 24.2378C55.3567 23.5713 54.9834 22.8499 54.678 22.2337C54.2843 21.4394 53.7507 20.7451 53.178 20.0993C52.0996 18.8834 50.8542 17.8187 49.5195 16.9719C47.0813 15.4252 44.1326 14.7218 41.4008 15.4018C40.0215 15.7451 38.7279 16.4489 37.6804 17.4848C36.734 18.4208 35.8741 19.7334 34.5309 19.9845C33.0695 20.2577 31.5593 19.1153 30.2793 18.4523C28.836 17.7048 27.338 17.2148 25.7499 17.1475C23.0894 17.0348 20.7483 18.2208 19.2146 20.5929C17.5825 23.1171 16.6035 26.6457 18.2308 29.5263C18.5332 30.0618 18.8918 30.5006 19.371 30.8419C19.8105 31.1549 20.4613 31.4656 20.6923 32.0234C20.9376 32.6164 20.526 33.4705 20.3967 34.0485C20.2113 34.8767 20.0813 35.738 20.2694 36.5905C20.5782 37.9904 21.6287 39.2343 22.6771 40.0509C23.7439 40.8819 24.9808 41.2819 26.2614 41.3793C27.1166 41.4444 27.9809 41.4322 28.8215 41.2656C29.2388 41.1829 29.596 41.0198 29.9926 40.8762C30.3782 40.7366 30.613 40.8155 30.9764 40.9932C32.6647 41.8187 34.3943 42.0064 36.1815 41.6341C37.6899 41.3199 39.4009 40.7334 40.5156 39.5308C41.7534 38.1953 41.529 36.59 40.9938 34.9111C41.359 35.0406 42.3242 35.0303 41.6715 34.4159C41.4129 34.1726 40.9735 34.1146 40.6648 33.9906C40.3053 33.8463 39.934 33.6484 39.6354 33.3778C38.4298 32.2852 39.192 29.6681 40.4691 29.0958C42.3665 28.2457 43.1513 30.9968 44.6478 31.5502C45.5083 31.8683 46.2851 31.1944 46.8202 30.5379C47.537 29.6584 47.9641 28.5573 48.3134 27.4472C48.6011 26.533 48.86 25.61 49.1558 24.6992C49.2939 24.2738 49.8034 23.0569 49.4219 22.6417C50.7996 22.1855 52.445 22.5834 53.7561 23.2087C54.2981 23.4673 54.7246 23.7732 55.0619 24.3055C55.1342 24.4195 55.3033 24.7641 55.4622 24.7596C55.7579 24.7513 55.6046 24.4053 55.5549 24.2378Z"
            fill="#271313"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M92.9757 94.6935L78.7254 83.2157L75.1102 91.1228L89.4293 97.8245C93.3394 102.856 95.5565 104.98 96.0807 104.196C96.5228 103.535 96.2292 102.884 95.9589 102.285C95.7482 101.818 95.5517 101.383 95.7278 100.997C96.1301 100.118 98.8974 100.336 101.515 100.655C104.132 100.974 103.419 99.7709 102.811 99.1655C100.202 97.4408 96.9233 95.9502 92.9757 94.6935ZM17.8943 120.505C18.982 117.64 21.0308 90.529 21.0308 90.529L30.6517 89.6496C30.6517 89.6496 24.4875 119.261 23.9454 121.088C23.2412 123.462 25.1245 126.634 26.4016 128.785C26.5991 129.118 26.7822 129.427 26.9389 129.704C25.5279 130.504 24.7438 129.774 23.9172 129.005C22.9836 128.136 21.9956 127.216 19.9888 128.393C19.2134 128.848 18.4883 129.368 17.7798 129.876C15.3324 131.629 13.0842 133.24 9.65563 131.551C9.11208 131.283 8.43577 130.143 9.70602 129.082C12.8707 126.436 17.3626 121.906 17.8943 120.505Z"
            fill="#B28B67"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M44.512 47.5571L48.4264 46.4948C56.9107 62.9611 70.3562 77.3757 88.7631 89.7387L77.3575 106.646C58.3871 88.3527 45.9045 68.8027 44.512 47.5571Z"
            fill="#129943"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M33.4143 105.77L72.3182 100.13C72.3182 100.13 50.8891 68.7342 50.2041 46.1445L39.3627 45.5709C32.2508 59.8867 32.9442 83.2877 33.4143 105.77Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.2761 115.961C16.9804 71.8586 39.1272 45.5934 39.1272 45.5934L39.1365 45.6002C39.1376 45.5975 39.1388 45.5949 39.1399 45.5923L39.8377 45.5258C41.0942 45.4495 43.6676 45.3174 43.6676 45.3174L46.9548 52.4543C48.6525 65.9183 56.9322 106.422 56.9322 106.422L33.7368 108.634C33.9552 110.507 34.2241 112.401 34.5482 114.314L17.2761 115.961Z"
            fill="#14AA4B"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M33.7585 108.815C32.7941 100.662 32.7871 92.9209 33.3339 85.8627C34.7519 94.0408 37.0188 103.828 40.2207 108.199L33.7585 108.815Z"
            fill="black"
            fillOpacity="0.1"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.5294 94.0558L48.4757 87.637L48.9837 92.9634L37.5294 94.0558Z"
            fill="white"
            fillOpacity="0.2"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="91" height="102" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
