export function OnboardingPiggyIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="77" height="64" viewBox="0 0 77 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_2001_82199)">
        <path
          d="M15.0996 10.9892L23.9314 10.6651L24.2292 10.654C24.2195 10.6166 24.2098 10.5792 24.2015 10.5432C23.8275 9.05553 23.1904 7.50415 22.4105 6.07742C22.254 5.78792 22.0905 5.50535 21.9215 5.22832C20.5932 3.04807 18.9628 1.28198 17.5098 0.689129C16.7577 0.384393 15.7797 0.630952 15.7035 1.58533L15.1744 9.83815L15.1522 10.1969L15.0996 10.9892Z"
          fill="#00A868"
        />
        <path
          d="M15.0996 10.9893L23.9314 10.6651L24.2292 10.654C24.2195 10.6166 24.2098 10.5792 24.2015 10.5432C23.8275 9.05556 23.1904 7.50418 22.4105 6.07745L15.1522 10.1969L15.0996 10.9893Z"
          fill="#1D232A"
        />
        <path
          d="M15.3534 10.4892C15.2855 10.4892 15.219 10.4559 15.1789 10.395C15.1165 10.298 15.1442 10.1706 15.2398 10.1069L20.2957 6.82267L15.6373 8.58043C15.5376 8.61783 15.4254 8.57351 15.3783 8.47932C15.3312 8.38374 15.3631 8.26739 15.4531 8.2106C15.4836 8.1912 18.5684 6.24644 20.9314 5.43335C21.0395 5.39734 21.1586 5.45413 21.196 5.56217C21.2334 5.67022 21.1752 5.78934 21.0672 5.82674C20.0311 6.18272 18.8495 6.76449 17.8564 7.29916L21.9509 5.75333C22.0493 5.71593 22.1615 5.75887 22.21 5.85444C22.2584 5.95002 22.2266 6.06499 22.1379 6.12316L15.4684 10.4559C15.431 10.4781 15.3922 10.4892 15.3534 10.4892Z"
          fill="#1D232A"
        />
        <path
          d="M73.6412 29.6141C73.4071 29.6141 73.1772 29.5463 72.986 29.4174C71.7338 28.5669 71.7546 26.7662 72.4929 25.4669C71.9347 25.2661 71.3197 25.2259 70.7684 25.3811C69.7766 25.6622 69.1006 26.6346 69.3236 27.4644C69.3527 27.5752 69.2876 27.6887 69.1768 27.7192C69.066 27.7483 68.9524 27.6832 68.922 27.5724C68.638 26.5141 69.4317 25.327 70.6548 24.9821C71.3169 24.7951 72.0593 24.8533 72.7215 25.1123C73.0497 24.6649 73.4404 24.3643 73.8864 24.2189C74.3615 24.0638 74.9433 24.0832 75.5223 24.2757C76.0846 24.4613 76.7689 24.9738 76.9878 25.5916C77.0265 25.6996 76.9698 25.8188 76.8617 25.8576C76.7537 25.8963 76.6345 25.8395 76.5958 25.7315C76.4295 25.2619 75.8519 24.8228 75.3907 24.6705C74.8948 24.507 74.4058 24.4862 74.0138 24.6151C73.6745 24.7259 73.3697 24.953 73.1051 25.2924C73.403 25.4545 73.6758 25.6595 73.9058 25.9033C74.7715 26.8244 75.0762 28.0226 74.6801 28.9534C74.554 29.2484 74.2978 29.4728 73.9764 29.5684C73.8684 29.5975 73.7534 29.6141 73.6412 29.6141ZM72.8752 25.6345C72.2103 26.7704 72.1563 28.3495 73.2187 29.0725C73.4002 29.1958 73.6398 29.2304 73.8614 29.1667C74.0664 29.1058 74.223 28.9714 74.3005 28.7886C74.7022 27.8411 74.1773 26.7939 73.6052 26.1845C73.3974 25.9628 73.1481 25.7786 72.8752 25.6345Z"
          fill="#1D232A"
        />
        <path
          d="M45.6071 62.0438L50.6117 63.0037C51.26 63.1284 51.9069 62.7779 52.1548 62.1657L58.3257 47.0188L46.3468 44.7222L44.4907 60.5229C44.4076 61.2432 44.8938 61.908 45.6071 62.0438Z"
          fill="#008E5A"
        />
        <path
          d="M50.9527 60.0145C50.9319 60.0145 50.9112 60.0117 50.8904 60.0048L45.0727 58.1916C44.9674 58.1583 44.9065 58.0489 44.9328 57.9423C44.9605 57.8356 45.0672 57.7677 45.1738 57.7899L47.6117 58.2678L45.0242 57.5503C44.9176 57.5212 44.8525 57.4131 44.8774 57.3051C44.9009 57.197 45.0076 57.1278 45.1156 57.1458L53.2479 58.5407C53.3615 58.56 53.4363 58.6681 53.4169 58.7803C53.3975 58.8925 53.2909 58.9701 53.1773 58.9493L49.1091 58.2512L52.9169 59.308C53.0249 59.3371 53.0886 59.4479 53.0637 59.556C53.0388 59.664 52.9321 59.7347 52.8213 59.7125L48.8403 58.9327L51.0123 59.61C51.1217 59.6446 51.1826 59.761 51.1494 59.8704C51.1231 59.9563 51.0414 60.0145 50.9527 60.0145Z"
          fill="#1D232A"
        />
        <path d="M53.8585 57.9879L55.9141 52.9404L45.47 52.4293L44.8633 57.3341L53.8585 57.9879Z" fill="#1D232A" />
        <path
          d="M4.76254 52.5747L7.85977 56.4587C8.27116 56.9754 8.99006 57.1291 9.57737 56.8258L24.1161 49.332L16.5101 39.7966L4.8969 50.7006C4.37054 51.1951 4.31236 52.0096 4.76254 52.5747Z"
          fill="#008E5A"
        />
        <path
          d="M12.7241 54.8298C12.6659 54.8298 12.6091 54.8062 12.5675 54.7591C12.49 54.6705 12.4138 54.5804 12.3362 54.4876C11.7281 53.7798 10.9912 52.9639 10.2751 52.173C9.46338 51.2754 8.62535 50.3487 7.97432 49.5758C7.90091 49.4885 7.91199 49.3569 7.99926 49.2835C8.08652 49.2087 8.21812 49.2212 8.29153 49.3085C8.8262 49.9429 9.49109 50.6853 10.1629 51.4292C10.0978 51.3308 10.0327 51.2325 9.96897 51.1327C9.3027 50.1174 8.67245 49.1589 8.06021 48.3748C7.99233 48.2876 8.0048 48.1629 8.08791 48.0895C8.17102 48.0175 8.29707 48.023 8.37325 48.102C8.89823 48.6463 9.56866 49.4026 10.2557 50.2033C9.56727 49.2406 8.901 48.3208 8.41896 47.6781C8.34971 47.5867 8.3691 47.4565 8.46052 47.3872C8.55194 47.318 8.68215 47.3373 8.75141 47.4288C10.2917 49.4844 13.7131 54.3671 13.7463 54.4156C13.81 54.507 13.7907 54.6345 13.7006 54.7009C13.6106 54.7688 13.4845 54.7522 13.4139 54.6649C13.3903 54.6358 11.8833 52.7589 10.3416 50.943C11.102 52.101 11.886 53.2908 12.6534 54.2175C12.7324 54.3089 12.8086 54.399 12.882 54.4862C12.9554 54.5735 12.9457 54.7037 12.8584 54.7771C12.821 54.8131 12.7726 54.8298 12.7241 54.8298Z"
          fill="#1D232A"
        />
        <path d="M8.70703 47.1227L13.6881 54.7065L18.2758 52.342L10.7765 45.1793L8.70703 47.1227Z" fill="#1D232A" />
        <path
          d="M33.0531 56.7744H46.7496C60.014 56.7744 70.7657 46.0214 70.7657 32.7584C70.7657 19.4941 60.0126 8.74243 46.7496 8.74243H33.0531C19.7888 8.74243 9.03711 19.4955 9.03711 32.7584C9.03711 46.0214 19.7888 56.7744 33.0531 56.7744Z"
          fill="#00A868"
        />
        <path
          d="M30.0196 56.5848L44.4128 8.74113C39.0924 8.74113 34.84 8.06933 30.8133 7.49448C17.6667 5.73394 5.5811 14.9633 3.82056 28.1099C2.06001 41.2565 10.7727 55.0445 30.0196 56.5848Z"
          fill="#00A868"
        />
        <path
          d="M22.4845 37.8338C22.436 37.8338 22.3875 37.8171 22.3487 37.7825C20.5106 36.1896 20.8015 33.922 20.814 33.8251C20.8292 33.7115 20.9345 33.6325 21.0481 33.6478C21.1617 33.663 21.2406 33.7683 21.2254 33.8819C21.2212 33.9082 20.9594 36.0275 22.6202 37.4681C22.7075 37.5429 22.7158 37.6745 22.641 37.7617C22.6008 37.8088 22.5427 37.8338 22.4845 37.8338Z"
          fill="#1D232A"
        />
        <path
          d="M17.204 37.964C16.2039 37.964 15.3174 37.6773 14.7841 37.2811C14.6913 37.2132 14.6733 37.083 14.7411 36.9902C14.809 36.8974 14.9392 36.8794 15.032 36.9473C16.19 37.8061 19.1931 38.0804 21.0547 35.5691C21.124 35.4777 21.2542 35.4583 21.3456 35.5261C21.4384 35.594 21.4564 35.7242 21.3885 35.817C20.2084 37.4072 18.5974 37.964 17.204 37.964Z"
          fill="#1D232A"
        />
        <path
          d="M12.3977 21.1619C13.2866 21.1619 14.0072 20.4413 14.0072 19.5524C14.0072 18.6634 13.2866 17.9428 12.3977 17.9428C11.5087 17.9428 10.7881 18.6634 10.7881 19.5524C10.7881 20.4413 11.5087 21.1619 12.3977 21.1619Z"
          fill="#1D232A"
        />
        <path
          d="M22.0217 25.4199C22.9106 25.4199 23.6312 24.6992 23.6312 23.8103C23.6312 22.9214 22.9106 22.2007 22.0217 22.2007C21.1327 22.2007 20.4121 22.9214 20.4121 23.8103C20.4121 24.6992 21.1327 25.4199 22.0217 25.4199Z"
          fill="#1D232A"
        />
        <path
          d="M3.7804 32.505L8.86812 34.4927C11.9238 35.6868 15.3673 34.1769 16.5613 31.1213C17.7553 28.0656 16.2455 24.6221 13.1898 23.428L8.10212 21.4403C5.04644 20.2463 1.60291 21.7561 0.408899 24.8118C-0.785115 27.8661 0.724718 31.311 3.7804 32.505Z"
          fill="#00A868"
        />
        <path
          d="M11.0216 35.1064C10.2792 35.1064 9.52428 34.9707 8.79291 34.6853L3.7052 32.6976C3.59854 32.6561 3.5459 32.5356 3.58746 32.4289C3.62901 32.3223 3.74952 32.2696 3.85618 32.3112L8.94389 34.2989C11.8874 35.4486 15.2187 33.9886 16.3684 31.0451C17.0125 29.3982 16.8657 27.5767 15.9681 26.0502C15.9099 25.9519 15.9431 25.8245 16.0415 25.7663C16.1398 25.7081 16.2673 25.7414 16.3254 25.8397C17.2881 27.477 17.4447 29.43 16.7548 31.1961C15.8074 33.6215 13.4803 35.1064 11.0216 35.1064Z"
          fill="#1D232A"
        />
        <path
          d="M10.3395 31.1615C10.8769 31.372 11.4836 31.1061 11.6942 30.5673L12.6084 28.2277C12.8189 27.6903 12.553 27.0836 12.0141 26.873C11.4767 26.6625 10.87 26.9285 10.6594 27.4673L9.74523 29.8068C9.53607 30.3442 9.80202 30.9509 10.3395 31.1615Z"
          fill="#008E5A"
        />
        <path
          d="M4.9547 29.0589C5.49214 29.2694 6.09885 29.0034 6.30939 28.4646L7.2236 26.1251C7.43415 25.5876 7.1682 24.9809 6.62937 24.7704C6.09192 24.5598 5.48522 24.8258 5.27467 25.3646L4.36046 27.7042C4.1513 28.2416 4.41726 28.8483 4.9547 29.0589Z"
          fill="#008E5A"
        />
        <path
          d="M32.4355 14.9688C33.3927 10.7662 32.7015 5.13693 30.8661 3.05363C30.3301 2.44416 29.3438 2.22946 28.8507 3.04948L24.1162 11.1984"
          fill="#00A868"
        />
        <path
          d="M24.8733 10.0999C24.8373 10.0999 24.8013 10.0902 24.7694 10.0722C24.6697 10.014 24.6365 9.88796 24.6946 9.78823L26.4788 6.71732C26.5369 6.61759 26.663 6.58434 26.7627 6.64252C26.8625 6.7007 26.8957 6.82674 26.8375 6.92648L25.0534 9.99738C25.0146 10.0625 24.9454 10.0999 24.8733 10.0999Z"
          fill="#1D232A"
        />
        <path
          d="M32.4358 15.1766C32.4206 15.1766 32.4054 15.1752 32.3901 15.1711C32.2779 15.1461 32.2087 15.0339 32.2336 14.9217C32.6256 13.1972 32.9539 11.1666 32.2918 7.0166C32.2738 6.90302 32.3513 6.79636 32.4649 6.77835C32.5785 6.76035 32.6852 6.83792 32.7032 6.9515C33.3777 11.1749 33.0411 13.2498 32.6394 15.0145C32.6173 15.1115 32.5314 15.1766 32.4358 15.1766Z"
          fill="#1D232A"
        />
        <path
          d="M60.3684 63.246L64.8245 62.667C65.4173 62.5894 65.8731 62.106 65.9132 61.5104L65.1403 47.4482H54.1074L58.9804 62.2639C59.1176 62.9025 59.7201 63.3291 60.3684 63.246Z"
          fill="#00A868"
        />
        <path
          d="M32.2512 62.9593H27.1552C26.4944 62.9593 25.9265 62.4939 25.7963 61.8456L21.2031 45.8068H33.3995L33.6308 61.6739C33.5795 62.3997 32.977 62.9593 32.2512 62.9593Z"
          fill="#00A868"
        />
        <path
          d="M35.7502 12.5558C38.835 13.229 42.9905 13.3786 45.6597 13.0586C45.7816 13.0448 45.8731 12.9035 45.8703 12.74L45.8426 10.8576C45.8398 10.6831 45.7304 10.5445 45.6016 10.5487C42.8756 10.6415 38.7574 10.503 35.8126 9.90459C35.6367 9.86858 35.4774 9.90182 35.4815 10.1179L35.512 12.2178C35.5148 12.384 35.6145 12.5267 35.7502 12.5558Z"
          fill="#008E5A"
        />
        <path
          d="M35.7501 12.5558C38.8348 13.229 42.9903 13.3786 45.6595 13.0587C45.7814 13.0448 45.8729 12.9035 45.8701 12.7401L45.8549 11.7012C45.8521 11.5267 45.7427 11.3895 45.6138 11.3951C42.8892 11.5142 38.7697 11.4172 35.8263 10.8493C35.6503 10.8147 35.491 10.8493 35.4952 11.0654L35.5118 12.2179C35.5146 12.3841 35.6143 12.5268 35.7501 12.5558Z"
          fill="#1D232A"
        />
        <path
          d="M24.0191 55.8492C23.9291 55.8492 23.846 55.7897 23.8197 55.6982L22.2572 50.2434C22.2254 50.1326 22.2891 50.0177 22.3999 49.9858C22.5093 49.9539 22.6257 50.0177 22.6575 50.1285L24.22 55.5833C24.2519 55.6941 24.1881 55.8091 24.0773 55.8409C24.0579 55.8465 24.0385 55.8492 24.0191 55.8492Z"
          fill="#1D232A"
        />
        <path
          d="M56.7224 55.6386C56.6338 55.6386 56.552 55.5818 56.5243 55.4932L55.0048 50.6714C54.9702 50.562 55.0311 50.4456 55.1406 50.411C55.25 50.3764 55.3663 50.4373 55.401 50.5468L56.9205 55.3685C56.9551 55.4779 56.8942 55.5943 56.7847 55.6289C56.764 55.6345 56.7432 55.6386 56.7224 55.6386Z"
          fill="#1D232A"
        />
      </g>
      <defs>
        <clipPath id="clip0_2001_82199">
          <rect width="77" height="62.6904" fill="white" transform="translate(0 0.565918)" />
        </clipPath>
      </defs>
    </svg>
  )
}
