export function RedirectToMobileIllustration() {
  return (
    <svg width="276" height="278" viewBox="0 0 296 298" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M147.257 293.054C209.932 293.054 260.739 242.308 260.739 179.711C260.739 117.113 209.932 66.3672 147.257 66.3672C84.5827 66.3672 33.775 117.113 33.775 179.711C33.775 242.308 84.5827 293.054 147.257 293.054Z"
        fill="#E8FEF3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.8866 113.185L70.6186 119.971L63.8451 131.723L52.1131 124.938L58.8866 113.185Z"
        fill="#A3F5CC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.3326 106.124L64.8544 96.5437L55.2903 91.0124L49.7685 100.593L59.3326 106.124Z"
        fill="#A3F5CC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.1028 120.038L44.6246 110.458L35.0605 104.926L29.5387 114.507L39.1028 120.038Z"
        fill="#42EC9A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M248.468 221.265L261.483 234.302L248.468 247.34L235.453 234.302L248.468 221.265Z"
        fill="#0FCC7D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M233.594 245.105L240.659 252.182L233.594 259.26L226.528 252.182L233.594 245.105Z"
        fill="#A3F5CC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M258.88 245.105L265.945 252.182L258.88 259.26L251.815 252.182L258.88 245.105Z"
        fill="#A3F5CC"
      />
      <mask id="mask0" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="33" y="34" width="228" height="260">
        <path
          d="M147.257 293.054C209.932 293.054 260.739 242.22 260.739 179.513V34.27H33.775V179.513C33.775 242.22 84.5827 293.054 147.257 293.054Z"
          fill="white"
        />
        <path
          d="M147.388 293.054C210.062 293.054 260.87 242.22 260.87 179.513V34.27H33.9053V179.513C33.9053 242.22 84.7131 293.054 147.388 293.054Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M112.048 38.1057C122.592 33.5398 134.73 37.3584 140.691 46.6117C149.515 57.3118 166.171 82.7525 163.611 88.9827L135.253 101.263L135.179 101.119C124.157 104.532 112.069 99.3467 107.317 88.7511L98.2999 68.6436C93.2013 57.2741 98.4809 43.981 110.092 38.9527L112.048 38.1057Z"
          fill="#42EC9A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M134.855 43.2147C145.141 51.0858 156.218 77.5291 149.737 82.5245C147.153 84.5166 140.136 85.3947 132.144 84.3949L148.44 110.133L125.021 120.236L116.569 79.997C109.754 76.7736 103.869 71.7592 101.192 64.4501C93.4809 43.3897 124.569 35.3435 134.855 43.2147Z"
          fill="#825D3B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M110.905 39.898C122.235 34.9873 128.127 38.6037 133.137 41.6704C134.291 50.3138 125.963 55.2789 120.25 63.4547C118.995 63.1183 117.617 63.1881 116.331 63.7453C113.359 65.0334 112.008 68.4387 113.313 71.3512C114.618 74.2637 118.085 75.5805 121.057 74.2923C121.14 74.2567 121.22 74.2196 121.3 74.1808C127.485 85.1009 132.88 98.2947 131.039 102.78L102.664 115.078C89.0213 88.845 108.971 92.6466 97.6145 67.3029C98.1583 65.9781 98.6538 64.6854 99.1955 63.5209L98.8302 62.7888C99.3265 50.8021 103.351 43.1719 110.905 39.898Z"
          fill="#42EC9A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M138.496 196.406L169.275 328.919L181.646 427.944L199.722 428.732L194.829 198.863L138.496 196.406Z"
          fill="#B28B67"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M138.496 196.406L169.275 328.919L181.646 427.944L199.722 428.732L194.829 198.863L138.496 196.406Z"
          fill="black"
          fillOpacity="0.1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M120.321 195.389C119.063 263.505 115.856 300.547 114.897 306.699C113.939 312.851 108.133 354.078 80.7116 425.49L99.5873 426.314C134.371 358.177 147.505 317.27 151.562 308.3C155.618 299.33 168.186 262.697 187.166 198.308L120.321 195.389Z"
          fill="#B28B67"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M137.595 196.365C144.978 241.833 158.093 314.47 176.941 414.275L203.438 415.432C211.243 314.176 206.767 247.113 195.097 198.875L137.595 196.365Z"
          fill="#2E3742"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M120.161 195.39C118.836 263.519 108.118 330.227 82.2352 411.08L110.605 412.32C145.455 345.21 171.457 279.501 191.328 198.499L120.161 195.39Z"
          fill="#252C37"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M86.927 199.012L108.857 195.026C104.207 241.339 101.615 265.935 101.08 268.816C99.8757 275.296 106.872 283.525 110.068 288.052C103.568 292.59 101.92 280.565 93.7524 286.45C86.2972 291.822 81.4448 299.947 70.7382 295.829C69.4219 295.323 67.5877 292.836 70.2527 290.131C76.8922 283.392 86.1276 272.041 87.0102 268.706C88.2138 264.158 88.1861 240.927 86.927 199.012ZM215.175 174.291L253.616 192.703C263.185 193.471 271.383 195.116 278.21 197.638C279.914 198.685 282.191 201.022 276.136 201.671C270.08 202.319 263.744 203.274 263.335 205.467C262.926 207.659 267.028 209.427 265.923 212.488C265.186 214.528 259.016 210.902 247.411 201.609L211.497 193.993L215.175 174.291Z"
          fill="#825D3B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M132.006 98.0485L140.686 94.8523C179.919 117.393 204.888 170.002 251.758 191.66L244.395 205.627C171.647 209.979 140.321 145.97 132.006 98.0485Z"
          fill="#008E5A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M115.335 218.355C151.349 211.725 177.84 206.848 194.808 203.724C198.44 203.055 196.687 197.984 195.622 195.457C183.338 166.303 152.976 138.027 144.114 94.9388L120.458 95.596C107.714 129.313 108.811 167.736 115.335 218.355Z"
          fill="#C7D1D9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M118.121 96.3598C118.893 96.2176 119.611 96.1353 120.274 96.1128L127.524 95.2003C162.254 160.456 177.383 205.473 172.91 230.251L110.481 241.737C108.539 250.967 106.477 258.81 104.296 265.269L87.2854 268.398C75.144 217.38 78.5049 171.565 97.3679 130.952C93.8509 130.618 90.7656 128.893 88.1127 125.78C76.6781 112.362 78.0065 103.571 86.387 99.2202C94.7675 94.8693 103.882 98.979 118.121 96.3598Z"
          fill="#0FCC7D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M133.145 237.189L109.948 241.459C112.215 230.679 114.319 218.012 116.261 203.457C121.579 219.397 127.207 230.642 133.145 237.189Z"
          fill="black"
          fillOpacity="0.1"
        />
      </g>
      <path
        d="M222.679 126.633C238.023 128.713 252.146 117.939 254.222 102.568C256.299 87.1971 245.543 73.0504 230.198 70.9704C214.854 68.8905 200.731 79.665 198.655 95.0358C196.579 110.407 207.335 124.553 222.679 126.633Z"
        fill="#42EC9A"
      />
      <path
        d="M208.262 112.017C208.383 111.201 208.507 110.381 208.632 109.555C209.085 106.545 209.556 103.433 209.951 100.37C211.395 89.1192 212.824 80.3298 214.571 71.8672C215.148 69.0357 216.147 65.5911 218.708 64.0582C220.931 62.722 225.167 62.9245 227.199 63.0228L227.552 63.0402C230.13 63.153 238.367 64.547 240.683 65.1949C241.09 65.3077 241.5 65.4147 241.91 65.5188C245.432 66.4327 249.417 67.4653 249.217 73.0993C249.047 77.6921 248.325 82.4556 247.629 87.0657C247.539 87.6442 247.451 88.2226 247.367 88.801C246.619 93.8103 245.6 99.7509 244.615 105.501C243.948 109.396 243.316 113.078 242.779 116.41C242.51 118.073 241.145 125.344 239.851 127.941C238.558 130.538 235.639 131.852 233.341 131.852L233.147 131.852C227.687 131.672 221.463 130.88 218.004 129.928L217.316 129.743C213.979 128.847 208.943 127.496 207.852 122.761C207.252 120.173 207.745 115.49 208.262 112.017Z"
        fill="#1A4E66"
      />
      <path
        d="M228.62 65.461C230.947 65.7184 236.127 66.4501 238.318 67.0517C242.048 68.0755 245.34 68.3098 245.172 73.5678C245.022 78.2503 243.989 84.107 243.296 88.7721C242.167 96.3642 240.232 106.796 239.008 114.391C238.679 116.439 237.464 123.175 236.332 124.803C235.2 126.432 233.352 126.576 232.053 126.539C228.935 126.437 223.328 126.021 219.952 125.092C216.88 124.251 211.747 123.357 210.425 119.678C209.654 117.524 210.624 112.965 210.956 110.727C211.473 107.256 212.03 103.661 212.483 100.11C213.598 91.3693 215.151 81.7007 216.944 73.0096C217.443 70.5888 218.31 67.5491 220.559 66.2563C222.658 65.0618 227.09 65.2932 228.62 65.461Z"
        fill="white"
      />
      <path
        d="M231.158 129.604C233.716 129.604 235.676 129.069 237.097 127.993C239.158 126.432 240.022 121.992 240.596 119.054L240.7 118.516C241.321 115.369 241.976 111.574 242.611 107.907C242.9 106.215 243.189 104.575 243.452 103.086C244.35 98.0359 246.587 85.154 247.933 74.7623C248.195 72.7378 248.334 70.8983 247.384 68.7234C247.256 68.4303 246.914 68.2969 246.622 68.4255C246.329 68.5541 246.196 68.8959 246.324 69.189C247.084 70.9243 247.078 72.3704 246.786 74.6119C245.444 84.992 243.212 97.8392 242.314 102.883C242.048 104.373 241.765 106.015 241.471 107.71C240.839 111.372 240.186 115.161 239.568 118.29L239.461 118.831C238.956 121.434 238.11 125.772 236.401 127.071C234.559 128.468 231.55 128.806 227.202 128.089C226.758 128.016 226.209 127.935 225.58 127.843C221.185 127.195 212.041 125.85 209.948 122.666C209.836 122.489 209.638 122.387 209.43 122.398C209.222 122.41 209.036 122.532 208.943 122.72C208.851 122.907 208.866 123.13 208.984 123.302C211.363 126.92 220.501 128.265 225.412 128.985L227.015 129.228C228.383 129.466 229.769 129.591 231.158 129.604Z"
        fill="#0D323F"
      />
      <path
        d="M246.983 88.2226C247.26 88.2288 247.502 88.037 247.56 87.7656C247.696 87.1467 247.91 85.7845 248.068 84.7491C248.11 84.5399 248.034 84.3246 247.87 84.1887C247.706 84.0528 247.48 84.0185 247.283 84.0994C247.086 84.1803 246.95 84.3633 246.928 84.5755C246.706 86.0361 246.532 87.0542 246.431 87.5227C246.394 87.6958 246.438 87.8763 246.551 88.0128C246.663 88.1493 246.832 88.2266 247.009 88.2226L246.983 88.2226Z"
        fill="#0D323F"
      />
      <path
        d="M247.78 83.2625C248.068 83.2655 248.315 83.0543 248.357 82.7679C248.565 81.3421 248.761 79.9133 248.836 79.2973C248.862 79.0906 248.775 78.8862 248.609 78.7609C248.443 78.6356 248.223 78.6086 248.031 78.69C247.84 78.7714 247.707 78.9489 247.681 79.1556C247.606 79.7687 247.413 81.1801 247.205 82.6031C247.183 82.7554 247.222 82.9103 247.314 83.0336C247.406 83.1568 247.544 83.2381 247.696 83.2596L247.78 83.2625Z"
        fill="#0D323F"
      />
      <path
        d="M259.895 69.8889C260.044 64.6195 257.409 60.387 253.14 59.1C251.079 58.477 247.974 57.8748 244.883 58.5403C241.843 59.1942 239.531 60.9081 238.011 63.6336C235.61 67.9356 235.357 73.2463 237.366 77.1655C238.651 79.6681 242.256 82.0035 246.1 82.4757C248.106 82.7221 250.178 82.463 252.02 81.4489C257.285 79.7715 259.766 74.4469 259.895 69.8889Z"
        fill="#1FAD66"
      />
      <path
        d="M246.108 76.0682C246.028 76.058 245.953 76.0275 245.888 75.9796C244.911 75.2612 244.136 74.2234 243.385 73.2195C242.974 72.6471 242.535 72.0977 242.069 71.5735C241.949 71.4446 241.908 71.2571 241.96 71.0817C242.013 70.9062 242.151 70.7695 242.323 70.723C242.495 70.6765 242.675 70.7273 242.795 70.8562C243.278 71.3805 243.733 71.9987 244.175 72.5821C244.759 73.4263 245.427 74.2029 246.171 74.8997C247.787 73.3288 249.139 71.3556 250.45 69.4441C251.4 68.079 252.357 66.666 253.42 65.3969C253.601 65.1841 253.911 65.1557 254.112 65.3333C254.314 65.5109 254.333 65.8282 254.155 66.0445C253.121 67.3167 252.148 68.6398 251.24 70.0085C249.812 72.088 248.336 74.2411 246.495 75.936C246.388 76.0343 246.247 76.0824 246.108 76.0682Z"
        fill="white"
      />
      <path
        opacity="0.399"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M209.339 50.3186L214.679 55.6682L209.339 61.0177L203.998 55.6682L209.339 50.3186Z"
        fill="#0FCC7D"
      />
      <path
        d="M221.823 92.1849C221.767 92.1286 221.701 92.0841 221.627 92.0535C221.554 92.0232 221.475 92.0075 221.396 92.0075C221.317 92.0075 221.238 92.0232 221.165 92.0535C221.091 92.0841 221.025 92.1286 220.969 92.1849L217.487 96.0292C217.281 96.2523 217.166 96.5453 217.166 96.8494C217.166 97.1536 217.281 97.4466 217.487 97.6696V97.6718L220.969 101.516C221.053 101.6 221.161 101.658 221.279 101.681C221.396 101.704 221.517 101.692 221.628 101.646C221.738 101.6 221.832 101.522 221.899 101.423C221.965 101.323 222 101.206 222 101.086V92.6113C222 92.4515 221.936 92.2981 221.823 92.1849Z"
        fill="#00A962"
      />
      <path
        d="M226.834 105.956C226.834 106.035 226.85 106.113 226.882 106.186C226.913 106.258 226.959 106.324 227.016 106.378C227.074 106.432 227.142 106.474 227.216 106.501C227.29 106.528 227.369 106.539 227.447 106.534C232.501 106.215 236.503 102.002 236.503 96.85C236.503 91.6982 232.501 87.4837 227.447 87.1662C227.369 87.161 227.29 87.1725 227.216 87.1992C227.141 87.2261 227.073 87.2679 227.016 87.3221C226.959 87.3762 226.913 87.4416 226.882 87.5142C226.85 87.5869 226.834 87.6653 226.834 87.7443V91.3777C226.835 91.5408 226.896 91.6979 227.005 91.8187C227.115 91.9397 227.264 92.0163 227.425 92.0335C228.596 92.1794 229.672 92.7493 230.453 93.6361C231.234 94.5229 231.664 95.6654 231.664 96.8488C231.664 98.0319 231.234 99.1745 230.453 100.061C229.672 100.948 228.596 101.518 227.425 101.664C227.264 101.681 227.115 101.758 227.005 101.879C226.896 102 226.836 102.157 226.834 102.32V105.956Z"
        fill="#00A962"
      />
    </svg>
  )
}
