export function CoinFailedIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={150} height={151} fill="none" {...props}>
      <g clipPath="url(#prefix__clip0_11590_35592)">
        <path
          d="M107.992 80.533a35.544 35.544 0 01-1.078 8.65 31.784 31.784 0 01-.883 2.937 26.346 26.346 0 01-.829 2.136c-.168.433-.365.862-.568 1.288a32.305 32.305 0 01-1.024 2.002c-.221.403-.44.797-.684 1.187-6.18 10.283-17.437 17.162-30.281 17.158l-12.8-.002-.128-40.411-.096-28.829.833.025c-.203.467.037 1.144.7 1.144.36 0 .719-.008 1.077-.008-.37.457-.17 1.335.589 1.307l24.197-.938c.512.231 1.018.47 1.522.726.872.433 1.723.902 2.537 1.41-8.192.396-16.382.795-24.577 1.196-1.013.049-.996 1.628.026 1.58 8.914-.436 17.839-.871 26.758-1.31l.27.199A35.287 35.287 0 01107.3 73.462c.452 2.278.694 4.644.692 7.07z"
          fill="#1D232A"
        />
        <path
          d="M92.936 92.95c-6.898 18.273-27.281 27.52-45.529 20.659A35.109 35.109 0 0127.79 95.332l8.425-6.38a25.161 25.161 0 01-1.022-3.803l-8.794 6.662a35.663 35.663 0 01-.665-2.241.593.593 0 00.114-.07l8.792-6.663.123-1.997-1.033.795-8.38 6.347a34.86 34.86 0 01-.73-5.398 35.287 35.287 0 012.173-14.32c.022-.054.04-.108.062-.163 4.997-13.235 17.068-21.735 30.28-22.811a33.161 33.161 0 012.482-.11l12.954-.017.173-.002a35.372 35.372 0 0112.343 2.227c.65.246 1.297.512 1.93.791l-11.821.458a34.763 34.763 0 014.02 2.254c3.954-.194 7.907-.385 11.861-.576.012.002.02.011.027.015.753.456 1.48.938 2.18 1.451-3.988.197-7.976.392-11.964.587a35.145 35.145 0 019.553 11.111c4.781 8.727 5.86 19.418 2.064 29.472z"
          fill="#E8E9EA"
        />
        <path
          d="M42.84 97.845h-.001a24.165 24.165 0 01-5.645-8.686h0a24.283 24.283 0 01-.832-2.64l35.696-27.035c.808.461 1.581.967 2.319 1.512a24.16 24.16 0 017.32 8.806L43.652 98.61a20.475 20.475 0 01-.813-.764zM26.398 91.81l.302.4.288-.219-.116-.34-.474.16zm1.591 2.743l-.41.31.21.47.41-.31c-.071-.156-.141-.313-.21-.47zm7.752-5.434l.471-.168-.302-.398-.29.22.121.346zm-.993-3.633c.035.18.073.362.112.542l.44-.334a25.65 25.65 0 01-.108-.545l-.444.337z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <path
          d="M58.373 96.864c-1.303 0-2.65-.235-3.977-.711-4.931-1.77-7.526-6.966-7.189-10.965a.281.281 0 11.56.047c-.32 3.787 2.144 8.71 6.82 10.387 3.366 1.21 6.847.786 9.311-1.134 1.873-1.459 2.822-3.59 2.603-5.848-.41-4.225-2.995-6.062-6.267-8.387l-.294-.21c-3.268-2.327-5.981-4.543-6.103-8.11-.08-2.332.975-4.475 2.897-5.876 2.285-1.666 5.462-2.01 8.497-.92 3.617 1.3 5.586 4.513 6.222 7.087a.282.282 0 11-.548.135c-.602-2.433-2.458-5.47-5.865-6.692-2.902-1.042-5.81-.735-7.976.846-1.768 1.29-2.74 3.259-2.666 5.402.114 3.315 2.613 5.351 5.868 7.67l.295.21c3.253 2.314 6.063 4.313 6.498 8.792.239 2.455-.787 4.769-2.816 6.347-1.638 1.273-3.693 1.93-5.87 1.93z"
          fill="#1D232A"
        />
        <path
          d="M53.021 99.652a.282.282 0 01-.264-.377l13.415-37.511a.282.282 0 01.531.19l-13.416 37.51a.283.283 0 01-.266.188z"
          fill="#1D232A"
        />
        <path
          d="M34.154 82.577l-8.122 6.156c-.044-.18-.087-.361-.127-.543l8.127-6.154.164-.126-.042.667z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <path
          d="M42.546 99.003a.284.284 0 01-.193-.077 25.451 25.451 0 01-6.404-9.88 25.225 25.225 0 01-.661-14.813c.219-.86.502-1.75.838-2.648a25.346 25.346 0 014.415-7.496.28.28 0 11.427.364 24.781 24.781 0 00-4.317 7.33c-.328.878-.604 1.75-.82 2.59a24.694 24.694 0 00.647 14.485 24.872 24.872 0 006.263 9.661.281.281 0 01-.195.484z"
          fill="#1D232A"
        />
        <path
          d="M111.885 61.741L108.3 51.82l-9.924-3.578 9.922-3.585 3.578-9.924 3.585 9.922 9.924 3.578-9.922 3.585-3.578 9.924z"
          fill="#E8E9EA"
        />
        <path
          d="M103.926 76.016l-2.308-6.39-6.39-2.305 6.39-2.308 2.304-6.392 2.308 6.39 6.39 2.305-6.39 2.308-2.304 6.392z"
          fill="#BBBDBF"
        />
      </g>
      <g filter="url(#prefix__filter0_d_11590_35592)">
        <path
          d="M98.25 132.232c11.598 0 21-9.402 21-21s-9.402-21-21-21-21 9.402-21 21 9.402 21 21 21z"
          fill="#4A4F55"
        />
      </g>
      <path
        d="M108.326 118.19a2.205 2.205 0 01-3.117 3.118l-6.959-6.959-6.96 6.959c-.43.43-.994.645-1.558.645a2.206 2.206 0 01-1.559-3.763l6.96-6.958-6.96-6.96a2.206 2.206 0 013.117-3.118l6.96 6.96 6.959-6.96a2.205 2.205 0 013.117 3.118l-6.958 6.96 6.958 6.958z"
        fill="#FCFCFC"
      />
      <defs>
        <clipPath id="prefix__clip0_11590_35592">
          <path fill="#fff" transform="translate(24.563 34.732)" d="M0 0h100.823v81.159H0z" />
        </clipPath>
        <filter
          id="prefix__filter0_d_11590_35592"
          x={74.25}
          y={89.232}
          width={48}
          height={48}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={1.5} />
          <feColorMatrix values="0 0 0 0 0.290196 0 0 0 0 0.309804 0 0 0 0 0.333333 0 0 0 0.32 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_11590_35592" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_11590_35592" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
